(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('calendar', ['$timeout', '$translate', '$filter',
        function ($timeout, $translate, $filter) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Calendar/Templates/Calendar.tpl.html',
                scope: {
                    dayClick: '&',
                    monthChange: '&'
                },
                transclude: true,
                controller: ['$scope', '$element', '$attrs', '$transclude', function ($scope, $element, $attrs, $transclude) {

                    $scope.weeks = [];
                    $scope.currentMonth = null;
                    $scope.items = [];

                    // Init
                    InitDirective();
                    function InitDirective() {
                        var today = moment().date(1);
                        $scope.currentMonth = today.clone().date(1);

                        // Build Calendar
                        BuildCalendar($scope.currentMonth, today);
                    }

                    // Objects
                    function Week(weekNumber) {
                        this.WeekNumber = weekNumber;
                        this.Days = [];
                    }

                    function Day(number, isCurrentMonth, isToday, date) {
                        this.Items = [];
                        this.Number = number;
                        this.IsCurrentMonth = isCurrentMonth;
                        this.IsToday = isToday;
                        this.Date = date;
                    }

                    // Methods
                    function BuildCalendar(month, dayInMonth) {
                        // Clone
                        month = month.clone();
                        dayInMonth = dayInMonth.clone().date(1);
                        
                        // Build Calendar
                        var startDate = RemoveTime(dayInMonth.date(1));
                        $scope.weeks = BuildMonth(startDate, month);
                    }

                    function RemoveTime(date) {
                        return date.day(0).hour(0).minute(0).second(0).millisecond(0);
                    }

                    function BuildMonth(start, month) {
                        var weeks = [];

                        var done = false;
                        var date = start.clone().startOf('isoWeek');
                        var monthIndex = date.month();
                        var count = 0;

                        while (!done) {
                            var week = new Week(date.week());
                            week.Days = BuildWeek(date.clone(), month);

                            // Add Week
                            weeks.push(week);

                            // Increment for next week
                            date.add(1, "w");
                            done = (count++ > 2 && monthIndex !== date.month());
                            monthIndex = date.month();
                        }

                        return weeks;
                    }

                    function GetWeekNumber(date) {
                        if (date.day() == 0) {
                            date.day(-1).week();
                        }
                        return date.week();
                    }

                    function BuildWeek(date, month){
                        var days = [];
                        var weekStartDate = date.day(1);

                        for (var i = 0; i < 7; i++) {
                            // Create day
                            var day = new Day(
                                weekStartDate.date(),
                                (weekStartDate.month() == month.month()),
                                (weekStartDate.isSame(new Date(), "day")),
                                weekStartDate
                            );
                            
                            // Add day
                            days.push(day);

                            // Increment for next day
                            weekStartDate = weekStartDate.clone();
                            weekStartDate.add(1, "d");
                        }

                        return days;
                    }

                    function Next() {
                        $scope.currentMonth = $scope.currentMonth.add(1, 'M').date(1);
                        var startDate = $scope.currentMonth.clone();
                        
                        BuildCalendar($scope.currentMonth, startDate);
                        MonthChange($scope.currentMonth.clone());
                    }

                    function Prev() {
                        $scope.currentMonth = $scope.currentMonth.add(-1, 'M').date(1);
                        var startDate = $scope.currentMonth.clone();

                        BuildCalendar($scope.currentMonth, startDate);
                        MonthChange($scope.currentMonth.clone());
                    }

                    function ItemIsPositive(day) {
                        return ItemIsState(day, 'positive');
                    }

                    function ItemIsNegative(day) {
                        return ItemIsState(day, 'negative');
                    }

                    function ItemIsState(day, state) {
                        var item = _.find(day.Items, function (o) {
                            return (_.isString(o.State) && o.State.toLowerCase() === state);
                        });
                        return !(!item);
                    }

                    function AddItem(item) {
                        if (item != undefined && item != null) {
                            var date = moment(item.Date);
                            _.forEach($scope.weeks, function (week) {
                                var day = _.find(week.Days, function (o) {
                                    return date.isSame(o.Date, "day");
                                });

                                // Add to day
                                if (day) {
                                    day.Items.push(item);
                                    return;
                                }
                            });
                        }
                    }
                    function RemoveItem(id) {
                        var removed = false;
                        _.forEach($scope.weeks, function (week) {
                            _.forEach(week.Days, function (day) {
                                var index = _.findIndex(day.Items, function (o) {
                                    return o.$CalendarItemId == id;
                                });
                                // Remove item
                                if (index >= 0) {
                                    day.Items.splice(index, 1);
                                    removed = true;
                                    return;
                                };
                            });

                            if (removed) {
                                return;
                            } 
                        });
                    }

                    function ClickOnDay(day) {
                        if (angular.isFunction($scope.dayClick)) {
                            var items = [];
                            for(var i = 0; i < $scope.items.length; i++){
                                var item = $scope.items[i];
                                var date = moment(item.Date);
                                if(date.isSame(day.Date, 'd')){
                                    items.push(item);
                                }
                            }

                            $scope.dayClick({
                                callback: {
                                    Day: day,
                                    Items: day.Items
                                }
                            });
                        }
                    }

                    function MonthChange(date) {
                        if (angular.isFunction($scope.monthChange)) {
                            $scope.monthChange({
                                callback: {
                                    Date: date
                                }
                            });
                        }
                    }

                    // Bindings
                    //$scope.HasItem = HasItem;
                    $scope.ItemIsPositive = ItemIsPositive;
                    $scope.ItemIsNegative = ItemIsNegative;
                    $scope.Next = Next;
                    $scope.Prev = Prev;
                    $scope.ClickOnDay = ClickOnDay;
                    this.AddItem = AddItem;
                    this.RemoveItem = RemoveItem;
                }]
            }
        }
    ]);
})();