(function () {
    'use strict';
    var app = angular.module('App');

    app
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('contactmodule', {
                        parent: 'base',
                        url: '/contactmodule/:token',
                        params: {
                            backState: null
                        },
                        template: '<contact-module my-store="ctrl.myStore" departments="ctrl.departments" settings="ctrl.moduleSettings"></contact-module>',
                        resolve: {
                            contactModuleData: ['$http', 'base', function ($http, base) {
                                if (base) {
                                    return $http.get('/Contact/GetContacts');
                                }
                                return;
                            }]
                        },
                        controller: 'ContactController as ctrl'
                    });
            }
        ])
        .controller('ContactController', [
            '$stateParams', 'Page', 'contactModuleData', ContactController
        ]);

    function ContactController($stateParams, Page, contactModuleData) {
        var ctrl = this;

        setBackButton();

        ctrl.myStore = contactModuleData.data.MyStore;
        ctrl.moduleSettings = contactModuleData.data.Settings;
        ctrl.departments = contactModuleData.data.Stores;

        Page.setTitle(contactModuleData.data.PageTitle);

        function setBackButton() {
            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
            } else {
                Page.showBackButton('home');
            }
        }
    }
})();