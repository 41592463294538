(function () {
    'use strict';

    angular
        .module('App')
        .factory('AddEventUpdateService', ['$http', '$stateParams', 'PopupWrapperService',
            function ($http, $stateParams, PopupWrapperService) {
                let editEventUpdateComponentController;
    
                return {
                    openEditEvent: openEditEvent,
                    openPopup: openPopup,
                    prepareDataForServer: prepareDataForServer
                }
    
                function openEditEvent(event) {
                    editEventUpdateComponentController.openUpdateEvent(event);
                }
    
                function openPopup(data) {
                    PopupWrapperService.createDynamic(
                        '<add-event-update' +
                        ' event-token="eventToken"' +
                        ' is-update="isUpdate"' +
                        ' on-event-update-created="onEventUpdateCreated"' +
                        '></add-event-update>', {
                            eventToken: data.eventToken,
                            isUpdate: data.isUpdate,
                            onEventUpdateCreated: data.onEventUpdateCreated
                        });
                }
                
                function prepareDataForServer(eventUpdate) {
                    if (eventUpdate.EventMedias) {
                        eventUpdate.eventMedias = eventUpdate.EventMedias.map(media => {
                            return media.MediaId;
                        });
                    }
    
                    return {
                        MediaIds: eventUpdate.eventMedias,
                        Translations: eventUpdate.selectedLanguages.reduce((acc, language) => {
                            language.BodyText && acc.push({
                                CultureId: language.CultureId,
                                BodyText: language.BodyText,
                                AutoTranslated: language.AutoTranslated
                            })
                            return acc
                        }, [])
                    };
                }
            }])
})();