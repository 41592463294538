(function () {
    'use strict';

    angular.module('App')
        .component('inviteIndividuals', {
            template: require('./InviteIndividualsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$compile', '$scope', '$http', InviteIndividualsController],
            bindings: {
                buttonId: '@',
                permissions: '<',
                invitedUsers: '=',
                isEdit: '<',
                token: '<',
                accountModuleToken: '<'
            }
        })

    function InviteIndividualsController($compile, $scope, $http) {
        var ctrl = this, popupEl, popup;

        ctrl.close = close;
        ctrl.save = save;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.openPopup = openPopup;
        ctrl.getUsers = getUsers;

        function onPopupRegistered(popupCtrl) {
            ctrl.users = getUsers(0, 100).then(function (resp) {
                return resp.Users;
            })

            popup = popupCtrl;
            popup.open()
        }

        function close() {
            if (!ctrl.isEdit) {
                ctrl.invitedUsers = [];
            }
            popup.remove();
            popupEl.remove()
        }

        function openPopup() {
            const template = angular.element(require('./InviteIndividualsPopup.html'));

            popupEl = $compile(template)($scope);
            angular.element('body').append(popupEl);
        }

        function getUsers(offset, limit, name) {
            let model = {
                offset: offset,
                limit: limit,
                name: name,
                token: ctrl.token,
                accountModuleToken: ctrl.accountModuleToken
            };
            ctrl.permissions?.forEach(permission => {
                if (permission.hasOwnProperty('MatchAllUserGroups')) {
                    permission.MatchAllGroups = permission.MatchAllUserGroups;
                    delete permission.MatchAllUserGroups;
                }
            });
            model.VirtualPermissions = ctrl.permissions;

            return $http.post('/Calendar/GetPossibleIndividualsByVirtualPermissions', model)
                .then(resp => getUsersResp(resp, limit))
        }

        function getUsersResp(response, limit) {
            const moreUsers = response.data.length !== 0 && response.data.length === limit;

            return {
                Users: response.data,
                MoreUsers: moreUsers
            }
        }

        function save() {
            popup.remove();
            popupEl.remove()
        }
    }
})();