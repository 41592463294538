(function () {
    'use strict';

    angular.module('App')
        .component('departmentHierarchy', {
            templateUrl: 'Scripts/Components/DepartmentHierarchy/DepartmentHierarchyComponent.html',
            controllerAs: 'ctrl',
            controller: [DepartmentHierarchyComponent],
            bindings: {
                departments: '<',
                onOpenDepartment: '&'
            }
        });

    function DepartmentHierarchyComponent() {
        var ctrl = this;
        ctrl.isRTL = angular.element('html').attr('dir') == 'rtl' ? true : false;
     
        ctrl.openDepartment = openDepartment;
        ctrl.toggleChildren = toggleChildren;
        ctrl.hasAlias = hasAlias;
        ctrl.departmentsToShow = [];
        
        ctrl.$onInit = function () {
            ctrl.departments = ctrl.departments;
            createHierarchy(ctrl.departments);
        };

        function createHierarchy(departments) {
            ctrl.rootDepartments = [];
            var Children = {};

            departments.forEach(function (department) {
                var parent = department.ParentId,
                    target = !parent ? ctrl.rootDepartments : (Children[parent] || (Children[parent] = []));

                target.push(department);
            })

            var findChildren = function (parent) {
                if (Children[parent.DepartmentId]) {
                    parent.Children = Children[parent.DepartmentId];
                    for (var i = 0, len = parent.Children.length; i < len; ++i) {
                        findChildren(parent.Children[i]);
                    }
                }
            };

            for (var i = 0, len = ctrl.rootDepartments.length; i < len; ++i) {
                findChildren(ctrl.rootDepartments[i]);
            }

            ctrl.rootDepartments.forEach(function (department) {
                department.Indentation = 0;
                ctrl.departmentsToShow.push(department);
            })

            return ctrl.rootDepartments;
        }

        function toggleChildren(departmentToOpen) {
            departmentToOpen.isOpen = !departmentToOpen.isOpen;
            var orderCount = 1;

            departmentToOpen.Children.forEach(function (department) {
                var departmentInArray = ctrl.departmentsToShow.find(x => x.DepartmentId === department.DepartmentId);

                if (!departmentInArray) {
                    let index = ctrl.departmentsToShow.findIndex(el => el.DepartmentId === department.ParentId);
                    ctrl.departmentsToShow.splice(index + orderCount, 0, department);
                    department.Indentation = departmentToOpen.Indentation + 20;
                } else {
                    ctrl.departmentsToShow = ctrl.departmentsToShow.filter(x => {
                        return x.DepartmentId != department.DepartmentId;
                    })
                }

                orderCount = orderCount+1;
            })

            getDescendants(departmentToOpen, (key, value) => {
                if (departmentToOpen.isOpen === false) {
                    if (key === "DepartmentId") {
                        var department = ctrl.departments.find(x => x.DepartmentId === value);

                        if (department.DepartmentId !== departmentToOpen.DepartmentId) {
                            ctrl.departmentsToShow = ctrl.departmentsToShow.filter(x => {
                                department.isOpen = false;
                                return x.DepartmentId != department.DepartmentId;
                            })
                        }
                    }
                }
            })
        }

        function getDescendants(obj, callback) {
            for (const [key, value] of Object.entries(obj)) {
                if (value && typeof value === "object") {
                    getDescendants(value, callback);
                } else {
                    callback(key, value);
                }
            }
        }

        function hasAlias(alias) {
            if (alias === "" ||
                alias === " " ||
                alias === null ||
                alias === undefined) {
                return false;
            } else {
                return true;
            }
        }

        function openDepartment(department) {
            ctrl.onOpenDepartment({ department: department });
        }
    }
})();
