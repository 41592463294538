(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('confirmPopup', {
            template: require('./ConfirmPopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', ConfirmPopupController],
            bindings: {
                options: '=',
                onYesCallback: '<',
                onNoCallback: '<',
                onClose: '<',
                isAlert: '<',
            }
        });

    function ConfirmPopupController($element, $timeout) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.yes = yes;
        ctrl.no = no;
        ctrl.close = close;

        function init() {
            if (ctrl.options.message) {
                ctrl.options.message = String(ctrl.options.message).replace(/(?:\r\n|\r|\n)/g, '<br />')
            }

            // Icon type
            if (_.isString(ctrl.options.iconClass) && ctrl.options.iconClass.length) {
                ctrl.iconType = 'class';
            } else if (_.isString(ctrl.options.icon) && ctrl.options.icon.length) {
                ctrl.iconType = 'image';
            }
        }

        function yes() {
            animate(ctrl.onYesCallback);
        }

        function no() {
            animate(ctrl.onNoCallback);

        }

        function close() {
            ctrl.onClose();
        }

        function animate(callback) {
            $element.find('.bg').addClass('fadeOut');
            $element.find('.confirmation-popup').addClass('fadeOut');
            $timeout(function () {
                callback()
            }, 400)
        }
    }
})();