(function () {
    'use strict';

    angular.module('App')
        .factory('AttachmentsViewerService',
            ['PopupWrapperService', 'FILE_TYPE', function (PopupWrapperService, FILE_TYPE) {
                return {
                    openViewerWithMedia: openViewerWithMedia
                };

        function prepareAttachments(mediaList) {
            return mediaList.map(function (media) {
                return getAttachmentFromMedia(media);
            });
        }

        function openViewerWithMedia(mediaList, key, onImageComment) {
            PopupWrapperService.createDynamic('<attachments-viewer' +
                ' attachments="attachments"' +
                ' selected-index="selectedAttachmentIndex" ' +
                ' on-popup-registered="onPopupRegistered"' +
                ' on-image-update="onImageComment" enable-comments="true"></attachments-viewer>', {
                onPopupRegistered: function (popup) {
                    popup.open();
                },
                attachments: prepareAttachments(mediaList),
                selectedAttachmentIndex: key,
                onImageComment: onImageComment
            })
        }

        function getAttachmentFromMedia(media) {
            var attachment = {
                id: media.MediaId,
                type: media.ContentType,
                attachmentType: getFileType(media.ContentType),
                name: media.FileName,
                thumbnail: getThumbnailUrl(media),
                media: media,
                status: 3
            };

            if (attachment.thumbnail) {
                attachment.status = 2;
            }

            return attachment;
        }

        function getFileType(type) {
            return FILE_TYPE[type] || FILE_TYPE['unknown'];
        }

        function getThumbnailUrl(media) {
            var thumb = media.ThumbnailUrl || media.ImageFormats.W250 || media.ImageFormats.W100 || null;
            // images can also use the original URL for the thumbnail if needed
            if (!thumb) {
                var mediaType = getFileType(media.ContentType);
                if (mediaType === 'image') {
                    thumb = media.OriginalUrl;
                }
            }
            return thumb;
        }
    }]);
})();