(function () {
    'use strict';

    angular
        .module('App')
        .component('serviceFormCommentsPopup', {
            template: require('./ServiceFormCommentsPopupComponent.html'),
            controller: ['$element', 'ServiceFormRestService', 'ServiceFormRenderService', 'ReportPopupService', ServiceFormCommentsPopupController],
            controllerAs: 'ctrl',
            bindings: {
                filling: '=',
                stageToken: '<',
                fillingToken: '<',
                commentId: '<',
                moduleToken: '@'
            }
        });

    function ServiceFormCommentsPopupController($element, ServiceFormRestService, ServiceFormRenderService, ReportPopupService) {
        var ctrl = this, popup;

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.postComment = postComment;
        ctrl.deleteComment = deleteComment;
        ctrl.registeredCallback = registeredCallback;
        ctrl.onReport = reportFillingComment;

        function onOpen() {
            ctrl.newComment = {
                text: '',
                uploadedMedia: []
            };
            ctrl.settings = {
                AllowAutomaticTranslation: ServiceFormRenderService.fillingInteractionSettings.AllowAutomaticTranslation,
                PreferTranslatedComments: ServiceFormRenderService.fillingInteractionSettings.PreferTranslatedComments,
            }
            ServiceFormRestService
                .getComments(ctrl.fillingToken, ctrl.stageToken)
                .then(function (comments) {
                    ctrl.filling.comments = comments;
                    ctrl.filling.CommentCount = comments.length;
                })
        }

        function close() {
            popup.remove();
            $element.remove();
        }


        function registeredCallback(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onReportSubmit(model, comment) {
            return comment.ServiceFormFillingCommentId ?
                ReportPopupService.reportFillingComment({
                    serviceFormFillingCommentId: comment.ServiceFormFillingCommentId,
                    model,
                }) :
                ReportPopupService.reportStageComment({
                    serviceFormStageCommentId: comment.ServiceFormStageCommentId,
                    model,
                })

        }

        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportFillingComment(comment) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, comment);
        }

        function postComment() {
            var commentId = ctrl.newComment.serviceFormFillingCommentId || ctrl.newComment.serviceFormStageCommentId,
                isEdit = !!commentId, request,
                media = ctrl.newComment.uploadedMedia.map(function (item) {
                    return item.MediaId;
                });

            if (!isEdit) {
                request = ServiceFormRestService.postComment;
            } else {
                request = ServiceFormRestService.editComment;
            }

            request(ctrl.fillingToken, ctrl.newComment.text, ctrl.stageToken, media, commentId)
                .then(function (comments) {
                    ctrl.filling.comments = comments;
                    ctrl.filling.CommentCount = comments.length;

                    ctrl.newComment = {
                        text: '',
                        uploadedMedia: []
                    };
                    isEdit = false;
                });
        }

        function deleteComment(comment) {
            ServiceFormRestService
                .deleteComment(ctrl.fillingToken, comment.ServiceFormFillingCommentId || comment.ServiceFormStageCommentId, ctrl.stageToken)
                .then(function (comments) {
                    if (!ctrl.stageToken) {
                        ctrl.filling.comments = comments;
                    } else {
                        ctrl.filling.comments = _.filter(ctrl.filling.comments, function (item) {
                            return item.ServiceFormStageCommentId !== comment.ServiceFormStageCommentId;
                        });
                    }
                    ctrl.filling.CommentCount = ctrl.filling.comments.length;
                })
        }
    }
})();


