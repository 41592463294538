(function () {
    'use strict';

    angular
        .module('App')
        .component('subtaskDetailsPopup', {
            template: require('./SubtasksDetailsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', '$rootScope', SubtaskDetailsPopupController],
            bindings: {
                issueToken: '<',
                taskToken: '<',
                completionData: '<'
            }
        });

    function SubtaskDetailsPopupController($element, $scope, $rootScope) {
        var ctrl = this, popup, subtaskListUpdatedWatcher;

        ctrl.loading = true;
        ctrl.close = close;
        ctrl.onRegistered = onRegistered;
        ctrl.$onDestroy = destroy;
        ctrl.isClosing = false;

        $scope.$on('$destroy', destroy);

        subtaskListUpdatedWatcher = $rootScope.$on('TaskManagement:subtasksListUpdated', function () {
            ctrl.close();
        });

        function onRegistered(popupCtrl) {
            popup = popupCtrl;
            popupCtrl.open();
        }

        function close() {
            popup.remove();
            $element.remove();
        }

        function destroy() {
            popup.remove();
            $element.remove();
            subtaskListUpdatedWatcher();
        }
    }
})();