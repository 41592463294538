(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartLink', ['Page',
				function (Page) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartLink.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    
                    scope.openLink = function () {
                        if (scope.data.AppLink != null) {
                            Page.stateGo(scope.data.AppLink.PathState, scope.data.AppLink.PathToken, scope.data.AppLink.PathExtra);
                        }
                    }
                }
            }
        }
    ]);
})();