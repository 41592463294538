HighchartsMore(Highcharts);
SolidGauge(Highcharts);

(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('gaugeChart', ['$log', '$http', 'Page', 'Profile', 'TimeLocale', 'ChartHelper', 'BasicHelper', '$timeout',
        function ($log, $http, Page, Profile, TimeLocale, ChartHelper, BasicHelper, $timeout) {
            return {
                restrict: 'E',
                template: require('./Templates/GaugeChart.tpl.html'),
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chartIsLoading = true;
                    scope.chart = {};

                    // Get data
                    $.getJSON(Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken,
                        function (response) {
                            //console.log(response, scope.data);
                            if (response.MetricData !== null) {
                                // Value
                                var chartValue = 0;
                                var metricValue = (response.MetricData.MetricValue) ? response.MetricData.MetricValue : 0;
                                if (response.MetricData.MetricValue % 1 !== 0) {
                                    chartValue = ChartHelper.formatNumber(response.MetricData.MetricValue, 2, ",", ".");
                                } else {
                                    chartValue = ChartHelper.formatNumber(response.MetricData.MetricValue, 0, ",", ".");
                                }

                                var customConfig = {};
                                if (!BasicHelper.stringIsNullOrEmpty(scope.data.CustomConfig)) {
                                    customConfig = JSON.parse(scope.data.CustomConfig);
                                }

                                // Create Chart
                                scope.chart = Highcharts.merge({
                                    chart: { type: 'solidgauge' },
                                    credits: { enabled: false },
                                    title: null,
                                    pane: {
                                        center: ['50%', '85%'],
                                        size: '140%',
                                        startAngle: -90,
                                        endAngle: 90,
                                        background: {
                                            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                                            innerRadius: '60%',
                                            outerRadius: '100%',
                                            shape: 'arc'
                                        }
                                    },
                                    tooltip: { enabled: false },
                                    yAxis: {
                                        min: 0,
                                        max: 100,
                                        stops: [
                                            [0.50, '#DF5353'],
                                            [0.75, '#DDDF0D'],
                                            [1.00, '#55BF3B']
                                        ],
                                        lineWidth: 0,
                                        minorTickInterval: null,
                                        tickAmount: 2,
                                        labels: {
                                            y: 16
                                        }
                                    },
                                    plotOptions: {
                                        solidgauge: {
                                            dataLabels: {
                                                y: -40,
                                                borderWidth: 0,
                                                useHTML: true
                                            }
                                        }
                                    },
                                    series: [{}] // Empty Series is being filled later
                                }, customConfig);

                                // Re Add data to series
                                _.forEach(scope.chart.series, function (i) {
                                    if (!i.name) {
                                        i.name = scope.data.Label;
                                    }
                                    if (!i.data) {
                                        i.data = [{}];
                                    } if (!i.data[0].y) {
                                        i.data[0].y = metricValue;
                                    }
                                    if (!i.dataLabels) {
                                        i.dataLabels = {
                                            format: '<div class="gauge-center""><span class="gauge-value">' + chartValue + '</span><br/>' +
                                                '<span class="gauge-label">' + scope.data.Label + '</span></div>'
                                        }
                                    }
                                });
                            } else {
                                scope.chart = null;
                            }

                            scope.chartIsLoading = false;
                            scope.$apply();

                            if (scope.chart != null) {
                                // Show Chart
                                $timeout(function () {
                                    Highcharts.chart($('.gauge-container', $element)[0], scope.chart);
                                });
                            }
                        }).fail(function () {
                            scope.chart = null;
                            scope.chartIsLoading = false;
                            scope.$apply();
                        });
                }
            }
        }
    ]);
})();