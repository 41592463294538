(function () {
    'use strict';

    angular.module('App')
        .factory('CompletionsPopupService', ['$http', 'PopupWrapperService', CompletionsPopupService]);

    function CompletionsPopupService($http, PopupWrapperService) {

        return {
            openCompletionsPopup: openCompletionsPopup,
        };


        function openCompletionsPopup(completions, sfToken) {
            PopupWrapperService
                .createDynamic(
                    '<completions-popup completions="completions" service-form-token="sfToken"></completions-popup>',
                    {
                        completions: completions,
                        sfToken: sfToken
                    });
        }

    }
})();