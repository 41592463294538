(function () {
    'use strict';

    angular.module('App')
        .factory('ContactFactory', ['$q', '$http', '$stateParams', 'Page', ContactFactory]);

    function ContactFactory($q, $http, $stateParams) {
        let lastSearchCanceler;

        return {
            search: search
        };

        function search(query) {
            const canceller = $q.defer();

            if (lastSearchCanceler) {
                lastSearchCanceler.resolve();
                lastSearchCanceler = undefined;
            }

            const promise = $http.post('/Contact/Search/', {
                token: $stateParams.token,
                query: query
            }, {timeout: canceller.promise}).then(resp => resp.data)

            lastSearchCanceler = canceller;

            return promise
        }
    }
})();