(function () {
    'use strict';

    angular
        .module('App')
        .factory('FeedbackCommentsService', ['$http', '$rootScope',
            function ($http, $rootScope) {

                return {
                    postComment: postComment
                };

                function postComment(path, {
                    ServiceFormFeedbackId,
                    ParentCommentId,
                    Comment,
                    MediaList,
                    ServiceFormFeedbackCommentId,
                    ServiceFormFieldId,
                    ServiceFormFillingId
                }) {
                    return $http.post(path, {
                        ServiceFormFeedbackId: ServiceFormFeedbackId,
                        ParentCommentId: ParentCommentId,
                        Comment: Comment,
                        MediaList: MediaList,
                        ServiceFormFeedbackCommentId: ServiceFormFeedbackCommentId,
                        ServiceFormFieldId: ServiceFormFieldId,
                        ServiceFormFillingId: ServiceFormFillingId
                    }).then((resp) => {
                        $rootScope.$broadcast(resp.data.Feedback ? 'fillingFeedbackUpdated' : 'formFeedbackUpdated', resp.data)

                        return resp
                    })
                }
            }]);
})();