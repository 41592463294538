(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('GeolocationService', ['$q', function ($q) {
        var fillingLocation,
            cashedLocation;

        return {
            getCurrentPosition: function () {
                return $q(function (resolve, reject) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        cashedLocation = position.coords;
                        resolve(position.coords);
                    }, function (error) {
                        reject(error)
                    });
                })
            },
            //reduce into one
            getLocation: function () {
                return $q(function (resolve) {
                    var locationSetting = {};
                    //something for ie11
                    if (window.navigator.userAgent.indexOf('Trident/') !== -1) {
                        locationSetting = {
                            enableHighAccuracy: false,
                            maximumAge: 50000
                        }
                    }

                    if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            cashedLocation = position.coords;
                            resolve(position.coords);
                        }, function (error) {
                            resolve(cashedLocation);
                        }, locationSetting);
                    } else {
                        resolve();
                    }
                });
            },
            getDistance: function (lat1, lon1, lat2, lon2) {
                var p = 0.017453292519943295;    // Math.PI / 180
                var c = Math.cos;
                var a = 0.5 - c((lat2 - lat1) * p) / 2 +
                    c(lat1 * p) * c(lat2 * p) *
                    (1 - c((lon2 - lon1) * p)) / 2;

                return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
            },
            setFillingLocation: function (location) {
                fillingLocation = location;
            },
            getFillingLocation: function () {
                return $q(function (resolve) {
                    resolve(fillingLocation)
                });
            },
            isGpsEnabled: isGpsEnabled
        }

        function isGpsEnabled() {
            return "geolocation" in navigator
        }
    }]);
})();