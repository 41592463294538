(function () {
    'use strict';

    angular
        .module('App')
        .component('createFilling', {
            template: require('./CreateUpdateFillingComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$timeout', '$element', 'ServiceFormRestService', 'ServiceFormRenderService',
                'ToastFactory', 'GeolocationService', 'CreateUpdateFillingService', 'PopupWrapperService',
                'ServiceFormDataFormatService', CreateFillingController],
            bindings: {
                form: '<',
                listsData: '<',
                serviceFormId: '<',
                fillingCreated: '<',
                preDraftCreated: '<',
                headerTitle: '<',
                popupId: '<',
                issueId: '<',
                calendarEventId: '<',
                attendingStatusId: '<',
                onPopupRegistered: '<',
                parentToken: '<',
                intro: '<',
                outro: '<',
                isLoopEnabled: '<',
                labelSettings: '<',
                cachedData: '<',
                isDraftEnabled: '<',
                isDynamic: '<',
                isStagesEnabled: '<',
                isGpsNeeded: '<',
                accountModuleToken: '<',
                embeddedMedias: '<',
            }
        });
    function CreateFillingController($rootScope, $scope, $timeout, $element, ServiceFormRestService, ServiceFormRenderService,
        ToastFactory, GeolocationService, CreateUpdateFillingService, PopupWrapperService, ServiceFormDataFormatService) {
        const ctrl = this;
        let initialForm, isStepValidWatcher, isOneStep;

        if (!ctrl.isStagesEnabled) {
            initialForm = _.cloneDeep(ctrl.form)
        }

        ctrl.maxActiveStep = 0;
        ctrl.lastRuleActions = {
            FieldsToShow: [],
            InstantFeedbacks: [],
            PagesToJump: []
        };

        ctrl.isUploading = false;

        ctrl.onOpenPopup = onOpenPopup;
        ctrl.startFilling = startFilling;
        ctrl.next = next;
        ctrl.finishForm = finishForm;
        ctrl.previous = previous;
        ctrl.onClosePopup = onClosePopup;
        ctrl.getFieldDataList = getFieldDataList;
        ctrl.goToForm = goToForm;
        ctrl.createAnother = createAnother;
        ctrl.isStepValid = isStepValid;
        ctrl.saveFieldValue = saveFieldValue;
        ctrl.checkIfFieldsAreVisible = checkIfFieldsAreVisible;
        ctrl.$onDestroy = destroy;

        const goToLimitedReportsEvent = $rootScope.$on('goToLimitedReports', () => {
            if (!PopupWrapperService.getPopup(ctrl.popupId)) return;
            
            onClosePopup(false);
        });
        
        function onOpenPopup(data) {
            let stage = data?.stage || null;
            ctrl.onClose = data?.onClose || null;

            if (stage) {
                ctrl.form = stage.Steps;
                initialForm = _.cloneDeep(ctrl.form)
                ctrl.stageToken = stage.ServiceFormStageToken;
            }
            ctrl.labels = ctrl.labelSettings || ServiceFormRenderService.getLabelSettings();
            ctrl.headerTitle = ctrl.isStagesEnabled ?
                stage.Title :
                (ctrl.headerTitle || ServiceFormRenderService.getLabelSettings().TranslatedEntityNameSingular || ServiceFormRenderService.getLabelSettings().EntityNameSingular);
            if (ctrl.isLoopEnabled && !ctrl.outro) {
                ctrl.loop = true;
            }
            if (ctrl.outro && ctrl.outro.LinkedForms && ctrl.outro.LinkedForms.length === 1 && ctrl.outro.LinkedForms[0].AutoContinue) {
                ctrl.autoSwitch = true;
            }
            isOneStep = ctrl.form.length === 1;

            if (ctrl.isGpsNeeded) {
                GeolocationService.getLocation().then(location => {
                    createPreDraft(location)
                })
            } else {
                createPreDraft();
            }
        }

        function createPreDraft(location) {
            ctrl.isLoading = true;
            CreateUpdateFillingService.createPreDraft({
                Token: ctrl.accountModuleToken,
                ServiceFormId: ctrl.serviceFormId,
                Lat: location && location.latitude,
                Lon: location && location.longitude,
                IssueId: ctrl.issueId,
                CalendarEventId: ctrl.calendarEventId,
                AttendingStatusId: ctrl.attendingStatusId,
                ParentServiceFormFillingToken: ctrl.parentToken
            }).then(resp => {
                ctrl.isLoading = false;
                ctrl.preDraftCreated && ctrl.preDraftCreated(resp.data);
                ctrl.filling = resp.data.Filling;
                ctrl.fieldsWithRuleActionsToLimitDataListUsage = resp.data.FieldsWithRuleActionsToLimitDataListUsage;
                ctrl.form = ServiceFormDataFormatService.formatDataForClient(ctrl.form, ctrl.filling);
                initFillingCreation();
                ctrl.IsDraft = resp.data.Filling.IsDraft;
                CreateUpdateFillingService
                    .updateFormOnRules(resp.data.RuleActions, ctrl.form, ctrl.lastRuleActions, (lastRuleActions) => {
                        ctrl.lastRuleActions = lastRuleActions;
                    });
            })
        }

        function initFillingCreation() {
            ctrl.activeStep = 0;
            ctrl.PageNavigation = [];
            ctrl.PageNavigation.push({
                step: ctrl.activeStep,
                isActive: true
            });
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            ctrl.showOutro = false;

            if (ctrl.intro) {
                ctrl.showFillings = false;
                ctrl.hideIntro = false;
            } else {
                ctrl.showFillings = true;
                ctrl.hideIntro = true;
            }

            ctrl.maxActiveStep = 0;
            isStepValidWatcher && isStepValidWatcher();
            isStepValidWatcher = $scope.$watch('ctrl.form', isStepValid, true);
        }

        function startFilling() {
            $element.find('.scroll-container').scrollTop(0);
            ctrl.showFillings = true;
            ctrl.hideIntro = true;
            isStepValid();
        }

        function onClosePopup(isSubmitted) {
            ctrl.activeStep = 0;

            ctrl.onClose && updateFillingStatus();

            isStepValidWatcher && isStepValidWatcher();
            ctrl.filling = null;
            ctrl.form = _.cloneDeep(initialForm);
            ctrl.showOutro = false;

            if (ctrl.isDynamic) {
                PopupWrapperService.getPopup(ctrl.popupId).remove();
                $element.remove();
                $scope.$parent.$destroy();
            } else {
                PopupWrapperService.getPopup(ctrl.popupId).hide();
            }

            //child popup closed after loop
            if (ctrl.outro && isSubmitted) {
                ctrl.fillingCreated();
            }

            if (ctrl.parentToken && ServiceFormRenderService.sessionCreatedFillings.length) {
                PopupWrapperService.getPopup(ServiceFormRenderService.fillingsOverviewPopupId)
                    .open({
                        serviceFormId: ctrl.serviceFormId,
                        serviceFormFillingTokens: ServiceFormRenderService.sessionCreatedFillings,
                        headerTitle: ctrl.labels.TranslatedEntityNamePlural || ctrl.labels.EntityNamePlural,
                        entityNameSingular: ctrl.labels.TranslatedEntityNameSingular || ctrl.labels.EntityNameSingular,
                        createChildFormCache: ctrl.cachedData,
                        createAnotherLabel: ctrl.labels.TranslatedCreateAnotherLabel || ctrl.labels.CreateAnotherLabel
                    });
            }
        }

        function updateFillingStatus() {
            if (ctrl.filling && ctrl.filling.ServiceFormFillingToken) {

                ServiceFormRestService.getFillingData(ctrl.filling.ServiceFormFillingToken)
                    .then(latestFilling =>  {
                        ctrl.onClose(latestFilling)
                    });
            }
        }

        function getFieldDataList(dataListId) {
            return ctrl.listsData.find(el => el.DataListId === dataListId)
        }

        function next() {
            ctrl.isLoading = true;

            if (ctrl.maxActiveStep <= ctrl.activeStep) {
                ctrl.maxActiveStep = ctrl.activeStep + 1;
            }

            saveStep().then((data) => {
                if (data.RuleActions && data.RuleActions.PagesToJump.length) {
                    const pageJumpId = data.RuleActions.PagesToJump[0],
                        stepNumber = getStepNumber(pageJumpId);

                    if (stepNumber) {
                        goNext(stepNumber);
                    } else if (ctrl.outro?.ServiceFormPageId === pageJumpId) {
                        finishForm();
                    }
                } else {
                    goNext()
                }
            }).catch(function (error) {
                ctrl.isLoading = false;
                ToastFactory.error(error.Message);
            });

            function getStepNumber(jumpToPageId) {
                return ctrl.form.find(step => step.ServiceFormPageId === jumpToPageId)?.StepNumber;
            }
        }

        function goNext(stepNumber) {
            scrollTop();
            ctrl.isNext = true;
            ctrl.activeStep = stepNumber || (ctrl.activeStep + 1);
            const activeStepIndex = ctrl.PageNavigation.findIndex(page => page.step === ctrl.activeStep);
            if (activeStepIndex === -1) {
                ctrl.PageNavigation.length = ctrl.PageNavigation.findIndex(page => page.isActive) + 1
                ctrl.PageNavigation.push({
                    step: ctrl.activeStep
                });
            }
            ctrl.PageNavigation.forEach((page, key) => {
                if (key === ctrl.PageNavigation.length - 1) {
                    page.isActive = true
                } else {
                    delete page.isActive
                }
            })
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            isStepValid();
            ctrl.isLoading = false;
        }

        function previous() {
            scrollTop();
            ctrl.isNext = false;
            const activeStepIndex = ctrl.PageNavigation.findIndex(page => page.step === ctrl.activeStep);
            ctrl.activeStep = ctrl.PageNavigation[activeStepIndex - 1].step;
            ctrl.PageNavigation.forEach(page => {
                if (page.step === ctrl.activeStep) {
                    page.isActive = true
                } else {
                    delete page.isActive
                }
            })
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            isStepValid();
        }

        function saveStep() {
            return CreateUpdateFillingService
                .updateFilling(CreateUpdateFillingService.getSaveModel(ctrl))
                .then(function (resp) {
                    ctrl.filling = resp.data.Filling;
                    ctrl.IsDraft = ctrl.filling.IsDraft;
                    ctrl.fillingCreated && ctrl.fillingCreated(resp.data);

                    return resp.data;
                });
        }

        function finishForm() {
            ctrl.isUploading = true;
            ctrl.IsDraft = false;

            CreateUpdateFillingService
                .updateFilling(CreateUpdateFillingService.getSaveModel(ctrl, true))
                .then(function (resp) {
                    ctrl.isUploading = false;
                    if (resp.data) {
                        ctrl.ServiceFormFillingToken = resp.data.Filling.ServiceFormFillingToken;

                        if (!_.get(ctrl, 'outro.InformationField.BodyHtml')) {
                            if (ctrl.calendarEventId) {
                                $rootScope.$emit('ServiceFrom: FillingCreated', ctrl.calendarEventId, resp.data.Filling);
                            } else {
                                ToastFactory.success(
                                    ctrl.labels.TranslatedFillingSubmissionMessage ||
                                    ctrl.labels.FillingSubmissionMessage ||
                                    ServiceFormRenderService.getLabelSettings().TranslatedFillingSubmissionMessage ||
                                    ServiceFormRenderService.getLabelSettings().FillingSubmissionMessage);
                            }
                        }
                        if (ctrl.parentToken) {
                            ServiceFormRenderService.sessionCreatedFillings.push(ctrl.ServiceFormFillingToken);
                        }
                        if (!ctrl.issueId) {
                            ctrl.form = initialForm;
                        }
                        ctrl.fillingCreated && ctrl.fillingCreated(resp.data);

                        if (ctrl.loop) {
                            initFillingCreation()
                        } else {
                            if (ctrl.outro) {
                                ctrl.showFillings = false;
                                ctrl.showOutro = true;
                                if (ctrl.autoSwitch) {
                                    ctrl.goToForm(ctrl.outro.LinkedForms[0].ServiceFormToken, ctrl.parentToken)
                                }
                            } else {
                                onClosePopup(true);
                            }
                        }
                    }
                });
        }

        function goToForm(token, parentTokenSource) {
            const parentToken = parentTokenSource || ctrl.ServiceFormFillingToken;

            ServiceFormRestService.getServiceFormData(token, parentToken).then(function (data) {
                const cachedData = {
                    data: _.cloneDeep(data),
                    token: token,
                    serviceFormFillingToken: parentToken
                };
                ServiceFormRenderService.renderLinkedFormPopup(data, token, parentToken, cachedData);
            })
        }

        function createAnother() {
            initFillingCreation()
        }

        function isStepValid() {
            if (ctrl.showFillings) {
                $timeout(function () {
                    ctrl.isNextDisabled = !ctrl.form[ctrl.activeStep].Groups
                        .every(group => group.formObject && group.formObject.$valid === true)
                }, 100)
            }
        }

        function scrollTop() {
            $element.find('.scroll-container').scrollTop(0);
        }

        function saveFieldValue(field) {
            ctrl.isLoading = true;
            return CreateUpdateFillingService.setSingleFieldValue({
                ServiceFormFillingToken: ctrl.filling.ServiceFormFillingToken,
                Field: field,
                ServiceFormStageToken: ctrl.stageToken,
                ServiceFormFieldId: field.ServiceFormFieldId,
                AccountModuleToken: ctrl.accountModuleToken
            }).then(resp => {
                CreateUpdateFillingService
                    .updateFormOnRules(resp.data.RuleActions, ctrl.form, ctrl.lastRuleActions, (lastRuleActions) => {
                        ctrl.isLoading = false;
                        ctrl.lastRuleActions = lastRuleActions;
                    });
            })
        }

        function checkIfFieldsAreVisible(fields) {
            return fields.some(field => field.IsVisibleOnFormLoad);
        }

        function destroy() {
            goToLimitedReportsEvent();
        }
    }
})();
