(function () {
    'use strict';

    angular
        .module('App')
        .component('departmentField', {
            template: require('./DepartmentFieldComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', 'ServiceFormRestService', DataListFieldController],
            require: {
                ngModelCtrl: 'ngModel'
            },
            bindings: {
                field: '<',
                index: '<',
                showLabelTitle: '<',
                listData: '<',
                filters: '<',
                accountModuleToken: '<',
                isLimitedByRule: '<',
                applyFilter: '<'
            }
        });

    function DataListFieldController($scope, ServiceFormRestService) {
        const ctrl = this;

        let dirtyWatcher = $scope.$watch('ctrl.field.model', (val) => {
            if (val !== undefined) {
                ctrl.ngModelCtrl.$setDirty();
                dirtyWatcher();
            }
        })

        ctrl.$onInit = $onInit;
        ctrl.lazyLoadItems = lazyLoadItems;

        function $onInit() {
            ctrl.ngModelCtrl.$isEmpty = (val) => {
                return val === undefined || !val.length
            }

            if (ctrl.showLabelTitle) {
                ctrl.labelTitle = ctrl.field.Title;
            }
            if (ctrl.listData) {
                if (ctrl.listData.Items) {
                    formatDepartments(ctrl.listData.Items);
                }
            } else {
                ctrl.listData = {
                    IsMultiSelect: true,
                    IsLazyLoad: true
                }
            }
        }

        function formatDepartments(items) {
            items.forEach(function (item) {
                item.prefix = '-'.repeat(item.AdditionalValues.Level);
                if (!item.AdditionalValues.IsSelectable) {
                    item.isDisabled = true;
                }
            });

            return items;
        }

        function lazyLoadItems() {
            var dataListId = ctrl.field.DataListId || ctrl.field.ReferencingDataListId;

            if (dataListId) {
                return ServiceFormRestService
                    .loadDataList(dataListId, ctrl.field.ServiceFormFieldId, false, ctrl.accountModuleToken)
                    .then(data => formatDepartments(data.Items));
            } else {
                return ServiceFormRestService.loadDataListByType(ctrl.field.DataListType)
                    .then(data => formatDepartments(data.Items));
            }
        }
    }
})();