(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('powerbi', {
                    parent: 'base',
                    url: '/powerBI/:token/:extra',
                    template: '<power-bi class="view-page" data="ctrl"></power-bi>',
                    resolve: {
                        powerBIData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/PowerBI/Get/?token=' + $stateParams.token + '&extra=' + $stateParams.extra);
                            }
                        }]
                    },
                    controller: 'PowerBIController as ctrl'
                });

        }
    ]);

    app.controller('PowerBIController', ['Page', 'powerBIData', '$stateParams',
        function (Page, powerBIData, $stateParams) {
            var ctrl = this;
            ctrl.Page = Page;

            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state,
                    $stateParams.backState.token,
                    $stateParams.backState.extra,
                    null,
                    $stateParams.backState.options
                );
            } else {
                Page.showBackButton('home');
            }

            Page.setTitle(powerBIData.data.PageTitle);
            // Copy data
            ctrl.EmbedToken = powerBIData.data.EmbedToken;
            ctrl.EmbedUrl = powerBIData.data.EmbedUrl;
            ctrl.ReportId = powerBIData.data.ReportId;
            ctrl.PageId = powerBIData.data.PageId;
            ctrl.FilterPane = powerBIData.data.FilterPane;
            ctrl.PageNavigation = powerBIData.data.PageNavigation;
            ctrl.Language = powerBIData.data.Language;
            ctrl.FormatLocale = powerBIData.data.FormatLocale;
            ctrl.ErrorMessage = powerBIData.data.ErrorMessage;
        }
    ]);
})();