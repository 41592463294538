(function () {
    'use strict';

    var app = angular.module('App');

    app.directive('barcode', function() {
        return {
            restrict: 'E',
            scope: {
                bcFormat: "@",
                bcValue: "<",
                bcWidth: "<",
                bcHeight: "<",
                bcBackground: "@",
                bcTextPosition: "@",
                bcLineColor: "@",
                bcDisplayValue: "<",
                bcFontSize: "<",
                bcMargin: "<",

            },
            link: function(scope, element, attrs) {
                var barcode = scope.bcValue || element.html();
                var img = new Image();
                var allowedFormats = ['CODE39', 'CODE128', 'EAN13', 'ITF14', 'ITF', 'MSI', 'pharmacode', 'codabar'];

                if (barcode && JsBarcode && isAllowedFormat()) {
                    try {
                        JsBarcode(img, barcode, {
                            format: scope.bcFormat || 'CODE128',
                            background: scope.bcBackground || 'transparent',
                            width: scope.bcWidth || 2,
                            height: scope.bcHeight || 100,
                            lineColor: scope.bcLineColor || '#000000',
                            displayValue: scope.bcDisplayValue !== false,
                            fontSize: scope.bcFontSize || 20,
                            margin: scope.bcMargin || 10
                        });
                    } catch (e) {
                        //console.log(e);
                    }
                    
                    element.html(img);
                }

                function isAllowedFormat() {
                    return allowedFormats.includes(scope.bcFormat);
                }

            },
        };
    })
})();