(function () {
	'use strict';
	var app = angular.module('App');

	app.directive('tableChart', ['$log', '$http', 'Page', 'Profile', 'ChartHelper',
		function ($log, $http, Page, Profile, ChartHelper) {
			return {
				restrict: 'E',
				templateUrl: '/Scripts/Directives/Chart/Templates/TableChart.tpl.html',
				scope: {
					data: '='
				},
				link: function (scope, element, attrs) {
					var $element = $(element);
					scope.chartIsLoading = true;
                    scope.chartLabel = scope.data.Label;
                    
					scope.noChart = false;


					var filterDepartmentId = Profile.getProfile().FilterDepartmentId;
					var hasMultipleDepartments = Profile.getProfile().HasMultiDepartment;

                    var url = Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken;
					if (hasMultipleDepartments) { url += "&departmentid=" + filterDepartmentId; }


					// get data series from external source
					$.getJSON(url,
						function (response) {
							//console.log(response);
							if (response.DataSeries.length > 0) {
                                scope.DataSeries = response.DataSeries;
                                scope.Title = response.Title;
							} else {
								// No chart data
								scope.noChart = true;
								//console.log("No data");
							}
							scope.chartIsLoading = false;
							scope.$apply();
						});


					function getColumnCount() {
						var length = scope.DataSeries.length;
						var count = (length > 7) ? 6 : length - 1;

						return new Array(count);
					}

					scope.getColumnCount = getColumnCount;
				}
			}
		}
	]);
})();