(function () {
        'use strict';

        angular.module('App')
            .component('policyView', {
                templateUrl: '/Scripts/Components/PolicyView/PolicyViewComponent.tpl.html',
                controller: ['$element', 'Page', '$http', '$filter', 'TimeLocale', 'ConfirmPopupService', 'ToastFactory', PolicyViewComponentController],
                controllerAs: 'ctrl',
                bindings: {}
            });

    function PolicyViewComponentController($element, Page, $http, $filter, TimeLocale, ConfirmPopupService, ToastFactory) {
            var ctrl = this;

            ctrl.state = 'load';
            ctrl.isLoading = true;
            ctrl.policy = null;
            ctrl.$onInit = init;
            ctrl.$onDestroy = destroy;
            ctrl.revoke = revoke;

            function init() {
               // Load policy
                $http.get('/Profile/LoadAcceptedPolicy').then(function (response) {
                    var data = response.data;
                    if (_.isObject(data)) {
                        var dateString = TimeLocale.dateTimeFormat(data.DateAccepted, 'LL');
                        var translation = $filter('translate')('POLICY.VIEW.DATE_ACCEPTED');
                        data.DateAcceptedString = translation.replace('[DATE]', dateString);

                        // Set data after load
                        ctrl.policy = data;
                        ctrl.isLoading = false;
                        ctrl.state = 'view';
                    } else {
                        // Error occured
                        ToastFactory.errorTranslated('ERROR.PAGE_NOT_LOADED');
                        ctrl.policy = null;
                        ctrl.isLoading = false;
                        ctrl.state = 'view';
                    }
                }, function () {
                    // Error occured
                    ToastFactory.errorTranslated('ERROR.PAGE_NOT_LOADED');
                    ctrl.policy = null;
                    ctrl.isLoading = false;
                    ctrl.state = 'view';
                });
            }

            function destroy() {
                
            }

            function revoke() {
                var opts = {
                    message: $filter('translate')('POLICY.REVOKE.CONFIRM_MESSAGE'),
                    icon: '/Content/Graphics/Emoji/svg/1f97a.svg'
                };

                ConfirmPopupService.open(opts).then(function () {
                    // Confirmed
                    $http.post('/Profile/RevokePolicy/' + ctrl.policy.PolicyToken).then(function (response) {
                        // Show message
                        ToastFactory.successTranslated('POLICY.REVOKE.IN_PROGRESS');
                        // Log out the user
                        Page.stateGo('logout');
                        ctrl.state = 'revoke';
                    }, function () {
                        ToastFactory.errorTranslated('ERROR.GENERAL');
                        ctrl.state = 'view';
                    });
                    ctrl.state = 'revoke';
                }, function () {
                    // Declined
                    ctrl.isLoading = false;
                });
            }
        }
    }

)();