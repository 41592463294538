(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('contact', {
                    parent: 'base',
                    url: '/contact/:token',
                    templateUrl: '/Contact',
                    resolve: {
                        contactList: ['$http', 'base', function ($http, base) {
                            if (base) {
                                return $http.get('/Contact/GetContacts');
                            }
                            return;
                        }]
                    },
                    controller: 'ContactController as ContactCtrl',
                    bindings: {
                        searchString: '<'
                    }
                });
        }]);

    app.controller('ContactController', ['$element', '$timeout', '$rootScope', '$scope', '$state', '$http',
        '$stateParams', 'Page', 'contactList', '$log', '$translate', 'ProfileViewerService', 'DepartmentViewerService',
        'events', 'ModuleSearchService', 'TemplateFactory', 'ContactFactory',
        function ($element, $timeout, $rootScope, $scope, $state, $http, $stateParams, Page, contactList, $log,
                  $translate, ProfileViewerService, DepartmentViewerService, events, ModuleSearchService, TemplateFactory, ContactFactory) {
            var ctrl = this;
            ctrl.Page = Page;

            $scope.$on('$destroy', destroy);

            // Run Init
            Init();

            function Init() {
                if ($stateParams.backState != null) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                Page.setTitle(contactList.data.PageTitle);

                $scope.settings = Page.getSettings();

                $scope.userContactsToDisplay = 20;
                $scope.searchContactsTodisplay = 20;
                $scope.moreContactUsers = true;
                $scope.moreSearchUsers = true;

                ctrl.settings = contactList.data.Settings;
                ctrl.myStore = contactList.data.MyStore;
                ctrl.stores = contactList.data.Stores;
                ctrl.users = null;
                ctrl.onSearch = onSearch;
                ctrl.onClean = onClean;
                ctrl.isNative = TemplateFactory.isNative();


                ctrl.searchSettings = {
                    onModelChange: ctrl.onSearch,
                    model: ctrl.searchString
                };

                ModuleSearchService.enableSearch(ctrl.searchSettings);


                // Set Headlines
                if (ctrl.settings.UserDepartmentHeadline.length < 1) {
                    $translate('CONTACT.MY_STORE').then(function (translation) {
                        ctrl.settings.UserDepartmentHeadline = translation;
                    });
                }
                if (ctrl.settings.DepartmentHeadline.length < 1) {
                    $translate('CONTACT.STORES').then(function (translation) {
                        ctrl.settings.DepartmentHeadline = translation;
                    });
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }

            // Methods
            function OpenProfileCard(contact) {
                ProfileViewerService.showProfileCard(contact.UserToken);
            }

            function OpenDepartmentCard(department) {
                DepartmentViewerService.showDepartmentCard(department.DepartmentToken);
            }

            function ToggleAccordion(item, $event) {
                if (!item.active && !item.isUsersLoaded) {
                    item.isLoading = true;
                    loadUsers(item.DepartmentToken).then(function (users) {
                        item.Users = users;
                        item.isUsersLoaded = true;
                        item.isLoading = false;
                    });
                }
                item.active = !item.active;
                angular.element(event.currentTarget).parent().find('.accordion-content')[0].inert = !item.active;
            }

            function loadMore(type) {
                if (type === 1 && $scope.moreContactUsers) {
                    $scope.userContactsToDisplay += 20;
                    $scope.moreContactUsers = $scope.userContactsToDisplay <= ctrl.myStore.length;
                } else if (type === 2 && $scope.moreSearchUsers && ctrl.items) {
                    if ($scope.searchContactsTodisplay < ctrl.items.length) {
                        $scope.searchContactsTodisplay = $scope.searchContactsTodisplay + 20 < ctrl.items.length ?
                            $scope.searchContactsTodisplay + 20 : ctrl.items.length;
                    } else if ($scope.userContactsToDisplay <= ctrl.relatedItems.length) {
                        $scope.userContactsToDisplay = $scope.userContactsToDisplay + 20 < ctrl.relatedItems.length ?
                            $scope.userContactsToDisplay + 20 : ctrl.relatedItems.length;
                    }
                    $scope.moreSearchUsers = (
                        (ctrl.items.length < $scope.searchContactsTodisplay ? ctrl.items.length : $scope.searchContactsTodisplay)
                        + (ctrl.relatedItems.length < $scope.userContactsToDisplay ? ctrl.relatedItems.length : $scope.userContactsToDisplay)) <
                        (ctrl.items.length + ctrl.relatedItems.length);
                }
            }

            function loadUsers(departmentToken) {
                return $http.get('/Contact/LoadUsers/' + departmentToken).then(function (resp) {
                    return resp.data.Users
                })
            }

            function onClean() {
                ctrl.items = null;
                ctrl.relatedItems = null;
                $scope.userContactsToDisplay = 20;
                $scope.searchContactsTodisplay = 20;
                $scope.moreContactUsers = true;
                $scope.moreSearchUsers = true;
                ctrl.searchString = '';
            }

            function destroy() {
                ModuleSearchService.disableSearch();
            }

            // Bindings
            $scope.getContactDefaultPhone = function (contact) {
                if (!ctrl.settings.ShowMyStoreContactInfo) {
                    return " ";
                }
                if (contact.ShareBusinessPhone && contact.BusinessPhone.length > 0) {
                    return contact.BusinessPhone;
                }
                if (contact.SharePhone && contact.Phone.length > 0) {
                    return contact.Phone;
                }

                return " ";
            };

            $scope.openProfileCard = OpenProfileCard;
            $scope.openDepartmentCard = OpenDepartmentCard;
            $scope.loadMore = loadMore;
            ctrl.toggleAccordion = ToggleAccordion;
        }
    ]);
})();
