(() => {
    'use strict';

    angular
        .module('App')
        .component('completionsPopup', {
            template: require('./CompletionsPopupComponent.html'),
            controller: ['$element', 'ServiceFormRestService', 'PopupWrapperService', CompletionsPopupController],
            controllerAs: 'ctrl',
            bindings: {
                completions: '<',
                serviceFormToken: '<'
            }
        });


    function CompletionsPopupController($element, ServiceFormRestService, PopupWrapperService) {
        let ctrl = this, popup;

        ctrl.popupId = 'CompletionsPopupId';

        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.openReport = openReport;
        ctrl.showReportButton = showReportButton;

        function close() {
            popup.remove();
            $element.remove();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }

        function openReport(user) {
            ServiceFormRestService.getFillingData(user.ServiceFormFillingToken, true).then(openFillingPopup)
        }

        function showReportButton(user) {
            return user.ServiceFormFillingToken;
        }

        function openFillingPopup(filling) {
            //ServiceFormRenderService.setLabelSettings(data.LabelSettings);
            PopupWrapperService.createDynamic(
                '<filling-preview-popup on-popup-registered="registeredCallback" service-form-token="serviceFormToken" on-close="close" is-preview="true"></filling-preview-popup>',
                {
                    registeredCallback: function (popup) {
                        popup.open(filling);
                    },
                    serviceFormToken: ctrl.serviceFormToken,
                    close: function (popup, element) {
                        popup.remove();
                        element.remove();
                    }
                }
            )
        }
    }
})();


