(function () {
    'use strict';

    angular
        .module('App')
        .component('contentSocialSharing', {
            template: require('./ContentSocialSharingComponent.html'),
            controller: ['$http', '$timeout', '$element', '$window', 'ToastFactory', 'Profile', 'ConfirmPopupService',
                'Page', 'NativeFactory', ContentSocialSharingController],
            controllerAs: 'ctrl',
            bindings: {
                post: '=',
                accountModuleToken: '<',
                settings: '<'
            }
        });

    function ContentSocialSharingController($http, $timeout, $element, $window, ToastFactory, Profile,
                                            ConfirmPopupService, Page, NativeFactory) {
        var ctrl = this;

        ctrl.linkedin = {};
        ctrl.profile = Profile.getProfile();

        ctrl.$onInit = init;
        ctrl.postLinkedin = postLinkedin;
        ctrl.shareLinkedin = shareLinkedin;
        ctrl.shareFacebook = shareFacebook;
        ctrl.closeLinkedinPopup = closeLinkedinPopup;
        ctrl.toggleSharingDialog = toggleSharingDialog;

        function init() {
            $window.FacebookAppId = ctrl.settings.FacebookAppId;
        }

        function shareLinkedin() {
            ctrl.showLinkedinPopup = true;
            ctrl.loading = true;

            $http.get('/SocialMediaSharing/GetLinkedInSharePreview?accountModuleToken=' + ctrl.accountModuleToken + '&moduleItemId=' + ctrl.post.ContentId)
                .then(function (resp) {
                    if (resp.data.AuthFlowRequired) {
                        window.location.href = '/SocialMediaSharing/InitiateLinkedInAuthFlow?accountModuleToken='
                            + ctrl.accountModuleToken + '&redirectUrl=' + encodeURIComponent(window.location.href);
                    } else {
                        ctrl.loading = false;
                        ctrl.linkedin.Body = resp.data.Body;
                        ctrl.linkedin.Title = resp.data.Title;
                        ctrl.linkedin.ImageUrl = resp.data.ImageUrl;
                        ctrl.linkedin.UserMentionsProposal = resp.data.UserMentionsProposal;

                        $timeout(function () {
                            const scrolledElement = $element.find('.scroll-container').first().get(0),
                                textarea = $element.find('textarea')[0];
                            if (scrolledElement) {
                                bodyScrollLock.disableBodyScroll(scrolledElement);
                            }
                            textarea.style.height = (textarea.scrollHeight) + 'px';
                        });
                    }
                });
        }

        function shareFacebook() {
            if (Page.getSettings().NativeSettings.IsNativeWrapper && NativeFactory.hasMethod('sharing.shareOnFacebook')) {
                NativeFactory.sharing
                    .shareOnFacebook(resp => {
                            if (resp.status == 1 && resp.data.Status === 'success') {
                                facebookCallback();
                            }
                        },
                        ctrl.post.SocialMediaSharing.Url,
                        ctrl.post.SocialMediaSharing.FacebookRemarks
                    );
            } else {
                FB.ui({
                    method: 'share',
                    quote: ctrl.post.SocialMediaSharing.FacebookRemarks,
                    href: ctrl.post.SocialMediaSharing.Url,
                }, function (response) {
                    if (response) {
                        facebookCallback();
                    }
                });
            }
        }

        function postLinkedin() {
            $http.post('/SocialMediaSharing/ShareLinkedIn', {
                accountModuleToken: ctrl.accountModuleToken,
                moduleItemId: ctrl.post.ContentId,
                userMentionsProposal: ctrl.linkedin.UserMentionsProposal
            }).then(function (resp) {
                ctrl.post.SocialMediaSharing = resp.data;
                closeLinkedinPopup();
                toggleSharingDialog(false);

                ConfirmPopupService.open({
                    title: 'CONTENT.SHARE.POST_SHARED_TITLE',
                    message: 'CONTENT.SHARE.POST_SHARED_MESSAGE',
                    yesText: 'CONTENT.SHARE.GOT_IT',
                    isAlert: true
                })
            }).catch(function (resp) {
                ToastFactory.error(resp.data.ErrorMessage);
            });
        }

        function facebookCallback() {
            $http.post('/SocialMediaSharing/ShareFacebook', {
                accountModuleToken: ctrl.accountModuleToken,
                moduleItemId: ctrl.post.ContentId
            }).then(function (resp) {
                ctrl.post.SocialMediaSharing = resp.data;
            }).catch(function (resp) {
                ToastFactory.error(resp.data.ErrorMessage);
            });

            toggleSharingDialog(false);
            ConfirmPopupService.open({
                title: 'CONTENT.SHARE.POST_SHARED_TITLE',
                message: 'CONTENT.SHARE.POST_SHARED_MESSAGE',
                yesText: 'CONTENT.SHARE.GOT_IT',
                isAlert: true
            })
        }

        function closeLinkedinPopup() {
            var scrolledElement = $element.find('.scroll-container').first().get(0);
            bodyScrollLock.enableBodyScroll(scrolledElement);

            ctrl.showLinkedinPopup = false
        }

        function toggleSharingDialog(show) {
            ctrl.showSharingDialog = show;
            if (show) {
                $timeout(function () {
                    var scrolledElement = $element.find('.sharing-dialog-popup').first().get(0);
                    bodyScrollLock.disableBodyScroll(scrolledElement);
                });
            } else {
                var scrolledElement = $element.find('.sharing-dialog-popup').first().get(0);
                bodyScrollLock.enableBodyScroll(scrolledElement);
            }

        }
    }
})();


