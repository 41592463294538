(() => {
    'use strict';

    angular
        .module('App')
        .component('sorting', {
            template: require('./SortingComponent.html'),
            controllerAs: 'ctrl',
            controller: [SortingController],
            bindings: {
                sortType: '=?',
                onSortChange: '<',
                options: '<',
                label: '@',
                sortingTitle: '@',
            }
        })

    function SortingController() {}
})();