(function () {
    'use strict';

    var app = angular.module('App');

    app.directive('isInert', [function () {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                $attrs.$observe('isInert', function (val) {
                    var isInert = val === 'true' ? true : false;
                    $element.get(0).inert = isInert;
                });
            }
        }
    }]);
})();