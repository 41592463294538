(() => {
    'use strict';

    angular
        .module('App')
        .directive('postReadRegistration', ['$timeout', 'PostReadRegistrationService', '$stateParams',
            'NotificationFactory', 'ContentWrapperService', 'ResponsiveService',
            function ($timeout, PostReadRegistrationService, $stateParams, NotificationFactory, ContentWrapperService,
                      ResponsiveService) {
                return {
                    restrict: 'A',
                    link: function (scope, element, attrs) {
                        var timeout,
                        storedToken = _.get(scope, '$parent.ctrl.postsData.ModuleToken') || $stateParams.token,
                        isLiveTile = attrs.isLiveTile === 'true',
                            initTrack, postId, parentContainer, footerComponent;

                        switch (attrs.postType) {
                            case 'social':
                                initTrack = !scope.post.IsRead && scope.post.TrackViews;
                                postId = scope.post.SocialPostId;
                                footerComponent = $('.social-footer');
                                if (isLiveTile) {
                                    parentContainer = element.parents('social-wall');
                                }
                                break
                            case 'content':
                                initTrack = !scope.content.IsSeen && attrs.readRegistrationEnabled === 'true';
                                postId = scope.content.ContentId;
                                footerComponent = $('.content-component-footer');
                                if (isLiveTile) {
                                    parentContainer = element.parents('.content-scroll-container');
                                }
                                break
                        }


                        if (initTrack) {
                            $timeout(function () {
                                initRegistration();
                            }, 2000)
                        }

                        function initRegistration() {
                            //TODO: update to intersection observer
                            PostReadRegistrationService.onScroll(function () {
                                $timeout.cancel(timeout);
                                timeout = $timeout(() => {
                                    if (shouldRegister()) {
                                        PostReadRegistrationService.offScroll(element, parentContainer);
                                        PostReadRegistrationService
                                            .registerRead(postId, storedToken, attrs.postType)
                                            .then(response => {
                                                if (attrs.postType === 'social') {
                                                    scope.post.IsRead = true;
                                                    scope.post.TotalViews = response.data.TotalViews;
                                                    NotificationFactory.markNotifications(response.data, false);
                                                }
                                                if (attrs.postType === 'content') {
                                                    scope.content.IsSeen = true;
                                                    if (scope.content.updateBadges) {
                                                        scope.content.updateBadges(response.data, scope.content);
                                                    }
                                                    if (scope.content.onContentRead) {
                                                        scope.content.onContentRead(
                                                            scope.content.ContentCategoryToken, response.data.Subtract
                                                        );
                                                    }
                                                    scope.content.TotalViews = response.data.TotalViews;
                                                    ContentWrapperService.addContentToExclude(postId);
                                                }
                                            })
                                            .catch(function (err) {
                                                console.log(err)
                                            });
                                    }
                                }, 2000);


                            }, element, parentContainer);
                        }

                        function shouldRegister() {
                            return (parentContainer && isContainerInWindow(parentContainer) && isScrolledIntoView(element))
                                || (!parentContainer && isScrolledIntoView(element))
                        }

                        function isScrolledIntoView(elem) {
                            var docViewTop = getViewTop(),
                                containerHeight = getContainerHeight(),
                                docViewBottom = docViewTop + containerHeight,

                                elemTop = $(elem).offset().top,
                                elemBottom = elemTop + $(elem).height();

                            if ($(elem).height() > containerHeight) {
                                return elemTop <= docViewTop && elemBottom >= docViewBottom ||
                                    (elemTop >= docViewTop && elemTop < docViewBottom && elemBottom >= docViewBottom) ||
                                    elemTop <= docViewTop && elemBottom <= docViewBottom;
                            } else {
                                return (elemBottom <= docViewBottom) && (elemTop >= docViewTop);
                            }
                        }

                        function getViewTop() {
                            return (parentContainer && parentContainer.offset().top) || $(window).scrollTop()
                                + $('.base-top').height();
                        }

                        function getContainerHeight() {
                            var height = parentContainer && $(parentContainer).height();

                            if (!height) {
                                height = (ResponsiveService.isDesktop() ?
                                    $(window).height() - (footerComponent && footerComponent.height() || 0) :
                                    $(window).height() - ($('.base-bottom').height() || 0)) - $('.base-top').height()
                            }

                            return height;
                        }

                        function isContainerInWindow(container) {
                            const docViewTop = $(window).scrollTop(),
                                  docViewBottom = docViewTop + $(window).height(),
                        
                                  containerTop = $(container).offset().top,
                                  containerBottom = containerTop + $(container).height();
                        
                            const isContainerFullyVisible = containerBottom <= docViewBottom && containerTop >= docViewTop,
                                  isContainerPartiallyVisible = containerBottom > docViewBottom && containerTop < docViewBottom;
                        
                            return isContainerFullyVisible || isContainerPartiallyVisible;
                        }
                        
                        scope.$on('$destroy', function () {
                            PostReadRegistrationService.offScroll(element, parentContainer);
                            $timeout.cancel(timeout);
                        })
                    }
                }
            }
        ]);
})();