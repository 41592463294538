(function () {
    'use strict';

    angular.module('App')
        .factory('IconService', ['MODULE_ID', function (MODULE_ID) {
            var service = {
                getIconByModuleId: getIconByModuleId,
                getClassByModuleId: getClassByModuleId
            };

            function getIconByModuleId(moduleId) {
                switch (moduleId) {
                    case MODULE_ID.LOGIN:
                        return 'login';
                    case MODULE_ID.CONTENT:
                    case MODULE_ID.CONTENT_COMMENT:
                        return 'content';
                    case MODULE_ID.CONTENT_SOCIAL_SHARE_FACEBOOK:
                    case MODULE_ID.CONTENT_SOCIAL_SHARE_LINKEDIN:
                        return 'share';
                    case MODULE_ID.SOCIAL:
                    case MODULE_ID.SOCIAL_POST:
                        return 'comment';
                    case MODULE_ID.SOCIAL_POST_COMMENT:
                        return 'comment';
                    case MODULE_ID.SOCIAL_POST_LIKE:
                        return 'thumbs-up';
                    case MODULE_ID.SOCIAL_HASHTAGS:
                        return 'hashtag';
                    case MODULE_ID.FORM:
                        return 'tasks';
                    case MODULE_ID.SERVICE_FORM:
                        return 'tasks';
                    case MODULE_ID.SURVEY:
                        return 'survey';
                    case MODULE_ID.WORK_MOOD:
                        return 'workmood';
                    case MODULE_ID.IFRAME:
                        return 'iframe';
                    case MODULE_ID.DUTYPLAN:
                        return 'dutyplan';
                    case MODULE_ID.MEMBERCARD:
                        return 'membercard';
                    case MODULE_ID.CALENDAR:
                        return 'calendar';
                    case MODULE_ID.WALKTHROUGH:
                        return 'walkthrough';
                    case MODULE_ID.POLICY:
                        return 'policy';
                    case MODULE_ID.TRAINING:
                    case MODULE_ID.TRAINING_MODULE:
                    case MODULE_ID.TRAINING_COURSE_LESSON:
                        return 'training';
                    case MODULE_ID.LOGOUT:
                        return 'logout';
                    case MODULE_ID.TASK_MANAGEMENT:
                    case MODULE_ID.TASK_MANAGEMENT_COMPLETION:
                        return 'task-management'
                }
            }
            
            function getClassByModuleId(moduleId) {
                switch (moduleId) {
                    case MODULE_ID.LOGIN:
                        return 'login';
                    case MODULE_ID.CONTENT:
                        return 'content'
                    case MODULE_ID.CONTENT_COMMENT:
                        return 'content-comment';
                    case MODULE_ID.CONTENT_SOCIAL_SHARE_FACEBOOK:
                        return 'share-facebook'
                    case MODULE_ID.CONTENT_SOCIAL_SHARE_LINKEDIN:
                        return 'share-linkedin';
                    case MODULE_ID.SOCIAL:
                        return 'social'
                    case MODULE_ID.SOCIAL_POST:
                        return 'social-post';
                    case MODULE_ID.SOCIAL_POST_COMMENT:
                        return 'social-comment';
                    case MODULE_ID.SOCIAL_POST_LIKE:
                        return 'social-like';
                    case MODULE_ID.SOCIAL_HASHTAGS:
                        return 'hashtag';
                    case MODULE_ID.FORM:
                        return 'form';
                    case MODULE_ID.SERVICE_FORM:
                        return 'service-form';
                    case MODULE_ID.SURVEY:
                        return 'survey';
                    case MODULE_ID.WORK_MOOD:
                        return 'workmood';
                    case MODULE_ID.IFRAME:
                        return 'iframe';
                    case MODULE_ID.DUTYPLAN:
                        return 'dutyplan';
                    case MODULE_ID.MEMBERCARD:
                        return 'membercard';
                    case MODULE_ID.CALENDAR:
                        return 'calendar';
                    case MODULE_ID.WALKTHROUGH:
                        return 'walkthrough';
                    case MODULE_ID.POLICY:
                        return 'policy';
                    case MODULE_ID.TRAINING:
                        return 'training'
                    case MODULE_ID.TRAINING_MODULE:
                        return 'training-module'
                    case MODULE_ID.TRAINING_COURSE_LESSON:
                        return 'training-course-lesson';
                    case MODULE_ID.LOGOUT:
                        return 'logout';
                    case MODULE_ID.TASK_MANAGEMENT:
                        return 'task-management'
                    case MODULE_ID.TASK_MANAGEMENT_COMPLETION:
                        return 'task-management-completion'
                }
            }

            return service;
        }])
})();