(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('firework', ['$timeout',
        function ($timeout) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/Firework.tpl.html',
                scope: {
                    //time: '@',
                    //fires: '@'
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {

                    // Default values
                    $scope.time = (typeof $scope.time == 'undefined') ? 5000 : $scope.time;
                    $scope.fires = (typeof $scope.fires) ? 10 : $scope.fires;

                    // Init
                    InitDirective()
                    function InitDirective() {

                        var parent = $($element).parent();
                        var width = $(parent).width();
                        var height = $(parent).height();

                        var colors = ['FF7700', 'FF4433', 'FF00FF', '00FF00', '0072FF', 'FFFF00'];
                        $scope.fireworks = [];

                        // Create
                        for (var i = 0; i < $scope.fires; i++) {
                            var w = (Math.random() * (width - 50)) + 25;
                            var h = (Math.random() * (height - 100)) + 100;

                            var colorIndex = (i >= colors.length) ? (i % colors.length) : i;
                            try{
                                var f = new CFirework("fireworks", colors[colorIndex], w, h);
                                f.useAudio = false;
                                f.timeBeforeExplosion = 600;
                                $scope.fireworks.push(f);
                            }
                            catch(ex){}
                        }

                        // Start
                        Start();
                    }

                    // Methods
                    function Start() {
                        // Start
                        for (var i = 0; i < $scope.fireworks.length; i++) {
                            var f = $scope.fireworks[i];
                            var timePart = ($scope.time / $scope.fireworks.length);
                            var time = (timePart * (i + 1)) - timePart;
                            FireIn(f, time);
                        }
                    }

                    function FireIn(firework, time) {
                        setTimeout(function () {
                            firework.start();
                        }, time);
                    }
                   
                }]
            }
        }
    ]);
})();