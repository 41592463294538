(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('EmptyController', ['Page', '$translate', 'events', '$scope',
        function (Page, $translate, events, $scope) {
            var ctrl = this;
            ctrl.Page = Page;

            $translate('NO_CONTENT.PAGE_TITLE').then(function (translation) {
                Page.setTitle(translation);
            });

            Page.showBackButton();

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);

})();