(() => {
    'use strict';

    angular.module('App')
        .component('contentPostTotalSwipes', {
            template: require('./ContentPostTotalSwipesComponent.html'),
            controller: ['$filter', 'PostReadRegistrationService', 'MODULE_ID', ContentPostTotalSwipesController],
            controllerAs: 'ctrl',
            bindings: {
                token: '<',
                totalSwipes: '<',
                isShort: '<',
                moduleToken: '<' 
            }
        });

    function ContentPostTotalSwipesController($filter, PostReadRegistrationService, MODULE_ID) {
        const ctrl = this;
        let title;

        ctrl.$onInit = init;
        ctrl.showReads = showReads;

        function init() {
            title = ctrl.totalSwipes === 1 ? (1 + ' ' + $filter('translate')('CONTENT.SWIPE')) :
                (ctrl.totalSwipes + ' ' + $filter('translate')('CONTENT.SWIPES'));
        }

        function showReads() {
            PostReadRegistrationService.showPopup(ctrl.token, title, MODULE_ID.CONTENT, 'swipes', ctrl.moduleToken);
        }
    }
})();