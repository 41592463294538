(() => {
    'use strict';

    angular.module('App')
        .component('profileSettingsCommunication', {
            template: require('./ProfileSettingsCommunicationComponent.tpl.html'),
            controller: ['ProfileSettingsService', 'Page', ProfileSettingsCommunicationComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsCommunicationComponentController(ProfileSettingsService, Page) {
        var ctrl = this;

        ctrl.$onInit = onInit;

        function onInit() {
            ctrl.isLoadingProfileData = true;

            ProfileSettingsService.getFullProfile().then(res => {
                ctrl.allowSms = ProfileSettingsService.createToggleProperty(res, 'AllowSmsNotification');
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }
    }
})();