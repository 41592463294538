(function () {
    'use strict';

    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('notifications', {
                    parent: 'base',
                    url: '/notifications',
                    template: '<notification-viewer></notification-viewer>',
                    controller: 'NotificationsController as ctrl'
                });
        }
    ]);

    app.controller("NotificationsController", ['$scope', 'Page', '$translate', '$state', 'TemplateFactory',
        function ($scope, Page, $translate, $state, TemplateFactory) {
            $translate('NOTIFICATION.TITLE').then(function (translation) {
                Page.setTitle(translation);
            });

            if ($state.params.backState) {
                Page.showBackButton($state.params.backState.state, $state.params.backState.token, $state.params.backState.extra);
            } else if (TemplateFactory.getTemplate().key !== 'native') {
                Page.showBackButton('home');
            }
        }
    ]);
})();