(() => {
    'use strict';
    angular
        .module('App')
        .component('notificationsSettings', {
            template: require('./NotificationsSettings.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$translate', '$stateParams', 'Page', notificationsSettingsController],
            bindings: {
                settings: '<',
                onClose: '<',
                isSettingsPage: '<',
                isLoading: '<'
            }
        });


    function notificationsSettingsController($element, $timeout, $translate, $stateParams, Page) {
        const ctrl = this;

        ctrl.closeModuleSettings = closeModuleSettings;
        ctrl.onModuleOpen = onModuleOpen;
        ctrl.close = close;

        function onModuleOpen(module) {
            ctrl.showModuleSettings = true;
            if (!ctrl.isSettingsPage) {
                bodyScrollLock.enableBodyScroll($element.find('.scroll-container').first().get(0));
                $timeout(() => {
                    bodyScrollLock.disableBodyScroll($element.find('.scroll-container').first().get(0));
                })
            }
            ctrl.module = module;

            if (ctrl.isSettingsPage) {
                Page.setTitle(ctrl.module.AccountModuleName);
                Page.showBackButtonFunction(closeModuleSettings)
            }
        }

        function closeModuleSettings() {
            ctrl.showModuleSettings = false;
            if (!ctrl.isSettingsPage) {
                bodyScrollLock.enableBodyScroll($element.find('.scroll-container').first().get(0));
                $timeout(() => {
                    bodyScrollLock.disableBodyScroll($element.find('.scroll-container').first().get(0));
                })
            }

            if (ctrl.isSettingsPage) {
                $translate('NOTIFICATION.NOTIFICATION_SETTINGS').then(function (translation) {
                    Page.setTitle(translation);
                });
                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }
            }
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
        }
    }
})();