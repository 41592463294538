(function () {
    'use strict';

    angular.module('App')
        .component('setReminderPopup', {
            templateUrl: '/Scripts/Components/Reminders/SetReminderPopup/SetReminderPopupComponent.html',
            controllerAs: 'ctrl',
            controller: ['$element', '$filter', 'toastr', 'RemindersService', SetReminderPopupController],
            bindings: {
                accountModuleToken: '<',
                entityId: '<',
                moduleId: '<',
                remindTime: '<',
                updateReminder: '<'
            }
        })

    function SetReminderPopupController($element, $filter, toastr, RemindersService) {
        var ctrl = this, popup;

        ctrl.popupRegistered = popupRegistered;
        ctrl.setReminder = setReminder;
        ctrl.close = close;

        function popupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function setReminder() {
            var time = moment(ctrl.remindTime).format('YYYY-MM-DD[T]HH:mm:ss');
            RemindersService.setReminder(ctrl.accountModuleToken, ctrl.entityId, ctrl.moduleId, time)
                .then(function (resp) {
                    ctrl.updateReminder && ctrl.updateReminder(resp.data);
                    toastr.success($filter('translate')('REMINDERS.CREATED') + ' ' + RemindersService.getSetReminderString(ctrl.remindTime));
                    close();
                })
                .catch(function (resp) {
                    toastr.error(resp.data.StatusText);
                })
        }

        function close() {
            popup.remove();
            $element.remove();
        }
    }
})();