(function () {
    'use strict';

    angular.module('App')
        .component('cancelRecurringEventPopup', {
            templateUrl: '/Scripts/Components/ScrollCalendar/EventDetails/CancelEventPopup/CancelRecurringEventPopupComponent.html',
            controllerAs: 'ctrl',
            controller: ['$element', CancelRecurringEventPopupController],
            bindings: {
                onAnswer: '<',
                eventName: '@'
            }
        })

    function CancelRecurringEventPopupController($element) {
        var ctrl = this;

        ctrl.openCancelation = openCancellation;
        ctrl.close = close;

        function close() {
            $element.remove()
        }

        function openCancellation(cancelType) {
            close();
            ctrl.onAnswer && ctrl.onAnswer(cancelType);
        }
    }
})();