(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('rankingView', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Ranking/Templates/RankingView.tpl.html',
                scope: {
                    view: '='
                },
                controller: ['$scope', '$element', '$attrs', '$http', 'TimeLocale',
                    function ($scope, $element, $attrs, $http, TimeLocale) {
                        // Init
                        InitDirective();
                        function InitDirective() {
                            $scope.loading = true;
                            $scope.rankingList = [];
                            $scope.Time = TimeLocale;

                            // Listen for tab changes
                            var unregister = $scope.$watch('view.inView', function (newVal) {
                                if (newVal) {
                                    unregister();
                                    LoadView();
                                }
                            })
                        }

                        // Methods
                        function LoadView() {
                            $http.get('/Ranking/GetRankingData?id=' + $scope.view.RankingViewId).then(function (response) {
                                $scope.rankingList = response.data;
                                $scope.loading = false;
                            }, function () {
                                $scope.loading = false;
                            });
                        }
                    }
                ]
            }
        }
    ]);
})();