(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlProgress', {
            template: require('./RelesysProgressComponent.html'),
            controller: ['$scope', RlProgressController],
            controllerAs: 'ctrl',
            bindings: {
                max: '<',
                current: '<',
                showNumbers: '<',
                height: '@'
            }
        });


    function RlProgressController($scope) {
        let ctrl = this;

        ctrl.height = ctrl.height || 10;

        $scope.$watch(updateNumbers, true);

        function updateNumbers() {
            ctrl.percentage = ctrl.max === 0 ? 0 : Math.round(ctrl.current * 100 / ctrl.max);
        }
    }
})();


