(function () {
    'use strict';

    angular
        .module('App')
        .component('readByPopup', {
            template: require('./ReadByPopupComponent.html'),
            controller: ['$scope', '$element', '$http', 'MODULE_ID', ReadByPopupController],
            controllerAs: 'ctrl',
            bindings: {
                moduleId: '<',
                token: '<',
                headerTitle: '<'
            }
        });


    function ReadByPopupController($scope, $element, $http, MODULE_ID) {
        var ctrl = this;

        ctrl.initialLoading = true;

        ctrl.getUsersUrl = {};

        ctrl.$onInit = init;
        ctrl.onClose = onClose;
        ctrl.registeredCallback = registeredCallback;

        function init() {
            getReadUsers(ctrl.token, getUsersUrl(ctrl.moduleId)).then(function (data) {
                ctrl.users = data.Items;
                ctrl.initialLoading = false;
            });
        }

        function getReadUsers(token, url) {
            return $http.post(url, {
                token: token
            }).then(function (resp) {
                return resp.data;
            }).finally(function () {
                ctrl.isLoading = false;
            });
        }

        function getUsersUrl(moduleId) {
            switch (moduleId) {
                case MODULE_ID.SOCIAL:
                    return '/Social/GetReadRegistrations';
                case MODULE_ID.SERVICE_FORM:
                    return '/ServiceForm/GetReadRegistrations'
            }
        }
        
        function registeredCallback(popup) {
            popup.open();
        }

        function onClose(data, popup) {
            $scope.$parent.$destroy();
            popup.remove();
            $element.remove();
        }
    }
})();


