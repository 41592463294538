(() => {
    'use strict';

    angular
        .module('App')
        .constant('PIN_STATUS', {
            VALID: 1,
            INVALID: 2,
            RESET: 3
        })
        .component('pinCodePage', {
            template: require('./PinCodePageComponent.html'),
            controller: ['$timeout', '$stateParams', 'PinCodeService', 'NativeFactory', 'events', '$rootScope', 'Profile', PinCodePageController],
            controllerAs: 'ctrl',
            bindings: {}
        });

    function PinCodePageController($timeout, $stateParams, PinCodeService, NativeFactory, events, $rootScope, Profile) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.onCreatePin = onCreatePin;
        ctrl.onSkipSetPin = onSkipPin;
        ctrl.onSecure = onSecure;

        ctrl.onBiometricAvailable = onBiometricAvailable;
        ctrl.onBiometricSetupClose = onBiometricSetupClose;

        function init() {
            ctrl.settings = PinCodeService.setPinCodeSettings($stateParams);
            initPin();

            // Let the native app know it should show now, but have a small timeout to let the loading transition get time to finish
            $timeout(() => {
                NativeFactory.basic.appIsReady();
            }, 100);
        }

        function initPin() {
            ctrl.secureAction = null;
            ctrl.biometricSetupState = null;
            
            ctrl.showSecurePage = false;
            ctrl.showKeyboard = true;
            
            ctrl.showCreatePinPage = false;

            ctrl.title = 'PIN.ENTER_PIN';

            ctrl.profile = Profile.getCachedProfile();
            if(!ctrl.profile){
                ctrl.profile = Profile.getProfile();
            }

            $rootScope.$broadcast(events.PINCODE_DIDSHOW);
        }

        function onSecure(secureAction) {
            ctrl.secureAction = secureAction;
            ctrl.showSecurePage = true;
        }

        function onBiometricAvailable(userToken, pinCode, nextAction){
            ctrl.biometricSetupState = {
                show: true,
                pinCode: pinCode,
                userToken: userToken,
                nextAction: nextAction
            };
        }
        
        function onBiometricSetupClose(){
            ctrl.biometricSetupState.show = false;
            ctrl.biometricSetupState.nextAction();
        }

        function onCreatePin() {
            ctrl.showCreatePinPage = true;
        }

        function onSkipPin() {
            PinCodeService.skipSetPin().then(function () {
                PinCodeService.loadDataAfterPin().finally(() => {
                    $rootScope.$broadcast(events.PINCODE_DIDHIDE);
                    PinCodeService.goToState();
                });
            });
        }
    }
})();


