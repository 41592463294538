(function () {
    'use strict';

    angular.module('App')
        .component('languageSelectorPopup', {
            template: require('./LanguageSelectorPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', LanguageSelectorPopupController],
            bindings: {
                selectedLanguages: '<',
                languages: '<',
                onUpdate: '<',
                requireUserLanguage: '<',
                moduleToken: '@'
            }
        })

    function LanguageSelectorPopupController($element) {
        var ctrl = this, popup;

        ctrl.$onInit = init;
        ctrl.toggleSelection = toggleSelection;
        ctrl.onRegistered = onRegistered;
        ctrl.close = close;
        ctrl.search = search;

        function init() {
            ctrl.languages = _.map(ctrl.languages, function (language) {
                var isSelected = _.find(ctrl.selectedLanguages, function (selectedLanguage) {
                    return selectedLanguage.CultureId === language.CultureId;
                });

                if (isSelected) {
                    language.selected = true;
                }
                return language
            });

            ctrl.filteredLanguages = ctrl.languages;
        }

        function toggleSelection(language) {
            if(language.selected && ctrl.selectedLanguages.length <= 1){
                return;
            }

            language.selected = !language.selected;

            if (!language.selected) {
                language.text && _.set(language, 'text', '');
                if (language.active) {
                    var activeIndex = _.findIndex(ctrl.selectedLanguages, language);
                    language.active = false
                    ctrl.selectedLanguages[activeIndex > 0 ? activeIndex - 1 : 0].active = true;
                }
            }
            ctrl.selectedLanguages = _.filter(ctrl.languages, {selected: true})
            ctrl.onUpdate && ctrl.onUpdate(ctrl.selectedLanguages);
        }

        function onRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            popup.remove()
            $element.remove();
        }

        function search(string) {
            ctrl.filteredLanguages = _.filter(ctrl.languages, function (language) {
                return language.Name.toLowerCase().indexOf(string.toLowerCase()) !== -1
            });

        }
    }
})();