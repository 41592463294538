(() => {
    'use strict';

    angular
        .module('App')
        .component('taskDetails', {
            template: require('./TaskDetailsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$rootScope', '$scope', '$timeout', '$filter', 'Page', 'ServiceFormRestService',
                'TaskDetailsService', 'BasicHelper', 'ServiceFormRenderService',
                'TaskManagementRenderService', 'TaskManagementRestService', 'Menu', 'ActionSheetService',
                'ConfirmPopupService', 'AssigneesPopupService', 'CompletionsPopupService', 'StatisticsPopupService',
                'TaskManagementCommentsPopupService', 'CreateSubtaskOnlyTitleService', 'ISSUE_STATUS', 'ServiceFormExternalService', 'TranslationService',
                'SubtasksListService', 'ProfileViewerService', TaskDetailsController],
            bindings: {
                issue: '<',
                close: '<',
                moderateAllowed: '<',
                closeOnDelete: '<',
                loading: '<'
            }
        });

    function TaskDetailsController($q, $rootScope, $scope, $timeout, $filter, Page, ServiceFormRestService,
        TaskDetailsService, BasicHelper, ServiceFormRenderService,
        TaskManagementRenderService, TaskManagementRestService, Menu, ActionSheetService,
        ConfirmPopupService, AssigneesPopupService, CompletionsPopupService,
        StatisticsPopupService, TaskManagementCommentsPopupService, CreateSubtaskOnlyTitleService, ISSUE_STATUS,
        ServiceFormExternalService, TranslationService, SubtasksListService, ProfileViewerService) {

        let ctrl = this, taskEditedWatcher, subtaskDeletedWatcher, subtaskCreatedWatcher,
            subtaskCompletedWatcher, subtasksOrderChangedWatcher, taskWasCompletedWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.competeTask = competeTask;
        ctrl.openCheckList = openCheckList;
        ctrl.openCalendarLink = openCalendarLink;
        ctrl.showTaskOptions = showTaskOptions;
        ctrl.openAssignees = openAssignees;
        ctrl.toggleExpandState = toggleExpandState;
        ctrl.openComments = openComments;
        ctrl.openStatisticsPopup = openStatistics;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.showAuthor = showAuthor;
        ctrl.openProfile = openProfile;
        ctrl.openCompletionsPopup = CompletionsPopupService.openCompletionsPopup;
        ctrl.openCreateSubtaskPopup = openCreateSubtaskPopup;
        ctrl.enableSorting = enableSorting;
        ctrl.disableSorting = disableSorting;
        ctrl.reopenTask = reopenTask;
        ctrl.saveSortedSubtasks = saveSortedSubtasks;
        ctrl.updateDefaultSubtasksOrder = updateDefaultSubtasksOrder;
        ctrl.filterSubtasksByStatus = filterSubtasksByStatus;
        ctrl.allSubtasksCompletedCheck = allSubtasksCompletedCheck;
        ctrl.cancelReopen = cancelReopen;
        ctrl.translationAllowed = translationAllowed;
        ctrl.allSubtasksCompleted = false;
        ctrl.sortingEnabled = false;
        ctrl.subtasksSorted = false;
        ctrl.defaultSubtasksOrder = [];
        ctrl.expandedSections = {};
        ctrl.ISSUE_STATUS = ISSUE_STATUS;

        taskEditedWatcher = $rootScope.$on('TaskManagement:taskEdited', (ev, issue, isParentIssue) => {
            if (isParentIssue) {
                ctrl.close && ctrl.close();
            } else {
                ctrl.loading = true;
                ctrl.issue = TaskManagementRenderService.updatePreviewData(ctrl.issue, issue);
                ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);
                ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
                $timeout(() => {
                    ctrl.loading = false;
                })
            }

            $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue, true);
        });

        subtaskDeletedWatcher = $rootScope.$on('TaskManagement:subtaskDeleted', (event, data) => {
            ctrl.issue.Subtasks = ctrl.issue.Subtasks.filter(x => x.IssueSubtaskToken !== data.IssueSubtaskToken);
            ctrl.defaultSubtasksOrder = ctrl.defaultSubtasksOrder.filter(x => x.IssueSubtaskToken !== data.IssueSubtaskToken);
            if (ctrl.issue.Subtasks.length === 0) {
                ctrl.issue.HasSubtasks = false;
                $rootScope.$emit('TaskManagement:taskEdited', ctrl.issue, false);
            }
            ctrl.allSubtasksCompleted = ctrl.allSubtasksCompletedCheck();
        });

        subtaskCompletedWatcher = $rootScope.$on('TaskManagement:subtaskCompleted', (event, data) => {
            ctrl.issue.Subtasks = data;
            ctrl.updateDefaultSubtasksOrder();
            ctrl.issue.Subtasks = ctrl.filterSubtasksByStatus(data);
            ctrl.allSubtasksCompleted = ctrl.allSubtasksCompletedCheck();
        });

        subtaskCreatedWatcher = $rootScope.$on('TaskManagement:subtasksListUpdated', (event, data) => {
            ctrl.issue.Subtasks = data;
            ctrl.updateDefaultSubtasksOrder();
            ctrl.issue.Subtasks = ctrl.filterSubtasksByStatus(data);
            ctrl.allSubtasksCompleted = ctrl.allSubtasksCompletedCheck();
            if (ctrl.issue.Subtasks.length > 0 && !ctrl.issue.HasSubtasks) {
                ctrl.issue.HasSubtasks = true;
                $rootScope.$emit('TaskManagement:taskEdited', ctrl.issue, false);
            }
            if (ctrl.issue.Status === ISSUE_STATUS.COMPLETE && !ctrl.allSubtasksCompleted) {
                ctrl.reopenTask(ctrl.issue.IssueToken);
            }
        });

        subtasksOrderChangedWatcher = $rootScope.$on('TaskManagement:subtasksOrderChanged', (event, data) => {
            ctrl.issue.Subtasks = data;
            ctrl.subtasksSorted = true;
        });

        taskWasCompletedWatcher = $rootScope.$on('TaskManagement:taskWasCompletedError', (event, data) => {
            if (data === ctrl.issue.IssueToken) {
                TaskManagementRenderService.getTaskPreviewData(ctrl.issue).then(function (issue) {
                    ctrl.issue = issue;
                    ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
                    ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);
                    ctrl.loading = false;
                    $rootScope.$emit('TaskManagement:taskEdited', ctrl.issue, false);
                });
            }

        })

        function init() {
            if (ctrl.issue) {
                initIssue();
            } else {
                ctrl.detailsPlaceholderRows = BasicHelper.getPlaceholderRows(8, 25, 100, true);
                $scope.$watch('ctrl.loading', function (newValue, oldValue) {
                    if (!newValue && ctrl.issue !== undefined && newValue !== oldValue) {
                        initIssue();
                    }
                });
            }

            ctrl.rootScopeListener = $rootScope.$on('TaskManagement:assigneesUpdated', updateStatistics);
        }

        function updateStatistics() {
            TaskManagementRenderService.getTaskPreviewStatisticsData(ctrl.issue, ctrl.issue.IssueToken);
        }

        function translationAllowed() {
            return isTranslationAllowed(ctrl.issue.TranslatableEntity) ||
                ctrl.issue.Subtasks?.some(subtask => isTranslationAllowed(subtask.TranslatableEntity)) ||
                ctrl.issue.Categories?.some(category => isTranslationAllowed(category.TranslatableEntity));
        }

        function isTranslationAllowed(translatableEntity) {
            return translatableEntity.AllowAutoTranslation || translatableEntity.LocalizedAlternativeLocale;
        }

        function initIssue() {
            ctrl.downloadLink = window.location.origin + ctrl.issue.DownloadLink;
            ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);
            ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
            ctrl.optionsIsEnabled = ctrl.issue.CanEdit || ctrl.issue.CanDelete || TaskDetailsService.isContentReportingEnabled();
            ctrl.completionRate = getCompletionRate();
            if (ctrl.issue.TranslatableEntity?.PreferTranslatedVersion) {
                ctrl.showOriginal = ctrl.issue.TranslatableEntity.PreferTranslatedVersion;
            }

            if (ctrl.issue.SubtasksEnabled) {
                ctrl.updateDefaultSubtasksOrder();
            }
            ctrl.issue.Subtasks = filterSubtasksByStatus(ctrl.issue.Subtasks);
            ctrl.allSubtasksCompleted = ctrl.allSubtasksCompletedCheck();

            if (ctrl.issue.LoadStatistics) {
                TaskManagementRenderService.getTaskPreviewStatisticsData(ctrl.issue, ctrl.issue.IssueToken);
            }
            $scope.$watch('ctrl.issue.LoadStatistics', function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    TaskManagementRenderService.getTaskPreviewStatisticsData(ctrl.issue, ctrl.issue.IssueToken);
                }
            });
        }

        function competeTask(isComplete) {
            if (isComplete) {
                return TaskManagementRestService.taskComplete(ctrl.issue.IssueToken).then(function (data) {
                    removeFromOverdue();
                    ctrl.issue = TaskManagementRenderService.updatePreviewData(ctrl.issue, data);
                    ctrl.issue.IsCompletedByCurrentUser = true;
                    ctrl.issue.Status = ISSUE_STATUS.COMPLETE;
                    ctrl.issue.CanReopen = true;
                    ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
                    ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);
                    ctrl.issue.Subtasks = filterSubtasksByStatus(ctrl.issue.Subtasks);
                    $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue, true);

                    if (ctrl.issue.NavigationBadge) {
                        Menu.removeActivity(1, "taskmanagement", ctrl.issue.AccountModuleToken, null);
                        Menu.removeLandingBadge(
                            1,
                            ctrl.issue.NavigationBadge.AccountModuleId,
                            ctrl.issue.NavigationBadge.ModuleId,
                            ctrl.issue.NavigationBadge.ModuleItemId);
                    }

                    return true;
                });
            } else {
                return ConfirmPopupService
                    .open({
                        message: $filter('translate')('TASK_MANAGEMENT.TASK.REOPEN'),
                        onClose: ctrl.cancelReopen
                    }).then(function () {
                        return ctrl.reopenTask(ctrl.issue.IssueToken);
                    })
                    .catch(function (error) {
                        ctrl.cancelReopen();
                    });
            }
        }

        function cancelReopen() {
            ctrl.issue.IsCompletedByCurrentUser = true;
        }

        function reopenTask(issueToken) {
            TaskManagementRestService.taskReopen(issueToken).then(function (data) {
                ctrl.issue = TaskManagementRenderService.updatePreviewData(ctrl.issue, data);
                ctrl.issue.IsCompletedByCurrentUser = false;
                ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
                ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);
                ctrl.issue.Subtasks = filterSubtasksByStatus(ctrl.issue.Subtasks);
                $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue, true);

                if (ctrl.issue.NavigationBadge) {
                    Menu.removeActivity(-1, "taskmanagement", ctrl.issue.AccountModuleToken, null);
                    Menu.removeLandingBadge(
                        -1,
                        ctrl.issue.NavigationBadge.AccountModuleId,
                        ctrl.issue.NavigationBadge.ModuleId,
                        ctrl.issue.NavigationBadge.ModuleItemId);
                }
            })
        }

        function openCalendarLink() {
            window.location.href = ctrl.downloadLink;
        }

        function destroy() {
            ServiceFormRenderService.destroyData();
            taskEditedWatcher();
            subtaskDeletedWatcher();
            subtaskCreatedWatcher();
            subtaskCompletedWatcher();
            subtasksOrderChangedWatcher();
            taskWasCompletedWatcher();
        }

        function removeFromOverdue() {
            delete ctrl.issue.IsOverdue;
            $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue, true);
        }

        function openCheckList() {
            ctrl.loadForm = true;
            $q.all({
                formData: ServiceFormRestService.getServiceFormData(ctrl.issue.ServiceFormToken),
                fillingData: ctrl.issue.ServiceFormFillingToken && ServiceFormRestService.getFillingData(ctrl.issue.ServiceFormFillingToken),
            }).then(({ formData, fillingData }) => {
                ctrl.filling = fillingData;

                ServiceFormExternalService.setSettings(formData);

                formData.issueId = ctrl.issue.IssueId;
                formData.NavigationBadge = ctrl.issue.NavigationBadge;
                formData.AccountModuleToken = ctrl.issue.AccountModuleToken;
                formData.ServiceFormAccountModuleToken = ctrl.issue.ServiceFormAccountModuleToken;

                $timeout(function () {
                    if (ctrl.filling) {
                        ctrl.filling.PageNavigation = ctrl.filling.PageNavigation[0].Pages;
                        ServiceFormExternalService.openUpdateFillingPopup(formData, ctrl.filling);
                    } else {
                        ServiceFormExternalService.openCreateFillingPopup(formData);
                    }
                    ctrl.loadForm = false;
                });

                formData.fillingCreated = function (filling) {
                    removeFromOverdue();
                    ctrl.filling = filling;
                    ctrl.issue.IsCompletedByCurrentUser = true;
                    ctrl.issue.Status = ISSUE_STATUS.COMPLETE;

                    $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue, true);

                    TaskManagementRenderService.getTaskPreviewData(ctrl.issue).then(function (issue) {
                        ctrl.issue = issue;
                        ctrl.issueStatus = TaskDetailsService.prepareIssueStatus(ctrl.issue);
                        ctrl.statusClass = TaskDetailsService.getStatusClass(ctrl.issue);

                        if (!ctrl.filling && issue.ServiceFormFillingToken) {
                            ServiceFormRestService.getFillingData(issue.ServiceFormFillingToken).then(function (filling) {
                                ctrl.filling = filling;
                            });
                        }

                        if (formData.NavigationBadge) {
                            Menu.removeActivity(1, "taskmanagement", formData.AccountModuleToken, null);
                            Menu.removeLandingBadge(
                                1,
                                formData.NavigationBadge.AccountModuleId,
                                formData.NavigationBadge.ModuleId,
                                formData.NavigationBadge.ModuleItemId);
                        }
                    });
                };
            })
        }

        function showTaskOptions(e) {
            const options = TaskDetailsService.prepareOptionsButtons(ctrl.issue, ctrl.closeOnDelete, ctrl.close);

            ActionSheetService
                .create(options, e.currentTarget)
                .show();
        }

        function openAssignees() {
            if (ctrl.issue.Status === ISSUE_STATUS.COMPLETE) {
                return false;
            }

            AssigneesPopupService.openAssigneesPopup({
                token: ctrl.issue.IssueToken,
                selectedUsers: _.clone(ctrl.issue.Assignees),
                canModerate: ctrl.issue.CanModerate,
                privateTask: ctrl.issue.PrivateTask,
                onSave: onSaveAssignees
            })
        }

        function onSaveAssignees(selectedUsers) {
            ctrl.issue.Assignees = selectedUsers;
            $rootScope.$emit('TaskManagement:taskUpdated', ctrl.issue);
            $rootScope.$emit('TaskManagement:assigneesUpdated', ctrl.issue, true);
        }

        function toggleExpandState(section) {
            ctrl.expandedSections[section] = !ctrl.expandedSections[section];
        }

        function openComments() {
            TaskManagementCommentsPopupService.openCommentsPopup(ctrl.issue, {});
        }

        function openCreateSubtaskPopup() {
            if (ctrl.issue.IsRecurringChild) {
                ConfirmPopupService.open({
                    message: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.CREATE_SERIES_QUESTION'),
                    yesText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.ONE'),
                    noText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.SERIES')
                })
                .then(() => openConfirmCreateSubtaskPopup(null, ctrl.issue.IssueToken))
                .catch(() => openConfirmCreateSubtaskPopup(ctrl.issue.ParentIssueToken, ctrl.issue.IssueToken))
            } else {
                openConfirmCreateSubtaskPopup(null, ctrl.issue.IssueToken);
            }
        }

        function openConfirmCreateSubtaskPopup(parentIssueToken, issueToken) {
            CreateSubtaskOnlyTitleService.openCreateSubtaskOnlyTitlePopup({
                ParentIssueToken: parentIssueToken,
                IssueToken: issueToken
            });
        }

        function getCompletionRate() {
            if (!ctrl.issue.Statistics || !ctrl.issue.Statistics.BasicStatistics) return 0;
            return StatisticsPopupService
                .getCompletionRate(ctrl.issue.Statistics.BasicStatistics.CompleteCount, ctrl.issue.Statistics.BasicStatistics.IncompleteCount);

        }

        function openStatistics() {
            if (!ctrl.issue.LoadStatistics && ctrl.issue.Statistics.BasicStatistics !== undefined) {
                StatisticsPopupService.openStatisticsPopup(
                    ctrl.issue.Statistics.BasicStatistics.IsIndividualTask ?
                        StatisticsPopupService.prepareUsersList(ctrl.issue.Statistics.DetailedUserStatistics) :
                        StatisticsPopupService.prepareDepartmentsList(ctrl.issue.Statistics.DetailedDepartmentStatistics),
                    ctrl.issue.Statistics.BasicStatistics.HasEndTimePassed,
                    ctrl.issue.CompletedOutsideUserHierarchy
                );
            }
        }

        function toggleTranslation() {
            if (TaskDetailsService.isTaskTranslated(ctrl.issue)) {
                ctrl.showOriginal = !ctrl.showOriginal;
            } else {
                TranslationService.translateTaskToUserCulture(ctrl.issue.IssueId)
                    .then(translation => {
                        if (translation) {
                            ctrl.issue = {
                                ...ctrl.issue,
                                TranslatableEntity: translation.Issue
                            };

                            if (translation.Categories?.length) {
                                ctrl.issue.Categories.forEach(category => {
                                    const translatedCategory = translation.Categories.find(x => x.LocalizedLocale.IssueCategoryId === category.IssueCategoryId);
                                    if (translatedCategory) {
                                        category.TranslatableEntity = translatedCategory;
                                    }
                                })
                            }

                            if (translation.Subtasks?.length) {
                                ctrl.issue.Subtasks.forEach(subtask => {
                                    const translatedSubtask = translation.Subtasks.find(x => x.LocalizedLocale.IssueSubtaskId === subtask.TranslatableEntity.LocalizedLocale.IssueSubtaskId);
                                    if (translatedSubtask) {
                                        subtask.TranslatableEntity = translatedSubtask;
                                    }
                                })
                                $rootScope.$emit('TaskManagement:subtasksListUpdated', ctrl.issue.Subtasks);
                            }
                            ctrl.showOriginal = !ctrl.showOriginal;
                        } else {
                            ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                        }
                    })
            }
        }

        function saveSortedSubtasks() {
            const tokensArray = ctrl.issue.Subtasks.map(x => x.IssueSubtaskToken);
            SubtasksListService.sortSubtasks(ctrl.issue.IssueToken, tokensArray).then(resp => {
                $rootScope.$emit('TaskManagement:subtasksOrderChanged', resp.data);
                ctrl.updateDefaultSubtasksOrder();
                ctrl.issue.Subtasks = filterSubtasksByStatus(ctrl.issue.Subtasks);
            });
            ctrl.sortingEnabled = false;
        }

        function enableSorting() {
            ctrl.sortingEnabled = true;
            ctrl.subtasksSorted = false;
            ctrl.issue.Subtasks = [...ctrl.defaultSubtasksOrder];
        }

        function disableSorting() {
            ctrl.sortingEnabled = false;
            ctrl.subtasksSorted = false;
            ctrl.issue.Subtasks = [...ctrl.defaultSubtasksOrder];
            ctrl.issue.Subtasks = filterSubtasksByStatus(ctrl.issue.Subtasks);
        }

        function updateDefaultSubtasksOrder() {
            ctrl.defaultSubtasksOrder = [...ctrl.issue.Subtasks];
        }

        function filterSubtasksByStatus(subtasks) {
            return _.sortBy(subtasks, 'IsCompleted');
        }

        function allSubtasksCompletedCheck() {
            if (ctrl.issue.Subtasks.length) {
                return ctrl.issue.Subtasks.every(subtask => subtask.IsCompleted);
            }
            return true;
        }

        function openProfile() {
            if (ctrl.issue.AuthorToken) {
                ProfileViewerService.showProfileCard(ctrl.issue.AuthorToken);
            }
        }

        function showAuthor() {
            return !ctrl.issue.HideAuthor && ctrl.issue.AuthorDepartment && ctrl.issue.AuthorName;
        }
    }
})();
