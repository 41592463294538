(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationsSettingsPopup', {
            template: require('./NotificationsSettingsPopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', 'NotificationFactory', 'Profile', 'PopupWrapperService', NotificationSettingsPopupController],
            bindings: {
                onClose: '<'
            }
        });

    function NotificationSettingsPopupController($scope, NotificationFactory, Profile) {
        const ctrl = this;
        let Popup;

        ctrl.popupId = 'NotificationsSettingsPopup';

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.isLoading = true;

        function onOpen(data, popup) {
            Popup = popup;

            ctrl.isLoading = true;
            Profile.getNotificationSettings().then((response) => {
                ctrl.settings = response;
            }).finally(() => {
                ctrl.isLoading = false;
            });
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            Popup && Popup.close();
        }
    }
})();