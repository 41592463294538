(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('TrackingService', ['$http', '$timeout', '$rootScope', 'events', 'Page', 'DeviceService',
        function ($http, $timeout, $rootScope, events, Page, DeviceService) {
            const _maxTimingCheckInterval = 15 * 60 * 1000; // 15 minutes

            var _eventQueue = [];
            var _timer = null;
            var _lastTimingCheck = null;
            var _instantTrack = true;
            var _serverDifference = 0;
            var _hasFlushed = false;
            var _userLoggedIn = false;
            var _settingsLoaded = false;

            // run init
            initialize();
            
            $rootScope.$on(events.APP_UNHIDE, () => tryFlushEvents(false));
            $rootScope.$on(events.APP_HIDE, () => tryFlushEvents(true));
            $rootScope.$on(events.USER_LOGGED_IN, () => _userLoggedIn = true);
            $rootScope.$on(events.USER_LOGGED_OUT, () => _userLoggedIn = false);
            $rootScope.$on(events.SETTINGS_LOADED, () => _settingsLoaded = true);

            return {
                trackEvent: trackEvent,
                trackPageView: trackPageView,
                trackUrlPageView: trackUrlPageView,
                trackAppHide: trackAppHide,
                trackAppUnhide: trackAppUnhide
            };

            // initialize factory
            function initialize() {
                $http.head('/track/ping').then(function (r) {
                    parseServerTimings(r);
                }, function (err) {
                    // something went wrong, so we won't create dates
                    console.error(err);
                });
            }

            function parseServerTimings(response) {
                try {
                    var dateHeader = response.headers('date');
                    var serverDate = new Date(dateHeader);
                    var clientDate = new Date();

                    // determine difference from client time
                    var difference = serverDate.getTime() - clientDate.getTime();
                    // save difference
                    _serverDifference = Math.round(difference / 1000);
                    // no longer require instant tracking
                    _instantTrack = false;
                    _lastTimingCheck = new Date();
                }
                catch (err) {
                    console.error(err);
                }
            }

            function trackPageView(entityType, entityId, viewName, metaData, instantTrack) {
                var metaDataRequest = {};

                if (metaData !== null && typeof metaData === 'object') {
                    metaDataRequest = metaData;
                }

                metaDataRequest.viewName = viewName;
                var data = {
                    entityType: entityType,
                    entityId: entityId,
                    metaData: metaDataRequest
                };

                const isInstant = instantTrack === true;
                trackEvent('Relesys.Tracking.Event.PageView', data, isInstant);
            }

            function trackUrlPageView(url, instantTrack){
                var data = {
                    url: url
                };

                const isInstant = instantTrack === true || (url === '/'); // we always flush when we hit the frontpage
                trackEvent('Relesys.Tracking.Event.PageView', data, isInstant);
            }

            function trackAppHide(){
                trackEvent('Relesys.Tracking.Event.AppHide', null, true);
            }

            function trackAppUnhide(){
                trackEvent('Relesys.Tracking.Event.AppUnhide', null, true);
            }

            function trackEvent(eventType, data, instantTrack) {
                // create event
                var ev = {
                    type: eventType,
                    data: data
                };

                // add time to object as it will not always be instantly flushed
                // this is done using the server difference in seconds
                if (_serverDifference !== 0) {
                    ev.time = new Date(new Date().getTime() + (_serverDifference * 1000));
                } else {
                    ev.time = new Date();
                }   

                // add event to queue
                _eventQueue.push(ev);

                tryFlushEvents(_instantTrack || instantTrack);                
            }

            function startFlushTimer() {
                // starts a timer with a timeout of 15 seconds
                _timer = $timeout(flushEvents, 15000);
            }

            function tryFlushEvents(isInstant) {
                // start timer if not already running
                if (_hasFlushed === false || isInstant) {
                    // we flush the first time straight away to ensure we've done the first tracking
                    flushEvents(isInstant);
                } else if (_timer === null) {
                    // starts a timer
                    startFlushTimer();
                }
            }

            function flushEvents(isInstant) {
                if (_userLoggedIn !== true || _settingsLoaded !== true) {
                    return;
                }

                if (_eventQueue.length === 0) {
                    return;
                }

                // Don't flush if app is not visible - Only if instant as that happens through other events which ensure the app is visible
                // We stop the flush as app visibility change will flush events all over if needed
                if (!isInstant && Page.isAppVisible() == false) {
                    if(_timer != null){
                        $timeout.cancel(_timer)
                    }
                    return;
                }

                // instantly stop / reset the timer
                // and take the events out and reset that array as well
                const eventsToSend = _.cloneDeep(_eventQueue);
                _timer = null;
                _eventQueue = [];

                // if instantTrack = TRUE, there might need to be another check against the server for server timings
                if (_instantTrack) {
                    // should it have been more than the allowed interval last check, another one is done
                    if (_lastTimingCheck === null
                        || (_lastTimingCheck.getTime() + _maxTimingCheckInterval) < new Date().getTime()) {
                        // run a new initialization
                        initialize();
                    }
                }

                // send events
                $http.post('/track/event', {
                    deviceId: DeviceService.getDeviceId(),
                    events: eventsToSend
                }).then(function (response) {
                    // it went well, so nothing is done
                    // apart from marking as having been flushed before
                    _hasFlushed = true;
                    parseServerTimings(response);
                }, function (err) {
                    // it failed, so they are added back in the queue
                    _.each(eventsToSend, function (o) {
                        _eventQueue.push(o);
                    });
                    // log error
                    console.error(err);
                    // ensure we won't try again immidiately
                    _hasFlushed = true;
                    
                    if (err.status && err.status == 404){
                        return;
                    }

                    // start a new timer for flushing
                    startFlushTimer();
                });
            }
        }
    ]);
})();