(() => {
    'use strict';

    angular
        .module('App')
        .component('createDataListItem', {
            template: require('./CreateDataListItemComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', 'ServiceFormRenderService', 'ServiceFormRestService', 'ToastFactory',
                'PopupWrapperService', 'DATALIST_TYPE', 'GeolocationService', CreateDataListItemController],
            bindings: {
                customFields: '<',
                listId: '<',
                popupId: '<',
                popupHeader: '@',
                fieldType: '<',
                searchInput: '<',
                createdLabel: '<',
                createdItemUpdate: '<',
                onClose: '&',
                onRegistered: '&',
                accountModuleToken: '<'
            }
        });

    function CreateDataListItemController($scope, $rootScope, ServiceFormRenderService, ServiceFormRestService, ToastFactory,
                                          PopupWrapperService, DATALIST_TYPE, GeolocationService) {
        const ctrl = this;

        ctrl.isGpsEnabled = GeolocationService.isGpsEnabled();
        ctrl.dataListItem = {};
        ctrl.isLoading = false;
        ctrl.DATALIST_TYPE = DATALIST_TYPE;
        ctrl.getFieldDataList = ServiceFormRenderService.getFieldDataList;
        ctrl.getAddressFromGps = ServiceFormRenderService.getGpsSettings().GpsToAddressEnabled;

        ctrl.onOpen = onOpen;
        ctrl.createDataListItem = createDataListItem;
        ctrl.getAddress = getAddress;

        function onOpen() {
            if (ctrl.searchInput) {
                ctrl.dataListItem.CustomId = ctrl.searchInput;
                ctrl.dataListItem.Name = ctrl.searchInput;
            }
        }

        function createDataListItem() {
            ctrl.isLoading = true;
            ctrl.dataListItem.DataListId = ctrl.listId;

            ServiceFormRestService
                .createDataListItem(ctrl.dataListItem, ctrl.customFields, ctrl.fieldType, ctrl.accountModuleToken)
                .then(data => {
                    resetData();
                    if (ctrl.createdLabel) {
                        ToastFactory.success(ctrl.createdLabel);
                    } else {
                        ToastFactory.successTranslated('DATALIST.ITEM.CREATED');
                    }

                    if (ctrl.createdItemUpdate) {
                        ctrl.createdItemUpdate(data).then(function (customer) {
                            $rootScope.$broadcast('dataListItemAdded', customer[0]);
                        })
                    } else {
                        $rootScope.$broadcast('dataListItemAdded', data);
                    }

                    PopupWrapperService.getPopup(ctrl.popupId).close();
                })
                .catch(error => {
                    ToastFactory.error(error.Message);
                })
                .finally(() => {
                    ctrl.isLoading = false;
                });
        }

        function getAddress() {
            ctrl.permissonsLaoded = true;
            GeolocationService.getCurrentPosition()
                .then(data => {
                    if (data && data.latitude && data.longitude) {
                        ServiceFormRestService.getAddress(data.latitude, data.longitude).then(setAddress)
                    } else {
                        ToastFactory.errorTranslated('SERVICEFORM.MAP.CANT_FIND_LOCATION');
                    }
                })
                .catch(() =>
                    ToastFactory.errorTranslated('SERVICEFORM.MAP.CANT_ACCESS'));
        }

        function setAddress({City, Country, Street, ZipCode}) {
            ctrl.dataListItem.City = City;
            ctrl.dataListItem.Country = Country;
            ctrl.dataListItem.Street = Street;
            ctrl.dataListItem.ZipCode = ZipCode;
        }

        function resetData() {
            for (var property in ctrl.dataListItem) {
                if (ctrl.dataListItem.hasOwnProperty(property)) {
                    ctrl.dataListItem[property] = null;
                }
            }
            ctrl.customFields.forEach(function (el) {
                if (el.model) {
                    el.model = null;
                }
            });
        }
    }
})();