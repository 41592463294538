(() => {
    'use strict';

    angular
        .module('App')
        .component('pinCodeWelcomePage', {
            template: require('./PinCodeWelcomePageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['PinCodeService', PinCodeWelcomePageController],
            bindings: {
                canSkip: '<',
                onSkip: '&',
                onCreate: '&'
            }
        });

    function PinCodeWelcomePageController(PinCodeService) {
        var ctrl = this;

        ctrl.showButtons = true;
        ctrl.labels = PinCodeService.getLabels();

        ctrl.onSkipClick = onSkipClick;

        function onSkipClick(){
            ctrl.showButtons = false;
            ctrl.onSkip && ctrl.onSkip();
        }
    }
})();