(() => {
    'use strict'
    angular
        .module('Relesys.Ui')
        .component('rlMessage', {
            template: require('./MessageComponent.html'),
            controller: ['$element', MessageController],
            controllerAs: 'ctrl',
            bindings: {
                severity: '@',
                message: '<',
                title: '<'
            }
        })

    function MessageController($element) {
        var ctrl = this;
        ctrl.icon = '';
        ctrl.class = '';
        ctrl.textClass = '';
        ctrl.iconClass = '';
        ctrl.titleClass = '';
        ctrl.onClose = onClose;
        switch (ctrl.severity) {
            case 'warning':
                ctrl.icon = "alert";
                ctrl.class = "rl-warning";
                ctrl.textClass = "warning-text";
                ctrl.iconClass = "warning-icon";
                break;
            case 'alert':
                ctrl.icon = "exclamation-circle";
                ctrl.class = "rl-alert";
                ctrl.textClass = "alert-text";
                ctrl.iconClass = "alert-icon";
                break;
            case 'info':
                ctrl.icon = "info";
                ctrl.class = "rl-info";
                ctrl.textClass = "info-text";
                ctrl.iconClass = "info-icon";
                ctrl.titleClass = "info-title";
                ctrl.close = true;
                break;
        }

        function onClose() {
            $element.remove();
        }
    }
})();