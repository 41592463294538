(() => {
    'use strict';

    angular
        .module('App')
        .directive('infiniteScrollLoader', [
            function () {
                return {
                    restrict: 'E',
                    template: require('./Templates/InfiniteScrollLoader.tpl.html'),
                    scope: {
                        isLoading: '=',
                        loadMore: '&',
                        allowLoading: '='
                    },
                    link: function (scope) {
                        scope.loadMoreClick = function () {
                            scope.loadMore();
                        }
                    }
                }
            }
        ]);
})();