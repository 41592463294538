(() => {
    'use strict';

    angular
        .module('App')
        .component('task', {
            template: require('./TaskComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'TaskDetailsService', 'ISSUE_STATUS', 'Page', TaskController],
            bindings: {
                issue: '<'
            }
        });

    function TaskController($stateParams, TaskDetailsService, ISSUE_STATUS, Page) {
        var ctrl = this;

        ctrl.ISSUE_STATUS = ISSUE_STATUS;

        ctrl.getOuterClass = getOuterClass;
        ctrl.openEventDetails = openEventDetails;
        ctrl.prepareDueSoonStatus = TaskDetailsService.prepareDueSoonStatus;

        function openEventDetails() {
            Page.stateGo('taskPopup', $stateParams.token, ctrl.issue.IssueToken, $stateParams.backState);
        }

        function getOuterClass() {
            return {
                'overdue': ctrl.issue.IsOverdue || ctrl.issue.Status === ctrl.ISSUE_STATUS.OVERDUE,
                'due-soon': (ctrl.issue.Status === ctrl.ISSUE_STATUS.DUE_SOON && ctrl.issue.DueHoursLeft >= 0),
                'completed': ctrl.issue.IsCompletedByCurrentUser,
            };
        }
    }
})();