(function () {
        'use strict';

        var app = angular.module('App');

        app.component('departmentUsergroupSelector', {
            templateUrl: '/Scripts/Components/DepartmentUserGroupSelector/DepartmentUserGroupSelectorComponent.tpl.html',
            controllerAs: 'ctrl',
            controller: ['$http', '$scope', '$element', '$timeout', '$templateRequest', '$compile', 'PopupWrapperService',
                DepartmentUserGroupSelectorController],
            bindings: {
                departments: '<',
                userGroups: '<',
                selectedDepartments: '=',
                selectedUserGroups: '='
            }
        });

        function DepartmentUserGroupSelectorController($http, $scope, $element, $timeout, $templateRequest, $compile,
                                              PopupWrapperService) {
            var ctrl = this, popup;

            ctrl.VIEW = {
                DEPARTMENTS: 1,
                USERGROUPS: 2
            };

            ctrl.popupId = 'departmentUserGroupSelectorPopup';

            ctrl.$onInit = init;
            ctrl.reset = reset;
            ctrl.setActiveTab = setActiveTab;
            ctrl.openDepartmentUserGroupSelectorPopup = openDepartmentUserGroupSelectorPopup;
            ctrl.close = close;

            function init() {
                ctrl.currentTab = ctrl.VIEW.DEPARTMENTS;

                if (ctrl.selectedDepartments) {
                    ctrl.departments.model = ctrl.selectedDepartments;
                }

                if (ctrl.selectedUserGroups) {
                    ctrl.userGroups.model = ctrl.selectedUserGroups;
                }

                ctrl.departmentWatch = $scope.$watchCollection('ctrl.departments.model', watchDepartments);
                ctrl.userGroupWatch = $scope.$watchCollection('ctrl.userGroups.model', watchUserGroups);
            }

            function watchDepartments(departments) {
                if (departments) {
                    ctrl.selectedDepartments = departments;
                }
            }

            function watchUserGroups(userGroups) {
                if (userGroups) {
                    ctrl.selectedUserGroups = userGroups;
                }
            }

            function openDepartmentUserGroupSelectorPopup() {
                ctrl.showDepartmentUserGroupSelector = true;
            }

            function reset() {
                ctrl.onChange && ctrl.onChange();
            }

            function setActiveTab(tab) {
                ctrl.currentTab = tab;
            }

            function close() {
                ctrl.showDepartmentUserGroupSelector = false;
            }
        }
    }

)();
