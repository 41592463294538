(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartDatelastupdated', ['$log', '$http', 'Page', 'Profile', 'TimeLocale',
        function ($log, $http, Page, Profile, TimeLocale) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartDateLastUpdated.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    // get data series from external source
                    $.getJSON(Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetDateLastUpdated/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken,
                        function (response) {
                            if (response !== 'DataSet not found') {
                                scope.dateLastUpdated = TimeLocale.dateTimeFormat(response, "H:mm | D MMM YYYY");
                            }
                            scope.chartIsLoading = false;
                            scope.$apply();
                        });
                }
            };
        }
    ]);
})();