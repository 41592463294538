(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartItem', ['$log',
        function ($log) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartItem.tpl.html',
                scope: {
                    data: '='
                }
            }
        }
    ]);
})();