(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('viewPage', [
        function () {
            return {
                scope: {
                    viewPageScrolling: '@',
                    noBackground: '@',
                    isNarrow: '@'
                },
                link: function (scope, element) {
                    $(element).addClass('view-page');

                    if (scope.viewPageScrolling === undefined || scope.viewPageScrolling !== 'false') {
                        $(element).addClass('scrolling');
                    }
                    if (scope.noBackground === undefined || scope.noBackground !== 'true') {
                        $(element).addClass('default-background');
                    }
                    if (scope.isNarrow === 'true') {
                        $(element).addClass('narrow');
                    }
                }
            }
        }
    ]);
})();