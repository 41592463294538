(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('UserGroupPickerController', ['$scope', '$http', 'close', '$translate', '$timeout', 'TimeLocale', 'userGroups', 'selectedUserGroup',
        function ($scope, $http, close, $translate, $timeout, TimeLocale, userGroups, selectedUserGroup) {
            // Init
            InitController();
            function InitController() {
                $scope.loading = false;
                
                // Translate title
                $translate('USERGROUP.SELECT_TITLE').then(function (translation) {
                    $scope.title = translation;
                });

                // Preselect one if not present
                if (selectedUserGroup == undefined || selectedUserGroup == null) {
                    selectedUserGroup = userGroups[0];
                }

                // Save to scope
                $scope.selectedUserGroup = selectedUserGroup;
                $scope.userGroups = userGroups;
            }

            // Methods
            function SelectUserGroup(select) {
                for (var i = 0; i < $scope.userGroups.length; i++) {
                    var group = $scope.userGroups[i];
                    if (group.RoleId == select.RoleId) {
                        $scope.selectedUserGroup = select;
                    }
                }

                $timeout(function () {
                    close();
                }, 200);
            }

            // Bind
            $scope.selectUserGroup = SelectUserGroup;
            $scope.close = close;
        }
    ]);

})();