(function () {
    'use strict';

    angular
        .module('App')
        .component('mapPopup', {
            template: require('./MapPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', MapPopupController],
            bindings: {
                title: '<',
                lat: '<',
                lon: '<'
            }
        })

    function MapPopupController($element, $timeout) {
        let ctrl = this, popup, map;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.close = close;
        ctrl.init = init;

        function init() {
            $timeout(() => {
                const position = {lat: ctrl.lat, lng: ctrl.lon};
                map = new google.maps.Map($element.find('#map')[0], {
                    center: position,
                    zoom: 15
                });
                new google.maps.Marker({
                    map,
                    position,
                    title: ctrl.title,
                });
            })

        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            popup.remove();
            $element.remove();
        }
    }
})();