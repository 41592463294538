(() => {
    'use strict';

    angular
        .module('App')
        .component('taskDetailsPopup', {
            template: require('./TaskDetailsPopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', 'Page', '$timeout', 'TaskManagementRenderService',
                TaskDetailsPopupController],
            bindings: {
                moderateAllowed: '<',
                issue: '<',
                token: '<'
            }
        });

    function TaskDetailsPopupController($element, $scope, Page, $timeout, TaskManagementRenderService) {
        var ctrl = this, popup;

        ctrl.loading = true;
        ctrl.close = close;
        ctrl.openEventDetails = openEventDetails;
        ctrl.onRegistered = onRegistered;
        ctrl.$onDestroy = destroy;
        ctrl.isClosing = false;

        $scope.$on('$destroy', destroy);

        function onRegistered(popupCtrl) {
            popupCtrl.open();
        }

        function openEventDetails(issue, popupCtrl) {
            popup = popupCtrl;

            // Start loading data if needed
            if (ctrl.issue === undefined) {
                if (ctrl.token !== undefined) {
                    loadIssue(ctrl.token);
                } else {
                    var watcherUnregister = $scope.$watch('ctrl.token', function (newVal, oldVal) {
                        if (newVal) {
                            watcherUnregister();
                            loadIssue(ctrl.token);
                        }
                    });
                }
            } else {
                ctrl.loading = false;
            }

            // The timeout is important to avoid reacting to the navigation to the popup
            $timeout(function () {
                $scope.$on('$locationChangeSuccess', function (ev) {
                    close(false, ev);
                });
            });
        }

        function loadIssue(token) {
            ctrl.loading = true;

            TaskManagementRenderService.getTaskPreviewData(null, token).then(issue => {
                ctrl.issue = issue;
                ctrl.loading = false;
            }).catch(() => {
                console.log('error loading issue');
                ctrl.loading = false;
            });
        }

        function close(reload, event) {
            if (ctrl.isClosing) {
                return;
            }

            ctrl.isClosing = true;

            $timeout(function () {
                $scope.$destroy();
            });

            var isStateChangeEvent = (event && event.name === '$locationChangeSuccess');
            if (!isStateChangeEvent) {
                Page.stateGoParent(reload);
            }
        }

        function destroy() {
            popup.remove();
            $element.remove();
        }
    }
})();