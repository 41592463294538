(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('CookieService', ['$cookies', function ($cookies) {
        var today = new Date();
        var expire = new Date(today.getFullYear() + 10, 1, 1, 0, 0, 0);

        return {
            set,
            get,
            remove
        }

        function set(key, val) {
            $cookies.put(key, val, {
                'path': '/',
                'expires': expire
            });
        }

        function get(key) {
            return $cookies.get(key);
        }
        
        function remove(key) {
            return $cookies.remove(key)
        }
    }]);
})();