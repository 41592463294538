(() => {
    'use strict';

    angular
        .module('App')
        .factory('HeaderButtonsFactory', ['$rootScope', HeaderButtonsFactory]);

    function HeaderButtonsFactory($rootScope) {
        const self = this;

        this.buttonsList = [];
        this.refreshFunction = null;

        return {
            getButtonListConfig: getButtonListConfig,
            createButtonsList: createButtonsList,
            resetButtonsList: resetButtonsList
        };

        function getButtonListConfig(getButtonsFunc) {
            let buttonsListLocal = [];
            getButtonsFunc().forEach(btn => {
                buttonsListLocal.push(new Button(btn.icon, btn.activeIcon, btn.onClick, btn.caption, btn.items, btn.active, btn.badges, btn.hidden, btn.type, btn.place));
            });

            return buttonsListLocal;
        }

        function createButtonsList(getButtonsFunc) {
            self.buttonsList = [];
            self.refreshFunction = getButtonsFunc;
            getButtonsFunc().forEach(btn => {
                self.buttonsList.push(new Button(btn.icon, btn.activeIcon, btn.onClick, btn.caption, btn.items, btn.active, btn.badges, btn.hidden, btn.type, btn.place));
            });

            $rootScope.headerButtons = self.buttonsList;
        }

        function resetButtonsList() {
            self.buttonsList = [];
            self.refreshFunction = null;
            $rootScope.headerButtons = self.buttonsList;
        }

        function Button(icon, activeIcon, onClick, caption, items, active, badges, hidden, type, place) {
            var Btn = this;

            this.expanded = false;
            this.icon = icon || '';
            this.activeIcon = activeIcon || '';
            this.caption = caption;
            this.items = [];
            this.active = active;
            this.hidden = hidden;
            this.badges = badges;
            this.type = type;
            this.place = place || 'right';

            this.onClick = function (e) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (!this.items.length) {
                    onClick && onClick(e);
                    createButtonsList(self.refreshFunction);
                } else {
                    this.expanded = true;
                }
            };

            this.onClose = function (e) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                this.expanded = false;
            };

            if (items) {
                angular.forEach(items, function (item) {
                    Btn.items.push(new ListItem(item.title, item.onClick, item.active, item.badges, Btn));
                });

            }

            this.setActiveItem = function (selectedItem) {
                this.items = this.items.map(function (item) {
                    item.active = item === selectedItem;
                    return item;
                });
            };
        }

        function ListItem(title, onClick, active, badges, parent) {
            this.active = active || false;
            this.title = title || '';
            this.badges = badges || null;

            this.onClick = function (e) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                parent.setActiveItem(this);
                parent.expanded = false;
                onClick();
                createButtonsList(self.refreshFunction);
            };
        }
    }
})();