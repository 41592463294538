(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlSortingPopup', {
            template: require('./SortingPopupComponent.html'),
            controller: ['$scope', '$element', SortingPopupController],
            controllerAs: 'ctrl',
            bindings: {
                options: '<',
                headerTitle: '@',
                onSortSelected: '<',
            }
        });

    function SortingPopupController($scope, $element) {
        const ctrl = this;

        ctrl.select = select;
        ctrl.onClose = onClose;

        function select(option) {
            ctrl.onSortSelected && ctrl.onSortSelected(option);
            ctrl.closeAction && ctrl.closeAction();
        }

        function onClose() {
            $scope.$destroy();
            $element.remove()
        }
    }
})();