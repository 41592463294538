(function () {
    'use strict';
    angular
        .module('App')
        .directive('timeAgo', ['TimeLocale', '$filter',
            function (TimeLocale, $filter) {
                return {
                    restrict: 'A',
                    scope: {
                        timeAgo: '='
                    },
                    link: function (scope, element, attrs) {

                        moment.relativeTimeThreshold('s', 59);
                        moment.relativeTimeThreshold('m', 59);
                        moment.relativeTimeThreshold('h', 24);

                        // Load it up
                        var val = scope.timeAgo;
                        var todayUnix = parseInt(moment().format('X'));
                        var valUnix = parseInt(TimeLocale.getMomentDate(val).format('X'));

                        if ((todayUnix - valUnix) >= 0 && (todayUnix - valUnix) <= 59) {
                            $(element).html($filter('translate')('SOCIAL.NOW'));
                        } else if (todayUnix < (valUnix + 86400)) {
                            $(element).html(TimeLocale.timeAgo(val));
                        } else if (todayUnix < (valUnix + 604800)) {
                            $(element).html(TimeLocale.dateTimeFormat(val, 'dddd HH:mm'));
                        } else {
                            $(element).html(TimeLocale.dateTimeFormat(val, 'D MMM YYYY HH:mm'));
                        }
                    }
                }
            }
        ]);
})();