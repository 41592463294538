(function () {
    'use strict';


    angular.module('App')
        .component('contentCommentsPopup', {
            template: require('./ContentCommentsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$scope', '$element', '$http', '$translate', '$timeout', 'TimeLocale', 'ToastFactory',
                'ContentWrapperService', 'Profile', 'BasicHelper', 'CommentsService', 'MentionService',
                'ProfileViewerService', 'DepartmentViewerService', 'ReportPopupService', contentCommentsPopupController],
            bindings: {
                post: '=',
                settings: '<',
                commentId: '<',
                moduleToken: '<'
            }
        });

    function contentCommentsPopupController($q, $scope, $element, $http, $translate, $timeout, TimeLocale, ToastFactory,
                                            ContentWrapperService, Profile, BasicHelper, CommentsService, MentionService,
                                            ProfileViewerService, DepartmentViewerService, ReportPopupService) {

        var ctrl = this, popup;

        ctrl.popupId = ContentWrapperService.contentCommentsPopupId;

        ctrl.comments = [];
        ctrl.isPosting = false;

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.onClose = onClose;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.deleteComment = deleteComment;
        ctrl.reportComment = reportComment;
        ctrl.postComment = postComment;
        ctrl.$onDestroy = destroy;
        ctrl.updateComments = updateComments;

        function onPopupRegistered(popup) {
            popup.open();
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
            initNewComment();

            ctrl.title = "";
            $translate('LOADING').then(function (translation) {
                ctrl.title = translation + "...";
            });

            // Load Comments
            ctrl.commentsLoading = true;
            loadComments();

            $timeout(function () {
                $element.on('click', '.post-tag', function (ev) {
                    var userToken = $(ev.currentTarget).find(".post-tag-user-token");
                    if (userToken && userToken[0]) {
                        ProfileViewerService.showProfileCard(userToken[0].innerHTML);
                    }
                    var departmentToken = $(ev.currentTarget).find(".post-tag-department-token");
                    if (departmentToken && departmentToken[0]) {
                        DepartmentViewerService.showDepartmentCard(departmentToken[0].innerHTML);
                    }
                });
            });
        }

        function destroy() {
            $scope.ctrl = null;
        }

        function close() {
            popup && popup.close();
        }

        function onClose() {
            popup.remove();
            $element.remove();
            popup = null;
            $scope.$parent.$destroy();
        }

        function initNewComment() {
            ctrl.newComment = {
                text: '',
                uploadedMedia: []
            };
        }

        function getParentCommentId(comment) {
            if (comment.ParentContentCommentId) {
                return getParentCommentId(ctrl.comments.find(function (it) {
                    return it.ContentCommentId === comment.ParentContentCommentId;
                }))
            } else {
                return comment.ContentCommentId;
            }
        }

        function deleteComment(comment) {
            CommentsService
                .deleteComment('/ContentModule/DeleteComment/' + comment.ContentCommentId)
                .then(updateComments)
        }

        function loadComments() {
            $http.post('/ContentModule/GetComments', {
                token: ctrl.post.ContentToken
            }).then(function (resp) {
                $translate('SOCIAL.COMMENTS').then(function (translation) {
                    ctrl.title = translation;
                });

                updateComments(resp.data);
                ctrl.commentsLoading = false;
            }, function (err) {
                $translate('ERROR.INFINTE_LOAD_ERROR').then(function (translation) {
                    ToastFactory.error(translation);
                });
            });
        }
        
        function onReportSubmit(model, itemId) {
            return ReportPopupService.reportContentComment({
                contentCommentId: itemId,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportComment(comment) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, comment.ContentCommentId);
        }
        
        function postComment(replyingMessage) {
            var d = $q.defer(), parentId = null, newText = null, model, postUrl,
                isEdit = !!ctrl.newComment.contentCommentId,
                mentions = MentionService.formatMentions(ctrl.newComment);


            if (!ctrl.isPosting && !ctrl.loading) {
                if (ctrl.newComment.text.length > 0 || ctrl.newComment.uploadedMedia.length > 0) {
                    ctrl.isPosting = true;

                    if (replyingMessage) {
                        parentId = getParentCommentId(replyingMessage);

                        if (ctrl.settings.IsTaggingEnabled) {
                            newText = '@' + replyingMessage.CreatedByUserToken + ' ' + ctrl.newComment.text;
                            mentions.TaggedUsers && mentions.TaggedUsers.push(replyingMessage.CreatedByUserId);
                        }
                    }

                    model = {
                        ContentToken: ctrl.post.ContentToken,
                        Text: newText || ctrl.newComment.text,
                        ParentContentCommentId: parentId,
                        MediaList: ctrl.newComment.uploadedMedia.map(function (item) {
                            return item.MediaId;
                        }),
                        TaggedUsers: mentions.TaggedUsers,
                        TaggedDepartments: mentions.TaggedDepartments,
                        TaggedUserGroups: mentions.TaggedUserGroups,
                        IgnoreContentSafetyWarning: ctrl.newComment.IgnoreContentSafetyWarning
                    }

                    if (!isEdit) {
                        postUrl = '/ContentModule/PostComment';
                    } else {
                        postUrl = '/ContentModule/EditComment';
                        model.ContentCommentId = ctrl.newComment.contentCommentId;
                    }

                    $http.post(postUrl, model).then(function (resp) {
                        ctrl.isPosting = false;
                        if (Profile.getProfile().UserToken !== ctrl.post.CreatedByUserToken) {
                            ctrl.post.IsSubscribed = true;
                        }
                        updateComments(resp.data);
                        initNewComment();

                        d.resolve();
                    }).catch(resp => {
                        ctrl.isPosting = false;
                        d.reject(resp);
                    });
                } else {
                    ToastFactory.error('You need to write something');
                    d.reject();
                }


            } else {
                d.reject();
            }

            return d.promise;
        }

        function updateComments(comments) {
            if ((ctrl.comments?.length > 0 && ctrl.comments.length !== comments.length) || ctrl.newComment.text) {
                ctrl.comments = ctrl.newComment.text || ctrl.newComment.uploadedMedia ?
                    comments.map(comment => {
                        const oldComment = ctrl.comments.find(item => item.ContentCommentId === comment.ContentCommentId);
                        return oldComment ?
                            {
                                ...oldComment,
                                MediaList: comment.MediaList,
                                TranslatableEntity: comment.TranslatableEntity,
                                _currentLocale: comment.TranslatableEntity.LocalizedAlternativeLocale?.CultureId === oldComment._currentLocale.CultureId ?
                                    comment.TranslatableEntity.LocalizedAlternativeLocale : comment.TranslatableEntity.LocalizedLocale
                            } :
                            {
                                ...comment,
                                MediaList: comment.MediaList,
                                TranslatableEntity: comment.TranslatableEntity,
                                _currentLocale: comment.TranslatableEntity.LocalizedLocale
                            }
                    })
                    : ctrl.comments.filter(comment => comments.map(comment => comment.ContentCommentId).includes(comment.ContentCommentId));
            } else {
                ctrl.comments = comments || [];
            }
            ctrl.post.CommentCount = ctrl.comments.length;
            _.each(ctrl.comments, function (comment) {
                comment._currentLocale = comment._currentLocale ?? getCurrentLocale(comment.TranslatableEntity);
                comment.originalText = comment._currentLocale.Text;
                comment._currentLocale.Text = MentionService.parseMentionsInText(
                    comment._currentLocale.Text,
                    comment.TaggedUsers,
                    comment.TaggedDepartments,
                    comment.TaggedUserGroups,
                    ctrl.settings.HashtagsEnabled
                );
            });
            ctrl.commentsTree = buildCommentsTree(ctrl.comments);
        }

        function getCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function buildCommentsTree(comments) {
            return CommentsService
                .buildCommentsTree(comments, 'ContentCommentId', 'ParentContentCommentId')
        }
    }
})();