(function () {
    'use strict';

    angular.module('App')
        .component('chatMembers', {
            template: require('./ChatMembersComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$filter', '$element', 'ChatPopupsService', 'ChatDataService', 'ChatConnectionService',
                'ConfirmPopupService', 'ProfileViewerService', 'Profile', 'ActionSheetService', 'ToastFactory', ChatMembersController],
            bindings: {
                channel: '<',
                closeConversation: '<',
                closeChatSettings: '<'
            }
        });

    function ChatMembersController($rootScope, $filter, $element, ChatPopupsService, ChatDataService, ChatConnectionService, 
        ConfirmPopupService, ProfileViewerService, Profile, ActionSheetService, ToastFactory) {
        let ctrl = this, popup,
            pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());

        ctrl.close = close;
        ctrl.currentUser = Profile.getProfile()
        ctrl.isAdmin = isAdmin;
        ctrl.isCurrentUserAdmin = isAdmin(ctrl.currentUser.UserToken);
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.openAddUsers = openAddUsers;
        ctrl.showActions = showActions;
        
        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            ChatConnectionService.onChannelItemEvents(
                ctrl.channel.item.channelId,
                channelEventsCallback
            )

            sortUsers();
        }

        function channelEventsCallback(channel) {
            ctrl.channel = channel;
            sortUsers();
        }

        function sortUsers() {
            ctrl.users = ctrl.channel.members
            .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
        }

        function addAdminRights(userId) {
            ChatDataService.addChannelAdminRights(ctrl.channel.item.channelId, userId);
        }

        function createChat(user) {
            ChatDataService.createChannel([user])
                .then(function (channelId) {
                    closeThisAndPreviousPopups(true);
                    $rootScope.$broadcast('CHAT.CHANNEL_CREATED', channelId);
                })
        }

        function isAdmin(token) {
            return !!ctrl.channel.item.adminUserIds.find(userId => {
                return userId === token
            });
        }
        
        function isBlocked(userId) {
            return ctrl.channel.blockedUsers?.includes(userId);
        }
        
        function openAddUsers() {
            ChatPopupsService.openAddUsersPopup(ctrl.channel, close);
        }

        function removeAdminRights(userId) {
            ChatDataService.removeChannelAdminRights(ctrl.channel.item.channelId, userId);
        }

        function removeMember(userId) {
            ConfirmPopupService.open({
                message: $filter('translate')('CHAT.EVENT.REMOVE_MEMBER_CONFIRM'),
                class: 'chat-leave-confirm'
            }).then(() => {
                ChatDataService.removeUsersFromChannel(ctrl.channel.item.channelId, [userId]);
            });
        }

        function showActions(user) {
            if (!user || user.userId === ctrl.currentUser.UserToken) {
                return false;
            }

            const header = {description: user.name};

            const buttons = [
                {
                    text: 'CHAT.SHOW_PROFILE',
                    icon: 'user',
                    onClick: function () {
                        ProfileViewerService.showProfileCard(user.userId);
                    }
                },
                {
                    text: 'CHAT.MESSAGE',
                    icon: 'comment',
                    onClick: function () {
                        createChat(user);
                    }
                }
            ];

            if (ctrl.isCurrentUserAdmin && !isAdmin(user.userId)) {
                buttons.push({
                    text: 'CHAT.MAKE_A_MODERATOR',
                    icon: 'user-admin',
                    onClick: function () {
                        addAdminRights(user.userId);
                    }
                });
            }

            if (ctrl.isCurrentUserAdmin && isAdmin(user.userId)) {
                buttons.push({
                    text: 'CHAT.REMOVE_MODERATOR_RIGHTS',
                    icon: 'remove-user',
                    onClick: function () {
                        removeAdminRights(user.userId);
                    }
                });
            }

            const blockUnblockBtn = (isBlocked(user.userId)) 
                ? {
                    text: 'CHAT.UNBLOCK_USER',
                    icon: 'ban',
                    onClick: function () {
                        unblockUser(user);
                    }
                }
                : {
                    text: 'CHAT.BLOCK_USER',
                    icon: 'ban',
                    onClick: function () {
                        blockUser(user);
                    }
                };
        
            buttons.push(blockUnblockBtn);

            if (ctrl.isCurrentUserAdmin) {
                buttons.push({
                    text: 'CHAT.REMOVE_FROM_GROUP',
                    icon: 'delete',
                    iconClass: 'red label-color',
                    onClick: function () {
                        removeMember(user.userId);
                    }
                });
            }

            const actionSheet = ActionSheetService.create(buttons, event.currentTarget, header, {name: 'chat-settings'});
            actionSheet.show();
        }

        function close() {
            ChatConnectionService.removeChannelItemEvents(channelEventsCallback);
            pushOpenWatcher();
            popup.remove();
            ChatPopupsService.disableBodyScrollInChat();
            $element.remove();
        }

        function closeThisAndPreviousPopups(isRemoved) {
            close();
            ctrl.closeConversation && ctrl.closeConversation(isRemoved);
            ctrl.closeChatSettings && ctrl.closeChatSettings();
        }

        function unblockUser(user) {
            ConfirmPopupService
                .open({
                    title: $filter('translate')('CHAT.BLOCK.UNBLOCK') + ' ' + user.name + '?',
                    message: 'CHAT.BLOCK.UNBLOCK_CONFIRMATION_DESCRIPTION',
                    yesText: 'CHAT.BLOCK.UNBLOCK',
                    noText: 'CANCEL'
                })
                .then(() => ChatDataService.unblockUser(user.userId)
                    .then(() => {
                        ctrl.channel.blockedUsers = ctrl.channel.blockedUsers.filter(id => user.userId !== id);
                        $rootScope.$broadcast('CHAT.BLOCKED_USERS_UPDATE', user.userId, false);
                    }))
        }

        function blockUser(user) {
            ConfirmPopupService
                .open({
                    title: $filter('translate')('CHAT.BLOCK.BLOCK') + ' ' + user.name + '?',
                    message: 'CHAT.BLOCK.BLOCK_CONFIRMATION_DESCRIPTION',
                    yesText: 'CHAT.BLOCK.BLOCK',
                    noText: 'CANCEL'
                })
                .then(() => ChatDataService.blockUser(user.userId)
                    .then(() => {
                        ctrl.channel.blockedUsers.push(user.userId);
                        $rootScope.$broadcast('CHAT.BLOCKED_USERS_UPDATE', user.userId, true);
                    }))
        }
    }
})();