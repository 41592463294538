(() => {
    'use strict';

    angular
        .module('App')
        .component('pinCodeHint', {
            template: require('./PinCodeHintComponent.html'),
            controllerAs: 'ctrl',
            controller: ['PinCodeService', PinCodeHintController]
        });

    function PinCodeHintController(PinCodeService) {
        var ctrl = this;
        
        ctrl.labels = PinCodeService.getLabels();
    }
})();


