(function () {
    'use strict';

    angular
        .module('App')
        .factory('FilterTasksService', ['$http', '$stateParams', '$filter', 'PopupWrapperService',
            'ConfirmPopupService', 'Menu', '$state', FilterTasks]);

    function FilterTasks($http, $stateParams, $filter, PopupWrapperService, ConfirmPopupService, Menu, $state) {

        return {
            openFilterTasksPopup: openFilterTasksPopup,
            filterTasks: filterTasks,
            getFilterPage: getFilterPage
        };


        function openFilterTasksPopup(currentFilterModel, data) {
            PopupWrapperService.createDynamic('<filter-tasks-popup on-popup-registered="popupRegistered"></filter-tasks-popup>', {
                popupRegistered: function (popup) {
                    popup.open(currentFilterModel, data);
                }
            });
        }

        function filterTasks(model) {
            return $http.post('/TaskManagement/GetTasks', {
                model: formatFilterData(_.cloneDeep(model))
            }).then(function (resp) {
                return resp.data;
            })
        }

        function getFilterPage() {
            return $http.get('/TaskManagement/GetFilterPage/' + $state.params.token).then(function (resp) {
                return resp.data;
            })
        }

        function formatFilterData(model) {
            model.Token = $state.params.token;

            model.DepartmentIds = _.map(model.Departments, function (department) {
                return department.DepartmentId
            });

            model.UserGroupIds = _.map(model.UserGroups, function (userGroup) {
                return userGroup.UserGroupId
            });

            model.StartTime = model.StartTime ?
                moment(model.StartTime).format('YYYY-MM-DD[T]HH:mm:ss') : null;

            // we always filter one day ahead
            model.EndTime = model.EndTime ? moment(model.EndTime).add(1, 'days') : null;
            model.EndTime = model.EndTime ?
                moment(model.EndTime).format('YYYY-MM-DD[T]HH:mm:ss') : null;

            model.SeparateOverdueTasks = false;

            delete model.Departments;
            delete model.UserGroups;

            return model;
        }
    }

})();
