(() => {
    'use strict';
    
    angular
        .module('Relesys.Ui')
        .component('rlToggleButton', {
            template: require('./RelesysToggleButtonComponent.html'),
            controllerAs: 'ctrl',
            controller: [RlToggleButtonController],
            bindings: {
                model: '=',
                label: '@',
                clicked: '=?'
            }
        })

    function RlToggleButtonController() {
        var ctrl = this;
        
        ctrl.onClick = onClick;

        function onClick() {
            ctrl.model = !ctrl.model;
            ctrl.clicked && ctrl.clicked();
        }
    }
})();