(() => {
        'use strict';

        angular.module('App')
            .component('profileSettingsSecurity', {
                template: require('./ProfileSettingsSecurityComponent.tpl.html'),
                controller: ['ProfileSettingsService', 'PopupWrapperService', 'PinCodeService', 'Page', ProfileSettingsSecurityComponentController],
                controllerAs: 'ctrl'
            });

        function ProfileSettingsSecurityComponentController(ProfileSettingsService, PopupWrapperService, PinCodeService, Page) {
            var ctrl = this;

            ctrl.$onInit = onInit;

            ctrl.changePassword = changePassword;
            ctrl.changePin = changePin;
            ctrl.openBiometric = openBiometric;
            ctrl.openMfa = openMfa;

            function onInit() {
                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile(true).then(res => {
                    ctrl.profile = res;
                    if (ctrl.profile.HasSecurityOptions === false) {
                        Page.triggerBackButton();
                    }

                    initBiometric(res);

                    ctrl.passwordEnabled = res.AllowPasswordChange;
                    ctrl.pinEnabled = res.PinCodeEnabled;
                    ctrl.hasPinCode = res.HasPinCode;

                    ctrl.mfaEnabled = res.MfaEnabled;

                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            }

            function initBiometric(profile){
                ctrl.biometric = {
                    available: false,
                    enabled: false
                };
                
                PinCodeService.getBiometricPinStatus(profile.UserToken).then((res) => {
                    ctrl.biometric.available = true;
                    ctrl.biometric.enabled = res.isStored;
                });
            }

            function changePassword() {
                Page.stateGo('profileSettingsSecurityPassword');
            }

            function changePin() {
                PopupWrapperService.getPopup('resetPinPopupId').open({ hasPinCode: ctrl.hasPinCode });
            }

            function openBiometric() {
                Page.stateGo('profileSettingsSecurityBiometric');
            }

            function openMfa() {
                Page.stateGo('profileSettingsSecurityMfa');
            }
        }
    }
)();