(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('departmentViewer', ['$http', '$rootScope',
        function ($http, $rootScope) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/DepartmentViewer.tpl.html',
                controller: ['$scope', '$element', '$timeout', 'Page', 'BasicHelper',
                    function ($scope, $element, $timeout, Page, BasicHelper) {
                        
                        // Init
                        InitDirective();
                        function InitDirective() {
                            $scope.show = true;
                            $scope.department = null;
                            $scope.width = $rootScope.screenSize.width;
                            $scope.hasContact = false;
                            $scope.hasData = false;
                            $scope.hasImage = false;
                            $scope.isRTL = $rootScope.isRTL;
                            Page.setMainContainerInert(true);

                            LoadDepartment();
                        }

                        // Methods
                        function LoadDepartment() {
                            $scope.loading = true;
                            $scope.department = null;

                            $http.get('/Department/Load/' + $scope.DepartmentToken).then(function (response) {
                                if (response.data !== null && typeof response.data === 'object') {
                                  $scope.department = response.data;
                                  HandleDepartment();
                                }
                                $scope.loading = false;
                            }, function () {
                                $scope.loading = false;
                                $scope.department = null;
                            });
                        }

                        function HandleDepartment() {
                          if ($scope.department != null) {
                            $scope.hasContact = (($scope.department.Phone.length > 0) || ($scope.department.Email.length > 0));
                            $scope.hasData = ($scope.department.Address.length > 0);
                            $scope.hasImage = ($scope.department.Image != null && $scope.department.Image.MediaId > 0);
                          } else {
                            $scope.hasContact = false;
                            $scope.hasData = false;
                            $scope.hasImage = false;
                          }
                        }

                        function CloseDepartmentViewer() {
                            $scope.show = false;
                            Page.setMainContainerInert(false);

                            $timeout(function () {
                                $element.remove();
                                $scope.$destroy();
                            }, 300);
                        }

                        function CallPhone() {
                            window.location.href = 'tel:+' + $scope.department.PhoneCode + '-' + $scope.department.Phone;
                        }

                        function SendEmail() {
                            BasicHelper.mailTo($scope.department.Email);
                        }

                        // Bind to scope
                        $scope.closeDepartmentViewer = CloseDepartmentViewer;
                        $scope.sendEmail = SendEmail;
                        $scope.callPhone = CallPhone;
                    }
                ]
            }
        }
    ]);
})();