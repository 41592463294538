(() => {
    'use strict'

    angular.module('App')
        .component('reactionsList', {
            template: require('./ReactionsList.html'),
            controllerAs: 'ctrl',
            bindings: {
                sendReaction: '<',
                showReactionTooltip: '<',
                preselectedReactionIndex: '<'
            },
            controller: ['ReactionsService', ReactionsListController]
        })

    function ReactionsListController(ReactionsService) {
        const ctrl = this;

        ctrl.reactions = ReactionsService.getReactions();
    }
})();