(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleQuestionCheckbox', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleQuestionCheckbox.html',
                scope: {
                    question: '=',
                    stepDone: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitController();
                    function InitController() {
                        if (!$scope.question.answers) {
                            $scope.question.answers = [];
                        }
                        if (!$scope.question.IsRequired) {
                            $scope.$watch('stepDone', function (newVal) {
                                $scope.question.answered = !!newVal;
                            });
                        }
                    }

                    // Methods
                    function PickOption(option) {
                        if ($scope.question.IsMultiple) {
                            option.picked = !option.picked;

                            // Remove old answers
                            $scope.question.answers = [];

                            // See if any is checked
                            var onePicked = false;
                            for (var i = 0; i < $scope.question.Options.length; i++) {
                                var opt = $scope.question.Options[i];
                                if (opt.picked) {
                                    onePicked = true;
                                    $scope.question.answers.push(new QuestionAnswer(opt.FormQuestionOptionId, opt.Value));
                                }
                            }

                            // Set if question is answered
                            $scope.question.answered = onePicked || !$scope.question.IsRequired;

                        } else {
                            // Remove all checks
                            $scope.question.answers = [];
                            for (var i = 0; i < $scope.question.Options.length; i++) {
                                var opt = $scope.question.Options[i];
                                opt.picked = false;
                            }
                            // Check only the new
                            option.picked = true;
                            $scope.question.answers.push(new QuestionAnswer(option.FormQuestionOptionId, option.Value));
                            $scope.question.answered = true;
                        }
                    }

                    // Objects
                    function QuestionAnswer(optionId, text) {
                        this.FormQuestionOptionId = optionId;
                        this.Text = text;
                    }

                    // Bind scope
                    $scope.pickOption = PickOption;
                }]
            }
        }
    ]);
})();