(function () {
    'use strict';

    angular.module('App')
        .factory('UpdateSubtaskPopupService',
            ['$http', '$stateParams', '$filter', 'PopupWrapperService', 'LanguageSelectorService', 'TranslationService', 'ConfirmPopupService', UpdateSubtaskPopupService]);

    function UpdateSubtaskPopupService($http, $stateParams, $filter, PopupWrapperService, LanguageSelectorService, TranslationService, ConfirmPopupService) {

        return {
            openUpdateSubtaskPopup: openUpdateSubtaskPopup,
            getSubtaskEditPage: getSubtaskEditPage,
            getSubtaskCreatePage: getSubtaskCreatePage,
            saveUpdatedSubtask: saveUpdatedSubtask,
            deleteSubtask: deleteSubtask,
            completeSubtask: completeSubtask,
            subtaskReopen: subtaskReopen,
            translateSubtask: translateSubtask
        };

        function openUpdateSubtaskPopup(subtask) {
            if (subtask.ParentIssueSubtaskToken) {
                ConfirmPopupService.open({
                    message: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.EDIT_SERIES_QUESTION'),
                    yesText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.ONE'),
                    noText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.SERIES')
                })
                .then(() => openConfirmUpdateSubtaskPopup(subtask, null))
                .catch(() => openConfirmUpdateSubtaskPopup(subtask, subtask.ParentIssueSubtaskToken));
            } else {
                openConfirmUpdateSubtaskPopup(subtask, null);
            }
        }

        function openConfirmUpdateSubtaskPopup(subtask, parentIssueSubtaskToken) {
            PopupWrapperService.createDynamic('<update-subtask-popup subtask="subtask" parent-issue-subtask-token="parentIssueSubtaskToken"></update-subtask-popup>',
                {subtask: subtask, parentIssueSubtaskToken: parentIssueSubtaskToken});
        }

        function getSubtaskEditPage(token) {
            return $http.get('/TaskManagement/GetSubtaskEditPage/' + token).then(resp => {
                resp.data.Cultures = LanguageSelectorService.updateLanguagesInfo(resp.data.Cultures);
                return resp;
            });
        }

        function getSubtaskCreatePage(token) {
            return $http.get('/TaskManagement/GetSubtaskCreatePage/' + token).then(resp => {
                resp.data.Cultures = LanguageSelectorService.updateLanguagesInfo(resp.data.Cultures);
                return resp;
            });
        } 

        function saveUpdatedSubtask(subtask, parentIssueSubtaskToken) {
            const model = {
                ParentIssueSubtaskToken: parentIssueSubtaskToken,
                ...subtask,
                Medias: subtask?.Medias.map(m => m.MediaId),
                LocaleList: subtask.LocaleList
                    .filter(locale => locale.Title?.length || locale.Summary?.length)
                    .map(({CultureId, Title, Summary, AutoTranslated }) => ({CultureId, Title, Summary, AutoTranslated}))
            }
            return $http.post('/TaskManagement/EditSubtask', model);
        }

        function deleteSubtask(parentIssueToken, subtaskToken) {
            return $http.post('/TaskManagement/DeleteSubtask', { ParentIssueSubtaskToken: parentIssueToken, IssueSubtaskToken: subtaskToken });
        }

        function completeSubtask(token) {
            return $http.post('/TaskManagement/CompleteSubtask/' + token);
        }

        function subtaskReopen(token) {
            return $http.post('/TaskManagement/ReopenSubtask/' + token);
        }

        function translateSubtask(localeList, index) { 
            return TranslationService.translateSubTask($stateParams.extra, localeList, index)
        }
    }
})();