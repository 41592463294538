(function () {
    'use strict';

    angular.module('App')
        .factory('CreateSubtaskOnlyTitleService',
            ['$http', 'PopupWrapperService', CreateSubtaskOnlyTitleService]);

    function CreateSubtaskOnlyTitleService($http, PopupWrapperService) {
        return {
            openCreateSubtaskOnlyTitlePopup: openCreateSubtaskOnlyTitlePopup,
            createSubtasks: createSubtasks,
        };

        function openCreateSubtaskOnlyTitlePopup(issue) {
            PopupWrapperService.createDynamic('<create-subtask-only-title parent-issue-token="parentIssueToken" issue-token="issueToken"></create-subtask-only-title>', { parentIssueToken: issue.ParentIssueToken, issueToken: issue.IssueToken });
        }

        function createSubtasks(parentIssueToken, issueToken, subtasksList) {
            const subtaskListWithMediaIds = subtasksList.map(subtask => {
                return {
                    ...subtask,
                    Medias: subtask.Medias.map(media => media.MediaId)
                };
            });
            return $http.post('/TaskManagement/CreateSubtasks', { ParentIssueToken: parentIssueToken, IssueToken: issueToken, Subtasks: subtaskListWithMediaIds });
        }
    }
})();