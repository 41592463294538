(() => {
    'use strict';

    angular
        .module('App')
        .component('filterTasksPopup', {
            template: require('./FilterTasksPopup.html'),
            controller: ['$scope', '$element', FilterTasksController],
            controllerAs: 'ctrl',
            bindings: {
                onPopupRegistered: '<'
            }
        });

    function FilterTasksController($scope, $element) {
        var ctrl = this, popup;

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.onReady = onReady;
        ctrl.loading = true;

        function onOpen(currentFilterModel, popupCtrl) {
            ctrl.currentFilterModel = currentFilterModel;
            popup = popupCtrl;
        }

        function close() {
            popup.remove();
            $element.remove()
        }
        
        function onReady() {
            ctrl.loading = false;
        }
    }
})();
