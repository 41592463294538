(function () {
    'use strict';

    angular
        .module('App')
        .component('reaction', {
            template: require('./ReactionComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['ServiceFormRestService', ReactionController],
            bindings: {
                hideCount: '<',
                isReacted: '=',
                reactionCount: '=',
                fillingToken: '<',
                reactions: '=',
                shouldBeExcluded: '<'
            }
        });

    function ReactionController(ServiceFormRestService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.onReactionClick = onReactionClick;

        function init() {
            ctrl.isLoading = false;
        }


        function onReactionClick() {
            if (!ctrl.isReacted) {
                postReaction();
            } else {
                removeReaction();
            }
        }

        function removeReaction() {
            ctrl.isLoading = true;
            ServiceFormRestService.deleteReaction(ctrl.fillingToken).then(function () {
                if (!ctrl.shouldBeExcluded) {
                    ctrl.reactionCount--;
                }
                ctrl.isReacted = false;
            }).finally(function () {
                ctrl.isLoading = false;
            });
        }

        function postReaction() {
            ctrl.isLoading = true;
            ServiceFormRestService.postReaction(ctrl.fillingToken).then(function (data) {
                if (!ctrl.shouldBeExcluded) {
                    ctrl.reactionCount++;
                }
                ctrl.reactions = data;
                ctrl.isReacted = true;
            }).finally(function () {
                ctrl.isLoading = false;
            });
        }
    }
})();
