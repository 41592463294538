(function () {
    angular.module('App')
        .factory('ModuleSearchService',
            ['$rootScope', function ($rootScope) {

                return {
                    enableSearch: enableSearch, 
                    disableSearch: disableSearch,
                    searchReset: null
                };

                function enableSearch(settings) {
                    $rootScope.moduleSearchSettings = settings;
                    $rootScope.moduleSearchEnable = true;
                }

                function disableSearch() {
                    $rootScope.moduleSearchSettings = {};
                    $rootScope.moduleSearchEnable = false;
                }
            }])
})();