(function () {
    'use strict';

    angular
        .module('App')
        .component('formsOverview', {
            template: require('./FormsOverviewComponent.html'),
            controller: ['$http', '$stateParams', 'Page', 'ToastFactory', FormsOverviewController],
            controllerAs: 'ctrl'
        });

    function FormsOverviewController($http, $stateParams, Page, ToastFactory) {
        const ctrl = this;
        ctrl.openForm = openForm;
        ctrl.$onInit = init;

        function init() {
            ctrl.loading = true;

            $http.post('/ServiceForm/GetData/' + $stateParams.token)
                .then(resp => {
                    if (resp.data.Forms && resp.data.Forms.length === 1) {
                        Page.stateGo(
                            'serviceForm',
                            $stateParams.token,
                            `?ServiceFormId=${resp.data.Forms[0].ServiceFormId}`,
                            Page.getPageTransition(),
                            Object.assign({badge: resp.data.Forms[0].Badge}, $stateParams.data)
                        );
                    } else {
                        this.forms = resp.data.Forms;
                        this.options = $stateParams.data
                        Page.setTitle(resp.data.PageTitle);
                        ctrl.loading = false;
                    }
                })
                .catch(er => {
                    ToastFactory.errorTranslated('ERROR.GENERAL');
                    ctrl.loading = false;
                });
        }

        function openForm({ServiceFormId, Badge}) {
            Page.stateGo(
                'serviceForm',
                $stateParams.token,
                `?ServiceFormId=${ServiceFormId}`,
                Page.getPageTransition(),
                Object.assign({badge: Badge}, ctrl.options)
            );
        }
    }
})();


