(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationsSettingPage', {
            template: require('./NotificationsSettingPageComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['Profile', NotificationsSettingPageController],
            bindings: {}
        });

    function NotificationsSettingPageController(Profile) {
        const ctrl = this;

        ctrl.settings = {};
        ctrl.isLoading = true;

        ctrl.$onInit = function () {
            ctrl.isLoading = true;

            Profile.getNotificationSettings().then((response) => {
                ctrl.settings = response;
            }).finally(() => {
                ctrl.isLoading = false;
            });
        };
    }
})();