(() => {
    'use strict';

    angular
        .module('App')
        .factory('FilterPopupService', ['PopupWrapperService', function (PopupWrapperService) {
            return {
                openFiltersPopup: openFiltersPopup,
                getSortingOptions: getSortingOptions
            }

            function openFiltersPopup(isCalendar) {
                PopupWrapperService.createDynamic(
                    '<filter-popup is-calendar="isCalendar"></filter-popup>', {
                        isCalendar: isCalendar
                    })
            }

            function getSortingOptions() {
                return [
                    {id: 1, name: 'CALENDAR.FILTER.COMING_UP', value: 1, icon: 'calendar-upcoming'},
                    {id: 2, name: 'CALENDAR.FILTER.RECENTLY_ADDED', value: 2, icon: 'clock'},
                    {id: 3, name: 'CALENDAR.FILTER.MOST_POPULAR', value: 3, icon: 'star'},
                ]
            }
        }])
})();