(() => {
    'use strict';

    angular
        .module('App')
        .component('multiplePermissionSelectorWrapper', {
            template: require('./MultiplePermissionSelectorWrapper.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$scope', '$http', '$filter', 'PopupWrapperService', 'ConfirmPopupService',
                'ActionSheetCustomService', 'MultiplePermissionSelectorService', MultiplePermissionSelectorWrapperController],
            require: {
                ngModel: 'ngModel'
            },
            bindings: {
                isMultiple: '<',
                permissions: '=?',
                options: '<',
                isLoaded: '<',
                preselectedDepartment: '<',
                isEdit: '<',
                moduleToken: '@',
                showValidation: '<'
            }
        });

    function MultiplePermissionSelectorWrapperController($timeout, $scope, $http, $filter, PopupWrapperService,
                                                         ConfirmPopupService, ActionSheetCustomService, MultiplePermissionSelectorService) {
        const ctrl = this;

        ctrl.PERMISSIONS_TYPE = MultiplePermissionSelectorService.getRequiredPermissionId();
        ctrl.INFO_POPUP_TYPES = MultiplePermissionSelectorService.getInfoPopupTypes();
        ctrl.selectedPermissions = [];
        ctrl.isEdited = false;
        ctrl.defaultPermissionsOnInit = [];

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.reset = reset;
        ctrl.openPermissionSelectorPopup = openPermissionSelectorPopup;
        ctrl.deletePermission = deletePermission;
        ctrl.permissionsEqualityCheck = permissionsEqualityCheck;
        ctrl.openInfoPopup = openInfoPopup;

        ctrl.permissionsWatcher = $scope.$watch('ctrl.selectedPermissions', (newVal) => {
            if (newVal?.length > 0) {
                $timeout(() => {
                    ctrl.ngModel.$setViewValue(_.cloneDeep(ctrl.selectedPermissions))
                })
                ctrl.isEdited = !ctrl.isMultiple && !ctrl.permissionsEqualityCheck(ctrl.selectedPermissions, ctrl.defaultPermissionsOnInit);
            } else {
                ctrl.isEdited = false;
            }
        }, true);

        function init() {
            if (!ctrl.Departments?.length && !ctrl.UserGroups?.length && ctrl.options) {
                ctrl.isLoading = true;
                $http.post('Directives/PermissionSelector/GetSelector', {
                    userGroupsLimited: ctrl.options.userGroupsLimited,
                    departmentFilterType: ctrl.options.departmentFilterType,
                    preselectedDepartment: ctrl.preselectedDepartment
                }).then(resp => {
                    ctrl.Departments = resp.data.Departments;
                    ctrl.UserGroups = resp.data.UserGroups.map(group => {
                        return group.IsEveryoneRole ? {
                            ...group,
                            Name: $filter('translate')(group.Name)
                        } : group
                    });
                    ctrl.isLoaded && ctrl.isLoaded();
                    setValidation();

                    if (ctrl.options.requirePermissionId === ctrl.PERMISSIONS_TYPE.departments || ctrl.options.requirePermissionId === ctrl.PERMISSIONS_TYPE.none) {
                        ctrl.defaultUserGroup = ctrl.UserGroups.find(group => group.IsEveryoneRole);
                    }
                    ctrl.defaultDepartment = ctrl.options.requireDepartment && ctrl.Departments.find(department => department.IsPreselected);

                    ctrl.defaultPermissions = [
                        {
                            DepartmentIds: (ctrl.defaultDepartment ? [ctrl.defaultDepartment] : []).map(department => department.DepartmentId),
                            UserGroupIds: ctrl.defaultUserGroup?.RoleId ? [ctrl.defaultUserGroup.RoleId] : [],
                            MatchAllGroups: ctrl.options.matchAllGroups,
                            ModuleItemPermissionId: ctrl.ngModel.$viewValue?.ModuleItemPermissionId
                        }
                    ];

                    ctrl.defaultPermissionsOnInit = [...ctrl.defaultPermissions];

                    if (ctrl.isEdit && !ctrl.isMultiple) {
                        ctrl.ngModel.$setViewValue(ctrl.ngModel.$viewValue);
                    }

                    if (!ctrl.ngModel.$viewValue) {
                        ctrl.ngModel.$setViewValue(ctrl.defaultPermissions);
                        if (!ctrl.isMultiple) {
                            ctrl.selectedPermissions = ctrl.ngModel.$viewValue;
                        }
                    } else if (ctrl.ngModel.$viewValue[0] && ctrl.ngModel.$viewValue[0].UserGroupIds.length === 0 && ctrl.ngModel.$viewValue[0].DepartmentIds.length === 0) {
                        ctrl.permissions = ctrl.defaultPermissions;
                        ctrl.selectedPermissions = ctrl.ngModel.$viewValue;
                    } else {
                        ctrl.selectedPermissions = ctrl.ngModel.$viewValue;
                    }
                    ctrl.isLoading = false
                });
            }
        }

        function setValidation() {
            ctrl.ngModel.$validators.required = (modelValue, viewValue) => {
                ctrl.isValid = validate();

                function validate() {
                    switch (ctrl.options.requirePermissionId) {
                        case ctrl.PERMISSIONS_TYPE.none:
                            return true
                        case ctrl.PERMISSIONS_TYPE.departments:
                            return !!modelValue.length
                                && modelValue.every(val => val?.DepartmentIds?.length)
                        case ctrl.PERMISSIONS_TYPE.userGroups:
                            return !!modelValue.length
                                && modelValue.every(val => val?.UserGroupIds?.length)
                        case ctrl.PERMISSIONS_TYPE.departmentsAndUserGroups:
                            return !!modelValue.length
                                && modelValue.every(val => val?.DepartmentIds?.length)
                                && modelValue.every(val => val?.UserGroupIds?.length)
                        case ctrl.PERMISSIONS_TYPE.departmentsOrUserGroups:
                            return !!modelValue.length
                                && (modelValue.every(val => val?.DepartmentIds?.length) || modelValue.every(val => val?.UserGroupIds?.length))
                    }
                }

                return ctrl.isValid;
            }
        }

        function permissionsEqualityCheck(permission1, permission2) {
            return _.isEqual(permission1[0].DepartmentIds, permission2[0].DepartmentIds) &&
                _.isEqual(permission1[0].UserGroupIds, permission2[0].UserGroupIds);
        }

        function openPermissionSelectorPopup(index) {
            PopupWrapperService.createDynamic('<multiple-permission-selector-popup' +
                ' departments="departments"' +
                ' user-groups="userGroups"' +
                ' settings="settings"' +
                ' index="index"' +
                ' module-token="moduleToken"' +
                ' match-all-groups="matchAllGroups"' +
                ' default-permissions="defaultPermissions"' +
                ' is-multiple="isMultiple"' +
                ' selected-permissions="selectedPermissions"' +
                ' require-permission-id="requirePermissionId"' +
                ' show-validation="showValidation"' +
                '></multiple-permission-selector-popup>',
                {
                    departments: ctrl.Departments,
                    settings: ctrl.options,
                    userGroups: ctrl.UserGroups,
                    index: index,
                    moduleToken: ctrl.moduleToken,
                    defaultPermissions: ctrl.defaultPermissionsOnInit,
                    isMultiple: ctrl.isMultiple,
                    selectedPermissions: ctrl.selectedPermissions,
                    matchAllGroups: matchAllGroupsValue(index),
                    requirePermissionId: ctrl.options.requirePermissionId,
                    showValidation: ctrl.showValidation && !ctrl.isValid
                });
        }

        function matchAllGroupsValue(index) {
            if (ctrl.ngModel.$viewValue && ctrl.ngModel.$viewValue[index] !== undefined) {
                return ctrl.ngModel.$viewValue[index]?.MatchAllGroups;
            } else {
                return ctrl.options.matchAllGroups;
            }
        }

        function deletePermission(index) {
            ConfirmPopupService.open({
                message: $filter('translate')('PERMISSIONS.CONFIRMATION_POPUP'),
            }).then(() => {
                ctrl.selectedPermissions.splice(index, 1);
                if (ctrl.selectedPermissions.length === 0) {
                    ctrl.ngModel.$setViewValue(ctrl.defaultPermissions);
                }
            });
        }

        function reset() {
            ctrl.selectedPermissions.splice(0, ctrl.selectedPermissions.length, ctrl.defaultPermissionsOnInit[0]);
            ctrl.isEdited = false;
        }

        function openInfoPopup() {
            let excludedSections = [];
            const requiredPermissionsId = ctrl.options.requirePermissionId;
            
            if (!requiredPermissionsId) {
                excludedSections = [...excludedSections, ctrl.INFO_POPUP_TYPES.requiredPermissions];
            }
            if (!ctrl.isMultiple || !requiredPermissionsId) {
                excludedSections = [...excludedSections, ctrl.INFO_POPUP_TYPES.permissionSets];
            }
            
            const template =
                `<permissions-info-popup 
                    excluded-sections="excludedSections"
                    required-permissions-id="requiredPermissionsId"
                ></permissions-info-popup>`;

            ActionSheetCustomService.create(template, {excludedSections, requiredPermissionsId});
        }

        function destroy() {
            ctrl.permissionsWatcher && ctrl.permissionsWatcher();
        }
    }
})();
