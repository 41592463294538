(function () {
    'use strict';

    var app = angular.module('App');

    app.component('percentageCircle', {
        templateUrl: '/Scripts/Components/PercentageCircle/PercentageCircleComponent.html',
        controllerAs: 'ctrl',
        bindings: {
            value: '<',
            showValueLabel: '<'
        },
        controller: ['$element', PercentageCircleController]
    });
    
    function PercentageCircleController($element) {
        var ctrl = this;

        ctrl.$onInit = function () {

        }
    }
})();