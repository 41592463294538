(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('bookmarks', {
                    parent: 'base',
                    url: '/bookmarks',
                    template: require('./BookmarksController.html'),
                    params: {
                        data: {}
                    },
                    controller: 'BookmarksController as ctrl'
                })
        }])
        .controller("BookmarksController", ['$translate', 'Page',
            function ($translate, Page) {
                const ctrl = this

                Page.showBackButton('home');

                // Set Page Title
                $translate('BOOKMARK.TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });
            }
        ]);
})();
