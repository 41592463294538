(() => {
    'use strict';

    angular
        .module('App')
        .component('customerField', {
            template: require('./CustomerFieldComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$templateRequest', '$compile', 'ServiceFormRestService',
                'PopupWrapperService', 'ServiceFormCustomerService', 'ServiceFormRenderService', CustomerFieldController],
            require: {
                ngModelCtrl: 'ngModel'
            },
            bindings: {
                field: '<',
                index: '<',
                listData: '<',
                selectClosestCustomer: '<',
                calculateDistances: '<',
                showLabelTitle: '<',
                hideAddCustomerButton: '<',
                filters: '<',
                onFieldInit: '<',
                accountModuleToken: '<',
                isLimitedByRule: '<',
            }
        });

    function CustomerFieldController($scope, $rootScope, $templateRequest, $compile, ServiceFormRestService,
                                     PopupWrapperService, ServiceFormCustomerService, ServiceFormRenderService) {

        const ctrl = this, scope = $scope.$new(),
            shouldCalculateDistance = ctrl.calculateDistances && ServiceFormRenderService.getGpsSettings().IsGpsTrackingEnabled;

        let popupElement, popupInstance, fillingLocationWatcher, dirtyWatcher;


        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.lazyLoadItems = lazyLoadItems;
        ctrl.createCustomer = createCustomer;
        ctrl.onClosePopup = onClosePopup;
        ctrl.onRegistered = onRegistered;
        ctrl.updateCreatedCustomer = updateCreatedCustomer;

        dirtyWatcher = $scope.$watch('ctrl.field.model', (val) => {
            if (val !== undefined) {
                ctrl.ngModelCtrl.$setDirty();
                dirtyWatcher();
            }
        })

        fillingLocationWatcher = $rootScope.$on('fillingLocation.changed', function () {
            if (!_.isEmpty(ctrl.listData.Items)) {
                ServiceFormCustomerService
                    .calculateDistances(ctrl.listData.Items, shouldCalculateDistance)
                    .then(function (listData) {
                        ctrl.listData.Items = listData;
                    });
            }
        });


        function init() {
            ctrl.popupId = 'customerPopupId';

            ctrl.ngModelCtrl.$isEmpty = (val) => {
                return val === undefined || !val.length
            }

            if (ctrl.showLabelTitle) {
                ctrl.labelTitle = ctrl.field.Title;
            }
            if (!_.isEmpty(ctrl.listData.Items)) {
                ServiceFormCustomerService
                    .calculateDistances(ctrl.listData.Items, shouldCalculateDistance)
                    .then(listData => {
                        ctrl.listData.Items = listData;
                        if (ctrl.selectClosestCustomer && !ctrl.field.model) {
                            ctrl.field.model = [listData[0]];
                        }
                        ctrl.onFieldInit && ctrl.onFieldInit();
                    });
            } else if (ctrl.field.DataListId && ctrl.selectClosestCustomer && shouldCalculateDistance) {
                ServiceFormCustomerService
                    .getClosestCustomer(ctrl.field.DataListId, shouldCalculateDistance)
                    .then(customer => {
                        if (customer) {
                            ctrl.field.model = [customer];
                        }
                        ctrl.onFieldInit && ctrl.onFieldInit();
                    });
            }
        }

        function updateCreatedCustomer(customer) {
            return ServiceFormCustomerService
                .calculateDistances([customer], shouldCalculateDistance)
                .then(function (updatedCustomer) {
                    return updatedCustomer
                });
        }


        function lazyLoadItems() {
            var datalistId = ctrl.field.DataListId;

            return ServiceFormRestService
                .loadDataList(datalistId, ctrl.field.ServiceFormFieldId, true, ctrl.accountModuleToken)
                .then(data => ServiceFormCustomerService
                    .calculateDistances(data.Items, shouldCalculateDistance)
                    .then(listData => listData));
        }
        
        function createCustomer() {
            $templateRequest('/Scripts/Components/ServiceForm/ServiceFormField/CustomerField/CreateCustomerPopup.html')
                .then(function (tpl) {
                    popupElement = $compile(tpl)(scope);
                    angular.element(document.body).append(popupElement);
                });
        }

        function onRegistered() {
            popupInstance = PopupWrapperService.getPopup(ctrl.popupId);
            popupInstance.open();
        }


        function onClosePopup() {
            popupInstance.remove();
            popupElement.remove();
        }

        function destroy() {
            fillingLocationWatcher();
            scope.$destroy();
        }
    }
})();