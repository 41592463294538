(() => {
    'use strict';

    angular
        .module('App')
        .factory('ContentSortingService', [ContentSortingService]);

    function ContentSortingService() {
        return {
            getOptions: getOptions
        };

        function getOptions(isFeed, options) {
            let sortingOptions = [];

            if (isFeed) {
                sortingOptions = [
                    {id: 0, icon: 'list', title: 'CONTENT.SORTING.DEFAULT'},
                ];
            }

            if (options.indexOf(5) !== -1 && !isFeed) {
                sortingOptions.push({id: 5, icon: 'bullseye-arrow', title: 'SORTING.MOST_RELEVANT'});
            }

            if (options.indexOf(6) !== -1 && !isFeed) {
                sortingOptions.push({id: 6, icon: 'arrow-up-1-9', title: 'SORTING.NEWEST_FIRST'});
            }

            if (options.indexOf(7) !== -1 && !isFeed) {
                sortingOptions.push({id: 7, icon: 'arrow-down-1-9', title: 'SORTING.OLDEST_FIRST'});
            }

            if (options.indexOf(1) !== -1) {
                sortingOptions.push({id: 1, icon: 'eye-crossed', title: 'CONTENT.SORTING.UNREAD'});
            }

            if (options.indexOf(2) !== -1) {
                sortingOptions.push({id: 2, icon: 'clock', title: 'CONTENT.SORTING.NEWEST'});
            }

            if (options.indexOf(3) !== -1) {
                sortingOptions.push({id: 3, icon: 'images', title: 'CONTENT.SORTING.MY_POSTS'});
            }

            if (options.indexOf(4) !== -1) {
                sortingOptions.push({id: 4, icon: 'at', title: 'CONTENT.SORTING.MENTIONS'});
            }

            return sortingOptions;
        }
    }
})();