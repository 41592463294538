(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .factory('PromptPopupService', ['$compile', '$q', '$rootScope', function ($compile, $q, $rootScope) {

            return {
                open: open
            };

            function open(opts) {
                return $q(function (resolve, reject) {
                    var defaultOptions, popupTemplate, popupElement,
                        scope = $rootScope.$new(true), options;


                    defaultOptions = {
                        isTextRequired: false,
                    };

                    options = _.merge(defaultOptions, opts);

                    popupTemplate = '<prompt-popup is-text-required="isTextRequired" on-ok="onOk" on-cancel="onCancel"' +
                        ' placeholder-text="{{ placeholderText }}" popup-class="{{ popupClass}}"' +
                        ' popup-title="{{ popupTitle }}" button-text="{{ buttonText }}"' +
                        ' icon="{{ icon }}" icon-prefix="{{ iconPrefix }}"></prompt-popup>';

                    scope = _.assign(scope, {
                        isTextRequired: options.isTextRequired,
                        icon: options.icon,
                        iconPrefix: options.iconPrefix,
                        placeholderText: options.placeholderText,
                        popupClass: options.popupClass,
                        popupTitle: options.popupTitle,
                        buttonText: options.buttonText
                    });

                    scope.onOk = function (text) {
                        resolve(text);
                        bodyScrollLock.enableBodyScroll(popupElement);
                        popupElement.remove();
                    }

                    scope.onCancel = function () {
                        reject();
                        bodyScrollLock.enableBodyScroll(popupElement);
                        popupElement.remove();
                    }

                    popupElement = $compile(popupTemplate)(scope);

                    angular.element(document).find('body').append(popupElement);
                    bodyScrollLock.disableBodyScroll(popupElement);
                });
            }
        }]);
})();