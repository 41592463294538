(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartText', ['$log', '$http', 'Page', 'Profile',
        function ($log, $http, Page, Profile) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartText.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                }
            }
        }
    ]);
})();