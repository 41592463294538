(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('passwordStrength', ['$interval', 'Page', '$translate', function ($interval, Page, $translate) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {

                var startCheck = null;
                var settings = Page.getSettings().LoginSettings.Access;

                // Startup
                if (window.zxcvbn === undefined) {
                    var attempts = 0;

                    // Add zxcvbn script to html
                    var src = '/Content/Plugins/zxcvbn/zxcvbn.js';
                    if ($('[src="' + src + '"]', 'body').length === 0) {
                        $('body').append('<script src="' + src + '" async>');
                    }

                    startCheck = $interval(function () {
                        checkStartup();
                    }, 100);
                } else {
                    // Init straight away
                    init();
                }

                function init() {
                    $translate('PROFILE.ERROR.PASSWORD_TOO_WEAK').then(function (errorMessage) {
                        // Insert elements
                        var html = '<div class="password-strength-container"><div class="password-strength-bar" data-password-strength-value="0">';
                        for (var i = 1; i <= 4; i++) {
                            html += '<div class="password-strength-bar-' + i + '"></div>';
                        }
                        html += '</div><span>' + errorMessage + '</span></div>';

                        var $container = $(html);
                        $container.insertAfter($element);

                        // Listen to changes
                        $($element).on('keyup.pwstr', function () {
                            validate($container);
                        });

                        $($element).on('change.pwstr', function () {
                            validate($container);
                        });

                        $($element).on('paste.pwstr', function () {
                            setTimeout(function () {
                                validate($container);
                            }, 100);
                        });
                    });
                    
                }

                function validate($container) {
                    var val = $($element).val();

                    if (val.length && val.length > 0) {
                        $container.toggleClass('active', true);
                        var res = zxcvbn(val);
                        $container.attr('data-password-strength-value', res.score);

                        // Is valid password
                        var isValid = (res.score >= settings.AppAccessPasswordStrengthType && val.length >= settings.MinimumPasswordLength);
                        $container.toggleClass('valid', isValid);
                    } else {
                        $container.toggleClass('active', false);
                        $container.attr('data-password-strength-value', 0);
                    }
                }

                function checkStartup() {
                    if (window.zxcvbn === undefined) {
                        attempts++;
                        if (attempts > 200) { // Give it up to 20 seconds
                            $interval.cancel(startCheck);
                        }
                    } else {
                        $interval.cancel(startCheck);
                        init();
                    }
                }
            }
        }
    }
    ]);
})();