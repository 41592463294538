(() => {
    'use strict';

    angular.module('App')
        .component('leaveRegistrationPopupComponent', {
            template: require('./LeaveRegistrationPopupComponent.html'),
            controller: ['$element', '$scope', '$filter', 'LeaveRegistrationService', 'ActionSheetCustomService',
                'ToastFactory', 'ConfirmPopupService', 'LEAVE_ERROR_TYPES', 'LEAVE_TYPES_ICONS', LeaveRegistrationPopupComponentController],
            controllerAs: 'ctrl',
            bindings: {
                data: '<',
                isEdit: '<',
                onClose: '<',
            }
        });

    function LeaveRegistrationPopupComponentController($element, $scope, $filter, LeaveRegistrationService, ActionSheetCustomService,
                                                       ToastFactory, ConfirmPopupService, LEAVE_ERROR_TYPES, LEAVE_TYPES_ICONS) {
        const ctrl = this;
        let popup, removeAllDayWatcher, initializing;

        ctrl.model = {};

        ctrl.onOpen = onOpen;
        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.registeredCallback = registeredCallback;
        ctrl.openDropdown = openDropdown;
        ctrl.onSubmit = onSubmit;
        ctrl.onDelete = onDelete;
        ctrl.updateDefaultMessage = updateDefaultMessage;

        ctrl.errorTypes = LEAVE_ERROR_TYPES;

        function init() {
            ctrl.options = ctrl.data.Types.map(type => ({
                ...type,
                title: type.Title,
                id: type.Type,
                icon: LEAVE_TYPES_ICONS[type.Type]
            }));
        }

        function onOpen() {
            ctrl.errorCode = null;
            initializing = true;
            ctrl.model = {...ctrl.data};

            if (ctrl.isEdit) {
                setInitialDateTime();
                const selectedOption = ctrl.options.find(option => option.Type === ctrl.model.Type);
                selectedOption.selected = true;
                ctrl.selectedItem = selectedOption;
                return;
            }

            setInitialDateTime();
            setInitialModel();
            
            if (ctrl.data.isReadOnly) {
                document.getElementsByTagName("textarea")[0].setAttribute("readonly", true)
            }
        }

        function setInitialModel() {
            ctrl.options[0].selected = true;
            ctrl.selectedItem = ctrl.options[0];
            ctrl.model = {
                ...ctrl.model,
                AllDay: true,
            };
            ctrl.model.Comment = parseDefaultMessage(ctrl.selectedItem.DefaultMessage);
        }


        function close() {
            popup.remove();
            $element.remove();
            ctrl.onClose();
            removeAllDayWatcher && removeAllDayWatcher();
        }

        function openDropdown() {
            ActionSheetCustomService.create(
                `<absence-dropdown-component selected-item="selectedItem" options="options" close-action="closeAction"></absence-dropdown-component>`,
                {
                    selectedItem: ctrl.selectedItem,
                    options: ctrl.options,
                    closeAction: closeAction
                }, 'leave-registration-popup-component');
        }


        function registeredCallback(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function updateDefaultMessage() {
            if (document.querySelector('rl-textarea').classList.contains('ng-pristine') && !ctrl.isEdit) {
                ctrl.model.Comment = parseDefaultMessage(ctrl.selectedItem.DefaultMessage);
            }
        }

        function closeAction(item) {
            if (item?.id) {
                ctrl.selectedItem = item;
                ctrl.model.Comment = parseDefaultMessage(ctrl.selectedItem.DefaultMessage);
            }
        }

        function onDelete() {
            ConfirmPopupService.open({message: 'LEAVE_REGISTRATION.DELETE_CONFIRMATION'}).then(() => {
                LeaveRegistrationService.deleteLeaveRegistration(ctrl.model.LeaveRegistrationToken)
                    .then(({data}) => {
                        if (data.Error) {
                            ToastFactory.warningTranslated(LEAVE_ERROR_TYPES[data.Error]);
                            return;
                        }

                        close()
                    })
                    .catch(() => ToastFactory.errorTranslated('ERROR.GENERAL'))
            });
        }

        function onSubmit() {
            ctrl.errorCode = null;
            ctrl.isEdit ? editLeave() : saveLeave();
        }

        function saveLeave() {
            if (ctrl.inProgress) {
                return;
            }

            ctrl.inProgress = true;

            const startDateFormatted = ctrl.model.AllDay ?
                ctrl.model.StartDate && moment(ctrl.model.StartDate).startOf('day') :
                ctrl.model.StartDateTime && moment(ctrl.model.StartDateTime);

            const endDateFormatted = ctrl.model.AllDay ?
                ctrl.model.EndDate && moment(ctrl.model.EndDate).endOf('day') :
                ctrl.model.EndDateTime && moment(ctrl.model.EndDateTime);
            
            LeaveRegistrationService.createLeaveRegistration({
                Type: ctrl.selectedItem.id,
                StartTime: startDateFormatted?.format('YYYY-MM-DDTHH:mm:ss'),
                EndTime: endDateFormatted?.format('YYYY-MM-DDTHH:mm:ss'),
                Comment: ctrl.model.Comment
            }).then(({data}) => {
                if (data.Error) {
                    ToastFactory.warningTranslated(LEAVE_ERROR_TYPES[data.Error]);
                    ctrl.errorCode = data.Error;
                } else {
                    ToastFactory.successTranslated('LEAVE_REGISTRATION.SUCCESS_MESSAGE');
                    close();
                }
            }).finally(() => ctrl.inProgress = false);
        }

        function parseDefaultMessage(message) {
            const startEx = new RegExp(`#startdate#`, 'g');
            const endEx = new RegExp(`#enddate#`, 'g');

            const dateTimeText = (date) => moment(date).format('DD MMM HH:mm');
            const dateText = (date) => moment(date).format('DD MMM');

            if ((ctrl.model.AllDay && ctrl.model.StartDate) || (!ctrl.model.AllDay && ctrl.model.StartDateTime)) {
                message = $filter('translate')(message).replace(startEx,
                    ctrl.model.AllDay ? dateText(ctrl.model.StartDate) : dateTimeText(ctrl.model.StartDateTime));
            }

            if ((ctrl.model.AllDay && ctrl.model.EndDate) || (!ctrl.model.AllDay && ctrl.model.EndDateTime)) {
                message = $filter('translate')(message).replace(endEx,
                    ctrl.model.AllDay ? dateText(ctrl.model.EndDate) : dateTimeText(ctrl.model.EndDateTime));
            }

            return $filter('translate')(message);
        }

        function editLeave() {
            if (ctrl.inProgress) {
                return;
            }

            ctrl.inProgress = true;
            
            const startDateFormatted = ctrl.model.AllDay ?
                ctrl.model.StartDate && moment(ctrl.model.StartDate).startOf('day') :
                ctrl.model.StartDateTime && moment(ctrl.model.StartDateTime);

            const endDateFormatted = ctrl.model.AllDay ?
                ctrl.model.EndDate && moment(ctrl.model.EndDate).endOf('day') : 
                ctrl.model.EndDateTime && moment(ctrl.model.EndDateTime);

            LeaveRegistrationService.updateLeaveRegistration(ctrl.model.LeaveRegistrationToken, {
                Type: ctrl.selectedItem.id,
                StartTime: startDateFormatted?.format('YYYY-MM-DDTHH:mm:ss'),
                EndTime: endDateFormatted?.format('YYYY-MM-DDTHH:mm:ss'),
                Comment: ctrl.model.Comment
            }).then(({data}) => {
                if (data.Error) {
                    ctrl.errorCode = data.Error;
                    ToastFactory.warningTranslated(LEAVE_ERROR_TYPES[data.Error]);
                } else {
                    ToastFactory.successTranslated('LEAVE_REGISTRATION.SUCCESS_MESSAGE');
                    close();
                }
            }).finally(() => ctrl.inProgress = false);
        }

        function setInitialDateTime() {
            if (ctrl.isEdit) {
                ctrl.model.StartDate = new Date(ctrl.data.StartTime);
                ctrl.model.EndDate = new Date(ctrl.data.EndTime);

                ctrl.model.StartDateTime = moment(ctrl.data.StartTime).toDate();
                ctrl.model.EndDateTime = moment(ctrl.data.EndTime).toDate();
            } else {
                ctrl.model.StartDate = new Date(moment().format());
                ctrl.model.EndDate = new Date(moment().format());
            }

            removeAllDayWatcher = $scope.$watch('ctrl.model.AllDay', (allDay) => {
                if (initializing) {
                    initializing = false;
                    return false;
                }

                if (allDay) {
                    ctrl.model.StartDate = new Date(moment(ctrl.model.StartDateTime).startOf('day'));
                    ctrl.model.EndDate = new Date(moment(ctrl.model.EndDateTime).endOf('day'));
                } else {
                    ctrl.model.StartDateTime = new Date(moment(ctrl.model.StartDateTime)
                        .day(moment(ctrl.model.StartDate).day())
                        .date(moment(ctrl.model.StartDate).date())
                        .year(moment(ctrl.model.StartDate).year()).seconds(0).millisecond(0));

                    ctrl.model.EndDateTime = new Date(moment(ctrl.model.EndDateTime)
                        .day(moment(ctrl.model.EndDate).day())
                        .date(moment(ctrl.model.EndDate).date())
                        .year(moment(ctrl.model.EndDate).year()).seconds(0).millisecond(0));
                }
            });
        }
    }
})();