(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('embeddedcontent', {
                    parent: 'base',
                    url: '/embeddedcontent/:token',
                    templateUrl: '/EmbeddedContent/Index',
                    resolve: {
                        embeddedData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/EmbeddedContent/GetData/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'EmbeddedContentController as EmbeddedContentCtrl'
                });
        }
    ]);

    app.controller('EmbeddedContentController', ['Page', '$scope', 'embeddedData',
        function (Page, $scope, embeddedData) {
            Page.showBackButton('home');
            Page.setTitle(embeddedData.data.pageTitle);

            $scope.data = embeddedData.data;
        }
    ]);
})();