(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlLoadingPlaceholder', {
            template: require('./RelesysLoadingPlaceholderComponent.html'),
            controllerAs: 'ctrl',
            controller: ['BasicHelper', RlLoadingPlaceholderController],
            bindings: {
                count: '<',
                minWidth: '<',
                maxWidth: '<',
                usePercentage: '<',
                animation: '<'
            }
        })

    function RlLoadingPlaceholderController(BasicHelper) {
        const ctrl = this;

        ctrl.usePercentage = ctrl.usePercentage !== undefined ? ctrl.usePercentage : true;
        ctrl.animation = ctrl.animation !== undefined ? ctrl.animation : true;

        ctrl.rows = BasicHelper.getPlaceholderRows(
            ctrl.count, ctrl.minWidth, ctrl.maxWidth, ctrl.usePercentage
        );
    }
})();