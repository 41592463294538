(function () {
    'use strict';

    angular
        .module('App')
        .component('stageFeedbackPopup', {
            template: require('./StageFeedbackPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'ServiceFormRestService', 'ServiceFormRenderService', 'ToastFactory',
                'TranslationService', StageFeedbackPopupController],
            bindings: {
                stage: '<',
                fillingToken: '<'
            }
        })

    function StageFeedbackPopupController($element, ServiceFormRestService, ServiceFormRenderService, ToastFactory,
                                          TranslationService) {
        const ctrl = this;
        let popup;

        ctrl.$onInit = init;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.close = close;

        function init() {
            ctrl.settings = {
                AllowAutomaticTranslation: ServiceFormRenderService.fillingInteractionSettings.AllowAutomaticTranslation,
                PreferTranslatedComments: ServiceFormRenderService.fillingInteractionSettings.PreferTranslatedComments,
            }
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();

            ServiceFormRestService
                .getComments(ctrl.fillingToken, ctrl.stage.ServiceFormStageToken, true)
                .then(comments => {
                    ctrl.comments = comments.map(comment => {
                        return {
                            ...comment,
                            showTranslated: comment.TranslatedText && ctrl.settings.PreferTranslatedComments
                        }
                    });
                });
        }

        function toggleTranslation(val, comment) {
            if (!comment.TranslatedText) {
                ctrl.isTranslating = true;
                TranslationService
                    .translateComment('ServiceFormStage', null, comment.ServiceFormStageCommentId, comment.Text)
                    .then(resp => {
                        if (resp.data.Text) {
                            comment.TranslatedText = resp.data.Text;
                            comment.showTranslated = val;
                        } else {
                            ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                            comment.CanBeTranslated = false;
                        }
                    })
                    .catch(() => {
                        ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE');
                        comment.CanBeTranslated = false;
                    })
                    .finally(() => {
                        ctrl.isTranslating = false;
                    })
            } else {
                comment.showTranslated = val;
            }
        }

        function close() {
            popup.remove()
            $element.remove();
        }
    }
})();