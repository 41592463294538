(function () {
    'use strict';

    var app = angular.module('App');
    app.controller("AppController", ['$rootScope', '$scope', '$state', 'Page',
        function ($rootScope, $scope, $state, Page) {
            var ctrl = this;
            ctrl.Page = Page;
        }
    ]);

})();