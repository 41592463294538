(() => {
    'use strict';

    angular
        .module('App')
        .component('contentSafetyWarningPopup', {
            template: require('./ContentSafetyWarningPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'ResponsiveService', ContentSafetyWarningPopupController],
            bindings: {
                severityLevel: '<',
                isBlocked: '<',
                onContinue: '<',
                editLabel: '<'
            }
        })

    function ContentSafetyWarningPopupController($element, ResponsiveService) {
        const ctrl = this;

        ctrl.SEVERITY_LEVEL = [null, 'CONTENT_SAFETY.LOW', 'CONTENT_SAFETY.MEDIUM', 'CONTENT_SAFETY.HIGH'];

        ctrl.continueSending = continueSending;
        ctrl.remove = remove;
        ctrl.showCloseButton =  ResponsiveService.isDesktop();

        function continueSending() {
            ctrl.onContinue()
            ctrl.closeAction();
        }

        function remove() {
            $element.remove();
        }
    }
})();