(() => {
    'use strict'
    angular
        .module('Relesys.Ui')
        .component('translationButton', {
            template: require('./TranslationButtonComponent.html'),
            controller: ['$scope', TranslationButtonController],
            controllerAs: 'ctrl',
            bindings: {
                showOriginal: '<',
                toggleTranslation: '<',
                showLoader: '<',
                translatableEntity: '<'
            }
        })

    function TranslationButtonController($scope) {
        var ctrl = this;
        var modelWatcher;

        ctrl.$onDestroy = onDestroy;
        ctrl.$onInit = onInit;
        ctrl.showButton = false;
        ctrl.autoTranslated = false;

        function onInit() {
            if (ctrl.translatableEntity) {
                modelWatcher = $scope.$watch('ctrl.translatableEntity._currentCultureId', function (value) {
                    if (value) {
                        parseEntity();
                    }
                });
            } else {
                ctrl.showButton = true;
            }
        }

        function parseEntity() {
            ctrl.showButton = ctrl.translatableEntity.AllowAutoTranslation || ctrl.translatableEntity.LocalizedAlternativeLocale !== null;

            if (ctrl.translatableEntity._currentCultureId === ctrl.translatableEntity.LocalizedLocale.CultureId) {
                ctrl.showOriginal = false;
                ctrl.autoTranslated = ctrl.translatableEntity.LocalizedLocale.AutoTranslated;
            } else {
                ctrl.showOriginal = true;
                ctrl.autoTranslated = ctrl.translatableEntity.LocalizedAlternativeLocale?.AutoTranslated;
            }
        }

        function onDestroy() {
            if (modelWatcher) {
                modelWatcher();
            }
        }
    }
})();