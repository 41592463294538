(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleQuestionSelector', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleQuestionSelector.html',
                scope: {
                    question: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitController();
                    function InitController() {
                        $scope.question.answered = true;
                    }
                }]
            }
        }
    ]);
})();