(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettingsPersonalInformation', {
                        parent: 'base',
                        url: '/profileSettings/account/personalInformation',
                        template: '<div class="profile-settings-edit-page" ng-class="{ \'floating-footer\': ctrl.isDesktop }">' +
                            '<profile-settings-personal-information can-go-back-requester="ctrl.canGoBackRequester" class="view-page narrow">' +
                            '</profile-settings-personal-information></div>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'ProfileSettingsPersonalInformationController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsPersonalInformationController', ['$stateParams', '$translate', 'Page', '$scope', 'ResponsiveService',
            function ($stateParams, $translate, Page, $scope, ResponsiveService) {
                $translate('PROFILE.SETTINGS.PERSONAL_INFORMATION.PAGE_TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });

                var ctrl = this,
                    screenResizeWatcher;

                ctrl.$onInit = onInit;
                ctrl.$onDestroy = onDestroy;

                ctrl.backState = $stateParams.backState;
                ctrl.canGoBackRequester = null;

                function onInit() {
                    Page.showBackButtonFunction(() => {
                        if (ctrl.canGoBackRequester) {
                            ctrl.canGoBackRequester().then(() => {
                                goBack();
                            });
                        } else {
                            goBack();
                        }
                    });

                    ctrl.isDesktop = ResponsiveService.isDesktop();
                    screenResizeWatcher = $scope.$watch(() => ResponsiveService.isDesktop(), (isDesktop) => {
                        if (isDesktop !== ctrl.isDesktop) {
                            ctrl.isDesktop = isDesktop;
                        }
                    });
                }

                function onDestroy() {
                    if (screenResizeWatcher) {
                        screenResizeWatcher();
                    }
                }

                function goBack() {
                    if (ctrl.backState) {
                        Page.stateGoBack(ctrl.backState.state, ctrl.backState.token, ctrl.backState.extra)
                    } else {
                        Page.stateGoBack('profileSettings');
                    }
                }
            }]);
})();