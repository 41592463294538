(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('actionSheetCustom', {
            template: require('./ActionSheetCustomComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', 'Page', ActionSheetCustomController],
            bindings: {
                headerTitle: '@',
                headerSubtitle: '@',
                onClose: '<',
                closeAction: '=?',
                inline: '<',
                moduleToken: '@',
                moduleName: '@',
                showCloseButton: '<'
            },
            transclude: true
        })

    function ActionSheetCustomController($timeout, Page) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.closeAction = close;

        function init() {
            ctrl.show = true;
            
            ctrl.moduleName = ctrl.moduleName ?? Page.getBaseViewClass();
            ctrl.moduleToken = ctrl.moduleToken ?? Page.getCurrentToken();
        }

        function close(ev) {
            ev && ev.stopPropagation();
            ctrl.show = false;
            $timeout(() => {
                ctrl.onClose && ctrl.onClose(ev);
            }, 300)
        }
    }
})();