(() => {
    'use strict';

    angular.module('App')
        .component('profileSettingsAccessibility', {
            template: require('./ProfileSettingsAccessibilityComponent.tpl.html'),
            controller: ['ProfileSettingsService', ProfileSettingsAccessibilityComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsAccessibilityComponentController(ProfileSettingsService) {
        var ctrl = this;

        ctrl.$onInit = onInit;

        function onInit() {
            ctrl.isLoadingProfileData = true;
            ProfileSettingsService.getFullProfile().then(res => {
                ctrl.dyslexia = ProfileSettingsService.createToggleProperty(res, 'DyslexiaFontEnabled', ProfileSettingsService.updateDyslexiaFontEnabled);
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }
    }
})();