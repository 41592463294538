(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('ServiceFormDataFormatService', ['FIELD_TYPE', 'DISPLAY_TYPE',
        function (FIELD_TYPE, DISPLAY_TYPE) {

            return {
                formatFields: formatFields,
                formatInUseFields: formatInUseFields,
                formatDataForServer: formatDataForServer,
                formatDataForClient: formatDataForClient,
                setModelData: setModelData
            };

            function formatDataForClient(formSource, filling, participants) {
                const form = angular.copy(formSource);
                let currentStepIndex;

                form.forEach((step, key) => {
                    step.Groups.forEach(group => {
                        group.Fields.forEach(field => {
                            const fillingField = filling.Fields.find(item => item.ServiceFormFieldId === field.ServiceFormFieldId);

                            if (fillingField) {
                                formatFieldForClient(field, fillingField, participants);
                                if (field.model && field.ServiceFormFillingValueId && field.model.length) {
                                    currentStepIndex = key;
                                }
                            }
                        });
                    });
                });

                return form;
            }

            function formatFieldForClient(field, fillingField, participants) {
                field.FeedbackData = fillingField.FeedbackData;
                field.model = setModelData(fillingField.Values, field);
                if (participants) {
                    field.participant = addParticipantToField(fillingField, participants);
                }
                if (fillingField.FilledDateLocalString) {
                    field.filledDateLocalString = fillingField.FilledDateLocalString;
                }
                if (fillingField.IsUsedForFillingDepartment) {
                    field.IsUsedForFillingDepartment = fillingField.IsUsedForFillingDepartment;
                }
            }

            function setModelData(values, field) {
                let model;

                switch (field.FieldType) {
                    case FIELD_TYPE.DATALIST:
                    case FIELD_TYPE.MEDIA:
                    case FIELD_TYPE.NUMBER:
                        model = [];
                        values.forEach(value => {
                            model.push(formatDataToModel(field.FieldType, value, field.DisplayType));
                        })
                        break;
                    default:
                        model = formatDataToModel(field.FieldType, _.head(values));
                        break;
                }

                if (values && values.length) {
                    field.ServiceFormFillingValueId =
                        _.head(values).ServiceFormFillingValueId ? _.head(values).ServiceFormFillingValueId : null;
                }

                return model;
            }

            function addParticipantToField(field, fillingParticipants) {
                return fillingParticipants.find(el => el.UserId === field.FilledByUserId);
            }

            function formatDataToModel(type, value, displayType) {
                switch (type) {
                    case FIELD_TYPE.DATALIST:
                        value.DataListItem.ServiceFormFillingValueId = value.ServiceFormFillingValueId;
                        if (displayType === DISPLAY_TYPE.CHECK_LIST) {
                            value.DataListItem.checked = true;
                            return value.DataListItem
                        } else {
                            return value.DataListItem
                        }
                    case FIELD_TYPE.MEDIA:
                        value.Media.ServiceFormFillingValueId = value.ServiceFormFillingValueId;
                        return value.Media;
                    case FIELD_TYPE.NUMBER:
                        return value.Number;
                    default:
                        return (value !== undefined) ? value.Value : null;
                }
            }

            function formatDataForServer(form) {
                let formattedData = [];

                form.forEach(function (step) {
                    step.Groups.forEach(function (group) {
                        formattedData = formattedData.concat(formatFields(group.Fields));
                    });
                });

                return formattedData;
            }

            function formatFields(fields, isCustomField) {
                let formattedData = [];

                fields.forEach(function (field) {
                    if (field.IsVisibleOnFormLoad && (field.model ||
                        (field.FieldType === FIELD_TYPE.NUMBER && field.model != null))) {
                        if (!angular.isArray(field.model)) {
                            formattedData.push(mapFiled(field.ServiceFormFieldId || field.DataListCustomFieldId, field.model, field.FieldType, isCustomField, field));
                        } else {
                            field.model.forEach(function (modelItem) {
                                formattedData.push(mapFiled(field.ServiceFormFieldId || field.DataListCustomFieldId, modelItem, field.FieldType, isCustomField));
                            })
                        }
                    }
                });

                return formattedData;
            }
            
            function formatInUseFields(fields, fieldInUse) {
                return fields?.map(field => {
                    const isInUse = fieldInUse.DataListItemIds?.includes(field.DataListItemId);
                    return {
                        ...field,
                        inUse: isInUse,
                        actionType: isInUse && fieldInUse.ActionType
                    };
                });
            }

            function mapFiled(fieldId, model, fieldType, isCustomField, field) {
                let ServiceFormFillingValueId = null, formattedField;

                if (model?.ServiceFormFillingValueId || (field && field.ServiceFormFillingValueId)) {
                    ServiceFormFillingValueId = model.ServiceFormFillingValueId || field.ServiceFormFillingValueId;
                }

                if (!isCustomField) {
                    formattedField = {
                        ServiceFormFieldId: fieldId,
                        Value: formatFieldData(model, fieldType),
                        ServiceFormFillingValueId: ServiceFormFillingValueId
                    };
                } else {
                    formattedField = {
                        DataListCustomFieldId: fieldId,
                        Value: formatFieldData(model, fieldType)
                    }
                }

                return formattedField;
            }

            function formatFieldData(model, type) {

                switch (type) {
                    case FIELD_TYPE.DATALIST:
                        return model?.DataListItemId;
                    case FIELD_TYPE.MEDIA:
                        return model?.MediaId;
                    default:
                        return model;
                }
            }
        }]);
})();
