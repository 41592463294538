(() => {
    'use strict';

    angular
        .module('App')
        .component('searchPanel', {
            template: require('./SearchPanelComponent.html'),
            controllerAs: 'ctrl',
            controller: ['CalendarRenderService', 'FilterPopupService', SearchPanelController],
            bindings: {
                searchString: '=?',
                search: '<',
                currentView: '<'
            }
        })

    function SearchPanelController(CalendarRenderService, FilterPopupService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.openFilters = openFilters;

        function init() {
            ctrl.getFilters = CalendarRenderService.getFilters;
        }

        function openFilters() {
            FilterPopupService.openFiltersPopup(ctrl.currentView === 1);
        }
    }
})();