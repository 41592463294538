(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('toDoDay', ['$timeout', '$animate', '$http', '$translate', 'toastr', '$rootScope',
        function ($timeout, $animate, $http, $translate, toastr, $rootScope) {
            return {
                restrict: 'E',
                templateUrl: '/Templates/Modules/ToDo/ToDoDay.Directive.tpl.html',
                link: function ($scope, $element, $attrs) {
                    $scope.loaded = false;
                    $scope.showEndPage = false;
                    $scope.swiperIndex = 0;

                    // Init
                    InitDirective();
                    function InitDirective() {
                        // Fade in
                        $timeout(function () {
                            $scope.loaded = true;
                        });

                        $scope.swiper = null;
                        $scope.swiperParams = {
                            allowSwipeToPrev: true,
                            allowSwipeToNext: true,
                            noSwiping: false,
                            //pagination: '.swiper-pagination',
                            paginationHide: true,
                            //paginationType: ($scope.showProgressBar) ? 'progress' : 'bullets'
                        };

                        $scope.Date = moment($scope.Day.Date);
                    }

                    // Methods
                    function Close() {
                        var elem = $('.todo-day', $element);

                        $animate.on('leave', elem, function (element, phase) {
                          if (phase == 'close') {
                            $scope.$destroy();
                            $element.remove();
                          }
                        });

                        $scope.loaded = false;
                        $scope.Callback();
                    }

                    function SwiperLoaded(swiper) {
                        $scope.swiper = swiper;

                        swiper.on('slideChange', function () {
                            $rootScope.safeApply(function () {
                                $scope.swiperIndex = $scope.swiper.activeIndex;
                            });
                        });
                    }

                    function Check(activity, type) {

                        // Post answer
                        $http.post('/Module/ToDo/RegisterActivity', {
                            ToDoActivityToken: activity.ToDoActivityToken,
                            Date: $scope.Date.format('YYYY-MM-DD'),
                            IsConfirmed: type
                        }).then(function (response) {
                            if (!response.data.Success) {
                                // Error, send user back
                                $translate('ERROR.GENERAL').then(function (translation) {
                                    toastr.error(translation);
                                });
                                Prev();
                            }
                        }, function () {
                            // Error, send user back
                            $translate('ERROR.GENERAL').then(function (translation) {
                                toastr.error(translation);
                            });
                            Prev();
                        });

                        // Go to next
                        Next();
                    }

                    function Next() {
                        if ($scope.loaded && !$scope.showEndPage) {
                            var index = $scope.swiper.activeIndex;
                            var count = $scope.swiper.slides.length;

                            if (index < (count - 1) && !$scope.Settings.EnableSingleMode) {
                                $scope.swiper.slideNext();
                            } else {
                                Finish();
                            }
                        }
                    }

                    function Finish() {
                        if ($scope.Settings.EnableEndPage && angular.isObject($scope.EndPage)) {
                            // Show EndPage
                            $scope.swiper.destroy();
                            $scope.showEndPage = true;
                        } else {
                            // Close straight away
                            Close();
                        }
                    }

                    function Prev() {
                        if ($scope.loaded && !$scope.showEndPage) {
                            $scope.swiper.slidePrev();
                        }
                    }

                    // Bindings
                    $scope.Close = Close;
                    $scope.Check = Check;
                    $scope.SwiperLoaded = SwiperLoaded;
                }
            }
        }
    ]);
})();