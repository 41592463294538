(function () {
    'use strict';

    angular.module('App')
        .component('starsRating', {
            templateUrl: '/Scripts/Components/StarsRating/StarsRatingComponent.html',
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', StarsRatingController],
            bindings: {
                rating: '=',
                editable: '<'
            }
        });

    function StarsRatingController($element, $scope) {
        var ctrl = this;

        ctrl.$onInit = init;

        $scope.$watch('ctrl.rating', function (newVal) {
            if (newVal) {
                ctrl.raitingPercent = Math.round(ctrl.rating * 100 / 5);
            }
        });

        function init() {
            ctrl.editable && $element.find('> div').on('click', 'rl-icon', function (ev) {
                ctrl.editable && $scope.$apply(function () {
                    ctrl.rating = $(ev.currentTarget).index() + 1;
                    ctrl.raitingPercent = Math.round(ctrl.rating * 100 / 5);
                });
            })
        }
    }
})();