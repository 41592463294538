(function () {
    'use strict';

    angular.module('App')
        .factory('CancelEventService', ['$http', 'ActionPopupService', function ($http, ActionPopupService) {

            return {
                cancelEvent: cancelEvent,
            };

            function cancelEvent(event, onCancelEventCallback) {
                if (event.IsRecurringChild) {
                    return openRecurrentPopup(event.Title, function (cancelType) {
                        openCancelEventPopup(event, cancelType, onCancelEventCallback)
                    })
                } else {
                    openCancelEventPopup(event, null, onCancelEventCallback)
                }
            }

            function getEventCultures(token) {
                return $http.post('Calendar/GetCancelEventPage/' + token).then(function (resp) {
                    return resp.data.AvailableCultures;
                })
            }

            function openRecurrentPopup(eventName, onAnswer) {
                ActionPopupService.create('<cancel-recurring-event-popup ' +
                    'event-name="{{eventName}}" on-answer="onAnswer"' +
                    '></cancel-recurring-event-popup>', {
                    eventName: eventName,
                    onAnswer: onAnswer
                })
            }

            function openCancelEventPopup(event, cancelType, onCancelEvent) {
                var availableCultures = getEventCultures(event.CalendarEventToken);
                ActionPopupService.create('<cancel-event-popup' +
                    ' event-name="{{eventName}}" event-token="eventToken"' +
                    ' cancel-type="cancelType" on-cancel-event="onCancelEvent"' +
                    ' available-cultures="availableCultures"' +
                    '></cancel-event-popup>', {
                    eventName: event.Title,
                    eventToken: event.CalendarEventToken,
                    cancelType: cancelType,
                    onCancelEvent: onCancelEvent,
                    availableCultures: availableCultures,
                })
            }
        }]);
})();   