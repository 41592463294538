(() => {
        'use strict';

        angular.module('App')
            .component('profileSettingsSharedInformation', {
                template: require('./ProfileSettingsSharedInformationComponent.tpl.html'),
                controller: ['ProfileSettingsService', 'Page', ProfileSettingsSharedInformationComponentController],
                controllerAs: 'ctrl'
            });

        function ProfileSettingsSharedInformationComponentController(ProfileSettingsService, Page) {
            var ctrl = this;

            ctrl.$onInit = onInit;

            function onInit() {
                ctrl.hasPrivacyPolicy = 

                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile().then(res => {

                    ctrl.shareBio = ProfileSettingsService.createToggleProperty(res, 'ShareBio');
                    ctrl.shareEmail = ProfileSettingsService.createToggleProperty(res, 'ShareEmail');
                    ctrl.shareSecondaryEmail = ProfileSettingsService.createToggleProperty(res, 'ShareSecondaryEmail');
                    ctrl.sharePhone = ProfileSettingsService.createToggleProperty(res, 'SharePhone');
                    ctrl.shareBusinessPhone = ProfileSettingsService.createToggleProperty(res, 'ShareBusinessPhone');

                    ctrl.hasBio = res.EnableBio;

                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            }
        }
    }
)();