(function () {
    'use strict';

    angular.module('App')
        .component('feedbackInlineComments', {
            template: require('./FeedbackInlineCommentsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', 'ServiceFormRenderService', FeedbackInlineComments],
            bindings: {
                field: '<',
                onOpenComments: '<',
                flatComments: '=?'
            }
        })

    function FeedbackInlineComments($scope, ServiceFormRenderService) {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            ctrl.settings = {
                AllowAutomaticTranslation: ServiceFormRenderService.fillingInteractionSettings.AllowAutomaticTranslation,
                PreferTranslatedComments: ServiceFormRenderService.fillingInteractionSettings.PreferTranslatedComments,
            }
        }

        $scope.$watch('ctrl.field.FeedbackData.Feedback.CommentsCount', () => {
            const flatComments = [];

            ctrl.field.FeedbackData.Feedback.Comments.forEach(comment => {
                flatComments.push(comment);
                if (comment.ChildComments.length) {
                    comment.ChildComments.forEach(childComment => {
                        childComment.isChild = true;
                        flatComments.push(childComment)
                    })
                }
            })
            
            ctrl.showMore = flatComments?.length > 3;
            ctrl.flatComments = flatComments.slice(0, 3);
        });

        ctrl.openComments = openComments;

        function openComments(comment) {
            ctrl.onOpenComments && ctrl.onOpenComments(comment, ctrl.field)
        }
    }
})();