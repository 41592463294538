(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('addToHomeSplash', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/AddToHomeSplash.tpl.html',
                controller: ['$scope', '$element', 'Page', function ($scope, $element, Page) {

                    var settings = Page.getSettings();

                    $scope.Background = settings.SplashScreenMedia;
                    $scope.BackgroundColor = settings.SplashBackgroundColor;
                    $scope.Favicon = settings.FaviconMedia;
                    $scope.Title = settings.Title;

                    // Check for iOS
                    $scope.isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;;

                    $scope.Continue = function () {
                        $element.remove();
                    };                    
                }]
            }
        }
    ]);
})();