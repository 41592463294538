(function () {
    'use strict';

    angular
        .module('App')
        .factory('SimpleUserListService', [
            function () {
                return {
                    setUsersListIsEmpty: setUsersListIsEmpty,
                    getUsersListIsEmpty: getUsersListIsEmpty,
                    setListOfRemovedUsers: setListOfRemovedUsers,
                    getListOfRemovedUsers: getListOfRemovedUsers,
                    listOfUsers: listOfUsers,
                }
            }
        ]);

    let isUserListEmpty = true,
        listOfUsers = [];

    function getListOfRemovedUsers() {
        return listOfUsers;
    }

    function getUsersListIsEmpty() {
        return isUserListEmpty;
    }

    function setListOfRemovedUsers(usersList) {
        listOfUsers = [...usersList];
    }

    function setUsersListIsEmpty(isEmpty) {
        isUserListEmpty = !!isEmpty;
    }
})();