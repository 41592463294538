(function () {
    'use strict';

    angular
        .module('App')
        .component('scrollShadow', {
            transclude: true,
            template: '<span class="gradient first rl-animation-fade" ng-show="ctrl.showFirstGradient"></span>' +
                '<ng-transclude></ng-transclude>' +
                '<span class="gradient last rl-animation-fade" ng-show="ctrl.showLastGradient"></span>',
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$element', '$timeout', ScrollShadowController],
            bindings: {
                direction: '@'
            }
        })

    function ScrollShadowController($rootScope, $scope, $element, $timeout) {
        const ctrl = this;
        let observer, el;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            $timeout(() => {
                el = $element.find('ng-transclude > *');
                el.on('scroll', calculateScroll)

                observer = new MutationObserver((entries) => {
                    calculateScroll(el);
                });
                observer.observe(el[0], {childList: true, subtree: true});

                calculateScroll();
            }, 100)
        }

        function calculateScroll() {
            if (ctrl.direction === 'vertical') {
                $scope.$apply(() => {
                    ctrl.showFirstGradient = el[0].scrollTop > 15;
                    ctrl.showLastGradient = el[0].scrollHeight > (el[0].scrollTop + el[0].offsetHeight);
                })

            }
            if (ctrl.direction === 'horizontal') {
                $scope.$apply(() => {
                    if ($rootScope.isRTL) {
                        ctrl.showFirstGradient = el[0].scrollWidth > (Math.abs(el[0].scrollLeft) + el[0].offsetWidth);
                        ctrl.showLastGradient = Math.abs(el[0].scrollLeft) > 15;
                    } else {
                        ctrl.showFirstGradient = Math.abs(el[0].scrollLeft) > 15;
                        ctrl.showLastGradient = el[0].scrollWidth > (Math.abs(el[0].scrollLeft) + el[0].offsetWidth);
                    }
                })
            }
        }

        function destroy() {
            observer.disconnect();
            el.off('scroll');
        }
    }
})();