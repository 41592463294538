(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('ContentLikesController', ['$scope','$http', 'close', 'content', '$translate', '$timeout', 'Page',
        function ($scope, $http, close, content, $translate, $timeout, Page) {
            $scope.loading = true;
            $scope.userlikes = [];
            Page.setMainContainerInert(true);

            // Create and translate header
            $scope.title = "";
            $translate('LOADING').then(function (translation) {
                $scope.title = translation + "...";
            });

            $scope.close = function () {
                Page.setMainContainerInert(false);
                close();
            };

            $http.get('/ContentModule/GetLikes/' + content.ContentToken)
              .then(function (response) {
                  $scope.userlikes = response.data;
                  $translate('SOCIAL.LIKES').then(function (translation) {
                      $scope.title = $scope.userlikes.length + " " + translation;
                  });

                  $timeout(function () {
                      $scope.loading = false;
                  });
              }, function () {
                  $translate('SOCIAL.LIKES').then(function (translation) {
                      $scope.title = 0 + " " + translation;
                  });

                  $timeout(function () {
                      $scope.loading = false;
                  });
              });
        }
    ]);

})();