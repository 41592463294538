(() => {
    'use strict';

    angular
        .module('App')
        .component('taskManagement', {
            template: require('./TaskManagementComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', '$stateParams', '$rootScope', '$scope', '$element', 'TaskManagementRenderService',
                'TaskDetailsService', 'TemplateFactory', 'HeaderButtonsFactory', 'CreateUpdateTaskService',
                'FilterTasksService', 'Page', 'ModuleSearchService', '$timeout', 'HowToGuideService',
                'ResponsiveService', TaskManagementController],
            bindings: {
                issues: '<',
                filteredIssues: '<',
                nextOffset: '<',
                categories: '<',
                overdueIssuesToShow: '<',
                feedLink: '<',
                moderateAllowed: '<',
                onSwitchView: '<',
                allowManagerView: '<',
                selectedDepartmentName: '@',
                enableHowToGuide: '<',
                showOverdueViewInMyTasks: '<',
                setDefaultBackButton: '<'
            }
        });

    function TaskManagementController($filter, $stateParams, $rootScope, $scope, $element, TaskManagementRenderService, TaskDetailsService,
                                      TemplateFactory, HeaderButtonsFactory, CreateUpdateTaskService, FilterTasksService, Page,
                                      ModuleSearchService, $timeout, HowToGuideService, ResponsiveService) {
        const ctrl = this;
        let scrolledContainer, throttledScroll = _.throttle(scrollEvents, 500),
            taskUpdateListener, taskCreateListener, taskDeleteListener, taskFilterListener,
            taskManagementStateChangeListener, taskSeriesDeleteListener;


        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onScrollNext = onScrollNext;
        ctrl.onScrollPrev = onScrollPrev;
        ctrl.onScrollNextFilter = onScrollNextFilter;
        ctrl.onScrollPrevFilter = onScrollPrevFilter;
        ctrl.changeView = changeView;
        ctrl.openCreateTask = openCreateTask;
        ctrl.openOverdue = openOverdue;
        ctrl.openFilter = openFilter;
        ctrl.removeFilters = removeFilters;
        ctrl.openCalendar = openCalendar;
        ctrl.openTasksList = openTasksList;
        ctrl.openOverlooking = openOverlooking;
        ctrl.getCurrentDate = getCurrentDate;
        ctrl.onSearch = onSearch;

        function init() {
            ctrl.appliedFiltersCount = 0;
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.VIEW = {
                CALENDAR: 1,
                TASKS: 2,
                MY_TASKS: 3,
                SEARCH: 4,
                OVERDUE: 5,
                MANAGER: 6,
                OVERSEEING: 7
            };
            ctrl.initialModel = {
                Token: $stateParams.token,
                StartTime: moment().subtract(1, 'months'),
                EndTime: moment().add(7, 'd'),
                SeparateOverdueTasks: true
            }
            ctrl.processing = true;
            ctrl.processingOverdueTab = true;
            ctrl.processingCalendarTab = true;
            ctrl.isOverdueProcessed = false;
            ctrl.isCalendarProcessed = false;
            ctrl.isOverseeingCalendarProcessed = false;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.currentView = ctrl.VIEW.MY_TASKS;
            ctrl.serviceFormPopupId = TaskDetailsService.serviceFormPopupId;
            ctrl.isNative = TemplateFactory.isNative();
            ctrl.calendarData = {};

            initListeners();
            loadTasks().then(function () {
                if (ctrl.isDesktop) {
                    prepareCalendarTab();
                    ctrl.formattedDate = moment().format('DD MMM YYYY');
                } else {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }
            });

            scrolledContainer = $element.find('.view-type-today');
            scrolledContainer.on('scroll', throttledScroll);

            $scope.$watch('ctrl.selectedDay', day => {
                if (!ctrl.hasFilter) {
                    day?.issues?.sort(function (a, b) {
                        return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                            new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                    });
                }
                showCalendarDay(day);
            }, true)

            if (ctrl.isDesktop) {
                $scope.$watchGroup([
                    'ctrl.processing',
                    'ctrl.processingCalendarTab',
                    'ctrl.isLoadingFilteredTasks'
                ], newValues => {
                    if (newValues) {
                        ctrl.desktopLoader = newValues.includes(true);
                    }
                }, true)
            }
        }

        function getCurrentDate() {
            const prefix = ctrl.selectedDay && moment(ctrl.selectedDay.fullDate).isSame(moment(), 'day') ||
            moment(ctrl.formattedDate, 'DD MMM YYYY').isSame(moment(), 'day') ?
                `${$filter('translate')('CALENDAR.TODAY.TITLE')}, ` : '';
            const selectedDate = prefix + ctrl.formattedDate;
            return selectedDate;
        }

        function onSearch(string) {
            ctrl.searchString = string;
        }

        function initListeners() {
            taskUpdateListener = $rootScope.$on('TaskManagement:taskUpdated', (ev, issue, resort) => {
                taskUpdated(issue, resort);
            });
            taskCreateListener = $rootScope.$on('TaskManagement:taskCreated', () => {
                Page.stateReload();
            });
            taskFilterListener = $rootScope.$on('TaskManagement:tasksFiltered', (ev, response, model, filtersCount, isReset) => {
                handleFiltering(response, model, filtersCount, isReset);
            });
            taskDeleteListener = $rootScope.$on('TaskManagement:taskDeleted', (ev, issue) => {
                taskDeleted(issue);
            });
            taskSeriesDeleteListener = $rootScope.$on('TaskManagement:taskSeriesDeleted', (ev, issue) => {
                taskSeriesDeleted(issue);
            });
            taskManagementStateChangeListener = $scope.$on('TaskManagement:stateChanged', () => {
                if (ctrl.currentView === ctrl.VIEW.OVERDUE || ctrl.currentView === ctrl.VIEW.SEARCH) {
                    Page.showBackButtonFunction(() => {
                        ctrl.changeView(ctrl.VIEW.MY_TASKS);
                    });
                } else {
                    ctrl.setDefaultBackButton && ctrl.setDefaultBackButton();
                }
            });
        }

        function findManagerIssues(issues) {
            return issues.filter(issue => {
                const issueIsBeforeNow = moment(issue.EndTimeLocal).isBefore(moment().startOf('day'));

                if (!issue.IsUserAllowedToCompleteIssue && !issueIsBeforeNow) {
                    return issue;
                }
            });
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ModuleSearchService.disableSearch();
            taskUpdateListener();
            taskCreateListener();
            taskFilterListener();
            taskDeleteListener();
            taskManagementStateChangeListener();
            taskSeriesDeleteListener();
        }

        function loadTasks() {
            return TaskManagementRenderService.getAllTasks(ctrl.initialModel).then(resp => {
                const overdueIssues = getCompletableOverdueIssues(resp.OverdueTasks)
                ctrl.issues = resp.Tasks;
                ctrl.overdueIssuesToShow = TaskManagementRenderService.mergeIssuesWithCategories(overdueIssues);
            }).finally(() => {
                generateCalendar(ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                ctrl.managerIssues = findManagerIssues(ctrl.issues);
            });
        }

        function loadMoreTasks() {
            if (ctrl.loadingMoreTasks || ctrl.hasFilter) {
                return;
            }

            ctrl.loadingMoreTasks = true;

            const newStartTime = moment(ctrl.endTime).add(1, 'days'),
                newEndTime = moment(newStartTime).add(7, 'days'),
                moreTasksModel = {
                    Token: $stateParams.token,
                    StartTime: newStartTime,
                    EndTime: newEndTime,
                    SeparateOverdueTasks: true
                }

            ctrl.startTime = newStartTime;
            ctrl.endTime = newEndTime;

            return TaskManagementRenderService.getAllTasks(moreTasksModel).then(resp => {
                resp.Tasks.forEach(task => {
                    const index =
                        ctrl.issues.findIndex(issue => issue.IssueId === task.IssueId);
                    if (index === -1) {
                        ctrl.issues.push(task);
                    }
                });
                ctrl.loadingMoreTasks = false;
            }).catch(() => {
                ctrl.loadingMoreTasks = false;
            })
        }

        function scrollEvents() {
            if (scrolledContainer.scrollTop() + scrolledContainer.innerHeight() > scrolledContainer[0].scrollHeight - 50) {
                $timeout(() => {
                    loadMoreTasks();
                }, 50)
            }
        }

        function generateCalendar(issues) {
            TaskManagementRenderService.generateCalendar(issues, ctrl.categories, true)
                .then(calendarData => {
                    ctrl.calendarData = calendarData;
                    ctrl.calendarData.isUserAllowedToCompleteIssue = ctrl.currentView === ctrl.VIEW.MY_TASKS;
                    ctrl.processing = false;
                })
                .catch(() => {
                    ctrl.processing = false;
                });
        }

        function getCompletableOverdueIssues(tasks) {
            return tasks.filter(issue => issue.IsUserAllowedToCompleteIssue);
        }

        function prepareOverdueTab() {
            if (!ctrl.isOverdueProcessed) {
                ctrl.processingOverdueTab = true;
                $timeout(() => {
                    ctrl.processingOverdueTab = false;
                    ctrl.isOverdueProcessed = true;
                }, 10);
            }
        }

        function fillCalendarWithIssues(isProcessed, isUserAllowedToCompleteIssue) {
            $timeout(() => {
                if (ctrl.hasFilter) {
                    ctrl.processingCalendarTab = false;
                } else {
                    TaskManagementRenderService.fillCalendarWithIssues(ctrl.calendarData, isUserAllowedToCompleteIssue)
                        .then(function () {
                            ctrl.processingCalendarTab = false;
                            isProcessed = true;

                        }).catch(() => {
                        ctrl.processingCalendarTab = false;
                        isProcessed = true;
                    });
                }
            }, 10);
        }

        function prepareCalendarTab() {
            if (!ctrl.isCalendarProcessed) {
                ctrl.processingCalendarTab = true;
                fillCalendarWithIssues(ctrl.isCalendarProcessed, ctrl.VIEW.MY_TASKS === ctrl.currentView)
            }
        }

        function prepareOverseeingCalendarTab() {
            if (!ctrl.isOverseeingCalendarProcessed) {
                ctrl.processingCalendarTab = true;
                fillCalendarWithIssues(ctrl.isOverseeingCalendarProcessed, ctrl.VIEW.MY_TASKS === ctrl.currentView)
            }
        }

        function getHeaderButtons() {
            return [
                {
                    icon: 'search',
                    onClick: () => {
                        const previous = ctrl.currentView;
                        ctrl.changeView(ctrl.VIEW.SEARCH);

                        Page.showBackButtonFunction(() => {
                            ctrl.changeView(previous);
                        });
                    },
                },
                {
                    icon: 'filter',
                    onClick: () => {
                        ctrl.openFilter();
                    },
                    badges: ctrl.appliedFiltersCount
                }
            ];
        }

        function openOverlooking(view) {
            changeView(view);
        }

        function prepareCalendars() {
            if (ctrl.currentView === ctrl.VIEW.OVERSEEING) {
                ctrl.hasFilter ?
                    generateFilteredCalendar(ctrl.currentFilterModel, ctrl.filteredOverlookingIssues) :
                    generateCalendar(ctrl.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue));
                prepareOverseeingCalendarTab();
                return;
            }
            if (ctrl.currentView === ctrl.VIEW.MY_TASKS) {
                ctrl.hasFilter ?
                    generateFilteredCalendar(ctrl.currentFilterModel, ctrl.filteredIssues) :
                    generateCalendar(ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                prepareCalendarTab();
            }
        }

        function changeView(view) {
            ctrl.prevView = ctrl.currentView !== ctrl.VIEW.SEARCH ? ctrl.currentView : ctrl.prevView;
            ctrl.isCalendarActive = false;
            ctrl.formattedDate = moment().format('DD MMM YYYY');
            ctrl.currentView = view;
            ctrl.setDefaultBackButton();
            prepareCalendars();

            let translate = '';
            switch (view) {
                case ctrl.VIEW.MY_TASKS:
                    translate = 'TASK_MANAGEMENT.MENU.MY_TASKS';
                    break;
                case ctrl.VIEW.OVERDUE:
                    translate = 'TASK_MANAGEMENT.MENU.OVERDUE';
                    break;
                case ctrl.VIEW.SEARCH:
                    translate = 'SEARCH.TITLE';
                    break;
                case ctrl.VIEW.OVERSEEING:
                    translate = 'TASK_MANAGEMENT.MENU.OVERLOOKING';
                    break;
                case ctrl.VIEW.MANAGER:
                    translate = 'TASK_MANAGEMENT.MENU.MANAGER_VIEW';
                    break;
            }

            if (!ctrl.isDesktop) {
                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                ctrl.onSwitchView(translate, view);
            }
        }

        function onScrollPrev(data) {
            return TaskManagementRenderService.getPreviousMonth(data, true);
        }

        function onScrollNext(data) {
            return TaskManagementRenderService.getNextMonth(data, true);
        }

        function onScrollPrevFilter(data) {
            return TaskManagementRenderService.getPreviousMonth(data, false, ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues);
        }

        function onScrollNextFilter(data) {
            return TaskManagementRenderService.getNextMonth(data, false, ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues);
        }

        function openCreateTask() {
            CreateUpdateTaskService.openCreateUpdatePopup();
        }

        function openFilter() {
            FilterTasksService.openFilterTasksPopup(ctrl.currentFilterModel);
        }

        function handleFiltering(data, model, filtersCount, isReset) {
            if (isReset) {
                removeFilters();
                return;
            }

            ctrl.currentFilterModel = model;
            ctrl.calendarModel = model;
            ctrl.isCalendarActive = false;
            ctrl.filteredIssues = data.Tasks.filter(issue => issue.IsUserAllowedToCompleteIssue);
            ctrl.filteredOverlookingIssues = data.Tasks.filter(issue => !issue.IsUserAllowedToCompleteIssue);
            ctrl.nextOffset = data.NextOffset;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = true;

            if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                ctrl.processingCalendarTab = true;
            }

            const filteredTasks = ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues;
            generateFilteredCalendar(model, filteredTasks);

            ctrl.appliedFiltersCount = filtersCount;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
        }

        function generateFilteredCalendar(model, filteredTasks) {
            TaskManagementRenderService
                .generateCalendar(filteredTasks, ctrl.categories, true, model.StartTime, model.EndTime)
                .then(calendarData => {
                    ctrl.filteredCalendarData = calendarData;
                    ctrl.formattedDate = `${moment(model.StartTime).format('DD MMM YYYY')} - ${moment(model.EndTime).format('DD MMM YYYY')}`;

                    ctrl.selectedDay = null;

                    if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                })
                .catch(() => {
                    if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                });
        }

        function removeFilters() {
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = false;
            ctrl.currentFilterModel = null;
            ctrl.calendarModel = null;
            ctrl.isCalendarActive = false;

            ctrl.appliedFiltersCount = 0;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            if (ctrl.isCalendarActive) {
                // Prepare the calendar as it has not been done before
                ctrl.VIEW.MY_TASKS === ctrl.currentView && !ctrl.isCalendarProcessed && prepareCalendarTab();
                ctrl.VIEW.OVERSEEING === ctrl.currentView && !ctrl.isOverseeingCalendarProcessed && prepareOverseeingCalendarTab();
            } else if (ctrl.isDesktop) {
                ctrl.selectedDay = null;
                ctrl.formattedDate = moment().format('DD MMM YYYY');
                prepareCalendars();
            } else {
                showCalendarDay(ctrl.selectedDay);
            }
        }

        function showCalendarDay(day) {
            if (day) {
                ctrl.selectedDayMyTasks = day.issues.filter(issue => issue.IsUserAllowedToCompleteIssue);
                ctrl.selectedDayOverlookingTasks = day.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue);
                ctrl.formattedDate = moment(day.fullDate).format('DD MMM YYYY');
            }
        }

        function openTasksList(view) {
            ctrl.isCalendarActive = false;
            changeView(view);
        }

        function openCalendar() {
            ctrl.isCalendarActive = true;
            prepareCalendars();
        }

        function openOverdue() {
            ctrl.changeView(ctrl.VIEW.OVERDUE);

            Page.showBackButtonFunction(() => {
                ctrl.changeView(ctrl.VIEW.MY_TASKS);
            });

            prepareOverdueTab();
        }

        function taskUpdated(issue, resort) {
            ctrl.issues = updateIssue(ctrl.issues, issue);
            TaskManagementRenderService.updateIssuesToDays(ctrl.calendarData.flatDays, [issue]);

            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                ctrl.filteredIssues = updateIssue(ctrl.filteredIssues, issue);
                TaskManagementRenderService.updateIssuesToDays(ctrl.filteredCalendarData.flatDays, [issue]);
            }

            if (!issue.IsOverdue) {
                _.remove(ctrl.overdueIssuesToShow, {IssueId: issue.IssueId});
            } else {
                ctrl.overdueIssuesToShow = updateIssue(ctrl.overdueIssuesToShow, issue);
            }

            if (resort) {
                ctrl.issues.sort((a, b) => {
                    return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                        new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                });
            }
        }

        function updateIssue(issues, issue) {
            const issueIndex = issues.findIndex(i => i.IssueId === issue.IssueId);

            if (issueIndex || issueIndex === 0) {
                issues[issueIndex] = issue;
            }

            return issues;
        }

        function taskDeleted(issue) {
            _.remove(ctrl.issues, {IssueId: issue.IssueId});
            _.remove(ctrl.overdueIssuesToShow, {IssueId: issue.IssueId});
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], false);

            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                _.remove(ctrl.filteredIssues, {IssueId: issue.IssueId});
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], false);
            }
        }

        function taskSeriesDeleted(issue) {
            _.remove(ctrl.issues, {ParentIssueId: issue.ParentIssueId});
            _.remove(ctrl.overdueIssuesToShow, {ParentIssueId: issue.ParentIssueId});
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], true);
            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                _.remove(ctrl.filteredIssues, {IssueId: issue.ParentIssueId});
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], true);
            }
        }
    }
})();
