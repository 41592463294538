(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('walkthroughSlideAppaccess', ['$translate', '$http',
        function ($translate, $http) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough-Slide-Appaccess.tpl.html',
                scope: {
                    slide: '=',
                    parent: '=',
                    done: '&'
                },
                controller: ['$scope', '$element', function ($scope, $element) {
                    var middleWare = '';

                    // Init
                    InitDirective();
                    function InitDirective() {
                        // Translate Texts
                        CreateAppAccessText();

                        // Create model
                        CreateAppAccessModel();
                    }

                    // Methods
                    function Post(model) {
                        // Only run while not already loading
                        if (!$scope.slide.Loading) {

                            // Reset Errors
                            $scope.model.Error = false;
                            $scope.model.ErrorMessage = null;

                            // Validate Input
                            if (model.Code != null && model.Code.length > 0) {
                                
                                // Code is present
                                $scope.slide.Loading = true;

                                // Attempt an Activation
                                $http.post('/Walkthrough/ValidateAppAccessCode', { code: model.Code }).then(function (response) {
                                    $scope.slide.Loading = false;

                                    if (response.data.Success) {
                                        // Success
                                        Done();
                                    } else {
                                        // Failed
                                        $translate('WALKTHROUGH.ACCESS' + middleWare + '.ERROR.INVALID').then(function (trans) {
                                            $scope.model.Error = true;
                                            $scope.model.ErrorMessage = trans;
                                        });
                                    }

                                }, function (response) {
                                    $scope.slide.Loading = false;

                                    // Call failed
                                    $translate('ERROR.GENERAL').then(function (trans) {
                                        $scope.model.Error = true;
                                        $scope.model.ErrorMessage = trans;
                                    });
                                });

                            } else {
                                // No code is present
                                $translate('WALKTHROUGH.ACCESS' + middleWare + '.ERROR.MISSING').then(function (trans) {
                                    $scope.model.Error = true;
                                    $scope.model.ErrorMessage = trans;
                                });
                            }
                        }
                    }

                    function Done() {
                        $scope.done();
                    }
                    
                    // Objects
                    function CreateAppAccessText() {
                        var slideData = $scope.slide.SlideData;

                        $scope.text = {
                            Headline: 'WALKTHROUGH.ACCESS' + middleWare + '.HEADLINE',
                            Code: 'WALKTHROUGH.ACCESS' + middleWare + '.CODE',
                            Text: 'WALKTHROUGH.ACCESS' + middleWare + '.TEXT',
                            Button: 'WALKTHROUGH.ACCESS' + middleWare + '.BUTTON'
                        }
                    }

                    function CreateAppAccessModel() {
                        $scope.model = {
                            Code: '',
                            Error: false,
                            ErrorMessage: null
                        };
                    }

                    // Bindings
                    $scope.Post = Post;
                }]
            }
        }
    ]);
})();