(function () {
    'use strict';

    angular.module('App')
        .component('channelsList', {
            template: require('./ChannelsListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$element', '$filter', 'ChatPopupsService', 'ToastFactory',
                'ChatConnectionService', 'ChatDataService', 'ConfirmPopupService', 'ConversationService', 'Profile', 'ResponsiveService',
                'ActionSheetService', 'MentionService', ChannelsListController],
            bindings: {
                channels: '=',
                blockedUsers: '<',
                onChannelClose: '<',
                markAsRead: '<'
            }
        });

    function ChannelsListController($rootScope, $scope, $element, $filter, ChatPopupsService, ToastFactory,
                                    ChatConnectionService, ChatDataService, ConfirmPopupService, ConversationService, Profile,
                                    ResponsiveService, ActionSheetService, MentionService) {

        let ctrl = this, touchElementHammer;

        ctrl.isDesktop = ResponsiveService.isDesktop();
        ctrl.currentUserToken = Profile.getProfile().UserToken
        ctrl.requestTokens = [];
        ctrl.requestGroupTokens = [];
        ctrl.channels = sortChannels(ctrl.channels);
        ctrl.isRTL = $rootScope.isRTL;
        ctrl.$onInit = init;
        ctrl.openChannel = openChannel;
        ctrl.leaveChannel = leaveChannel;
        ctrl.hideChannel = hideChannel;
        ctrl.openChannelOptions = openChannelOptions;
        ctrl.$onDestroy = destroy;
        ctrl.getChannelCompanion = ChatDataService.getChannelCompanion;
        ctrl.createChannel = ChatPopupsService.openCreateChatPopup;
        ctrl.isUserBlocked = isUserBlocked;
        ctrl.isMuted = isMuted;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.parseMentions = parseMentions;
        ctrl.setNotificationSetting = setNotificationSetting;
        ctrl.getNonBlockedUser = getNonBlockedUser;
        ctrl.getMemberName = getMemberName;

        const destroyBadgeWatch = $scope.$watch(getBadgeCount, (newVal, oldVal) => {
                ChatConnectionService.setChatBadge(newVal);
            }),
            destroyChannelCreatedWatch = $rootScope.$on('CHAT.CHANNEL_CREATED', (ev, channelId) => {
                const channel = ctrl.channels.find(channel => channel.item.channelId === channelId);
                ChatConnectionService.openChannel(channelId, { ...channel, blockedUsers: ctrl.blockedUsers }, ctrl.onChannelClose);
            }),
            destroyNotificationSettingWatcher = $rootScope.$on('CHAT.NOTIFICATION_SETTING_CHANGED', (ev, channelId, notificationsEnabled) => {
                const channel = ctrl.channels.find(channel => channel.item.channelId === channelId);
                channel && (channel.item.notificationsEnabled = notificationsEnabled);
            }),
            destroyLastMessageUpdateWatcher = $rootScope.$on('CHAT.LAST_MESSAGE_UPDATE', (ev, data) => {
                updateLastMessage(data);
            }),    
            destroyBlockedUsersUpdateWatcher = $rootScope.$on('CHAT.BLOCKED_USERS_UPDATE', (ev, blockedUserId, block) => {
                $scope.$applyAsync(() => {
                    if (block) {
                        ctrl.blockedUsers.push(blockedUserId);
                    } else {
                        ctrl.blockedUsers = ctrl.blockedUsers.filter(id => blockedUserId !== id);
                    }
                })
            }),
            destroyChannelOpened = $rootScope.$on('CHAT.CHANNEL_MARKED_AS_READ', (ev, channelId) => {
                const channel = ctrl.channels.find(channel => channel.item.channelId === channelId);
                channel && (channel.item.unreadMessagesCount = 0);
            }),
            destroyChannelRemoved = $rootScope.$on('CHAT.CHANNEL_REMOVED', (ev, channelId) =>
                removeChannelFromList(channelId)),
            destroyMarkAllChannelsAsRead = $rootScope.$on('CHAT.MARK_ALL_CHANNELS_READ', () => {
                ctrl.channels = ctrl.channels.map(channel => {
                    channel.item.unreadMessagesCount = 0;
                    channel.item.forceUnread = false
                    
                    return channel;
                })
            })

        function init() {
            if (!ctrl.isDesktop) {
                initTouchEvents();
            }
            
            ChatConnectionService.onChannelListEvents(getUpdateChannelList);
            ChatConnectionService.onUpdateMessage(updateLastMessage);
            ChatConnectionService.onChannelLatMessageUpdate(updateLastMessage);
        }
        
        function parseMentions(message, members) {
            return message && MentionService.parseMentionToText(message, members);
        }

        function sortChannels(channelsData) {
            return _.orderBy(channelsData.channels ?? channelsData, function (channel) {
                return channel.item.lastMessageDate || channel.item.dateJoined;
            }, 'desc');
        }

        function getUpdateChannelList(event) {
            ChatDataService.getUserChannels().then(channels => {
                ctrl.channels = sortChannels(channels);
            })
        }

        function getBadgeCount() {
            return ctrl.channels.filter(channel => {
                return channel.item.unreadMessagesCount > 0 || channel.item.forceUnread;
            }).length
        }

        function getNonBlockedUser(channel) {
            const nonBlockedUsers = channel.members.filter((user) => {
                return !ctrl.blockedUsers.includes(user.userId)
            });
            
            if (nonBlockedUsers.length === 1) return nonBlockedUsers[0];

            return nonBlockedUsers.filter((user) => user.userId !== ctrl.currentUserToken)[0];
        }

        function getMemberName(userId, channel) {
            const user = channel.members.find(user => user.userId === userId);
            const firstName = user?.name?.split(' ')[0];
            return firstName || '';
        }

        function openChannel(channel) {
            ChatConnectionService.openChannel(channel.item.channelId, {
                ...channel,
                blockedUsers: ctrl.blockedUsers
            }, ctrl.onChannelClose);
            $element.find('.channel-wrapper').removeClass('active');
            channel.item.forceUnread = false;
        }
        
        function updateLastMessage(ev, messageModified) {
            const updatedChannel = ctrl.channels.find(channel => {
                return channel.item.channelId === ev.channelId;
            });

            if (updatedChannel) {
                $scope.$applyAsync(() => {
                    if (messageModified) {
                        updatedChannel.item.lastMessageText = ev.text;
                        updatedChannel.item.lastMessageDate = ev.dateDeleted || ev.dateModified;
                        updatedChannel.item.lastMessageIsDeleted = !!ev.dateDeleted;
                    }
                    else {
                        updatedChannel.item.blockedUsers = ev.blockedUsers;
                        updatedChannel.item.lastMessageAuthorId = ev.authorId;
                        updatedChannel.item.lastMessageDate = ev.dateDeleted || ev.dateCreated;
                        updatedChannel.item.lastMessageId = ev.messageId;
                        updatedChannel.item.lastMessageText = ev.text;
                        updatedChannel.item.unreadMessagesCount = ev.unreadMessagesCount;
                        updatedChannel.item.lastMessageIsDeleted = ev.isDeleted;
                        ctrl.channels = sortChannels(ctrl.channels);
                    }
                });
            } else {
                getUpdateChannelList();
            }
        }

        function markAsUnread($event, channel) {
            $event && $event.stopPropagation();
            channel.item.unreadMessagesCount = 1;
            channel.item.forceUnread = true;
            ChatDataService.markChannelAsUnread(channel.item.channelId);
        }

        function muteChannel(event, channel) {
            event && event.stopPropagation();
            channel.item.notificationsEnabled = false;
            setNotificationSetting(channel, false);
                
        }

        function unmuteChannel(event, channel) {
            event && event.stopPropagation();
            channel.item.notificationsEnabled = true;
            setNotificationSetting(channel, true);
        }
        
        function leaveChannel($event, channel) {
            $event && $event.stopPropagation();

            ConfirmPopupService.open({
                message: $filter('translate')('CHAT.EVENT.LIST_LEAVE_CONFIRM'),
                class: 'chat-leave-confirm'
            }).then(() =>
                ChatDataService.leaveChannel(channel.item.channelId)
                    .then(() => {
                        removeChannelFromList(channel.item.channelId);
                    })
                    .catch(resp => {
                        if (resp.data.code === 'LAST_ADMIN_CANNOT_LEAVE') {
                            ConfirmPopupService.open({
                                message: $filter('translate')('CHAT.LEAVE_LAST_MODERATOR_WARNING'),
                                yesText: $filter('translate')('GOT_IT'),
                                isAlert: true
                            }).then(() => {
                                $element.find('.channel-wrapper').removeClass('active');
                            });
                        } else {
                            $element.find('.channel-wrapper').removeClass('active');
                            ToastFactory.error(resp.data.error);
                        }
                    })
            );
        }

        function hideChannel($event, channel) {
            $event && $event.stopPropagation();

            ConfirmPopupService
                .open({
                    message: $filter('translate')('CHAT.EVENT.LIST_HIDE_CONFIRM'),
                    yesText: $filter('translate')('GOT_IT'),
                    isAlert: true
                })
                .then(() => {
                    ChatDataService.hide(channel.item.channelId);
                    removeChannelFromList(channel.item.channelId);
                });

            return false;
        }

        function removeChannelFromList(channelId) {
            ctrl.channels = ctrl.channels.filter(channelItem => channelItem.item.channelId !== channelId);
        }

        function initTouchEvents() {
            var touchElement = $element.get(0);

            touchElementHammer = new Hammer(touchElement);
            touchElementHammer.get('press').set({time: 200});

            touchElementHammer.on('press', (event) => {
                event.preventDefault();
                const channel = ctrl.channels.find(channel =>
                    channel.item.channelId === event.target.closest('.channel-wrapper').getAttribute('data-index')
                )
                openChannelOptions(event, channel);
            })
        }

        function openChannelOptions(event, channel) {
            if (ctrl.isDesktop) {
                event.stopPropagation();
            }
            
            const header = {description: channel.item.name};

            ActionSheetService.create(
                [(channel.item.unreadMessagesCount === 0 && !channel.item.forceUnread)?
                {
                    text: 'CHAT.ACTIONSHEET.MARK_UNREAD',
                    icon: 'comment',
                    iconClass: 'red-dot',
                    onClick: function () {
                        markAsUnread(null, channel)}
                }:
                {
                    text: 'CHAT.ACTIONSHEET.MARK_READ',
                    icon: 'comment-check',
                    onClick: function () {
                        ctrl.markAsRead(null, channel)}
                },
                {
                    text: 'CHAT.ACTIONSHEET.HIDE',
                    icon: 'eye-crossed',
                    onClick: function () {
                        hideChannel(null, channel)}
                }, (!isMuted(channel)) ?
                    {
                        text: 'CHAT.MUTE',
                        icon: 'notifications',
                        onClick: function () {
                            muteChannel(null, channel)}
                }:
                    {
                        text: 'CHAT.UNMUTE',
                        icon: 'notifications-slash',
                        onClick: function () {
                            unmuteChannel(null, channel)}
                    }, (channel.item.isGroupChat)?
                        {
                            text: 'CHAT.LEAVE_THE_GROUP',
                            icon: 'leave-chat',
                            iconClass: 'red label-color',
                            onClick: function () {
                                leaveChannel(null, channel)}
                        }:
                    {
                        text: 'CHAT.ACTIONSHEET.DELETE',
                        icon: 'delete',
                        iconClass: 'red label-color',
                        onClick: function () {
                            leaveChannel(null, channel)}
                    
                }], event.currentTarget, header, {name: 'channels-list'}).show();
        }

        function setNotificationSetting(channel, notificationsEnabled) {
            ChatDataService
                .setChannelNotificationSettings(channel.item.channelId, notificationsEnabled)
                .catch(err => {
                    ToastFactory.errorTranslated('ERROR.GENERAL')
                    channel.item.notificationsEnabled = notificationsEnabled;
                });
        }

        function isMuted(channel) {
            return !channel.item.notificationsEnabled;
        }

        function isUserBlocked(userId) {
            return ctrl.blockedUsers?.includes(userId);
        } 
        
        function isUserNotBlocked(member) {
            return !ctrl.blockedUsers?.includes(member.userId);
        } 
        
        function destroy() {
            touchElementHammer && touchElementHammer.destroy();
            destroyBadgeWatch();
            destroyChannelCreatedWatch();
            destroyChannelOpened();
            destroyChannelRemoved();
            destroyNotificationSettingWatcher();
            destroyLastMessageUpdateWatcher();
            destroyBlockedUsersUpdateWatcher();
            destroyMarkAllChannelsAsRead();
            ConversationService.onChannelsListDestroy();
        }
    }
})();