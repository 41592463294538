(function () {
    'use strict';

    angular
        .module('App')
        .component('attachmentPreview', {
            template: ['$element', '$attrs', function ($element, $attrs) {
                const templates = {
                    'inline': require('./AttachmentPreview.tpl.html'),
                    'square': require('./AttachmentPreview-square.tpl.html'),
                };

                if ($attrs.template) {
                    return templates[$attrs.template];
                } else {
                    return templates['inline'];
                }
            }],
            controllerAs: 'ctrl',
            bindings: {
                onRemove: '<',
                onAdd: '<',
                uploadOptions: '<',
                enableViewer: '<',
                disableEditing: '<',
                isMultiSelect: '<',
                enableImageEditing: '<',
                min: '<',
                max: '<',
                moduleToken: '@'
            },
            require: {
                ngModelCtrl: '?ngModel'
            },
            controller: ['$q', '$timeout', 'FILE_TYPE', 'PopupWrapperService', AttachmentPreviewController]
        });

    function AttachmentPreviewController($q, $timeout, FILE_TYPE, PopupWrapperService) {
        var ctrl = this;

        ctrl.attachmentViewerPopupId = "attachmentsViewerPopup";
        ctrl.thumbnailSelectorPopupId = "thumbnailSelectorPopup";

        ctrl.attachments = [];
        ctrl.FILE_TYPE = FILE_TYPE;
        ctrl.uploadOptions = ctrl.uploadOptions || {};
        ctrl.globalProgress = 0;

        ctrl.$onInit = onInit;
        ctrl.mediaProcessed = mediaProcessed;
        ctrl.mediaPreviewLoaded = mediaPreviewLoaded;
        ctrl.removeAttachment = removeAttachment;
        ctrl.openAttachmentsViewer = openAttachmentsViewer;
        ctrl.openThumbnailSelector = openThumbnailSelector;
        ctrl.onThumbnailUpdated = onThumbnailUpdated;
        ctrl.formatTime = formatTime;
        ctrl.onImageUpdate = onImageUpdate;

        // Upload events
        ctrl.onFileAdd = onFileAdd;
        ctrl.onFileDone = onFileDone;
        ctrl.onFileProgress = onFileProgress;
        ctrl.onFileRemove = onFileRemove;

        function onInit() {
            if (ctrl.ngModelCtrl) {
                // timeout to avoid first initialization of ng-model with NaN values
                $timeout(function () {
                    if (ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length) {
                        ctrl.attachments = ctrl.ngModelCtrl.$viewValue.map(function (media) {
                            return getAttachmentFromMedia(media);
                        });
                    }

                })
            }

            ctrl.ngModelCtrl.$isEmpty = (val) => {
                return !val?.length
            }
            
            if (ctrl.min) {
                ctrl.ngModelCtrl.$validators.min = function (modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    if (ctrl.ngModelCtrl.$validators.required(modelValue, viewValue) && value && value.length >= 1) {
                        return value.length >= ctrl.min;
                    } else {
                        return true
                    }
                };
                ctrl.ngModelCtrl.$validate();
            }
        }

        function getFileType(type) {
            return FILE_TYPE[type] || FILE_TYPE['unknown'];
        }

        function removeAttachment(attachment) {
            _.remove(ctrl.attachments, function (o) {
                return o.id === attachment.id;
            });

            if (attachment.media) {
                ctrl.onRemove && ctrl.onRemove(attachment.media);
                ctrl.removeFile && attachment.media.MediaId !== attachment.id
                && ctrl.removeFile(attachment.id, attachment.media.MediaId);
            } else {
                ctrl.removeFile && ctrl.removeFile(attachment.id);
            }

            if (ctrl.ngModelCtrl) {
                var newValue = [];
                _.forEach(ctrl.attachments, function (o) {
                    o.status === 3 && o.media && newValue.push(o.media);
                });
                newValue = newValue.length ? newValue : [];
                ctrl.ngModelCtrl.$setViewValue(newValue);
                if (!ctrl.isMultiSelect && !ctrl.ngModelCtrl.$viewValue.length) {
                    ctrl.disabled = false;
                } else if (ctrl.max && (ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length < ctrl.max) ||
                    (ctrl.min === 0 && ctrl.ngModelCtrl.$viewValue === null)) {
                    ctrl.disabled = false;
                }
            }
        }

        function mediaPreviewLoaded(attachment) {
            attachment.previewLoaded = true;
        }

        function mediaProcessed(attachment) {
            if (attachment.status !== 3 && attachment.id !== attachment.media.MediaId) {
                ctrl.onAdd && ctrl.onAdd(attachment.media);
            }

            attachment.status = 3;

            if (ctrl.ngModelCtrl && (!ctrl.ngModelCtrl.$viewValue || ctrl.ngModelCtrl.$viewValue.length !== ctrl.attachments.length)) {
                var newValue = [];
                _.forEach(ctrl.attachments, function (o) {
                    o.status === 3 && o.media && newValue.push(o.media);
                });
                ctrl.ngModelCtrl.$setViewValue(newValue);
            }

            if (!ctrl.isMultiSelect && ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length === 1) {
                ctrl.disabled = true;
            } else if (ctrl.max && ctrl.ngModelCtrl.$viewValue.length === ctrl.max) {
                ctrl.disabled = true;
            }
        }

        function getAttachmentFromMedia(media) {
            var attachment = {
                id: media.MediaId,
                type: media.ContentType,
                attachmentType: getFileType(media.ContentType),
                name: media.FileName,
                thumbnail: getThumbnailUrl(media),
                media: media,
                status: 3
            };

            if (attachment.thumbnail) {
                attachment.status = 2;
            }

            return attachment;
        }

        function onFileAdd(id, file) {
            var attachment = {
                id: id,
                type: file.type,
                attachmentType: getFileType(file.type),
                name: file.name,
                progress: 0,
                status: 0
            };
            if (!ctrl.isMultiSelect) {
                if (ctrl.attachments.length === 0) {
                    ctrl.attachments.push(attachment);
                } else {
                    ctrl.disabled = true;
                    return false;
                }
            } else if (ctrl.max) {
                if (ctrl.attachments.length < ctrl.max) {
                    ctrl.attachments.push(attachment);
                } else {
                    ctrl.disabled = true;
                    return false;
                }
            } else {
                ctrl.attachments.push(attachment);
            }
            getGlobalProgress();
        }

        function onFileRemove(id) {
            var item = _.find(ctrl.attachments, function (o) {
                return o.id === id;
            });
            if (item) {
                removeAttachment(item);
            }

            getGlobalProgress();
        }

        function onFileProgress(id, progress) {
            var item = _.find(ctrl.attachments, function (o) {
                return o.id === id;
            });

            if (item) {
                if (progress < 100) {
                    item.status = 1;
                } else {
                    item.status = item.status === 3 ? 3 : 2;
                }
                item.progress = progress;
                if (progress === 0 && !item.elapsedTimeStart) {
                    item.elapsedTimeStart = Date.now();
                }
                if (progress > 0 && progress < 100) {
                    var delta = (Date.now() - item.elapsedTimeStart);
                    item.elapsedTime = (delta / progress) * 100 - delta;
                }

                var circle = $('.file-attachment-id-' + item.id + ' .progress-ring-circle');

                if (circle.length) {
                    circle = circle[0];

                    if (progress < 100) {
                        // Update percentage progress
                        var radius = circle.r.baseVal.value;
                        var circumference = radius * 2 * Math.PI;

                        var offset = circumference - progress / 100 * circumference;
                        circle.style.strokeDashoffset = offset;
                    } else {
                        // Set as processing as we've uploaded 100%
                        circle.style.strokeDashoffset = 0;
                    }
                }

                getGlobalProgress();
            }
        }

        function onFileDone(id, media) {
            var item = _.find(ctrl.attachments, function (o) {
                return o.id === id;
            });
            if (item) {
                item.media = media;

                // We attempt to grab the thumbnail
                var thumbnail = getThumbnailUrl(media);
                if (thumbnail) {
                    item.thumbnail = thumbnail;
                } else {
                    // Otherwise we just want the item to appear processed straight away
                }
                mediaProcessed(item);
            }
            getGlobalProgress();
        }

        function getThumbnailUrl(media) {
            var thumb = media.ThumbnailUrl || media.ImageFormats.W250 || media.ImageFormats.W100 || null;
            // images can also use the original URL for the thumbnail if needed
            if (!thumb) {
                var mediaType = getFileType(media.ContentType);
                if (mediaType === 'image') {
                    thumb = media.OriginalUrl;
                }
            }
            return thumb;
        }

        function openAttachmentsViewer(key, forceImageUpdate) {
            if (ctrl.enableViewer) {
                ctrl.selectedAttachmentIndex = key;
                PopupWrapperService.createDynamic(`<attachments-viewer editable="!disableEditing"
                    attachments="attachments" on-browse="browseFiles" on-remove="removeAttachment" 
                    selected-index="selectedAttachmentIndex" on-popup-registered="onPopupRegistered"
                    on-image-update="onImageUpdate" force-image-update="forceImageUpdate"
                    module-token="moduleToken"
                    ></attachments-viewer>`, {
                    onPopupRegistered: function (popup) {
                        popup.open();
                    },
                    disableEditing: ctrl.disableEditing,
                    attachments: ctrl.attachments,
                    browseFiles: ctrl.browseFiles,
                    removeAttachment: ctrl.removeAttachment,
                    selectedAttachmentIndex: ctrl.selectedAttachmentIndex,
                    onImageUpdate: ctrl.enableImageEditing && ctrl.onImageUpdate,
                    forceImageUpdate: forceImageUpdate,
                    moduleToken: ctrl.moduleToken
                })
            }
        }

        function onImageUpdate({media, selectedIndex}) {
            return $q((resolve) => {
                if (ctrl.attachments[selectedIndex]) {
                    ctrl.attachments[selectedIndex].media = media;
                    ctrl.attachments[selectedIndex].thumbnail = getThumbnailUrl(media);
                }
                ctrl.ngModelCtrl.$setViewValue(ctrl.attachments.map((el) => el.media));
                resolve();
            })

        }

        function formatTime(tick) {
            const seconds = tick / 1000;
            let label = '';

            if (seconds > 600) label = '> 10 min'
            if (seconds < 600) label = '< 10 min'
            if (seconds < 300) label = '< 5 min';
            if (seconds < 60) label = '< 1 min';
            if (seconds < 10) label = '< 10 sec';

            return label;
        }

        function openThumbnailSelector(attachment) {
            if (attachment.media) {
                PopupWrapperService.getPopup(ctrl.thumbnailSelectorPopupId).open({
                    media: attachment.media,
                    attachmentId: attachment.id
                });
            }
        }

        function getGlobalProgress() {
            var loadingItems = [], globalProgress = 0;

            loadingItems = ctrl.attachments.filter(function (item) {
                return (item.status === 0 || item.status === 1) && item.progress < 100
            });

            if (loadingItems.length) {
                globalProgress = _.sumBy(loadingItems, function (item) {
                    return item.progress
                }) / loadingItems.length;
            } else {
                globalProgress = 0;
                ctrl.globalProgress = 0;
            }

            if (globalProgress >= ctrl.globalProgress) {
                ctrl.globalProgress = globalProgress;

                var circle = $('.unseen-images-counter .progress-ring-circle');
                if (circle.length) {
                    circle = circle[0];

                    if (globalProgress < 100) {
                        // Update percentage progress
                        var radius = circle.r.baseVal.value;
                        var circumference = radius * 2 * Math.PI;

                        var offset = circumference - globalProgress / 100 * circumference;
                        circle.style.strokeDashoffset = offset;
                    } else {
                        // Set as processing as we've uploaded 100%
                        circle.style.strokeDashoffset = 0;
                    }
                }
            }
        }

        function onThumbnailUpdated(attachmentId, thumbnailMedia) {
            var index = ctrl.attachments.findIndex(function (item) {
                return item.id === attachmentId;
            })

            ctrl.attachments[index].thumbnail = thumbnailMedia.UrlEncoded;
        }

    }
})();
