(() => {
    'use strict';

    angular
        .module('App')
        .component('assigneesInfoPopup', {
            template: require('./AssigneesInfoPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'ResponsiveService', AssigneesInfoPopupController],
            bindings: {
                excludedSections: '<',
            }
        })

    function AssigneesInfoPopupController($element, ResponsiveService) {
        const ctrl = this;

        ctrl.remove = remove;
        ctrl.showCloseButton =  ResponsiveService.isDesktop();

        let allSections = [
            {
                id: 'whoIsResponsible',
                title: 'TASK_MANAGEMENT.INFO.RESPONSIBLE.DEFINITION_TITLE',
                text: 'TASK_MANAGEMENT.INFO.RESPONSIBLE.DEFINITION_TEXT'
            },
            {
                id: 'howToSeeResponsible',
                title:  'TASK_MANAGEMENT.INFO.RESPONSIBLE.TUTORIAL_TITLE',
                text: 'TASK_MANAGEMENT.INFO.RESPONSIBLE.TUTORIAL_TEXT',
                demos: [
                    {
                        images: [
                            '../../../../Content/Graphics/ResponsiblePopup/AssigneesExample1.svg',
                            '../../../../Content/Graphics/ResponsiblePopup/AssigneesExample2.svg'
                        ],
                        status: ['../../../../Content/Graphics/ResponsiblePopup/ProgressTask.svg'],
                        taskTitle: 'TASK_MANAGEMENT.INFO.RESPONSIBLE.TUTORIAL_TASK_TITLE',
                        deadline: 'TASK_MANAGEMENT.INFO.RESPONSIBLE.TUTORIAL_DEADLINE',
                        categories: ['red', 'yellow']
                    }
                ]
            },{
                id: 'whoIsAssignee',
                title: 'TASK_MANAGEMENT.INFO.ASSIGNEE.DEFINITION_TITLE',
                text: 'TASK_MANAGEMENT.INFO.ASSIGNEE.DEFINITION_TEXT'
            },
            {
                id: 'howToSeeAssignees',
                title: 'TASK_MANAGEMENT.INFO.ASSIGNEE.TUTORIAL_TITLE',
                text: 'TASK_MANAGEMENT.INFO.ASSIGNEE.TUTORIAL_TEXT',
                demos: [
                    {
                        images: [
                            '../../../../Content/Graphics/ResponsiblePopup/AssigneesExample3.svg',
                            '../../../../Content/Graphics/ResponsiblePopup/AssigneesExample4.svg'
                        ],
                        status: ['../../../../Content/Graphics/ResponsiblePopup/ProgressTask.svg'],
                        taskTitle: 'TASK_MANAGEMENT.INFO.ASSIGNEE.TUTORIAL_TASK_TITLE',
                        lock: "true",
                        deadline: 'TASK_MANAGEMENT.INFO.ASSIGNEE.TUTORIAL_DEADLINE',
                        categories: ['green']
                    }
                ]
            },
            {
                id: 'whoIsInvited',
                title: 'CALENDAR.INFO.INVITED_TITLE',
                text: 'CALENDAR.INFO.INVITED_TEXT'
            }
        ];

        ctrl.sections = allSections.filter(section => !ctrl.excludedSections.includes(section.id));
        
        function remove() {
            $element.remove();
        }
    }
})();