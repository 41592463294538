(() => {
    'use strict';

    angular
        .module('App')
        .component('permissionsInfoPopup', {
            template: require('./PermissionsInfoPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'ResponsiveService', 'MultiplePermissionSelectorService', PermissionsInfoPopupController],
            bindings: {
                excludedSections: '<',
                requiredPermissionsId: '<'
            }
        })

    function PermissionsInfoPopupController($element, ResponsiveService, MultiplePermissionSelectorService) {
        const ctrl = this;

        ctrl.remove = remove;
        ctrl.showCloseButton = ResponsiveService.isDesktop();
        ctrl.INFO_POPUP_TYPES = MultiplePermissionSelectorService.getInfoPopupTypes();

        ctrl.sections = [
            {
                id: ctrl.INFO_POPUP_TYPES.permissionsDefinition,
                title: 'PERMISSIONS.INFO.DEFINITION_TITLE',
                text: 'PERMISSIONS.INFO.DEFINITION_TEXT'
            },            
            {
                id: ctrl.INFO_POPUP_TYPES.howPermissionsWork,
                title: 'PERMISSIONS.INFO.HOW_WORKS_TITLE',
                text: 'PERMISSIONS.INFO.HOW_WORKS_TEXT'
            },            
            {
                id: ctrl.INFO_POPUP_TYPES.departments,
                title: 'PERMISSIONS.INFO.DEPARTMENTS_TITLE',
                text: 'PERMISSIONS.INFO.DEPARTMENTS_TEXT',
                tags: [
                    'PERMISSIONS.INFO.AUSTRIA',
                    'PERMISSIONS.INFO.DENMARK',
                    'PERMISSIONS.INFO.GERMANY',
                    'PERMISSIONS.INFO.TURKEY'
                ]
            },
            {
                id: ctrl.INFO_POPUP_TYPES.userGroups,
                title: 'PERMISSIONS.INFO.USER_GROUPS_TITLE',
                text: 'PERMISSIONS.INFO.USER_GROUPS_TEXT',
                tags: [
                    'PERMISSIONS.INFO.AREA_MANAGERS',
                    'PERMISSIONS.INFO.STORE_MANAGERS'
                ],
                // Hidden temporarily, until it is enabled in app.
                // subsections: [
                //     {
                //         id: 'unlimitedAccess',
                //         subtitle: 'PERMISSIONS.INFO.UNLIMITED_ACCESS_TITLE',
                //         text: 'PERMISSIONS.INFO.UNLIMITED_ACCESS_TEXT',
                //         tags: ['PERMISSIONS.INFO.ADMINISTRATOR']
                //     }
                // ]
            },
            {
                id: ctrl.INFO_POPUP_TYPES.requiredPermissions,
                title: 'PERMISSIONS.INFO.REQUIRED_TITLE',
                text: `PERMISSIONS.INFO.REQUIRED_TEXT_${ctrl.requiredPermissionsId}`,
            },
            {
                id: ctrl.INFO_POPUP_TYPES.permissionSets,
                title: 'PERMISSIONS.INFO.SETS_TITLE',
                text: 'PERMISSIONS.INFO.SETS_TEXT',
                demos: [
                    'PERMISSIONS.INFO.SETS_IMAGE1',
                    'PERMISSIONS.INFO.SETS_IMAGE2'
                ]
            },
            {
                id: ctrl.INFO_POPUP_TYPES.example,
                title: 'PERMISSIONS.INFO.EXAMPLE_TITLE',
                text: 'PERMISSIONS.INFO.EXAMPLE_TEXT',
                demos: [
                    'PERMISSIONS.INFO.EXAMPLE_IMAGE'
                ]
            }
        ];


        function remove() {
            $element.remove();
        }
    }
})();