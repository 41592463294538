(function () {
    'use strict';

    angular.module('App')
        .component('bookmarkFilters', {
            template: require('./BookmarkFiltersComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: [BookmarkFiltersController],
            bindings: {
                allBookmarks: '<',
                filteredBookmarks: '=?',
                selectedFilter: '=?'
            }
        });

    function BookmarkFiltersController() {
        var ctrl = this;

        ctrl.filterOptions = getFilters();
        ctrl.filterBookmarks = filterBookmarks;
        ctrl.selectedFilter = null;

        ctrl.$onChanges = function (changes) {
            if (changes.allBookmarks) {
                ctrl.filterBookmarks(ctrl.selectedFilter);
            }
        };

        function filterBookmarks(type) {
            if (!type) {
                ctrl.filteredBookmarks = ctrl.allBookmarks;
            }
            else {
                ctrl.filteredBookmarks = ctrl.allBookmarks.filter((bookmark) => bookmark.BookmarkType === type);
            }

            ctrl.selectedFilter = type;
        }

        function getFilters() {
            return [
                {id: 1, type: null, text: 'ALL'},
                {id: 2, type: 1, text: 'SEARCH.CONTENT_POST'},
                {id: 3, type: 2, text: 'SEARCH.LIBRARY_POST'},
                {id: 4, type: 3, text: 'SEARCH.SOCIAL_POST'},
            ]
        }
    }
})();