(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleQuestionTextfield', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleQuestionTextfield.html',
                scope: {
                    question: '=',
                    stepDone: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitController();
                    function InitController() {
                        if ($scope.question.answers == undefined) {
                            RemoveAnswer();
                        }

                        $scope.textSingle = '';
                        $scope.textMulti = '';

                        if (!$scope.question.IsRequired) {
                            $scope.$watch('stepDone', function (newVal) {
                                if (newVal) {
                                    SetAnswer();
                                } else if(GetAnswer().length == 0){
                                    RemoveAnswer();
                                }
                            });
                        }
                    }

                    // Methods
                    function TextChange() {
                        var textAnswer = GetAnswer();

                        if (textAnswer.length > 0) {
                            SetAnswer();
                        } else {
                            RemoveAnswer();
                        }
                    }

                    function GetAnswer(){
                        var textAnswer = '';
                        if ($scope.question.Options[0].FormQuestionOptionTypeId == 2) {
                            textAnswer = $scope.textSingle;
                        } else {
                            textAnswer = $scope.textMulti;
                        }

                        return textAnswer;
                    }

                    function SetAnswer() {
                        var textAnswer = GetAnswer();
                        
                        $scope.question.answered = true;
                        $scope.question.answers = [new QuestionAnswer($scope.question.Options[0].FormQuestionOptionId, textAnswer)]
                    }

                    function RemoveAnswer() {
                        $scope.question.answered = false;
                        $scope.question.answers = [];
                    }

                    // Objects
                    function QuestionAnswer(optionId, text) {
                        this.FormQuestionOptionId = optionId;
                        this.Text = text;
                    }

                    // Bind scope
                    $scope.textChange = TextChange;
                }]
            }
        }
    ]);
})();