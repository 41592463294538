(function () {
    'use strict';

    angular
        .module('App')
        .component('simpleLanguageSelector', {
            template: require('./SimpleLanguageSelectorComponent.html'),
            controllerAs: 'ctrl',
            controller: ['ActionSheetService', SimpleLanguageSelectorController],
            bindings: {
                languages: '=',
                currentIndex: '=?'
            }
        })

    function SimpleLanguageSelectorController(ActionSheetService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.openLanguageSelector = openLanguageSelector;

        function init() {
            ctrl.currentIndex = 0;
            ctrl.languages[ctrl.currentIndex].active = true;
        }

        function openLanguageSelector(ev) {
            ActionSheetService
                .create(
                    generateButtons(),
                    ev.currentTarget,
                    {description: 'TRANSLATION.SWAP_LANGUAGE'}
                )
                .show()
        }

        function generateButtons() {
            return ctrl.languages.map(function (language, index) {
                var button = {
                    text: language.Name,
                    svgSource: language.image
                };

                if (language.active) {
                    button.iconClass = 'red label-color icon-shape big';
                    button.rightIconClass = 'red';
                    button.rightPrefix = 'far';
                    button.rightIcon = 'dot-circle';
                } else {
                    button.iconClass = 'icon-shape big';
                    button.rightIcon = 'circle';
                    button.onClick = function () {
                        ctrl.languages.forEach(function (lang) {
                            delete lang.active;
                        })
                        ctrl.languages[index].active = true;
                        ctrl.currentIndex = index;
                    }
                }

                return button
            })
        }
    }

})();