(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleStep', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleStep.html',
                scope: {
                    step: '=',
                    stepDone: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitController();
                    function InitController() {
                        // Listen for changes to answers
                        $scope.$watch(function($scope){
                            return $scope.step.Questions.map(function(obj){ return obj.answered; });
                        }, function (newVal) {
                            var allQuestionsAnswered = true;
                            for (var i = 0; i < $scope.step.Questions.length; i++) {
                                if (!$scope.step.Questions[i].answered) {
                                    allQuestionsAnswered = false;
                                }
                            }

                            $scope.step.answered = allQuestionsAnswered;
                        }, true);
                    }
                }]
            }
        }
    ]);
})();