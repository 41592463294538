(function () {
    'use strict';

    function getCallback(method, scope, event) {

    }

    angular.module('App')
        .directive('ngTouchstart', ['$parse', function ($parse) {
            return {
                controller: ['$scope', '$element', function ($scope, $element) {

                    $element.bind('touchstart', onTouchStart);

                    function onTouchStart(event) {
                        var method = $element.attr('ng-touchstart'),
                            fn = $parse(method, null, true),
                            callback = function () {
                                fn($scope, {$event: event});
                            };

                        $scope.$apply(callback);
                    }

                }]
            };
        }])
        .directive('ngTouchend', ['$parse', function () {
            return {
                controller: ['$scope', '$element', function ($scope, $element) {

                    $element.bind('touchend', onTouchEnd);

                    function onTouchEnd(event) {
                        var method = $element.attr('ng-touchend'),
                            fn = $parse(method, null, true),
                            callback = function () {
                                fn($scope, {$event: event});
                            };

                        $scope.$apply(callback);
                    }

                }]
            };
        }])
        .directive('ngTouchlong', ['$parse', function () {
            return {
                controller: ['$scope', '$element', '$timeout', function ($scope, $element, $timeout) {
                    var touched = false;

                    $element.bind('touchstart', onTouchStart);
                    $element.bind('touchend', onTouchEnd);

                    function onTouchStart() {
                        touched = true;

                        $timeout(function () {
                            if (touched) {
                                var method = $element.attr('ng-touchlong'),
                                    fn = $parse(method, null, true),
                                    callback = function () {
                                        fn($scope, {$event: event});
                                    };
                                $scope.$apply(callback);
                            }
                        }, 1000);
                    }

                    function onTouchEnd(event) {
                        touched = false;
                    }

                }]
            };
        }]);
})();