(() => {
    'use strict';

    angular
        .module('App')
        .component('serviceFormLiveTile', {
            template: require('./ServiceFormLiveTileComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$rootScope', '$timeout', '$interval', 'ResponsiveService', ServiceFormLiveTileController],
            bindings: {
                tile: '<'
            }
        });

    function ServiceFormLiveTileController($http, $rootScope, $timeout, $interval, ResponsiveService) {
        var ctrl = this, interval;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.activeSlideIndex = 1;
            ctrl.screenSize = ResponsiveService.isDesktop() ? $rootScope.screenSize.width / 2 : $rootScope.screenSize.width;

            $http.post('/ServiceForm/GetServiceFormFillings', {
                token: ctrl.tile.AppLink.PathToken,
                serviceFormId: ctrl.tile.AppLink.ModuleItemId,
                Limit: ctrl.tile.Settings.FillingsCount,
                Offset: 0
            }).then(function (resp) {
                ctrl.fillings = parseFillings(resp.data.Fillings);
                ctrl.LabelSettings = resp.data.LabelSettings;
                $timeout(function () {
                    changeSlides();
                })
            });
        }

        function changeSlides() {
            interval = $interval(function () {
                if (ctrl.activeSlideIndex === ctrl.fillings.length) {
                    ctrl.activeSlideIndex = 1;
                } else {
                    ctrl.activeSlideIndex++
                }
            }, 5000)
        }


        function parseFillings(fillings) {
            return _.map(fillings, function (filling) {
                return {
                    FillingTitle: filling.FillingTitle,
                    CreatedByUserName: filling.CreatedByUserName,
                    CreatedByDepartmentName: filling.CreatedByDepartmentName,
                    DateCreatedLocalString: filling.DateCreatedLocalString,
                    Image: getFillingImage(filling)
                }
            })
        }

        function getFillingImage(filling) {
            var media = _.find(filling.Fields, {'FieldType': 5});

            if (media && media.Values.length) {
                return _.head(media.Values).Media.ImageFormats['W' + $rootScope.imageWidth];
            }
        }

        function destroy() {
            $interval.cancel(interval);
        }
    }
})();