(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('modalPage', [
        function () {
            return {
                restrict: 'E',
                transclude: true,
                templateUrl: '/Scripts/Directives/Templates/ModalPage.tpl.html',
                scope: {
                    title: '@',
                    submitText: '@',
                    onClose: '&',
                    onSubmit: '&',
                    inject: '='
                },
                controller: ['$scope', '$element', '$attrs', '$timeout', '$transclude',
                    function ($scope, $element, $attrs, $timeout, $transclude) {
                        $scope.show = false;
                        $scope.isSubmitable = false;

                        // Init
                        initDirective();
                        function initDirective() {
                            $timeout(function () {
                                $scope.show = true;
                            }, 50);

                            if (angular.isObject($scope.inject)) {
                                $scope.inject.closeModalPage = closeModalPage;
                            }

                            if (angular.isFunction($scope.onSubmit) && ($attrs.onSubmit)) {
                                $scope.isSubmitable = true;
                            }
                        }

                        // Methods
                        function closeModalPage() {
                            $scope.show = false;
                            $timeout(function () {
                                if ($scope.onClose) {
                                    $scope.onClose();
                                }
                            }, 200);
                        }

                        function submitModalPage() {
                            if ($scope.onSubmit) {
                                $scope.onSubmit();
                            }
                        }

                        // Bindings
                        $scope.closeModalPage = closeModalPage;
                        $scope.submitModalPage = submitModalPage;
                    }
                ]
            }
        }
    ]);
})();