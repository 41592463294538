(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('DeviceService', ['$http', 'BasicHelper',
        function ($http, BasicHelper) {
            return {
                getDeviceId: getDeviceId,
                setNewDeviceId: setNewDeviceId
            };

            function getDeviceId() {
                const deviceId = localStorage.getItem('relesys.device.id');

                if (deviceId === null) {
                    return createAndSaveDeviceId();
                }

                return deviceId;
            }

            function setNewDeviceId(newDeviceId) {
                if (typeof newDeviceId !== 'string' || newDeviceId.length < 32) {
                    console.error('deviceId is not a string or is below the required length of 32');
                    return;
                }

                const oldDeviceId = getDeviceId();
                // save the new device id to localstorage
                persistDeviceId(newDeviceId);

                // make sure the server knows a device id has changed
                $http.post('/PushNotification/ChangeDeviceId', {
                    oldDeviceId: oldDeviceId,
                    newDeviceId: newDeviceId
                });
            }

            function createAndSaveDeviceId() {
                const deviceId = 'WEB' + BasicHelper.getSecureRandomID(32, true);
                persistDeviceId(deviceId);
                return deviceId;
            }

            function persistDeviceId(deviceId) {
                localStorage.setItem('relesys.device.id', deviceId);
            }
        }
    ]);
})();