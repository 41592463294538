(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('LoginFactory', ['$http', '$q', 'Profile', 'Menu', 'NativeFactory',
        function ($http, $q, Profile, Menu, NativeFactory) {
            var loginApproved = false;

            return {
                loginApproved: function(){
                    return loginApproved;
                },
                approveLogin: function(){
                    loginApproved = true;
                },
                autoLogin: function (code, isPreview) {
                    var d = $q.defer();

                    if (!angular.isUndefined(code)) {
                        // First clear profile cache
                        Profile.clearCache();

                        // Now do an auto login
                        $http.get('/Login/AutoLogin/' + code + '?isPreview=' + isPreview).then(function (response) {
                            if (response.data) {
                                d.resolve(true);
                            } else {
                                d.resolve(false);
                            }
                        }, function () {
                            d.resolve(false);
                        });
                    } else {
                        d.resolve(true);
                    }
                    
                    return d.promise;
                },
                login: function (username, password, rememberMe) {
                    var d = $q.defer();

                    $http.post(
                        '/Login/Login', {
                            Username: username,
                            Password: password,
                            RememberMe: rememberMe
                        }
                    )
                    .then(function (response) {
                        if (response.data.Profile) {
                            Profile.setProfile(response.data.Profile);
                            d.resolve({ success: true });
                        } else {
                            NativeFactory.user.isLoggedOut();
                            d.resolve({ success: false, error: response.data.Validation });
                        }
                    }, function () {
                        d.resolve({ success: false });
                    });

                    return d.promise;
                },
                forgotPassword: function(username) {
                    var d = $q.defer();

                    $http.post('/Login/ForgotPassword', { Username: username })
                        .then(function (response) {
                            d.resolve({ success: (response.data == "True"), error: false });
                        }, function () {
                            d.resolve({ success: false, error: true });
                        });

                    return d.promise;
                },
                logout: function(){
                    var d = $q.defer();

                    $http.post('/Login/LogoutRequest', {}).then(function (response) {
                        if (response.data.IsLoggedOut) {
                            Profile.clearCache();
                            Menu.clearCache();
                            d.resolve(true);
                        } else {
                            window.location.href = response.data.RedirectUrl;
                        }
                    }, function () {
                        d.resolve(false);
                    });

                    Profile.setProfile(null);
                    return d.promise;
                },
                isLoggedIn: function () {
                    var d = $q.defer();

                    $http.get('/Login/IsLoggedIn')
                        .then(function (response) {
                            d.resolve((response.data == "True"));
                        }, function () {
                            d.resolve(false);
                        });

                    return d.promise;
                }
            }
        }
    ]);

})();