(() => {
    'use strict';

    angular
        .module('App')
        .component('managerDepartmentPopup', {
            template: require('./ManagerDepartmentPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', 'FilterTasksService', 'TaskManagementRenderService', managerDepartmentPopupController],
            bindings: {}
        });

    function managerDepartmentPopupController($rootScope, $element, FilterTasksService, TaskManagementRenderService) {
        var ctrl = this, popup;

        ctrl.popupId = TaskManagementRenderService.managerDepartmentPopupId;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.department = null;
        ctrl.onOpenFilter = openFilter;

        $rootScope.$on('TaskManagement:tasksFiltered', function (ev, response, model, filtersCount, isReset) {
            if (isReset) {
                if (ctrl.isPopup) {
                    close();
                }
            }
        });

        function onOpen(data, popupCtrl) {
            ctrl.isPopup = true;
            popup = popupCtrl;
            ctrl.department = data.department;
            ctrl.startTime = data.startTime;
            ctrl.endTime = data.endTime;
            ctrl.categories = data.categories;
            ctrl.filterModel = data.filterModel;
        }

        function close() {
            popup && popup.close();
        }

        function openFilter() {
            FilterTasksService.openFilterTasksPopup(ctrl.filterModel);
        }
    }
})();