(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                 .state('chart', {
                     parent: 'base',
                     url: '/chart/:token/:extra',
                     templateUrl: '/Chart',
                     params: {
                         backState: null
                     },
                     resolve: {
                         dashboard: ['base', '$http', '$stateParams', function (base, $http, $stateParams) {
                             if (base) {
                                 return $http.get('/Chart/GetCharts/' + $stateParams.extra);
                             } return;
                         }]
                     },
                     controller: 'ChartController as ChartCtrl'
                 });
        }
    ]);

    app.controller('ChartController', ['$rootScope', '$scope', '$state', 'Page', 'Profile', 'dashboard', 'events', '$stateParams',
        function ($rootScope, $scope, $state, Page, Profile, dashboard, events, $stateParams) {
            var ctrl = this;
            Page.setTitle(dashboard.data.PageTitle);

            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
            } else {
                Page.showBackButton('home');
            }

            $scope.Dashboard = dashboard.data.Dashboard;
            $scope.TabCount = 0;
            $scope.Token = $stateParams.token;
            $scope.DashboardToken = $stateParams.extra;

            // Check for Content
            var i = 0;
            angular.forEach($scope.Dashboard.Tabs, function (tab) {
                tab.selected = false;
                angular.forEach(tab.Groups, function (group) {
                    if (group.Items.length > 0) {
                        tab.show = true;
                        $scope.TabCount++;
                    }
                });
                i++;
            });

            $scope.tabSelected = function (tab) {
                tab.selected = true;
            }

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);
})();