(function () {
    'use strict';

    angular.module('App')
        .factory('TaskManagementRenderService', ['$q', 'BasicHelper', 'TaskManagementRestService', 'Profile', TaskManagementRenderService]);

    function TaskManagementRenderService($q, BasicHelper, TaskManagementRestService, Profile) {
        let weekStartNumber = 1, categories;

        return {
            getAllTasks: getAllTasks,
            getTaskPreviewData: getTaskPreviewData,
            getTaskPreviewStatisticsData: getTaskPreviewStatisticsData,
            generateCalendar: generateCalendar,
            fillCalendarWithIssues: fillCalendarWithIssues,
            getNextMonth: getNextMonth,
            getPreviousMonth: getPreviousMonth,
            sortTasks: sortTasks,
            getNextTasks: getNextTasks,
            getPreviousTasks: getPreviousTasks,
            mergeIssuesWithCategories: mergeIssuesWithCategories,
            saveCategories: saveCategories,
            markAsOverdue: markAsOverdue,
            addOrUpdateIssuesToDays: addOrUpdateIssuesToDays,
            updateIssuesToDays: updateIssuesToDays,
            removeIssuesFromDays: removeIssuesFromDays,
            updatePreviewData: updatePreviewData,
            managerDepartmentPopupId: 'managerDepartmentPopupId',
        };

        function getAllTasks(model) {
            return TaskManagementRestService.getAllTasks(model).then((resp) => {
                return resp;
            });
        }

        function mergeIssuesWithCategories(issues) {
            let category;

            issues.forEach(issue => {
                category = categories.find(category => category.IssueCategoryId === issue.IssueCategoryId);
                if (category) {
                    issue.Category = category;
                    issue.color = issue.Category.Color;
                    issue.bgColor = BasicHelper.hexToRgba(issue.color, 0.3);
                }
            });

            return issues;
        }

        function getNextMonth(calendarData, loadData, issues) {
            const lastMonth = calendarData.calendar[calendarData.calendar.length - 1];
            let months = [getMonth(_.cloneDeep(lastMonth.fullDate).add(1, 'months')), getMonth(_.cloneDeep(lastMonth.fullDate).add(2, 'months'))];

            calendarData.calendar.push(months[0]);
            calendarData.calendar.push(months[1]);

            if (loadData === undefined) {
                loadData = true;
            }

            return generateFlatCalendar(months, [], issues, loadData, calendarData.isUserAllowedToCompleteIssue).then((flatMonth) => {
                calendarData.flatDays = calendarData.flatDays.concat(flatMonth);
                return calendarData;
            });
        }

        function getPreviousMonth(calendarData, loadData, issues) {
            const firstMonth = calendarData.calendar[0];
            let months = [getMonth(_.cloneDeep(firstMonth.fullDate).subtract(2, 'months')),
                getMonth(_.cloneDeep(firstMonth.fullDate).subtract(1, 'months'))];

            calendarData.calendar.unshift(months[1]);
            calendarData.calendar.unshift(months[0]);

            if (loadData === undefined) {
                loadData = true;
            }

            return generateFlatCalendar(months, [], issues, loadData, calendarData.isUserAllowedToCompleteIssue).then((flatMonth) => {
                calendarData.flatDays = flatMonth.concat(calendarData.flatDays);
                return calendarData;
            });
        }

        function fillCalendarWithIssues(calendarData, IsUserAllowedToCompleteIssue) {
            return TaskManagementRestService.getTasks(calendarData.calendar, IsUserAllowedToCompleteIssue).then((tasks) => {
                if (categories.length) {
                    mergeIssuesWithCategories(tasks.Tasks);
                }
                return addOrUpdateIssuesToDays(calendarData.flatDays, tasks.Tasks);
            });
        }

        function getWeekdays(isIso) {
            let sunday,
                weekDays = moment.weekdays();

            if (isIso) {
                sunday = weekDays.shift();
                weekDays.push(sunday);
            }

            return weekDays;
        }


        function getShortWeekdays(isIso, isShorten) {
            let sunday,
                weekDays = moment.weekdaysShort();

            if (isIso) {
                sunday = weekDays.shift();
                weekDays.push(sunday);
            }

            if (isShorten) {
                weekDays = weekDays.map(day => day.slice(0, 2));
            }

            return weekDays;
        }

        function getWeeksStartAndEndInMonth(month, year) {
            let fullDate, day,
                weeks = [],
                flatWeeks = [],
                days = [],
                firstDate = new Date(year, month, 1),
                lastDate = new Date(year, month + 1, 0),
                numDays = lastDate.getDate(),
                start = 1,
                end = 7 - firstDate.getDay();

            let tz = Profile.getProfile().TimeZone.IANA;
            tz = (typeof tz === 'string') ? tz : 'Europe/Paris';

            if (weekStartNumber === 1) {
                if (firstDate.getDay() === 0) {
                    end = 1;
                } else {
                    end = 7 - firstDate.getDay() + 1;
                }
            }
            while (start <= numDays) {
                days = [];
                for (let i = start; i <= end; i++) {
                    fullDate = moment(i + '.' + (month + 1) + '.' + year, 'DD.MM.YYYY');
                    day = {
                        date: i,
                        jsDate: new Date(fullDate._d.getTime()),
                        fullDate: fullDate,
                        isCurrent: fullDate.format('DD.MM.YYYY') === moment().tz(tz).format('DD.MM.YYYY'),
                        issues: []
                    };
                    flatWeeks.push(day);
                    days.push(day);
                }
                weeks.push(days);
                start = end + 1;
                end = end + 7;
                end = start === 1 && end === 8 ? 1 : end;
                if (end > numDays) {
                    end = numDays;
                }
            }
            return {
                days: flatWeeks,
                weeks: weeks
            };
        }

        function getMonth(momentDate, isCurrent) {
            const generatedWeeks = getWeeksStartAndEndInMonth(momentDate.month(), momentDate.year());
            return {
                year: momentDate.year(),
                order: parseInt(momentDate.year() + '' + momentDate.month().toString().padStart(2, '0')),
                title: momentDate.format('MMM'),
                isCurrent: isCurrent,
                fullDate: momentDate,
                weeks: generatedWeeks.weeks,
                days: generatedWeeks.days
            };
        }

        function generateCalendar(issues, categoriesSource, isIso, minDate, maxDate) {
            categories = categoriesSource;

            const currentPeriod = generateCalendarMonths(minDate, maxDate);
            issues = mergeIssuesWithCategories(issues, categoriesSource);

            return generateFlatCalendar(currentPeriod, [], issues, false).then(function (days) {
                return {
                    calendar: currentPeriod,
                    flatDays: days,
                    daysOfWeek: getWeekdays(isIso),
                    shortDaysOfWeek: getShortWeekdays(isIso, true)
                }
            });
        }

        function generateCalendarMonths(minDate, maxDate) {
            let months = [];
            if (minDate && maxDate) {

                const today = moment();
                let month = moment(minDate).startOf('month').startOf('day');
                const maxMonth = moment(maxDate).startOf('month').startOf('day');
                let hasCurrentMonth = false;

                // Add month before
                months.push(getMonth(month.clone().subtract(1, 'months')));

                while (month <= maxMonth) {
                    var isCurrent = today.isSame(month, 'month');
                    months.push(getMonth(month, isCurrent));
                    month = month.clone().add(1, 'month');

                    if (isCurrent) {
                        hasCurrentMonth = true;
                    }
                }

                if (!hasCurrentMonth) {
                    months[1].isCurrent = true;
                }

                // Add month after
                months.push(getMonth(maxMonth.add(1, 'months')));

            } else {
                months = [
                    getMonth(moment().subtract(1, 'months')),
                    getMonth(moment(), true),
                    getMonth(moment().add(1, 'months'))
                ];
            }

            return months;
        }

        function isDateSameDay(src, tar) {
            return (src.getFullYear() === tar.getFullYear()
                && src.getMonth() === tar.getMonth()
                && src.getDate() === tar.getDate());
        }

        function isDateSameDayOrBefore(src, tar) {
            if (src.getFullYear() > tar.getFullYear()) {
                return false;
            }
            if (src.getFullYear() < tar.getFullYear()) {
                return true;
            }
            if (src.getMonth() > tar.getMonth()) {
                return false;
            }
            if (src.getMonth() < tar.getMonth()) {
                return true;
            }

            return (src.getDate() <= tar.getDate());
        }

        function addOrUpdateIssue(issueDay, issueSource, ignoreAdd) {
            const index = issueDay.issues.findIndex(issue => issue.IssueId === issueSource.IssueId);

            if (index === -1) {
                if (!ignoreAdd) {
                    issueDay.issues.push(issueSource);
                }
            } else {
                issueDay.issues[index] = { ...issueDay.issues[index], ...issueSource };
            }
        }

        function addOrUpdateIssuesToDays(flatDays, issues, ignoreAdd) {
            const lastDay = flatDays.reduce((max, current) => {
                return current.jsDate > max.jsDate ? current : max;
            }, flatDays[0]);

            issues.forEach(function (issue) {
                const startTime = new Date(issue.StartTimeLocal);
                  let endTime = new Date(issue.EndTimeLocal),
                    issueDay;

                if (isDateSameDayOrBefore(endTime, lastDay.jsDate) === false) {
                    endTime = new Date(lastDay.jsDate.getTime());
                }

                if (isDateSameDay(startTime, endTime)) {
                    issueDay = flatDays.find(day => isDateSameDay(startTime, day.jsDate));
                    issueDay && addOrUpdateIssue(issueDay, issue, (ignoreAdd === true));
                } else {
                    while (isDateSameDayOrBefore(startTime, endTime)) {
                        issueDay = flatDays.find(day => isDateSameDay(day.jsDate, startTime));
                        issueDay && addOrUpdateIssue(issueDay, issue, (ignoreAdd === true));
                        startTime.setDate(startTime.getDate() + 1);
                    }
                }
            });

            return flatDays;
        }

        function updateIssuesToDays(flatDays, issues) {
            return addOrUpdateIssuesToDays(flatDays, issues, true);
        }

        function removeIssuesFromDays(flatDays, issues, deleteSeries) {
            flatDays.forEach((day) => {
                if (day.issues.length) {
                    issues.forEach((issue) => {
                        if (deleteSeries && issue.ParentIssueId === issues[0].ParentIssueId) {
                            day.issues = day.issues.filter(issue => issue.ParentIssueId !== issue.ParentIssueId);
                        } else {
                            day.issues = day.issues.filter(issue => issue.IssueId !== issue.IssueId);
                        }
                    });
                }
            });
        }

        function generateFlatCalendar(monthsData, flatDays, issues, loadIssues, isUserAllowedToCompleteIssue) {
            const months = Array.isArray(monthsData) ? monthsData : [monthsData];
            flatDays = Array.isArray(flatDays) ? flatDays : [];

            months.forEach((month) => {
                flatDays = flatDays.concat(month.days);
            });

            if (issues) {
                return $q((resolve) => {
                    const daysWithIssues = addOrUpdateIssuesToDays(flatDays, issues);
                    resolve(daysWithIssues)
                });
            } else if (loadIssues) {
                return TaskManagementRestService.getTasks(months, isUserAllowedToCompleteIssue).then((issues) => {
                    if (categories.length) {
                        mergeIssuesWithCategories(issues.Tasks);
                    }
                    return addOrUpdateIssuesToDays(flatDays, issues.Tasks);
                })
            } else {
                return $q(resolve => {
                    resolve(flatDays);
                });
            }
        }

        function sortTasks(issues, sortByStart) {
            let sortedIssues = [];
            if (sortByStart) {
                sortedIssues = issues.sort((a, b) => {
                    return new Date(a.StartTimeLocal) - new Date(b.StartTimeLocal);
                });
            } else {
                sortedIssues = issues.sort((a, b) => {
                    return new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                });
            }

            return sortedIssues;
        }

        function getTaskPreviewData(issueSource, token) {
            return TaskManagementRestService
                .getTaskPreviewData(token || issueSource.IssueToken)
                .then(data => updatePreviewData(issueSource, data));
        }

        function getTaskPreviewStatisticsData(issueSource, token) {
            return TaskManagementRestService
                .getTaskPreviewStatisticsData(token || issueSource.IssueToken)
                .then(data => {
                    issueSource.Statistics = data;
                    issueSource.LoadStatistics = false;
                    return issueSource;
                }).catch(() => {
                    issueSource.LoadStatistics = false;
                    issueSource.Statistics = {};
                    return null;
                });
        }

        function updatePreviewData(issueSource, issueUpdateSource) {
            let issue = issueSource || {};
            const keys = Object.keys(issueUpdateSource);

            if (issue) {
                issue = { ...issue, ...issueUpdateSource.IssueModel };
            }

            keys.forEach(key => {
                if (key !== 'IssueModel') {
                    issue[key] = issueUpdateSource[key]
                }
            })

            issue.Categories?.length && issue.Categories.forEach(category => {
                category.bgColor = BasicHelper.hexToRgba(category.Color, 0.3);
            });

            return issue;
        }

        function getMonthOrder(month) {
            return parseInt(month.year() + '' + month.month().toString().padStart(2, '0'));
        }

        function isMonthAlreadyExists(issue, calendar, months) {
            const monthOrder = getMonthOrder(moment(issue.StartTimeLocal));
            return calendar.find(item => item.order === monthOrder) || months.find(month => getMonthOrder(month) === monthOrder);
        }

        function groupIssuesByMonths(issues, calendar, isForvard) {
            const months = [];
            let lastMonth;
            
            issues.forEach((issue) => {
                if (!isMonthAlreadyExists(issue, calendar, months)) {
                    const lastCalendarItem = calendar[calendar.length - 1];
                    const startTimeOrder = getMonthOrder(moment(issue.StartTimeLocal));
                    const lastCalendarOrder = getMonthOrder(lastCalendarItem.fullDate);
                    
                    if (isForvard) {                        
                        if (startTimeOrder - lastCalendarOrder > 0) {
                            lastMonth = _.cloneDeep(calendar[calendar.length - 1]?.fullDate).add(1, 'months');
                            while (getMonthOrder(lastMonth) <= startTimeOrder) {
                                months.push(_.cloneDeep(lastMonth));
                                lastMonth = lastMonth.add(1, 'months');
                            }
                        }
                    } else {
                        if (lastCalendarOrder - startTimeOrder > 0) {
                            const firstMonth = _.cloneDeep(calendar[0]?.fullDate).subtract(1, 'months');
                            while (getMonthOrder(firstMonth) >= startTimeOrder) {
                                months.unshift(_.cloneDeep(firstMonth));
                                lastMonth = firstMonth.subtract(1, 'months');
                            }
                        }
                    }
                }
            });
            return months;
        }

        function getTasks(issueId, calendar, isAfter) {
            return TaskManagementRestService.getTasksBeforeAfter(issueId, 10, isAfter).then((data) => {
                if (data.Tasks) {
                    mergeIssuesWithCategories(data.Tasks);
                }

                const months = groupIssuesByMonths(data.Tasks, calendar, isAfter).map(month => getMonth(month));
                return generateFlatCalendar(months, data.Tasks, null, true).then(() => {
                    return {
                        months: months,
                        LoadMore: data.LoadMore
                    }
                });
            });
        }

        function getNextTasks(issueId, calendar) {
            return getTasks(issueId, calendar, true);
        }

        function getPreviousTasks(issueId, calendar) {
            return getTasks(issueId, calendar, false);

        }

        function markAsOverdue(issues) {
            issues.forEach(issue => {
                issue.IsOverdue = true;
            });
            
            return issues;
        }

        function saveCategories(categoriesSrc) {
            categories = categoriesSrc;
        }
    }
})();
