(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('metricChart', ['$log', '$http', 'Page', 'Profile', 'TimeLocale', 'ChartHelper',
        function ($log, $http, Page, Profile, TimeLocale, ChartHelper) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/MetricChart.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chartIsLoading = true;
                    scope.chart = {
                        IsNegative: false,
                        IsNeutral: false,
                        ShowDevelopment: false,
                        ShowTarget: false,
                        OnTarget: false,
                        Date: null,
                        Value: null,
                        Label: null
                    };

                    // Get data
                    var filterDepartmentId = Profile.getProfile().FilterDepartmentId;
                    var hasMultipleDepartments = Profile.getProfile().HasMultiDepartment;

                    var url = Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken;
                    if (hasMultipleDepartments) { url += "&departmentid=" + filterDepartmentId; }

                    $.getJSON(url,
                        function (response) {
                            if (response.MetricData !== null) {
                                // Date
                                if (response.DateLastUpdated !== null) {
                                    scope.chart.Date = TimeLocale.dateTimeFormat(response.DateLastUpdated, "H:mm | D MMM YYYY");
                                }

                                // Value with decimal property
                                var chartValue = ChartHelper.formatNumber(response.MetricData.MetricValue, response.MetricData.Decimals, ",", ".");

                                //if (response.MetricData.MetricValue % 1 !== 0) {
                                //    chartValue = ChartHelper.formatNumber(response.MetricData.MetricValue, 2, ",", ".");
                                //} else {
                                //    chartValue = ChartHelper.formatNumber(response.MetricData.MetricValue, 0, ",", ".");
                                //}

                                scope.chart.Value = chartValue;
                                
                                // replace merge variables in the metric-label with label attached with the data-value.
                                //scope.data.Label = (response.MetricData.Label !== null && response.MetricData.Label.length > 0) ? response.MetricData.Label : scope.data.Label;
                                scope.data.Label = response.MetricData.Label;


                                // Development
                                if (response.MetricData.ShowDevelopment) {
                                    scope.chart.ShowDevelopment = true;
                                    scope.chart.IsNegative = (response.MetricData.MetricDifference < 0);
                                    scope.chart.IsNeutral = (response.MetricData.MetricDifference === 0);
                                }

                                // Target
                                if (response.MetricData.ShowTarget && response.MetricData.Target !== null) {
                                    scope.chart.ShowTarget = true;
                                    scope.chart.OnTarget = (response.MetricData.MetricValue >= response.MetricData.Target);
                                }

                                scope.chart.Prefix = response.Prefix;
                                scope.chart.Suffix = response.Suffix;

                            } else {
                                scope.chart.Value = "-";
                            }


                            scope.chartIsLoading = false;
                            scope.$apply();
                        }).fail(function () {
                            scope.chart.Value = "-";
                            scope.chartIsLoading = false;
                            scope.$apply();
                        });

                    // Open App Link
                    scope.openLink = function () {
                        Page.stateGoLink(scope.data.AppLink);
                    }
                }
            }
        }
    ]);
})();