'use strict';

angular
    .module('App')
    .factory('LeaveRegistrationService', ['$http', LeaveRegistrationService]);

function LeaveRegistrationService($http) {
    return {
        getRegisteredLeaves: getRegisteredLeaves,
        createLeaveRegistration: createLeaveRegistration,
        updateLeaveRegistration: updateLeaveRegistration,
        deleteLeaveRegistration: deleteLeaveRegistration,
        getLeaveRegistrationEditPage: getLeaveRegistrationEditPage,
        getLeaveRegistrationCreatePage: getLeaveRegistrationCreatePage,
        translateLeaveMessage: translateLeaveMessage,
    }

    function getRegisteredLeaves() {
        return $http.post('LeaveRegistration/GetList');
    }    
    
    function getLeaveRegistrationEditPage(token) {
        return $http.post('LeaveRegistration/GetEditPage', { token });
    }  
    
    function getLeaveRegistrationCreatePage() {
        return $http.post('LeaveRegistration/GetCreatePage');
    }   
    
    function updateLeaveRegistration(token, model) {
        return $http.post('LeaveRegistration/Update', { token, model });
    }   
    
    function deleteLeaveRegistration(token) {
        return $http.post('LeaveRegistration/Delete', { token });
    }   
    
    function createLeaveRegistration(data) {
        return $http.post('LeaveRegistration/Create', data);
    }
    
    function translateLeaveMessage(token) {
        return $http.post('LeaveRegistration/GetTranslation', {token});
    }
}