(() => {
    'use strict';

    angular
        .module('App')
        .component('setNewPin', {
            template: require('./SetNewPinComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', 'PinCodeService', '$rootScope', 'events', SetNewPinController],
            bindings: {
                profile: '<',
                onSecure: '&',
                onBiometricAvailable: '&'
            }
        });

    function SetNewPinController($timeout, PinCodeService, $rootScope, events) {
        var ctrl = this, firstInitPin;

        ctrl.$onInit = init;
        ctrl.pinTitle = 'PIN.CREATE_YOUR';
        ctrl.showKeyboard = true;
        ctrl.submitCode = submitCode;
        ctrl.isSaving = false;

        function init(){
            ctrl.allowBiometric = false;
            ctrl.biometricSetupState = null;

            const userToken = ctrl.profile && ctrl.profile.UserToken || null;

            PinCodeService.getBiometricPinStatus(userToken).then(() => {
                ctrl.allowBiometric = true;
            }, () => {
                ctrl.allowBiometric = false;
            });
        }

        function submitCode(pin) {
            if(ctrl.isSaving){
                return;
            }

            if (!firstInitPin) {
                firstInitPin = pin.join('');
                ctrl.pinTitle = 'PIN.REPEAT';
                ctrl.pinCodeModel = [null, null, null, null];
            } else {
                if (firstInitPin === pin.join('')) {
                    ctrl.pinTitle = 'PIN.SAVING';
                    ctrl.isPinValid = true;
                    ctrl.isPinInvalid = false;
                    ctrl.showKeyboard = false;
                    ctrl.isSaving = true;

                    PinCodeService.setPin(firstInitPin).then(function (res) {
                        ctrl.pinTitle = 'PIN.SUCCESS';
                        PinCodeService.loadDataAfterPin().finally(() => {
                            // Set the result action
                            var resAction = function () {
                                $rootScope.$broadcast(events.PINCODE_DIDHIDE);
                                PinCodeService.goToState();
                            };

                            if (ctrl.allowBiometric) {
                                // Biometric can be set up so we should ask
                                ctrl.onBiometricAvailable({
                                    userToken: res.response.data.userToken,
                                    pinCode: firstInitPin,
                                    nextAction: resAction
                                });
                            } else if (res.response.data.isSecure) {
                                // Device is secure, show that message first
                                ctrl.onSecure({secureAction: resAction});
                            } else {
                                // Run result now
                                resAction();
                            }
                        });
                    }, () => {
                        ctrl.isSaving = false;
                    });
                } else {
                    firstInitPin = null;
                    ctrl.pinTitle = 'PIN.PIN_NOT_MATCH';
                    ctrl.isPinValid = false;
                    ctrl.isPinInvalid = true;
                    ctrl.showKeyboard = false;
                    ctrl.isSaving = false;

                    $timeout(function () {
                        ctrl.pinCodeModel = [null, null, null, null];
                        ctrl.isPinValid = false;
                        ctrl.isPinInvalid = false;
                        ctrl.showKeyboard = true;
                        ctrl.pinTitle = 'PIN.CREATE_YOUR';
                    }, 2000);
                }
            }
        }
    }
})();