(function () {
    'use strict';

    angular.module('App')
        .component('stagesPopup', {
            template: require('./StagesPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', 'PopupWrapperService', 'ServiceFormRenderService', StagesPopupController],
            bindings: {
                stages: '<',
                onClose: '&'
            }
        })

    function StagesPopupController($element, $scope, PopupWrapperService, ServiceFormRenderService) {
        let ctrl = this, popup;

        ctrl.popupId = 'stagesPopupId';

        ctrl.close = close;
        ctrl.onSelectStage = onSelectStage;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.headerTitle = ServiceFormRenderService.getLabelSettings().TranslatedEntityNameSingular || ServiceFormRenderService.getLabelSettings().EntityNameSingular;

        const destroyFillingCreated = $scope.$on('fillingCreated', close);

        function onSelectStage(stage) {
            PopupWrapperService.getPopup('createFillingPopupId').open({
                stage: stage,
                onClose: latestFilling => {
                    if (ctrl.onClose) {
                        ctrl.onClose({ latestFilling });
                    }
                }
            });
        }

        function close() {
            destroyFillingCreated();
            popup.remove();
            $element.remove();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }
    }
})();