require('konva/konva.min.js');

(function () {
    'use strict';

    angular.module('App')
        .directive('imageLoaded', function () {
            return {
                restrict: 'A',
                scope: {
                    attachment: '<',
                    onImageLoaded: '&'
                },
                link: function (scope, element, attrs) {
                    element.bind('load', function () {
                        scope.$apply(function () {
                            scope.attachment.isLoaded = true;
                            scope.onImageLoaded(element);
                        });
                    });
                }
            };
        })
        .component('attachmentsViewer', {
            templateUrl: '/Scripts/Components/AttachmentsViewer/AttachmentsViewer.html',
            controllerAs: 'ctrl',
            controller: ['$http', '$element', '$scope', '$timeout', '$rootScope', '$compile', '$filter', 'Page',
                'ResponsiveService', 'BasicHelper', 'Profile', 'ToastFactory',
                'ConfirmPopupService', AttachmentsViewerController],
            bindings: {
                attachments: '<',
                selectedIndex: '<',
                editable: '<',
                onBrowse: '<',
                onRemove: '<',
                onPopupRegistered: '<',
                onImageUpdate: '<',
                enableComments: '<',
                forceImageUpdate: '<',
                moduleToken: '@'
            }
        });

    function AttachmentsViewerController($http, $element, $scope, $timeout, $rootScope, $compile, $filter, Page,
                                         ResponsiveService, BasicHelper, Profile, ToastFactory,
                                         ConfirmPopupService) {
        const ctrl = this;
        let popup, firstInit = true;
        let imageEditingContainer, stage, imageHeight, imageWidth, image, scale,
            drawingLayer, lastElementsCount = 0, writingLayer, figuresLayer, figuresTransformer, figuresHistory = [];

        ctrl.popupId = 'attachmentsViewerPopup';
        ctrl.imageEditMode = 'select';
        ctrl.currentUser = Profile.getProfile();

        ctrl.close = close;
        ctrl.onOpen = onOpen;
        ctrl.deleteAttachment = deleteAttachment;
        ctrl.browseFiles = browseFiles;
        ctrl.isViewableFile = isViewableFile;
        ctrl.getFileIcon = getFileIcon;
        ctrl.listenForEvents = listenForEvents;
        ctrl.getImageUrl = getImageUrl;
        ctrl.initMediaViewer = initMediaViewer;
        ctrl.setSlide = setSlide;
        ctrl.formatTime = formatTime;
        ctrl.getLoadingItems = getLoadingItems;
        ctrl.getThumbUrl = getThumbUrl;
        ctrl.getLinkUrl = getLinkUrl;

        ctrl.drawMode = 'brush';
        ctrl.colors = [
            {val: '#FFFFFF', selected: true}, {val: '#000000'}, {val: '#E65446'}, {val: '#DCA24B'}, {val: '#E6DE2E'},
            {val: '#5FB951'}, {val: '#6631FE'}];
        ctrl.strokeWidth = 5;

        ctrl.enableImageEditing = enableImageEditing;
        ctrl.closeImageEditing = closeImageEditing;
        ctrl.saveImageEditing = saveImageEditing;
        ctrl.initDrawing = initDrawing;
        ctrl.initWriting = initWriting;
        ctrl.initFigures = initFigures;
        ctrl.undoDrawing = undoDrawing;
        ctrl.toggleTextColor = toggleTextColor;
        ctrl.selectColor = selectColor;
        ctrl.setStrokeWidth = setStrokeWidth;
        ctrl.addFigure = addFigure;

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
            ctrl.fileDownloadUrl = null;
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.showSingleMedia = ctrl.attachments.length === 1;
            ctrl.swiper = null;

            Page.overlayColorAdjustment('#000', '#fff');
            $scope.$watchCollection('ctrl.attachments', initMediaViewer);
        }

        function close() {
            Page.revertOverlayColorAdjustment();
            popup.remove();
            ctrl.onPopupRegistered && $element.remove();
        }

        function listenForEvents(swiper) {
            ctrl.swiper = swiper;
            ctrl.fileDownloadUrl = ctrl.attachments[0].media && ctrl.attachments[0].media.DownloadUrl;

            ctrl.swiper.on('slideChange', function () {
                $scope.$applyAsync();
            });

            // Load first image
            if (ctrl.isDesktop) {
                ctrl.swiper.$el.on('click', function (e) {
                    e.target.className === 'swiper-button-next' && ctrl.swiper.slideNext();
                    e.target.className === 'swiper-button-prev' && ctrl.swiper.slidePrev();

                    const activeImage = document.querySelector('.file-type-image.active');
                    if (activeImage) {
                        activeImage.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                    }
                });
            }
        }

        function getFileIcon(file) {
            switch (file.MediaTypeId) {
                case 1:
                    return 'fas fa-file-image';
                case 2:
                    return 'fas fa-file-video';
                case 3:
                    return 'fas fa-file-pdf';
                case 6:
                    return 'fas fa-file-audio';
                case 7:
                    return 'fas fa-file-excel';
                case 8:
                    return 'fas fa-file-excel';
                case 10:
                    return 'fas fa-file-word';
                case 11:
                    return 'fas fa-file-powerpoint';
            }

            return null;
        }

        function getImageUrl(file) {
            if (Page.getSettings().Base.ImageZoomEnabled) {
                return file.ImageFormats.W1500 || file.OriginalUrl;
            } else {
                const width = $element.find('.media-viewer-content').width() > 0 ?
                    $element.find('.media-viewer-content').width() :
                    angular.element('body').width();

                return file.ImageFormats['W' + BasicHelper.getImageVariantBySize(width)];
            }
        }

        function initMediaViewer() {
            ctrl.swiperParams = {
                allowSwipeToPrev: ctrl.attachments.length > 1,
                allowSwipeToNext: ctrl.attachments.length > 1,
                watchOverflow: true,
                zoom: Page.getSettings().Base.ImageZoomEnabled
            };

            ctrl.showNavButtons = ctrl.isDesktop && ctrl.attachments.length > 1;

            ctrl.swiperParams.pagination = {
                el: '.swiper-pagination',
                paginationType: 'bullets',
                dynamicBullets: !ctrl.isDesktop && true,
                clickable: ctrl.isDesktop && true
            };

            if (ctrl.isDesktop) {
                ctrl.swiperParams.pagination.clickable = true;
                ctrl.swiperParams.pagination.renderBullet = renderBullet;
            }

            $timeout(function () {
                ctrl.swiper && ctrl.swiper.update();
                if (ctrl.forceImageUpdate && firstInit) {
                    firstInit = false;
                    ctrl.enableImageEditing();
                }
            }, 300);
        }

        function renderBullet(index, className) {
            const item = ctrl.attachments[index],
                media = item.media ? item.media.ImageFormats.W100 : '';

            return `<button tabindex="0"
                            aria-label="${index + 1}"
                            ng-style="{'background-image': url(${media}) }"
                            class="${className}"
                    ></button>`;
        }

        function isViewableFile(file) {
            if (file) {
                const viewableFiles = [2, 6];
                return _.indexOf(viewableFiles, file.MediaTypeId) >= 0;
            }
        }

        function deleteAttachment(ev, attachment) {
            ev.stopPropagation();
            ev.preventDefault();
            ctrl.onRemove && ctrl.onRemove(attachment);
        }

        function browseFiles() {
            ctrl.onBrowse && ctrl.onBrowse();
        }

        function setSlide(key) {
            ctrl.swiper.slideTo(key)
        }

        function formatTime(tick) {
            return moment(tick).format('mm:ss');
        }

        function getLoadingItems() {
            return ctrl.attachments.filter(function (item) {
                return (item.status === 0 || item.status === 1) && item.progress < 100
            });
        }

        function getThumbUrl(attachment) {
            var thumb = '';
            if (attachment.media) {
                thumb = attachment.media.ImageFormats.W100 || attachment.media.ThumbnailUrl || '';
            }

            return thumb;
        }

        function getLinkUrl(media) {
            if (BasicHelper.isWrapperApp()) {
                return media.DownloadUrl;
            } else {
                return media.Url;
            }
        }

        function enableImageEditing() {
            ctrl.imageEditing = true;
            ctrl.isLoading = true;
            $timeout(initImageEditing)
        }

        function closeImageEditing() {
            removeLastElements(ctrl.imageEditMode);
            switch (ctrl.imageEditMode) {
                case 'draw':
                    stopDrawing();
                    ctrl.showColorPicker = false;
                    ctrl.imageEditMode = 'select';
                    break;
                case 'write':
                    ctrl.showColorPicker = false;
                    ctrl.imageEditMode = 'select';
                    break;
                case 'figures':
                    ctrl.showColorPicker = false;
                    ctrl.imageEditMode = 'select';
                    stopFiguring();
                    break;
                case 'select':
                    ConfirmPopupService.open({
                        message: $filter('translate')('SERVICEFORM.FEEDBACK.UNDO_IMAGE_CHANGES'),
                    }).then(() => {
                        ctrl.showColorPicker = false;
                        ctrl.imageEditing = false
                        ctrl.showComment = false;
                    })
            }
        }

        function saveImageEditing() {
            lastElementsCount = 0;
            ctrl.showColorPicker = false;
            switch (ctrl.imageEditMode) {
                case 'draw':
                    stopDrawing();
                    ctrl.imageEditMode = 'select';
                    break;
                case 'write':
                    ctrl.imageEditMode = 'select';
                    break;
                case 'figures':
                    ctrl.imageEditMode = 'select';
                    stopFiguring();
                    break;
                case 'select':
                    saveImage();
            }
        }

        function saveImage() {
            const imageData = stage.toDataURL({
                    mimeType: ctrl.attachments[ctrl.swiper.activeIndex].type,
                    pixelRatio: 1 * scale
                }),
                name = ctrl.attachments[ctrl.swiper.activeIndex].media.FileName.split('.');
            ctrl.isLoading = true;
            $http.post(Page.getSettings().MediaServerDomain + '/Upload?isBase64=true', {
                AccountToken: ctrl.currentUser.AccountToken,
                UserToken: ctrl.currentUser.UserToken,
                base64data: imageData,
                base64fileName: `${name[0]}_edited.${name[1]}`
            }).then((resp) => {
                const imageUpdate = ctrl.onImageUpdate.length > 1 ? ctrl.onImageUpdate[0] : ctrl.onImageUpdate,
                    hasField = ctrl.onImageUpdate.length > 1,
                    options = {
                        comment: ctrl.comment,
                        media: resp.data.Files[0]
                    };
                if (hasField) {
                    options.field = ctrl.onImageUpdate[1];
                } else {
                    options.selectedIndex = ctrl.selectedIndex
                }
                imageUpdate(options).then(ctrl.close);
            }).catch(resp => {
                ctrl.isLoading = false
                ToastFactory.error(resp.data.Message)
            })
        }

        function changeImageEditMode(mode) {
            resetColor();
            switch (mode) {
                case 'draw':
                    ctrl.showColorPicker = true;
                    ctrl.showComment = false;
                    break;
                case 'write':
                    ctrl.showComment = false;
                    break;
                case 'figures':
                    ctrl.showComment = false;
                    break;
                case 'select':
                    ctrl.showComment = true;
                    break;
            }
        }

        function initImageEditing() {
            imageEditingContainer = $element.find('#image-editing-container');

            const imageObj = new Image();
            imageObj.onload = () => {
                onImageLoaded(imageObj);
            }
            imageObj.src = `/media/get/` + ctrl.attachments[ctrl.swiper.activeIndex].media.MediaToken;
        }

        function onImageLoaded(imageSrc) {
            const aspectRatio = imageSrc.width / imageSrc.height,
                imageLayer = new Konva.Layer({id: 'image'});
            let sizes;

            if (imageSrc.width > imageEditingContainer.outerWidth()) {
                sizes = adjustToWidth(imageSrc.width);
                if (sizes?.height > imageEditingContainer.outerHeight()) {
                    sizes = adjustToHeight(sizes.height);
                }

            } else if (imageSrc.height > imageEditingContainer.outerHeight()) {
                sizes = adjustToHeight(imageSrc.height);
                if (sizes?.width > imageEditingContainer.outerWidth()) {
                    sizes = adjustToWidth(sizes.width);
                }
            }

            imageWidth = sizes?.width || imageSrc.width;
            imageHeight = sizes?.height || imageSrc.height;
            scale = sizes?.newScale || 1;

            function adjustToWidth(width) {
                return {
                    width: imageEditingContainer.outerWidth(),
                    height: imageEditingContainer.outerWidth() / aspectRatio,
                    newScale: width / imageEditingContainer.outerWidth()
                }
            }

            function adjustToHeight(height) {
                return {
                    height: imageEditingContainer.outerHeight(),
                    width: imageEditingContainer.outerHeight() * aspectRatio,
                    newScale: height > imageEditingContainer.outerHeight()
                }
            }

            image = new Konva.Image({
                x: 0,
                y: 0,
                image: imageSrc,
                width: imageWidth,
                height: imageHeight,
            });

            $scope.$apply(() => {
                ctrl.isLoading = false;
                $scope.$watch('ctrl.imageEditMode', changeImageEditMode)
            })

            stage = new Konva.Stage({
                container: 'image-editing-container',   // id of container <div>
                height: imageHeight,
                width: imageWidth
            });

            stage.add(imageLayer);

            imageLayer.add(image);
        }

        function initDrawing() {
            drawingLayer = stage.getLayers().find(layer => layer.attrs.id === 'draw') || new Konva.Layer({id: 'draw'});
            drawingLayer.listening(false);
            let isPaint = false, lastLine;

            ctrl.imageEditMode = 'draw'
            ctrl.drawMode = 'brush'
            ctrl.strokeWidth = 5;

            stage.add(drawingLayer);
            stage.on('mousedown touchstart', (e) => {
                const pos = stage.getPointerPosition();
                isPaint = true;
                lastLine = new Konva.Line({
                    stroke: ctrl.colors.find(color => color.selected).val,
                    strokeWidth: ctrl.strokeWidth,
                    globalCompositeOperation:
                        ctrl.drawMode === 'brush' ? 'source-over' : 'destination-out',
                    lineCap: 'round',
                    lineJoin: 'round',
                    points: [pos.x, pos.y, pos.x, pos.y],
                });
                drawingLayer.add(lastLine);
            });

            stage.on('mouseup touchend', () => {
                isPaint = false
                lastElementsCount++;
            });

            stage.on('mousemove touchmove', (e) => {
                if (isPaint) {
                    e.evt.preventDefault();

                    const pos = stage.getPointerPosition(),
                        newPoints = lastLine.points().concat([pos.x, pos.y]);

                    lastLine.points(newPoints);
                }
            });
        }

        function stopDrawing() {
            stage.off('mousedown touchstart');
            stage.off('mouseup touchend');
            stage.off('mousemove touchmove');
        }

        function stopFiguring() {
            stage.off('click tap');
            figuresHistory = [];
            resetFiguresTransformer();
            forceFiguresNotDraggable();
        }

        function removeLastElements(type) {
            const layer = {
                draw: drawingLayer,
                write: writingLayer,
                figures: figuresLayer
            }
            if (type === 'figures') {
                resetFiguresTransformer();
            }
            if (lastElementsCount > 0) {
                for (let i = 0; i < lastElementsCount; i++) {
                    const children = layer[type].getChildren();
                    children[children.length - 1].remove();
                }
                lastElementsCount = 0;
            }
        }

        function initWriting() {
            let writingGroup;
            writingLayer = stage.getLayers().find(layer => layer.attrs.id === 'write') || new Konva.Layer({id: 'write'});
            writingGroup = new Konva.Group({
                x: (imageWidth / 2) - 15,
                y: imageHeight / 2 - 15,
                draggable: true,
            });

            ctrl.textNode = new Konva.Text({
                x: 5,
                y: 5,
                fontFamily: window.getComputedStyle(document.querySelector('body'), null)
                    .getPropertyValue('font-family'),
                fontSize: 20,
                fill: ctrl.colors.find(color => color.selected).val,
                fontStyle: '600',
                wrap: 'word',
                align: 'center',
                width: 5,
                lineHeight: 1.3
            });

            ctrl.textBox = new Konva.Rect({
                width: 15,
                height: 10 + ctrl.textNode.fontSize() * ctrl.textNode.lineHeight(),
                fill: '#000000',
                opacity: 0.6,
                cornerRadius: 7,
            });

            writingGroup.add(ctrl.textBox)
            writingGroup.add(ctrl.textNode)
            lastElementsCount++;

            ctrl.imageEditMode = 'write';

            stage.add(writingLayer);

            writingLayer.add(writingGroup);

            focusText(writingGroup);
            ctrl.textNode.on('click tap', () => focusText(writingGroup))
        }

        function focusText(writingGroup) {
            const textPosition = ctrl.textNode.absolutePosition(),
                areaPosition = {
                    x: textPosition.x,
                    y: textPosition.y,
                },
                textarea = document.createElement('div');

            let prevTextWidth;

            writingGroup.draggable(false);

            document.querySelector('#image-editing-container .konvajs-content').appendChild(textarea);

            textarea.className = 'custom';
            textarea.contentEditable = true;
            textarea.innerText = ctrl.textNode.text();
            textarea.style.minHeight = ctrl.textNode.fontSize() + 'px';
            textarea.style.minWidth = 5 + 'px';
            textarea.style.maxWidth = imageWidth + 'px';
            textarea.style.maxHeight = imageHeight + 'px';
            textarea.style.color = ctrl.textNode.fill();
            textarea.style.fontWeight = ctrl.textNode.fontStyle();
            textarea.style.top = areaPosition.y - 1 + 'px';
            textarea.style.left = areaPosition.x + 2.5 + 'px';
            textarea.style.fontSize = ctrl.textNode.fontSize() + 'px';

            prevTextWidth = textarea.offsetWidth;

            textarea.addEventListener('focus', handleFocus);
            textarea.focus();

            ctrl.textNode.hide();

            textarea.addEventListener('input', () => {
                const width = textarea.offsetWidth + 10 > imageWidth ? imageWidth - 10 : textarea.offsetWidth,
                    height = textarea.offsetHeight + 10 > imageHeight ? imageHeight - 10 : textarea.offsetHeight;

                setWidth(width);
                setHeight(height);

                const newLeft = textarea.offsetLeft - (textarea.offsetWidth - prevTextWidth) / 2;

                textarea.style.left = (newLeft > 0 ? newLeft : 0) + 'px';
                writingGroup.x(newLeft > 0 ? newLeft - 7 : 0);

                prevTextWidth = textarea.offsetWidth;

                function setHeight(height) {
                    const textLineMinHeight = ctrl.textNode.fontSize() * ctrl.textNode.lineHeight(),
                        boxMinSize = 10 + textLineMinHeight;

                    ctrl.textNode.height(height < textLineMinHeight ? textLineMinHeight : height);
                    ctrl.textBox.height(height + 10 < boxMinSize ? boxMinSize : height + 10);
                }

                function setWidth(width) {
                    ctrl.textNode.width(width < 5 ? 5 : width + 5);
                    ctrl.textBox.width(ctrl.textNode.width() + 10 < 15 ? 15 : ctrl.textNode.width() + 10);
                }
            });

            function removeTextarea() {
                if (textarea.parentNode) {
                    textarea.parentNode.removeChild(textarea);
                    window.removeEventListener('click', handleOutsideClick);
                    window.removeEventListener('touchstart', handleOutsideClick);
                    textarea.removeEventListener('focusout', handleOutsideClick);
                    textarea.removeEventListener('focus', handleOutsideClick);
                    ctrl.textNode.show();
                }
            }

            function handleOutsideClick(e) {
                if (e.type === 'focusout' || e.target !== textarea) {
                    ctrl.textNode.text(textarea.innerText);
                    removeTextarea();
                    writingGroup.draggable(true);
                }
            }

            function handleFocus() {
                document.execCommand('selectAll', false, null)
            }

            setTimeout(() => {
                textarea.addEventListener('focusout', handleOutsideClick);
                window.addEventListener('click', handleOutsideClick);
                window.addEventListener('touchstart', handleOutsideClick);
            });
        }

        function initFigures() {
            ctrl.imageEditMode = 'figures'
            figuresLayer = getFiguresLayer() || new Konva.Layer({id: 'figures'});

            figuresTransformer = figuresTransformer || new Konva.Transformer();

            stage.on('click tap', (ev) => {
                if (ev.target === image) {
                    forceFiguresNotDraggable();
                    figuresTransformer.nodes([]);
                    $scope.$apply(() => {
                        ctrl.showColorPicker = false;
                        resetColor();
                    })
                } else {
                    figuresTransformer.nodes([ev.target]);
                    ev.target.draggable(true);
                    $scope.$apply(() => {
                        ctrl.showColorPicker = true;
                    })
                }
            });
            figuresLayer.add(figuresTransformer);
            stage.add(figuresLayer);
        }

        function getFiguresLayer() {
            return stage.getLayers().find(layer => layer.attrs.id === 'figures')
        }

        function forceFiguresNotDraggable() {
            if (getFiguresLayer().children.length) {
                getFiguresLayer().children.forEach(node => {
                    node.draggable(false);
                })
            }
        }

        function addFigure(type) {
            let figure,
                options = {
                    stroke: ctrl.colors.find(color => color.selected).val,
                    strokeWidth: 5,
                    draggable: true,
                    strokeScaleEnabled: false
                },
                x = imageWidth / 2, y = imageHeight / 2;

            if (figuresTransformer.nodes().length) {
                figuresTransformer.nodes()[0].draggable(false);
            }

            switch (type) {
                case 'line':
                    Object.assign(options, {
                        points: [x, y, x + 50, y + 50]
                    })
                    figure = new Konva.Line(options);
                    break;
                case 'arrow':
                    Object.assign(options, {
                        points: [x, y, x + 50, y + 50]
                    })
                    figure = new Konva.Arrow(options);
                    break;
                case 'circle':
                    Object.assign(options, {
                        x: x,
                        y: y,
                        radius: 50,
                    })
                    figure = new Konva.Circle(options);
                    break;
                case 'square':
                    Object.assign(options, {
                        x: x - 50,
                        y: y - 50,
                        width: 100,
                        height: 100
                    })
                    figure = new Konva.Rect(options);
                    break;
            }
            figuresLayer.add(figure);
            figuresHistory.push({
                type: 'add',
                figure: figure
            });
            figure.on('dragstart', saveStep);
            figure.on('transformstart', saveStep);

            figuresTransformer.nodes([figure]);
            ctrl.showColorPicker = true;
            lastElementsCount++;

            function saveStep() {
                figuresHistory.push({
                    type: 'change',
                    figure: figure,
                    attrs: figure.getAbsoluteTransform().decompose()
                });
            }
        }

        function resetFiguresTransformer() {
            figuresTransformer.nodes([]);
        }

        function toggleTextColor() {
            if (ctrl.textBox.fill() === '#000000') {
                ctrl.textColorActive = true;
                ctrl.textBox.fill('#ffffff')
                ctrl.textNode.fill('#000000')
            } else {
                ctrl.textColorActive = false;
                ctrl.textBox.fill('#000000')
                ctrl.textNode.fill('#ffffff')
            }
        }

        function selectColor(color) {
            const selected = ctrl.colors.find(color => color.selected)
            selected.selected = false;
            color.selected = true;

            switch (ctrl.imageEditMode) {
                case 'draw':
                    break;
                case 'write':
                    ctrl.textNode.fill(color.val)
                    break;
                case 'figures':
                    const figure = figuresTransformer.nodes();
                    if (figure.length) {
                        figuresHistory.push({
                            type: 'change',
                            figure: figure[0],
                            attrs: {stroke: figure[0].stroke()}
                        });
                        figure[0].stroke(color.val);
                    }
                    break;
            }
        }

        function setStrokeWidth(width) {
            ctrl.showDrawSize = false;
            ctrl.strokeWidth = width;
        }

        function resetColor() {
            ctrl.colors.find(color => color.selected).selected = false;
            ctrl.colors[0].selected = true;
        }

        function undoDrawing() {
            if (ctrl.imageEditMode === 'draw') {
                const children = drawingLayer.getChildren()
                if (children.length) {
                    children[children.length - 1].remove()
                    lastElementsCount--;
                }
            }
            if (ctrl.imageEditMode === 'figures') {
                if (figuresHistory && figuresHistory.length) {
                    const step = figuresHistory[figuresHistory.length - 1];

                    if (step.type === 'add') {
                        step.figure.remove();
                        figuresTransformer.nodes([]);
                        lastElementsCount--;
                    }
                    if (step.type === 'change') {
                        step.figure.setAttrs(step.attrs)
                    }
                    figuresHistory.pop();
                }
            }
        }
    }
})();
