(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('rlPhoneInput', {
            template: require('./RelesysPhoneInputComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', RlPhoneInputController],
            require: {
                ngModel: 'ngModel'
            },
            bindings: {
                customId: '@',
                placeholder: '@',
                isRequired: '<',
                highlight: '<',
                ngDisabled: '=',
                phoneCodes: '<'
            }
        });

    function RlPhoneInputController($scope) {
        const ctrl = this;

        $scope.$watch('ctrl.modelNumber', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                const currentModel = ctrl.ngModel.$viewValue;
                ctrl.ngModel.$setViewValue({
                    number: newValue,
                    country: currentModel.country
                });
            }
        });

        $scope.$watch('ctrl.modelCountry', (newValue, oldValue) => {
            if(newValue.hasOwnProperty('id')) {
                newValue = newValue.id;
            }

            if(newValue !== oldValue) {
                const currentModel = ctrl.ngModel.$viewValue;
                ctrl.ngModel.$setViewValue({
                    number: currentModel.number,
                    country: newValue
                });
            }
        });

        ctrl.$onInit = init;

        function init() {
            ctrl.isConstructed = false;
            ctrl.phoneCodeOptions = [];
            
            if (!ctrl.phoneCodes || !ctrl.phoneCodes.length) {
                console.error('Required attribute [phone-codes] was not supplied for <rl-phone-input>');
                return;
            }
            
            ctrl.ngModel.$isEmpty = (val) => {
                return val === undefined || !val?.number?.length
            };

            ctrl.ngModel.$render = () => {
                const currentModel = ctrl.ngModel.$viewValue;
                const currentCountry = parseInt(currentModel.country);
                
                ctrl.modelNumber = currentModel.number;

                ctrl.phoneCodes.forEach(o => {
                    const id = parseInt(o.id);
                    ctrl.phoneCodeOptions.push({
                        id: id,
                        name: '+' + o.code,
                        fullName: o.name + ' +' +  o.code,
                        svgImage: '/content/graphics/countryFlags/' + o.countryCode + '.svg',
                        selected: id === currentCountry
                    });
                });
    
                
                var selectedOption = ctrl.phoneCodeOptions.find(o => o.selected);
                if (!selectedOption) {
                    selectedOption = ctrl.phoneCodeOptions[0];
                    selectedOption.selected = true;
                }
                
                ctrl.modelCountry = selectedOption;
                ctrl.isConstructed = true;
            };

            return;
        }
    }
})();