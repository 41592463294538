(() => {
    'use strict';

    angular
        .module('App')
        .component('contentCategorySelectorPopup', {
            template: require('./CategorySelectorPopup.html'),
            controller: ['$rootScope', MultiplePermissionSelectorPopupController],
            controllerAs: 'ctrl',
            bindings: {
                categories: '<',
                selectCategory: '=',
            }
        });


    function MultiplePermissionSelectorPopupController($rootScope) {
        let ctrl = this, popup;

        ctrl.isRTL = $rootScope.isRTL;
        
        ctrl.closePopup = closePopup;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.select = onSelect;
        
        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }

        function onSelect(selectedCategory) {
            ctrl.selectCategory(selectedCategory);
            closePopup();
        }
        
        function closePopup() {
            popup.remove();
        }
    }
})();


