(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('AlertController', ['$http', '$q', '$state', '$rootScope', '$location', 'cfpLoadingBar', '$timeout', '$compile', 'ModalService',
        function ($http, $q, $state, $rootScope, $location, cfpLoadingBar, $timeout, $compile, ModalService) {
            var f = {
                create: function(options, callback){

                },
                showMessagePopup: function (options, callback) {
                    var newScope = $rootScope.$new(true);
                    var defaultOptions = {
                        headline: '',
                        text: '',
                        icon: '',
                        cssClass: ''
                    }
                    $.extend(defaultOptions, options);

                    newScope.PopupContent = {
                        headline: defaultOptions.headline,
                        text: defaultOptions.text,
                        icon: defaultOptions.icon,
                        cssClass: defaultOptions.cssClass
                    };

                    newScope.PopupClosed = function () {
                        if (callback !== undefined) {
                            callback();
                        }
                    };
                    
                    var $popup = $compile("<popup></popup>")(newScope);
                    $('body').append($popup);
                }
            };
            return f;
        }
    ]);
})();