(() => {
    'use strict';

    angular
        .module('App')
        .component('updateEvent', {
            template: require('./CreateUpdateEventComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$rootScope', '$filter', '$scope', 'PopupWrapperService', 'CreateUpdateEventService',
                'CalendarRenderService', 'ToastFactory', 'LanguageSelectorService', 'TranslationService', '$timeout',
                'Page', 'Profile', 'ActionSheetCustomService', CreateEventController],
            bindings: {
                calendars: '<',
                timezones: '<',
                timezoneEnabled: '<',
                signupLimitEnabled: '<',
                accountModuleToken: '<',
            }
        });

    function CreateEventController($element, $rootScope, $filter, $scope, PopupWrapperService, CreateUpdateEventService,
                                   CalendarRenderService, ToastFactory, LanguageSelectorService, TranslationService,
                                   $timeout, Page, Profile, ActionSheetCustomService) {
        const ctrl = this;
        let popup, oldEvent, isEventSaving, initializing, allDayWatcher, translationWatcher, eventChangeWatcher, loadingWatcher;

        ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
        ctrl.uploadParams = {
            AccountToken: Profile.getProfile().AccountToken,
            UserToken: Profile.getProfile().UserToken
        }

        ctrl.popupId = 'EditEventPopup';
        ctrl.translatedText = [];
        ctrl.warningMessage = '';
        ctrl.eventTypes = {
            Event: 1,
            ToDo: 2
        };
        ctrl.matchAllGroupsValue = false;

        ctrl.$onInit = init;
        ctrl.openUpdateEvent = openUpdateEvent;
        ctrl.close = close;
        ctrl.save = save;
        ctrl.onOpen = onOpen;
        ctrl.deleteImage = deleteImage;
        ctrl.getTranslation = getTranslation;
        ctrl.isTranslateDisabled = isTranslateDisabled;
        ctrl.selectLanguage = LanguageSelectorService.selectLanguage;
        ctrl.validateLanguages = validateLanguages;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;
        ctrl.setEventType = setEventType;
        ctrl.initEventChangeWatcher = initEventChangeWatcher;
        ctrl.languageLoaded = languageLoaded;
        ctrl.permissionLoaded = permissionLoaded;
        ctrl.openInfoPopup = openInfoPopup;

        function init() {
            ctrl.isEdit = true;
            CreateUpdateEventService.setEditEventComponentController(ctrl);
        }

        function languageLoaded() {
            ctrl.isLanguageLoaded = true;
        }

        function permissionLoaded() {
            ctrl.isPermissionLoaded = true;
        }

        function openUpdateEvent(event) {
            initializing = true;
            ctrl.event = prepareDataForRender(event);

            ctrl.permissionOptions = {
                hideUserGroups: false,
                hideDepartments: false,
                departmentFilterType: null,
                requireDepartment: false,
                matchAllGroups: false,
                userGroupsLimited: true // always true on the calendar module
            };

            CreateUpdateEventService.getPermissionsSettings(ctrl.event.CalendarId).then(resp => {
                ctrl.permissionOptions.departmentFilterType = resp.DepartmentFilterType;
                ctrl.permissionOptions.requireDepartment = resp.RequireDepartmentFilter;
                ctrl.permissionOptions.matchAllGroups = resp.MatchAllGroups;
                ctrl.permissionOptions.requirePermissionId = resp.PermissionSettingType,
                    ctrl.departmentFilterType = resp.DepartmentFilterType;
                ctrl.hideDepartments = !resp.RequireDepartmentFilter;

                if (!ctrl.AreMultiplePermissionsSet && !ctrl.isEdit) {
                    ctrl.matchAllGroupsValue = resp.MatchAllGroups;
                }
            });
            CreateUpdateEventService.getEditEventData(event.CalendarEventToken).then(resp => {
                ctrl.settings = resp.Settings;
                ctrl.event.selectedLanguages = resp.Translations;
                ctrl.languages = resp.AvailableCultures;
                ctrl.RemindersEnabled = resp.RemindersEnabled;
                ctrl.cultures = LanguageSelectorService.updateLanguagesInfo(resp.Cultures);
                ctrl.AllowAutomaticTranslation = resp.Settings.AllowAutomaticTranslation;
                ctrl.Permissions = resp.Permissions;
                ctrl.preferTranslate = resp.Settings.AllowAutomaticTranslation && resp.Settings.PreferTranslateEvent;
                ctrl.translatedText = _.cloneDeep(ctrl.event.selectedLanguages);

                // Permissions
                ctrl.event.Permissions = JSON.parse(resp.Permissions);
                ctrl.event.AreMultiplePermissionsSet = resp.Settings.MultiplePermissionSetsEnabled;
                if (!ctrl.AreMultiplePermissionsSet && !ctrl.isEdit) {
                    ctrl.event.Permissions.MatchAllGroups = ctrl.matchAllGroupsValue;
                }
                ctrl.showPermissions = true;

                !ctrl.languages && languageLoaded();

                $timeout(() => {
                    ctrl.AllowAutomaticTranslation && initTranslationWatcher()
                })
            })

            ctrl.IndividualInvitationsEnabled = ctrl.event.calendar.IndividualInvitationsEnabled;

            oldEvent = _.clone(ctrl.event);
            popup = PopupWrapperService.getPopup(ctrl.popupId);
            popup.open();
        }

        function prepareDataForRender(eventData) {
            var event = _.cloneDeep(eventData),
                start = moment(event.StartTime),
                startOfDay = moment(event.StartTime).startOf('day'),
                end = moment(event.EndTime),
                endOfDay = moment(event.EndTime).endOf('day');

            if (start.isSame(startOfDay, 'seconds') && end.isSame(endOfDay, 'seconds')) {
                event.allDay = true;
                event.AllDayStart = new Date(start.format("MMM DD, YYYY"));
                event.AllDayEnd = new Date(end.format("MMM DD, YYYY"));
            } else {
                event.Start = start.toDate();
                event.End = end.toDate();
            }

            event.registrationStart = moment(event.AllowRegisterStartDate).toDate();
            event.registrationEnd = moment(event.AllowRegisterEndDate).toDate();

            allDayWatcher && allDayWatcher();
            allDayWatcher = $scope.$watch('ctrl.event.allDay', function (allDay) {
                if (initializing) {
                    initializing = false;
                    return false;
                }
                if (allDay) {
                    event.AllDayStart = new Date(moment(event.Start).startOf('day'));
                    event.AllDayEnd = new Date(moment(event.End).endOf('day'));
                } else {
                    event.Start = new Date(moment(event.Start)
                        .day(moment(event.AllDayStart).day())
                        .date(moment(event.AllDayStart).date())
                        .year(moment(event.AllDayStart).year()).seconds(0).millisecond(0));

                    event.End = new Date(moment(event.End)
                        .day(moment(event.AllDayEnd).day())
                        .date(moment(event.AllDayEnd).date())
                        .year(moment(event.AllDayEnd).year()).seconds(0).millisecond(0));

                    ctrl.event.registrationStart = new Date(moment(ctrl.event.registrationStart)
                        .day(moment(ctrl.event.AllowRegisterStartDate).day())
                        .date(moment(ctrl.event.AllowRegisterStartDate).date())
                        .year(moment(ctrl.event.AllowRegisterStartDate).year()).seconds(0).millisecond(0));

                    ctrl.event.registrationEnd = new Date(moment(ctrl.event.registrationEnd)
                        .day(moment(ctrl.event.AllowRegisterEndDate).day())
                        .date(moment(ctrl.event.AllowRegisterEndDate).date())
                        .year(moment(ctrl.event.AllowRegisterEndDate).year()).seconds(0).millisecond(0));
                }
            });

            event.calendar = _.find(ctrl.calendars, {'CalendarId': event.CalendarId});
            event.image = event.BackgroundMediaId ? [event.BackgroundMediaId] : null;
            event.selectedLanguages = [];
            event.ReminderPeriodTypeId = eventData.ReminderPeriodTypeId.toString();

            return event;
        }

        function close() {
            translationWatcher && translationWatcher();
            ctrl.showPermissions = false;
            ctrl.languages = undefined;
            ctrl.warningMessage = '';
            ctrl.isChanged = false;
            popup && popup.hide();
            eventChangeWatcher && eventChangeWatcher();
            loadingWatcher && loadingWatcher();
            ctrl.isPermissionLoaded = false;
            ctrl.isLanguageLoaded = false;
        }

        function initEventChangeWatcher() {
            let initializing = true;

            eventChangeWatcher = $scope.$watch(() => ctrl.event,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function save(event) {
            if (!ctrl.eventForm.$valid || !ctrl.validateLanguages()) {
                ctrl.showValidation = true;

                $timeout(function () {
                    $element.find('multiple-permission-selector-wrapper.ng-invalid').length &&
                    $element.find('.scroll-container').scrollTop($element.find('multiple-permission-selector-wrapper.ng-invalid')[0].offsetTop);
                });

                return false;
            }

            const eventModel = CreateUpdateEventService.prepareDataForServer(event, ctrl.RemindersEnabled);
            eventModel.CalendarEventId = event.CalendarEventId;

            if (!isEventSaving) {
                isEventSaving = true;
                CalendarRenderService.editEvent(eventModel, oldEvent).then(function () {
                    isEventSaving = false;
                    ToastFactory.successTranslated('CALENDAR.EVENT.EDITED');
                    $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
                    close();
                }).catch(function (error) {
                    isEventSaving = false;
                    ctrl.warningMessage = error.Message;
                });
            }
        }

        function onOpen() {
            ctrl.categories = CalendarRenderService.getCategories();
            const selectedCategory = ctrl.categories
                .find(cat => cat.CalendarCategoryId === ctrl.event.CalendarCategoryId);
            if (selectedCategory) {
                selectedCategory.isSelected = true
            }

            loadingWatcher = $scope.$watchGroup(['ctrl.isPermissionLoaded', 'ctrl.isLanguageLoaded'], () => {
                if (ctrl.isLanguageLoaded && ctrl.isPermissionLoaded) {
                    initEventChangeWatcher();
                }
            });
        }

        function deleteImage() {
            ctrl.event.image = null;
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.event.selectedLanguages[ctrl.currentIndex]?.CultureId;
                const languages = ctrl.event.selectedLanguages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function translate() {
            ctrl.isTranslating = true;

            TranslationService.translateEvent(ctrl.accountModuleToken, ctrl.event.selectedLanguages, ctrl.currentIndex).then(translations => {
                ctrl.event.selectedLanguages = ctrl.event.selectedLanguages.map(language => ({
                    ...language,
                    AutoTranslated: false
                }));

                translations.translations && translations.translations.forEach(translation => {
                    const locale = ctrl.event.selectedLanguages.find(culture => culture.CultureId === translation.CultureId);

                    locale.Title = translation.Title;
                    locale.Summary = translation.Summary;
                    locale.AutoTranslated = true;
                })

                ctrl.translatedText = _.cloneDeep(ctrl.event.selectedLanguages);
            }).finally(() => {
                ctrl.isTranslating = false;
                ctrl.translateDisabled = true;
                ctrl.event.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
            })
        }

        function isTranslateDisabled() {
            return ctrl.isTranslating || ctrl.translateDisabled
        }

        function initTranslationWatcher() {
            translationWatcher = $scope.$watch(() => {
                    return ctrl.event.selectedLanguages[ctrl.currentIndex];
                },
                (newVal) => {
                    const currentItem = ctrl.translatedText.find((item) => item.CultureId === newVal?.CultureId);
                    ctrl.translateDisabled = !newVal ||
                        ((newVal?.Title ?? '').trim() === (currentItem?.Title ?? '').trim() &&
                            (newVal?.Summary ?? '').trim() === (currentItem?.Summary ?? '').trim()) ||
                        ctrl.event.selectedLanguages.length < 2;

                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.event.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
                    }
                }, true
            )
        }

        function isShowTranslationChangeWarning() {
            return ctrl.event.selectedLanguages
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.Title?.trim() !== item?.Title?.trim() ||
                        lang.Summary?.trim() !== item?.Summary?.trim();
                })
        }

        function validateLanguages() {
            if (!ctrl.event.selectedLanguages.length) {
                return false;
            }

            if (Page.getSettings().CulturesRework) {
                return ctrl.event.selectedLanguages.some(x => x.Title);
            }

            return ctrl.event.selectedLanguages[0].Title;
        }

        function openInfoPopup() {
            let excludedSections = ['whoIsAssignee','howToSeeAssignees', 'whoIsResponsible', 'howToSeeResponsible'];

            const template =
                `<assignees-info-popup
                    excluded-sections="excludedSections"></assignees-info-popup>`;

            ActionSheetCustomService.create(template, {excludedSections});
        }

        function setEventType(eventType) {
            ctrl.event.CalendarEventTypeId = eventType;
        }
    }
})();