(function () {
    'use strict';

    angular.module('App')
        .component('powerBi', {
            templateUrl: '/Scripts/Components/PowerBi/PowerBiComponent.tpl.html',
            controllerAs: 'ctrl',
            controller: ['$http', '$state', '$stateParams', 'HeaderButtonsFactory', 'ResponsiveService', '$scope', powerBiController],
            bindings: {
                data: '<'
            }
        });
    
    function powerBiController($http, $state, $stateParams, HeaderButtonsFactory, ResponsiveService, $scope) {
        var ctrl = this;
        var selectedPage = null;
        var tabset = null;
        ctrl.pages = [];
        ctrl.isDesktop = ResponsiveService.isDesktop();
        
        
        // Get models. models contains enums that can be used.
        var models = window['powerbi-client'].models;

        // Check for landscape vs portrait
        var layoutType = models.LayoutType.MobilePortrait;
        if (window.innerWidth >= 1024) {
            layoutType = models.LayoutType.MobileLandscape;
        }

        ctrl.options = {
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.Read,
            pageName: ctrl.data.PageId,
            settings: {
                layoutType: layoutType,
                filterPaneEnabled: ctrl.data.FilterPane,
                //navContentPaneEnabled: ctrl.data.PageNavigation,
                navContentPaneEnabled: false,
                localeSettings: {
                    language: ctrl.data.Language,
                    formatLocale: ctrl.data.FormatLocale
                }
            }
        };

        ctrl.onEmbedded = function ($embed) {
            // Only handle this if page navigation is enabled
            if (ctrl.data.PageNavigation) {
                $embed.on('loaded', function () {
                    $embed.getPages().then(function (pages) {
                        ctrl.pages = pages;
                        $scope.$apply();
                    });
                });

                $embed.on('pageChanged', function (event) {
                    if (selectedPage === null || selectedPage.name !== event.detail.newPage.name) {
                        // Get the index of the page which was selected
                        var index = _.findIndex(ctrl.pages, function (o) {
                            return o.isActive === true;
                        });
                        // set the tab as active if possible
                        if (index >= 0) {
                            tabset.setActive(index);
                        }
                    }
                });
            }

            HeaderButtonsFactory.createButtonsList(ctrl.getHeaderButtons);
        };

        ctrl.changePage = function (page) {
            if (selectedPage === null) {
                selectedPage = page;
            } else {
                selectedPage = page;
                page.setActive();
            }
        };

        ctrl.onTabInit = function (tabs) {
            tabset = tabs;
            if (_.isString(ctrl.data.PageId) && ctrl.data.PageId.length) {
                // Get the index of the page which was selected
                var index = _.findIndex(ctrl.pages, function (o) {
                    return o.isActive === true;
                });
                // select the tab if possible
                if (index >= 0) {
                    tabset.select(index);
                }
            }
        };

        ctrl.printReport = function () {
            var element = $('.powerbi-frame')[0];
            var report = powerbi.get(element);
            report.print();
        };

        ctrl.getHeaderButtons = function () {
            // only add if in mobile-view. Desktop-view will add a button in the markup.
            if (!ctrl.isDesktop) {
                return [
                    {
                        icon: 'print',
                        onClick: function () {
                            ctrl.printReport();
                        },
                        place: 'right'
                    }
                ];
            }
        }
        
    }
})();