(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('ShowMoreLessFactory', [
        function () {

            return {
                getStringByPosition: getStringByPosition
            };

            function getStringByPosition(str, pos) {
                var leftSideString = str.substr(0, pos),
                    leftMatch = leftSideString.match(/[^.,\s]*$/);

                return str.substr(0, leftMatch.index) || str
            }
        }
    ]);
})();