(function () {
    'use strict';

    angular
        .module('App')
        .factory('ReportPopupService', ['$http', '$filter', 'ConfirmPopupService', 'ActionSheetCustomService', 'Page',
            function ($http, $filter, ConfirmPopupService, ActionSheetCustomService, Page) {

                return {
                    openReportPopup: openReportPopup,
                    onReportSubmit: onReportSubmit,
                    reportContentPost: reportContentPost,
                    reportContentComment: reportContentComment,
                    reportSocialPost: reportSocialPost,
                    reportSocialComment: reportSocialComment,
                    reportChatMessage: reportChatMessage,
                    reportProfile: reportProfile,
                    reportEvent: reportEvent,
                    reportFilling: reportFilling,
                    reportFillingComment: reportFillingComment,
                    reportStageComment: reportStageComment,
                    reportFeedbackComment: reportFeedbackComment,
                    reportTask: reportTask,
                    reportTaskComment: reportTaskComment,
                };

                function openReportPopup(onCloseReport, onReportSubmit, itemId, moduleType) {
                    ActionSheetCustomService.create(
                        `<report-popup 
                                on-close-report="onCloseReport"
                                on-report-submit="onReportSubmit"
                                item-id="itemId"
                                module-type="moduleType"
                             ></report-popup>`,
                        {
                            onCloseReport,
                            onReportSubmit,
                            itemId,
                            moduleType
                        });
                }

                function reportContentPost(report) {
                    return $http.post('/Violation/ReportContentPost', report);
                }

                function reportSocialPost(report) {
                    return $http.post('/Violation/ReportSocialPost', report);
                }

                function reportContentComment(report) {
                    return $http.post('/Violation/ReportContentComment', report);
                }

                function reportSocialComment(report) {
                    return $http.post('/Violation/ReportSocialComment', report);
                }

                function reportProfile(report) {
                    return $http.post('/Violation/ReportProfile', report);
                }

                function reportEvent(report) {
                    return $http.post('/Violation/ReportEvent', report);
                }

                function reportFilling(report) {
                    return $http.post('/Violation/ReportFilling', report);
                }

                function reportFillingComment(report) {
                    return $http.post('/Violation/ReportFillingComment', report);
                }

                function reportStageComment(report) {
                    return $http.post('/Violation/ReportStageComment', report);
                }

                function reportFeedbackComment(report) {
                    return $http.post('/Violation/ReportFeedbackComment', report);
                }

                function reportTask(report) {
                    return $http.post('/Violation/ReportTask', report);
                }

                function reportTaskComment(report) {
                    return $http.post('/Violation/ReportTaskComment', report);
                }

                function reportChatMessage(report) {
                    return $http.post('/Violation/ReportMessage', report);
                }

                function onReportSubmit(submitted) {
                    if (typeof submitted === 'boolean' && submitted) {
                        Page.setMainContainerInert(true);
                        ConfirmPopupService.open({
                            title: $filter('translate')('REPORT.CONFIRMATION_TITLE'),
                            message: $filter('translate')('REPORT.CONFIRMATION_TEXT'),
                            yesText: $filter('translate')('DISMISS'),
                            isAlert: true,
                            onClose: () => Page.setMainContainerInert(false)
                        }).then(() => Page.setMainContainerInert(false));
                    }
                }
            }]);
})();