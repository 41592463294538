(() => {
    'use strict';
    angular
        .module('App.Filters', [])
        .run(() => {
        });
})();

require([
    './Filters/ConvertToPersents',
    './Filters/Cut',
    './Filters/DateTime',
    './Filters/Find',
    './Filters/HtmlEscape',
    './Filters/HtmlFormatText',
    './Filters/IsMedia',
    './Filters/NewLine',
    './Filters/NewLineEscapeHtml',
    './Filters/NewLineWords',
    './Filters/NumberFormat',
    './Filters/Placeholder',
    './Filters/Range',
    './Filters/SecureHtml',
    './Filters/WrapVideo',
])