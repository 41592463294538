(() => {
    'use strict';

    angular.module('App')
        .component('messageItem', {
            template: require('./MessageItem.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$filter', '$scope', 'MESSAGE_STATUS', 'ReactionsService', 'ActionSheetService', 'ChatPopupsService',
                'ChatDataService', 'ConfirmPopupService', 'ConversationService', 'MentionService', 'ToastFactory', 'ReportPopupService', 'Page',
                'ResponsiveService', MessageItemController],
            bindings: {
                isFirstInRow: '<',
                isDifferentDays: '<',
                currentUserToken: '<',
                message: '<',
                channel: '<',
                onReply: '<',
                onEdit: '<',
                goToReplyingMessage: '<',
                onOptionsOpen: '<',
                getMember: '<',
                messageReads: '<',
                isOptionsVisible: '=?',
                resetPosition: '<',
                showMessageStatus: '<',
                onResend: '<'
            }
        })

    function MessageItemController($element, $filter, $scope, MESSAGE_STATUS, ReactionsService, ActionSheetService, ChatPopupsService,
                                   ChatDataService, ConfirmPopupService, ConversationService, MentionService, ToastFactory, ReportPopupService, Page,
                                   ResponsiveService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.hideOptions = hideOptions;
        ctrl.openReactionsView = openReactionsView;
        ctrl.showMessageReads = showMessageReads;
        ctrl.hideMessageAuthorForUser = hideMessageAuthorForUser;
        ctrl.getTime = getTime;
        ctrl.sendReaction = sendReaction;
        ctrl.isUserHiddenFromMessageViews = isUserHiddenFromMessageViews;
        ctrl.getBodyText = getBodyText;
        ctrl.isEdited = isEdited;
        ctrl.showTimeOrResendMessage = showTimeOrResendMessage;
        ctrl.editMessage = editMessage;
        ctrl.replyMessage = replyMessage;

        ctrl.visibleViewersLength = 3;
        ctrl.MESSAGE_STATUS = MESSAGE_STATUS;
        ctrl.isDesktop = ResponsiveService.isDesktop();

        $scope.$watch('ctrl.channel.item.lastMessageId', handleStatusChange);
        $scope.$watch('ctrl.message.status', handleStatusChange);
        $scope.$watch('ctrl.message.hideStatus', handleStatusHiding);
        $scope.$watch('ctrl.messageReads', handleStatusHiding);

        function init() {
            if (ctrl.channel.reactionsEnabled) {
                ctrl.reactions = ReactionsService.getReactions();
            }
            ctrl.optionButtons = getOptionButtons();
            ctrl.status = getMessageStatus();
            ctrl.isSent = isMessageSent();
            ctrl.isAway = isAway();
            ctrl.statusVisible = ctrl.showMessageStatus(ctrl.message);
            ctrl.authorName = ctrl.getMember(ctrl.message.item.authorId).name;
            if (ctrl.messageReads) {
                ctrl.messageReads = filterOutCurrentUserAndAuthor(ctrl.messageReads);
            }
        }
        
        function isAway() {
            return ctrl.channel.members.find(user => user.userId === ctrl.message.item.authorId)?.away;
        }
        
        function handleStatusChange() {
            if (ctrl.status) {
                ctrl.status = getMessageStatus();
                ctrl.isSent = isMessageSent();
                handleStatusHiding();
            }
        }

        function handleStatusHiding() {
            ctrl.statusVisible = ctrl.showMessageStatus(ctrl.message);
        }

        function filterOutCurrentUserAndAuthor(messageReads) {
            return messageReads.filter(id => {
                return (id !== ctrl.currentUserToken) && (id !== ctrl.message.item.authorId)
            });
        }

        function getOptionButtons() {
            const buttons = [];

            ctrl.message.item.text && buttons.push({
                text: 'CHAT.COPY_MESSAGE',
                icon: 'copy',
                onClick: (_, ev) => {
                    ev.stopPropagation();
                    ConversationService.copyMessageText($element.find('.message-body'))
                    hideOptions();
                }
            });

            if (ctrl.message.item.authorId === ctrl.currentUserToken) {
                buttons.push({
                    text: 'CHAT.EDIT_MESSAGE',
                    icon: 'edit',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        ctrl.onEdit(ctrl.message)
                        hideOptions();
                    }
                });

                buttons.push({
                    text: 'CHAT.DELETE_MESSAGE',
                    icon: 'delete',
                    iconClass: 'red',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        deleteMessage();
                        hideOptions();
                    }
                });
            } else {
                buttons.push({
                    text: 'CHAT.REPLY',
                    icon: 'reply',
                    onClick: (_, ev) => {
                        ev.stopPropagation();
                        ctrl.onReply(ctrl.message)
                        hideOptions();
                    }
                });

                if (Page.getSettings()?.ContentReportingEnabled) {
                    buttons.push({
                        text: 'REPORT.TITLE',
                        icon: 'alert',
                        onClick: (_, ev) => {
                            ev.stopPropagation();
                            reportMessage(ctrl.message)
                            hideOptions();
                        }
                    });
                }
            }
            
            return buttons;
        }

        function editMessage() {
            ctrl.onEdit(ctrl.message);
            hideOptions();
        }

        function replyMessage() {
            ctrl.onReply(ctrl.message);
            hideOptions();
        }

        function isEdited() {
            return ( ctrl.message.status === MESSAGE_STATUS.EDITED || ctrl.message.item.dateModified ) && !ctrl.message.item.dateDeleted;
        }

        function onReportSubmit(model, itemId) {
            return ReportPopupService.reportChatMessage({
                messageId: itemId,
                model,
            })
        }

        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportMessage(message) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, message.item.messageId, 'messageItem');
        }

        function getBodyText(message) {
            return message && MentionService.parseMentionsInText(message, usersForMention());
        }

        function usersForMention() {
            return ctrl.channel.members?.filter(member => !(ctrl.channel.blockedUsers ?? []).includes(member.userId));
        }

        function showTime(ev) {
            ev && ev.stopPropagation();
            if (!ctrl.isOptionsVisible) {
                ctrl.timeVisible = !ctrl.timeVisible;
            }
        }

        function showTimeOrResendMessage(ev) {
            if (ctrl.message.status === MESSAGE_STATUS.FAILED) {
                ctrl.onResend(ctrl.message.item.clientMessageId);
            } else if (ctrl.message.failedReactionId) {
                sendReaction(ev, ctrl.message.failedReactionId);
            } else {
                !ctrl.isDesktop && showTime(ev);
            }
        }

        function hideOptions(ev) {
            ev && ev.stopPropagation();
            ctrl.isOptionsVisible = false;
            ctrl.timeVisible = false;
            ctrl.isMenuActive = false;
            $element.find('.hover')[0]?.classList.remove('hover');
            ctrl.resetPosition();
        }

        function openReactionsView(ev) {
            ev.stopPropagation();
            ReactionsService.openReactionsViewer(ctrl.message.item.messageId, true, 'Chat', false, () => {
                ConversationService.removeReaction(ctrl.message.item.messageId);
            });
        }

        function sendReaction(ev, reactionId) {
            ev.stopPropagation();
            ctrl.message.failedReactionId = null;
            ConversationService.sendReaction(ctrl.message.item.messageId, reactionId)
                .catch(() => {
                    ctrl.message.failedReactionId = reactionId;
                });
            updateReactionView(reactionId);
            hideOptions();
        }

        function updateReactionView(reactionId) {            
            const previousUserReactionId = ctrl.message.item.requestingUserReactionTypeId;

            if (userClickedSameReaction(reactionId, previousUserReactionId)) return;

            const userIsEditingReaction = !!previousUserReactionId;
            updateRequestingUserReactionTypeId(reactionId)

            if (userIsEditingReaction) {
                removePreviousUserReaction(previousUserReactionId)
            }

            const hasMessageNewReaction = ctrl.message.item.reactionsSummary?.some((reaction) => reaction.ReactionTypeId === reactionId);

            if (hasMessageNewReaction) {
                increaseReactionsCount(reactionId);
            } else {
                addReactionToSummary(reactionId);
            }

            function userClickedSameReaction(newReactionId, previousReactionId) {
                return newReactionId === previousReactionId;
            }
    
            function removePreviousUserReaction(previousUserReactionId) {
                const previousReaction = ctrl.message.item.reactionsSummary?.find(reaction => previousUserReactionId === reaction.ReactionTypeId);
                previousReaction && previousReaction.Count--;
                if (previousReaction?.Count < 1) {
                    ctrl.message.item.reactionsSummary = ctrl.message.item.reactionsSummary.filter(reaction => previousUserReactionId !== reaction.ReactionTypeId);
                }
            }
    
            function updateRequestingUserReactionTypeId(reactionId) {
                ctrl.message.item.requestingUserReactionTypeId = reactionId;
            }
    
            function increaseReactionsCount(reactionId) {
                const newReaction = ctrl.message.item.reactionsSummary.find(reaction => reactionId === reaction.ReactionTypeId);
                if (newReaction && !newReaction.inProgress) {
                    newReaction.Count++;
                    newReaction.inProgress = true;
                }
            }
    
            function addReactionToSummary(reactionId) {
                ctrl.message.item.reactionsSummary = 
                [ 
                    ...(ctrl.message.item.reactionsSummary || []),
                    {
                        Count: 1,
                        ReactionTypeId: reactionId,
                        inProgress: true
                    }
                ];
            }
        }

        function deleteMessage() {
            ConfirmPopupService.open({title: 'CHAT.DELETE_MESSAGE_CONFIRMATION'}).then(() => {
                ConversationService
                    .deleteMessage(ctrl.message.item.messageId)
                    .catch(() => {
                        ToastFactory.errorTranslated();
                    })
            })
        }

        function showMessageReads(channelId, messageId) {
            ChatPopupsService.openMessageViewersPopup(channelId, messageId, ctrl.currentUserToken, ctrl.channel.blockedUsers);
        }

        function hideMessageAuthorForUser(userToken) {
            if (ctrl.channel.item.appLinkId && ctrl.channel.item.creatorUserId === ctrl.currentUserToken) {
                return ctrl.channel.item.hideAuthor && userToken !== ctrl.currentUserToken;
            }
            return false
        }

        function getTime(timestamp) {
            const now = moment();
            const targetDate = moment(timestamp);

            switch (true) {
                case now.isSame(targetDate, 'day'):
                    return targetDate.format('HH:mm');

                case now.clone().subtract(1, 'day').isSame(targetDate, 'day'):
                    return $filter('translate')('YESTERDAY') + ' ' + targetDate.format('HH:mm');

                case now.diff(targetDate, 'days') <= 7:
                    return targetDate.format('dddd HH:mm');

                case now.diff(targetDate, 'months') > 1:
                    return targetDate.format('DD MMM HH:mm');

                case now.diff(targetDate, 'years') > 1:
                    return targetDate.format('DD MMM YYYY HH:mm');
            }

            return targetDate.format('DD MMM YYYY HH:mm');
        }

        function isUserHiddenFromMessageViews(userId) {
            return !(
                ctrl.message.isBlocked 
                || ctrl.channel.isBlocked 
                || ctrl.channel.blockedUsers?.includes(userId) 
                || userId === ctrl.message.item.authorId
                || userId === ctrl.currentUserToken
            );
        }

        function isMessageSent() {
            switch (ctrl.message.status) {
                case MESSAGE_STATUS.SENDING:
                case MESSAGE_STATUS.FAILED:
                case MESSAGE_STATUS.POSTPONED:
                    return false;
                case MESSAGE_STATUS.DELIVERED:
                case undefined:
                case MESSAGE_STATUS.EDITED:
                    return true;
                default:
                    return true;
            }
        }

        function getMessageStatus() {
            switch (ctrl.message.status) {
                case MESSAGE_STATUS.SENDING:
                    return 'CHAT.STATUS.SENDING';
                case MESSAGE_STATUS.FAILED:
                    return 'CHAT.STATUS.FAILED';
                case MESSAGE_STATUS.POSTPONED:
                    return 'CHAT.STATUS.POSTPONED';
                case MESSAGE_STATUS.DELIVERED:
                case undefined:
                case MESSAGE_STATUS.EDITED:
                    return 'CHAT.STATUS.DELIVERED';
                default:
                    return null;
            }
        }
    }
})();
