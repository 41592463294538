(() => {
    'use strict';

    angular
        .module('App')
        .component('managerView', {
            template: require('./ManagerViewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', 'TaskManagementRestService', 'TaskManagementRenderService', 'ISSUE_STATUS',
                'PopupWrapperService', 'ResponsiveService', ManagerViewComponentController],
            bindings: {
                selectedDepartment: '<',
                startTime: '<',
                endTime: '<',
                categories: '<',
                filterModel: '=',
                managerViewLoading: '=?',
                openFilter: '&'
            }
        });

    function ManagerViewComponentController($rootScope, TaskManagementRestService, TaskManagementRenderService,
                                            ISSUE_STATUS, PopupWrapperService, ResponsiveService) {
        var ctrl = this;
        let taskFilterWatcher;

        ctrl.startTime = ctrl.startTime ? ctrl.startTime : new Date(moment().startOf('week'));
        ctrl.endTime = ctrl.endTime ? ctrl.endTime : new Date(moment().endOf('week'));
        ctrl.fetching = false;
        ctrl.managerViewLoading = true;
        ctrl.issues = [];
        ctrl.activeStatusFilter = null;
        ctrl.overdueCount = 0;
        ctrl.completedCount = 0;
        ctrl.ISSUE_STATUS = ISSUE_STATUS;
        ctrl.managerFilters = [];
        ctrl.isDesktop = ResponsiveService.isDesktop();

        ctrl.$onInit = init;
        ctrl.$onDestroy = onDestroy;
        ctrl.onChangeDateFilter = onChangeDateFilter;
        ctrl.onChangeStatusFilter = onChangeStatusFilter;
        ctrl.OpenFilter = ctrl.openFilter;
        ctrl.openDepartment = openDepartment;

        taskFilterWatcher = $rootScope.$on('TaskManagement:tasksFiltered', function (ev, response, model, filtersCount, isReset) {
            ctrl.issues = [];

            if (isReset) {
                ctrl.managerFilters.StartTime = ctrl.startTime;
                ctrl.managerFilters.EndTime = ctrl.endTime;
                ctrl.managerFilters.CategoryIds = [];
                ctrl.hasFilter = false;
            } else {
                ctrl.managerFilters.StartTime = model.StartTime;
                ctrl.managerFilters.EndTime = moment(model.EndTime).endOf('day')
                ctrl.managerFilters.CategoryIds = model.CategoryIds;
                ctrl.hasFilter = true;
            }

            if (!ctrl.fetching) {
                getTasks();
            }
        });

        function init() {
            if (ctrl.filterModel) {
                ctrl.managerFilters.StartTime = ctrl.filterModel.StartTime;
                ctrl.managerFilters.EndTime = moment(ctrl.filterModel.EndTime).endOf('day')
                ctrl.managerFilters.CategoryIds = ctrl.filterModel.CategoryIds;
                ctrl.hasFilter = true;
            } else {
                ctrl.managerFilters.StartTime = ctrl.startTime;
                ctrl.managerFilters.EndTime = ctrl.endTime;
                ctrl.managerFilters.CategoryIds = ctrl.categories;
                ctrl.hasFilter = false;
            }

            getTasks();
        }

        function onChangeDateFilter() {
            getTasks();
        }

        function getTasks() {
            ctrl.fetching = true;
            ctrl.managerFilters.DepartmentId = (ctrl.selectedDepartment && ctrl.selectedDepartment.DepartmentId) || null;

            TaskManagementRestService.getManagerTab(ctrl.managerFilters).then(function (data) {
                ctrl.fetching = false;
                ctrl.issues = data.Tasks || [];
                ctrl.overallCompletions = data.OverallCompletions;
                ctrl.overallCompletionsRequired = data.OverallCompletionsRequired;
                ctrl.overallProgress = data.OverallProgress;
                ctrl.departments = data.Departments;
                ctrl.tasksCount = data.TasksCount;

                ctrl.isTasksView = !!(ctrl.issues && ctrl.issues.length);

                if (!_.isEmpty(ctrl.issues)) {
                    ctrl.issues = TaskManagementRenderService.sortTasks(ctrl.issues);
                }

                onChangeStatusFilter(ctrl.activeStatusFilter);
                calculateOverallProgress();
            }).catch(() => {
                ctrl.fetching = false;
            }).finally(() => {
                ctrl.managerViewLoading = false;
            })
        }

        function calculateOverallProgress() {
            ctrl.completedCount = ctrl.issues.filter(function (item) {
                return item.Status === ISSUE_STATUS.COMPLETE;
            }).length;

            ctrl.overdueCount = ctrl.issues.filter(function (item) {
                return item.Status === ISSUE_STATUS.OVERDUE;
            }).length;
        }

        function onChangeStatusFilter(issueStatus) {
            ctrl.activeStatusFilter = issueStatus;

            if (!issueStatus) {
                ctrl.activeStatusIssues = ctrl.issues;
                return;
            }

            ctrl.activeStatusIssues = ctrl.issues.filter(function (item) {
                return item.Status === issueStatus;
            })
        }

        function openDepartment(department) {
            var data = {};
            if (ctrl.hasFilter) {
                data = {
                    department: department,
                    startTime: ctrl.managerFilters.StartTime,
                    endTime: ctrl.managerFilters.EndTime,
                    categories: ctrl.managerFilters.CategoryIds,
                    filterModel: ctrl.filterModel
                }
            } else {
                data = {
                    department: department,
                    startTime: ctrl.startTime,
                    endTime: ctrl.endTime
                }
            }

            PopupWrapperService.getPopup(TaskManagementRenderService.managerDepartmentPopupId).open(data);
        }

        function onDestroy() {
            taskFilterWatcher && taskFilterWatcher();
            ctrl.issues = [];
        }
    }
})();