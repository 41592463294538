(() => {
    'use strict';

    angular
        .module('App')
        .component('managerOverviewTable', {
            template: require('./ManagerOverviewTableComponent.html'),
            controllerAs: 'ctrl',
            controller: [ManagerOverviewTableController],
            bindings: {
                departments: '<',
                onOpenDepartment: '<'
            }
        });

    function ManagerOverviewTableController() {
        var ctrl = this;
        var COLUMN = {
            NAME: {
                title: 'TASK_MANAGEMENT.DEPARTMENTS',
                key: 'DepartmentName'
            },
            COMPLETION: {
                title: 'TASK_MANAGEMENT.COMPLETION',
                key: 'Progress'
            }
        };

        ctrl.COLUMN = COLUMN;
        ctrl.columns = [COLUMN.NAME, COLUMN.COMPLETION];

        ctrl.openDepartmentDetails = ctrl.onOpenDepartment;

        ctrl.$onInit = function () {
        };

        ctrl.toggle = function (node) {
            if (node.ChildrenDepartments && node.ChildrenDepartments.length) {
                node.isOpen = !node.isOpen;
            } else {
                ctrl.openDepartmentDetails(node);
            }
        };

        ctrl.sort = function (column) {
            ctrl.isReverse = (ctrl.selectedProperty === column.key) ? !ctrl.isReverse : false;
            ctrl.selectedProperty = column.key;
        }
    }
})();