(function () {
    'use strict';

    angular.module('App')
        .component('feedbackCommentsPopup', {
            template: require('./FeedbackCommentsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$http', '$timeout', '$rootScope', '$filter', 'ServiceFormRenderService',
                'ConfirmPopupService', 'FeedbackCommentsService', 'ReportPopupService', FeedbackCommentsPopupController],
            bindings: {
                feedback: '<',
                replyingMessage: '<',
                onCommentsUpdate: '<',
                commentId: '<',
                serviceFormFieldId: '<',
                serviceFormFillingId: '<',
                moduleToken: '@'
            }
        });

    function FeedbackCommentsPopupController($scope, $element, $http, $timeout, $rootScope, $filter,
                                             ServiceFormRenderService, ConfirmPopupService, FeedbackCommentsService, ReportPopupService) {
        const ctrl = this;
        let popup;

        ctrl.ratingColor = ServiceFormRenderService.getRatingColorClass(ctrl.feedback.Rating);
        ctrl.ratingIcon = ServiceFormRenderService.getRatingMedia(ctrl.feedback.Rating);

        ctrl.$onInit = init;
        ctrl.postComment = postComment;
        ctrl.deleteComment = deleteComment;
        ctrl.onRegistered = onRegistered;
        ctrl.close = close;
        ctrl.onReport = reportFeedbackComment;

        function init() {
            ctrl.settings = {   
                AllowAutomaticTranslation: ServiceFormRenderService.fillingInteractionSettings.AllowAutomaticTranslation,
                PreferTranslatedComments: ServiceFormRenderService.fillingInteractionSettings.PreferTranslatedComments,
            }
            resetNewComment();
        }

        function postComment(replyingComment) {
            if (!ctrl.isCommentPosting) {
                const
                    isNew = !ctrl.newComment.ServiceFormFeedbackCommentId,
                    path = '/ServiceForm/' + (isNew ? 'CreateFeedbackComment' : 'UpdateFeedbackComment');
                ctrl.isCommentPosting = true;

                FeedbackCommentsService.postComment(path, {
                    ServiceFormFeedbackId: ctrl.feedback && ctrl.feedback.ServiceFormFeedbackId,
                    ParentCommentId: replyingComment && (replyingComment.ParentCommentId || replyingComment.ServiceFormFeedbackCommentId),
                    ServiceFormFieldId: ctrl.serviceFormFieldId,
                    ServiceFormFillingId: ctrl.serviceFormFillingId,
                    Comment: ctrl.newComment.text,
                    MediaList: ctrl.newComment.uploadedMedia && ctrl.newComment.uploadedMedia.map(media => media.MediaId),
                    ServiceFormFeedbackCommentId: ctrl.newComment.ServiceFormFeedbackCommentId
                }).then((resp) => {
                    ctrl.feedback = resp.data.Feedback || resp.data;
                    ctrl.isCommentPosting = false;
                    resetNewComment();

                    isNew && $timeout(() => {
                        const comments = $element.find('.scroll-container.comments-list').get(0);
                        comments.scrollTop = comments.scrollHeight;
                    })
                })
            }
        }

        function onReportSubmit(model, itemId) {
            return ReportPopupService.reportFeedbackComment({
                serviceFormFeedbackCommentId: itemId,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportFeedbackComment(comment) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, comment.ServiceFormFeedbackCommentId);
        }

        function deleteComment(comment) {
            ConfirmPopupService
                .open({message: $filter('translate')('COMMENTS.DELETE_CONFIRMATION')})
                .then(() => {
                    $http.post('/ServiceForm/DeleteFeedbackComment', {
                        serviceFormFeedbackCommentId: comment.ServiceFormFeedbackCommentId
                    }).then((resp) => {
                        ctrl.feedback = resp.data.Feedback || resp.data;
                        $rootScope.$broadcast(resp.data.Feedback ? 'fillingFeedbackUpdated' : 'formFeedbackUpdated', resp.data)
                    })
                })
        }

        function onRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            $scope.$destroy();
            $element.remove();
        }

        function resetNewComment() {
            ctrl.newComment = {
                text: '',
                uploadedMedia: []
            };
        }
    }
})();