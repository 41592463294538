(function () {
    'use strict';

    angular.module('App')
        .component('pointsLog', {
            templateUrl: '/Scripts/Components/PointsLog/PointsLog.tpl.html',
            controllerAs: 'ctrl',
            controller: ['$http', '$timeout', '$translate', 'PointsLogService', 'HeaderButtonsFactory', 'TimeLocale',
                'IconService', PointsLogController]
        });

    function PointsLogController($http, $timeout, $translate, PointsLogService, HeaderButtonsFactory, TimeLocale,
                                 IconService) {
        var ctrl = this,
            pointsData, currentHighscoreIndex;

        ctrl.loading = true;
        ctrl.popupId = 'pointsLogPopup';
        ctrl.showDate = showDate;
        ctrl.paging = paging;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.getIcon = getIcon;


        function paging() {
            if (ctrl.logs) {
                ctrl.logs = _.take(getHighscores(pointsData.Points, ctrl.currentHighscore.HighscoreId), ctrl.logs.length + 50);
            }
        }

        function close() {
            PointsLogService.closePopup()
        }

        function onOpen() {
            $http.get('/Profile/GetPointLog/')
                .then(function (response) {
                    $translate('POINTS').then(function (translation) {
                        ctrl.title = translation;
                    });

                    pointsData = response.data;

                    if (pointsData.Highscores.length) {
                        ctrl.isMultipleHighscores = pointsData.Highscores.length > 1;
                        var defaultHighscore = _.find(pointsData.Highscores, {IsDefault: true});

                        setHighscore(defaultHighscore);

                        setFilterButtons(generateFilterConfig(pointsData.Highscores));

                        ctrl.logs = _.take(getHighscores(pointsData.Points, ctrl.currentHighscore.HighscoreId), 50);
                    }

                    $timeout(function () {
                        ctrl.loading = false;
                    });

                }, function () {
                    ctrl.loading = false;
                });
        }

        function showDate(data) {
            return TimeLocale.dateTimeFormat(data, 'D MMM YYYY');
        }

        function setHighscore(data) {
            ctrl.currentHighscore = data;
            currentHighscoreIndex = _.indexOf(pointsData.Highscores, data);
            ctrl.logs = _.take(getHighscores(pointsData.Points, data.HighscoreId), 50);
        }

        function generateFilterConfig(highscores) {
            var filterButtonsConfig = [];
            _.each(highscores, function (highscore) {
                filterButtonsConfig.push({
                    title: highscore.Name,
                    active: highscore.HighscoreId === ctrl.currentHighscore.HighscoreId,
                    onClick: function () {
                        setHighscore(highscore)
                    }
                })
            });

            return filterButtonsConfig;
        }

        function setFilterButtons(filterButtonsConfig) {
            ctrl.filterButtons = HeaderButtonsFactory.getButtonListConfig(function () {
                return [
                    {
                        icon: 'filter',
                        activeIcon: 'filter',
                        caption: 'CHANGE HIGHSCORE',
                        items: filterButtonsConfig
                    }
                ];
            });
        }

        function getHighscores(highscores, highscoreId) {
            return _.filter(highscores, {HighscoreId: highscoreId})
        }

        function getIcon(log) {
            return IconService.getIconByModuleId(log.ModuleId);
        }
    }
})();