(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('barChart', ['$log', '$http', 'Page', 'Profile', 'ChartHelper',
        function ($log, $http, Page, Profile, ChartHelper) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/BarChart.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chartIsLoading = true;
                    scope.chartLabel = scope.data.Label;
                    scope.noChart = false;
                    var $_container = $element.find('.chart-container');

                    var filterDepartmentId = Profile.getProfile().FilterDepartmentId;
                    var hasMultipleDepartments = Profile.getProfile().HasMultiDepartment;

                    var url = Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken;
                    if (hasMultipleDepartments) { url += "&departmentid=" + filterDepartmentId; }


                    // get data series from external source
                    $.getJSON(url,
                        function (response) {
                            if (response.DataSeries.length > 0) {
                                ShowChartWithData(response);
                            } else {
                                // No chart data
                                scope.noChart = true;
                            }
                            scope.chartIsLoading = false;
                            scope.$apply();
                        });



                    function ShowChartWithData(data) {
                        var chartSeries = [];
                        var chartCats = [];

                        // Add Data
                        var iLength = data.DataSeries.length;
                        for (var i = 0; i < iLength; i++) {
                            var mainColor = data.DataSeries[i].color;
                            var dLength = data.DataSeries[i].Data.length;
                            var serie = {
                                name: data.DataSeries[i].Name,
                                data: []
                            };
                            for (var d = 0; d < dLength; d++) {
                                var item = data.DataSeries[i].Data[d];
                                serie.data.push([item.Label, item.Value]);
                            }
                            chartSeries.push(serie);
                        }

                        // Calulate Size
                        var _width = $element.width();
                        var _height = _width / 1.5;
                        $_container.css({
                            width: _width,
                            height: _height + 20
                        });

                        // Create Chart
                        Highcharts.chart($_container[0], {
                            chart: {type: 'column'},
                            title: {text: null},
                            credits: {enabled: false},
                            xAxis: {
                                type: 'category',
                                lineColor: '#ffffff',
                                tickWidth: 0,
                                minorGridLineColor: '#ffffff'
                            },
                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        style: { 'fontWeight': 'normal' }
                                    }
                                }
                            },
                            legend: {
                                symbolRadius: 0,
                                itemStyle: {
                                    'fontWeight': 'normal'
                                }
                            },
                            yAxis: {
                                min: 0,
                                title: { text: null }
                            },
                            series: chartSeries
                        });
                    }
                }
            }
        }
    ]);
})();