(() => {
    'use strict';

    angular
        .module('App')
        .component('fillingsOverview', {
            template: require('./FIllingsOverviewComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['ServiceFormRestService', 'ServiceFormRenderService', FillingsOverviewController],
            bindings: {
                popupId: '<'
            }
        });

    function FillingsOverviewController(ServiceFormRestService, ServiceFormRenderService) {
        var ctrl = this;

        ctrl.onOpen = onOpen;
        ctrl.onClose = onClose;
        ctrl.createAnotherFilling = createAnotherFilling;

        function onOpen(data) {
            ctrl.headerTitle = data.headerTitle;
            ctrl.entityNameSingular = data.entityNameSingular;
            ctrl.createAnother = data.createChildFormCache;
            ServiceFormRestService.getFillingsOverview(data.serviceFormId, data.serviceFormFillingTokens)
                .then(function (data) {
                    ctrl.ChildFillings = [{
                        Overview: data
                    }];
                })
        }

        function onClose() {
            ServiceFormRenderService.sessionCreatedFillings = [];
            ctrl.popup.hide();
        }

        function createAnotherFilling() {
            ctrl.popup.hide();
            ServiceFormRenderService.renderLinkedFormPopup(
                ctrl.createAnother.data,
                ctrl.createAnother.token,
                ctrl.createAnother.serviceFormFillingToken,
                ctrl.createAnother
            );
        }
    }
})();