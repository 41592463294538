(() => {
    'use strict';

    angular
        .module('App')
        .factory('PostReadRegistrationService', ['$timeout', '$http', '$q', 'ModalService',
            'PopupWrapperService', 'MODULE_ID', PostReadRegistrationService]);

    function PostReadRegistrationService($timeout, $http, $q, ModalService, PopupWrapperService, MODULE_ID) {
        let callbacks = [], isEventListenerRegistered = false;

        return {
            showPopup: showPopup,
            onScroll: onScroll,
            offScroll: offScroll,
            registerRead: registerRead
        };

        function onScroll(callback, element, parentContainer) {
            if (!isEventListenerRegistered) {
                angular.element(parentContainer || document).on('scroll', applyListeners);
                isEventListenerRegistered = true;
            }
            registerCallback({
                callback: callback,
                element: element
            });
            $timeout(() => {
                callback();
            });
        }

        function offScroll(element, parentContainer) {
            _.remove(callbacks, function (callback) {
                return callback.element === element;
            });
            if (!callbacks.length) {
                angular.element(parentContainer || document).off('scroll', applyListeners);
                isEventListenerRegistered = false;
            }
        }

        function registerRead(postId, token, postType) {
            if (postType === 'social' && token && postId) {
                return $http.post('/Social/RegisterRead', {
                    token: token,
                    socialPostId: postId
                });
            } else if (postType === 'content' && postId) {
                return $http.post('/ContentModule/MarkAsSeen', {
                    contentId: postId
                });
            } else {
                return $q.reject('Error no id or token');
            }
        }

        function applyListeners(ev) {
            callbacks.forEach(callback => callback.callback(ev));
        }

        function registerCallback(callback) {
            callbacks.push(callback);
        }

        function showPopup(token, title, moduleId, type, moduleToken) {
            let componentName = 'read-by-popup';

            if (moduleId === MODULE_ID.CONTENT) {
                componentName = 'content-total-swipes-popup'
            }

            return PopupWrapperService.createDynamic(
                '<' + componentName + ' module-id="moduleId" type="type" ' +
                'token="token" header-title="title" module-token="moduleToken"></' + componentName + '>',
                {
                    moduleId: moduleId,
                    token: token,
                    title: title,
                    type: type,
                    moduleToken: moduleToken
                }
            )
        }
    }
})();