(function () {
    'use strict';

    angular
        .module('App')
        .component('wantToRatePopup', {
            template: require('./WantToRatePopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', WantToRatePopupController],
            bindings: {
                onYes: '<',
                onNo: '<'
            }
        })

    function WantToRatePopupController($element, $scope) {
        const ctrl = this;

        ctrl.yes = yes;
        ctrl.no = no;
        ctrl.onClose = onClose;

        function yes() {
            ctrl.closeAction();
            ctrl.onYes && ctrl.onYes();
        }
        
        function no() {
            ctrl.closeAction();
            ctrl.onNo && ctrl.onNo();             
        }

        function onClose() {
            $scope.$destroy();
            $element.remove()
        }
    }
})();