(function () {
    'use strict';

    var app = angular.module('App');

    app.component('ecShopgunPageView', {
        templateUrl: '/Scripts/Components/EmbeddedContent/ShopGun/ShopGunPageViewComponent/ShopGunPageViewComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$http', 'Page', EcShopgunPageViewController],
        bindings: {
            token: '<',
            session: '<',
            settings: '<',
            catalog: '<',
            showCatalog: '&'
        }
    });

    function EcShopgunPageViewController($http) {
        var ctrl = this;
        ctrl.isLoading = true;
        ctrl.pages = [];
        
        $http.get('/EmbeddedContent/ShopGunGetPageView/' + ctrl.token + '?sessionToken= ' + ctrl.session.token + '&catalogid=' + ctrl.catalog.id).then(function (response) {
            ctrl.pages = response.data.pages;
            ctrl.isLoading = false;
        }, function () {
            ctrl.isLoading = false;
        });

        ctrl.showPage = function (index) {
            if (ctrl.showCatalog) {
                ctrl.showCatalog({ catalog: ctrl.catalog, pageIndex: index });
            }
        };
    }
})();