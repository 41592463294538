(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('walkthroughSlideNativedownload', ['$translate', '$rootScope',
		function ($translate, $rootScope) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough-Slide-NativeDownload.tpl.html',
                scope: {
                    slide: '=',
                    parent: '=',
                    done: '&'
                },
                controller: ['$scope', '$element', function ($scope, $element) {
                    
                    // Init
                    InitDirective();
                    function InitDirective() {
                    }

                    // Methods
                    function Done() {
                        $scope.done();
					}

					function getBackgroundUrl() {
						var media = $scope.slide.SlideData.BackgroundMediaId;
						//console.log(media);
						if (media) {
							return media.ImageFormats.W600;
						}
						return null;
                    }

                    function openDownload() {
                        window.location.href = '/Native';
                    }

                    function skip() {
                        // Allow to go on directly from here
                        $($scope.parent).removeClass('swiper-no-swiping');
                        $scope.slide.Done = true;
                        $scope.done();
                    }
                    
                    // Objects

					// Bindings
					$scope.getBackgroundUrl = getBackgroundUrl;
                    $scope.Done = Done;
                    $scope.openDownload = openDownload;
                    $scope.skip = skip;
                }]
            }
        }
    ]);
})();