(function () {
    'use strict';


    angular.module('App')
        .component('createSubtaskOnlyTitle', {
            template: require('./CreateSubtaskOnlyTitleComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$rootScope', 'CreateSubtaskOnlyTitleService', 'UpdateSubtaskPopupService',
                'SubtasksListService', 'ToastFactory', createSubtaskOnlyTitlePopupController],
            bindings: {
                issueToken: '<',
                parentIssueToken: '<'
            }
        });

    function createSubtaskOnlyTitlePopupController($element, $rootScope, CreateSubtaskOnlyTitleService,
        UpdateSubtaskPopupService, SubtasksListService, ToastFactory) {

        var ctrl = this, preliminarySubtaskListUpdatedWatcher;

        ctrl.infoTitle = 'TASK_MANAGEMENT.SUBTASK.INFO';
        ctrl.infoMessage = 'TASK_MANAGEMENT.SUBTASK.SUBTASKS_TO_UNCOMPLETED_TASKS';

        ctrl.addSubtasks = addSubtasks;
        ctrl.addSubtaskToList = addSubtaskToList;
        ctrl.sortSubtasks = sortSubtasks;
        ctrl.clearInput = clearInput;
        ctrl.$onDestroy = destroy;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.editSubtask = editSubtask;
        ctrl.removeSubtaskFromTheList = removeSubtaskFromTheList;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.subtaskTitle = '';
        ctrl.isSaving = false;
        ctrl.subtaskList = [];
        ctrl.sortableOptions = {
            animation: 350,
            handle: '.handle',
            forceFallback: true,
            onEnd: function () {
                ctrl.sortSubtasks();
            }
        };;

        let popup;

        preliminarySubtaskListUpdatedWatcher = $rootScope.$on('TaskManagement:updatePreliminarySubtasksList', (event, data) => {
            ctrl.subtaskList = ctrl.subtaskList.map(subtask => {
                if (subtask.Index === data.Index) {
                    return {
                        ...subtask,
                        ...data
                    };
                }
                return subtask;
            });
        });

        function addSubtasks() {
            ctrl.isSaving = true;
            if (ctrl.subtaskList.length) {
                CreateSubtaskOnlyTitleService.createSubtasks(ctrl.parentIssueToken, ctrl.issueToken, ctrl.subtaskList).then(resp => {
                    $rootScope.$emit('TaskManagement:subtasksListUpdated', resp.data);
                    ctrl.subtaskTitle = '';
                    popup.remove();
                    $element.remove();
                    popup = null;
                })
                .catch(
                    () => {
                        ctrl.isSaving = false;
                        popup.remove();
                        $element.remove();
                        ToastFactory.errorTranslated('TASK_MANAGEMENT.SUBTASK.TASK_HAS_COMPLETIONS');
                        $rootScope.$emit('TaskManagement:taskWasCompletedError', ctrl.issueToken);
                    }
                );
            }
        }

        function sortSubtasks() {
            const tokensArray = ctrl.subtaskList.map(x => x.IssueSubtaskToken);
            SubtasksListService.sortSubtasks(ctrl.issueToken, tokensArray).then(resp => {
                $rootScope.$emit('TaskManagement:subtasksOrderChanged', resp.data);
            });
        }

        function addSubtaskToList() {
            if (!ctrl.subtaskTitle) return;
            ctrl.subtaskList.push(
                {
                    Title: ctrl.subtaskTitle,
                    LocaleList: [],
                    Medias: [],
                    Index: ctrl.subtaskList.length
                }
            );
            ctrl.subtaskTitle = '';
        }

        function clearInput() {
            ctrl.subtaskTitle = '';
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;

        }

        function close() {
            popup.remove();
            $element.remove();
            popup = null;
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }

        function destroy() {
            preliminarySubtaskListUpdatedWatcher && preliminarySubtaskListUpdatedWatcher();
        }

        function editSubtask(subtask) {
            UpdateSubtaskPopupService.openUpdateSubtaskPopup(subtask);
        }

        function removeSubtaskFromTheList(index) {
            ctrl.subtaskList = ctrl.subtaskList.filter(item => item.Index !== index);
        }
    }
})();