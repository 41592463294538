(() => {
    angular
        .module('Relesys.Ui')
        .component('rlAccordion', {
            controllerAs: 'ctrl',
            controller: ['$element', AccordionController],
            transclude: true,
            template: `<ng-transclude class="accordion-wrapper"></ng-transclude>`,
            bindings: {
                multipleOpen: '<'
            }

        });

    function AccordionController($element) {
        const ctrl = this;
        let accordion;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            accordion = $element.get(0);

            accordion.addEventListener('click', handleClick)
        }

        function handleClick(e) {
            if (e.target.closest('.accordion-item-header')) {
                const clickedItem = e.target.closest('rl-accordion-item');

                if (!clickedItem.classList.contains('opened')) {
                    if (!ctrl.multipleOpen) {
                        accordion
                            .querySelector('rl-accordion-item.opened')
                            ?.classList.remove('opened')
                    }

                    clickedItem.classList.add('opening');
                }

                setTimeout(() => {
                    clickedItem.classList.toggle('opened');
                })

                setTimeout(() => {
                    clickedItem.classList.remove('opening');
                }, 300)
            }
        }

        function destroy() {
            accordion.removeEventListener('click', handleClick);
        }
    }
})();