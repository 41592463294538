(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartHeader', ['$log', '$http', 'Page', 'Profile',
			function ($log, $http, Page, Profile) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartHeader.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chart = {
                        ShowHelp: (scope.data.HelpText != undefined && scope.data.HelpText != null && scope.data.HelpText.length > 0)
                    };

                    scope.openHelp = function () {
                        Page.showHelpPopup(scope.data.HelpText);
                    }
                }
            }
        }
    ]);
})();