(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlToggleFiltersPopup', {
            template: require('./ToggleFiltersPopupComponent.html'),
            controller: ['$timeout', '$scope', '$element', ToggleFiltersPopupController],
            controllerAs: 'ctrl',
            bindings: {
                onFilterSelected: '<',
                options: '<',
                removeSelection: '<',
                popupHeader: '<'
            }
        });

    function ToggleFiltersPopupController($timeout, $scope, $element) {
        const ctrl = this;
        
        ctrl.select = select;
        ctrl.clearFilter = clearFilter;
        ctrl.onClose = onClose;
        
        function select(item) {
            ctrl.onFilterSelected && ctrl.onFilterSelected(item);
            $timeout(() => {
                ctrl.closeAction && ctrl.closeAction();
            });
        }

        function clearFilter() {
            ctrl.onFilterSelected && ctrl.onFilterSelected();
            ctrl.closeAction && ctrl.closeAction();
        }

        function onClose() {
            $scope.$destroy();
            $element.remove();
        }
    }
})
();