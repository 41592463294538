(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('AppBaseFactory', ['$http', '$q', '$rootScope', 'Page', 'Profile', 'Menu', 'WalkthroughFactory',
        'NativeFactory', 'events', 'NotificationFactory', 'ChatConnectionService',
        'ReactionsService', 'ResponsiveService', 'TrackingService',
        function ($http, $q, $rootScope, Page, Profile, Menu, WalkthroughFactory, NativeFactory, events,
                  NotificationFactory, ChatConnectionService, ReactionsService,
                  ResponsiveService, TrackingService) {

            let lastResume = new Date();
            const lastResumeInterval = 1000 * 60; // 60 seconds

            // Listen for events
            // On native badge updates
            $rootScope.$on(events.BADGE_UPDATED, function (event, data) {
                // Set a new badge
                NativeFactory.push.setBadge(data.badgeCount);
            });

            // On new push message
            $rootScope.$on(events.PUSH_MESSAGE, function (event, data) {
                // Make sure we get a object
                if (_.isObject(data.pushMessage)) {
                    var msg = data.pushMessage;

                    // Navigate away if the push message says we should
                    if ((_.isString(msg.payload.appLinkId) && _.trim(msg.payload.appLinkId).length) || _.isNumber(msg.payload.appLinkId)) {
                        // We have a AppLinkId
                        // Was is also clicked?
                        if (_.isBoolean(msg.wasClicked) && msg.wasClicked === true) {
                            var appLinkId = _.toNumber(msg.payload.appLinkId);
                            Page.goToAppLink(appLinkId);

                            // Did we also have a notification id?
                            if ((_.isString(msg.payload.notificationId) && msg.payload.notificationId.length) || _.isNumber(msg.payload.notificationId)) {
                                // Lets register it as read, as we have clicked the push
                                var notificationId = _.toNumber(msg.payload.notificationId);
                                NotificationFactory.markAsRead(notificationId, true, true);
                            }
                        }
                    } else if (msg.payload.chatChannelId && msg.payload.chatModuleToken) {
                        if (_.isBoolean(msg.wasClicked) && msg.wasClicked === true) {
                            Page.stateGo(
                                'chat',
                                msg.payload.chatModuleToken,
                                null,
                                null,
                                {
                                    ChannelId: msg.payload.chatChannelId,
                                    MessageId: msg.payload.chatMessageId,
                                    hash: Date.now()
                                }
                            )
                        }
                    }
                }

                if (Page.isPinCodePageVisible() == false) {
                    // Reload our counters when we recieve a push message
                    // Reload Notifications
                    NotificationFactory.loadNotifications();
                }
            });

            // On app hide
            $rootScope.$on(events.APP_HIDE, function(event, data) {
                Page.setAppVisibility(false);
                // Track that the app was hidden
                if((Page.getSettings() != null && Page.getSettings().NativeSettings.IsNativeWrapper === false) || (data && data.isNativeEvent === true)){
                    TrackingService.trackAppHide();
                }
            });

            // On app un-hide
            $rootScope.$on(events.APP_UNHIDE, function (event, data) {
                Page.setAppVisibility(true);

                // Track that the app was un-hidden
                if((Page.getSettings() != null && Page.getSettings().NativeSettings.IsNativeWrapper === false) || (data && data.isNativeEvent === true)){
                    TrackingService.trackAppUnhide();
                }

                // Load Badges again when resuming the app
                // Only if it's more than 1 minute since we last loaded the badges
                if (Page.isPinCodePageVisible() == false) {
                    var diff = new Date() - lastResume;
                    if (diff > lastResumeInterval) {
                        lastResume = new Date();
                        // Reload Notifications
                        NotificationFactory.loadNotifications();
                        // Reload Badges based on navigation
                        reloadBadges();
                    }
                }

                // Evaluate the device ID if present
                if (data && _.isString(data.deviceId)) {
                    $http.post('/Login/ValidateDevice', {deviceId: data.deviceId}).then(function (response) {
                        if (response.data.IsValid === true) {
                            $http.defaults.headers.common['x-relesys-device-id'] = response.data.DeviceId;
                            $('html').data('device-token', response.data.DeviceId);
                            $('html').attr('device-token', response.data.DeviceId);
                        } else {
                            delete $http.defaults.headers.common['x-relesys-device-id'];
                            $('html').data('device-token', null);
                            $('html').removeAttr('data-device-token');
                        }
                    });
                }
            });

            function refreshBaseData(data) {
                const chatBadgeCount = data.UnreadChatsCount;
                NotificationFactory.setNativeBadgeCount(data.ModuleBadgeCount, data.NotificationCenterBadgeCount, chatBadgeCount);
                Menu.setMenus(data);
                ChatConnectionService.setChatBadge(data.UnreadChatsCount);
                $rootScope.$broadcast(events.CHAT_BADGES_UPDATED, true);
                $rootScope.$broadcast(events.MENU_UPDATED, true);
            }

            function updateProfileData(profileData){
                Profile.setProfile(profileData, true);
                if (profileData.DyslexiaFontEnabled) {
                    angular.element('body').addClass('dyslexia-font');
                }
            }

            var f = {
                loadBase: function () {
                    const d = $q.defer();

                    if (!Menu.hasData() || !Profile.getProfile()) {
                        if (!ResponsiveService.isDesktop()) {
                            NotificationFactory.loadUnseenCount();
                        }
                        ReactionsService.loadReactions();

                        $http.get('/App/LoadApp').then(function (response) {
                            if (response.data) {
                                // Settings
                                Page.setSettings(response.data.Settings);

                                if (response.data.AccountModuleCssClasses) {
                                    Page.setAccountModuleCssClasses(response.data.AccountModuleCssClasses);
                                }

                                // Profile
                                if (response.data.Profile) {
                                    updateProfileData(response.data.Profile);
                                } else {
                                    NativeFactory.user.isLoggedOut();
                                }
                                // Navigation   
                                if (response.data.Navigation) {
                                    Menu.setMenus(response.data.Navigation);
                                    if (response.data.Navigation.UnreadChatsCount) {
                                        ChatConnectionService.setChatBadge(response.data.Navigation.UnreadChatsCount);
                                    }
                                }
                                // Walkthrough
                                if (response.data.Walkthrough && response.data.Walkthrough.Enabled) {
                                    WalkthroughFactory.setWalkthrough(response.data.Walkthrough, false);
                                } else {
                                    WalkthroughFactory.setWalkthrough(null, true);
                                }
                            } else {
                                WalkthroughFactory.setWalkthrough(null, true);
                            }

                            // Load Badges asynchronously
                            reloadBadges();

                            d.resolve();
                        }, function () {
                            WalkthroughFactory.setWalkthrough(null, true);
                            d.reject();
                        });

                    } else if(_.isObject(Profile.getProfile()) == false) {
                        // we have data, but the profile = null
                        // so we need to reload the profile and do the basic profile and Menu data mapping
                        Profile.loadProfile().then(function (profileData){
                            updateProfileData(profileData);
                            d.resolve();
                        });
                    } else {
                        d.resolve();
                    }

                    return d.promise;
                },
                reloadApp: function() {
                    NativeFactory.basic.reload(function (res) {
                        if (res.status === 0) {
                            // Native did not do the reload so we do it instead
                            window.location.reload(true);
                        }
                    });
                }
            };

            function reloadBadges() {
                $http.get('/Navigation/GetNavigation')
                    .success(function (data) {
                        refreshBaseData(data);
                    });
            }

            return f;
        }
    ]);
})();