(function () {
    'use strict';

    angular
        .module('App')
        .component('media', {
            controllerAs: 'ctrl',
            template: require('./Media.tpl.html'),
            bindings: {
                item: '=',
                noLazy: '<',
                stop: '<',
                gradient: '<',
                enablePopup: '<',
                onImageComment: '<',
                enableImageComment: '<',
                onMediaLoaded: '<',
                resizeWidth: '<',
                items: '=',
                selectedIndex: '<',
            },
            controller: ['$scope', '$element', '$sce', '$timeout', '$rootScope', 'BasicHelper',
                'AttachmentsViewerService', MediaController]
        })

    function MediaController($scope, $element, $sce, $timeout, $rootScope, BasicHelper, AttachmentsViewerService) {
        const ctrl = this,
            videoFormats = ['HD2160p', 'HD1440p', 'HD1080p', 'HD720p', 'SD480p', 'SD360p', 'SD240p'];

        ctrl.$onInit = init;
        ctrl.getEmbedUrl = getEmbedUrl;
        ctrl.playVideo = playVideo;
        ctrl.openViewer = openViewer;
        ctrl.getSafeUrl = getSafeUrl;

        function init() {
            const elWidth = $element.width(),
                width = elWidth > 0 ? elWidth : document.querySelector('body').offsetWidth;
            
            ctrl.width = 'W' + BasicHelper.getImageVariantBySize(width);
            ctrl.item.show = !(ctrl.item.MediaType === 2 && ctrl.item.MediaPathTypeId === 1);

            if (ctrl.item.MediaType === 2) {
                $scope.$watch('stop', function (val) {
                    if (val) {
                        if (ctrl.item.MediaPathTypeId === 1) {
                            var $video = $element.find('video').get(0);
                            if ($video !== undefined) {
                                $video.pause();
                            }
                        }
                    }
                });
            }

            $timeout(() => {
                ctrl.onMediaLoaded && ctrl.onMediaLoaded();
                ctrl.isLoaded = true;
            })
        }

        function getEmbedUrl() {
            if (ctrl.item.MediaType === 2) {
                const youtubeEmbedUrl = "https://www.youtube-nocookie.com/embed/",
                    vimeoEmbedUrl = "https://player.vimeo.com/video/",
                    dreamBroker = "https://dreambroker.com/channel/";

                if (ctrl.item.MediaPathTypeId === 1) {
                    let videoUrl = ctrl.item.UrlEncoded;
                    for (let i = 0; i < videoFormats.length; i++) {
                        if (ctrl.item.VideoFormats && 
                            ctrl.item.VideoFormats[videoFormats[i]] &&
                            ctrl.item.VideoFormats[videoFormats[i]].length) {
                            videoUrl = ctrl.item.VideoFormats[videoFormats[i]];
                            break;
                        }
                    }
                    return $sce.trustAsResourceUrl(videoUrl);
                } else if (ctrl.item.MediaPathTypeId === 2) {
                    return $sce.trustAsResourceUrl(youtubeEmbedUrl + ctrl.item.Path + "?rel=0&showinfo=0");
                } else if (ctrl.item.MediaPathTypeId === 3) {
                    if (ctrl.item.Path.includes("?h=")) {
                        return $sce.trustAsResourceUrl(vimeoEmbedUrl + ctrl.item.Path + "&portrait=0&title=0&badge=0");
                    }
                    return $sce.trustAsResourceUrl(vimeoEmbedUrl + ctrl.item.Path + "?portrait=0&title=0&badge=0");
                } else if (ctrl.item.MediaPathTypeId === 5) {
                    return $sce.trustAsResourceUrl(dreamBroker + ctrl.item.Path);
                }
            }
            return null;
        }

        function getSafeUrl() {
            return $sce.trustAsResourceUrl(ctrl.item.Url);
        }

        function playVideo($event) {
            $event && $event.stopPropagation();
            ctrl.item.show = true;
            if (ctrl.item.MediaPathType === 1) {
                $timeout(function () {
                    var video = $element.find('video');
                    video[0].play();
                });
            }
        }

        function openViewer($event, selectedIndex) {
            if (ctrl.enablePopup) {
                $event.stopPropagation();
                AttachmentsViewerService.openViewerWithMedia(
                    ctrl.items || [ctrl.item],
                    selectedIndex || 0,
                    ctrl.enableImageComment && ctrl.onImageComment
                );
            }
        }
    }
})();