(function () {
    'use strict';

    var app = angular.module('App');

    app.component('buttonGroup', {
        templateUrl: '/Scripts/Components/ButtonGroup/ButtonGroupComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$timeout', '$rootScope', 'ServiceFormRenderService', ButtonGroupController],
        bindings: {
            items: '<',
            itemId: '@',
            isMultiselect: '<',
            min: '<',
            max: '<',
            serviceFormFieldId: '<'
        },
        require: {
            ngModelCtrl: 'ngModel'
        }
    });


    function ButtonGroupController($timeout, $rootScope, ServiceFormRenderService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.onChange = onChange;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.items = _.cloneDeep(ctrl.items);

            $timeout(function () {
                _.each(ctrl.ngModelCtrl.$viewValue, function (model) {
                    ctrl.items = ctrl.items.map(item => {
                        if (item.DataListItemId === model.DataListItemId) {
                            item.selected = true;
                            item.inUse = false;
                        }
                        return item;
                    });
                });

                if (ctrl.min && ctrl.isMultiselect) {
                    ctrl.ngModelCtrl.$validators.min = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;
                        if (ctrl.ngModelCtrl.$validators.required(modelValue, viewValue) && value && value.length >= 1) {
                            return value.length >= ctrl.min;
                        } else {
                            return true
                        }
                    };
                    ctrl.ngModelCtrl.$validate();
                }
            });
        }

        function onChange(model) {
            if (ctrl.isMultiselect || ctrl.items?.length === 1) {
                if (model.inUse && model.actionType) {
                    showPopup(model);
                    return;
                }

                model.selected = !model.selected;
                ctrl.ngModelCtrl.$setViewValue(_.filter(ctrl.items, {selected: true}));
                ctrl.disabled = ctrl.max && (ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length === ctrl.max);
            } else {
                if (model.inUse && model.actionType) {
                    showPopup(model);
                    return;
                }

                _.each(ctrl.items, function (item) {
                    item.selected = false;

                });
                model.selected = true;
                ctrl.ngModelCtrl.$setViewValue([model]);
            }
        }

        function showPopup(item) {
            return ServiceFormRenderService.checkLimitRuleAndShowPopup(item, () => {
                $rootScope.$broadcast('goToLimitedReports', {
                    item,
                    listData: ctrl.items,
                    DataListFilters: {
                        DataListItemId: item.DataListItemId,
                        ServiceFormFieldId: ctrl.serviceFormFieldId
                    }
                });
                destroy();
            });
        }

        function destroy() {
            _.each(ctrl.items, function (item) {
                item.selected = false;
            })
        }
    }
})(); 