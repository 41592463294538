(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('WalkthroughFactory', ['$q', '$http', '$timeout', 'Page', function ($q, $http, $timeout, Page) {
        var walkthrough = null;
        var walkthroughChecked = false;
        var initialized = false;
        var IgnoreAppLink = false;

        var f = {
            isChecked: function () {
                return walkthroughChecked;
            },
            isOpenAppLink: function() {
                return !IgnoreAppLink;
            },
            setWalkthrough: function (data, checked, ignoreAppLink) {
                walkthrough = data;
                walkthroughChecked = checked;
                IgnoreAppLink = ignoreAppLink;
            },
            loadPrivacy: function () {
                return $http.get('/Walkthrough/LoadPolicy').then(function (response) {
                    if (response.data == undefined || response.data == null || response.data.length === 0 || !response.data.Enabled) {
                        // Do not show a walkthrough if none could be loaded, or it said it wasn't enabled
                        f.setWalkthrough(null, true);
                    } else {
                        // Set to show the walkthrough
                        f.setWalkthrough(response.data, false);
                    }
                })
            },
            loadWalkthrough: function () {
                var d = $q.defer();

                $http.get('/Walkthrough/LoadWalkthroughSlides').then(function (response) {
                    if (response.data == undefined || response.data == null || response.data.length === 0 || !response.data.Enabled) {
                        // Do not show a walkthrough if none could be loaded, or it said it wasn't enabled
                        f.setWalkthrough(null, true);
                    } else {
                        // Set to show the walkthrough
                        f.setWalkthrough(response.data, false, true);
                    }

                    d.resolve();
                }, function () {
                    f.setWalkthrough(null, true);
                    d.resolve();
                });

                return d.promise;
            },
            load: function () {
                var d = $q.defer();

                $http.get('/Walkthrough/Get').then(function (response) {
                    if (response.data == undefined || response.data == null || response.data.length === 0 || !response.data.Enabled) {
                        // Do not show a walkthrough if none could be loaded, or it said it wasn't enabled
                        f.setWalkthrough(null, true);
                    } else {
                        // Set to show the walkthrough
                        f.setWalkthrough(response.data, false);
                    }

                    d.resolve();
                }, function () {
                    f.setWalkthrough(null, true);
                    d.resolve();
                });

                return d.promise;
            },
            get: function () {
                return walkthrough;
            },
            initialized: function () {
                initialized = true;
            },
            isInitialized: function () {
                return initialized;
            },
            end: function (element) {
                // Set walkthrough as has been checked
                walkthroughChecked = true;
            }
        };

        return f;
    }]);
})();