(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlButton', {
            template: require('./RelesysButtonComponent.html'),
            controller: [RlButtonController],
            controllerAs: 'ctrl',
            transclude: true,
            bindings: {
                type: '@',
                click: '&',
                disabled: '<',
                text: '@',
                icon: '@',
                iconPosition: '@',
                iconPrefix: '@'
            }
        });


    function RlButtonController() {
        const ctrl = this;

        // type:
        // full-width
        // standard
        // outline
        // transparent
        // round
        // upper
        //iconPosition:
        ///left
        //right
        
        ctrl.$onInit = function () {
            ctrl.type = ctrl.type || 'standard';
        }
    }
})();


