(() => {
    'use strict';

    angular
        .module('App')
        .component('contactModule', {
            template: require('./ContactComponent.html'),
            controllerAs: 'ctrl',
            controller: ['TemplateFactory', '$translate', 'ModuleSearchService', 'ContactFactory', 'DepartmentPopupService', 'Page', ContactComponent],
            bindings: {
                myStore: '<',
                departments: '<',
                settings: '<'
            }
        });

    function ContactComponent(TemplateFactory, $translate, ModuleSearchService, ContactFactory, DepartmentPopupService, Page) {
        const ctrl = this;

        ctrl.views = {
            MyStore: 1,
            Departments: 2
        }
        ctrl.$onInit = init;
        ctrl.activeView = ctrl.views.MyStore;
        ctrl.openDepartmentCard = OpenDepartment;
        ctrl.search = onSearch;
        ctrl.$onDestroy = destroy;

        ctrl.myStoreContacts = ctrl.myStore;
        ctrl.moduleSettings = ctrl.settings;
        ctrl.isNative = TemplateFactory.getTemplate().key === 'native';

        ctrl.searchSettings = {
            onModelChange: ctrl.search,
            model: ''
        };

        function init() {
            ModuleSearchService.enableSearch(ctrl.searchSettings);
            setTabHeadlines();
            setActiveTab();
        }

        function onSearch(string) {
            if (string != null && string !== '') {
                ContactFactory.search(string).then(data => {
                    ctrl.usersFromSearch = data.ResultUsers;
                    ctrl.relatedUsersFromSearch = data.RelatedResultsUsers;

                    ctrl.relatedUsersFromSearch.forEach(item => {
                        if (!ctrl.usersFromSearch.some(x => x.UserToken === item.UserToken)) {
                            ctrl.usersFromSearch.push(item);
                        }
                    });

                    ctrl.searchUsersToShow = _.orderBy(ctrl.usersFromSearch,
                        [user => user.Name.toLowerCase()], ['asc']);
                    ctrl.isSearchView = true;
                });
            } else {
                if (ctrl.moduleSettings.ShowMyStore) {
                    ctrl.activeView = ctrl.views.MyStore;
                }
                ctrl.relatedUsersFromSearch = null;
                ctrl.usersFromSearch = null;
                ctrl.isSearchView = false;
            }
        }

        function OpenDepartment(department) {
            const showContacts = ctrl.moduleSettings.StaticContactsEnabled;
            DepartmentPopupService.openDepartmentPopup(department.DepartmentToken, showContacts, Page.getCurrentToken());
        }

        function setTabHeadlines() {
            if (ctrl.moduleSettings.UserDepartmentHeadline.length < 1) {
                $translate('CONTACT.MY_STORE').then(function (translation) {
                    ctrl.moduleSettings.UserDepartmentHeadline = translation;
                });
            }

            if (ctrl.moduleSettings.DepartmentHeadline.length < 1) {
                $translate('CONTACT.STORES').then(function (translation) {
                    ctrl.moduleSettings.DepartmentHeadline = translation;
                });
            }
        }

        function setActiveTab() {
            if (ctrl.moduleSettings.ShowStores && !ctrl.moduleSettings.ShowMyStore) {
                ctrl.activeView = ctrl.views.Departments;
            } else {
                ctrl.activeView = ctrl.views.MyStore;
            }
        }

        function destroy() {
            ModuleSearchService.disableSearch();
        }
    }
})();
