(function () {
    'use strict';
    angular
        .module('App')
        .directive('mediaUpload', ['Upload', '$translate', 'ToastFactory', '$log', 'BasicHelper', 'Page', 'Profile',
            'UploadFactory', '$cookies', '$timeout', 'ModalService', 'LocalStorageService', mediaUpload]);

    function mediaUpload(Upload, $translate, ToastFactory, $log, BasicHelper, Page, Profile, UploadFactory, $cookies,
                         $timeout, ModalService, LocalStorageService) {
        return {
            restrict: 'E',
            require: '?ngModel',
            transclude: true,
            template: require('./Templates/MediaUpload.tpl.html'),
            scope: {
                uploaded: '&',
                maxSize: '@',
                ref: '@',
                allowTypes: '@',
                isMultiple: '=?',
                hideGlobalProgress: '=?'
            },
            controller: ['$rootScope', '$scope', '$element', '$attrs', '$transclude', '$parse', '$compile', '$timeout',
                function ($rootScope, $scope, $element, $attrs, $transclude, $parse, $compile, $timeout) {
                    var ngModel = $element.controller('ngModel');
                    const ios11WarningKey = 'ios11uploadwarning';

                    if (_.isString($scope.ref) === false || $scope.ref.length === 0) {
                        $scope.ref = BasicHelper.getRandomID(30);
                    }

                    $element.attr('tabindex', 0);
                    $element.attr('role', 'button');

                    $element.on('keypress', function (ev) {
                        if (ev.keyCode === 13 || ev.keyCode === 32) {
                            $element.find('.upload').trigger('click');
                        }
                    });

                    $scope.acceptedMedia = "";
                    $scope.filesUploading = [];

                    // Default Values
                    $attrs.allowImage = ($attrs.image !== undefined && $attrs.image.toLowerCase() !== 'false');
                    $attrs.allowVideo = ($attrs.video !== undefined && $attrs.video.toLowerCase() !== 'false');
                    $attrs.allowAll = ($attrs.all !== undefined && $attrs.all.toLowerCase() !== 'false');

                    if ($attrs.multiple !== undefined && $attrs.multiple.toLowerCase() !== 'false' && $scope.isMultiple === undefined) {
                        $scope.isMultiple = true;
                    }

                    // Accepted Media Attributes
                    if ($attrs.allowTypes !== undefined) {
                        switch ($scope.allowTypes) {
                            case 'all':
                                $attrs.allowAll = true;
                                $attrs.allowVideo = false;
                                $attrs.allowImage = false;
                                break;
                            case 'media':
                                $attrs.allowAll = false;
                                $attrs.allowVideo = false;
                                $attrs.allowImage = false;
                                break;
                            case 'video':
                                $attrs.allowAll = false;
                                $attrs.allowVideo = true;
                                $attrs.allowImage = false;
                                break;
                            case 'image':
                                $attrs.allowAll = false;
                                $attrs.allowVideo = false;
                                $attrs.allowImage = true;
                                break;
                        }
                    }

                    var allowAllMedia = (!$attrs.allowVideo && !$attrs.allowImage && !$attrs.allowAll);


                    // Determine upload warning messages
                    var warningMissing = determineWarningCheck();
                    if (warningMissing) {
                        var warningCheckTimeout = $timeout(function () {
                            if (warningMissing) {
                                warningMissing = determineWarningCheck();
                            } else {
                                $timeout.cancel(warningCheckTimeout);
                            }
                        }, 5000);
                    }

                    // Create accepted Media
                    if ($attrs.allowAll) {
                        $scope.acceptedMedia = "*/*";
                    } else {
                        if ($attrs.allowVideo || allowAllMedia)
                            $scope.acceptedMedia += "video/*";
                        if ($attrs.allowImage || allowAllMedia) {
                            if ($scope.acceptedMedia.length > 0)
                                $scope.acceptedMedia += ",";
                            $scope.acceptedMedia += "image/*";
                        }
                    }

                    // For our Android native app, we require the */* wildcard type on versions below 1.3.41,
                    // so we keep that here for backward compatibility
                    if ($scope.acceptedMedia === '' || $scope.acceptedMedia === '*/*') {
                        if (NativeHelper.isAndroid() === true) {
                            $scope.acceptedMedia = '*/*';
                        } else {
                            $scope.acceptedMedia = '';
                        }
                    }

                    // Max Size
                    if ($scope.maxSize === undefined) {
                        $scope.maxSize = 10;
                    }
                    $scope.maxSizeText = $scope.maxSize + "MB";

                    // Upload Function
                    $scope.uploadMedia = function (files, invalidFiles) {
                        if (files && files.length) {
                            for (var i = 0; i < files.length; i++) {
                                uploadFile(files[i]);
                            }
                        } else if (invalidFiles && invalidFiles.length) {
                            for (var ii = 0; ii < invalidFiles.length; ii++) {
                                var invalidFile = invalidFiles[ii];
                                if (invalidFile.$error === "maxSize") {
                                    $translate('UPLOAD.FILE_SIZE').then(function (translation) {
                                        translation = translation.replace("[SIZE]", $scope.maxSizeText);
                                        ToastFactory.error(translation);
                                    });
                                }
                            }
                        } else {
                            // We just didn't recieve any files - Not a big error
                            //var e = new Error('Upload called without any files or errors');
                            //$log.error(e.message);
                            //Raven.captureException(e);
                        }
                        $rootScope.disableVisibilitychange = false;
                    };

                    // Upload Warning Function
                    $scope.missingUploadWarning = function () {
                        return warningMissing;
                    };

                    $scope.uploadWarning = function () {
                        // Show warning
                        ModalService.showModal({
                            templateUrl: '/Templates/GlobalModules/IOS11UploadWarningModal.tpl.html',
                            controller: 'IOS11UploadWarningModalController'
                        }).then(function (modal) {

                        });

                        // Reset warning
                        warningMissing = false;
                        // Save warning of iOS 11
                        if (!BasicHelper.isWrapperApp() && BasicHelper.isIOS() && BasicHelper.isIOSVersionBetween('11', '11.3', true, false)) {
                            LocalStorageService.set(ios11WarningKey, true);
                        }
                    };

                    function getFileWithCoordinates(data) {
                        var file = data.Files[0];
                        if (!_.isEmpty(data.GpsInfo) && data.Files[0].MediaId === data.GpsInfo[0].MediaId) {
                            file.GpsInfo = data.GpsInfo[0];
                            if (typeof file.GpsInfo.Lat === 'number' && typeof file.GpsInfo.Lon === 'number') {
                                file.GpsInfo.title = file.GpsInfo.Lat.toFixed(5) + ' ' + file.GpsInfo.Lon.toFixed(5);
                            }
                        }

                        return file;
                    }

                    function uploadFile(file) {
                        var sourceFile = file;
                        sourceFile.ref = $scope.ref;

                        UploadFactory.upload(file, {hideGlobalProgress: $scope.hideGlobalProgress}).then(function (response) {
                            // Success
                            var file = getFileWithCoordinates(response.data);
                            // Set Model

                            if (ngModel) {
                                if ($scope.isMultiple) {
                                    var newValue = ngModel.$modelValue;
                                    if (_.isArray(newValue) === false)
                                        newValue = [];

                                    newValue.push(file);
                                    ngModel.$setViewValue(newValue);
                                } else {
                                    ngModel.$setViewValue([file]);
                                }
                            }

                            // Fire callback
                            if ($scope.uploaded) {
                                $parse($scope.uploaded)({
                                    $media: file,
                                    $file: sourceFile
                                });
                            }
                        }, function () {
                            // Failed
                            var e = new Error("Failed to upload file");
                            Raven.captureException(e);
                        });
                    }

                    function determineWarningCheck() {
                        if (!BasicHelper.isWrapperApp() && BasicHelper.isIOS() && BasicHelper.isIOSVersionBetween('11', '11.3', true, false)) {
                            if(LocalStorageService.hasLocalStorage() == false){
                                // we will not warn if cannot store the state
                                return false;
                            }

                            const storedValue = LocalStorageService.get(ios11WarningKey);
                            if(storedValue !== true){
                                return true;
                            }
                        }

                        return false;
                    }
                }]
        };
    }
})();