(function () {
	'use strict';
	var app = angular.module('App');

	app.directive('ngDynamicWrapper', ['$compile', '$parse',function($compile, $parse) {
			return {
				restrict: 'A',
				terminal: true,
				priority: 100000,
				scope: {
					ctrl: '=ctrl',
					template: '=template'
				},
				link: function(scope, elem, attrs) {
					elem.attr('ng-controller', scope.ctrl);
					elem.removeAttr('ng-dynamic-wrapper');

					if (scope.template) {
						var html = '<ng-include src="\'' + scope.template + '\'"></ng-include>';
						elem.html(html);
					}

					$compile(elem)(scope);
				}
			}
		}
	]);
})();