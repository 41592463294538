(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .factory('ConfirmPopupService', ['$compile', '$q', '$rootScope', function ($compile, $q, $rootScope) {

            return {
                open: open
            };

            function open(opts) {
                return $q((resolve, reject) => {
                    const scope = $rootScope.$new(true);
                    let defaultOptions, popupTemplate, popupElement, options;

                    defaultOptions = {
                        title: null,
                        message: '',
                        yesText: 'YES',
                        noText: 'NO',
                        iconName: '',
                        notes: '',
                        isAlert: false,
                        onClose: undefined,
                        preventClosing: false,
                    };

                    options = _.merge(defaultOptions, opts);

                    popupTemplate = '<confirm-popup class="" ' +
                        'is-alert="ctrl.options.isAlert" ' +
                        'options="ctrl.options" ' +
                        'on-yes-callback="ctrl.onYes" ' +
                        'on-no-callback="ctrl.onNo" ' +
                        'on-close="ctrl.onClose"></confirm-popup>';

                    scope.ctrl = {
                        onYes: () => {
                            resolve();
                            popupElement.remove();
                        },
                        onNo: () => {
                            reject();
                            popupElement.remove();
                        },
                        onClose: () => {
                            options.onClose && options.onClose();
                            popupElement.remove();
                        },
                        options: options
                    };

                    popupElement = $compile(popupTemplate)(scope).get(0);
                    document.querySelector('body').appendChild(popupElement);
                });
            }
        }]);
})();