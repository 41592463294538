(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('headerPopup', {
            template: require('./HeaderPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$attrs', '$transclude', HeaderPopupController],
            transclude: {
                'left': '?leftButtons',
                'titleSlot': '?titleSlot',
                'right': '?rightButtons',
            },
            bindings: {
                headerTitle: '@',
                headerSubTitle: '@',
                onClose: '&',
                closeDisabled: '<',
                onSave: '&',
                saveDisabled: '<',
                saveInactive: '<'
            }
        })

    function HeaderPopupController($attrs, $transclude) {
        var ctrl = this;

        ctrl.isCloseSet = $attrs.onClose;
        ctrl.isSaveSet = $attrs.onSave;
        
        ctrl.isLeftSet = $transclude.isSlotFilled('left');
        ctrl.isRightSet = $transclude.isSlotFilled('right');
        ctrl.isTitleSlotSet = $transclude.isSlotFilled('titleSlot');
    }
})();