(function () {
    'use strict';

    angular
        .module('App')
        .component('fillingsOverviewContent', {
            template: require('./FillingsOverviewContentComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$translate', 'ServiceFormRestService', 'ConfirmPopupService', FillingsOverviewContent],
            bindings: {
                childFillings: '<',
                showDelete: '<',
                entityNameSingular: '<'
            }
        });

    function FillingsOverviewContent($translate, ServiceFormRestService, ConfirmPopupService) {
        var ctrl = this;
        ctrl.getDateValue = getDateValue;

        ctrl.deleteFilling = deleteFilling;
        ctrl.createSubTitles = createSubTitles;
        ctrl.$onInit = init;

        function init() {
            _.forEach(ctrl.childFillings, function (form) {
                _.forEach(form.Overview.Fillings, function (filling) {
                    _.forEach(filling.Cells, function (cell) {
                        if (cell.FieldType === 5 && !cell.MediaValues) {
                            cell.MediaValues = [];

                            _.forEach(cell.Values, function (value) {
                                if (value.Media) {
                                    cell.MediaValues.push(value.Media);
                                }
                            })
                        }
                    })
                })
            })
        }

        function getDateValue(field) {
            var value = field.Values[0];
            if (value) {
                return value.DateTimeLocaleString;
            }

            return null;
        }

        function deleteFilling(filling, fillings) {
            var confirmationString = $translate.instant('SERVICEFORM.FILLING.DELETE_WARNING') + ' ' +
                ctrl.entityNameSingular + '? ' + $translate.instant('SERVICEFORM.FILLING.DELETE_UNDONE');

            var opts = {
                message: confirmationString,
                class: 'overview-popup'
            };

            ConfirmPopupService.open(opts)
                .then(function () {
                    ServiceFormRestService.deleteFilling(filling.ServiceFormFillingToken).then(function () {
                        _.remove(fillings, filling);
                    });
                });
        }

        function createSubTitles(dataListItem) {
            var subTitle = [];
            if (dataListItem.DataListType == 3 && dataListItem.AdditionalValues) // customer 
            {
                if (dataListItem.AdditionalValues.Country) {
                    subTitle.push(dataListItem.AdditionalValues.Country);
                }
                if (dataListItem.AdditionalValues.ZipCode) {
                    subTitle.push(dataListItem.AdditionalValues.ZipCode + " " + dataListItem.AdditionalValues.City);
                }
                if (dataListItem.AdditionalValues.Street) {
                    subTitle.push(dataListItem.AdditionalValues.Street);
                }
            }
            return subTitle;
        }
    }
})();