(() => {
    'use strict';

    angular
        .module('App')
        .component('pinCode', {
            template: require('./PinCode.html'),
            controller: ['$scope', PinCodeController],
            controllerAs: 'ctrl',
            bindings: {
                pinTitle: '<',
                onSubmit: '<',
                pinCode: '=?',
                showKeyboard: '<',
                isValid: '<',
                isInvalid: '<',
                showBiometricButton: '<',
                onBiometric: '<'
            }
        });


    function PinCodeController($scope) {
        var ctrl = this;
        var validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'backspace'];
        var keycodeTable = {
            48: '0', 49: '1', 50: '2', 51: '3', 52: '4', 53: '5', 54: '6', 55: '7', 56: '8', 57: '9', // Regular number keys
            96: '0', 97: '1', 98: '2', 99: '3', 100: '4', 101: '5', 102: '6', 103: '7', 104: '8', 105: '9', // Numpad number keys
            8: 'backspace'
        };

        ctrl.keyboard = [
            {
                title: '1',
                click: function () {
                    enterNumber(1);
                }
            }, {
                title: '2',
                click: function () {
                    enterNumber(2);
                }
            }, {
                title: '3',
                click: function () {
                    enterNumber(3);
                }
            }, {
                title: '4',
                click: function () {
                    enterNumber(4);
                }
            }, {
                title: '5',
                click: function () {
                    enterNumber(5);
                }
            }, {
                title: '6',
                click: function () {
                    enterNumber(6);
                }
            }, {
                title: '7',
                click: function () {
                    enterNumber(7);
                }
            }, {
                title: '8',
                click: function () {
                    enterNumber(8);
                }
            }, {
                title: '9',
                click: function () {
                    enterNumber(9);
                }
            }, {
                title: 'biometric',
                click: openBiometric
            }, {
                title: '0',
                click: function () {
                    enterNumber(0);
                }
            }, {
                title: 'backspace',
                click: backspace
            }

        ];

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        $scope.$watch('ctrl.pinCode', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const newPinLength = newVal.join('').length;
                if (newPinLength === 0) {
                    ctrl.currentPosition = 0;
                } else if(newPinLength === 4) {
                    ctrl.currentPosition = 3;
                }
            }
        }, true);

        function init() {
            if (!ctrl.pinCode) {
                ctrl.pinCode = [null, null, null, null];
            }
            ctrl.currentPosition = ctrl.pinCode.join('').length;

            // Listen for keyboard events
            document.addEventListener('keydown', pinKeyboardEvent, true);
        }

        function pinKeyboardEvent(ev) {
            var key = ev.key || ev.keyCode;

            if (_.isNumber(key)) {
                if (key.toString() in keycodeTable) {
                    key = keycodeTable[key.toString()];
                } else {
                    return;
                }
            }

            // Ensure lowercase
            key = key.toLowerCase();

            if (validKeys.indexOf(key) >= 0) {
                if (key === 'backspace') {
                    backspace();
                } else {
                    enterNumber(parseInt(key));
                }
                $scope.$apply();
            }
        }

        function enterNumber(number) {
            if (ctrl.currentPosition <= ctrl.pinCode.length - 1) {
                ctrl.pinCode[ctrl.currentPosition] = number;

                if (ctrl.currentPosition !== ctrl.pinCode.length - 1) {
                    ctrl.currentPosition++;
                }
            }

            if (ctrl.pinCode.join('').length === 4) {
                submit();
            }
        }

        function backspace() {
            if (ctrl.currentPosition === (ctrl.pinCode.length - 1) && ctrl.pinCode[ctrl.currentPosition] !== null) {
                ctrl.pinCode[ctrl.currentPosition] = null;
            } else if (ctrl.currentPosition !== 0) {
                ctrl.currentPosition--;
                ctrl.pinCode[ctrl.currentPosition] = null;
            }

            if (ctrl.isValid || ctrl.isInvalid) {
                ctrl.isValid = false;
                ctrl.isInvalid = false;
            }
        }

        function openBiometric() {
            if(ctrl.showBiometricButton){
                ctrl.onBiometric && ctrl.onBiometric();
            }
        }

        function submit() {
            ctrl.onSubmit && ctrl.onSubmit(ctrl.pinCode);
        }

        function destroy() {
            document.removeEventListener('keydown', pinKeyboardEvent, true);
        }
    }
})();


