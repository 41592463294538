(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('approveSlider', {
            template: require('./ApproveSliderComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', 'ToastFactory', ApproveSliderController],
            bindings: {
                onApprove: '<',
                onReject: '<',
                isApproved: '<',
                isRejected: '<',
                approveText: '@',
                approvedText: '@',
                rejectText: '@',
                rejectedText: '@'
            }
        })

    function ApproveSliderController($element, $scope, ToastFactory) {
        var ctrl = this, lineWidth, swiperPosition;

        ctrl.swiperPosition = 0;

        ctrl.$onInit = init;
        ctrl.approve = approve;
        ctrl.reject = reject;

        function init() {
            ctrl.swiperEl = $element.find('.swiper');

            if (!ctrl.isApproved && !ctrl.isRejected) {
                initSwiper();
            }
        }

        function initSwiper() {
            ctrl.swiper = new Hammer(ctrl.swiperEl[0]);
            ctrl.swiper.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL});

            ctrl.swiper.on('panleft', onPan);
            ctrl.swiper.on('panright', onPan);
            ctrl.swiper.on('panend', onPanend);
            lineWidth = ($element.find('.line').width() / 2) - (ctrl.swiperEl.width() / 2) + 1
        }

        function onPan(e) {
            $scope.$apply(function () {
                ctrl.isDragging = true
            })
            swiperPosition = e.deltaX;
            ctrl.swiperEl.css('transform', 'translateX(' + swiperPosition + 'px)');

            if (e.deltaX >= lineWidth) {
                ctrl.swiperEl.css('transform', 'translateX(' + lineWidth + 'px)');
                ctrl.swiper.destroy()
                approve();
            } else if (e.deltaX <= -lineWidth) {
                ctrl.swiperEl.css('transform', 'translateX(' + -lineWidth + 'px)');
                ctrl.swiper.destroy()
                reject();
            }
        }

        function onPanend(e) {
            $scope.$apply(function () {
                ctrl.isDragging = false
            })
            swiperPosition = 0;
            ctrl.swiperEl.css('transform', 'translateX(' + swiperPosition + 'px)');
        }

        function approve() {
            $scope.$apply(function () {
                ctrl.isDragging = false
                ctrl.isApproved = true;
                ctrl.onApprove && ctrl.onApprove()
                    .catch(resetOnError)
            })
        }

        function reject() {
            $scope.$apply(function () {
                ctrl.isDragging = false
                ctrl.isRejected = true;
                ctrl.onReject && ctrl.onReject()
                    .catch(resetOnError)
            })
        }

        function resetOnError(error) {
            initSwiper();
            ctrl.isApproved = false;
            ctrl.isRejected = false;
            ctrl.swiperEl.css('transform', 'translateX(' + 0 + 'px)');
            error && error.statusText && ToastFactory.error(error.statusText)
        }
    }
})();