(function () {
    'use strict';
    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('reminders', {
                        parent: 'base',
                        url: '/reminders',
                        template: '<reminders reminders="ctrl.reminders" class="view-page"></reminders>',
                        resolve: {
                            reminders: ['RemindersService', function (RemindersService) {
                                return RemindersService.getReminders()
                            }]
                        },
                        controller: ['$stateParams', '$filter', 'Page', 'reminders', RemindersController],
                        controllerAs: 'ctrl'
                    });
            }])

    function RemindersController($stateParams, $filter, Page, reminders) {
        var ctrl = this;

        ctrl.reminders = reminders;

        if ($stateParams.backState) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
        } else {
            Page.showBackButton('home');
        }

        Page.setTitle($filter('translate')('REMINDERS.REMINDERS'));
    }
})();