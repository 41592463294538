(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider) {
            $stateProvider
                .state('scormcloud', {
                    parent: 'base',
                    url: '/scormcloud/:token/:extra',
                    templateUrl: '/Scripts/Components/ScormCloud/ScormCloud.html',
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        scormCloudData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                if ($stateParams.extra !== undefined && $stateParams.extra !== null && $stateParams.extra.length > 0) {
                                    return $http.get('/ScormCloud/GetCourse/' + $stateParams.extra);
                                } else {
                                    return $http.get('/ScormCloud/GetList/' + $stateParams.token);
                                }
                            }
                            return;
                        }]
                    },
                    controller: 'ScormCloudController as ctrl'
                });
        }
    ]);


    app.controller('ScormCloudController', ['$rootScope', '$scope', 'Page', '$stateParams', 'scormCloudData', 'events',
        function ScormCloudController($rootScope, $scope, Page, $stateParams, scormCloudData, events) {
            var ctrl = this;

            // Set title
            $scope.title = scormCloudData.data.PageTitle;
            $scope.menuTitle = scormCloudData.data.MenuTitle;
            Page.setTitle($scope.title);

            // Get settings/data
            ctrl.courses = scormCloudData.data.Courses;

            // Manage the back state
            Page.showBackButton('home');

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateReload();
            });
        }
    ]);
})();