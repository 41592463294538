(function () {
    'use strict';

    angular.module('App')
        .component('campaignModule', {
            template: require('./CampaignModuleComponent.html'),
            controllerAs: 'ctrl',
            controller: ['Page', '$rootScope', '$timeout', CampaignModuleComponentController],
            bindings: {
                campaigns: '<',
                moduleToken: '<'
            }
        });

    function CampaignModuleComponentController(Page, $rootScope, $timeout) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.getCampaignBackground = getCampaignBackground;
        ctrl.openCampaign = openCampaign;

        function init() {
            $timeout(() => {
                ctrl.isLoaded = true;
            }, 250);
        }

        function getCampaignBackground(campaign) {
            if (campaign.FeatureMedia) {
                const background = campaign.FeatureMedia.ImageFormats['W' + $rootScope.imageWidth]
                return {
                    backgroundImage: 'url(' + background + ')'
                };
            }

            return null;
        }

        function openCampaign(campaign) {
            Page.stateGo('campaign', ctrl.moduleToken, campaign.CampaignToken, {
                state: 'campaignmodule',
                extra: campaign.CampaignToken,
                token: ctrl.moduleToken
            }, campaign);
        }
    }
})();
