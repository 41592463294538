(function () {
    'use strict';

    angular
        .module('App')
        .component('eventUpdate', {
            template: require('./EventUpdateComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', EventUpdateController],
            bindings: {
                eventUpdate: '<',
                onShowOptions: '<',
                isEditable: '<',
                onRender: '<',
                showOriginal: '<'
            }
        });

    function EventUpdateController($timeout) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.showOptions = ctrl.onShowOptions;
        ctrl.readMoreLength = 100;

        function init() {
            $timeout(() => {
                ctrl.onRender && ctrl.onRender();
            });
        }

    }
})();