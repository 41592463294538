(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlAccordionItem', {
            template: require('./AccordionItemComponent.html'),
            controllerAs: 'ctrl',
            transclude: {
                'header': 'headerSlot',
                'body': 'bodySlot',
            },
        })
})();