(() => {
    'use strict'

    angular
        .module('App')
        .component('departmentSwapSelector', {
            template: require('./DepartmentSwapSelectorComponent.tpl.html'),
            controller: [DepartmentSwapSelectorComponentController],
            controllerAs: 'ctrl',
            bindings: {
                departments: '<',
                isLoading: '<'
            }
        });

    function DepartmentSwapSelectorComponentController() { }
})();