(() => {
    'use strict';

    angular
        .module('App')
        .component('multiplePermissionSelector', {
            template: require('./MultiplePermissonSelectorComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: [MultiplePermissionSelectorController],
            bindings: {
                selectedPermissions: '<',
                departments: '<',
                userGroups: '<',
                openPermissionSelectorPopup: '&',
                onDelete: '&',
                isMultiple: '<'
            }
        });

    function MultiplePermissionSelectorController() {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            ctrl.isEdited = false;
            ctrl.Departments = [];
            ctrl.UserGroups = [];

            if (ctrl.selectedPermissions?.DepartmentIds?.length > 0) {
                ctrl.Departments = ctrl.selectedPermissions.DepartmentIds.map(departmentId =>
                    ctrl.departments.find(department => department.DepartmentId === departmentId));
            }

            if (ctrl.selectedPermissions?.UserGroupIds?.length > 0) {
                ctrl.UserGroups = ctrl.selectedPermissions.UserGroupIds.map(RoleId => ctrl.userGroups.find(group => group.RoleId === RoleId));
            }

            ctrl.selectedElements = [...ctrl.Departments, ...ctrl.UserGroups];
        }
    }
})();
