(() => {
    'use strict';

    angular
        .module('App')
        .component('userProfilePopup', {
            template: require('./UserProfilePopupComponent.html'),
            controller: ['$element', '$http', '$filter', '$rootScope','ActionSheetService', 'ToastFactory', 'Profile', 'ConfirmPopupService', 'ReportPopupService', 'Page', UserProfilePopupController],
            controllerAs: 'ctrl',
            bindings: {
                token: '<'
            }
        });


    function UserProfilePopupController($element, $http, $filter, $rootScope, ActionSheetService, ToastFactory, Profile, ConfirmPopupService, ReportPopupService, Page) {
        const ctrl = this;
        let popup;

        ctrl.$onInit = init;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.onClose = onClose;
        ctrl.settings = settings;

        function init() {
            $http.get('/Profile/LoadProfile/' + ctrl.token).then((res) => {
                ctrl.profileData = res;
                ctrl.isCurrentUser = Profile.getProfile().UserToken === ctrl.token;
                ctrl.isLoaded = true;
            });
        }

        function onPopupRegistered(popup) {
            popup.open();
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
        }

        function close() {
            popup && popup.close();
            popup && popup.remove();
            $element.remove();
        }

        function onClose() {
            popup && popup.remove();
            $element.remove();
        }

        function settings(event) {
            const buttons = [];
            if (Page.getSettings()?.ContentReportingEnabled) {
                buttons.push({
                    text: 'REPORT.TITLE',
                    icon: 'exclamation-circle',
                    onClick: () => reportProfile()
                })
            }
            
            if (ctrl.profileData.data.IsBlocked) {
                buttons.push({
                    text: 'PROFILE.BLOCK.UNBLOCK_USER',
                    icon: 'user',
                    onClick: () => unblockUser()
                });
            } else {
                buttons.push({
                    text: 'PROFILE.BLOCK.BLOCK_USER',
                    icon: 'user-remove',
                    onClick: () => blockUser()
                });
            }

            const actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {name: 'user-profile'});
            actionSheet.show();
        }


        function onReportSubmit(model) {
            return ReportPopupService.reportProfile({
                token: ctrl.token,
                model,
            })
        }

        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportProfile() {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, ctrl.token, 'userProfile');
        }

        function unblockUser() {
            $http.post(`/Profile/UnblockUser/${ctrl.token}`)
                .then(() => {
                    ctrl.profileData.data.IsBlocked = false;
                    ToastFactory.successTranslated('PROFILE.BLOCK.UNBLOCK_USER_DONE');
                    $rootScope.$broadcast('CHAT.BLOCKED_USERS_UPDATE', ctrl.token, false);
                });
        }

        function blockUser() {
            ConfirmPopupService
                .open({
                    title: $filter('translate')('PROFILE.BLOCK.BLOCK') + ' ' + ctrl.profileData.data.Name + '?',
                    message: 'PROFILE.BLOCK.BLOCK_CONFIRMATION_DESCRIPTION',
                    yesText: 'PROFILE.BLOCK.BLOCK',
                    noText: 'CANCEL'
                }).then(() => $http.post(`/Profile/BlockUser/${ctrl.token}`).then(() => {
                ctrl.profileData.data.IsBlocked = true;
                ToastFactory.successTranslated('PROFILE.BLOCK.BLOCK_USER_DONE');
                $rootScope.$broadcast('CHAT.BLOCKED_USERS_UPDATE', ctrl.token, true);
            }));
        }
    }
})();


