(() => {
    'use strict';
    
    angular
        .module('App')
        .directive('mediaThumbnail', ['$sce', '$timeout', '$rootScope', '$parse', 'AttachmentsViewerService',
            mediaThumbnail]);

    function mediaThumbnail($sce, $timeout, $rootScope, $parse, AttachmentsViewerService) {
        return {
            restrict: 'E',
            template: require('./Templates/MediaThumbnail.tpl.html'),
            scope: {
                item: '=',
                background: '@',
                onRemove: '&',
                preview: '@'
            },
            link: function (scope, element, attrs) {
                if (scope.background === undefined || scope.background == 'false') {
                    scope.background = false;
                } else {
                    scope.background = true;
                }

                scope.removeEnabled = (attrs.onRemove);
                setImage(scope.item);

                scope.$watch('item', function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        setImage(newVal);
                    }
                });

                function setImage(item) {
                    switch (item.MediaType) {
                        case 1:
                            scope.imageUrl = scope.item.ImageFormats['W' + $rootScope.imageWidth];
                            break;
                        case 2:
                        case 3:
                            scope.imageUrl = scope.item.ThumbnailUrl;
                            break;
                    }
                }

                function remove($event) {
                    $event.stopPropagation();
                    if (scope.removeEnabled) {
                        $parse(scope.onRemove());
                    }
                }

                function previewThumb() {
                    if (scope.preview && scope.preview.toLowerCase() === 'true') {
                        AttachmentsViewerService.openViewerWithMedia([scope.item]);
                    }
                }

                // Init
                InitDirective();

                function InitDirective() {
                    // Set Media Width
                    scope.imageSettings = {
                        offset: 500,
                        background: scope.background
                    };

                    if (scope.item) {
                        // Get the url
                        setImage(scope.item);
                    }
                }

                // Methods

                // Bindings
                scope.remove = remove;
                scope.previewThumb = previewThumb;
            }
        }
    }
})();