(() => {
    'use strict';

    angular
        .module('App')
        .component('iconPlus', {
            template: require('./IconPlusComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                onClick: '<',
                label: '@'
            }
        });
})();