(function () {
    'use strict';

    angular.module('App')
        .factory('TaskManagementCommentsPopupService',
            ['$http', 'PopupWrapperService', TaskManagementCommentsPopupService]);

    function TaskManagementCommentsPopupService($http, PopupWrapperService) {

        return {
            openCommentsPopup: openCommentsPopup,
        };


        function openCommentsPopup(issue, settings) {
            PopupWrapperService.createDynamic(
                `<task-management-comments-popup issue="issue" settings="settings"></task-management-comments-popup>`,
                {
                    issue: issue,
                    settings: settings
                });
        }

    }
})();