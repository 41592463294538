(() => {
    'use strict';

    angular
        .module('App')
        .component('contentTotalSwipesPopup', {
            template: require('./ContentTotalSwipesPopupComponent.html'),
            controller: ['$scope', '$element', '$http', ContentTotalSwipesPopupController],
            controllerAs: 'ctrl',
            bindings: {
                headerTitle: '<',
                token: '<',
                type: '<',
                moduleToken: '<'
            }
        });


    function ContentTotalSwipesPopupController($scope, $element, $http) {
        const ctrl = this,
            URL_BY_TYPE = {
                'swipes': '/ContentModule/GetPostSwipes/',
                'views': '/ContentModule/GetPostViews/'
            };
        let sourceUsers;

        ctrl.initialLoading = true;

        ctrl.$onInit = init;
        ctrl.onClose = onClose;
        ctrl.filterUsers = filterUsers;
        ctrl.registeredCallback = registeredCallback;

        function init() {
            ctrl.title = ctrl.headerTitle;
            ctrl.tabs = getTabs();

            getReadUsers(ctrl.token).then(data => {
                sourceUsers = data.Items;
                if (!sourceUsers) {
                    ctrl.noUsersLoaded = true;
                }
                ctrl.users = sourceUsers
                calculateCounts();
                ctrl.initialLoading = false;
            });
        }

        function getTabs() {
            return [
                {
                    active: true,
                    type: 'all',
                    title: 'CONTENT.VIEWS_ALL'
                },
                {
                    active: false,
                    type: 'read',
                    title: ctrl.type === 'views' ? 'CONTENT.SEEN_BY' : 'CONTENT.SWIPED'
                },
                {
                    active: false,
                    type: 'unread',
                    title: ctrl.type === 'views' ? 'CONTENT.NOT_SEEN_BY' : 'CONTENT.NOT_SWIPED'
                }
            ]
        }

        function getReadUsers(token) {
            const url = URL_BY_TYPE[ctrl.type] + token +
                (ctrl.moduleToken ? `?AccountModuleToken=${ctrl.moduleToken}` : '')

            return $http.post(url)
                .then(resp => resp.data)
                .finally(() => {
                    ctrl.isLoading = false;
                });
        }

        function onClose(data, popup) {
            $scope.$parent.$destroy();
            popup.remove();
            $element.remove();
        }

        function filterUsers(tab) {
            $element.find('.scroll-container').scrollTop(0);

            ctrl.tabs.forEach(tab => {
                tab.active = false;
            })
            tab.active = true;

            if (tab.type === 'read') {
                ctrl.users = sourceUsers.filter(user => user.DateCreated);
            } else if (tab.type === 'unread') {
                ctrl.users = sourceUsers
                    .filter(user => !user.DateCreated)
                    .sort((a, b) => moment(b.DateCreated).valueOf() - moment(a.DateCreated).valueOf());
            } else {
                ctrl.users = sourceUsers;
            }
        }

        function calculateCounts() {
            ctrl.tabs[0].count = sourceUsers.length;
            ctrl.tabs[1].count = sourceUsers.filter(user => user.DateCreated).length;
            ctrl.tabs[2].count = sourceUsers.filter(user => !user.DateCreated).length;
        }

        function registeredCallback(popup) {
            popup.open();
        }
    }
})();


