(() => {
    'use strict';

    angular
        .module('App')
        .factory('ContentSafetyWarningService', ['ActionSheetCustomService', ContentSafetyWarningService]);

    function ContentSafetyWarningService(ActionSheetCustomService) {

        return {
            openPopup: openPopup
        }

        function openPopup(level, action, editLabel, onContinue) {
            const isBlocked = action === 1,
                template =
                    `<content-safety-warning-popup 
                    severity-level="level" 
                    is-blocked="isBlocked"
                    on-continue="onContinue"
                    edit-label="editLabel"
                ></content-safety-warning-popup>`;

            ActionSheetCustomService.create(template, {level, isBlocked, editLabel, onContinue});
        }
    }
})();