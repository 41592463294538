(function () {
    'use strict';

    angular.module('App')
        .factory('ParticipantsService', ['PopupWrapperService', function (PopupWrapperService) {
            return {
                openParticipantsPopup: openParticipantsPopup
            };

            function openParticipantsPopup(participants, moduleToken) {
                PopupWrapperService
                    .createDynamic('<participants-popup participants="participants" module-token="moduleToken"></participants-popup>',
                        {
                            participants: participants,
                            moduleToken: moduleToken
                        })
            }
        }]);
})();