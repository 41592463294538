(function () {
    'use strict';

    angular
        .module('App')
        .component('participantsPopup', {
            template: require('./ParticipantsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', ParticipantsPopupController],
            bindings: {
                participants: '<'
            }
        })

    function ParticipantsPopupController($element) {
        var ctrl = this, popup;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.close = close;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            popup.remove();
            $element.remove();
        }
    }
})();