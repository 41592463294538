(function () {
    'use strict';

    angular.module('App')
        .factory('TaskDetailsService', ['$filter', '$rootScope', 'CreateUpdateTaskService', 'Page',
            'ConfirmPopupService', 'ISSUE_STATUS', 'PopupWrapperService', 'ToastFactory', 'ReportPopupService',
            TaskDetailsService]);

    function TaskDetailsService($filter, $rootScope, CreateUpdateTaskService, Page, ConfirmPopupService,
        ISSUE_STATUS, PopupWrapperService, ToastFactory, ReportPopupService) {


        return {
            prepareDueSoonStatus: prepareDueSoonStatus,
            prepareIssueStatus: prepareIssueStatus,
            prepareOptionsButtons: prepareOptionsButtons,
            getStatusClass: getStatusClass,
            openPopup: openPopup,
            isTaskTranslated: isTaskTranslated,
            isContentReportingEnabled: isContentReportingEnabled,
        };

        function prepareIssueStatus(issue) {
            let issueStatus = $filter('translate')('TASK_MANAGEMENT.PREVIEW.IN_PROGRESS');

            if (issue.Status === ISSUE_STATUS.DUE_SOON) {
                issueStatus = prepareDueSoonStatus(issue);
            }
            if (issue.Status === ISSUE_STATUS.OVERDUE) {
                issueStatus = $filter('translate')('TASK_MANAGEMENT.PREVIEW.OVERDUE');
            }
            if (issue.Status === ISSUE_STATUS.COMPLETE) {
                issueStatus = $filter('translate')('TASK_MANAGEMENT.PREVIEW.COMPLETED');
            }

            return issueStatus;
        }

        function prepareDueSoonStatus(issue) {
            var issueStatus = $filter('translate')('TASK_MANAGEMENT.PREVIEW.DUE_SOON') + ': ';

            if (issue.DueHoursLeft === 0) {
                issueStatus += $filter('translate')('TASK_MANAGEMENT.PREVIEW.LESS_THAN_ONE_HOUR_LEFT');
            } else if (issue.DueHoursLeft < 0) {
                issueStatus = $filter('translate')('TASK_MANAGEMENT.DEADLINE') + ': ' + issue.DueText;
            } else {
                issueStatus +=
                    (issue.DueHoursLeft >= 24 ? Math.floor(issue.DueHoursLeft / 24) : issue.DueHoursLeft)
                    + ' '
                    + $filter('translate')(
                        issue.DueHoursLeft >= 24
                            ? 'TASK_MANAGEMENT.PREVIEW.DUE_SOON_DAYS_LEFT'
                            : 'TASK_MANAGEMENT.PREVIEW.DUE_SOON_HOURS_LEFT'
                    );
            }

            return issueStatus;
        }

        function prepareOptionsButtons(issue, closeOnDelete, closePopup) {
            var buttons = [];
            // Edit
            if (issue?.CanEdit) {
                buttons.push({
                    text: 'TASK_MANAGEMENT.TASK.EDIT_TASK',
                    translate: true,
                    icon: 'edit',
                    onClick: function () {
                        var data = new Object();
                        data.isParentIssue = false;
                        if (issue.IsRecurringChild) {
                            var issueToken;
                            ConfirmPopupService.open({
                                message: $filter('translate')('TASK_MANAGEMENT.RECURRING.SERIES_QUESTION'),
                                yesText: $filter('translate')('TASK_MANAGEMENT.RECURRING.ONE'),
                                noText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SERIES')
                            }).then(
                                function () {
                                    issueToken = issue.IssueToken;
                                }, function () {
                                    data.isParentIssue = true;
                                    issueToken = issue.ParentIssueToken;
                                }
                            ).finally(function () {
                                data.token = issueToken;
                                CreateUpdateTaskService.openCreateUpdatePopup(data);
                            });
                        } else {
                            data.token = issue.IssueToken;
                            CreateUpdateTaskService.openCreateUpdatePopup(data);
                        }
                    }
                });
            }
            
            if (isContentReportingEnabled()) {
                buttons.push({
                    text: 'REPORT.TITLE',
                    icon: 'exclamation-circle',
                    onClick: () => reportTask(issue.IssueToken)
                })
            }
            
            if (issue?.CanDelete) {
                buttons.push({
                    text: 'TASK_MANAGEMENT.TASK.DELETE_TASK',
                    translate: true,
                    iconClass: 'red',
                    icon: 'delete',
                    onClick: function () {
                        CreateUpdateTaskService.deleteTask(issue).then(function (response) {
                            ToastFactory.successTranslated('TASK_MANAGEMENT.TASK.DELETED');
                            closePopup && closePopup();
                            if (closeOnDelete) {
                                Page.stateGoBack('notifications');
                            }
                            CreateUpdateTaskService.updateNavigationBadges(response.data.BadgeCount, response.data.NavigationBadge);
                        });
                    }
                })
            }
            return buttons;
        }
        
        function isContentReportingEnabled() {
            return Page.getSettings()?.ContentReportingEnabled;
        }

        function onReportSubmit(model, token) {
            return ReportPopupService.reportTask({
                token,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportTask(token) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, token);
        }

        function getStatusClass(issue) {
            return {
                'in-progress': issue.Status === ISSUE_STATUS.IN_PROGRESS,
                'due-soon': issue.Status === ISSUE_STATUS.DUE_SOON,
                'completed': issue.Status === ISSUE_STATUS.COMPLETE,
                'overdue': issue.Status === ISSUE_STATUS.OVERDUE,
            }
        }

        function openPopup(token) {
            PopupWrapperService
                .createDynamic('<task-details-popup token="issueToken"></task-details-popup>',
                    {
                        issueToken: token
                    }
                );
        }

        function isTaskTranslated(issue) {
            return !issue.TranslatableEntity.AllowAutoTranslation &&
                isTranslated(issue.Categories) &&
                isTranslated(issue.Subtasks)

            function isTranslated(items) {
                return items.every(item => !item.TranslatableEntity.AllowAutoTranslation);
            }
        }
    }
})();