(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('backgroundColor', function () {
        return function (scope, element, attrs) {
            attrs.$observe('backgroundColor', function (value) {
                if(value.length > 0){
                    element.css({ 'background-color': value });
                }
            });
        };
    });
})();