(() => {
    'use strict';

    angular
        .module('App')
        .component('exploreView', {
            template: require('./ExploreViewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', 'CalendarRenderService', ExploreViewController],
            bindings: {}
        })

    function ExploreViewController($rootScope, CalendarRenderService) {
        const ctrl = this;
        let removeFilterWatcher, removeEventUpdatedWatcher, removeEventCreatedWatcher;

        ctrl.$onInit = init;
        ctrl.paging = paging;
        ctrl.$onDestroy = destroy;

        function init() {
            loadEvents();
            removeFilterWatcher = $rootScope.$on('filter-events', loadEvents)
            removeEventCreatedWatcher = $rootScope.$on('event-created', loadEvents)
            removeEventUpdatedWatcher = $rootScope.$on('event-updated', updateEvent)
        }

        function loadEvents() {
            ctrl.isLoading = true;
            CalendarRenderService.getCalendarEvents().then(({events, lastEventId}) => {
                ctrl.lastEventId = lastEventId;
                ctrl.isLoading = false;
                ctrl.exploreEvents = events;
            })
        }

        function paging() {
            ctrl.layzyLoading = true;
            CalendarRenderService.getCalendarEvents({
                AfterEventId: ctrl.lastEventId
            }).then(({events, lastEventId}) => {
                ctrl.lastEventId = lastEventId;
                ctrl.layzyLoading = false;
                ctrl.exploreEvents = [...ctrl.exploreEvents, ...events];
            })
        }

        function updateEvent(_, id) {
            const indexToUpdate = ctrl.exploreEvents.findIndex(event => event.CalendarEventId === id);
            if (indexToUpdate !== -1) {
                const updatedEvent = CalendarRenderService.getEvent(id)
                if (updatedEvent) {
                    ctrl.exploreEvents[indexToUpdate] = updatedEvent;
                } else {
                    ctrl.exploreEvents.splice(indexToUpdate, 1);
                }
            }
        }

        function destroy() {
            removeEventCreatedWatcher && removeEventCreatedWatcher();
            removeFilterWatcher && removeFilterWatcher();
            removeEventUpdatedWatcher && removeEventUpdatedWatcher();
        }
    }
})();