(function () {
    'use strict';

    angular.module('App')
        .component('userPortraits', {
            templateUrl: '/Scripts/Components/UserPortraits/UserPortraitsComponent.html',
            controllerAs: 'ctrl',
            bindings: {
                users: '<'
            }
        })
})();