(function () {
    'use strict';

    angular.module('App')
        .factory('StagesService', ['PopupWrapperService', function (PopupWrapperService) {
            return {
                openStagesPopup: openStagesPopup
            };

            function openStagesPopup(data) {
                PopupWrapperService
                    .createDynamic('<stages-popup stages="stages" on-close="onClose(latestFilling)"></stages-popup>',
                        {
                            stages: _.cloneDeep(data.stages),
                            onClose: data.onClose
                        });
            }
        }]);
})();