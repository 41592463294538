(function () {
  'use strict';
  var app = angular.module('App');

  app.factory('ProfileNavigationFactory', ['$rootScope', 'Profile', 'Page',
    function ($rootScope, Profile, Page) {
      var navigationOpen = false;

      // Functions
      function close() {
        navigationOpen = false;
      }
      function open() {
        navigationOpen = true;
      }

      // Return Object
      var f = {
        isVisible: function () {
          return navigationOpen;
        },
        toggleNavigation: function () {
          if (!navigationOpen) {
            f.openNavigation();
          } else {
            f.closeNavigation();
          }
        },
        closeNavigation: function () {
          $rootScope.safeApply(function () {
            close();
          })
        },
        openNavigation: function () {
          $rootScope.safeApply(function () {
            // Only continue if enabled
            if (f.isEnabled()) {
              // Refresh Profile Info
              Profile.loadProfile();

              open();
            } else {
              // Load normal profile page
              Page.stateGo('profileOverview');
            }
          })
        },
        isEnabled: function () {
          var settings = Page.getSettings();
          if (settings) {
            return settings.Base.ProfileNavigationEnabled;
          }
          return false;
        }
      };

      return f;
    }
  ]);
})();