(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$httpProvider', 'toastrConfig', 'cfpLoadingBarProvider', '$translateProvider', 'msdElasticConfig', '$ariaProvider', '$timeLocaleProvider',
        function ($httpProvider, toastrConfig, cfpLoadingBarProvider, $translateProvider, msdElasticConfig, $ariaProvider, $timeLocaleProvider) {
            // Make HTTP Calls act as Ajax Call
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            // Create new function to be callable outside of angular context
            app.setDefaultHeader = function (name, value) {
                $httpProvider.defaults.headers.common[name] = value;
            };
            // Allow CORS requests
            $httpProvider.defaults.useXDomain = true;

            // Check if we should add a device token header
            var deviceToken = $('html').data('device-token');
            if (deviceToken) {
                $httpProvider.defaults.headers.common['x-relesys-device-id'] = deviceToken;
            }

            // Add Custom Url Referrer to handle app preview
            var urlReferrer = $("#UrlReferrer").val();
            if (_.isString(urlReferrer) && urlReferrer.length) {
                $httpProvider.defaults.headers.common['CustomUrlReferrer'] = urlReferrer;
            }

            // means that the app is working under iOS WebApp 
            if (window.navigator && window.navigator.standalone) {
                $httpProvider.defaults.headers.common['Standalone'] = window.navigator.standalone;
            }

            // Add cache handling to all calls
            var cacheVar = $('body').data('version');
            $httpProvider.interceptors.push(function () {
                return {
                    request: function (config) {
                        var urlInLowerCase = config.url.toLowerCase(),
                            isStaticContent = (urlInLowerCase.indexOf('.html') > 0 || urlInLowerCase.indexOf('.svg') > 0 || urlInLowerCase.startsWith('/index/base') > 0),
                            blacklistUrls = ['directives/toast/toast.html', 'directives/progressbar/progressbar.html'],
                            separator = config.url.indexOf('?') === -1 ? '?' : '&',
                            isNotInBlackList = _.findIndex(blacklistUrls, function (o) {
                                return o === urlInLowerCase;
                            }) < 0;

                        if (isStaticContent && isNotInBlackList) {
                            config.url = config.url + separator + '_v=' + cacheVar;
                        } else {
                            if (config.method === 'GET' && isNotInBlackList) {
                                config.url = config.url + separator + 'noCache=' + new Date().getTime();
                                config.headers["Cache-Control"] = "no-cache,no-store";
                                config.headers.Pragma = "no-cache";
                                config.headers["If-Modified-Since"] = "Sat, 01 Jan 2000 01:00:00 GMT";
                            }
                        }

                        return config;
                    }
                };
            });

            var mediaLabelsAddress = $('body').data('labels-storage');

            // Translate config
            $translateProvider.useStaticFilesLoader({
                prefix: mediaLabelsAddress,
                suffix: '.json?v=' + cacheVar
            });
            $translateProvider.preferredLanguage('en-GB'); // default
            $translateProvider.fallbackLanguage('en-GB'); // fallback
            $translateProvider.useSanitizeValueStrategy('sceParameters'); // security

            // Get the language of the HTML
            var htmlLanguage = $('html').attr('lang');
            var uiLanguage = $('html').data('i18n');

            $translateProvider.preferredLanguage(htmlLanguage); // change language
            $timeLocaleProvider.setLocale(uiLanguage); // change locale for times/dates

            // Config Toastr
            angular.extend(toastrConfig, {
                autoDismiss: true,
                maxOpened: 1,
                positionClass: 'toast-container'
            });

            // Configure Load Bar
            cfpLoadingBarProvider.spinnerTemplate = '';

            // Disable Cache for all Ajax calls
            $.ajaxSetup({cache: false});

            // Configure MSD Elastic (Autogrow Textarea)
            msdElasticConfig.append = '\n';

            $ariaProvider.config({
                bindKeypress: true,
                bindRoleForClick: false,
                tabindex: false
            });

            // Register FilePond plugins
            FilePond.registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

            // Use CSS Vars globally
            cssVars.default({
                onlyLegacy: true
            });
        }
    ]);
})();