(() => {
    'use strict'

    angular
        .module('Relesys.Ui')
        .component('rlSelectList', {
            template: require('./SelectListComponent.html'),
            controller: ['$scope', SelectListController],
            controllerAs: 'ctrl',
            bindings: {
                list: '<',
                onSelect: '<',
                isMultipleSelect: '<',
                selectedItem: '=?',
                circleCheck: '<'
            }
        });

    function SelectListController($scope) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.select = select;
        ctrl.multiSelect = multiSelect;

        function init() {
            $scope.$watch('ctrl.list', () => {
                ctrl.selectedItem = {...ctrl.list.find(x => x.selected)}
            }, true);
        }

        function select(item) {
            const selectedItem = ctrl.list.find(x => x.selected);
            if (selectedItem) {
                delete selectedItem.selected;
            }
            item.selected = true;
            ctrl.onSelect && ctrl.onSelect(item);
        } 
        
        function multiSelect(item) {
            item.selected = !item.selected;
            ctrl.onSelect && ctrl.onSelect(ctrl.list);
        }
    }
})();