(() => {
    angular.module('App')
        .component('reactionsSummary', {
            template: require('./ReactionsSummary.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', ReactionsSummaryController],
            bindings: {
                reactions: '<',
                hideOne: '<',
                failedReactionId: '<'
            }
        })

    function ReactionsSummaryController($scope) {
        const ctrl = this;

        $scope.$watch('ctrl.reactions', (newValue) => {
            if (newValue) {
                ctrl.filteredRactions = ctrl.reactions.filter(r => r.Count).splice(0, 3);
                ctrl.totalCount = ctrl.reactions.reduce((prev, current) => prev + current.Count, 0);
                if (ctrl.failedReactionId) ctrl.totalCount--; 
            }
        }, true)
    }
})();