(function () {
        'use strict';

        angular
            .module('App')
            .component('selectContentCategory', {
                template: require('./SelectContentCategoryComponent.tpl.html'),
                controllerAs: 'ctrl',
                controller: ['$scope', 'PopupWrapperService', SelectCategory],
                require: {
                    ngModelCtrl: 'ngModel'
                },
                bindings: {
                    categories: '<',
                    categoryToken: '=?'
                }
            });

        function SelectCategory($scope, PopupWrapperService) {
            const ctrl = this;

            $scope.$watch('ctrl.categories', categories => {
                if (categories?.length) {
                    const cat = categories.find(category => category.selected);
                    ctrl.ngModelCtrl.$setViewValue(cat?.ContentCategoryToken);
                }
            }, true);

            ctrl.$onInit = init;
            ctrl.$onDestroy = destroy;
            ctrl.openCategorySelector = openCategorySelector;
            
            function init() {
                ctrl.categories.forEach(category => {
                    category.title = category.Title;
                    
                    if (category.TranslatableEntity) {
                        category.title = selectCurrentLocale(category.TranslatableEntity).Title;
                        category.Summary = selectCurrentLocale(category.TranslatableEntity).Summary;
                    }
                    
                    if (ctrl.categoryToken && category.ContentCategoryToken === ctrl.categoryToken) {
                        category.selected = true;
                    }
                });
            }

            function selectCurrentLocale(translatableEntity) {
                return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                    translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
            }

            function destroy() {
                ctrl.categories.forEach(category => category.selected = false);
            }
            
            function selectCategory(selectedCategory) {
                const category = ctrl.categories.find(category => category.ContentCategoryToken === ctrl.categoryToken);
                selectedCategory.selected = selectedCategory.ContentCategoryToken !== category?.ContentCategoryToken;

                ctrl.categoryToken = ctrl.categories.find(category => category.selected)?.ContentCategoryToken;
            }

            function openCategorySelector() {
                PopupWrapperService.createDynamic('<content-category-selector-popup' +
                    ' categories="categories"' +
                    ' select-category="selectCategory"></content-category-selector-popup>',
                    {
                        categories: ctrl.categories,
                        selectCategory: selectCategory,
                    });
            }
        }
    }
)
();