(function () {
    'use strict';

    angular
        .module('App')
        .component('thumbnailSelector', {
            template: require('./ThumbnailSelector.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', 'PopupWrapperService', '$sce', '$timeout', '$http', 'Page', 'Profile', 'BasicHelper', ThumbnailSelectorController],
            bindings: {
                onUpdated: '<'
            }
        });

    function ThumbnailSelectorController($element, $scope, PopupWrapperService, $sce, $timeout, $http, Page, Profile, BasicHelper) {
        var ctrl = this,
            lastProgress = 0,
            initialProgress = 0;

        ctrl.popupId = 'thumbnailSelectorPopup';
        ctrl.close = close;
        ctrl.onOpen = onOpen;
        ctrl.onReady = onReady;
        ctrl.snapshot = snapshot;
        ctrl.getUrl = getUrl;
        ctrl.getCrossOrigin = getCrossOrigin;

        ctrl.seeking = true;

        function onOpen(data) {
            ctrl.media = data.media;
            ctrl.attachmentId = data.attachmentId;
        }

        function onReady() {
            // assign controls

            $timeout(function () {
                ctrl.container = $element.find('.video-container')[0];
                ctrl.video = $element.find('video')[0];
                ctrl.timeline = $element.find('.timeline')[0];
                ctrl.timelineProgress = $element.find('.timeline-progress')[0];
                ctrl.drag = $element.find('.timeline-drag')[0];

                ctrl.video.addEventListener('seeking', onseeking);
                ctrl.video.addEventListener('seeked', onseeked);
                ctrl.video.addEventListener("loadedmetadata", onloadedmeta, false);
                ctrl.video.addEventListener("loadeddata", onloaded, false);

                onloadedmeta();
            })
        }

        function onPanstart(ev) {
            initialProgress = lastProgress;
        }

        function onPanRight(ev) {
            var progressPercent = getProgress(ev, initialProgress);
            ctrl.drag.style.left = progressPercent + '%';
            ctrl.video.currentTime = ctrl.video.duration * progressPercent / 100;
            ctrl.timelineProgress.style.transform = 'scaleX(' + progressPercent / 100 + ')';
            lastProgress = progressPercent;

            $scope.$apply();
        }

        function onPanLeft(ev) {
            var progressPercent = getProgress(ev, initialProgress);
            ctrl.drag.style.left = progressPercent + '%';
            ctrl.video.currentTime = ctrl.video.duration * progressPercent / 100;
            ctrl.timelineProgress.style.transform = 'scaleX(' + progressPercent / 100 + ')';
            lastProgress = progressPercent;

            $scope.$apply();
        }

        function onPanend(ev) {
            ev.preventDefault();
        }

        function getProgress(ev, initialProgress) {
            var w = ctrl.timeline.offsetWidth;
            var delta = ev.deltaX;
            var progress = (delta * 100 / w) + initialProgress;
            return parseFloat((progress > 100 ? 100 : progress < 0 ? 0 : progress).toFixed(2));
        }

        function capture(video) {
            if (!video) return;

            // fit video into container with width 640px
            var scaleFactor = video.videoWidth > 640 ? Number((640 / video.videoWidth).toFixed(2)) : 1;

            var w = video.videoWidth * scaleFactor;
            var h = video.videoHeight * scaleFactor;

            var canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            return canvas;
        }

        function snapshot() {
            ctrl.seeking = true;
            var settings = Page.getSettings();
            var profile = Profile.getProfile();
            if (BasicHelper.isSafari() || (NativeHelper && NativeHelper.isIOS())) {
                // send time of frame to API

                $http.post(settings.MediaServerDomain + '/Upload/SetThumbnailFrame', {
                    AccountToken: profile.AccountToken,
                    UserToken: profile.UserToken,
                    MediaId: ctrl.media.MediaId,
                    FrameInSeconds: ctrl.video.currentTime
                }).then(function (response) {
                    var files = response.data.Files
                    if (files && files[0]) {
                        ctrl.onUpdated && ctrl.onUpdated(ctrl.attachmentId, files[0]);
                    }
                    ctrl.seeking = false;
                    close();
                }).catch(function (er) {
                    ctrl.seeking = false;
                });
            } else {
                // make picture through canvas
                var canvas = capture(ctrl.video);
                var dataURL = canvas.toDataURL("image/jpeg");
                var base64 = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

                $http.post(settings.MediaServerDomain + '/Upload/SetThumbnail', {
                    AccountToken: profile.AccountToken,
                    UserToken: profile.UserToken,
                    MediaId: ctrl.media.MediaId,
                    Image: base64
                }).then(function (response) {
                    var files = response.data.Files
                    if (files && files[0]) {
                        ctrl.onUpdated && ctrl.onUpdated(ctrl.attachmentId, files[0]);
                    }
                    ctrl.seeking = false;
                    close();
                }).catch(function (er) {
                    ctrl.seeking = false;
                });
            }
        }

        function getUrl() {
            return $sce.trustAsResourceUrl(ctrl.media.UrlEncoded);
        }

        function getCrossOrigin() {
            return ctrl.media.UrlEncoded.includes("media.relesys") ? "use-credentials" : "anonymous";
        }

        function close() {
            PopupWrapperService.getPopup(ctrl.popupId).hide();
            lastProgress = 0;
            initialProgress = 0;
            ctrl.dragHammer && ctrl.dragHammer.destroy();
            ctrl.dragHammer = null;

            ctrl.video && ctrl.video.removeEventListener('seeking', onseeking);
            ctrl.video && ctrl.video.removeEventListener('seeked', onseeked);
            ctrl.video && ctrl.video.removeEventListener('loadedmetadata', onloadedmeta);
            ctrl.video && ctrl.video.removeEventListener('loadeddata', onloaded);
        }

        function onseeking() {
            ctrl.seeking = true;
            $scope.$apply();
        }

        function onseeked() {
            ctrl.seeking = false;
            $scope.$apply();
        }

        function onloadedmeta() {
            if (ctrl.drag && !ctrl.dragHammer) {
                ctrl.dragHammer = new Hammer(ctrl.container);
                ctrl.dragHammer.get('pan').set({direction: Hammer.DIRECTION_ALL});

                ctrl.dragHammer.on('panleft', onPanLeft);
                ctrl.dragHammer.on('panright', onPanRight);
                ctrl.dragHammer.on('panend', onPanend);
                ctrl.dragHammer.on('pancancel', onPanend);
                ctrl.dragHammer.on('panstart', onPanstart);

                ctrl.video.play().then(function () {
                    ctrl.video.pause();
                })
            }
        }

        function onloaded() {
            ctrl.seeking = false;
        }
    }
})();