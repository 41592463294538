(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            //$locationProvider.html5Mode(true);

            $urlRouterProvider.when('', '/');
            $urlRouterProvider.otherwise('/');

            // Default States
            $stateProvider
                .state('error', {
                    url: '/error',
                    templateUrl: '/Error'
                })
                .state('pin', {
                    url: '/pin?SetPinCode&CanSkip&EnterPinCode',
                    template: '<pin-code-page></pin-code-page>',
                    resolve: {
                        settings: ['Page', function (Page) {
                            return Page.loadSettings();
                        }]
                    },
                    redirectToIf: ['$rootScope', function ($rootScope) {
                        return $rootScope.ForcePinSkip ? 'home' : undefined;
                    }]

                })
                .state('login', {
                    url: '/login?path&user',
                    templateUrl: '/Login',
                    resolve: {
                        autoLogin: ['$stateParams', 'LoginFactory', function ($stateParams, LoginFactory) {
                            return LoginFactory.autoLogin($stateParams.user);
                        }],
                        isLoggedIn: ['LoginFactory', function (LoginFactory) {
                            return LoginFactory.isLoggedIn();
                        }],
                        settings: ['Page', function (Page) {
                            return Page.loadSettings();
                        }]
                    },
                    controller: 'LoginController as LoginCtrl'
                })
                .state('logout', {
                    url: '/logout',
                    controller: ['$state', 'LoginFactory', function ($state, LoginFactory) {
                        LoginFactory.logout().then(function () {
                            $state.go('login');
                        });
                    }]
                })
                .state('base', {
                    url: '?user&isPreview',
                    templateUrl: '/Index/Base',
                    resolve: {
                        autoLogin: ['$stateParams', 'LoginFactory', 'AppBaseFactory',
                            function ($stateParams, LoginFactory, AppBaseFactory) {
                                return LoginFactory.autoLogin($stateParams.user, $stateParams.isPreview)
                                    .then(AppBaseFactory.loadBase).then(resp => {
                                        return resp
                                    });
                            }],
                        base: () => true
                    },
                    controller: 'BaseController as BaseCtrl'
                })
                .state('empty', {
                    url: '/empty',
                    parent: 'base',
                    templateUrl: '/Index/Empty',
                    controller: 'EmptyController as EmptyCtrl'
                });

            // Home
            $stateProvider
                .state('home', {
                    url: '/',
                    params: {
                        data: {}
                    },
                    template: require('../templates/home.tpl.html'),
                    parent: 'base',
                    controller: 'HomeController as HomeCtrl'
                });

            // Profile
            $stateProvider
                .state('profile', {
                    url: '/profile?prevState',
                    redirectTo: 'profileOverview'
                });

            $httpProvider.interceptors.push('AuthHttpResponseInterceptor');
        }
    ]);

})();