(() => {
        'use strict';

        angular.module('App')
            .component('profileSettingsSecurityMfa', {
                template: require('./ProfileSettingsSecurityMfaComponent.tpl.html'),
                controller: ['ProfileSettingsService', 'Page', ProfileSettingsSecurityMfaComponentController],
                controllerAs: 'ctrl'
            });

        function ProfileSettingsSecurityMfaComponentController(ProfileSettingsService, Page) {
            var ctrl = this;

            ctrl.$onInit = onInit;

            function onInit() {
                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile(true).then(res => {

                    ctrl.profile = res;
                    ctrl.mfaEnabled = res.MfaEnabled;

                    if (!ctrl.mfaEnabled) {
                        Page.triggerBackButton();
                    }
                    
                    ctrl.mfaIsConfigured = res.MfaIsConfigured;
                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            }
        }
    }
)();