(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('timeAgoTimeZone', ['TimeLocale',
        function (TimeLocale) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var updateTimestamp = function(dateCreatedUtc) {
                        var now = moment().utc();
                        var diffInDays = now.diff(dateCreatedUtc, 'days');
                        var sameDay = now.isSame(dateCreatedUtc, 'day');

                        if (sameDay) {
                            $(element).html(dateCreatedUtc.local().format('HH:mm'));
                        } else if (diffInDays < 7) {
                            $(element).html(dateCreatedUtc.local().format('ddd'));
                        } else {
                            $(element).html(dateCreatedUtc.local().format('DD/MM'));
                        }
                    };
                    
                    scope.$watch(attrs.timeAgoTimeZone, function(newVal) {
                        if (newVal) {
                            var dateCreatedUtc = moment(newVal);
                            updateTimestamp(dateCreatedUtc);
                        }
                    });
                }
            };
        }
    ]);
})();