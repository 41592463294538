(() => {
    'use strict';

    angular
        .module('App')
        .component('filterPopup', {
            template: require('./FilterPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'CalendarRenderService', 'FilterPopupService', FilterPopupController],
            bindings: {
                isCalendar: '<'
            }
        });

    function FilterPopupController($element, CalendarRenderService, FilterPopupService) {
        const ctrl = this;
        let popup;

        ctrl.$onInit = init;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.applyFilters = applyFilters;
        ctrl.resetFilters = resetFilters;
        ctrl.getActiveFiltersCount = getActiveFiltersCount;
        ctrl.close = close;

        function init() {
            ctrl.filter = CalendarRenderService.getFilters().savedFilters || getInitModel();
            ctrl.categories = CalendarRenderService.getCategories().map(category => ({
                ...category,
                isSelected: ctrl.filter?.CategoryIds?.find(id => id === category.CalendarCategoryId)
            }));
            ctrl.sortingOptions = FilterPopupService.getSortingOptions();
            ctrl.sortingOptions[0].selected = true;
        }

        function getInitModel() {
            return {
                SortType: FilterPopupService.getSortingOptions()[0]
            }
        }

        function getActiveFiltersCount() {
            return Object.keys(ctrl.filter).length && Object.keys(ctrl.filter)
                .map(key => {
                    switch (key) {
                        case 'SortType':
                            return 0
                        case 'CategoryIds':
                            return ctrl.filter[key].length ? 1 : 0
                        default:
                            return ctrl.filter[key] ? 1 : 0
                    }
                }).reduce((prev, curr) => prev + curr)
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function applyFilters() {
            CalendarRenderService.filterEvents(ctrl.filter, getActiveFiltersCount());
            close();
        }

        function resetFilters() {
            ctrl.filter = getInitModel();
            ctrl.resetCategories && ctrl.resetCategories();
        }

        function close() {
            resetFilters();
            popup.remove();
            $element.remove();
        }
    }
})();