(function () {
    'use strict';

    angular
        .module('App')
        .component('contentSharesPopup', {
            template: require('./ContentSharesPopupComponent.html'),
            controller: ['$http', '$timeout', '$element', ContentSharesPopupController],
            controllerAs: 'ctrl',
            bindings: {
                moduleToken: '<',
                moduleItemId: '<'
            }
        });

    function ContentSharesPopupController($http, $timeout, $element) {
        var ctrl = this, popup;

        ctrl.registeredCallback = registeredCallback;
        ctrl.onClose = onClose;
        ctrl.filterUsers = filterUsers;

        function registeredCallback(popupCtrl) {
            popup = popupCtrl;
            popup.open();

            ctrl.loading = true;

            $http.get('/SocialMediaSharing/GetUsers?accountModuleToken=' + ctrl.moduleToken
                + '&moduleItemId=' + ctrl.moduleItemId)
                .then(function (resp) {
                    ctrl.users = resp.data;
                    ctrl.usersGroups = _.groupBy(ctrl.users, 'SocialMediaTypeId');
                    ctrl.usersGroupsLength = Object.keys(ctrl.usersGroups).length;

                    if (ctrl.usersGroupsLength > 1) {
                        filterUsers(0);
                    } else {
                        filterUsers(Object.keys(ctrl.usersGroups)[0])
                    }

                    $timeout(function () {
                        ctrl.loading = false;
                    })
                });
        }

        function onClose() {
            popup.remove();
            $element.remove();
        }

        function filterUsers(SocialMediaTypeId) {
            SocialMediaTypeId = parseInt(SocialMediaTypeId);
            ctrl.activeSocialMediaTypeId = SocialMediaTypeId;
            ctrl.filteredUsers = SocialMediaTypeId !== 0
                ? _.filter(ctrl.users, {SocialMediaTypeId: SocialMediaTypeId})
                : ctrl.users;
        }
    }
})();


