(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('actionPopup', {
            template: require('./ActionPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', ActionPopupController],
            transclude: true,
            bindings: {
                close: '=?'
            }
        })

    function ActionPopupController($timeout, $element) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.close = close;

        function init() {
            $timeout(function () {
                ctrl.show = true;
            })
        }

        function close() {
            ctrl.show = true;
            $timeout(function () {
                $element.remove()
            }, 300)
        }
    }
})();