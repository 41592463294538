(() => {
    'use strict';

    angular
        .module('App.Elements', [])
        .run();
})();

require([
    './Elements/Autocomplete/AutocompleteComponent',
    './Elements/DepartmentSelector/DepartmentSelector',
    './Elements/Loading/Loading.directive',
    './Elements/Loading/Loading.factory',
    './Elements/UserGroupSelector/UserGroupSelector',
    './Elements/ValidationMessage/ValidationMessage',
])