(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('tab', ['$parse',
        function ($parse) {
            return {
                restrict: 'E',
                transclude: true,
                template: '<div class="tab" roles="tabpanel" ng-hide="!active" ng-transclude></div>',
                require: '^tabset',
                scope: {
                    heading: '@',
                    image: '@',
                    icon: '@',
                    onSelect: '&'
                },
                link: function (scope, elem, attrs, tabsetCtrl) {

                    // Init Directive
                    InitDirective();
                    function InitDirective() {
                        scope.tab = scope;
                        scope.active = false;
                        tabsetCtrl.addTab(scope);
                    }

                    // Methods
                    function Select() {
                        scope.onSelect();
                    }

                    // Bind to scope
                    scope.select = Select;
                }
            };
        }
    ]);
})();