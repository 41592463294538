(() => {
    'use strict';

    angular
        .module('App')
        .component('createPinBiometricPopup', {
            template: require('./CreatePinBiometricPopupComponent.html'),
            controller: ['$filter', 'PinCodeService', 'Profile', CreatePinBiometricPopupComponentController],
            controllerAs: 'ctrl'
        });

    function CreatePinBiometricPopupComponentController($filter, PinCodeService, Profile) {
        var ctrl = this, popup;

        ctrl.popupId = 'createPinBiometricPopupId';
        ctrl.popupTitle = $filter('translate')('PIN.BIOMETRIC.SETUP.ENABLE_HEADLINE');
        
        ctrl.showCompletion = false;
        ctrl.showKeyboard = true;
        ctrl.showButtons = false;
        ctrl.isVerifyingPin = false;
        ctrl.isCompleted = false;
        ctrl.cancelledPinCode = null;

        ctrl.pinTitle = 'PIN.ENTER_PIN';
        ctrl.profile = Profile.getProfile();

        ctrl.submitCode = submitCode;
        ctrl.tryAgain = tryAgain;

        ctrl.onPopupOpen = onPopupOpen;
        ctrl.onPopupClose = onPopupClose;

        ctrl.onClose = null;

        function submitCode(pin) {
            if(ctrl.isVerifyingPin){
                return;
            }

            const pinSanitized = pin.join('');
            ctrl.isVerifyingPin = true;
            ctrl.pinTitle = 'PIN.SUBMITTING';

            PinCodeService.verifyPinCode(pinSanitized).then((res) => {
                if (res.response.data.success) {
                    saveBiometric(pinSanitized);
                } else {
                    const remainingAttempts = res.response.data.remainingAttempts || 0;

                    ctrl.isVerifyingPin = false;
                    ctrl.pinTitle = $filter('translate')('PIN.WRONG_CODE', { NUMBER: remainingAttempts });
                    ctrl.isPinInvalid = true;
                    ctrl.showKeyboard = false;
                    ctrl.showButtons = true;
                    ctrl.cancelledPinCode = null;
                }
            }, () => {
                ctrl.isVerifyingPin = false;
                ctrl.pinTitle = 'ERROR.GENERAL'
                ctrl.isPinInvalid = true;
                ctrl.showKeyboard = false;
                ctrl.showButtons = true;
                ctrl.cancelledPinCode = null;
            });
        }

        function tryAgain() {
            
            if(ctrl.cancelledPinCode){
                saveBiometric(ctrl.cancelledPinCode);
                return;
            }
            
            ctrl.pinCodeModel = [null, null, null, null];
            ctrl.showButtons = false;
            ctrl.isPinInvalid = false;
            ctrl.showKeyboard = true;
            ctrl.pinTitle = 'PIN.ENTER_PIN';
        }

        function saveBiometric(pinCode){
            ctrl.cancelledPinCode = null;

            PinCodeService.saveBiometricPin(ctrl.profile.UserToken, pinCode).then(() => {
                ctrl.isCompleted = true;
            }, (err) => {
                if (err.isCancelEvent) {
                    ctrl.pinTitle = 'PIN.BIOMETRIC.ERROR.SAVE_CANCELLED';
                    ctrl.cancelledPinCode = pinCode;
                } else if (err.errorCode) {
                    ctrl.pinTitle = err.errorCode;
                } else if (err.errorMessage) {
                    ctrl.pinTitle = err.errorMessage;
                } else {
                    ctrl.pinTitle = 'ERROR.GENERAL'
                }

                ctrl.isVerifyingPin = false;
                ctrl.isPinInvalid = true;
                ctrl.showKeyboard = false;
                ctrl.showButtons = true;
            });
        }

        function onPopupOpen(data, popupCtrl) {
            popup = popupCtrl;
            
            ctrl.profile = data.profile;
            ctrl.onClose = data.onClose;
        }

        function reset() {
            ctrl.pinTitle = 'PIN.ENTER_PIN';
            ctrl.pinCodeModel = [null, null, null, null];
            ctrl.showKeyboard = true;
            ctrl.showButtons = false;
            
            ctrl.isPinInvalid = false;
            ctrl.isPinValid = false;

            ctrl.isVerifyingPin = false;
            ctrl.isCompleted = false;
            ctrl.onClose = null;
            ctrl.profile = null;
        }

        function onPopupClose(data, popupCtrl) {
            ctrl.onClose && ctrl.onClose(ctrl.isCompleted);

            reset();
            if(popupCtrl){
                popupCtrl.hide();
            }else{
                popup && popup.hide();
            }
        }
    }
})();