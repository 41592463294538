(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('HowToGuideService', ['$rootScope', '$timeout', '$http', '$compile', 'PopupWrapperService',
        function ($rootScope, $timeout, $http, $compile, PopupWrapperService) {
        var popupId = 'howToGuidePopupId';

            // Return Object
            var service = {
                getData: getData,
                renderHowToGuidePopup: renderHowToGuidePopup
            };

            return service;

            function getData(token) {
                return $http.get('/HowToGuide/GetData/' + token).then(function (response) {
                    return response.data;
                });
            }

            function renderHowToGuidePopup(token) {
                var popupTemplate = '<how-to-guide popup-id="ctrl.popupId" token="ctrl.token"></how-to-guide>',
                    scope = $rootScope.$new(true), popupElement;

                scope.ctrl = {
                    popupId: popupId,
                    token: token
                };

                popupElement = $compile(popupTemplate)(scope);
                angular.element(document).find('body').append(popupElement);

                $timeout(function () {
                    PopupWrapperService.getPopup(scope.ctrl.popupId).open();
                }, 200);
            }
        }
    ]);
})();