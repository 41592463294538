(() => {
    'use strict';

    angular
        .module('App')
        .component('subtasksCounter', {
            template: require('./SubtasksCounterComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', subtasksCounterController],
            bindings: {
                subtasks: '<'
            }
        });

    function subtasksCounterController($scope) {
        const ctrl = this;
        let removeSubtasksWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.subtasksCompleted = 0;

            removeSubtasksWatcher = $scope.$watch('ctrl.subtasks', () => {
                if (ctrl.subtasks.length) {
                    ctrl.subtasksCompleted = ctrl.subtasks.filter(x => x.IsCompleted).length;
                }
                
            }, true)
        }

        function destroy() {
            removeSubtasksWatcher && removeSubtasksWatcher();
        }
    }
})();