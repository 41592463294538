(function () {
	'use strict';
	var app = angular.module('App');

	app.controller('PageModalWrapperController', ['$rootScope', '$scope', 'Page', 'close', 'controller', 'templateUrl', 'className',
		function ($rootScope, $scope, Page, close, controller, templateUrl, className) {
			var ctrl = this;
			Page.setPageAsModalState(true);
            Page.setMainContainerInert(true);

            ctrl.templateUrl = templateUrl;
			ctrl.controller = controller;
			ctrl.className = className;

			$scope.close = function() {
				Page.setPageAsModalState(false);
				Page.setMainContainerInert(false);
				close();
			};
		}
	]);

})();