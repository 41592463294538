(() => {
    'use strict';

    angular.module('App')
        .component('desktopMessageMenu', {
            template: require('./DesktopMessageMenu.html'),
            controllerAs: 'ctrl',
            controller: [DesktopMessageMenuController],
            bindings: {
                optionButtons: '<',
                isAuthor: '<',
                sendReaction: '=?',
                onEdit: '=?',
                onReply: '=?',
                isMenuActive: '=?',
                reactionsEnabled: '<'
            }
        })

    function DesktopMessageMenuController() {
        const ctrl = this;
        
        ctrl.openMenu = openMenu;
        
        function openMenu() {
            ctrl.isMenuActive = !ctrl.isMenuActive;
        }
    }
})();
