(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('UploadFactory', ['$q', '$http', '$timeout', 'Upload', '$rootScope', 'toastr', 'Page', 'Profile', '$translate',
      function ($q, $http, $timeout, Upload, $rootScope, toastr, Page, Profile, $translate) {
        var queue = [];
        var isUploading = false;

        var f = {
          upload: function (file, options) {
            var d = $q.defer();

            if (file !== undefined && !file.$error) {

              // Add file to queue
              // Listen for file to be done
              AddUploadToQueue(file, options).then(function (response) {
                d.resolve(response);
              }, function () {
                d.reject();
              });

            } else {

              // Show error toast
              $translate('UPLOAD.FAILED').then(function (translation) {
                toastr.error(translation);
              });
              // Broadcast event to progress
              $rootScope.$broadcast('fileUploadDone', { file: file });
              $rootScope.$broadcast('fileUploadError', { file: file });

              // Reject Upload
              d.reject();
            }

            return d.promise;
          }
        };

        function AddUploadToQueue(file, options) {
          var d = $q.defer();
          
          file.progress = 0;
          $rootScope.$broadcast('fileUploadBegin', { file: file, options: options });

          // Listen for upload done event
          var listener = $rootScope.$on('internalFileUploadDone', function (event, args) {
            // Only do an action if it's the same file
            if (args.file.id == file.id) {
              // Deregister listener
              listener();
              // Return resolve/reject
              if (args.response) {
                d.resolve(args.response);
              } else {
                d.reject();
              }
            }
          });

          queue.push(file);

          // UploadNextInQueue
          UploadNextInQueue();

          return d.promise;
        }

        function UploadNextInQueue() {
          if (!isUploading && queue.length > 0) {
            isUploading = true;

            // Get first file
            var file = queue[0];

            // Get settings needed
            var settings = Page.getSettings();
            var profile = Profile.getProfile();

            // Start Upload
            file.upload = Upload.upload({
              url: settings.MediaServerDomain + '/Upload',
              data: {
                file: file,
                AccountToken: profile.AccountToken,
                UserToken: profile.UserToken
              },
              headers: { 'X-Requested-With': 'XMLHttpRequest' }
            });

            // Listen for completion
            file.upload.then(function (response, c) {
              var uploadSuccess = false;
              if (response.data != "") {
                if (response.data.Status == 1) {
                  // Successfully uploaded
                  uploadSuccess = true;
                } else {
                  // Show error toast
                  $translate('UPLOAD.WRONG_FILE').then(function (translation) {
                    toastr.error(translation);
                  });

                  $rootScope.$broadcast('fileUploadError', { file: file });
                }
              } else {
                // Show error toast
                $translate('UPLOAD.WRONG_FILE').then(function (translation) {
                  toastr.error(translation);
                });

                $rootScope.$broadcast('fileUploadError', { file: file });

              }

              // Broadcast event to progress
              $rootScope.$broadcast('fileUploadDone', { file: file });

              // Broadcast internal event
              if (uploadSuccess) {
                RemoveUploadFromQueue(file, response);
              } else {
                RemoveUploadFromQueue(file);
              }
            }, function (response, a) {
              // Show error toast
              $translate('UPLOAD.FAILED').then(function (translation) {
                toastr.error(translation);
              });
              // Broadcast event to progress
              $rootScope.$broadcast('fileUploadDone', { file: file });
              $rootScope.$broadcast('fileUploadError', { file: file });

              // Broadcast internal event
              RemoveUploadFromQueue(file);
            }, function (evt) {
              // Change progress
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                if (file.progress >= 100) {
                    $rootScope.$broadcast('fileUploadPartiallyDone', { file: file });
                }
            });
          }
        }

        function RemoveUploadFromQueue(file, response) {
          // Remove file from queue
          var index = queue.indexOf(file);
          if (index >= 0) {
            queue.splice(index, 1);
          }

          // Broadcast internal event
          $rootScope.$broadcast('internalFileUploadDone', { file: file, response: response });
          isUploading = false;

          // Attempt to upload the next file
          UploadNextInQueue();
        }

        return f;
    }]);
})();