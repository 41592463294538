(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
            function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
                $stateProvider
                    .state('policyView', {
                        parent: 'base',
                        url: '/policy',
                        template: '<div class="policy-view-page" view-page view-page-scrolling="false"><policy-view></policy-view></div>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'PolicyViewController as ctrl'
                    });
            }
        ])
        .controller('PolicyViewController', ['$stateParams', '$translate', 'Page', 'TemplateFactory',
            function ($stateParams, $translate, Page, TemplateFactory) {
                var template = TemplateFactory.getTemplate();

                $translate('PROFILE.PRIVACY_POLICY').then(function (translation) {
                    Page.setTitle(translation);
                });

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    if (template.key === 'web') {
                        Page.showBackButton();
                    } else {
                        Page.showBackButton('profileOverview');
                    }
                }
            }]);

})();