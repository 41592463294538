(() => {
    'use strict';

    angular
        .module('App')
        .component('statisticsPopup', {
            template: require('./StatisticsPopupComponent.html'),
            controller: ['$element', '$timeout', 'ISSUE_STATUS', 'StatisticsPopupService', 'ServiceFormRestService',
                'PopupWrapperService', StatisticsPopupController],
            controllerAs: 'ctrl',
            bindings: {
                items: '<',
                hasEndTimePassed: '<',
                showAlertMessage: '<'
            }
        });


    function StatisticsPopupController($element, $timeout, ISSUE_STATUS, StatisticsPopupService, ServiceFormRestService,
        PopupWrapperService) {
        let ctrl = this, popup;

        ctrl.popupId = 'StatisticsPopupId';

        ctrl.ISSUE_STATUS = ISSUE_STATUS;
        ctrl.activeStatusFilter = null;
        ctrl.completedCount = getCompletedCount();
        ctrl.uncompletedCount = getUncompletedCount();
        ctrl.onChangeStatusFilter = onChangeStatusFilter;
        ctrl.completionRate = StatisticsPopupService.getCompletionRate(ctrl.completedCount, ctrl.uncompletedCount)
        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.filteredItems = ctrl.items;
        ctrl.openReport = openReport;
        ctrl.showReportButton = showReportButton;
        ctrl.resetListScroll = resetListScroll;
        ctrl.alertMessage = "TASK_MANAGEMENT.COMPLETED_ALERT_MESSAGE";

        function close() {
            popup.remove();
            $element.remove();
        }

        function showReportButton(item) {
            return item.ServiceFormFillingToken;
        }

        function openReport(item) {
            if (!showReportButton(item)) {
                return;
            }

            ServiceFormRestService
                .getFillingData(item.ServiceFormFillingToken, true).then(openFillingPopup)
        }

        function openFillingPopup(filling) {
            PopupWrapperService.createDynamic(
                '<filling-preview-popup on-popup-registered="registeredCallback"' +
                ' service-form-token="serviceFormToken" service-form-id="serviceFormId"' +
                ' account-module-token="accountModuleToken" on-close="close" is-preview="true"' +
                '></filling-preview-popup>',
                {
                    registeredCallback: function (popup) {
                        popup.open(filling);
                    },
                    serviceFormToken: filling.ServiceFormToken,
                    serviceFormId: filling.ServiceFormId,
                    accountModuleToken: filling.AccountModuleToken,
                    close: function (popup, element) {
                        popup.remove();
                        element.remove();
                    }
                }
            )
        }

        function resetListScroll() {
            $timeout(function () {
                const element = document.querySelector('.user-list-wrapper');
                if (element) {
                    element.scrollTop = 0;
                }
            });
        }

        function onChangeStatusFilter(issueStatus) {
            ctrl.activeStatusFilter = issueStatus;
            ctrl.resetListScroll();
            if (!issueStatus) {
                ctrl.filteredItems = ctrl.items;
                return;
            }

            var isCompleted = issueStatus === ISSUE_STATUS.COMPLETE;
            ctrl.filteredItems = ctrl.items.filter(function (item) {
                return item.IsCompleted === isCompleted;
            })
        }

        function getCompletedCount() {
            return ctrl.items.filter(function (item) {
                return item.IsCompleted
            }).length
        }

        function getUncompletedCount() {
            return ctrl.items.filter(function (item) {
                return !item.IsCompleted
            }).length
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }
    }
})();


