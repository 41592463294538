(function () {
    'use strict';

    angular.module('App')
        .component('nativeUpgrade', {
            templateUrl: '/Scripts/Components/NativeUpgrade/NativeUpgradeComponent.tpl.html',
            controller: ['$element', '$sce', NativeUpgradeController],
            controllerAs: 'ctrl',
            bindings: {
                downloadUrl: '@'
            }
        });

    function NativeUpgradeController($element, $sce) {
        var ctrl = this;
        ctrl.$onInit = init;
        
        function init() {
            $element.addClass('app-splashscreen-background');
            $element.addClass('app-splashscreen-background-image');
        }

        ctrl.download = function () {
            window.location.href = ctrl.downloadUrl;
        }
    }
})();