(function () {
    'use strict';

    angular
        .module('App')
        .component('attendanceOverviewPopup', {
            template: require('./AttendanceOverviewPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$rootScope', '$scope', '$filter', 'CalendarRestService', 'CookieService',
                'CalendarRenderService', 'ConfirmPopupService', 'ToastFactory', AttendanceOverviewPopupController],
            bindings: {
                calendarEventId: '<',
                isSignUp: '<',
                isHierarchyLimitEnabled: '<',
                isModerationAllowed: '<',
                attendanceListType: '<'
            }
        })

    function AttendanceOverviewPopupController($element, $rootScope, $scope, $filter, CalendarRestService,
                                               CookieService, CalendarRenderService, ConfirmPopupService, ToastFactory) {
        const ctrl = this,
            ATTENDANCE_ALERT_MESSAGES = {
                1: 'CALENDAR.EVENT.ATTENDANCE_CLOSE_ON',
                2: 'CALENDAR.EVENT.ATTENDANCE_AVAILABLE_ON',
                3: 'CALENDAR.EVENT.ATTENDANCE_CLOSED'
            },
            ATTENDANCE_STATUS = {
                OPEN: 1,
                NOT_OPEN_YET: 2,
                CLOSED: 3
            };

        let popup, storedUsers;

        ctrl.searchString = '';
        ctrl.attendanceType = {
            attendanceList: 1,
            signups: 0
        }
        ctrl.header = ctrl.attendanceListType === ctrl.attendanceType.attendanceList ? 'CALENDAR.EVENT.ATTENDANCE_LIST' : 'CALENDAR.EVENT.PARTICIPANTS.TITLE';
        ctrl.tabs = [
            {
                active: true,
                title: 'CALENDAR.EVENT.PARTICIPANTS.ALL'
            }
        ];
        if (!ctrl.isSignUp) {
            ctrl.tabs = ctrl.tabs.concat([
                {
                    type: 1,
                    icon: 'save',
                    title: 'CALENDAR.EVENT.PARTICIPANTS.YES'
                },
                {
                    type: 2,
                    icon: 'help',
                    title: 'CALENDAR.EVENT.PARTICIPANTS.MAYBE'
                }
            ])

            if (ctrl.attendanceListType === ctrl.attendanceType.signups) {
                ctrl.tabs = ctrl.tabs.concat([
                    {
                        type: 3,
                        icon: 'close',
                        title: 'CALENDAR.EVENT.PARTICIPANTS.NO'
                    },
                    {
                        type: null,
                        icon: 'help',
                        title: 'CALENDAR.EVENT.PARTICIPANTS.PENDING'
                    }
                ])
            }
        }

        ctrl.showCheckboxes = false;
        ctrl.disabledCheckboxes = false;
        ctrl.showSaveButton = false;
        ctrl.showAttendanceList = false;

        ctrl.$onInit = init;
        ctrl.filterUsers = filterUsers;
        ctrl.onSearch = onSearch;
        ctrl.registeredCallback = registeredCallback;
        ctrl.onClose = onClose;
        ctrl.onSave = onSave;
        ctrl.removeUsers = removeUsers;
        ctrl.selectUser = selectUser;

        function init() {
            if (CookieService.get('isHierarchyLimitTipShown') !== 'true') {
                ctrl.showHierarchyTip = true;
                CookieService.set('isHierarchyLimitTipShown', true)
            }

            if (ctrl.attendanceListType === ctrl.attendanceType.signups) {
                CalendarRestService.getEventSignups(ctrl.calendarEventId).then(function (data) {
                    storedUsers = data.Items;
                    onSearch(ctrl.searchString);
                    ctrl.loaded = true;
                })
            }

            if (ctrl.attendanceListType === ctrl.attendanceType.attendanceList) {
                CalendarRestService.getEventAttendanceList(ctrl.calendarEventId)
                    .then(({Items, Status, OpenDate, CloseDate}) => {
                        storedUsers = Items || [];

                        ctrl.alertMessage = $filter('translate')(ATTENDANCE_ALERT_MESSAGES[Status]) + ' ' +
                            (Status === 2 ? OpenDate : CloseDate);

                        ctrl.showAttendanceList = Status !== ATTENDANCE_STATUS.NOT_OPEN_YET;
                        ctrl.showCheckboxes = Status !== ATTENDANCE_STATUS.NOT_OPEN_YET;
                        ctrl.disabledCheckboxes = Status === ATTENDANCE_STATUS.CLOSED;
                        ctrl.showSaveButton = Status === ATTENDANCE_STATUS.OPEN;
                        onSearch(ctrl.searchString);
                        ctrl.loaded = true;
                    })
            }
        }

        function onSearch(string) {
            const users = storedUsers.filter(user => {
                return user.UserName.toLowerCase().indexOf(string.toLowerCase()) !== -1
            });
            sortUsersByTabs(users)

            filterUsers(ctrl.tabs.find(tab => tab.active));
        }

        function filterUsers(tab) {
            ctrl.tabs.forEach(tab => tab.active = false);
            tab.active = true;
            ctrl.users = tab.users
        }

        function selectUser(userToken, isAttending) {
            CalendarRestService.setUserAttendanceStatus(ctrl.calendarEventId, userToken, isAttending);
        }

        function calculateCount() {
            ctrl.tabs[0].count = ctrl.tabs[0].users.length;

            if (!ctrl.isSignUp) {
                ctrl.tabs[1].count = getTabCount(1);
                ctrl.tabs[2].count = getTabCount(2);

                if (ctrl.attendanceListType === ctrl.attendanceType.signups) {
                    ctrl.tabs[3].count = getTabCount(3);
                    ctrl.tabs[4].count = getTabCount(4);
                }
            }

            function getTabCount(index) {
                return ctrl.tabs[index].users.length
            }
        }

        function sortUsersByTabs(users) {
            ctrl.tabs[0].users = users;
            if (!ctrl.isSignUp) {
                ctrl.tabs[1].users = filterUsersByStatus(users, 1);
                ctrl.tabs[2].users = filterUsersByStatus(users, 2);

                if (ctrl.attendanceListType === ctrl.attendanceType.signups) {
                    ctrl.tabs[3].users = filterUsersByStatus(users, 3);
                    ctrl.tabs[4].users = filterUsersByStatus(users, null);
                }
            }
            calculateCount();

            function filterUsersByStatus(users, status) {
                return users.filter(user => user.AttendingStatus === status || (!status && !user.AttendingStatus))
            }
        }

        function removeUsers() {
            if (ctrl.selectedUsers.length) {
                ConfirmPopupService.open({
                    message: 'CALENDAR.EVENT.REMOVE_USER_MESSAGE',
                }).then(remove)
            } else {
                ctrl.showCheckboxes = !ctrl.showCheckboxes;
            }

            function remove() {
                CalendarRenderService.removeParticipants(ctrl.calendarEventId, ctrl.selectedUsers)
                    .then(resp => {
                        ctrl.showCheckboxes = false;
                        ctrl.selectedUsers = [];

                        storedUsers = resp.Signups;
                        onSearch(ctrl.searchString);

                        $rootScope.$emit('updateListOfSignupUsers', resp.Event);
                    })
            }
        }

        function onClose() {
            popup.remove();
            $element.remove();
        }

        function onSave() {
            ToastFactory.success($filter('translate')('CALENDAR.EVENT.ATTENDANCE_SAVED'));
            popup.remove();
            $element.remove();
        }

        function registeredCallback(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }
    }
})();