(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('tabset', [
        function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    init: '&',
                    tabSlider: '@'
                },
                templateUrl: '/Scripts/Directives/Templates/Tabset.tpl.html',
                bindToController: true,
                controllerAs: 'tabset',
                controller: ['$scope', '$attrs', '$element', '$timeout', '$parse', function ($scope, $attrs, $element, $timeout, $parse) {
                    var self = this;
                    var $_border = $('.active-tab-border', $element);

                    // Init
                    InitController();

                    function InitController() {
                        self.tabs = [];
                        self.hideHeader = false;
                        $scope.isSlider = false;

                        if (self.tabSlider && self.tabSlider.toLowerCase() === 'true') {
                            $scope.isSlider = true;
                            $($element).addClass('tabset-slider');
                        }

                        if ($attrs.tabScroll && $attrs.tabScroll.toLowerCase() === 'true') {
                            $($element).addClass('tabset-scroll');
                        }

                        if ($attrs.native) {
                            $attrs.$observe('native', function(value) {
                                if (value && value.toLowerCase() === 'true') {
                                    $($element).addClass('native');
                                } else {
                                    $($element).removeClass('native');
                                }
                            });
                        }

                        if (!($scope.tabRef)) {
                            $scope.tabRef = {};
                        }

                        // Add Methods to tabRef
                        $scope.tabRef = {
                            select: function (index) {
                                if (index >= 0 && index < self.tabs.length) {
                                    Select(self.tabs[index]);
                                }
                            },
                            setActive: function (index) {
                                if (index >= 0 && index < self.tabs.length) {
                                    SetActive(self.tabs[index]);
                                }
                            }
                        };

                        if (self.init) {
                            $timeout(function () {
                                $parse(self.init)({
                                    tabs: $scope.tabRef
                                });
                            });
                        }
                    }

                    // Methods
                    function AddTab(tab) {
                        self.tabs.push(tab);
                        $timeout(function () {
                            SelectFirst();
                        });
                    }

                    function SelectFirst() {

                        var parentTab = GetParentTab($scope);
                        if (parentTab != null) {
                            if (parentTab.active) {
                                Select(self.tabs[0]);
                            } else {
                                var unregister = parentTab.$watch('active', function (newVal) {
                                    if (newVal) {
                                        Select(self.tabs[0]);
                                        unregister();
                                    }
                                });
                            }
                        } else if (self.tabs.length > 0) {
                            var alreadySelected = false;
                            for (var i = 0; i < self.tabs.length; i++) {
                                if (self.tabs[i].active) {
                                    alreadySelected = true;
                                }
                            }

                            if (!alreadySelected) {
                                Select(self.tabs[0]);
                            }
                        }
                    }

                    function Select(selectedTab) {
                        // Set tab as active
                        SetActive(selectedTab);

                        // Fire OnSelect Event
                        selectedTab.select();
                    }

                    function SetActive(selectedTab) {
                        var index = 0;
                        for (var i = 0; i < self.tabs.length; i++) {
                            var tab = self.tabs[i];
                            tab.active = (tab === selectedTab);
                            if (tab.active) {
                                index = i;
                            }
                        }
                        $timeout(function () {
                            var $curTab = $('.tab-header li', $element).eq(index);
                            $_border.css('left', $curTab.position().left);
                            $_border.css('width', $curTab.width() + 'px');
                        });
                    }

                    function GetParentTab(scope) {
                        if (scope.$parent != null) {
                            if (scope.$parent.tab !== undefined) {
                                return scope.$parent.tab;
                            }
                            return GetParentTab(scope.$parent);
                        }
                        return null;
                    }

                    function HasImage(tab) {
                        return (angular.isString(tab.image) && tab.image.length > 0);
                    }

                    function HasIcon(tab) {
                        return (angular.isString(tab.icon) && tab.icon.length > 0);
                    }

                    // Bind to ctrl
                    self.addTab = AddTab;
                    self.select = Select;
                    self.hasImage = HasImage;
                    self.hasIcon = HasIcon;
                }]
            };
        }
    ]);
})();