(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlSorting', {
            template: require('./SortingComponent.html'),
            controller: ['$timeout', '$element', 'ActionSheetCustomService', SortingPopupController],
            controllerAs: 'ctrl',
            bindings: {
                onSelect: '<',
                sortingOptions: '<',
                headerTitle: '@',
                selectedOptionId: '=?'
            }
        });

    function SortingPopupController($timeout, $element, ActionSheetCustomService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.openSortingPopup = openSortingPopup;
        ctrl.onSortSelected = onSortSelected;

        function init() {
            ctrl.sortingOptions = ctrl.sortingOptions || [
                {id: 1, icon: 'bullseye-arrow', title: 'SORTING.MOST_RELEVANT'},
                {id: 2, icon: 'arrow-up-1-9', title: 'SORTING.NEWEST_FIRST'},
                {id: 3, icon: 'arrow-down-1-9', title: 'SORTING.OLDEST_FIRST'},
            ];

            if (ctrl.selectedOptionId) {
                const option = ctrl.sortingOptions.find(option => option.id === ctrl.selectedOptionId);
                if (option) {
                    option.selected = true;
                }
            } else {
                ctrl.sortingOptions[0].selected = true;
            }
        }

        function openSortingPopup() {
            ActionSheetCustomService.create(
                `<rl-sorting-popup 
                     options="options"
                     on-sort-selected="onSortSelected"
                     header-title="{{ headerTitle }}"
                 ></rl-sorting-popup>`,
                {
                    options: ctrl.sortingOptions,
                    headerTitle: ctrl.headerTitle,
                    onSortSelected: onSortSelected
                },
                $element[0]
            );
        }

        function onSortSelected(option) {
            ctrl.selectedOptionId = option.id;
            $timeout(() => {
                ctrl.onSelect && ctrl.onSelect(option);
            })
        }
    }
})();