(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('contactInfo', ['$log',
        function ($log) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/ContactInfo.tpl.html',
                scope: {
                    contact: '='
                },
                link: function (scope, element, attrs) {
                    
                }
            }
        }
    ]);
})();