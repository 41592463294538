function StateTransitionClass(state, token, extra, options, notify, restParams) {
    this.state = state;
    this.token = token;
    this.extra = extra;
    this.options = options;
    this.notify = notify;
    this.restParams = restParams;

    return this;
}

window.StateTransitionClass = StateTransitionClass;