(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('LoginController', ['$rootScope', '$scope', '$state', '$location', '$stateParams', 'Page', 'toastr', 'LoginFactory', 'isLoggedIn', '$translate', '$timeout', 'NativeFactory',
        function ($rootScope, $scope, $state, $location, $stateParams, Page, toastr, LoginFactory, isLoggedIn, $translate, $timeout, NativeFactory) {

            $scope.loading = false;
            $scope.showLogin = false;
            $scope.resetPass = false;

            // Init
            InitController();
            function InitController() {
                // First check if user is logged in
                if (isLoggedIn) {
                    Page.stateGo('home');
                } else {
                    $scope.settings = Page.getSettings().LoginSettings;

                    // This means we currently show an error about the user missing a login code in his link
                    $scope.loginState = false;

                    // Should we show the login boxes in the app?
                    if ($scope.settings.Access.LoginByPhoneNumberEnabled || $scope.settings.Access.LoginByUserNameEnabled) {
                        // One of the login types are enabled and should be shown
                        $scope.loginState = true;
                    }

                    $scope.factory = LoginFactory;

                    if ($scope.settings.AuthenticateUsingIDServer) {
                        // If we want to use the ID server for authentication instead, we should redirect the user to that instead of showing the app screen
                        window.location.href = '/Login/IdServer';
                    } else {
                        // Use a timeout before showing the login to allow CSS animations to fire
                        $timeout(function () {
                            $scope.showLogin = true;
                            NativeFactory.basic.appIsReady();
                        }, 100);
                    }

                    // Set Models
                    $scope.loginModel = new LoginModel();
                    $scope.resetModel = new ResetModel();
                }   
            }

            // Methods
            function Login(model) {
                $scope.loading = true;

                var result = LoginFactory.login(model.Username, model.Password, model.RememberMe);
                return result.then(function (result) {
                    toastr.clear();
                    if (result.success) {
                        LoginFactory.approveLogin();
                        $location.path('/');
                    } else {
                        $scope.loading = false;
                        if (!result.error) {
                            $translate('ERROR.GENERAL').then(function (translation) {
                                toastr.error(translation);
                            });
                        } else {
                            var errorMsg = 'LOGIN.WRONG_LOGIN';
                            switch (result.error) {
                                case 3:
                                case 8:
                                    errorMsg = 'LOGIN.BLOCKED_LOGIN';
                                    break;
                                default:
                                    errorMsg = 'LOGIN.WRONG_LOGIN';
                                    break;
                            }
                            $translate(errorMsg).then(function (translation) {
                                toastr.error(translation);
                            });
                        }
                    }
                });
            }

            function ForgotPassword(model) {
                $scope.loading = true;

                var result = LoginFactory.forgotPassword(model.Username);
                return result.then(function (result) {
                    toastr.clear();

                    if (result.success) {
                        $scope.resetPass = false;
                        $translate('LOGIN.FORGOT_PASSWORD_SUCCESS').then(function (translation) {
                            toastr.success(translation);
                        });
                    } else {
                        if (result.error) {
                            $translate('ERROR.GENERAL').then(function (translation) {
                                toastr.error(translation);
                            });
                        } else {
                            $translate('LOGIN.USER_NOT_FOUND').then(function (translation) {
                                toastr.error(translation);
                            });
                        }
                    }
                });
            }

            function ToggleForgotPassword() {
                $scope.resetPass = !$scope.resetPass;
            }

            // Objects
            function LoginModel() {
                this.Username = '';
                this.Password = '';
                this.RememberMe = true;
            }
            function ResetModel() {
                this.Username = '';
            }

            // Bind
            $scope.Login = Login;
            $scope.ForgotPassword = ForgotPassword;
            $scope.ToggleForgotPassword = ToggleForgotPassword;
        }
    ]);

})();