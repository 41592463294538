(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('EventDetailsService', ['$http', '$stateParams', 'PopupWrapperService',
        function ($http, $stateParams, PopupWrapperService) {

            return {
                openEventDetails: openEventDetails,
                openSignups: openSignups
            }

            function openEventDetails(eventId, isUpdate, token) {
                PopupWrapperService.createDynamic(
                    `<event-details
                                   class="calendar-event-details calendar-event-details-${$stateParams.token}"
                                   event-id="eventId"
                                   is-update="isUpdate"
                                   account-module-token="token">
                               ></event-details>`,
                    {
                        eventId: eventId,
                        isUpdate: isUpdate,
                        token: token
                    }
                );
            }

            function openSignups({ CalendarEventId, SignupEnabled, LimitParticipantListByHierarchy, IsModerationAllowed }, attendanceType) {
                PopupWrapperService
                    .createDynamic(
                        `<attendance-overview-popup 
                                        calendar-event-id="eventId"
                                        is-sign-up="isSignUp"
                                        is-hierarchy-limit-enabled="limitByHierarchy"
                                        is-moderation-allowed="isModerationAllowed",
                                        attendance-list-type="attendanceType"
                                   ></attendance-overview-popup>`,
                        {
                            eventId: CalendarEventId,
                            isSignUp: SignupEnabled,
                            limitByHierarchy: LimitParticipantListByHierarchy,
                            isModerationAllowed: IsModerationAllowed,
                            attendanceType: attendanceType
                        })
            }
        }]);
})();
