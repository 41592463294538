(function () {
    'use strict';

    angular
        .module('App')
        .component('lazyDatalist', {
            template: require('./LazyDatalistComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$rootScope', '$element', '$timeout', '$scope', '$filter', '$templateRequest', '$compile',
                '$q', 'ServiceFormDataFormatService', 'ServiceFormRestService', 'ServiceFormRenderService', 'DATALIST_TYPE', LazyDatalistController],
            bindings: {
                buttonId: '@',
                field: '<',
                listData: '=',
                amount: '<',
                isMultiple: '<',
                isLazy: '<',
                onLazyOpen: '&',
                headerTitle: '<',
                defaultValue: '<',
                labelTitle: '<',
                enableSearch: '<',
                filters: '<',
                searchInput: '=?',
                isBarcode: '<',
                inlineSearch: '=?',
                index: '<',
                onCreateClick: '&',
                showCreateButton: '<',
                createButtonLabel: '@',
                min: '<',
                max: '<',
                isLimitedByRule: '<',
            },
            require: {
                ngModelCtrl: 'ngModel'
            },
        });

    function LazyDatalistController($stateParams, $rootScope, $element, $timeout, $scope, $filter, $templateRequest,
                                    $compile, $q, ServiceFormDataFormatService, ServiceFormRestService, ServiceFormRenderService, DATALIST_TYPE) {
        var ctrl = this, firstTimeInit, popup, scrollContainer, dataListItemAddedListener,
            scope = $scope.$new();

        ctrl.amount = 50;

        ctrl.isLazyDataLoaded = false;
        ctrl.isLoading = true;
        ctrl.inUseLabel = ServiceFormRenderService.getLabelSettings().TranslatedInUseLabel || ServiceFormRenderService.getLabelSettings().InUseLabel;

        ctrl.isRTL = $rootScope.isRTL;
        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.clearSearch = clearSearch;
        ctrl.close = close;
        ctrl.resetSelections = resetSelections;
        ctrl.search = search;
        ctrl.enter = enter;
        ctrl.toggleDropdown = toggleDropdown;
        ctrl.onSelect = onSelect;
        ctrl.loadData = loadData;
        ctrl.selectInline = selectInline;
        

        function init() {
            ctrl.isOpen = false;
            ctrl.visibleList = [];
            ctrl.selectedItems = [];
            ctrl.isLazyDataLoaded = false;
            ctrl.listDataCopyForSearch = [];
            firstTimeInit = true;

            ctrl.ngModelCtrl.$isEmpty = (val) => {
                return val === undefined || !val.length
            }

            // Init ngModel
            $scope.$watch(function () {
                return ctrl.ngModelCtrl.$viewValue
            }, viewValueChanged);

            $scope.$watch('ctrl.searchInput', function (newVal) {
                if (!_.isUndefined(newVal)) {
                    ctrl.search(newVal);
                }
            });

            $scope.$watch('ctrl.inlineSearch', function (newVal, oldVal) {
                if (!_.isUndefined(newVal) && newVal !== oldVal) {
                    inlineSearch(newVal);
                }
            });
            
            if (!ctrl.isLazy) {
                ctrl.visibleList = _.take(ctrl.listData, ctrl.amount);
                ctrl.listDataCopyForSearch = _.clone(ctrl.listData);
                setSelections();
            }

            dataListItemAddedListener = $rootScope.$on('dataListItemAdded', function (e, newVal) {
                if (newVal.DataListId === (ctrl.field && ctrl.field.DataListId) && !_.includes(ctrl.listDataCopyForSearch, newVal)) {
                    ctrl.listData.isUpdated = true;
                    ctrl.listDataCopyForSearch.unshift(newVal);
                    ctrl.visibleList.unshift(newVal);
                    ctrl.onSelect(newVal);
                    if (!ctrl.isBarcode) {
                        ctrl.isForceClose = true;
                    }
                }
            });

            if (ctrl.min && ctrl.isMultiple) {
                ctrl.ngModelCtrl.$validators.min = function (modelValue, viewValue) {
                    var value = modelValue || viewValue;
                    if (ctrl.ngModelCtrl.$validators.required(modelValue, viewValue) && value && value.length >= 1) {
                        return value.length >= ctrl.min;
                    } else {
                        return true
                    }
                };
                ctrl.ngModelCtrl.$validate();
            }
        }

        function setInitialTitle() {
            ctrl.title = $filter('translate')('SERVICEFORM.DATALIST.SELECT');
        }

        function close() {
            bodyScrollLock.enableBodyScroll(scrollContainer);
            $timeout(function () {
                ctrl.isOpen = false;
                popup && popup.remove();
                clearSearch();
            });
            if (ctrl.isBarcode) {
                ctrl.isOpenInline = false;
            }
        }
        
        function filterElements(items) {
            if (ctrl.filters && ctrl.filters.length) {
                return _.filter(items, function (item) {
                    return _.find(ctrl.filters, {'DataListItemId': parseInt(item.CascadingFilterValue)});
                })
            } else {
                return items
            }
        }

        function getItemsInUse() {
            return ServiceFormRestService.getItemsInUseForField($stateParams.token, ctrl.field.ServiceFormFieldId);
        }

        function onOpen() {
            $timeout(function () {
                $element.find('button.close').focus();
            });
            ctrl.isLoading = true;

            ctrl.isLimitedByRule ? getItemsInUse().then(fieldInUse => loadListData(fieldInUse)) : loadListData();
        }

        function loadListData(fieldInUse) {
            if (fieldInUse?.DataListItemIds?.length > 0) {
                ctrl.listDataCopyForSearch = ServiceFormDataFormatService.formatInUseFields(ctrl.listDataCopyForSearch?.length > 0 ? ctrl.listDataCopyForSearch : ctrl.listData, fieldInUse);
            }
            
            if (ctrl.onLazyOpen && ctrl.isLazy && !ctrl.isLazyDataLoaded) {
                ctrl.onLazyOpen().then(function (items) {
                    ctrl.listDataCopyForSearch = fieldInUse?.DataListItemIds?.length > 0 ?
                        ServiceFormDataFormatService.formatInUseFields(items, fieldInUse) : _.clone(items);
                    ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
                    ctrl.visibleList = _.take(ctrl.listData, ctrl.amount);
                    
                    setSelections();
                    ctrl.isLazyDataLoaded = true;
                    ctrl.isLoading = false;
                });
            } else if (ctrl.isLazy && ctrl.isLazyDataLoaded) {
                ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
                ctrl.visibleList = _.take(ctrl.listData, ctrl.amount);
                setSelections();
                ctrl.isLoading = false;
            } else {
                ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
                ctrl.visibleList = _.take(ctrl.listData, ctrl.amount);
                setSelections();
                ctrl.isLoading = false;
            }
        }

        function loadData() {
            if (ctrl.onLazyOpen && ctrl.isLazy && !ctrl.isLazyDataLoaded) {
                ctrl.onLazyOpen().then(function (items) {
                    ctrl.listDataCopyForSearch = _.clone(items);
                    ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
                    ctrl.isLazyDataLoaded = true;
                });
            } else if (ctrl.isLazy && ctrl.isLazyDataLoaded) {
                ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
            } else {
                ctrl.listData = filterElements(ctrl.listDataCopyForSearch);
            }
        }

        function viewValueChanged(newVal) {
            if ((!newVal || !newVal.length) && firstTimeInit) {
                if (ctrl.defaultValue && ctrl.defaultValue.length) {
                    ctrl.selectedItems.push(ctrl.defaultValue);
                    ctrl.ngModelCtrl.$setViewValue(ctrl.selectedItems);
                } else {
                    setInitialTitle();
                }
            } else {
                if (!ctrl.selectedItems.length) {
                    ctrl.selectedItems = _.clone(newVal)
                }
                setSelections();
            }
            firstTimeInit = false;
        }

        function setSelections() {
            if (ctrl.listData) {
                if (!ctrl.isMultiple) {
                    // Single select
                    var selectedItem = _.first(ctrl.selectedItems);
                    if (ctrl.isBarcode && selectedItem) {
                        ctrl.inlineSearch = selectedItem.CustomId;
                    }
                    ctrl.listData.forEach(function (el) {
                        el.selected = (selectedItem && el.DataListItemId === selectedItem.DataListItemId);
                    });
                } else {
                    // Multiple select
                    ctrl.listData.forEach(function (el) {
                        var item = _.find(ctrl.selectedItems, function (o) {
                            return o.DataListItemId === el.DataListItemId;
                        });

                        el.selected = !(!item);
                    });
                }
            }

            if (ctrl.selectedItems.length === 0) {
                setInitialTitle();
            } else {
                ctrl.title = '';
                ctrl.selectedItems.forEach(function (item) {
                    ctrl.title += (item.Title + ', ');
                });
                ctrl.title = ctrl.title.slice(0, -2);
            }
        }

        function clearSearch() {
            ctrl.searchInput = '';
            ctrl.search('');
        }

        function toggleDropdown() {
            if (!ctrl.isOpen) {
                onOpen()
            } else {
                close();
            }
            ctrl.isOpen = !ctrl.isOpen;

            if (ctrl.isOpen) {
                $templateRequest('/Scripts/Components/LazyDatalist/LazyDataListPopup.tpl.html').then(function (tpl) {
                    popup = $compile(tpl)(scope);
                    angular.element(document.body).append(popup);

                    $timeout(function () {
                        scrollContainer = popup.find('.scroll-container').get(0);
                        scrollContainer && bodyScrollLock.disableBodyScroll(scrollContainer);


                        popup.find('ul').on('scroll', function (ev) {
                            if (angular.element(this).scrollTop() + angular.element(this).innerHeight() >= angular.element(this)[0].scrollHeight - 1) {
                                $timeout(function () {
                                    if (ctrl.visibleList.length !== ctrl.listData.length) {
                                        ctrl.visibleList = _.take(ctrl.listData, ctrl.visibleList.length + ctrl.amount);
                                    }
                                });
                            }
                        });
                    }, 300);
                })
            }
        }
        
        function onSelect(item) {
            // Figure out if it already is selected
            const selectedItem = ctrl.selectedItems.find((o) => {
                return o.DataListItemId === item.DataListItemId;
            });
            
            if (selectedItem) {
                // it exists, so we remove it
                if (item.DataListType === DATALIST_TYPE.DEPARTMENT && 
                    ctrl.field.IsUsedForFillingDepartment &&
                    ctrl.field.IsRequired && ctrl.selectedItems?.length === 1) {
                    return;
                }
                
                ctrl.selectedItems = ctrl.isMultiple ?
                    ctrl.selectedItems.filter(selectedItem => selectedItem.DataListItemId !== item.DataListItemId) :
                    [];
                
                item.inUse = false;
            } else {
                if (item.inUse) {
                    return ServiceFormRenderService.checkLimitRuleAndShowPopup(item, () => {
                        $rootScope.$broadcast('goToLimitedReports', {
                            item,
                            listData: ctrl.listData,
                            DataListFilters: {
                                DataListItemId: item.DataListItemId,
                                ServiceFormFieldId: ctrl.field.ServiceFormFieldId
                            }
                        })
                        close();
                    });
                }

                if (ctrl.isMultiple) {
                    // it wasn't selected, so we do that now
                    ctrl.selectedItems.push(item);
                } else {
                    // it wasn't selected, so we do that now
                    ctrl.selectedItems = [item];
                }
                item.inUse = !!item.actionType;
            }

            if (ctrl.isBarcode && ctrl.selectedItems.length) {
                ctrl.inlineSearch = ctrl.selectedItems[0].CustomId;
                ctrl.isForceClose = true;
            }

            ctrl.ngModelCtrl.$setViewValue(_.clone(ctrl.selectedItems));

            if (!ctrl.isMultiple) {
                // close straight away if it is single select
                close();
            } else {
                ctrl.disabled = ctrl.max && (ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length === ctrl.max);
            }
        }
        
        function resetSelections() {
            ctrl.selectedItems = [];
            ctrl.ngModelCtrl.$setViewValue(ctrl.selectedItems);
        }

        function createSearchString(el, valuesString) {
            for (var key in el) {
                if (el.hasOwnProperty(key) && key !== '$$hashKey') {
                    if (typeof el[key] === 'string') {
                        valuesString = valuesString + el[key];
                    } else if (typeof el[key] === 'object') {
                        createSearchString(el[key], valuesString)
                    }
                }
            }
            return valuesString;
        }

        function createSearchBarcodeString(el, valuesString) {
            for (var key in el) {
                if (key === 'CustomId' || key === 'Title') {
                    if (typeof el[key] === 'string') {
                        valuesString = valuesString + el[key];
                    } else if (typeof el[key] === 'object') {
                        createSearchString(el[key], valuesString)
                    }
                }
            }
            return valuesString;
        }

        function findItemsInline(model) {
            if (!ctrl.isLazyDataLoaded) {
                return ctrl.onLazyOpen().then(function (items) {
                    ctrl.listDataCopyForSearch = _.clone(items);

                    return getFilteredItems(ctrl.listDataCopyForSearch, model);
                });
            }

            return getFilteredItems(ctrl.listDataCopyForSearch, model);
        }

        function getFilteredItems(listDataCopyForSearch, model) {
            var eqItem = _.find(filterElements(listDataCopyForSearch), {CustomId: model});

            if (eqItem) {
                if (!ctrl.isForceClose) {
                    ctrl.onSelect(eqItem);
                }
                return eqItem;
            } else {
                ctrl.selectedItems = [];
                ctrl.ngModelCtrl.$setViewValue([]);
                return _.filter(filterElements(listDataCopyForSearch), function (el) {
                    var string = '';
                    string += ctrl.isBarcode ? createSearchBarcodeString(el, string) : createSearchString(el, string);

                    return string.toLowerCase().indexOf(model.toLowerCase()) !== -1;
                });
            }
        }

        function findItems(model) {
            return _.filter(filterElements(ctrl.listDataCopyForSearch), function (el) {
                var string = '';
                string += ctrl.isBarcode ? createSearchBarcodeString(el, string) : createSearchString(el, string);

                return string.toLowerCase().indexOf(model.toLowerCase()) !== -1;
            });
        }

        function inlineSearch(model) {
            ctrl.isLoading = true;
            $q.when(findItemsInline(model), function (filteredItems) {
                ctrl.predictionList = _.take(filteredItems, 5);
                ctrl.isOpenInline = model.length && !ctrl.isForceClose;
                ctrl.isLoading = false;
                ctrl.isForceClose = false;
            });
        }

        function selectInline(item) {
            ctrl.inlineSearch = item.CustomId
        }

        function search(model) {
            var filteredItems = findItems(model);

            ctrl.listData = filteredItems;
            ctrl.visibleList = _.take(ctrl.listData, ctrl.amount);
            if (filteredItems.length) {
                ctrl.isEmptySearch = false;
            } else {
                ctrl.isEmptySearch = true;
            }
        }

        function enter($event) {
            $event.preventDefault();
        }

        function destroy() {
            dataListItemAddedListener();
            scope.$destroy();
        }
    }
})();