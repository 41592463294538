(() => {
        'use strict';

        angular.module('App')
            .component('blockedUsers', {
                template: require('./BlockedUsersComponent.html'),
                controller: ['$http', '$filter', 'ToastFactory', 'ConfirmPopupService', BlockedUsersController],
                controllerAs: 'ctrl',
            });

        function BlockedUsersController($http, $filter, ToastFactory, ConfirmPopupService) {
            const ctrl = this;

            ctrl.$onInit = init;
            ctrl.unblock = unblock;

            function init() {
                $http.get('/Profile/GetBlockedUsers').then((blockedUsers) => {
                    ctrl.blockedUsers = blockedUsers.data;
                    ctrl.isLoaded = true;
                })
            }

            function unblock(unblockedUser) {
                ConfirmPopupService
                    .open({
                        title: $filter('translate')('PROFILE.BLOCK.UNBLOCK') + ' ' + unblockedUser.BlockedUserName +'?',
                        message: 'PROFILE.BLOCK.UNBLOCK_CONFIRMATION_DESCRIPTION',
                        yesText: 'PROFILE.BLOCK.UNBLOCK',
                        noText: 'CANCEL'
                    })
                    .then(() => $http.post(`/Profile/UnblockUser/${unblockedUser.BlockedUserToken}`))
                    .then(() => {
                        ctrl.blockedUsers = ctrl.blockedUsers.filter((user) => user.BlockedUserToken !== unblockedUser.BlockedUserToken);
                        ToastFactory.successTranslated('PROFILE.BLOCK.UNBLOCK_USER_DONE');
                    });
            }
        }
    }
)();