(() => {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('contentOverview', {
                    parent: 'base',
                    url: '/contentOverview/:token/',
                    params: {
                        backState: null,
                        data: {}
                    },
                    template: '<content-categories class="view-page" content-overview="ctrl.contentOverview"></content-categories>',
                    resolve: {
                        contentOverview: ['$http', '$stateParams', 'base', 'Page', function ($http, $stateParams, base, Page) {
                            if (base) {
                                return $http.get('/ContentModule/GetSettings/' + $stateParams.token)
                                    .then(contentProcessing);
                            }

                            function contentProcessing(resp) {
                                const data = resp.data

                                data.isDataPreloaded = true;

                                if (!data.ShowCategoryView) {
                                    Page.stateGo('content', $stateParams.token, data.CategoryToken, $stateParams.backState, data);
                                } else {
                                    return data
                                }
                            }
                        }]
                    },
                    controller: 'ContentOverviewController as ctrl'
                })
        }])
        .controller('ContentOverviewController',
            ['$scope', '$http', '$stateParams', 'Page', 'contentOverview', 'events', ContentOverviewController]
        );


    function ContentOverviewController($scope, $http, $stateParams, Page, contentOverview, events) {
        const ctrl = this;

        ctrl.contentOverview = contentOverview;

        if ($stateParams.backState) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token,
                $stateParams.backState.extra, null, $stateParams.backState.options
            );
        } else {
            Page.showBackButton('home');
        }

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, () => {
            Page.stateReload();
        });

        // Set Page Title
        Page.setTitle(ctrl.contentOverview.PageTitle, 'CONTENT.CATEGORY_OVERVIEW');
    }
})();
