(function () {
    'use strict';

    var app = angular.module('App');

    app.component('searchEvents', {
        template: require('./SearchEventsComponent.tpl.html'),
        controllerAs: 'ctrl',
        controller: ['$scope', 'CalendarRenderService', SearchEventsController],
        bindings: {
            searchString: '<'
        }
    });

    function SearchEventsController($scope, CalendarRenderService) {
        const ctrl = this;
        let removeSearchWatcher;

        ctrl.$onInit = init;
        ctrl.search = search;
        ctrl.paging = paging;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.isSearched = false;

            removeSearchWatcher = $scope.$watch('ctrl.searchString', (newVal) => {
                if (newVal) {
                    ctrl.searchString = newVal;
                    ctrl.search();
                }
            });
        }

        function search() {
            if (!ctrl.isSearchInProgress) {
                if (ctrl.searchString) {
                    ctrl.isSearchInProgress = true;
                    CalendarRenderService.search(ctrl.searchString).then(({Events, BatchLastEventId}) => {
                        ctrl.events = CalendarRenderService.mergeEventsWithCategories(Events);
                        ctrl.lastEventId = BatchLastEventId;
                        ctrl.isSearchInProgress = false;
                        ctrl.isSearched = true;
                    });
                } else {
                    ctrl.events = [];
                }
            }
        }

        function paging() {
            ctrl.layzyLoading = true;
            CalendarRenderService.getCalendarEvents({
                SearchString : ctrl.searchString,
                AfterEventId: ctrl.lastEventId
            }).then(({events, lastEventId}) => {
                ctrl.lastEventId = lastEventId;
                ctrl.layzyLoading = false;
                ctrl.events = [...ctrl.events, ...events];
            })
        }

        function destroy() {
            removeSearchWatcher && removeSearchWatcher();
        }
    }
})();