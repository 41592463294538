(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('selectList', ['BasicHelper',
        function (BasicHelper) {
            return {
                restrict: 'E',
                replace: true,
                template: require('./Templates/SelectList.directive.html'),
                link: function (scope) {

                  // Methods
                  function submit() {
                    var data = [];
                    // Grab data from options
                    for (var i = 0; i < scope.options.length; i++) {
                      var option = scope.options[i];
                      if (option.selected) {
                        data.push(option);
                      }
                    }

                    if (!scope.isMultiple) {
                      if (data.length) {
                        data = data[0];
                      } else {
                        data = null;
                      }
                    }

                    scope.dismiss(null, data);
                  }

                  function select(option) {
                    if (!scope.isMultiple) {
                      for (var i = 0; i < scope.options.length; i++) {
                        scope.options[i].selected = false;
                      }
                      option.selected = true;
                    } else {
                      option.selected = !(option.selected);
                    }
                  }

                  // Bindings
                  scope.submit = submit;
                  scope.select = select;
                }
            }
        }
    ]);
})();