(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('metricLabel', ['$log', '$http', 'Page', 'Profile', 'TimeLocale', 'ChartHelper', '$sce',
        function ($log, $http, Page, Profile, TimeLocale, ChartHelper, $sce) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/MetricLabel.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chartIsLoading = true;
                    scope.data.negative = true;
                    scope.chart = {
                        Date: null,
                        Label: null
                    };

                    var filterDepartmentId = Profile.getProfile().FilterDepartmentId;
                    var hasMultipleDepartments = Profile.getProfile().HasMultiDepartment;

                    var url = Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken;
                    if (hasMultipleDepartments) { url += "&departmentid=" + filterDepartmentId; }


                    // get data
                    $.getJSON(url,
                        function (response) {
                            if (response.MetricData !== null) {
                                // Date
                                if (response.DateLastUpdated !== null) {
                                  scope.chart.Date = TimeLocale.dateTimeFormat(response.DateLastUpdated, "H:mm | D MMM YYYY");
                                }
                                scope.chart.Label = response.MetricData.Label;
                            } else {
                                scope.chart.Label = "-";
                            }
                            //scope.chart.Label = $sce.trustAsHtml(scope.chart.Label);
                            scope.chartIsLoading = false;
                            scope.$apply();
                        }).fail(function () {
                            scope.chartIsLoading = false;
                            scope.chart.Label = "-";
                            scope.$apply();
                        });
                }
            }
        }
    ]);
})();