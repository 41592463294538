(function () {
    'use strict';

    var app = angular.module('App');

    app.component('monthsView', {
        templateUrl: '/Scripts/Components/MonthsView/MonthsViewComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$element', '$timeout', '$scope', MonthsViewController],
        bindings: {
            calendarData: '=',
            selectedDay: '=',
            filterStartDay: '=',
            filterEndDay: '=',
            isActive: '<',
            onScrollPrev: '<',
            onScrollNext: '<',
            showWeekNumber: '<',
            withoutPreselectedDay: '<'
        }
    });

    function MonthsViewController($element, $timeout, $scope) {
        var ctrl = this, scrolledContainer, throttledScroll = _.throttle(scrollEvents, 500);

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.dayClick = dayClick;
        ctrl.getWeekNumber = getWeekNumber;
        ctrl.isDayInRange = isDayInRange;
        ctrl.isDayLastInRange = isDayLastInRange;
        ctrl.isDayFirstInRange = isDayFirstInRange;

        var watcher = $scope.$watch('ctrl.isActive', function (isActive) {
            if (isActive) {
                $timeout(function () {
                    scrollToMonth($element.find('.current-month'));
                });
            }
        })

        function init() {
            ctrl.dayNames = ctrl.calendarData.daysOfWeek;
            ctrl.shortDayNames = ctrl.calendarData.shortDaysOfWeek;
            ctrl.todayDateNumber = parseInt(moment().format('DD'));
            ctrl.selectedDay = !ctrl.withoutPreselectedDay && ctrl.calendarData.flatDays.find(day => day.isCurrent);

            scrolledContainer = $element.find('.scrolled-months');
            scrolledContainer.on('scroll', throttledScroll);
        }

        function destroy() {
            scrolledContainer && scrolledContainer.off('scroll', throttledScroll);
            watcher && watcher();
        }

        function dayClick(day) {
            ctrl.selectedDay = day;
        }

        function scrollToMonth(month) {
            var scrollTop = month.offset().top - scrolledContainer.offset().top + scrolledContainer.scrollTop();
            scrolledContainer.scrollTop(scrollTop);
        }

        function scrollEvents() {
            if (scrolledContainer.scrollTop() + scrolledContainer.innerHeight() > scrolledContainer[0].scrollHeight - 50) {
                if (ctrl.onScrollNext) {
                    ctrl.onScrollNext(ctrl.calendarData)
                }
            } else if (scrolledContainer.scrollTop() === 0) {
                var firstMoth = $element.find('.month:first');

                if (ctrl.onScrollPrev) {
                    ctrl.onScrollPrev(ctrl.calendarData);
                }
                
                $timeout(function () {
                    scrollToMonth(firstMoth);
                });
            }
        }

        function getWeekNumber(week) {
            if (week && week[0]) {
                return week[0].fullDate.isoWeek();
            }
        }
        
        function isDayInRange(day) {
            return day.fullDate.isBetween(ctrl.filterStartDay, ctrl.filterEndDay);
        }
        
        function isDayLastInRange(day) {
            return moment(ctrl.filterEndDay).isSame(moment(day.fullDate), 'day');
        }
        function isDayFirstInRange(day) {
            return moment(ctrl.filterStartDay).isSame(moment(day.fullDate), 'day');
        }
    }
})();
