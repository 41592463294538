(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('walkthroughSlide', ['$http', 'Profile', '$translate', 'Page', '$timeout', 'toastr', 'WalkthroughFactory', '$interval',
        function ($http, Profile, $translate, Page, $timeout, toastr, WalkthroughFactory, $interval) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough-Slide.tpl.html',
                scope: {
                    slide: '=',
                    done: '&',
                    next: '&'
                },
                controller: ['$scope', '$element', function ($scope, $element) {

                    // Init
                    InitDirective();
                    function InitDirective() {
                        $scope.parent = $($element).parent();
                    }

                    // Methods
                    function Done() {
                        $scope.done();
                    }

                    // Objects

                    // Bindings
                    $scope.Done = Done;
                }]
            }
        }
    ]);
})();