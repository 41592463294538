(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('setFocus', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                function findFocusable() {
                    return $element.find('a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable]')
                        .not('[tabindex=-1], [disabled], :hidden');
                }

                $timeout(function () {
                    findFocusable().first().focus();
                }, 300);
            }
        }
    }
    ]);
})();