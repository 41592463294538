(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('iframe', {
                    parent: 'base',
                    url: '/iframe/:token/:extra',
                    templateUrl: '/Iframe/Iframe',
                    resolve: {
                        iframeData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Iframe/GetIframe/?token=' + $stateParams.token + '&extra=' + $stateParams.extra);
                            }
                            return;
                        }]
                    },
                    controller: 'IframeController as IframeCtrl'
                });

        }
    ]);

    app.controller('IframeController', ['$window', 'Page', '$scope', 'iframeData', '$sce', '$http', '$stateParams', '$element', 'events', 'Profile',
        function ($window, Page, $scope, iframeData, $sce, $http, $stateParams, $element, events, Profile) {
            var ctrl = this;
            ctrl.Page = Page;

            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
            } else {
                Page.showBackButton('home');
            }
            Page.showMenuButton(!iframeData.data.HideMenuButton);

            Page.setTitle(iframeData.data.Title);
            $scope.iframeUrl = $sce.trustAsResourceUrl(iframeData.data.Url);
            $scope.url = iframeData.data.Url;
            $scope.background = iframeData.data.BackgroundImage;
            $scope.title = iframeData.data.Title;
            $scope.urlPreview = iframeData.data.UrlPreview;
            $scope.iframeToken = iframeData.data.IframeToken;

            if (iframeData.data.LockVerticalScroll) {
                angular.element('body').addClass('lock-vertical-scrolling')
            }

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateReload();
            });

            // Listen for iframeEvents
            var iframeEvents = iframeData.data.Events;
            if (iframeEvents && iframeEvents.length) {
                $scope.$on(events.POST_MESSAGE, function (ev, args) {
                    IframeEventCallback(iframeEvents, args);
                });
            }

            function IframeEventCallback(iframeEvents, args) {
                var origin = args.origin;
                var data = args.data;

                // Only allow if same origin
                if (_.startsWith($scope.iframeUrl, origin)) {
                    // Only accept objects
                    if (_.isObject(data) && !_.isString(data)) {
                        // Read Type
                        if (!_.isEmpty(data.type) && _.isString(data.type) && data.type.toLowerCase() === "relesys_iframeevent") {
                            // Read Key
                            if (!_.isEmpty(data.key) && _.isString(data.key)) {
                                ValidateIframeEventKey(iframeEvents, data.key);
                            }
                        }
                    }
                }
            }

            function ValidateIframeEventKey(iframeEvents, key) {
                _.forEach(iframeEvents, function (o) {
                    if (o.IframeEventKey === key) {
                        SendEvent(key);
                    }
                });
            }

            function SendEvent(key) {
                var obj = {
                    IframeToken: $scope.iframeToken,
                    Key: key
                };
                $http.post('/iframe/PostEvent', obj).then(function (response) {
                    // Do nothing as this happens in the background
                    Profile.reloadPoints();
                }, function (response) {
                    $translate('ERROR.GENERAL').then(function (translation) {
                        toastr.error(translation);
                    });
                });
            }

            $scope.$on('$destroy', function () {
                angular.element('body').removeClass('lock-vertical-scrolling')
            });
        }
    ]);
})();