(function () {
    'use strict';

    angular
        .module('App')
        .component('mediaPreview', {
            template: require('./MediaPreviewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$attrs', '$rootScope', '$compile', '$timeout',
                'ResponsiveService', 'BasicHelper', 'AttachmentsViewerService', 'MEDIA_TYPE', MediaPreviewController],
            bindings: {
                items: '=',
                noLazy: '<',
                editable: '<',
                onImageComment: '<',
                enableImageComment: '<',
                selectedIndex: '=?',
            }
        })

    function MediaPreviewController($scope, $element, $attrs, $rootScope, $compile, $timeout, ResponsiveService,
                                    BasicHelper, AttachmentsViewerService, MEDIA_TYPE) {
        const ctrl = this,
            nonFileMediaTypes = [MEDIA_TYPE.INITIALIZED, MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO, MEDIA_TYPE.AUDIO];
        let removeItemsWatcher, removeIndexWatcher;

        ctrl.MEDIA_TYPE = MEDIA_TYPE;
        ctrl.$onInit = init;
        ctrl.isFile = isFile;
        ctrl.isMedia = isMedia;
        ctrl.openMediaViewer = openMediaViewer;
        ctrl.getLinkUrl = getLinkUrl;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.noLazy = ctrl.noLazy ? ctrl.noLazy : false;
            ctrl.onlyDownloadableFiles = false;
            ctrl.onlyDownloadableFilesThumbnail = null;
            ctrl.isGalleryView = ctrl.items.length > 1 && typeof ctrl.selectedIndex === 'number';
            // Get Preview
            removeItemsWatcher = $scope.$watchCollection('ctrl.items', updatePreview);
            
            if (ctrl.isGalleryView) {
                removeIndexWatcher = $scope.$watchCollection('ctrl.selectedIndex', updatePreview);
            }
        }

        function updatePreview() {
            ctrl.previews = [];
            
            $timeout(() => {
                if (ctrl.items && ctrl.items.length > 0) {
                    if (ctrl.items.length > 1) {
                        ctrl.items.forEach(itemFile => {
                            const showThumbnail = haveThumbnail(itemFile);
                            if (showThumbnail && ctrl.previews.length < 3) {
                                itemFile.showThumbnail = showThumbnail;
                                ctrl.previews.push(itemFile);
                            }
                        });
                        ctrl.onlyDownloadableFiles = ctrl.previews.length === 0 && ctrl.onlyDownloadableFiles === false;
                        if (typeof ctrl.selectedIndex === 'number') {
                            ctrl.selectedIndex = ctrl.items.findIndex(item => item.isCurrent);
                            ctrl.previews = [ctrl.items[ctrl.selectedIndex]]
                        } else {
                            ctrl.items = _.sortBy(ctrl.items, ['showThumbnail']);
                            ctrl.previews = _.take(ctrl.items, 3);
                        }
                    } else {
                        ctrl.items[0].showThumbnail = haveThumbnail(ctrl.items[0]);
                        ctrl.previews.push(ctrl.items[0]);
                    }
                }

                const width = $element.width() > 0 ? $element.width() : angular.element('body').width();
                if (ctrl.previews.length > 1) {
                    ctrl.imageVariant = 'W' + BasicHelper.getImageVariantBySize(width / 2, 600);
                } else {
                    ctrl.imageVariant = 'W' + BasicHelper.getImageVariantBySize(width);
                }
            })
        }

        function openMediaViewer($event, selectedIndex) {
            $event.stopPropagation();
            $event.preventDefault();
            const index = ctrl.items.findIndex((item) => item.MediaId === ctrl.previews[selectedIndex].MediaId);
            const selectedAttachmentIndex = index > -1 ? index : 0;
            AttachmentsViewerService.openViewerWithMedia(
                ctrl.isGalleryView ? _.cloneDeep(ctrl.items) : _.sortBy(_.cloneDeep(ctrl.items), ['showThumbnail']), selectedAttachmentIndex, ctrl.enableImageComment && ctrl.onImageComment);
        }

        function haveThumbnail(media) {
            return media.MediaTypeId === MEDIA_TYPE.IMAGE || media.MediaTypeId === MEDIA_TYPE.VIDEO ||
                angular.isString(media.ThumbnailUrl) && media.ThumbnailUrl.length > 0;
        }

        function isFile(media) {
            return nonFileMediaTypes.indexOf(media.MediaTypeId) < 0;
        }

        function isMedia(media) {
            return nonFileMediaTypes.indexOf(media.MediaTypeId) >= 0
        }

        function getLinkUrl(media) {
            if (BasicHelper.isWrapperApp()) {
                return media.DownloadUrl;
            } else {
                return media.Url;
            }
        }

        function destroy() {
            removeItemsWatcher && removeItemsWatcher();
            removeIndexWatcher && removeIndexWatcher();
        }
    }
})();