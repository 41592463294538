(function () {
    'use strict';

    angular
        .module('App')
        .component('fillingLoadingItem', {
            template: require('./FillingLoadingItemComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: [FillingLoadingItemController],
        });

    function FillingLoadingItemController() {}
})();