(function () {
    'use strict';

    angular.module('App')
        .component('bookmarksDropdown', {
            template: require('./BookmarksDropdownComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['Page', 'BookmarkService', BookmarksDropdownController],
            bindings: {}
        });

    function BookmarksDropdownController(Page, BookmarkService) {
        var ctrl = this;
        ctrl.BookmarkService = BookmarkService;
        ctrl.bookmarksCount = 0;
        ctrl.hideSeeMoreBtn = false;
        ctrl.openBookmarksPage = openBookmarksPage;

        function openBookmarksPage() {
            Page.stateGo('bookmarks', null, null, Page.getPageTransition());
            ctrl.BookmarkService.toggleDropdownVisibility();
        }
    }
})();