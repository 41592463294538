(function () {
    'use strict';
    angular.module('App')
        .directive('tagging', ['MentionService', TaggingDirective]);

    function TaggingDirective(MentionService) {
        return {
            controller: ['$controller', '$scope', '$element', '$attrs', '$q', '$timeout', '$document',
                function ($controller, $scope, $element, $attrs, $q, $timeout, $document) {
                    angular.extend(this, $controller('uiMention', {
                        $element: $element,
                        $scope: $scope,
                        $attrs: $attrs,
                        $q: $q,
                        $timeout: $timeout,
                        $document: $document
                    }));
                }],
            controllerAs: 'tagging',
            require: ['ngModel', 'tagging'],
            link: function ($scope, $element, $attrs, args) {
                var ngModel = args[0], tagging = args[1];

                $scope.ctrl.tagging = tagging;

                tagging.delimiter = "#";
                tagging.searchPattern = new RegExp('(?:\\s+|^)#(?:[\\S])+$');
                tagging.decodePattern = new RegExp('#\[[\\s\\w]+:[+\\S]', 'gi');

                tagging.label = function (choice) {
                    return '#' + choice.Name.toLowerCase();
                };

                tagging.encode = function (choice) {
                    return '#' + choice.Name.toLowerCase();
                };

                tagging.findChoices = function (match) {
                    tagging.caretPosition = $($element).getCaretPosition();

                    return MentionService.sortHashtagsSuggestions($scope.ctrl.suggestedHashtags, match[0]);
                };

                tagging.init(ngModel);
            }
        };
    }
})();
