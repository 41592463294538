(function () {
    'use strict';

    angular
        .module('App')
        .component('contentLibrary', {
            template: require('./ContentLibraryComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$state', '$scope', '$filter', '$timeout', '$stateParams', 'ResponsiveService',
                'ContentWrapperService', 'ContentLibraryService', 'HeaderButtonsFactory', 'ToastFactory',
                'Page', 'CookieService', 'ContentSortingService', ContentLibraryController],
            bindings: {
                contentData: '<'
            }
        });

    function ContentLibraryController($state, $scope, $filter, $timeout, $stateParams, ResponsiveService,
                                      ContentWrapperService, ContentLibraryService, HeaderButtonsFactory, ToastFactory,
                                      Page, CookieService, ContentSortingService) {
        const ctrl = this;
        let searchStringListener, searchFiltersWatch, feedFiltersWatch;

        ctrl.searchQuery = {};

        ctrl.$onInit = init;
        ctrl.paging = paging;
        ctrl.searchPaging = searchPaging;
        ctrl.onSearch = onSearch;
        ctrl.onDateFilterSelected = onDateFilterSelected;

        ctrl.openItemFromFeed = openItemFromFeed;
        ctrl.openItemFromSearch = openItemFromSearch;
        ctrl.isSearchSticky = isSearchSticky;
        ctrl.isFilterActive = isFilterActive;
        ctrl.isMultipleFiltersActive = isMultipleFiltersActive;
        ctrl.resetFilters = resetFilters;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.loadingItems = true;
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.searchPlaceholder = $filter('translate')('CONTENT.SEARCH_IN') + ' ' + ctrl.contentData.PageTitle;
            ctrl.searchResultsOptions = ctrl.contentData.SearchResultsSortingOptions &&
                ContentSortingService.getOptions(false, ctrl.contentData.SearchResultsSortingOptions);

            !ctrl.isDesktop && HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            ContentWrapperService.resetContentIdsToExclude();

            getContent();
            initWatchers();

            const cookieAccountModuleId = CookieService.get('accountModuleId');
            if (Number(cookieAccountModuleId) !== ctrl.contentData.AccountModuleId) {
                CookieService.set('accountModuleId', ctrl.contentData.AccountModuleId);
                resetAllSearch();
                ctrl.showSearch = false;
            }

            if (ContentWrapperService.getSearchState().searchText) {
                ctrl.hideResults = true;
                ctrl.searchQuery = ContentWrapperService.getSearchState();
                ctrl.endDate = ctrl.searchQuery.endDate;
                ctrl.startDate = ctrl.searchQuery.startDate;
                ctrl.showSearch = true;
                ctrl.includeFuturePosts = ctrl.searchQuery.includeFuturePosts;
                ctrl.searchItemsReceived = true;
                $timeout(() => {
                    $timeout(() => {
                        document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                        ctrl.hideResults = false;
                    })
                }, 300);
            }

            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
        }

        function getContent(isPaging) {
            ctrl.loadingItems = true;
            return ContentWrapperService.getContents({
                offset: ctrl.nextOffset,
                limit: isPaging ? 20 : (!ctrl.contentData.Settings.SwipeToRead ? 1000000 : null),
                token: ctrl.contentData.AccountModuleToken,
                categoryToken: ctrl.contentData.CategoryToken,
                sortType: ctrl.sortType,
                startTime: ctrl.searchQuery.startDate,
                endTime: ctrl.searchQuery.endDate,
                ContentIdsToExclude: ContentWrapperService.getContentIdsToExclude()
            }).then(({Items, NextOffset}) => {
                const loadedItems = Items.map(item => prepareItem(item));

                if (isPaging) {
                    ctrl.contentData.Items = [...ctrl.contentData.Items, ...loadedItems]
                } else {
                    ctrl.contentData.Items = loadedItems;
                }

                ctrl.nextOffset = NextOffset;
                ctrl.loadingItems = false;
            })
        }

        function initWatchers() {
            searchStringListener = $scope.$watch('ctrl.searchQuery.searchText', (newValue, oldValue) => {
                if (newValue !== oldValue && ctrl.searchQuery?.searchText?.length === 0) {
                    document.querySelector('html').scrollTop = 0;
                    ctrl.searchItemsReceived = false;
                    ctrl.searchQuery.sortType = 5; // most relevant
                    if (isFilterActive()) {
                        getContent();
                    }
                }
            });

            searchFiltersWatch = $scope.$watch(
                '[ctrl.searchQuery.includeFuturePosts,ctrl.searchQuery.sortType,ctrl.searchQuery.startDate,ctrl.searchQuery.endDate]',
                (newVal, oldVal) => {
                    if (newVal !== oldVal && ctrl.searchQuery?.searchText?.length) {
                        onSearch();
                    }
                })

            feedFiltersWatch = $scope.$watch(
                '[ctrl.sortType, ctrl.searchQuery.includeFuturePosts, ctrl.startDate, ctrl.endDate]',
                (newVal, oldVal) => {
                    if (newVal !== oldVal && !ctrl.searchQuery?.searchText?.length) {
                        CookieService.set('sortTypeId-' + $state.params.token, ctrl.sortType);
                        ContentWrapperService.resetContentIdsToExclude();
                        getContent();
                    }
                });
        }

        function paging() {
            if (!ctrl.loadingItems && ctrl.nextOffset) {
                ctrl.loadingItems = true;

                getContent(true).catch(_ => {
                    ctrl.loadingItems = false;
                    ctrl.moreItemsToLoad = false;
                    ToastFactory.error('Could not load more as an error occured');
                });
            }
        }

        function openItemFromFeed(item) {
            const libraryData = {
                item: item,
                contentData: ctrl.contentData
            }

            Page.stateGo('contentLibrary', item.ContentToken, '?AccountModuleToken=' + ctrl.contentData.AccountModuleToken, Page.getPageTransition(), libraryData);
        }

        function openItemFromSearch(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            ContentWrapperService.setSearchState(ctrl.searchQuery);
            item.EntityLink.EntityType = 'ContentLibrary.Post';
            Page.stateGoLinkV2(item.EntityLink);
        }

        function isSearchSticky() {
            return ctrl.startDate || ctrl.endDate || ctrl.searchQuery.includeFuturePosts || ctrl.searchQuery?.searchText?.length || ResponsiveService.isMobile();
        }

        function isFilterActive() {
            return ctrl.startDate || ctrl.endDate || ctrl.searchQuery.includeFuturePosts;
        }

        function isMultipleFiltersActive() {
            return ctrl.searchQuery.includeFuturePosts && ctrl.startDate || ctrl.searchQuery.includeFuturePosts && ctrl.endDate;
        }

        function onSearch() {
            document.querySelector('html').scrollTop = 0;
            if (ctrl.searchQuery?.searchText?.length > 0) {
                if (!ctrl.searchItemsReceived) {
                    ctrl.loadingItems = true;
                } else {
                    ctrl.processing = true;
                }
                search()
                    .then(({BatchLastEntityId, Items}) => {
                        ctrl.searchQuery = {
                            ...ctrl.searchQuery,
                            searchResultsList: Items,
                            batchLastEntityId: BatchLastEntityId,
                        }
                        ctrl.lastSearchedText = ctrl.searchQuery.searchText;
                        ctrl.searchItemsReceived = true;
                        ctrl.loadingItems = false;
                        ctrl.processing = false;
                    })
                    .catch(err => {
                        if (err.status !== -1) {
                            ToastFactory.errorTranslated();
                        }
                    })
            }
        }

        function onDateFilterSelected(selectedDateFilterId, {startDate, endDate} = {}) {
            ctrl.startDate = startDate;
            ctrl.endDate = endDate;
            if (selectedDateFilterId) {
                ctrl.searchQuery = {...ctrl.searchQuery, startDate, endDate, selectedDateFilterId};
            } else {
                delete ctrl.searchQuery.startDate;
                delete ctrl.searchQuery.endDate;
                delete ctrl.searchQuery.selectedDateFilterId;
            }
        }

        function searchPaging() {
            ctrl.layzyLoading = true;
            search(true)
                .then(({BatchLastEntityId, Items}) => {
                    ctrl.searchQuery = {
                        ...ctrl.searchQuery,
                        batchLastEntityId: BatchLastEntityId,
                        searchResultsList: [...ctrl.searchQuery.searchResultsList, ...Items]
                    };
                })
                .catch(() => {
                    ToastFactory.errorTranslated();
                })
                .finally(() => {
                    ctrl.layzyLoading = false;
                })

        }

        function search(isPaging) {
            return ContentWrapperService.getSearchContentResults({
                searchText: ctrl.searchQuery.searchText,
                accountModuleId: ctrl.contentData.AccountModuleId,
                sortType: ctrl.searchQuery.sortType ? ctrl.searchQuery.sortType : 5, // 5 - most relevant
                afterEntityId: isPaging && ctrl.searchQuery.batchLastEntityId,
                startDate: ctrl.searchQuery.startDate ? moment(ctrl.searchQuery.startDate).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                endDate: ctrl.searchQuery.endDate ? moment(ctrl.searchQuery.endDate).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                selectedDateFilterId: ctrl.searchQuery.selectedDateFilterId,
                includeFuturePosts: ctrl.searchQuery.includeFuturePosts,
                limit: isPaging ? 10 : 20
            })
        }

        function prepareItem(item) {
            item.readingProgress = ContentLibraryService
                .getItemReadingProgress(ctrl.contentData.AccountModuleToken, item.ContentToken)
                .current || (item.IsSwiped ? 100 : 0);
            item.readingTotal = ContentLibraryService
                .getItemReadingProgress(ctrl.contentData.AccountModuleToken, item.ContentToken)
                .total || (item.IsSwiped ? 100 : 0);

            return item;
        }

        function getHeaderButtons() {
            return [{
                place: 'right',
                icon: 'search',
                active: ctrl.showSearch,
                activeIcon: 'close',
                onClick: () => {
                    ctrl.showSearch = !ctrl.showSearch;
                    resetAllSearch();
                }
            }];
        }

        function resetFilters() {
            const searchString = ctrl.searchQuery.searchText;
            document.querySelector('html').scrollTop = 0;
            if (ctrl.searchItemsReceived) {
                ctrl.processing = true;
            }
            ctrl.startDate = null;
            ctrl.endDate = null;
            ctrl.searchQuery = {};
            ctrl.resetDateFilter();
            ctrl.searchQuery.searchText = searchString;
        }

        function resetAllSearch() {
            ctrl.searchQuery = {};
            document.querySelector('html').scrollTop = 0;
            ContentWrapperService.cleanSearchState();
            ctrl.searchItemsReceived = false;
            ctrl.resetDateFilter && ctrl.resetFilters();
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            searchStringListener();
            searchFiltersWatch();
            feedFiltersWatch();

            if ($state.current.name !== 'contentLibrary') {
                ContentWrapperService.cleanSearchState();
            }
        }
    }
})();