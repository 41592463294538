(function () {
    'use strict';

    angular.module('App')
        .component('cancelEventPopup', {
            template: require('./CancelEventPopupComponment.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$scope', 'LanguageSelectorService', 'CalendarRenderService',
                'TranslationService', 'Page', CancelEventPopupController],
            bindings: {
                availableCultures: '<',
                onCancelEvent: '<',
                eventName: '@',
                eventToken: '<',
                cancelType: '<'
            }
        })

    function CancelEventPopupController($element, $timeout, $scope, LanguageSelectorService, CalendarRenderService, TranslationService, Page) {
        var ctrl = this, translationWatcher;

        ctrl.languages = [];
        ctrl.translatedText = [];
        ctrl.sendNotifications = false;

        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.getTranslation = getTranslation;
        ctrl.isTranslateDisabled = isTranslateDisabled;
        ctrl.selectLanguage = LanguageSelectorService.selectLanguage;
        ctrl.sendCancellation = sendCancellation;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;

        function init() {
            ctrl.availableCultures.then(function (cultures) {
                ctrl.languages = LanguageSelectorService.updateLanguagesInfo(cultures);
            });

            $timeout(() => {
                initTranslationWatcher();
            });
        }

        function close() {
            $element.remove()
        }

        function sendCancellation() {
            CalendarRenderService
                .cancelEvent(ctrl.eventToken, ctrl.languages, ctrl.cancelType, ctrl.sendNotifications)
                .then(resp => {
                    ctrl.onCancelEvent && ctrl.onCancelEvent(resp);
                    close();
                })
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.languages[ctrl.currentIndex]?.CultureId;
                const languages = ctrl.languages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function translate() {
            const {eventToken, languages} = ctrl;
            ctrl.isTranslating = true;

            TranslationService.translateCancelReason(eventToken, languages, ctrl.currentIndex).then(translations => {
                ctrl.languages = ctrl.languages.map(language => ({ ...language, AutoTranslated: false}));

                translations.translations && translations.translations.forEach(translation => {
                    const locale = ctrl.languages.find(culture => culture.CultureId === translation.CultureId);

                    locale.CancelReason = translation.CancelReason;
                    locale.AutoTranslated = true;
                })
                ctrl.translatedText = _.cloneDeep(ctrl.languages);
            }).finally(() => {
                ctrl.isTranslating = false;
                ctrl.translateDisabled = true;
                ctrl.languages[ctrl.currentIndex].AutoTranslated = false;
            })
        }

        function isTranslateDisabled() {
            return ctrl.isTranslating || ctrl.translateDisabled
        }

        function initTranslationWatcher() {
            translationWatcher = $scope.$watch(() => {
                    return (ctrl.languages[ctrl.currentIndex]?.CancelReason?.trim() || '');
                },
                (newVal) => {
                    const currentText = ctrl.translatedText[ctrl.currentIndex]?.CancelReason?.trim() || '';
                    ctrl.translateDisabled = !newVal?.length || newVal === currentText || ctrl.languages.length < 2;
                    
                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.languages[ctrl.currentIndex].AutoTranslated = false;
                    }
                }
            )
        }

        function isShowTranslationChangeWarning() {
            return ctrl.languages
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.CancelReason?.trim() !== item?.CancelReason?.trim();
                })
        }
    }
})();