(() => {
    'use strict';
    
    angular.module('App')
        .component('absenceDropdownComponent', {
            template: require('./AbsenceDropdownComponent.html'),
            controller: ['$scope', '$element', 'Page', AbsenceDropdownComponentController],
            controllerAs: 'ctrl',
            bindings: {
                options: '<',
                selectedItem: '<',
                closeAction: '<'
            }
        });

    function AbsenceDropdownComponentController($scope, $element, Page) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.onLeaveSelected = onLeaveSelected;

        function init() {
            Page.setMainContainerInert(true);
        }

        function close() {
            Page.setMainContainerInert(false);
            $scope.$destroy();
            $element.remove();            
            ctrl.closeAction && ctrl.closeAction(ctrl.selectedItem);
        }  
        
        function onLeaveSelected(item) {
            ctrl.selectedItem = item;
            ctrl.options = ctrl.options.map(option => ({ ...option, selected: option.id === item.id }));
            close();
        }
    }
})();