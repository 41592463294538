(() => {
    'use strict';

    angular
        .module('App')
        .component('pinCodeSecurePage', {
            template: require('./PinCodeSecurePageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['PinCodeService', PinCodeSecurePageController],
            bindings: {
                onRedirect: '<'
            }
        });

    function PinCodeSecurePageController(PinCodeService) {
        var ctrl = this;
        
        ctrl.labels = PinCodeService.getLabels();

        ctrl.onContinue = function () {
            ctrl.onRedirect();
        };
    }
})();