(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('chartFile', ['$log', '$http', 'Page', 'Profile', 'TimeLocale',
        function ($log, $http, Page, Profile, TimeLocale) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/ChartFile.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);

                    // get data series from external source
                    $.getJSON("/Chart/GetChartDashboardItemFile/?token=" + scope.data.ChartDashboardItemToken,
                        function (response) {
                            //console.log(response);
                            scope.title = response.Title;
                            scope.mediaUrl = response.MediaUrl;
                            scope.chartIsLoading = false;
                            scope.$apply();
                        });


                }
            }
        }
    ]);
})();