(function () {
    'use strict';

    angular
        .module('App')
        .factory('CalendarRestService', ['$stateParams', '$q', '$http', 'LanguageSelectorService',
            function ($stateParams, $q, $http, LanguageSelectorService) {

                return {
                    getCalendarData: getCalendarData,
                    getCalendarEvents: getCalendarEvents,
                    getCalendarViewEvents: getCalendarViewEvents,
                    eventSignup: eventSignup,
                    eventSignOut: eventSignOut,
                    createEvent: createEvent,
                    createEventUpdate: createEventUpdate,
                    editEventUpdate: editEventUpdate,
                    deleteEventUpdate: deleteEventUpdate,
                    getCreateEventUpdateData: getCreateEventUpdateData,
                    getEditEventUpdateData: getEditEventUpdateData,
                    editEvent: editEvent,
                    deleteEvent: deleteEvent,
                    getEventPreviewData: getEventPreviewData,
                    getEventSignups: getEventSignups,
                    getEventAttendanceList: getEventAttendanceList,
                    toggleInterested: toggleInterested,
                    cancelEventRequest: cancelEventRequest,
                    removeParticipants: removeParticipants,
                    setUserAttendanceStatus: setUserAttendanceStatus
                }

                function getCalendarData(token) {
                    return get('/Calendar/GetData/' + token);
                }

                function getCalendarEvents(filterModel) {
                    return post('/Calendar/GetEvents/' + $stateParams.token, filterModel)
                }

                function eventSignup(calendarEventId, attendingStatus) {
                    return post('/Calendar/EventSignup', {
                        token: $stateParams.token,
                        calendarEventId: calendarEventId,
                        attendingStatus: attendingStatus
                    })
                }

                function eventSignOut(calendarEventId) {
                    return post('/Calendar/EventSignout', {
                        token: $stateParams.token,
                        calendarEventId: calendarEventId
                    })
                }

                function createEvent(model) {
                    model.token = $stateParams.token;

                    return post('/Calendar/CreateEvent', {
                        model: model
                    })
                }

                function createEventUpdate(model, eventToken) {
                    return post('/Calendar/CreateEventUpdate', {
                        model: model,
                        token: eventToken
                    })
                }

                function editEventUpdate(model, eventToken) {
                    return post('/Calendar/EditEventUpdate', {
                        model: model,
                        token: eventToken
                    })
                }

                function deleteEventUpdate(eventToken) {
                    return post('/Calendar/DeleteEventUpdate', {
                        token: eventToken
                    })
                }

                function getCreateEventUpdateData(eventToken) {
                    return post('/Calendar/GetCreateEventUpdatePage/' + eventToken)
                        .then(resp => ({
                            languages: LanguageSelectorService.updateLanguagesInfo(resp.AvailableCultures),
                            settings: resp.Settings
                        }))
                }

                function getEditEventUpdateData(eventToken) {
                    return post('/Calendar/GetEditEventUpdatePage/' + eventToken)
                        .then(resp => ({
                            languages: LanguageSelectorService.updateLanguagesInfo(resp.AvailableCultures),
                            attachments: resp.MediaList,
                            translations: resp.Translations,
                            settings: resp.Settings
                        }))
                }

                function editEvent(model) {
                    model.token = $stateParams.token;

                    return post('/Calendar/EditEvent', {
                        model: model
                    })
                }

                function deleteEvent(calendarEventId) {
                    return post('/Calendar/DeleteEvent', {
                        token: $stateParams.token,
                        calendarEventId: calendarEventId
                    })
                }

                function getEventPreviewData(calendarEventId, token) {
                    return post('/Calendar/EventPreview', {
                        token: token || $stateParams.token,
                        calendarEventId: calendarEventId
                    })
                }

                function getCalendarViewEvents(months, savedFilters) {
                    const periodFrom = _.head(months).fullDate.startOf('month').format(),
                        periodTo = _.last(months).fullDate.endOf('month').format();

                    return getCalendarEvents({
                        Limit: 1000000,
                        StartTime: periodFrom,
                        EndTime: periodTo,
                        CategoryIds: savedFilters?.CategoryIds,
                        ExcludeFullyBookedEvents: savedFilters?.ExcludeFullyBookedEvents,
                    });
                }

                function toggleInterested({ CalendarEventId, IsInterested }) {
                    return post('/Calendar/SetInterested/' + $stateParams.token, {
                        CalendarEventId: CalendarEventId,
                        Interested: IsInterested
                    })
                }

                function getEventSignups(calendarEventId) {
                    return get('/Calendar/GetEventSignups/' + $stateParams.token + '?calendarEventId=' + calendarEventId)
                }

                function getEventAttendanceList(calendarEventId) {
                    return get('/Calendar/GetAttendanceList/' + $stateParams.token + '?calendarEventId=' + calendarEventId)
                }

                function setUserAttendanceStatus(calendarEventId, userToken, isAttending) {
                    return post('/Calendar/ConfirmAttendance/' + $stateParams.token, {
                        calendarEventId: calendarEventId,
                        userToken: userToken,
                        IsAttending: isAttending
                    })
                }

                function cancelEventRequest(eventToken, translations, cancelType, sendNotifications) {
                    return post('Calendar/CancelEvent/' + eventToken, {
                        Translations: translations.filter(translation => translation.CancelReason),
                        CancelType: cancelType,
                        SendNotifications: sendNotifications
                    })
                }

                function removeParticipants(calendarEventId, userTokens) {
                    return post('/Calendar/RemoveParticipants', {
                        token: $stateParams.token,
                        calendarEventId: calendarEventId,
                        userTokens: userTokens,
                    })
                }

                function getData(response) {
                    return response.data;
                }

                function catchData(response) {
                    throw response.data;
                }

                function post(path, data) {
                    return $http.post(path, data).then(getData).catch(catchData);
                }

                function get(path) {
                    return $http.get(path).then(getData).catch(catchData);
                }

            }]);
})();
