(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('LocalStorageService', [function () {
        var _localStorageAvailable = null;

        return {
            set,
            setObject,
            get,
            getObject,
            remove,
            hasLocalStorage
        }

        function set(key, value){
            try {
                if(hasLocalStorage() == false){
                    return;
                }
    
                localStorage.setItem(key, value);
            }
            catch (ex) {
                _localStorageAvailable = null;
                return;
            }
        }

        function setObject(key, value){
            try {
                if (hasLocalStorage() == false) {
                    return;
                }
    
                localStorage.setItem(key, JSON.stringify(value));
            }
            catch (ex) {
                _localStorageAvailable = null;
                return;
            }
        }

        function get(key, defaultValue){
            try {
                if (hasLocalStorage() == false) {
                    return parseDefaultValue(defaultValue);
                }
    
                const value = localStorage.getItem(key);

                if (value == null || typeof(value) == typeof undefined) {
                    return parseDefaultValue(defaultValue);
                }

                return value;
            }
            catch (ex) {
                _localStorageAvailable = null;
                return parseDefaultValue(defaultValue);
            }
        }

        function getObject(key, defaultValue){
            try {
                if (hasLocalStorage() == false) {
                    return parseDefaultValue(defaultValue);
                }
    
                const value = localStorage.getItem(key);

                if (value == null || typeof(value) == typeof undefined) {
                    return parseDefaultValue(defaultValue);
                }
    
                return JSON.parse(value);
            }
            catch (ex) {
                _localStorageAvailable = null;
                return parseDefaultValue(defaultValue);
            }
        }

        function remove(key){
            try {
                if (hasLocalStorage() == false) {
                    return;
                }
    
                localStorage.removeItem(key);
            }
            catch (ex) {
                _localStorageAvailable = null;
                return;
            }
        }

        function parseDefaultValue(defaultValue){
            if (typeof(defaultValue) == typeof undefined) {
                return null;
            }

            return defaultValue;
        }

        function hasLocalStorage(){
            if (_localStorageAvailable != null) {
                return _localStorageAvailable;
            }

            if (typeof(window.localStorage) != typeof undefined) {
                const testKey = "__localStorageTest__";
                const testValue = "__localStorageTestValue__";

                try {
                    window.localStorage.setItem(testKey, testValue);
                    var retrievedValue = window.localStorage.getItem(testKey);
                    window.localStorage.removeItem(testKey);

                    if (retrievedValue !== testValue) {
                        _localStorageAvailable = false;
                        return false;
                    }
                    
                    _localStorageAvailable = true;
                    return true;
                } 
                catch (ex) {
                    _localStorageAvailable = false;
                    return false;
                }
            }
            
            _localStorageAvailable = false;
            return false;
        }
    }]);
})();