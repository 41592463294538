(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettingsCelebrations', {
                        parent: 'base',
                        url: '/profileSettings/privacy/celebrations',
                        template: '<div class="profile-settings-edit-page">' +
                            '<profile-settings-celebrations class="view-page narrow">' +
                            '</profile-settings-celebrations></div>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'ProfileSettingsCelebrationsController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsCelebrationsController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('PROFILE.SETTINGS.CELEBRATIONS.PAGE_TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('profileSettings');
                }
            }]);
})();