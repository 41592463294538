(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('tip', {
            template: require('./TipComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', TipComponent],
            bindings: {
                titleText: '<',
                text: '<',
                isHidden: '<',
                openTip: '=?'
            }
        });

    function TipComponent() {
        var ctrl = this;

        ctrl.showTip = !ctrl.isHidden;
        ctrl.openTip = openTip;
        ctrl.hideTip = hideTip;

        function openTip() {
            ctrl.showTip = true;
        }

        function hideTip(ev) {
            ctrl.showTip = false;

            ev.stopPropagation();
        }
    }
})();