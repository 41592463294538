(function () {
    'use strict';

    var app = angular.module('App')

    app.directive('minLength', function () {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModel) {
                if (attrs.minLength !== 'false') {
                    scope.minlength = attrs.minLength || 1;
                    ngModel.$validators.minLength = function (modelValue) {
                        /* Assume TRUE when empty, as ngRequired should be used for mandatory values */
                        return (ngModel.$isEmpty(modelValue)) ? true : (modelValue.length >= scope.minlength);
                    };
                }
            }
        };
    });
})();