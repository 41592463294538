(function () {
    'use strict';

    var app = angular.module('App');
    app.factory('CreateUpdateEventService', ['$http', '$stateParams', 'PopupWrapperService', 'LanguageSelectorService',
        function ($http, $stateParams, PopupWrapperService, LanguageSelectorService) {
            var editEventComponentController;

            return {
                prepareDataForServer: prepareDataForServer,
                setEditEventComponentController: setEditEventComponentController,
                openEditEvent: openEditEvent,
                openPopup: openPopup,
                getCreateEventData: getCreateEventData,
                getEditEventData: getEditEventData,
                getUpdatePage: getUpdatePage,
                getPermissionsSettings: getPermissionsSettings
            };

            function prepareDataForServer(event, reminderEnabled) {
                var preparedData;
                if (event.image) {
                    event.BackgroundMediaId = _.head(event.image).MediaId;
                }
                if (event.EventMedias) {
                    event.eventMedias = _.map(event.EventMedias, function (media) {
                        return media.MediaId;
                    });
                }

                if (!event.allDay) {
                    event.StartTime = moment(event.Start).format('YYYY-MM-DD[T]HH:mm:ss');
                    event.EndTime = moment(event.End).format('YYYY-MM-DD[T]HH:mm:ss');
                    if (event.registrationEnd) {
                        event.AllowRegisterEndDate = moment(event.registrationEnd).format('YYYY-MM-DD[T]HH:mm:ss');
                    } else {
                        event.AllowRegisterEndDate = null;
                    }
                    if (event.registrationStart) {
                        event.AllowRegisterStartDate = moment(event.registrationStart).format('YYYY-MM-DD[T]HH:mm:ss');
                    } else {
                        event.AllowRegisterStartDate = null;
                    }
                } else {
                    event.StartTime = moment(event.AllDayStart).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
                    event.EndTime = moment(event.AllDayEnd).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
                    if (event.registrationEnd) {
                        event.AllowRegisterEndDate = moment(event.registrationEnd).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
                    } else {
                        event.AllowRegisterEndDate = null;
                    }
                    if (event.registrationStart) {
                        event.AllowRegisterStartDate = moment(event.registrationStart).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
                    } else {
                        event.AllowRegisterStartDate = null;
                    }
                    event.StartTimeTimeZone = null;
                    event.EndTimeTimeZone = null;
                }

                preparedData = {
                    Location: event.Location,
                    StartTime: event.StartTime,
                    StartTimeTimeZone: event.StartTimeTimeZone,
                    EndTime: event.EndTime,
                    EndTimeTimeZone: event.EndTimeTimeZone,
                    AllowRegisterStartDate: event.AllowRegisterStartDate,
                    AllowRegisterEndDate: event.AllowRegisterEndDate,
                    AllowRegisterStartDateTimeZone: event.AllowRegisterStartDateTimeZone,
                    AllowRegisterEndDateTimeZone: event.AllowRegisterEndDateTimeZone,
                    BackgroundMediaId: event.BackgroundMediaId,
                    CalendarCategoryId: event.CalendarCategoryId?.[0],
                    CalendarId: event.calendar.CalendarId,
                    CalendarEventTypeId: event.CalendarEventTypeId,
                    CalendarEventId: event.CalendarEventId,
                    AttendanceConfirmationEnabled: event.AttendanceConfirmationEnabled,
                    AttendancePointsAmount: event.AttendancePointsAmount,
                    EventMedias: event.eventMedias,
                    SignupEnabled: event.SignupEnabled,
                    SignupLimit: event.SignupLimit,
                    Permissions: event.Permissions,
                    Individuals: _.map(event.Individuals, function (individual) {
                        return individual.UserId
                    }),
                    Translations: event.selectedLanguages.reduce(function (acc, language) {
                        if (language.Title) {
                            acc.push({
                                CultureId: language.CultureId,
                                Title: language.Title,
                                Summary: language.Summary,
                                AutoTranslated: language.AutoTranslated
                            })
                        }
                        return acc
                    }, []),
                }

                if (reminderEnabled && event.CalendarEventTypeId === 1) {
                    preparedData.ReminderDuration = event.ReminderDuration;
                    preparedData.ReminderPeriodTypeId = event.ReminderPeriodTypeId;
                }

                return preparedData;
            }

            function setEditEventComponentController(ctrl) {
                editEventComponentController = ctrl;
            }

            function openEditEvent(event) {
                editEventComponentController.openUpdateEvent(event);
            }

            function openPopup(data) {
                PopupWrapperService.createDynamic(
                    `<create-event
                         calendars="calendars"
                         timezones="timezones"
                         selected-day="selectedDay"
                         timezone-enabled="timezoneEnabled"
                         signup-limit-enabled="signupLimitEnabled"
                         categories="categories"
                         account-module-token="accountModuleToken"
                    ></create-event>`, {
                    calendars: data.calendars,
                    timezones: data.timezones,
                    selectedDay: data.selectedDay,
                    timezoneEnabled: data.timezoneEnabled,
                    signupLimitEnabled: data.signupLimitEnabled,
                    categories: data.categories,
                    accountModuleToken: data.accountModuleToken
                });
            }

            function getCreateEventData() {
                return $http.post('/Calendar/GetCreatePage/' + $stateParams.token)
                    .then(function (resp) {
                        resp.data.AvailableCultures = LanguageSelectorService.updateLanguagesInfo(resp.data.AvailableCultures)

                        return resp.data
                    })
            }

            function getEditEventData(eventToken) {
                return $http.post('/Calendar/GetEditPage/' + eventToken)
                    .then(function (resp) {
                        resp.data.AvailableCultures = LanguageSelectorService.updateLanguagesInfo(resp.data.AvailableCultures)
                        return resp.data;
                    })
            }

            function getUpdatePage(token) {
                return $http.post('/Calendar/GetEditPage/' + token).then(function (resp) {
                    return resp.data;
                })
            }

            function getPermissionsSettings(calendarId) {
                return $http.post('/Calendar/GetPermissionSettings', { calendarId: calendarId }).then(function (resp) {
                    return resp.data;
                })
            }
        }])
})();