(function () {
    'use strict';

    angular
        .module('App')
        .component('stages', {
            template: require('./StagesComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', 'FillingPreviewService', 'ParticipantsService', 'TimeLocale', 'ToastFactory',
                StagesController],
            bindings: {
                stages: '<',
                fillingToken: '<',
                onStageClick: '<',
                isNew: '<',
                showOriginal: '<',
                moduleToken: '@'
            }
        })

    function StagesController($filter, FillingPreviewService, ParticipantsService, TimeLocale, ToastFactory) {
        var ctrl = this;

        ctrl.selectStage = selectStage;
        ctrl.openComments = openComments;
        ctrl.openParticipants = openParticipants;
        ctrl.getStageStatus = getStageStatus;

        function getStageStatus(stage) {
            var string;
            switch (stage.ReviewStatus) {
                case 1:
                    return $filter('translate')('SERVICEFORM.STAGES.PENDING');
                case 2:
                    if (!stage.ApprovedBy) {
                        return '';
                    }

                    string = $filter('translate')('SERVICEFORM.STAGES.APPROVED_BY');
                    string += ' ' + stage.ApprovedBy.Name + ' · ' + TimeLocale.timeAgo(stage.ApprovedAt);
                    return string;
                case 3:
                    if (!stage.RejectedBy) {
                        return '';
                    }

                    string = $filter('translate')('SERVICEFORM.STAGES.DECLINED_BY');
                    string += ' ' + stage.RejectedBy.Name + ' · ' + TimeLocale.timeAgo(stage.RejectedAt)
                    return string;
            }
        }

        function openComments(stage) {
            FillingPreviewService.openCommentsPopup(stage, ctrl.fillingToken, ctrl.moduleToken)
        }

        function openParticipants(stage) {
            ParticipantsService.openParticipantsPopup(stage.Participants, ctrl.moduleToken)
        }

        function selectStage(stage) {
            if (!stage.Steps.length && (stage.ReviewStatus === 0 || !stage.ReviewStatus)) {
                ToastFactory.errorTranslated('SERVICEFORM.STAGE_EMPTY');
            } else {
                ctrl.onStageClick && ctrl.onStageClick(stage);
            }
        }
    }
})();