(function () {
    'use strict';

    angular
        .module('App')
        .component('serviceFormField', {
            template: require('./ServiceFormField.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['FIELD_TYPE', 'DATALIST_TYPE', 'DISPLAY_TYPE', 'DATA_LIST_SORT_TYPE', '$scope', '$element',
                'ServiceFormRenderService', 'FillingPreviewService', ServiceFormFieldController],
            bindings: {
                field: '<',
                index: '@',
                listData: '<',
                onValueUpdated: '<',
                accountModuleToken: '<',
                embeddedMedias: '<',
                fieldsToLimit: '<',
            }
        });

    function ServiceFormFieldController(FIELD_TYPE, DATALIST_TYPE, DISPLAY_TYPE, DATA_LIST_SORT_TYPE, $scope, $element,
                                        ServiceFormRenderService, FillingPreviewService) {
        const ctrl = this;
        let isInit;

        $scope.$on('fillingFeedbackUpdated', onFillingFeedbackUpdated);
        
        if (ctrl.listData?.DataListType !== DATALIST_TYPE.CUSTOMER) {
            $scope.$watch('ctrl.field.model', valueUpdated)
        }

        ctrl.ratingColorClass = ServiceFormRenderService.getFeedbackRatingColorClass;
        ctrl.feedbackIcon = ServiceFormRenderService.getRatingMediaById;
        
        ctrl.enableImageEditing = ServiceFormRenderService.getFeedbackOptions().IsFeedbackEnabled &&
            ServiceFormRenderService.getFeedbackOptions().IsFeedbackDrawingEnabled;

        ctrl.hasFeedback = hasFeedback();

        ctrl.$onInit = init;
        ctrl.valueUpdated = valueUpdated;
        ctrl.uploadMedia = uploadMedia;
        ctrl.openFormFeedbackComments = openFormFeedbackComments;
        ctrl.replyComment = replyComment;
        ctrl.customerFieldInit = customerFieldInit;

        function init() {
            ctrl.FIELD_TYPE = FIELD_TYPE;
            ctrl.DATALIST_TYPE = DATALIST_TYPE;
            ctrl.isLimitedByRule = ctrl.fieldsToLimit?.includes(ctrl.field.ServiceFormFieldId);

            ctrl.listData = _.cloneDeep(ctrl.listData);

            if (ctrl.field && ctrl.field.Alias) {
                $element.attr('data-alias', ctrl.field.Alias);
            }

            if (isNotLazyDataList() && ctrl.listData.DataListSortType !== DATA_LIST_SORT_TYPE.DISTANCE &&
                ctrl.listData.isUpdated) {
                var sortFunction;
                if (ctrl.listData.DataListSortType === DATA_LIST_SORT_TYPE.NAME) {
                    sortFunction = sortByName;
                }
                if (ctrl.listData.DataListSortType === DATA_LIST_SORT_TYPE.SORT_OREDER) {
                    sortFunction = sortBySortOrder;
                }
                ctrl.listData.Items.sort(sortFunction);

                ctrl.listData.isUpdated = false;
            }
        }

        function customerFieldInit() {
            $scope.$watch('ctrl.field.model', valueUpdated)
        }

        function valueUpdated(newVal, oldVal) {
            if (!isInit) {
                isInit = true;
                return false
            }

            if (newVal !== undefined && !_.isEqual(newVal, oldVal) && (!isEmpty(newVal) || !isEmpty(oldVal) && isEmpty(newVal))) {
                ctrl.disabled = true;
                ctrl.onValueUpdated && ctrl.onValueUpdated(ctrl.field).then(() => {
                    ctrl.disabled = false;
                });
            }
        }

        function isEmpty(val) {
            if (val === null || val === undefined) {
                return true
            } else {
                if (typeof val !== 'number') {
                    return !val.length
                }
                return false
            }
        }

        function uploadMedia(media) {
            if (!_.isEmpty(media.GpsInfo)) {
                media.GpsInfo.title = 'Media location ' + media.GpsInfo.title;
            }
        }

        function openFormFeedbackComments(feedbackData) {
            FillingPreviewService.openFormFeedbackComments(feedbackData.Feedback)
        }

        function replyComment(replyingMessage, feedbackData) {
            FillingPreviewService.openFormFeedbackComments(feedbackData.Feedback, replyingMessage);
        }

        function onFillingFeedbackUpdated(event, feedbackData) {
            if (ctrl.field.ServiceFormFieldId === feedbackData.ServiceFormFieldId) {
                ctrl.field.FeedbackData = feedbackData;
            }
        }

        function isNotLazyDataList() {
            return ctrl.field.FieldType === ctrl.FIELD_TYPE.DATA_LIST && !ctrl.listData.IsLazyLoad;
        }

        function sortByName(first, second) {
            return first.Title > second.Title ? 1 : (first.Title < second.Title ? -1 : 0);
        }

        function sortBySortOrder(first, second) {
            return first.SortOrder - second.SortOrder
        }

        function hasFeedback() {
            return ctrl.field.FeedbackData &&
                ctrl.field.FeedbackData.Feedback &&
                ctrl.field.FeedbackData.Feedback.Rating !== null &&
                ctrl.field.FeedbackData.Feedback.Rating !== undefined
        }
    }
})();