(() => {
    'use strict';

    angular
        .module('App.Basics', [])
        .run(() => {
        });
})();

require([
    './Basics/AuthHttpResponseInterceptor',
    './Basics/BasicHelper',
    './Basics/CopyLinkService',
    './Basics/NativeFactory',
    './Basics/TimeLocaleProvider',
    './Basics/Constants/Constants',
    './Basics/Constants/Events',
    './Basics/Constants/NativeConstants',
])