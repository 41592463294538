(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('promptPopup', {
            template: require('./PromptPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: [PromptPopupController],
            bindings: {
                isTextRequired: '<',
                onOk: '<',
                onCancel: '<',
                icon: '@',
                iconPrefix: '@',
                placeholderText: '@',
                popupClass: '@',
                popupTitle: '@',
                buttonText: '@'
            }
        })

    function PromptPopupController() {
        var ctrl = this;

        ctrl.ok = ok;
        ctrl.cancel = cancel;

        function ok(text) {
            ctrl.onOk && ctrl.onOk(text);
        }

        function cancel() {
            ctrl.onCancel && ctrl.onCancel();
        }
    }
})();