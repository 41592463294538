(() => {
    'use strict';

    angular
        .module('App')
        .component('fillingItem', {
            template: require('./FillingListItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$filter', 'ServiceFormRenderService', 'PostReadRegistrationService',
                'MODULE_ID', 'PopupWrapperService', FillingItemController],
            bindings: {
                filling: '<',
                hasPerformanceRanges: '<'
            }
        });

    function FillingItemController($scope, $filter, ServiceFormRenderService, PostReadRegistrationService, MODULE_ID,
                                   PopupWrapperService) {
        var ctrl = this, isPopupLoading;

        $scope.$watch('ctrl.filling', () => {
            ctrl.filling = ServiceFormRenderService.formatFillingItemMedia(ctrl.filling);
            ctrl.hasMedia = !!ctrl.filling.Fields.find(field => {
                return field.MediaValues && field.MediaValues.length && field.ShowFieldOnListView;
            });
        }, true);
        ctrl.settings = ServiceFormRenderService.fillingInteractionSettings;
        ctrl.prefareTranslated = ctrl.settings.PreferTranslatedFillings
        ctrl.ratingRange = ServiceFormRenderService.getFeedbackRatingRange();

        ctrl.openFillingItemView = openFillingItemView;
        ctrl.createSubTitles = createSubTitles;
        ctrl.openViewsPopup = openViewsPopup;
        ctrl.openMap = openMap;

        function openFillingItemView() {
            if (!isPopupLoading) {
                PopupWrapperService.getPopup('fillingPreviewPopupId').open(ctrl.filling.ServiceFormFillingToken);
            }
        }

        function createSubTitles(dataListItem) {
            let subTitle = "";

            if (dataListItem.DataListType == 3 && dataListItem.AdditionalValues) // customer 
            {
                if (dataListItem.AdditionalValues.Street) {
                    subTitle += dataListItem.AdditionalValues.Street + "<br/>";
                }
                if (dataListItem.AdditionalValues.ZipCode) {
                    subTitle += dataListItem.AdditionalValues.ZipCode + " ";
                }
                if (dataListItem.AdditionalValues.City) {
                    subTitle += dataListItem.AdditionalValues.City
                }
            }
            return subTitle;
        }

        function openViewsPopup() {
            PostReadRegistrationService.showPopup(
                ctrl.filling.ServiceFormFillingToken,
                ctrl.filling.TotalViews + ' ' + $filter('translate')('SOCIAL.VIEWS'),
                MODULE_ID.SERVICE_FORM
            );
        }

        function openMap(event) {
            event.stopPropagation();
            ServiceFormRenderService.showMap(ctrl.filling.Latitude, ctrl.filling.Longitude);
        }
    }
})();
