(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('ServiceFormRestService', ['$http', '$stateParams', 'GeolocationService',
        'ServiceFormDataFormatService', 'TaskManagementRenderService', 'DATALIST_TYPE', 'ServiceFormRenderService',
        'CommentsService',
        function ($http, $stateParams, GeolocationService, ServiceFormDataFormatService, TaskManagementRenderService,
                  DATALIST_TYPE, ServiceFormRenderService, CommentsService) {

            var fillingFilters, fieldGroups, colors = [
                'var(--quiz-color)',
                'var(--service-form-color)',
                'var(--confirmation-color)',
                'var(--warning-color)',
                'var(--highlight-color)'
            ];

            return {
                registerRead: registerRead,
                destroyData: destroyData,
                putStagesIntoGroups: putStagesIntoGroups,
                applyFilters: applyFilters,
                getReactions: getReactions,
                postReaction: postReaction,
                deleteReaction: deleteReaction,
                getComments: getComments,
                postComment: postComment,
                editComment: editComment,
                deleteComment: deleteComment,
                lazyLoadFillings: lazyLoadFillings,
                loadFilteredFillings: loadFilteredFillings,
                loadDataList: loadDataList,
                loadDataListByType: loadDataListByType,
                getGpsCoordinates: getGpsCoordinates,
                createDataListItem: createDataListItem,
                getAddress: getAddress,
                getClosestCustomer: getClosestCustomer,
                deleteFilling: deleteFilling,
                getServiceFormData: getServiceFormData,
                getFillingData: getFillingData,
                getFillingsOverview: getFillingsOverview,
                getFilling: getFilling,
                getItemsInUseForField: getItemsInUseForField,
            };

            function applyFilters(filters) {
                fillingFilters = filters;
            }

            function getReactions(fillingToken) {
                return post('/ServiceForm/GetReactions', {
                    token: fillingToken
                });
            }

            function getItemsInUseForField(token, serviceFormFieldId) {
                return post('/ServiceForm/EvaluateFieldInUseRule', {
                    token,
                    serviceFormFieldId
                });
            }

            function postReaction(fillingToken) {
                return post('/ServiceForm/PostReaction', {
                    token: fillingToken
                });
            }

            function deleteReaction(fillingToken) {
                return post('/ServiceForm/DeleteReaction', {
                    token: fillingToken
                });
            }

            function getComments(token, stageToken, isReviewComments) {
                if (stageToken) {
                    return post('/ServiceForm/GetStageComments', {
                        token: stageToken,
                        fillingToken: token,
                        isReviewComments: isReviewComments
                    });
                } else {
                    return post('ServiceForm/GetComments', {
                        token: token,
                    });
                }
            }

            function postComment(token, comment, stageToken, media) {
                if (stageToken) {
                    return post('/ServiceForm/PostStageComment', {
                        comment: comment,
                        token: stageToken,
                        fillingToken: token,
                        MediaList: media
                    });
                } else {
                    return post('ServiceForm/PostComment', {
                        comment: comment,
                        token: token,
                        MediaList: media
                    });
                }
            }

            function editComment(token, comment, stageToken, media, commentId) {
                if (stageToken) {
                    return post('/ServiceForm/EditStageComment/', {
                        comment: comment,
                        token: stageToken,
                        fillingToken: token,
                        ServiceFormStageCommentId: commentId,
                        MediaList: media
                    })
                } else {
                    return post('/ServiceForm/EditComment/', {
                        comment: comment,
                        token: token,
                        serviceFormFillingCommentId: commentId,
                        MediaList: media
                    })
                }
            }

            function deleteComment(token, commentId, stageToken) {
                var url = stageToken ? '/ServiceForm/DeleteStageComment' : 'ServiceForm/DeleteComment',
                    options = stageToken ? {
                        serviceFormStageCommentId: commentId,
                        token: stageToken,
                        fillingToken: token
                    } : {
                        serviceFormFillingCommentId: commentId,
                        token: token,
                    };
                return CommentsService.deleteComment(url, options)
            }

            function lazyLoadFillings(limit, offset, serviceFormId) {
                limit = limit ? limit : 24;
                offset = offset ? offset : 0;

                const filterModel = _.assign(fillingFilters, {
                    serviceFormId: serviceFormId,
                    token: $stateParams.token,
                    limit: limit,
                    offset: offset
                });

                return post('/ServiceForm/GetServiceFormFillings', filterModel);
            }

            function loadFilteredFillings(filters, serviceFormId) {
                fillingFilters = filters;

                const filterModel = _.assign({
                    serviceFormId: serviceFormId,
                    token: $stateParams.token,
                    limit: 24,
                    offset: 0
                }, filters);

                return post('/ServiceForm/GetServiceFormFillings', filterModel);
            }

            function loadDataList(dataListId, serviceFormFieldId, narrowDown, accountModuleToken) {
                return post('/DataList/GetDataList', {
                    token: accountModuleToken || $stateParams.token,
                    dataListId: dataListId,
                    serviceFormFieldId: serviceFormFieldId,
                    narrowDown: narrowDown
                });
            }

            function loadDataListByType(dataListType) {
                return post('/DataList/GetDataListByType', {
                    token: $stateParams.token,
                    dataListType: dataListType
                });
            }

            function getGpsCoordinates(mediaIds) {
                return post('/DataList/GetGpsCoordinates', {
                    token: $stateParams.token,
                    mediaIds: mediaIds
                });
            }

            function createDataListItem(model, customFields, fieldType, accountModuleToken) {
                var url;

                model.CustomFieldValues = ServiceFormDataFormatService.formatFields(customFields, true);

                switch (fieldType) {
                    case DATALIST_TYPE.DATEINTERVAL:
                        url = '/DataList/CreateDateInterval';
                        break;
                    case DATALIST_TYPE.CUSTOMER:
                        url = '/DataList/CreateCustomer';
                        break;
                    default:
                        url = '/DataList/CreateDefaultDataListItem';
                }

                return post(url, {
                    token: accountModuleToken || $stateParams.token,
                    model: model
                });
            }

            function getAddress(lat, lon) {
                return post('/DataList/GetAddressByGps', {
                    token: $stateParams.token,
                    lat: lat,
                    lng: lon
                })
            }

            function getClosestCustomer(lat, lon, dataListId) {
                return post('/DataList/GetClosestCustomer', {
                    token: $stateParams.token,
                    lat: lat,
                    lon: lon,
                    dataListId: dataListId
                });
            }

            function deleteFilling(fillingToken) {
                return post('/ServiceForm/DeleteFilling', {
                    token: fillingToken
                });
            }

            function getServiceFormData(token, parentServiceFormFillingToken) {
                return post('/ServiceForm/GetServiceFormData/' + token, {
                    parentServiceFormFillingToken: parentServiceFormFillingToken
                })
            }

            function getFillingData(token, setSettings) {
                return post('/ServiceForm/GetFillingData/' + token).then(function (data) {
                    data.FeedbackData && addFeedbackToField(data);
                    putFillingsInToGroups(data);

                    if (setSettings) {
                        ServiceFormRenderService.setLabelSettings(data.LabelSettings);
                        data.FeedbackData && ServiceFormRenderService.setFeedbackRatingRange(data.FeedbackData.RatingRange);
                    }

                    _.each(data.Filling.Participants, function (participant, index) {
                        participant.color = colors[index % colors.length];
                    });

                    return data;
                });
            }

            function getFillingsOverview(serviceFormId, serviceFormFillingTokens) {
                return post('/ServiceForm/GetFillingsOverview/' + $stateParams.token, {
                    serviceFormId: serviceFormId,
                    serviceFormFillingTokens: serviceFormFillingTokens
                })
            }

            function addFeedbackToField(data) {
                const isStages = !!data.Stages.length;
                data.FeedbackData.FieldsFeedback.forEach(feedback => {
                    if (isStages) {
                        data.Stages.forEach(stage => {
                            addToFieldInner(stage.Fields, feedback)
                        })
                    } else {
                        addToFieldInner(data.Filling.Fields, feedback)
                    }
                })

                function addToFieldInner(fields, feedback) {
                    let filling = fields.find(field => feedback.ServiceFormFieldId === field.ServiceFormFieldId);
                    filling && (filling.FeedbackData = feedback);
                }
            }

            function getFilling(token) {
                return post('/ServiceForm/GetFilling/' + token).then(function (data) {
                    putFillingsInToGroups(data);

                    _.each(data.Filling.Participants, function (participant, index) {
                        participant.color = colors[index % colors.length];
                    });

                    return data;
                })
            }

            function getData(response) {
                return response.data;
            }

            function catchData(response) {
                throw response.data;
            }

            function post(path, data) {
                return $http.post(path, data).then(getData).catch(catchData);
            }

            function registerRead(serviceFormFillingId) {
                return post('/ServiceForm/RegisterRead', {
                    token: $stateParams.token,
                    serviceFormFillingId: serviceFormFillingId
                })
            }

            function putStagesIntoGroups(stages) {
                _.each(stages, function (stage) {
                    var stageByGroups = putFieldsToGroups(stage.Fields, fieldGroups);

                    stage.Groups = [];
                    stage.Groups = stageByGroups;
                })

                return stages
            }

            function putFillingsInToGroups(data) {
                if (data.FieldGroups) {
                    fieldGroups = data.FieldGroups;

                    if (data.Stages && data.Stages.length) {
                        data.Stages.forEach(stage => {
                            stage.Groups = [];
                            if (stage.Fields.length) {
                                stage.Groups = putFieldsToGroups(stage.Fields, data.FieldGroups);
                            }
                        })
                    } else if (data.Filling.Fields && data.Filling.Fields.length) {
                        const fillingsByGroups = putFieldsToGroups(data.Filling.Fields, data.FieldGroups);

                        data.Filling.Groups = [];
                        data.Filling.Groups = fillingsByGroups;
                    }
                }

                return data;
            }

            function putFieldsToGroups(fields, groups) {
                const formattedGroups = [],
                    firstIndex = _.first(fields).FieldType === 7 ? 1 : 0,
                    lastIndex = _.last(fields).FieldType === 7 ? fields.length - 2 : fields.length - 1;

                let previousField, currentGroup;

                for (let key = firstIndex; key <= lastIndex; key++) {
                    const currentField = fields[key],
                        existingGroup = groups
                            .find(group => group.ServiceFormFieldGroupId === currentField.ServiceFormFieldGroupId);

                    if (previousField && currentField.ServiceFormFieldGroupId === previousField.ServiceFormFieldGroupId) {
                        if (currentGroup && currentGroup.Fields) {
                            currentGroup.Fields.push(ServiceFormRenderService.formatMediaField(currentField));
                            if (!currentGroup.IsNotEmpty && currentField.Values.length) {
                                currentGroup.IsNotEmpty = true;
                            }
                        }
                    } else {
                        if (currentGroup) {
                            formattedGroups.push(currentGroup);
                        }
                        currentGroup = {};
                        if (existingGroup) {
                            currentGroup = _.cloneDeep({...existingGroup});
                            currentGroup.Fields = [];
                        }
                        if (!currentGroup.Fields) {
                            currentGroup.Fields = [];
                        }
                        currentGroup.Fields.push(ServiceFormRenderService.formatMediaField(currentField));

                        if (!currentGroup.IsNotEmpty && currentField.Values.length) {
                            currentGroup.IsNotEmpty = true;
                        }
                    }

                    previousField = currentField;
                    if (key === lastIndex) {
                        formattedGroups.push(currentGroup);
                    }
                }

                return formattedGroups;
            }

            function destroyData() {
                fillingFilters = null;
            }
        }]);
})();