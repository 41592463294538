(function () {
    'use strict';

    angular.module('App')
        .factory('SubtaskDetailsService', ['$filter', '$rootScope', 'UpdateSubtaskPopupService', 'Page', 'ToastFactory',
            'TranslationService', 'ConfirmPopupService', SubtaskDetailsService]);

    function SubtaskDetailsService($filter, $rootScope, UpdateSubtaskPopupService, Page, ToastFactory, TranslationService, ConfirmPopupService) {

        return {
            prepareOptionsButtons: prepareOptionsButtons,
            prepareIssueStatus: prepareIssueStatus,
            getStatusClass: getStatusClass,
            translateSubtaskToUserCulture: translateSubtaskToUserCulture
        };

        function prepareIssueStatus(issue) {
            let issueStatus = $filter('translate')('TASK_MANAGEMENT.SUBTASK.TITLE');
            if (issue && issue.IsCompleted) {
                issueStatus = $filter('translate')('TASK_MANAGEMENT.SUBTASK.COMPLETED');
            }
            return issueStatus;
        }

        function prepareOptionsButtons(issue, closeOnDelete, closePopup) {
            var buttons = [];
            if (issue.Model.CanEdit) {
                buttons.push({
                    text: 'TASK_MANAGEMENT.SUBTASK.EDIT',
                    translate: true,
                    icon: 'edit',
                    onClick: function () {
                        UpdateSubtaskPopupService.openUpdateSubtaskPopup(issue.Model);
                    }
                });
            }

            if (issue.Model.CanDelete) {
                buttons.push({
                    text: 'TASK_MANAGEMENT.SUBTASK.DELETE',
                    translate: true,
                    iconClass: 'red',
                    icon: 'delete',
                    onClick: function () {
                        if (issue.Model.ParentIssueSubtaskToken) {
                            ConfirmPopupService.open({
                                message: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.DELETE_SERIES_QUESTION'),
                                yesText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.ONE'),
                                noText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SUBTASK.SERIES')
                            })
                            .then(() => openConfirmDeleteSubtaskPopup(null, issue.Model, closeOnDelete, closePopup))
                            .catch(() => openConfirmDeleteSubtaskPopup(issue.Model.ParentIssueSubtaskToken, issue.Model, closeOnDelete, closePopup))
                        } else {
                            openConfirmDeleteSubtaskPopup(null, issue.Model, closeOnDelete, closePopup);
                        }
                    }
                })
            }
            return buttons;
        }

        function openConfirmDeleteSubtaskPopup(parentIssueToken, issue, closeOnDelete, closePopup) {
            UpdateSubtaskPopupService.deleteSubtask(parentIssueToken, issue.IssueSubtaskToken).then(function (response) {
                ToastFactory.successTranslated('TASK_MANAGEMENT.SUBTASK.DELETED');
                closePopup && closePopup();
                if (closeOnDelete) {
                    Page.stateGoBack('notifications');
                }
                $rootScope.$emit('TaskManagement:subtaskDeleted', issue);
            });
        }

        function getStatusClass(issue) {
            return issue.IsCompleted ? 'completed' : 'in-progress';
        }

        function translateSubtaskToUserCulture(model) {
            return TranslationService.translateSubtaskToUserCulture(model)
        }
    }
})();