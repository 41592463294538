(function () {
    'use strict';

    angular.module('App')
        .factory('StatisticsPopupService', ['$http', 'PopupWrapperService', StatisticsPopupService]);

    function StatisticsPopupService($http, PopupWrapperService) {

        return {
            openStatisticsPopup: openStatisticsPopup,
            prepareUsersList: prepareUsersList,
            prepareDepartmentsList: prepareDepartmentsList,
            getCompletionRate: getCompletionRate,
        };

        function prepareUsersList(items) {
            return items.map(function (item) {
                return {
                    Name: item.UserName,
                    UserToken: item.UserToken,
                    DepartmentName: item.DepartmentName,
                    DateCreated: item.DateCompleted,
                    UserTitle: item.UserTitle,
                    ProfileImage: item.ProfileImage,
                    IsCompleted: item.IsCompleted,
                    ServiceFormFillingToken: item.ServiceFormFillingToken
                }
            })
        }

        function prepareDepartmentsList(items) {
            return items.map(function (item) {
                return {
                    Name: item.DepartmentName,
                    UserToken: item.DepartmentToken,
                    DepartmentName: item.UserName,
                    DateCreated: item.DateCompleted,
                    UserTitle: item.UserTitle,
                    isDepartment: true,
                    ProfileImage: item.ProfileImage,
                    IsCompleted: item.IsCompleted,
                    ServiceFormFillingToken: item.ServiceFormFillingToken
                }
            })
        }

        function getCompletionRate(completeCount, incompleteCount) {
            var total = completeCount + incompleteCount;
            return total > 0 ?
                Math.round(completeCount / total * 100)
                : 0;
        }

        function openStatisticsPopup(items, hasEndTimePassed, showAlertMessage) {
            PopupWrapperService
                .createDynamic(
                    '<statistics-popup items="items" has-end-time-passed="hasEndTimePassed" show-alert-message="showAlertMessage"></statistics-popup>',
                    {
                        items: items,
                        hasEndTimePassed: hasEndTimePassed,
                        showAlertMessage: showAlertMessage
                    });
        }

    }
})();