(function () {
    'use strict'
    angular.module('App')
        .component('filePreview', {
            template: require('./FilePreviewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', FilePreviewController],
            bindings: {
                preview: '<',
                noLazy: '<',
                showText: '<',
                imageVariant: '<',
                count: '<',
                multiple: '<',
                hideDownload: '<'
            }
        })

    function FilePreviewController($scope) {
        const ctrl = this;
        let iconData, deleteMediaWatcher;

        ctrl.imageVariant = ctrl.imageVariant || 'W250';
        ctrl.noLazy = ctrl.noLazy || false;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            deleteMediaWatcher = $scope.$watch('ctrl.preview.MediaId', () => {
                if (ctrl.preview) {
                    iconData = getIcon();
                    ctrl.previewImage = ctrl.preview.ThumbnailUrl || ctrl.preview.ImageFormats[ctrl.imageVariant];
                    ctrl.icon = iconData.icon;
                    ctrl.fileClass = iconData.class;
                    ctrl.customIconUrl = getCustomIconUrl();
                }
            })
        }

        function destroy() {
            deleteMediaWatcher && deleteMediaWatcher();
        }

        function getIcon() {
            switch (ctrl.preview.MediaTypeId) {
                case 1:
                    return {icon: 'image', class: 'image'}
                case 2:
                    return {icon: 'play', class: 'video'}
                case 3:
                    return {icon: 'pdf', class: 'pdf'}
                case 6:
                    return {icon: 'audio', class: 'audio'}
                case 7:
                case 8:
                    return {icon: 'excel', class: 'excel'}
                case 9:
                    return {icon: 'zip', class: 'zip'}
                case 10:
                    return {icon: 'word', class: 'word'}
                case 11:
                    return {icon: 'powerpoint', class: 'powerpoint'}
                case 12:
                    if (ctrl.preview.Extension === 'ai') {
                        return {icon: 'file', class: 'file'}
                    } else {
                        return {icon: 'image', class: 'file-image'}
                    }
                case 14:
                    return { icon: 'image', class: 'file-image' }
                default:
                    return {icon: 'file', class: 'file'}
            }
        }

        function getCustomIconUrl() {
            switch (ctrl.preview.MediaTypeId) {
                case 12:
                    if (ctrl.preview.Extension === 'ai') {
                        return '/Content/Graphics/MediaFiles/Ai.svg'
                    } else {
                        break;
                    }
                case 13:
                    return '/Content/Graphics/MediaFiles/Ps.svg'
            }
        }
    }
})();