(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('campaignmodule', {
                    parent: 'base',
                    url: '/campaignmodule/:token',
                    template: require('./CampaignModule.html'),
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        moduleData: ['$stateParams', 'base', 'CampaignService', 'Page', function ($stateParams, base, CampaignService, Page) {
                            if (base) {
                                return CampaignService.loadModule($stateParams.token).then(res => {
                                    if (res.Campaigns.length === 1) {
                                        const campaign = res.Campaigns[0];
                                        Page.stateGo('campaign', campaign.AccountModuleToken, campaign.CampaignToken, $stateParams.backState, campaign);
                                    } else {
                                        return res;
                                    }
                                });
                            }
                        }]
                    },
                    controller: 'CampaignModuleController'
                });
        }
    ]);

    app.controller('CampaignModuleController', ['$scope', 'Page', '$stateParams', 'moduleData', 'events', 'TemplateFactory',
        function ($scope, Page, $stateParams, moduleData, events, TemplateFactory) {
            Page.setTitle(moduleData.pageTitle);
            
            $scope.campaigns = moduleData.Campaigns;
            $scope.moduleToken = moduleData.AccountModuleToken;

            if ($stateParams.backState !== null) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
            } else {
                Page.showBackButton('home');
            }

            $scope.template = TemplateFactory.getTemplate();

            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateReload();
            });
        }]);

})();