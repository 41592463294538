(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('campaign', {
                    parent: 'base',
                    url: '/campaign/:token/:extra',
                    template: require('./Campaign.html'),
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        campaignData: ['CampaignService', '$stateParams', 'base', function (CampaignService, $stateParams, base) {
                            if (base) {
                                if ($stateParams.data && !_.isEmpty($stateParams.data) && $stateParams.data.CampaignToken) {
                                    return $stateParams.data;
                                } else {
                                    return CampaignService.getCampaign($stateParams.token, $stateParams.extra);
                                }
                            }
                        }]
                    },
                    controller: 'CampaignController'
                });
        }
    ]);

    app.controller('CampaignController', ['$scope', 'Page', '$stateParams', 'campaignData', 'events', 'TemplateFactory',
        function ($scope, Page, $stateParams, campaignData, events, TemplateFactory) {
            var ctrl = this;
            var modelWatcher;

            ctrl.$onDestroy = onDestroy;
            ctrl.$onInit = onInit;

            function onInit() {
                // Set title
                if (campaignData.TranslatableEntity.LocalizedAlternativeLocale &&
                    campaignData.TranslatableEntity.PreferTranslatedVersion) {
                    Page.setTitle(campaignData.TranslatableEntity.LocalizedAlternativeLocale.Title);
                } else {
                    Page.setTitle(campaignData.TranslatableEntity.LocalizedLocale.Title);
                }
                
                $scope.campaign = campaignData;

                // Manage the back state
                if ($stateParams.backState !== null) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                $scope.template = TemplateFactory.getTemplate();

                // listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }

            function onDestroy(){
                modelWatcher();
            }
        }]);

})();