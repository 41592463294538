(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('AddToHome', ['$rootScope', '$cookies', '$compile', 'BasicHelper', function ($rootScope, $cookies, $compile, BasicHelper) {
        var cookieKey = 'AddToHomeCheck';
        var f = {
            checkAddToHome: function(){
                var isFullscreen = false;
                try {
                    if (("standalone" in window.navigator) && window.navigator.standalone) {
                        isFullscreen = true;
                    }
                    if (('matchMedia' in window) && window.matchMedia('(display-mode: standalone)').matches) {
                        isFullscreen = true;
                    }
                }
                catch (err) {
                    // Do nothing
                }

                if (!isFullscreen) {
                    var cookieCheck = $cookies.getObject(cookieKey);
                    var haveAskedForFullscreen = (cookieCheck !== null && cookieCheck !== undefined && cookieCheck)

                    if (!haveAskedForFullscreen) {
                        // Save that we have asked
                        var today = new Date();
                        var expire = new Date(today.getFullYear() + 10, 1, 1, 0, 0, 0);
                        $cookies.putObject(cookieKey, true, {
                            'path': '/',
                            'expires': expire
                        });

                        if (!BasicHelper.isWrapperApp()) {
                            // Show Splash
                            var newScope = $rootScope.$new(true);
                            var $splash = $compile("<add-to-home-splash></add-to-home-splash>")(newScope);
                            $('body').append($splash);
                        }
                    }
                } else {
                    // Add Cookie anyway
                    var today = new Date();
                    var expire = new Date(today.getFullYear() + 10, 1, 1, 0, 0, 0);
                    $cookies.putObject(cookieKey, true, {
                        'path': '/',
                        'expires': expire
                    });
                }
            }
        };

        return f;
    }]);
})();