(() => {
    'use strict';

    angular.module('App')
        .component('eventStatus', {
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$element', 'CALENDAR_EVENT_STATUS', EventStatusController],
            template: require('./EventStatusComponent.html'),
            bindings: {
                event: '<'
            }
        })

    function EventStatusController($scope, $rootScope, $element, CALENDAR_EVENT_STATUS) {
        const ctrl = this;
        let statusWatcher;

        ctrl.CALENDAR_EVENT_STATUS = CALENDAR_EVENT_STATUS;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            statusWatcher = $scope.$watch('ctrl.event', (newVal) => {
                if (newVal) {
                    ctrl.status = getIcon(getStatus());
                }
            }, true)
        }

        function destroy() {
            statusWatcher();
        }

        function getStatus() {
            const ev = ctrl.event;

            if (ev.IsCancelled) {
                return CALENDAR_EVENT_STATUS.CANCELLED;
            }
            if (ev.CalendarEventTypeId === 1 && ev.IsCurrentUserSignedUp && (ev.AttendingStatus === 1 || ev.AttendingStatus === 0)) {
                return CALENDAR_EVENT_STATUS.ATTENDING;
            }
            if (ev.SignupEnabled && ev.SignupLimit) {
                if (ev.SignupLimit === ev.SignupNumber) {
                    return CALENDAR_EVENT_STATUS.FULLY_BOOKED
                }
                if ((ev.SignupLimit - ev.SignupNumber) <= (ev.SignupLimit / 3)) {
                    return CALENDAR_EVENT_STATUS.FEW_SPOTS_LEFT
                }
            }
            if (ev.CalendarEventTypeId === 2 && ev.IsCurrentUserSignedUp) {
                return CALENDAR_EVENT_STATUS.COMPLETED
            }
        }

        function getIcon(status) {
            switch (status) {
                case ctrl.CALENDAR_EVENT_STATUS.ATTENDING:
                    return {
                        icon: 'save',
                        class: 'attending',
                        text: 'CALENDAR.EVENT.ATTENDING'
                    }
                case ctrl.CALENDAR_EVENT_STATUS.FEW_SPOTS_LEFT:
                    return {
                        icon: 'exclamation',
                        class: 'few-spots',
                        text: 'CALENDAR.EVENT.FEW_SPOTS_LEFT'
                    }
                case ctrl.CALENDAR_EVENT_STATUS.FULLY_BOOKED:
                    return {
                        icon: 'close',
                        class: 'fully-booked',
                        text: 'CALENDAR.EVENT.FULLY_BOOKED'
                    }
                case ctrl.CALENDAR_EVENT_STATUS.CANCELLED:
                    return {
                        icon: 'close',
                        class: 'cancelled',
                        text: 'CALENDAR.EVENT.CANCELLED'
                    }
                case ctrl.CALENDAR_EVENT_STATUS.COMPLETED:
                    return {
                        icon: 'save',
                        class: 'completed',
                        text: 'CALENDAR.TODO.COMPLETED'
                    }
            }
        }
    }
})();