(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('SelectListService', ['$rootScope', '$compile', '$animate', '$q', '$document', '$log', '$timeout', 'Page',
        function ($rootScope, $compile, $animate, $q, $document, $log, $timeout, Page) {

            return {
                create: function (settings) {
                    var setup = {
                        options: [],
                        title: '',
                        isMultiple: false
                    };
                    angular.extend(setup, settings);

                    var hasAnimated = false;
                    var $scope = $rootScope.$new(true);
                    var $element = $compile('<select-list></select-list>')($scope);

                    var returnObj = {
                        show: function () {
                            var d = $q.defer();

                            if (setup.options.length) {
                                $scope.options = setup.options;
                                $scope.title = setup.title;
                                $scope.isMultiple = setup.isMultiple === true;

                                $scope.dismiss = function ($event, data) {
                                    // Revert overlay device color adjustments
                                    Page.revertOverlayColorAdjustment();

                                    if ($event) {
                                        $event.preventDefault();
                                        $event.stopPropagation();
                                    }

                                    returnObj.dismiss().then(function () {
                                        // Successfully dismissed
                                        if (data) {
                                            d.resolve(data);
                                        } else {
                                            d.reject();
                                        }
                                    }, function () {
                                        // Failed to dismiss
                                        d.reject();
                                    });
                                };

                                // Adjust colors for the overlay to match for every device
                                Page.overlayColorAdjustment('#000', '#fff');

                                $animate.enter($element, $document[0].body, $document[0].body.lastChild).then(function () {
                                    hasAnimated = true;
                                });
                            } else {
                                d.reject();
                            }

                            return d.promise;
                        },
                        dismiss: function () {
                            var d = $q.defer();

                            // Revert overlay device color adjustments
                            Page.revertOverlayColorAdjustment();

                            if (hasAnimated) {
                                $timeout(function () {
                                    $animate.leave($element).then(function () {
                                        d.resolve();
                                    });
                                }, 100);
                            } else {
                                d.reject();
                            }

                            return d.promise;
                        }
                    };
                    return returnObj;
                }
            };
        }
    ]);
})();