(() =>{
    'use strict';

    angular
        .module('App')
        .component('tasks', {
            template: require('./TasksComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$filter', 'TaskManagementRenderService', TasksController],
            bindings: {
                issues: '<',
                sortByStart: '<',
                isDesktop: '<'
            }
        });

    function TasksController($scope) {
        var ctrl = this;

        $scope.$watchCollection('ctrl.issues', function (issues) {
            if (!_.isEmpty(ctrl.issues)) {
                ctrl.issues = issues;
            }
        });
    }
})();