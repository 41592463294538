(() => {
    'use strict';

    angular
        .module('App')
        .component('filterButton', {
            template: require('./FIlterButton.tpl.html'),
            controller: ['$rootScope', FilterButtonController],
            controllerAs: 'ctrl',
            bindings: {
                buttons: '<',
                place: '@',
                hasButtons: '=?'
            }
        });

    function FilterButtonController($rootScope) {
        var ctrl = this;

        ctrl.$onInit = function () {
            refreshButtons();
        };

        ctrl.$onChanges = function () {
            refreshButtons();
        };

        $rootScope.$on('openHeaderMenu', () => {
            if (ctrl.place === 'title' && ctrl.visibleButtons?.length) {
                ctrl.visibleButtons[0]?.onClick && ctrl.visibleButtons[0].onClick()
            }
        })

        function refreshButtons() {
            if (ctrl.buttons) {
                ctrl.visibleButtons = ctrl.buttons.filter(function (btn) {
                    return btn.place === ctrl.place
                })
                ctrl.hasButtons = ctrl.visibleButtons?.length;
            }
        }
    }
})();