(() => {
    'use strict';

    angular
        .module('App')
        .component('taskManagementCommentsPopup', {
            template: require('./TaskManagementCommentsPopupComponent.html'),
            controller: ['$scope', '$element', '$http', '$q', '$timeout', 'CommentsService', 'ReportPopupService', TaskManagementCommentsPopupController],
            controllerAs: 'ctrl',
            bindings: {
                issue: '=?',
                settings: '<'
            }
        });


    function TaskManagementCommentsPopupController($scope, $element, $http, $q, $timeout, CommentsService, ReportPopupService) {
        var ctrl = this, popup;

        ctrl.popupId = 'CommentsPopupId';
        ctrl.posting = false;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.onClose = onClose;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.postComment = postComment;
        ctrl.deleteComment = deleteComment;
        ctrl.editComment = editComment;
        ctrl.mapToCurrentLocale = mapToCurrentLocale;
        ctrl.onReport = reportIssueComment;

        function onOpen() {
            initNewComment();

            ctrl.posting = false;
        }

        function close() {
            popup && popup.close();
        }

        function onClose() {
            popup.remove();
            $element.remove();
            popup = null;
            $scope.$parent.$destroy();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }

        function onReportSubmit(model, issueCommentId) {
            return ReportPopupService.reportTaskComment({
                issueCommentId,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportIssueComment(comment) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, comment.IssueCommentId);
        }

        function postComment() {
            var d = $q.defer(), isEdit = !!ctrl.newComment.issueCommentId, model, url;

            if (!ctrl.posting && (ctrl.newComment.text.length > 0 || ctrl.newComment.uploadedMedia.length)) {
                ctrl.posting = true;

                model = {
                    token: ctrl.issue.IssueToken,
                    comment: ctrl.newComment.text,
                    mediaList: ctrl.newComment.uploadedMedia.map(function (item) {
                        return item.MediaId;
                    })
                }

                if (!isEdit) {
                    url = '/TaskManagement/PostComment';
                } else {
                    url = '/TaskManagement/UpdateComment'
                    model.issueCommentId = ctrl.newComment.issueCommentId;
                }

                $http.post(url, model).then(function (response) {
                    isEdit = false;
                    ctrl.issue.Comments = mapToCurrentLocale(response.data);
                    ctrl.posting = false;
                    initNewComment();

                    d.resolve();
                }).catch(function () {
                    ctrl.posting = false;
                    d.resolve();
                });
            } else {
                d.resolve();
            }

            return d.promise;
        }

        function deleteComment(comment) {
            CommentsService.deleteComment('/TaskManagement/DeleteComment', {
                token: ctrl.issue.IssueToken,
                issueCommentId: comment.IssueCommentId
            }).then(function (comments) {
                ctrl.issue.Comments = mapToCurrentLocale(comments);
            })
        }

        function editComment(comment) {
            if (!ctrl.posting) {
                ctrl.posting = true;
                $http.post('/TaskManagement/UpdateComment', {
                    token: ctrl.issue.IssueToken,
                    issueCommentId: comment.IssueCommentId,
                    comment: ctrl.newComment.text
                }).then(function (response) {
                    ctrl.issue.Comments = mapToCurrentLocale(response.data);
                    ctrl.posting = false;
                }).catch(function () {
                    ctrl.posting = false;
                });
            }
        }

        function initNewComment() {
            ctrl.newComment = {
                text: '',
                uploadedMedia: []
            };
        }

        function mapToCurrentLocale(comments) {
            comments.map(function (item) {
                item._currentLocale = item._currentLocale ?? {};
                item._currentLocale.Text = item.TranslatableEntity.LocalizedLocale.Text;
            });

            return comments;
        }
    }
})();
