(() => {
    'use strict';

    angular
        .module('App')
        .directive('mediaUploadProgress', ['BasicHelper', function (BasicHelper) {
            return {
                restrict: 'E',
                template: require('./Templates/MediaUploadProgress.tpl.html'),
                controller: ['$rootScope', '$scope', function ($rootScope, $scope) {
                    $scope.files = [];
                    $scope.options = {};

                    // Begin
                    $rootScope.$on('fileUploadBegin', function (event, args) {
                        $scope.options = args.options || {};
                        $scope.files.push(args.file);
                        args.file.id = BasicHelper.getRandomID();
                    });

                    // Done
                    $rootScope.$on('fileUploadDone', function (event, args) {
                        var index = 0;
                        angular.forEach($scope.files, function (item) {
                            if (item.id === args.file.id) {
                                $scope.files.splice(index, 1);
                                return;
                            }
                            index++;
                        });

                        if (!$scope.files.length) {
                            $scope.options = {};
                        }
                    });
                }],
                compile: function (element, attrs) {

                }
            };
        }
        ]);
})();