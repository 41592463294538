(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('calendarItem', ['$timeout', '$translate',
        function ($timeout, $translate) {
            return {
                restrict: 'E',
                require: '^calendar',
                transclude: true,
                scope: {
                    item: '='
                },
                link: function (scope, element, attrs, calendar) {
                    var id = scope.$id;
                    scope.item.$CalendarItemId = id;
                    calendar.AddItem(scope.item);

                    scope.$on('$destroy', function () {
                        calendar.RemoveItem(id);
                    });
                }
            }
        }
    ]);
})();