(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('stickyTopClass', ['$window', function ($window) {
        var $win = angular.element($window); // wrap window object as jQuery object

        return {
            restrict: 'A', link: function (scope, element, attrs) {
                const topClass = attrs.stickyTopClass,  // get CSS class from directive's attribute value
                    headerHeight = element.height();
                let savedState = $win.scrollTop() >= headerHeight;

                if (attrs.stickyTopParent) {
                    $win = angular.element(attrs.stickyTopParent);
                }

                $win.on('scroll', _.throttle(function (e) {
                    const scrollTop = element.hasClass('fixed-top') ? 
                        $win.scrollTop() + (headerHeight - angular.element('.head').height()) : $win.scrollTop();
                    const currentState = scrollTop >= headerHeight;

                    if (savedState !== currentState) {
                        savedState = currentState;
                        element.toggleClass(topClass, savedState)
                    }
                }, 50));
            }
        };
    }]);
})();
