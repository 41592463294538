(function () {
    'use strict';

    angular
        .module('App')
        .factory('CampaignService', ['$http', '$q', 'TranslatableEntityService', CampaignService]);

    function CampaignService($http, $q, TranslatableEntityService) {
        return {
            loadModule: loadModule,
            getCampaign: getCampaign,
            translateCampaignRequest: translateCampaignRequest,
            translateCampaignSlotRequest: translateCampaignSlotRequest,
            registerSlotActivity: registerSlotActivity
        };

        function loadModule(accountModuleToken) {
            var defer = $q.defer();

            $http.get('/campaign/loadModule/' + accountModuleToken).then((res) => {
                var result = res.data;
                result.Campaigns = mapCampaigns(result.Campaigns);
                defer.resolve(result);
            }).catch(() => {
                defer.reject();
            });

            return defer.promise;
        }

        function getCampaign(accountModuleToken, campaignToken) {
            var defer = $q.defer();

            $http.get('/campaign/getCampaign', {
                params: {
                    campaignToken: campaignToken,
                    accountModuleToken: accountModuleToken
                }
            }).then((res) => {
                if (res.data) {
                    defer.resolve(mapCampaign(res.data));
                } else {
                    defer.reject();
                }
            }).catch(() => {
                defer.reject();
            });

            return defer.promise;
        }

        function registerSlotActivity(accountModuleToken, campaignSlotToken){
            $http.post('/campaign/registerActivity', {
                accountModuleToken: accountModuleToken,
                campaignSlotToken: campaignSlotToken
            });
        }

        function translateCampaignRequest(campaign) {
            return $http.post('/campaign/translateCampaign', {
                accountModuleToken: campaign.AccountModuleToken,
                campaignToken: campaign.CampaignToken
            }).then(res => res.data);
        }

        function translateCampaignSlotRequest(campaign, campaignSlot) {
            return $http.post('/campaign/translateCampaignSlot', {
                accountModuleToken: campaign.AccountModuleToken,
                campaignSlotToken: campaignSlot.CampaignSlotToken
            }).then(res => res.data);
        }

        function mapCampaigns(campaigns) {
            if (campaigns && campaigns.length) {
                _.forEach(campaigns, mapCampaign);
            }
            return campaigns;
        }

        function mapCampaign(campaign) {
            if (campaign) {
                TranslatableEntityService.initTranslatableEntity(campaign.TranslatableEntity);
                if (campaign.Slots && campaign.Slots.length) {
                    _.forEach(campaign.Slots, mapSlot);
                }
            }
            return campaign;
        }

        function mapSlot(slot) {
            if (slot) {
                TranslatableEntityService.initTranslatableEntity(slot.TranslatableEntity);
            }
            return slot;
        }
    }
})();