(() => {

    angular
        .module('App')
        .factory('CreateUpdateFillingService', ['$http', '$stateParams', 'TaskManagementRenderService',
            'ServiceFormDataFormatService', 'ConfirmPopupService', CreateUpdateFillingService]);

    function CreateUpdateFillingService($http, $stateParams, TaskManagementRenderService, ServiceFormDataFormatService,
                                        ConfirmPopupService) {

        return {
            createPreDraft: createPreDraft,
            updateFilling: updateFilling,
            setSingleFieldValue: setSingleFieldValue,
            informAboutFillingUpdate: informAboutFillingUpdate,
            updateVisibleSteps: updateVisibleSteps,
            getCurrentPageId: getCurrentPageId,
            haveRuleAction: haveRuleAction,
            updateFormOnRules: updateFormOnRules,
            getSaveModel: getSaveModel
        };

        function createPreDraft(model) {
            model.Token = model.Token || $stateParams.token;
            return $http.post('/ServiceForm/CreatePreDraft', model)
        }

        function updateFilling(model) {
            const formattedFormData = ServiceFormDataFormatService.formatDataForServer(model.form);

            return $http.post('/ServiceForm/UpdateFilling', {
                token: model.accountModuleToken || $stateParams.token,
                isDraft: model.isDraft,
                serviceFormFillingToken: model.serviceFormFillingToken,
                values: formattedFormData,
                issueId: model.issueId,
                CalendarEventId: model.calendarEventId,
                serviceFormStageToken: model.stageToken,
                PageId: model.pageId
            })
        }


        function setSingleFieldValue(model) {
            const value = ServiceFormDataFormatService.formatFields([model.Field]);

            return $http.post('/ServiceForm/SetSingleFieldValues', {
                token: model.AccountModuleToken || $stateParams.token,
                ServiceFormFillingToken: model.ServiceFormFillingToken,
                Values: value,
                ServiceFormStageToken: model.ServiceFormStageToken,
                ServiceFormFieldId: model.ServiceFormFieldId
            }).then(resp => {
                ServiceFormDataFormatService.setModelData(resp.data.Values, model.Field)
                return resp
            })
        }

        function informAboutFillingUpdate(serviceFormFillingId) {
            ConfirmPopupService
                .open({
                    message: 'SERVICEFORM.FEEDBACK.INFORM_MANAGER',
                })
                .then(() => {
                    return $http.post('/ServiceForm/InformAboutFillingUpdate', {
                        serviceFormFillingId: serviceFormFillingId
                    });
                })
        }

        function updateVisibleSteps(form, pageNavigation) {
            return form.map(page => {
                page.isActive = pageNavigation.find(step => page.StepNumber === step.step) !== undefined;

                return page
            })
        }

        function getCurrentPageId(form, activeStep) {
            return form.find(step => step.StepNumber === activeStep).ServiceFormPageId
        }

        function haveRuleAction(ruleActions, lastRuleActions) {
            return ruleActions.FieldsToShow.length || ruleActions.InstantFeedbacks.length || lastRuleActions &&
                (lastRuleActions.FieldsToShow.length || lastRuleActions.InstantFeedbacks.length);
        }

        function updateFormOnRules(ruleActions, form, lastRuleActions, callback) {
            if (ruleActions) {
                if (haveRuleAction(ruleActions, lastRuleActions)) {
                    form.forEach(step => {
                        step.Groups.forEach(group => {
                            group.Fields.forEach(field => {
                                if (ruleActions.FieldsToShow.find(id => id === field.ServiceFormFieldId)) {
                                    field.IsVisibleOnFormLoad = true;
                                } else if (lastRuleActions.FieldsToShow.find(id => id === field.ServiceFormFieldId)) {
                                    field.IsVisibleOnFormLoad = false;
                                }

                                const feedback = ruleActions.InstantFeedbacks
                                    .find(feedback => feedback.ServiceFormFieldId === field.ServiceFormFieldId);
                                if (feedback) {
                                    field.InstantFeedback = feedback;
                                } else if (lastRuleActions.InstantFeedbacks
                                    .find(feedback => feedback.ServiceFormFieldId === field.ServiceFormFieldId)) {
                                    field.InstantFeedback = null;
                                }
                            });
                        })
                    })
                }
            }
            callback && callback(ruleActions);
        }

        function getSaveModel(ctrl, isLast) {
            const form = getFormDataForStep(ctrl, isLast),
                pageId = getCurrentPageId(ctrl.form, ctrl.activeStep);

            return {
                form: form,
                isDraft: ctrl.IsDraft,
                serviceFormFillingToken: ctrl.filling.ServiceFormFillingToken,
                issueId: ctrl.issueId,
                calendarEventId: ctrl.calendarEventId,
                stageToken: ctrl.stageToken,
                pageId: pageId,
                accountModuleToken: ctrl.accountModuleToken
            }
        }

        function getFormDataForStep(ctrl, isLast) {
            if (ctrl.isDraftEnabled) {
                const stepsData = [], lastStep = ctrl.form[ctrl.form.length - 1].StepNumber;

                ctrl.maxActiveStep = isLast ? lastStep + 1 : ctrl.maxActiveStep;

                ctrl.form.forEach(step => {
                    if (step.StepNumber < ctrl.maxActiveStep && ctrl.PageNavigation.find(page => page.step === step.StepNumber)) {
                        stepsData.push(step)
                    }
                })

                return stepsData
            }

            return ctrl.form;
        }
    }
})();