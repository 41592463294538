(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlDateFilter', {
            template: require('./DateFilterComponent.html'),
            controller: ['ActionSheetCustomService', DateFilterController],
            controllerAs: 'ctrl',
            bindings: {
                onFilterSelected: '<',
                selectedOptionId: '<',
                initialStartDate: '<',
                initialEndDate: '<',
                reset: '=?',
            }
        });

    function DateFilterController(ActionSheetCustomService) {
        const ctrl = this;

        ctrl.CUSTOM_OPTION_ID = 6;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.openFilters = openFilters;
        ctrl.selectOption = selectOption;
        ctrl.reset = reset;

        function init() {
            ctrl.options = [
                { id: 1, title: 'DATE_FILTER.TODAY' },
                { id: 2, title: 'DATE_FILTER.YESTERDAY' },
                { id: 3, title: 'DATE_FILTER.LAST_THREE' },
                { id: 4, title: 'DATE_FILTER.LAST_WEEK' },
                { id: 5, title: 'DATE_FILTER.LAST_MONTH' },
                { id: 6, title: 'DATE_FILTER.CUSTOM' }
            ];

            if (ctrl.selectedOptionId !== undefined) {
                const option = ctrl.options.find(option => option.id === ctrl.selectedOptionId);
                option.selected = true;
                if (ctrl.selectedOptionId === ctrl.CUSTOM_OPTION_ID) {
                    ctrl.startDate = ctrl.initialStartDate;
                    ctrl.endDate = ctrl.initialEndDate;
                    ctrl.title = getTitle(ctrl.startDate, ctrl.endDate);
                } else {
                    ctrl.title = option.title;
                }
            }
        }

        function openFilters() {
            ActionSheetCustomService.create(
                `<rl-date-filters-popup 
                    on-filter-selected="onFilterSelected"
                    options="options"
                    start-date="startDate"
                    end-date="endDate"
                    remove-selection="removeSelection"
                ></rl-date-filters-popup>`,
                {
                    onFilterSelected: ctrl.selectOption,
                    options: ctrl.options,
                    startDate: ctrl.startDate,
                    endDate: ctrl.endDate,
                    removeSelection
                }, 'rl-date-filter');
        }


        function selectOption(option, startDate, endDate) {
            if (!option) {
                reset();
                ctrl.onFilterSelected && ctrl.onFilterSelected();
            } else {
                ctrl.startDate = startDate;
                ctrl.endDate = endDate;

                ctrl.onFilterSelected && ctrl.onFilterSelected(option.id, getDatePeriod(option.id, startDate, endDate));
                ctrl.title = option.id === ctrl.CUSTOM_OPTION_ID ? getTitle(startDate, endDate) : option.title;
            }
        }

        function getTitle(startDate, endDate) {
            if (startDate && endDate) {
                return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
            }
            if (startDate && !endDate) {
                return `from ${moment(startDate).format('DD/MM/YYYY')}`;
            }
            if (!startDate && endDate) {
                return `till ${moment(endDate).format('DD/MM/YYYY')}`;
            }
        }

        function reset() {
            delete ctrl.startDate;
            delete ctrl.endDate;

            removeSelection();
        }

        function removeSelection() {
            const selectedOption = getSelectedOption();
            if (selectedOption) {
                delete selectedOption.selected;
            }

            ctrl.title = '';
        }

        function getSelectedOption() {
            return ctrl.options.find(option => option.selected);
        }

        function getDatePeriod(id, startDate, endDate) {
            switch (id) {
                case 1:
                    return {
                        startDate: moment().startOf('day').toDate(),
                        endDate: moment().endOf('day').toDate()
                    };
                case 2:
                    return {
                        startDate: moment().subtract(1, 'days').startOf('day').toDate(),
                        endDate: moment().subtract(1, 'days').endOf('day').toDate()
                    };
                case 3:
                    return {
                        startDate: moment().subtract(3, 'days').startOf('day').toDate(),
                        endDate: moment().endOf('day').toDate()
                    };
                case 4:
                    return {
                        startDate: moment().subtract(1, 'weeks').startOf('week').startOf('day').toDate(),
                        endDate: moment().subtract(1, 'weeks').endOf('week').endOf('day').toDate()
                    };
                case 5:
                    return {
                        startDate: moment().subtract(1, 'months').startOf('month').startOf('day').toDate(),
                        endDate: moment().subtract(1, 'months').endOf('month').endOf('day').toDate()
                    };
                case 6:
                    return {
                        startDate: startDate ? moment(startDate).startOf('day').toDate() : '',
                        endDate: endDate ? moment(endDate).endOf('day').toDate() : ''
                    };
            }
        }

        function destroy() {

        }
    }
})();