(function () {
    'use strict';

    angular.module('App')
        .component('createChat', {
            template: require('./CreateChatComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$rootScope', 'ChatPopupsService', 'ChatConnectionService', 'ChatDataService',
                CreateChatController]
        });

    function CreateChatController($element, $rootScope, ChatPopupsService, ChatConnectionService, ChatDataService) {
        const ctrl = this,
            pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());
        let popup;


        ctrl.fetching = false;
        ctrl.usersLoading = true;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onSingleUserSelect = onSingleUserSelect;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.createChat = createChat;
        ctrl.close = close;
        ctrl.loadUsers = loadUsers;
        ctrl.blockedUsersIds = ChatDataService.getBlockedIds();

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            ctrl.selectedUsers = [];
            ctrl.users = loadUsers(0, 100).then(function (resp) {
                ctrl.moreUsers = resp.MoreUsers;

                return resp.Users
            });
        }

        function loadUsers(offset, limit, name) {
            return ChatConnectionService.getAllUsers(offset, limit, name, ctrl.blockedUsersIds).then(resp => {
                ctrl.usersLoading = false;
                return { ...resp, Users: resp.Users };
            });
        }

        function close() {
            pushOpenWatcher();
            popup.remove();
            $element.remove();
        }

        function createChat(users) {
            if (!ctrl.fetching) {
                ctrl.fetching = true;

                ChatDataService.createChannel(users || ctrl.selectedUsers)
                    .then(function (channelId) {
                        $rootScope.$broadcast('CHAT.CHANNEL_CREATED', channelId);
                        close();
                        ctrl.fetching = false;
                    })
                    .catch(function (e) {
                        ctrl.fetching = false;
                      
                    });
            }
        }

        function onSingleUserSelect(users) {
            createChat(users);
        }
    }
})();