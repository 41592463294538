(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleQuestionMedia', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleQuestionMedia.html',
                scope: {
                    question: '='
                },
                controller: ['$scope', '$element', '$attrs', 'AttachmentsViewerService',
                    function ($scope, $element, $attrs, AttachmentsViewerService) {

                        // Init
                        InitController();
                        function InitController() {
                            if ($scope.question.answers == undefined) {
                                $scope.question.answers = [];
                            }
                            CheckAnswers();
                        }

                        // Methods
                        function CheckAnswers() {
                            var questionAnswered = true;
                            var questionIsRequired = false;

                            for (var i = 0; i < $scope.question.Options.length; i++) {
                                var opt = $scope.question.Options[i];
                                if (opt.IsRequired && !opt.answered) {
                                    questionAnswered = false;
                                } else {
                                    opt.answered = true;
                                }

                                if (opt.IsRequired) {
                                    questionIsRequired = true;
                                }
                            }

                            $scope.question.answered = questionAnswered;
                            $scope.question.IsRequired = questionIsRequired;
                        }

                        function DeleteAnswer(optionId) {
                            var index = -1;
                            for (var i = 0; i < $scope.question.answers.length; i++) {
                                var answer = $scope.question.answers[i];
                                if (answer.FormQuestionOptionId == optionId) {
                                    index = i;
                                }
                            }

                            if (index >= 0) {
                                $scope.question.answers.splice(index, 1);
                            }
                        }

                        function OptionImageUpload(media, option) {
                            // Delete old answer
                            DeleteAnswer(option.FormQuestionOptionId);

                            // Save new answer
                            $scope.question.answers.push(new QuestionAnswer(option.FormQuestionOptionId, media));
                            option.answered = true;
                            option.media = media;

                            // Update question
                            CheckAnswers();
                        }

                        // Objects
                        function QuestionAnswer(optionId, media) {
                            this.FormQuestionOptionId = optionId;
                            this.Media = media;
                            this.MediaId = media.MediaId;
                        }

                        function previewMedia(media) {
                            if (media) {
                                AttachmentsViewerService.openViewerWithMedia([media]);
                            }
                        }

                        // Bind scope
                        $scope.optionImageUpload = OptionImageUpload;
                        $scope.previewMedia = previewMedia;
                    }
                ]
            }
        }
    ]);
})();