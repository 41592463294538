(function () {
    'use strict';

    angular
        .module('App')
        .component('subtaskDetails', {
            template: require('./SubtaskDetailsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$filter', 'SubtaskDetailsService', 'SubtasksListService', 'ActionSheetService',
                'UpdateSubtaskPopupService', 'ConfirmPopupService', 'ToastFactory', SubtaskDetailsController],
            bindings: {
                issueToken: '<',
                taskToken: '<',
                close: '<',
                closeOnDelete: '<',
                loading: '<',
                completionData: '<'
            }
        });

    function SubtaskDetailsController($rootScope, $timeout, $filter, SubtaskDetailsService, SubtasksListService, ActionSheetService,
                                      UpdateSubtaskPopupService, ConfirmPopupService, ToastFactory) {

        let ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.showSubtaskOptions = showSubtaskOptions;
        ctrl.toggleExpandState = toggleExpandState;
        ctrl.completeSubtask = completeSubtask;
        ctrl.statusClass = SubtaskDetailsService.getStatusClass;
        ctrl.cancelReopen = cancelReopen;
        ctrl.issueStatus = '';

        function init() {
            ctrl.expandedSections = {
                files: true
            };
            ctrl.loading = true;

            SubtasksListService.getSubtaskDetails(ctrl.issueToken).then(function (resp) {
                ctrl.issue = resp.data;
                if (resp.data) {
                    ctrl.statusClass = SubtaskDetailsService.prepareIssueStatus(ctrl.issue);
                    ctrl.showOriginal = true;
                    ctrl.issueStatus = SubtaskDetailsService.prepareIssueStatus(ctrl.issue.Model);
                    ctrl.statusClass = SubtaskDetailsService.getStatusClass(ctrl.issue.Model);
                    if (ctrl.issue.Model.TranslatableEntity.LocalizedAlternativeLocale) {
                        ctrl.showOriginal = !(ctrl.issue.Model.TranslatableEntity.PreferTranslatedVersion && ctrl.issue.Model.TranslatableEntity.LocalizedAlternativeLocale.Title || ctrl.issue.Model.TranslatableEntity.LocalizedAlternativeLocale.Summary);
                    }
                }
                ctrl.loading = false;
            });
        }

        function toggleExpandState(section) {
            ctrl.expandedSections[section] = !ctrl.expandedSections[section];
        }

        function showSubtaskOptions() {
            const buttons = SubtaskDetailsService.prepareOptionsButtons(
                    ctrl.issue, ctrl.closeOnDelete, ctrl.close
                ),
                taskDetailsActions = ActionSheetService.create(buttons, null, null, { name: 'subtask-details'});

            taskDetailsActions.show();
        }

        function destroy() {
            popup.remove();
            $element.remove();
        }

        function completeSubtask(isCompleted) {
            if (!isCompleted) {
                return UpdateSubtaskPopupService.completeSubtask(ctrl.issue.Model.IssueSubtaskToken).then(resp => {
                    ctrl.issue.Model.IsCompleted = true;
                    ctrl.statusClass = SubtaskDetailsService.getStatusClass(ctrl.issue.Model);
                    ctrl.issueStatus = SubtaskDetailsService.prepareIssueStatus(ctrl.issue.Model);
                    $rootScope.$emit('TaskManagement:subtaskCompleted', resp.data);
                    ToastFactory.successTranslated('TASK_MANAGEMENT.SUBTASK.COMPLETED');
                    $timeout(() => {
                        ctrl.close();
                    }, 300)
                });
            } else {
                return ConfirmPopupService
                    .open({message: $filter('translate')('TASK_MANAGEMENT.TASK.REOPEN'), onClose: ctrl.cancelReopen})
                    .then(() => {
                        return UpdateSubtaskPopupService.subtaskReopen(ctrl.issue.Model.IssueSubtaskToken).then(resp => {
                            ctrl.issue.Model.IsCompleted = false;
                            ctrl.statusClass = SubtaskDetailsService.getStatusClass(ctrl.issue.Model);
                            ctrl.issueStatus = SubtaskDetailsService.prepareIssueStatus(ctrl.issue.Model);
                            $rootScope.$emit('TaskManagement:subtasksListUpdated', resp.data);
                            ctrl.close();
                        })
                    })
                    .catch(function (error) {
                        ctrl.cancelReopen();
                    });
            }
        }

        function cancelReopen() {
            ctrl.issue.Model.IsCompleted = true;
        }

        function toggleTranslation() {
            if (ctrl.issue.Model.TranslatableEntity.LocalizedLocale?.Title && ctrl.issue.Model.TranslatableEntity.LocalizedAlternativeLocale?.Title) {
                ctrl.showOriginal = !ctrl.showOriginal;
            } else {
                SubtaskDetailsService.translateSubtaskToUserCulture({
                    issueToken: ctrl.taskToken,
                    subtaskToken: ctrl.issue.Model.IssueSubtaskToken
                })
                    .then(translation => {
                        ctrl.issue.Model.TranslatableEntity = translation;
                        ctrl.showOriginal = !ctrl.showOriginal;
                    })
            }
        }
    }
})();
