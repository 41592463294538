(function () {
    'use strict';

    angular
        .module('App')
        .factory('TranslatableEntityService', ['$q', TranslatableEntityService]);

    function TranslatableEntityService($q) {
        return {
            initTranslatableEntity: initTranslatableEntity,
            switchCurrentLocale: switchCurrentLocale
        };

        function initTranslatableEntity(translatableEntity){
            if(translatableEntity.LocalizedAlternativeLocale !== null && translatableEntity.PreferTranslatedVersion){
                translatableEntity._currentCultureId = translatableEntity.LocalizedAlternativeLocale.CultureId;
                translatableEntity._currentLocale = translatableEntity.LocalizedAlternativeLocale;
                return;
            }

            translatableEntity._currentCultureId = translatableEntity.LocalizedLocale.CultureId;
            translatableEntity._currentLocale = translatableEntity.LocalizedLocale;
            return;
        }

        function switchCurrentLocale(translatableEntity, autoTranslateRequest){
            var defer = $q.defer();

            if (translatableEntity._currentCultureId === translatableEntity.LocalizedLocale?.CultureId) {
                // Showing the original
                if(translatableEntity.LocalizedAlternativeLocale){
                    // We have an alternative, so we show that
                    translatableEntity._currentCultureId = translatableEntity.LocalizedAlternativeLocale.CultureId;
                    translatableEntity._currentLocale = translatableEntity.LocalizedAlternativeLocale;
                    defer.resolve(translatableEntity);
                }else{
                    // We do not have an alternative and should instead auto-translate if possible
                    if(translatableEntity.AllowAutoTranslation && autoTranslateRequest){

                        // Ensure we get a promise
                        if(autoTranslateRequest instanceof Function){
                            autoTranslateRequest = autoTranslateRequest();
                        }

                        autoTranslateRequest.then(res => {
                            if(translatableEntity.LocalizedLocale.CultureId !== res.LocalizedLocale.CultureId){
                                // The new original is not the same as before - We therefore expect it to be a new closer match, and should map it to the alternative instead
                                res.LocalizedAlternativeLocale = res.LocalizedLocale;
                            }

                            translatableEntity.LocalizedAlternativeLocale = res.LocalizedAlternativeLocale;
                            translatableEntity._currentCultureId = res.LocalizedAlternativeLocale.CultureId;
                            translatableEntity._currentLocale = res.LocalizedAlternativeLocale;
                            defer.resolve(translatableEntity);
                        }).catch(() => {
                            defer.reject();
                        });
                    }else{
                        defer.reject();
                    }
                }
            } else {
                // Showing the alternative locale - it means we can always swap back to the original
                translatableEntity._currentCultureId = translatableEntity.LocalizedLocale.CultureId;
                translatableEntity._currentLocale = translatableEntity.LocalizedLocale;
                defer.resolve(translatableEntity);
            }

            return defer.promise;
        }
    }
})();