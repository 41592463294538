(function () {
    'use strict';

    angular.module('App')
        .factory('CommentsService', ['$http', '$filter', 'ConfirmPopupService', 'ToastFactory',
            function ($http, $filter, ConfirmPopupService, ToastFactory) {

                return {
                    buildCommentsTree: buildCommentsTree,
                    deleteComment: deleteComment,
                    decodeHtml: decodeHtml,
                };

                function buildCommentsTree(comments, commentIdKey, parentCommentIdKey) {
                    var tree = [];
                    _.each(comments, function (comment) {
                        if (!comment[parentCommentIdKey]) {
                            tree.push(comment)
                        }
                    });

                    tree.map(function (item) {
                        return item.ChildComments = comments.filter(function (comment) {
                            return item[commentIdKey] === comment[parentCommentIdKey];
                        })
                    })

                    return tree;
                }

                function deleteComment(url, options) {
                    var opts = {
                        message: $filter('translate')('SOCIAL.CONFIRM_DELETE')
                    };

                    return ConfirmPopupService.open(opts).then(function () {
                        return $http.post(url, options)
                            .then(function (resp) {
                                return resp.data || [];
                            })
                            .catch(function () {
                                ToastFactory.error($filter('translate')('ERROR.GENERAL'));
                            })
                    });
                }

                function decodeHtml(text) {
                    var doc = new DOMParser().parseFromString(text, "text/html");
                    return doc.documentElement.textContent;
                }
            }]);
})();