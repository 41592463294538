(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('ServiceFormCustomerService', ['$q', 'GeolocationService', 'ServiceFormRestService',
        function ($q, GeolocationService, ServiceFormRestService) {

            return {
                calculateDistances: calculateDistances,
                getClosestCustomer: function (dataListId, isCalculateDistancesRequired) {
                    return GeolocationService.getLocation().then(function (geoData) {
                        var isCalculateDistances = isCalculateDistancesRequired && !_.isUndefined(geoData);
                        if (isCalculateDistances) {
                            return ServiceFormRestService.getClosestCustomer(geoData.latitude, geoData.longitude, dataListId).then(function (customer) {
                                return calculateDistances([customer], isCalculateDistances).then(function (customer) {
                                    return customer[0];
                                });
                            })
                        }
                    });
                }
            };

            function createSubtitles(item) {
                var cityAndZipcode = '',
                    subtitles = [];
                if (item.AdditionalValues.Street) {
                    subtitles.push(item.AdditionalValues.Street);
                }
                if (item.AdditionalValues.ZipCode) {
                    cityAndZipcode += item.AdditionalValues.ZipCode + ' ';
                }
                if (item.AdditionalValues.City) {
                    cityAndZipcode += item.AdditionalValues.City;
                }
                if (cityAndZipcode) {
                    subtitles.push(cityAndZipcode);
                }
                if (item.AdditionalValues.Country) {
                    subtitles.push(item.AdditionalValues.Country);
                }
                return subtitles;
            }

            function formatListData(listData, geoData, isCalculateDistancesRequired) {
                var isCalculateDistances = isCalculateDistancesRequired && !_.isUndefined(geoData);

                listData.forEach(function (customer) {
                    customer.subtitles = createSubtitles(customer);
                    if (!_.isNull(customer.AdditionalValues.Latitude) && !_.isNull(customer.AdditionalValues.Longitude) && isCalculateDistances) {
                        customer.distance = GeolocationService.getDistance(
                            customer.AdditionalValues.Latitude,
                            customer.AdditionalValues.Longitude,
                            geoData.latitude,
                            geoData.longitude
                        );
                        customer.subtitles.push(customer.distance.toFixed(2) + ' km');
                    }
                });

                if (isCalculateDistances) {
                    listData.sort(function (first, second) {
                        return first.distance - second.distance;
                    });
                }

                return listData;
            }

            function calculateDistances(listData, isCalculateDistancesRequired) {
                if (isCalculateDistancesRequired) {
                    return GeolocationService.getLocation().then(function (geoData) {
                        return formatListData(listData, geoData, isCalculateDistancesRequired)
                    });
                } else {
                    return $q(function (resolve) {
                        resolve(formatListData(listData));
                    })
                }
            }

        }]);

})();