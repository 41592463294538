(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlToggle', {
            template: require('./RelesysToggleComponent.html'),
            controller: ['$timeout', RlToggleController],
            controllerAs: 'ctrl',
            bindings: {
                model: '=?',
                disabled: '<',
                onChange: '&',
                customId: '@'
            }
        });


    function RlToggleController($timeout) {
        var ctrl = this;

        ctrl.id = ctrl.customId || 'id-' + Math.random().toString(36).substring(2);
        ctrl.change = change;

        function change() {
            $timeout(function () {
                ctrl.onChange && ctrl.onChange();
            })
        }
    }
})();


