(function () {
    'use strict';

    angular.module('App')
        .component('usersList', {
            template: require('./UsersListComponent.tpl.html'),
            controller: ['$q', '$scope', '$timeout', 'Profile', 'ProfileViewerService', UserListController],
            controllerAs: 'ctrl',
            transclude: true,
            bindings: {
                previewOnly: '<',
                users: '<',
                multiple: '<',
                selectedUsers: '=?',
                onSingleUserSelect: '<',
                disableSearch: '<',
                hideDivider: '<',
                onLazyPaging: '<',
                moreUsers: '<',
                filterFunction: '<',
                nextOffset: '<',
                onTranscludeClick: '<',
                showTransclude: '<',
                excludeUsersIds: '<',
                blockedUsersIds: '<',
                trackByKey: '@',
                userNameKey: '@',
                departmentKey: '@',
                imageKey: '@',
                showChatHistory: '<',
                shareHistory: '=?',
                admins: '<',
                showActions: '<',
                searchNameOnly: '=?'
            }
        });

    function UserListController($q, $scope, $timeout, Profile, ProfileViewerService) {
        var ctrl = this, filterDelta = 0, searchString, watchUser;

        ctrl.currentUser = Profile.getProfile()
        ctrl.showMenuIcon = showMenuIcon;
        ctrl.multiple = ctrl.previewOnly ? false : ctrl.multiple;
        ctrl.userNameKey = ctrl.userNameKey || 'Name';
        ctrl.trackByKey = ctrl.trackByKey || 'UserToken';
        ctrl.departmentKey = ctrl.departmentKey || 'DepartmentName';
        ctrl.imageKey = ctrl.imageKey || 'ProfileImage';

        ctrl.$onInit = init;

        ctrl.$onDestroy = destroy;
        ctrl.paging = paging;
        ctrl.search = search;
        ctrl.showProfile = showProfile;
        ctrl.toggleUserSelection = toggleUserSelection;
        ctrl.removeFromSelected = removeFromSelected;
        ctrl.isPagingDisabled = isPagingDisabled;
        ctrl.isBlocked = isBlocked;
        ctrl.toggleShareHistory = toggleShareHistory;
        ctrl.isAdmin = isAdmin;
        ctrl.isInitializing = true;

        function init() {
            ctrl.selectedUsers = ctrl.selectedUsers ? ctrl.selectedUsers.map(user => ({ ...user, isLoaded: false })) : [];

            $q.when(ctrl.users, function (newUsers) {
                ctrl.isUsersLoaded = true;
                ctrl.users = ctrl.filterFunction ? ctrl.filterFunction(newUsers) : newUsers;
                filterDelta = newUsers.length - ctrl.users.length;

                markSelected(ctrl.users);

                $timeout(function () {
                    initUsersWatcher();
                });

                ctrl.usersLoaded = newUsers.slice(0, 100);
            });
        }

        function initUsersWatcher() {
            ctrl.usersWatcher = $scope.$watchCollection('ctrl.users', function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                ctrl.usersLoaded = ctrl.users.slice(0, 100);
                ctrl.search(searchString);
            });
        }

        function markSelected(users) {
            ctrl.selectedUsers.forEach(selectedUser => {
                const loadedUser = users
                    .find(user => user[ctrl.trackByKey] === selectedUser[ctrl.trackByKey]);

                if (loadedUser) {
                    selectedUser.isLoaded = true;
                    loadedUser.selected = true;
                }
            })
        }

        function showProfile(user) {
            ProfileViewerService.showProfileCard(user.userId);
        }

        function showMenuIcon(user) {
            return ctrl.showActions && user.userId !== ctrl.currentUser.UserToken;
        }

        function paging() {
            ctrl.isPagingLoading = true;
            if (!ctrl.onLazyPaging) {
                if (searchString && searchString.length) {
                    ctrl.usersLoaded = ctrl.users.filter(user => {
                        const searchableText = getSearchableText(user);
                        return searchableText.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
                    }).slice(0, ctrl.usersLoaded.length + 50);
                } else {
                    ctrl.usersLoaded = ctrl.users.slice(0, ctrl.usersLoaded.length + 50);
                }

                ctrl.isPagingLoading = false;
            } else {
                lazyPaging(ctrl.nextOffset || ctrl.usersLoaded.length + filterDelta, 50, searchString, ctrl.excludeUsersIds, true)
            }
        }

        function isPagingDisabled() {
            if (ctrl.onLazyPaging) {
                return ctrl.isPagingLoading || !ctrl.moreUsers
            } else {
                return ctrl.isPagingLoading || !ctrl.usersLoaded || (ctrl.usersLoaded.length === ctrl.users.length);
            }
        }

        function toggleUserSelection(user) {

            if (!ctrl.previewOnly) {
                if (ctrl.multiple) {
                    multipleSelection(user);
                } else {
                    singleSelection(user);
                    ctrl.onSingleUserSelect(ctrl.selectedUsers);
                }
            }
        }

        function removeFromSelected(selectedUser) {
            const userLoaded = ctrl.usersLoaded.find(user => user[ctrl.trackByKey] === selectedUser[ctrl.trackByKey]);
            const userInUsers = ctrl.users.find(user => user[ctrl.trackByKey] === selectedUser[ctrl.trackByKey]);
            if (userLoaded) userLoaded.selected = false;
            if (userInUsers) userInUsers.selected = false;
            
            _.remove(ctrl.selectedUsers, user => {
                return user[ctrl.trackByKey] === selectedUser[ctrl.trackByKey]
            });
        }

        function multipleSelection(user) {
            user.selected = !user.selected;
            if (user.selected) {
                ctrl.selectedUsers.push(user);
            } else {
                _.remove(ctrl.selectedUsers, selectedUser => {
                    return selectedUser[ctrl.trackByKey] === user[ctrl.trackByKey]
                });
            }
        }

        function singleSelection(user) {
            ctrl.selectedUsers = [];
            ctrl.selectedUsers.push(user);
        }

        function toggleShareHistory() {
            ctrl.shareHistory = !ctrl.shareHistory;
        }

        function search(searchStringSrc) {
            searchString = searchStringSrc || '';
            if (ctrl.onLazyPaging) {
                lazyPaging(0, 100, searchString, ctrl.excludeUsersIds, false);
            } else {
                ctrl.usersLoaded = ctrl.users.filter(user => {
                    const searchableText = getSearchableText(user);
                    return  searchableText.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
                });
            }
        }

        function getSearchableText(user) {
            return ctrl.searchNameOnly ? user[ctrl.userNameKey] : `${user[ctrl.userNameKey]} ${user[ctrl.departmentKey]}`;
        }

        function lazyPaging(offset, limit, searchString, excludeUsersIds, isPaging) {
            return ctrl.onLazyPaging(offset, limit, searchString, excludeUsersIds).then(function (resp) {
                let filteredUsers = ctrl.filterFunction ? ctrl.filterFunction(resp.Users) : resp.Users;

                ctrl.nextOffset += resp.nextOffset;

                filterDelta += resp.Users.length - filteredUsers.length;

                markSelected(filteredUsers);

                if (isPaging) {
                    ctrl.usersLoaded = _.concat(ctrl.usersLoaded, filteredUsers);
                } else {
                    ctrl.usersLoaded = filteredUsers;
                }
                ctrl.moreUsers = resp.MoreUsers;
                ctrl.isPagingLoading = false;
            })
        }

        function isAdmin(token) {
            if (!ctrl.admins) return false; 

            return !!ctrl.admins.find(userId => {
                return userId === token
            });
        }

        function isBlocked(userId) {
            return ctrl.blockedUsersIds?.includes(userId);
        }

        function destroy() {
            watchUser && watchUser();
        }
    }
})();