(function () {
    'use strict';

    angular
        .module('App')
        .component('simpleUserList', {
            template: require('./SimpleUserListComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$timeout', SimpleUserListController],
            bindings: {
                users: '<',
                type: '@',
                selectUsers: '<',
                disabledCheckboxes: '<',
                selectedUsers: '=?',
                onSelect: '=?',
            }
        });

    function SimpleUserListController($scope, $timeout) {
        const ctrl = this;
        let removeUserWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.selectUser = selectUser;

        function init() {
            ctrl.datasource = {};
            ctrl.selectedUsers = ctrl.selectedUsers || [];

            removeUserWatcher = $scope.$watch('ctrl.users', function () {
                ctrl.datasource.get = get;
                ctrl.adapter && ctrl.adapter.reload();
            });
        }

        function get(index, count, success) {
            $timeout(function () {
                let result = [];
                const start = Math.max(0, index),
                    end = Math.min(index + count - 1, ctrl.users.length);

                if (start <= end) {
                    result = ctrl.users.slice(start, end + 1);
                }
                success(result);
            }, 100);
        }

        function selectUser(user) {
            const userIndex = ctrl.selectedUsers.indexOf(user.UserToken);
            if (userIndex === -1) {
                ctrl.selectedUsers.push(user.UserToken);
            } else {
                ctrl.selectedUsers.splice(userIndex, 1);
            }

            if (user.AttendanceConfirmed !== undefined && user.AttendanceConfirmed !== null) {
                user.AttendanceConfirmed = !user.AttendanceConfirmed;
                ctrl.onSelect && ctrl.onSelect(user.UserToken, user.AttendanceConfirmed);
            }
        }

        function destroy() {
            removeUserWatcher && removeUserWatcher();
        }
    }
})();