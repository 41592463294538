(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('prevNextButtons', {
            template: require('./PrevNextButtonsComponent.html'),
            controllerAs: 'ctrl',
            controller: [PrevNextButtonsController],
            bindings: {
                showPrev: '<',
                showNext: '<',
                showSubmit: '<',
                prevDisabled: '<',
                nextDisabled: '<',
                submitDisabled: '<',
                onPrevClick: '&',
                onNextClick: '&',
                onSubmitClick: '&'
            }
        })

    function PrevNextButtonsController() {}
})();