(function () {
    'use strict';

    angular.module('App')
        .factory('AssigneesPopupService', ['$http', '$stateParams', 'PopupWrapperService', AssigneesPopupService]);

    function AssigneesPopupService($http, $stateParams, PopupWrapperService) {

        return {
            getPossibleAssignees: getPossibleAssignees,
            openAssigneesPopup: openAssigneesPopup,
            saveAssignees: saveAssignees
        };

        function getPossibleAssignees(considerPermissions, token, permissions, offset, limit, name) {
            let model = {
                offset: offset, limit: limit, name: name, token: token
            };

            if (permissions?.SelectedDepartments && permissions?.SelectedUserGroups) {
                permissions.UserGroupIds = permissions.SelectedUserGroups.map(
                    userGroup => userGroup.RoleId
                );
                permissions.DepartmentIds = permissions.SelectedDepartments.map(
                    department => department.DepartmentId
                );
                delete permissions.SelectedDepartments;
                delete permissions.SelectedUserGroups;
            }

            if (considerPermissions) {
                model.AccountModuleToken = $stateParams.token;
                if (!permissions?.length) {
                    if (considerPermissions.hasOwnProperty('MatchAllUserGroups')) {
                        permissions.MatchAllGroups = permissions.MatchAllUserGroups;
                        delete permissions.MatchAllUserGroups;
                    }
                    model.VirtualPermissions = [permissions];
                } else {
                    permissions?.forEach(permission => {
                        if (permission.hasOwnProperty('MatchAllUserGroups')) {
                            permission.MatchAllGroups = permission.MatchAllUserGroups;
                            delete permission.MatchAllUserGroups;
                        }
                    });
                    model.VirtualPermissions = permissions;
                }

                return $http.post('/TaskManagement/GetPossibleAssigneesByVirtualPermissions/', model)
                    .then(resp => getUsers(resp, limit))
            } else {
                return $http.post('/TaskManagement/GetPossibleAssignees/', model).then(resp => getUsers(resp, limit))
            }
        }

        function getUsers(response, limit) {
            const moreUsers = response.data.length !== 0 && response.data.length === limit;

            return {
                Users: response.data,
                MoreUsers: moreUsers
            }
        }

        function openAssigneesPopup({ considerPermissions, token, selectedUsers, onSave, permissions, canModerate, privateTask }) {
            PopupWrapperService
                .createDynamic(
                    `<assignees-popup
                                    consider-permissions="considerPermissions"
                                    permissions="permissions"
                                    issue-token="issueToken"
                                    selected-users="selectedUsers"
                                    on-save="onSave"
                                    can-moderate="canModerate"
                                    private-task="privateTask"
                               ></assignees-popup>`,
                    {
                        considerPermissions: considerPermissions,
                        issueToken: token,
                        selectedUsers: selectedUsers,
                        onSave: onSave,
                        permissions: permissions,
                        canModerate: canModerate,
                        privateTask: privateTask
                    });
        }

        function saveAssignees(token, users) {
            let userTokens = _.map(users, function (user) {
                return user.UserToken;
            });

            return $http.post('/TaskManagement/SaveAssignees/' + token, {
                UserTokens: userTokens
            })
        }
    }
})();