(() => {
    'use strict';

    angular
        .module('App')
        .component('translationExclusions', {
            template: require('./TranslationExclusionsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', 'ConfirmPopupService', TranslationExclusionsController],
            bindings: {
                excludedWords: '<'
            }
        })

    function TranslationExclusionsController($filter, ConfirmPopupService) {
        const ctrl = this;

        ctrl.showExclusionInfo = showExclusionInfo;

        function showExclusionInfo() {
            const exclusionsString = ctrl.excludedWords.map(item => `"${item}"`).join(', '),
                message = $filter('translate')('TRANSLATION.EXCLUDE_MESSAGE') + ' ' + exclusionsString + '.';
            
            ConfirmPopupService.open({
                message: message,
                isAlert: true,
                yesText: 'DISMISS'
            })
        }
    }
})();