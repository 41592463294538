(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('ServiceFormRenderService', ['$compile', '$rootScope', '$timeout', 'PopupWrapperService', 'ConfirmPopupService',
        'Page', 'DATALIST_RULE',
        
        function ($compile, $rootScope, $timeout, PopupWrapperService, ConfirmPopupService, Page, DATALIST_RULE) {
            let fillingItemData = {},
                labelSettings = {},
                storedDataLists,
                updateFillingController,
                gpsSettings,
                sessionCreatedFillings = [],
                fillings = [],
                feedbackRanges = [],
                ratingRange, ratingColors, feedbackOptions = {};

            return {
                createFillingPopupId: 'createFillingPopupId',
                updateFillingPopupId: 'updateFillingPopupId',
                filterFillingPopupId: 'filterFillingPopupId',
                destroyData: destroyData,
                saveFillings: saveFillings,
                updateFilling: updateFilling,
                indexGroups: indexGroups,
                setGpsSettings: setGpsSettings,
                getGpsSettings: getGpsSettings,
                setLabelSettings: setLabelSettings,
                getLabelSettings: getLabelSettings,
                updateLabelSettings: updateLabelSettings,
                getFieldDataList: getFieldDataList,
                setDatalists: setDatalists,
                setFillingItemData: setFillingItemData,
                getFillingItemData: getFillingItemData,
                formatFillingItemMedia: formatFillingItemMedia,
                formatFillingGropsMedia: formatFillingGropsMedia,
                resetFillingModel: resetFillingModel,
                setUpdateFillingController: setUpdateFillingController,
                openUpdateFullingPopup: openUpdateFullingPopup,
                renderLinkedFormPopup: renderLinkedFormPopup,
                formatMediaField: formatMediaField,
                showMap: showMap,
                sessionCreatedFillings: sessionCreatedFillings,
                fillingsOverviewPopupId: 'fillingsOverviewPopupId',
                fillingInteractionSettings: {},
                setFeedbackRatingRange: setFeedbackRatingRange,
                setFeedbackRanges: setFeedbackRanges,
                setFeedbackOptions: setFeedbackOptions,
                getFeedbackOptions: getFeedbackOptions,
                getFeedbackRatingRange: getFeedbackRatingRange,
                getFeedbackRange: getFeedbackRange,
                getFeedbackRatingColorClass: getFeedbackRatingColorClass,
                getRatingColorClass: getRatingColorClass,
                getAverageFeedbackRating: getAverageFeedbackRating,
                checkLimitRuleAndShowPopup: checkLimitRuleAndShowPopup,
                getRatingMedia: getRatingMedia,
                getRatingMediaById: getRatingMediaById,
            };

            function goThroughFields(steps, callback) {
                steps.forEach(function (step) {
                    step.Groups.forEach(function (group) {
                        group.Fields.forEach(function (field) {
                            callback(field);
                        })
                    })
                });
            }

            function indexGroups(form) {
                var index = 0;
                _.each(form.Steps, function (step) {
                    _.each(step.Groups, function (group) {
                        group.groupIndex = index;
                        index++;
                    })
                })
            }

            function formatMedaiFields(fields) {
                fields.forEach(function (field) {
                    if (field.FieldType === 5) {
                        formatMediaField(field)
                    }
                });

                return fields
            }

            function formatMediaField(field) {
                field.MediaValues = [];

                field.Values.forEach(function (value) {
                    if (value.Media) {
                        field.MediaValues.push(value.Media);
                    }
                });

                return field
            }

            function showMap(lat, lon) {
                PopupWrapperService
                    .createDynamic('<map-popup lat="lat" lon="lon"></map-popup>', {
                        lat: lat,
                        lon: lon
                    })
            }

            function formatFillingGropsMedia(filling) {
                _.each(filling.Groups, function (group) {
                    group.Fields = formatMedaiFields(group.Fields);
                });

                return filling;
            }

            function saveFillings(fillingsSrc) {
                fillings = fillingsSrc;
            }

            function updateFilling(token, fillingSource) {
                var fillingIndex = _.findIndex(fillings, {'ServiceFormFillingToken': token});

                fillings[fillingIndex] = {...fillingSource};
            }

            function destroyData() {
                storedDataLists = undefined;
                labelSettings = {};
                gpsSettings = undefined;
                updateFillingController = undefined;
                sessionCreatedFillings = undefined;
                ratingRange = undefined;
                ratingColors = undefined;
                feedbackOptions = {};
            }

            function setGpsSettings(settings) {
                gpsSettings = settings;
            }

            function getGpsSettings() {
                return gpsSettings
            }

            function setLabelSettings(settings) {
                labelSettings = settings;
            }

            function getLabelSettings() {
                return labelSettings;
            }

            function updateLabelSettings(labels) {
                Object.keys(labels).forEach(labelKey => {
                    labelSettings[labelKey] = labels[labelKey] || labelSettings[labelKey];
                })
            }

            function getFieldDataList(dataListId) {
                if (storedDataLists) {
                    return storedDataLists.find(function (el) {
                        return el.DataListId === dataListId;
                    });
                }
            }

            function setDatalists(dataLists) {
                storedDataLists = dataLists;
            }

            function setFillingItemData(data) {
                fillingItemData = data;
            }

            function getFillingItemData() {
                return fillingItemData;
            }

            function formatFillingItemMedia(filling) {
                var fillingItem = angular.copy(filling);

                fillingItem.Fields = formatMedaiFields(fillingItem.Fields);

                return fillingItem;
            }

            function resetFillingModel(steps) {
                var clearedModel = angular.copy(steps)
                goThroughFields(clearedModel, function (field) {
                    field.model = null;
                });

                return clearedModel;
            }

            function setUpdateFillingController(ctrl) {
                updateFillingController = ctrl;
            }

            function openUpdateFullingPopup(filling) {
                updateFillingController.openUpdateFilling(filling);
            }

            function renderLinkedFormPopup(data, token, parentToken, cachedData) {
                var popupTemplate = '<create-filling is-dynamic="true"' +
                        '                    lists-data="ctrl.listsData"\n' +
                        '                    form="ctrl.form"\n' +
                        '                    service-form-id="ctrl.serviceFormId"\n' +
                        '                    popup-id="ctrl.popupId"\n' +
                        '                    on-popup-registered="ctrl.onPopupRegistered"\n' +
                        '                    parent-token="ctrl.parentToken"\n' +
                        '                    is-loop-enabled="ctrl.isLoopEnabled"\n' +
                        '                    header-title="ctrl.headerTitle"\n' +
                        '                    label-settings="ctrl.labelSettings"\n' +
                        '                    cached-data="ctrl.cachedData"\n' +
                        '                    intro="ctrl.intro"' +
                        '                    outro="ctrl.outro">\n' +
                        '                </create-filling>',
                    scope = $rootScope.$new(true), popupElement;

                data.Form.Steps = this.resetFillingModel(data.Form.Steps);

                scope.ctrl = {
                    form: data.Form.Steps,
                    listsData: data.DataLists,
                    serviceFormId: data.Form.ServiceFormId,
                    intro: data.Form.Intro,
                    outro: data.Form.Outro,
                    headerTitle: data.PageTitle,
                    popupId: parentToken + '' + Math.random().toString(36).substr(2) + '-popup-id',
                    parentToken: parentToken,
                    labelSettings: data.LabelSettings,
                    isLoopEnabled: data.IsLoopEnabled,
                    cachedData: cachedData
                };
                popupElement = $compile(popupTemplate)(scope);
                angular.element(document).find('body').append(popupElement);
                $timeout(function () {
                    PopupWrapperService.getPopup(scope.ctrl.popupId).open();
                });
            }

            function setFeedbackRatingRange(range) {
                ratingRange = range;
            }

            function setFeedbackRanges(ranges) {
                feedbackRanges = ranges.map(range => ({
                    ...range,
                    Styles: {
                        backgroundColor: range.Color,
                        color: range.TextColor,
                        borderColor: range.Color
                    }
                }));
            }

            function setFeedbackOptions(options) {
                feedbackOptions = options;
            }

            function getFeedbackOptions() {
                return feedbackOptions
            }

            function getAverageFeedbackRating(fields) {
                const filteredFields = fields.filter(field => field.Values?.length && field.FeedbackData);

                if (!filteredFields.length) {
                    return undefined;
                }

                const sum = filteredFields.reduce((accumulator, currentValue) => accumulator + currentValue.FeedbackData.Feedback?.Rating, 0);
                return sum / filteredFields.length;
            }

            function getRatingMedia(rangeValue) {
                if (feedbackRanges) {
                    const range = feedbackRanges
                        .filter(range => range.MinRange <= rangeValue && rangeValue <= range.MaxRange)
                        .sort((a, b) => b.MinRange - a.MinRange) // Sort by MinRange in descending order
                        .find(() => true);

                    if (range) {
                        return range.Icon;
                    }
                }
            }

            function getRatingMediaById(rangeId) {
                if (feedbackRanges) {
                    const range = feedbackRanges.find(value => value.ServiceFormFeedbackRangeId === rangeId);

                    if (range) {
                        return range.Icon;
                    }
                }
            }

            function getFeedbackRange() {
                return feedbackRanges
            }
            
           function getFeedbackRatingRange() {
                return ratingRange
            }
    
            function getFeedbackRatingColorClass(rangeId) {
                if (feedbackRanges) {
                    const range = feedbackRanges.find(value => value.ServiceFormFeedbackRangeId === rangeId);

                    if (range) {
                        return range.Styles;
                    }
                }
            }

            function getRatingColorClass(rangeValue) {
                if (feedbackRanges) {
                    const range = feedbackRanges.filter(range => range.MinRange <= rangeValue && rangeValue <= range.MaxRange)
                        .sort((a, b) => b.MinRange - a.MinRange) // Sort by MinRange in descending order
                        .find(() => true);

                    if (range) {
                        return range.Styles;
                    }
                }
            }

            function checkLimitRuleAndShowPopup(item, applyFilter) {
                if (item.actionType === DATALIST_RULE.BLOCK ||
                    (item.actionType === DATALIST_RULE.BLOCK_AND_POPUP && Page.getCurrentPage().name !== 'serviceForm')) {
                    ConfirmPopupService.open({
                        message: 'SERVICEFORM.DATALIST.REPORT_IN_USE',
                        yesText: 'CANCEL',
                        isAlert: true
                    })
                    return;
                }

                if (item.actionType === DATALIST_RULE.BLOCK_AND_POPUP) {
                    ConfirmPopupService.open({
                        message: 'SERVICEFORM.DATALIST.REPORT_IN_USE',
                        yesText: 'SERVICEFORM.DATALIST.GO_TO_REPORT',
                        noText: 'CANCEL',
                    }).then(() => {
                        applyFilter();
                    });
                }
            }
        }]);
})();
