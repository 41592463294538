(() => {
    'use strict';

    angular
        .module('App')
        .component('feedbackForm', {
            template: require('./FeedbackFormComponent.html'),
            controllerAs: 'ctrl',
            controller: ['ServiceFormRenderService', FeedbackFormController],
            bindings: {
                feedback: '<',
            }
        })

    function FeedbackFormController(ServiceFormRenderService) {
        const ctrl = this;

        ctrl.ratingColorClass = ServiceFormRenderService.getFeedbackRatingColorClass;
        ctrl.feedbackIcon = ServiceFormRenderService.getRatingMediaById;
    }
})();