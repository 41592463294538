(function () {
    'use strict';

    angular
        .module('App')
        .component('numberField', {
            template: require('./NumberFieldComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', NumberFieldController],
            bindings: {
                index: '<',
                field: '<',
                disabled: '<'
            }
        });

    function NumberFieldController($element) {
        var ctrl = this, step = 0;

        ctrl.$onInit = init;
        ctrl.change = change;

        function init() {
            step = Math.pow(10, -ctrl.field.NumberPrecision);

            const isDecimal = ctrl.field.NumberPrecision > 0;
            const tryNumber = isDecimal ? parseFloat(ctrl.field.model) : parseInt(ctrl.field.model);
            ctrl.field.model = isNaN(tryNumber) ? null : tryNumber;

            if (isDecimal) {
                ctrl.pattern = new RegExp("-?[0-9]+(\.[0-9]{1," + ctrl.field.NumberPrecision + "})?$");
                ctrl.placeholder = '0,' + '0'.padStart(ctrl.field.NumberPrecision, '0');
            } else {
                ctrl.pattern = new RegExp("-?[0-9]*$");
                ctrl.placeholder = 0;
            }
        }

        function change(isAdd) {
            if (isAdd) {
                const addValue = parseFloat((ctrl.field.model ? ctrl.field.model + step : step)
                    .toFixed(ctrl.field.NumberPrecision));
                if (addValue <= ctrl.field.MaxNumberOfSelected) {
                    ctrl.field.model = addValue;
                } else if (!ctrl.field.MaxNumberOfSelected) {
                    ctrl.field.model = addValue;
                }
            } else {
                const decreaseValue = parseFloat((ctrl.field.model ? ctrl.field.model - step : -step)
                    .toFixed(ctrl.field.NumberPrecision));
                if (decreaseValue >= ctrl.field.MinNumberOfSelected) {
                    ctrl.field.model = decreaseValue;
                } else if (!ctrl.field.MinNumberOfSelected) {
                    ctrl.field.model = decreaseValue;
                }
            }
            $element.find('input').controller('ngModel').$setDirty();
        }
    }
})(); 