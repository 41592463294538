(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('userManagement', {
                parent: 'base',
                url: '/userManagement/:token/:extra',
                template: require('./UserManagement.html'),
                resolve: {
                    userList: ['$http', 'base', '$stateParams', function ($http, base, $stateParams) {
                        if (base) {
                            const url = '/UserManagement/GetUsers/' + $stateParams.token +
                                ($stateParams.extra === 'recent' ? '?showOnlyRecent=true' : '')

                            return $http.get(url).then(resp => ({
                                ...resp.data,
                                Recent: $stateParams.extra === 'recent'
                            }));
                        }
                    }]
                },
                controller: 'UserManagementController as ctrl'
            });
        }])
        .controller('UserManagementController', ['$rootScope', '$scope', '$stateParams', 'Page', 'userList', 'events',
            'ModuleSearchService', 'TemplateFactory', 'HeaderButtonsFactory', 'UserManagementService', 'HowToGuideService',
            'ResponsiveService',
            function ($rootScope, $scope, $stateParams, Page, userList, events, ModuleSearchService, TemplateFactory,
                      HeaderButtonsFactory, UserManagementService, HowToGuideService, ResponsiveService) {
                const ctrl = this;
                let searchUsers = [];

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                Page.setTitle(userList.PageTitle);

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
                
                ctrl.isDesktop = ResponsiveService.isDesktop();


                $scope.$on("$destroy", destroy);

                ctrl.status = ['today', 'week', 'month', 'had-activity', 'no-activity', 'pending'];
                ctrl.filtersCount = userList.Recent ? 1 : 0;
                ctrl.activeView = 1;
                ctrl.users = userList.Users.slice(0, 50);
                ctrl.pendingUsers = [...userList.PendingUsers];
                ctrl.settings = userList.Settings;
                ctrl.isNative = TemplateFactory.isNative;
                ctrl.UserSignUpEnabled = userList.UserSignUpEnabled;
                ctrl.enableHowToGuide = userList.HowToGuideEnabled;
                ctrl.userCount = userList.Users.length;
                ctrl.showFilters = false;
                updateActivityWidget(userList);
                $rootScope.HaveInfoButton = userList.HowToGuideEnabled;

                if (userList.Recent) {
                    ctrl.filterModel = UserManagementService.getCleanFilterModel();
                    ctrl.filterModel.filters.Recent = userList.Recent;
                }

                ctrl.paging = paging;
                ctrl.search = search;
                ctrl.editUser = editUser;
                ctrl.createUser = createUser;
                ctrl.openFilters = openFilters;
                ctrl.openInfoPopup = openInfoPopup;
                ctrl.getManagementFilters = getManagementFilters;
                ctrl.closeFilters = closeFilters;

                ctrl.searchSettings = {
                    onModelChange: ctrl.search,
                    model: ''
                };

                ModuleSearchService.enableSearch(ctrl.searchSettings);
                if (!ctrl.isDesktop) {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }
                
                function paging() {
                    if (ctrl.activeView !== 1) {
                        return;
                    }
                    
                    if (!ctrl.searchSettings.model?.trim() && ctrl.users.length !== userList.Users.length) {
                        ctrl.users = userList.Users.slice(0, ctrl.users.length + 20); // Load more users
                        return;
                    } 
                    
                    if (ctrl.searchSettings.model?.trim() && ctrl.users.length !== searchUsers.length) {
                        ctrl.users = searchUsers.slice(0, ctrl.users.length + 20);
                    }
                }
                
                function search(name) {
                    if (!name || name.trim() === "") {
                        ctrl.users = userList.Users.slice(0, 50);
                        ctrl.pendingUsers = [...userList.PendingUsers];
                        return;
                    }

                    searchUsers = searchFilter(userList.Users, name);
                    ctrl.users = searchUsers.slice(0, 50);
                    ctrl.pendingUsers = searchFilter(userList.PendingUsers, name);
                    angular.element('.user-management').scrollTop(0);
                }

                function searchFilter(users, name) {
                    return (users || [])
                        .filter(user => user.Name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                            user.Title.toLowerCase().indexOf(name.toLowerCase()) !== -1)
                }

                function updateUsersOnClose(newUser) {
                    if (newUser) {
                        ctrl.loading = true;
                        UserManagementService.getUsers($stateParams.token).then((resp) => {
                            ctrl.searchSettings.model = '';
                            ctrl.filterModel =  UserManagementService.getCleanFilterModel();
                            ctrl.filtersCount = 0;
                            userList.PendingUsers = resp.data.PendingUsers;
                            userList.Users = resp.data.Users;
                            ctrl.users = userList.Users.slice(0, 50);
                            ctrl.pendingUsers = [...userList.PendingUsers];
                            ModuleSearchService.searchReset && ModuleSearchService.searchReset();
                        }).finally(() => ctrl.loading = false);
                    }
                }
                
                function applyFilters(users, pendingUsers) {
                    const filteredUsers = searchFilter(users, ctrl.searchSettings.model);
                    const filteredPendingUsers = searchFilter(pendingUsers, ctrl.searchSettings.model);
                    
                    ctrl.users = filteredUsers.slice(0, 50);
                    ctrl.pendingUsers = [...filteredPendingUsers];
                }

                function editUser(user) {
                    if (ctrl.settings.EnableUserEdit) {
                        user.Selected = true;
                        UserManagementService.openUpdateUserPopup($stateParams.token, user.UserToken, updateUsersOnClose)
                    }
                }

                function createUser() {
                    if (ctrl.settings.EnableUserCreation) {
                        UserManagementService.openCreateUserPopup($stateParams.token, userList.Users, updateUsersOnClose);
                    }
                }

                function updateActivityWidget(filterUsersResponse) {
                    const {TotalUsers, TotalActiveUsersLast30Days, TotalActiveUsersLast7Days, TotalUsersMatchingFilters} = filterUsersResponse;
                    ctrl.totalUsersCount = TotalUsers;
                    ctrl.activeLastWeekCount = TotalActiveUsersLast7Days;
                    ctrl.activeLastMonthCount = TotalActiveUsersLast30Days;
                    ctrl.filterMatchingUsersCount = TotalUsersMatchingFilters;
                }

                function getHeaderButtons() {
                    return [{
                        icon: 'info',
                        place: 'right',
                        onClick: openInfoPopup,
                        hidden: !ctrl.enableHowToGuide,
                    }, {
                        icon: 'filters',
                        activeIcon: 'filters-active',
                        place: 'right',
                        onClick: openFilters,
                        active: !!ctrl.filtersCount,
                    }, {
                        icon: 'search',
                        place: 'right'
                    }];
                }

                function destroy() {
                    HeaderButtonsFactory.resetButtonsList();
                    ModuleSearchService.disableSearch();
                }

                function closeFilters() {
                    ctrl.showFilters = false;
                }
                
                function getManagementFilters() {
                    ctrl.showFilters = true;
                    ctrl.managementFilters = {
                        allowedDepartments: userList.AllowedDepartments,
                        filterModel: structuredClone(ctrl.filterModel),
                        onFiltered: (response, filterModel, filtersCount) => {
                            ctrl.filtersCount = filtersCount;
                            ctrl.filterModel = filterModel;
                            userList.Users = response.Users;
                            userList.PendingUsers = response.PendingUsers;
                            
                            applyFilters(response.Users, response.PendingUsers);
                            updateActivityWidget(response);
                            
                            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                        },
                        managerViewEnabled: ctrl.settings.ActivityManagerViewEnabled
                    };
                }

                function openFilters() {
                    getManagementFilters();
                    const { allowedDepartments, filterModel, onFiltered, managerViewEnabled } = ctrl.managementFilters;
                    UserManagementService
                        .openFiltersPopup(allowedDepartments, filterModel, onFiltered, managerViewEnabled);
                }

                function openInfoPopup() {
                    HowToGuideService.renderHowToGuidePopup($stateParams.token);
                }
            }
        ]);
})();   