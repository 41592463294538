(() => {
    'use strict';

    angular.module('App')
        .service('MultiplePermissionSelectorService', [MultiplePermissionSelectorService])


    function MultiplePermissionSelectorService() {
        return {
            getRequiredPermissionId: getRequiredPermissionId,
            getInfoPopupTypes: getInfoPopupTypes
        }

        function getRequiredPermissionId() {
            return {
                none: null,
                departments: 1,
                userGroups: 2,
                departmentsAndUserGroups: 3,
                departmentsOrUserGroups: 4
            }
        }

        function getInfoPopupTypes() {
            return {
                permissionsDefinition: 'permissionsDefinition',
                howPermissionsWork: 'howPermissionsWork',
                departments: 'departments',
                userGroups: 'userGroups',
                requiredPermissions: 'requiredPermissions',
                permissionSets: 'permissionSets',
                example: 'example'
            }
        }
    }
})();