(() => {
    'use strict';

    angular
        .module('App')
        .component('resetPinPopup', {
            template: require('./ResetPinPopupComponent.html'),
            controller: ['$filter', '$timeout', 'PinCodeService', 'Profile', 'Page', ResetPinPopupController],
            controllerAs: 'ctrl'
        });

    function ResetPinPopupController($filter, $timeout, PinCodeService, Profile, Page) {
        var ctrl = this, isOldPinValid, firstNewPin, popup, oldPin, hasPinCode;

        ctrl.popupId = 'resetPinPopupId';
        ctrl.popupTitle = $filter('translate')('PIN.CHANGE_PIN');
        ctrl.showKeyboard = true;
        ctrl.showButtons = false;
        ctrl.isVerifyingPin = false;
        ctrl.pinTitle = 'PIN.ENTER_OLD';
        ctrl.profile = Profile.getProfile();

        ctrl.submitCode = submitCode;
        ctrl.onOpen = onOpen;
        ctrl.tryAgain = tryAgain;
        ctrl.close = close;
        ctrl.onBiometricSetupClose = onBiometricSetupClose;

        function initBiometric(){
            ctrl.allowBiometric = false;
            ctrl.biometricSetupState = null;

            PinCodeService.getBiometricPinStatus(ctrl.profile.UserToken).then(() => {
                ctrl.allowBiometric = true
            }, () => {
                ctrl.allowBiometric = false;
            });
        }

        function submitCode(pin) {
            if(ctrl.isVerifyingPin){
                return;
            }
            ctrl.biometricSetupState = null;

            if (!isOldPinValid) {
                oldPin = pin.join('');
                ctrl.isVerifyingPin = true;
                ctrl.pinTitle = 'PIN.SUBMITTING';

                PinCodeService.verifyPinCode(oldPin).then((res) => {
                    ctrl.isVerifyingPin = false;
                    if (res.response.data.success) {
                        isOldPinValid = true;
                        ctrl.pinTitle = 'PIN.ENTER_NEW';
                        ctrl.pinCodeModel = [null, null, null, null];
                    } else {
                        const remainingAttempts = res.response.data.remainingAttempts || 0;

                        ctrl.pinTitle = $filter('translate')('PIN.WRONG_CODE', { NUMBER: remainingAttempts });
                        ctrl.isPinInvalid = true;
                        ctrl.showKeyboard = false;
                        ctrl.showButtons = true;
                    }
                }, () => {
                    ctrl.isVerifyingPin = false;
                });
            } else if (!firstNewPin) {
                firstNewPin = pin.join('');
                ctrl.pinTitle = 'PIN.REPEAT_NEW';
                ctrl.pinCodeModel = [null, null, null, null];
            } else {
                if (firstNewPin === pin.join('')) {
                    ctrl.pinTitle = 'PIN.UPDATED';
                    ctrl.isPinValid = true;
                    ctrl.isPinInvalid = false;
                    ctrl.showKeyboard = false;
                    
                    ctrl.isVerifyingPin = true;
                    ctrl.pinTitle = 'PIN.SAVING';

                    PinCodeService.setPin(firstNewPin, oldPin).then(function () {
                        const action = () => {
                            $timeout(function () {
                                Page.stateReload();
                                popup && popup.close();
                                reset();
                            }, 1500);
                        }

                        if(ctrl.allowBiometric){
                            PinCodeService.clearBiometricPin(ctrl.profile.UserToken, false);

                            ctrl.biometricSetupState = {
                                show: true,
                                userToken: ctrl.profile.UserToken,
                                pinCode: firstNewPin,
                                nextAction: action
                            };
                        }else{
                            action();
                        }
                    }, () => {
                        ctrl.isVerifyingPin = false;
                    });
                } else {
                    firstNewPin = null;
                    ctrl.pinTitle = 'PIN.PIN_NOT_MATCH';
                    ctrl.isPinValid = false;
                    ctrl.isPinInvalid = true;
                    ctrl.showKeyboard = false;
                    
                    $timeout(function () {
                        ctrl.pinCodeModel = [null, null, null, null];
                        ctrl.isPinValid = false;
                        ctrl.isPinInvalid = false;
                        ctrl.showKeyboard = true;
                        ctrl.pinTitle = 'PIN.CREATE_YOUR';
                    }, 2000);
                }
            }
        }

        function tryAgain() {
            ctrl.pinCodeModel = [null, null, null, null];
            ctrl.showButtons = false;
            ctrl.isPinInvalid = false;
            ctrl.showKeyboard = true;
            ctrl.pinTitle = 'PIN.ENTER_OLD';
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
            hasPinCode = data.hasPinCode;
            if (!hasPinCode) {
                isOldPinValid = true;
                ctrl.pinTitle = 'PIN.CREATE_YOUR';
            }

            initBiometric();
        }

        function reset() {
            ctrl.pinTitle = 'PIN.ENTER_OLD';
            ctrl.pinCodeModel = [null, null, null, null];
            ctrl.showKeyboard = true;
            ctrl.showButtons = false;
            ctrl.isPinInvalid = false;
            ctrl.isPinValid = false;
            ctrl.biometricSetupState = null;
            isOldPinValid = undefined;
            firstNewPin = undefined;
        }

        function onBiometricSetupClose(){
            ctrl.biometricSetupState.show = false;
            ctrl.biometricSetupState.nextAction();
        }

        function close(data, popup) {
            reset();
            popup.hide();
        }
    }
})();