(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('modal', [
        function () {
            return {
                restrict: 'E',
                transclude: {
                    'footer': '?modalFooter'
                },
                template: require('/Scripts/Directives/Templates/Modal.tpl.html'),
                scope: {
                    title: '@'
                },
                controller: ['$scope', '$element', '$attrs', '$timeout', '$transclude',
                    function ($scope, $element, $attrs, $timeout, $transclude) {
                        $scope.show = false;
                        $scope.footerFound = $transclude.isSlotFilled('footer');

                        $scope.padding = !($attrs.padding == 'false');

                        $scope.infiniteEnableLoading = false;
                        $scope.infiniteLoading = false;

                        // Enable Infinite Loading Communication
                        if ($scope.$parent.infiniteEnableLoading !== undefined && $scope.$parent.infiniteLoading !== undefined) {
                            $scope.$parent.$watch('infiniteEnableLoading', function (newVal) {
                                $scope.infiniteEnableLoading = newVal;
                            })
                            $scope.$parent.$watch('infiniteLoading', function (newVal) {
                                $scope.infiniteLoading = newVal;
                                if (!newVal && $scope.infiniteEnableLoading) {

                                    // Activate a new load if the page is not full height
                                    $timeout(function () {
                                        var height = $('.modal-body', $element).outerHeight();
                                        if (height < $scope.modalMaxBodyHeight) {
                                            $scope.ModalScrolled();
                                        }
                                    });
                                }
                            });
                        } else {
                            $scope.infiniteEnableLoading = false;
                            $scope.infiniteLoading = false;
                        }

                        // Listen for close event
                        $scope.$parent.$on('closeModal', function () {
                            $scope.CloseModal();
                        });

                        $scope.CloseModal = function () {
                            $timeout(function () {
                                if($scope.show){ // check to prevent multiple requests while in progress
                                    $scope.show = false;
                                    $scope.$parent.close($scope.result, 100);
                                }
                            }, 0);
                        };

                        $scope.ModalScrolled = function () {
                            if ($scope.$parent.infiniteScroll && $scope.infiniteEnableLoading) {
                                $scope.$parent.infiniteScroll();
                            }
                        };

                        $timeout(function () {
                            $scope.show = true;
                        });
                    }
                ]
            }
        }
    ]);
})();