(function () {
    'use strict';

    angular
        .module('App')
        .component('events', {
            templateUrl: '/Scripts/Components/ScrollCalendar/Events/EventsComponent.tpl.html',
            controllerAs: 'ctrl',
            bindings: {
                events: '<',
                isBig: '<'
            }
        });
})();