(() => {
    'use strict';

    angular
        .module('App')
        .component('fillingListDisplay', {
            template: require('./FillingListDisplayComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: [fillingListDisplayController],
            bindings: {
                displays: '<',
                isMultipleColumn: '='
            }
        });

    function fillingListDisplayController() {
        const ctrl = this;

        ctrl.$onInit = function () {
            ctrl.isMultipleColumn = true;
        };

        ctrl.changeView = function () {
            ctrl.isMultipleColumn = !ctrl.isMultipleColumn;
        };
    }
})();