(function () {
    'use strict';

    angular.module('App')
        .factory('RemindersService', ['$http', '$filter', 'PopupWrapperService', 'toastr', 'MODULE_ID',
            function ($http, $filter, PopupWrapperService, toastr, MODULE_ID) {
                return {
                    openSetReminders: openSetReminders,
                    setReminder: setReminder,
                    deleteReminder: deleteReminder,
                    getReminders: getReminders,
                    getSetReminderString: getSetReminderString,

                };

                function openSetReminders(moduleToken, itemToken, moduleId, remindTime, updateReminder) {
                    PopupWrapperService
                        .createDynamic(
                            '<set-reminder-popup ' +
                            'account-module-token="moduleToken" entity-id="itemToken" module-id="moduleId" ' +
                            'remind-time="remindTime" update-reminder="updateReminder"></set-reminder-popup>'
                            , {
                                moduleToken: moduleToken,
                                itemToken: itemToken,
                                moduleId: moduleId,
                                remindTime: remindTime,
                                updateReminder: updateReminder
                            })
                }

                function setReminder(AccountModuleToken, EntityId, ModuleId, ReminderDateTime) {
                    return $http.post('/reminder/create', {
                        AccountModuleToken: AccountModuleToken,
                        EntityId: EntityId,
                        ModuleId: ModuleId,
                        ReminderDateTime: ReminderDateTime
                    })
                }

                function getIcon(moduleId) {
                    switch (moduleId) {
                        case MODULE_ID.CONTENT:
                            return 'content'
                        case MODULE_ID.CALENDAR:
                            return 'calendar'
                        default:
                            return 'content';
                    }
                }

                function formatReminders(reminders) {
                    var mappedReminders = _.map(reminders, function (reminder) {
                        reminder.icon = getIcon(reminder.ModuleId);
                        reminder.ReminderDateTimeFormat = moment(reminder.ReminderDateTimeLocal).format('HH:mm')

                        return reminder
                    }); 

                    mappedReminders = _.sortBy(mappedReminders, function (reminder) {
                        return moment(reminder.ReminderDateTimeLocal).valueOf()
                    })

                    return _.groupBy(mappedReminders, function (reminder) {
                        var date = moment(reminder.ReminderDateTimeLocal);
                        if (date.isSame(new Date(), 'day')) {
                            return 'REMINDERS.TODAY'
                        }
                        if (date.isSame(moment().add(1, 'days'), 'day')) {
                            return 'REMINDERS.TOMORROW'
                        }
                        return date.startOf('day').format('dddd DD.')
                            + ' ' + date.startOf('day').format('MMM').toLowerCase();
                    })
                }

                function getReminders() {
                    return $http.get('/reminder/get').then(function (resp) {
                        return formatReminders(resp.data)
                    })
                }

                function getSetReminderString(time) {
                    var day;
                    if (time) {
                        day = moment(time).format('dddd DD MMM');
                        if (moment(time).isSame(new Date(), 'day')) {
                            day = $filter('translate')('REMINDERS.TODAY');
                        }
                        if (moment(time).isSame(moment().add(1, 'days'), 'day')) {
                            day = $filter('translate')('REMINDERS.TOMORROW')
                        }
                        return $filter('translate')('REMINDERS.REMIND_TIME', {
                            day: day,
                            time: moment(time).format('HH:mm')
                        });
                    } else {
                        return 'REMINDERS.SET_REMINDER'
                    }
                }

                function deleteReminder(token) {
                    return $http.post('/reminder/Delete', {
                        ReminderToken: token
                    })
                }
            }]);
})();
