(() => {
    'use strict';
    angular
        .module('App')
        .component('moduleNotificationsSettings', {
            template: require('./ModuleNotificationsSettingsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$http', '$timeout', 'ToastFactory', 'Profile', ModuleNotificationSettingsController],
            bindings: {
                module: '<'
            }
        });

    function ModuleNotificationSettingsController($scope, $http, $timeout, ToastFactory, Profile) {
        var ctrl = this, initializing = true, settingsWatcher;

        ctrl.isNative = Profile.getProfile().IsNativeAppEnabled;
        ctrl.updateSettings = updateSettings;

        ctrl.$onInit = function () {
            loadSettings();
        };

        function loadSettings() {
            ctrl.isLoading = true;

            $http.get('/Profile/GetNotificationSettingsForAccountModule/' + ctrl.module.accountModuleId).then(resp => {
                ctrl.moduleSettings = resp.data;
            }).catch(() => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
            }).finally(() => {
                ctrl.isLoading = false;
            });
        }

        function updateSettings() {
            ctrl.moduleSettings.AllowPushNotification = ctrl.moduleSettings.AllowNotification;
            $http.post('/Profile/SaveNotificationSettingsForAccountModule', {
                accountModuleId: ctrl.module.accountModuleId,
                allowNotification: ctrl.moduleSettings.AllowNotification,
                allowPushNotification: ctrl.moduleSettings.AllowPushNotification
            }).catch((err) => {
                ToastFactory.error(err);
            });
        }
    }
})();