(() => {
    'use strict'

    angular
        .module('App')
        .component('departmentSwapPopup', {
            template: require('./DepartmentSwapPopupComponent.tpl.html'),
            controller: ['DepartmentSwapService', '$element', DepartmentSwapPopupComponentController],
            controllerAs: 'ctrl',
            bindings: {
                onPopupRegistered: '<',
                onClose: '<'
            }
        });

    function DepartmentSwapPopupComponentController(DepartmentSwapService, $element) {
        var ctrl = this;
        var popup;

        ctrl.popupId = 'DepartmentSwapPopupId';
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        
        ctrl.saveChanges = saveChanges;
        ctrl.DepartmentSwapService = DepartmentSwapService;

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;

            // Load data if not loaded before
            if (!DepartmentSwapService.isLoaded() && !DepartmentSwapService.isLoading()) {
                DepartmentSwapService.loadDepartments();
            }
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
        }

        function saveChanges() {
            var selection = DepartmentSwapService.getDepartments().find(o => o.selected == true);

            if (selection) {
                DepartmentSwapService.changeDepartment(selection.id).finally(() => {
                    close();
                });
            }
        }
    }
})();