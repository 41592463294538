angular
    .module('Relesys.Ui')
    .component('noContent', {
        template: require('./NoContent.tpl.html'),
        controllerAs: 'ctrl',
        controller: [NoContentComponentController],
        bindings: {
            icon: '@',
            iconClass: '@',
            relIcon: '@',
            title: '@',
            text: '@'
        }
    });

function NoContentComponentController() {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.icon = ctrl.icon || 'book-open-page-variant';
    }
}