(function () {
    'use strict';

    angular
        .module('App')
        .component('selectCategory', {
            template: require('./SelectCategoryComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', 'CalendarRenderService', 'BasicHelper', SelectCategory],
            require: {
                ngModelCtrl: 'ngModel'
            },
            bindings: {
                categories: '<',
                isMultiple: '<',
                openCategorySelector: '=?',
                categoryTypeId: '@',
                showCategorySelector: '=?',
                resetCategories: '=?',
                needed: '<'
            }
        });

    function SelectCategory($rootScope, $scope, CalendarRenderService, BasicHelper) {
        const ctrl = this;

        $scope.$watch('ctrl.categories', categories => {
            if (categories?.length) {
                const cat = categories
                    .filter(category => category.isSelected)
                    .map(category => category[ctrl.categoryTypeId])
                ctrl.ngModelCtrl.$setViewValue(cat);
            }
        }, true);

        ctrl.isRTL = $rootScope.isRTL;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.selectCategory = selectCategory;
        ctrl.back = back;
        ctrl.openCategorySelector = openCategorySelector;
        ctrl.resetCategories = resetCategories;
        ctrl.getCategoryColor = getCategoryColor;

        function init() {
            ctrl.needed && setValidation();
            ctrl.showCategorySelector = false;
            ctrl.categories.forEach(category => {
                category.bgColor = BasicHelper.hexToRgba(category.Color, 0.3);
                category._currentCulture = getCurrentCulture(category.TranslatableEntity);
            });
        }

        function setValidation() {
            ctrl.ngModelCtrl.$validators.isEmpty = (modelValue, viewValue) => {
                const value = modelValue || viewValue;
                return value?.length > 0
            }
        }

        function destroy() {
            ctrl.categories.forEach(category => {
                category.isSelected = false
            })
        }

        function getCurrentCulture(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function selectCategory(selectedCategory) {
            if (ctrl.isMultiple) {
                selectedCategory.isSelected = !selectedCategory.isSelected;
            } else {
                ctrl.categories.forEach(category => {
                    if (category[ctrl.categoryTypeId] === selectedCategory[ctrl.categoryTypeId]) {
                        category.isSelected = !selectedCategory.isSelected;
                    } else {
                        category.isSelected = false;
                    }
                });
                ctrl.showCategorySelector = false;
            }
        }

        function openCategorySelector() {
            ctrl.showCategorySelector = true;
        }

        function back() {
            ctrl.showCategorySelector = false;
        }

        function resetCategories() {
            ctrl.categories.forEach(category => category.isSelected = false)
        }

        function getCategoryColor(category) {
            return {
                'background-color': category.isSelected ? category.Color : category.bgColor
            }
        }
    }
})();