(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('feedback', {
                    parent: 'base',
                    url: '/feedback/:token',
                    templateUrl: '/Feedback/Index',
                    resolve: {
                        feedbackInfo: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Feedback/GetInfo/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'FeedbackController as FeedbackCtrl'
                });
        }
    ]);

    app.controller('FeedbackController', ['Page', '$scope', '$http', '$stateParams', 'Upload', 'toastr', '$translate', 'feedbackInfo', 'events',
        function (Page, $scope, $http, $stateParams, Upload, toastr, $translate, feedbackInfo, events) {
            var ctrl = this;
            ctrl.Page = Page;
            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
            } else {
                Page.showBackButton('home');
            }
            $scope.files = [];

            // Init
            Page.setTitle(feedbackInfo.data.PageTitle);
            $scope.postModel = {
                Subject: '',
                Text: '',
                MediaPreview: [],
                Media: [],
                AccountModuleToken: feedbackInfo.data.AccountModuleToken
            };
            $scope.texts = {
                Intro: '',
                TextPlaceholder: ''
            };

            // Grab Texts from Settings
            if (feedbackInfo.data.Settings.Headline.length > 0) {
                $scope.texts.Intro = feedbackInfo.data.Settings.Headline;
            } else {
                $translate('FEEDBACK.INTRO').then(function (translation) {
                    $scope.texts.Intro = translation;
                });
            }
            if (feedbackInfo.data.Settings.Description.length > 0) {
                $scope.texts.TextPlaceholder = feedbackInfo.data.Settings.Description;
            } else {
                $translate('FEEDBACK.TEXT_PLACEHOLDER').then(function (translation) {
                    $scope.texts.TextPlaceholder = translation;
                });
            }

            $scope.Post = function () {
                if ($scope.postModel.Subject.length > 0 && $scope.postModel.Text.length > 0) {
                    $http.post('/Feedback/PostFeedback', $scope.postModel).then(function (response) {
                        $scope.postModel.Subject = '';
                        $scope.postModel.Text = '';
                        $scope.postModel.MediaPreview = [];
                        $scope.postModel.Media = [];

                        $translate('FEEDBACK.SUCCESS').then(function (translation) {
                            toastr.success(translation);
                        });
                    });
                } else {
                    $translate('FEEDBACK.ERROR').then(function (translation) {
                        toastr.error(translation);
                    });
                }
            };

            $scope.MediaUploaded = function (media) {
                $scope.postModel.Media.push({ MediaId: media.MediaId });
                $scope.postModel.MediaPreview.push(media);
            };

            $scope.RemoveMedia = function (index) {
                $scope.postModel.Media.splice(index, 1);
                $scope.postModel.MediaPreview.splice(index, 1);
            };

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);
})();