(() => {
    'use strict';

    angular.module('App')
        .component('notificationsList', {
            template: require('./NotificationsListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$http', '$timeout', 'PopupWrapperService', 'NotificationFactory', 'TimeLocale',
                'events', NotificationsListController],
            bindings: {
                openSettings: '<',
                enableLazy: '<',
                loadNotifications: '=?',
                placeholders: '<'
            }
        });

    function NotificationsListController($scope, $http, $timeout, PopupWrapperService, NotificationFactory, TimeLocale, events) {
        var ctrl = this, notificationsChangedWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = onDestroy;
        ctrl.onOpenModuleNotificationsSettings = onOpenModuleNotificationsSettings;
        ctrl.filter = filter;
        ctrl.paging = paging;
        ctrl.loadNotifications = loadNotifications;

        function init() {
            loadNotifications();

            if (!ctrl.placeholders) {
                ctrl.placeholders = 8;
            }

            notificationsChangedWatcher = $scope.$on(events.NOTIFICATIONS_CHANGED, function (e, notifications) {
                updateNotifications(notifications);
            });
        }

        function onDestroy() {
            notificationsChangedWatcher();
        }

        function prepareNotifications(notifications) {
            var preparedNotifications = {
                new: [],
                other: []
            };

            angular.forEach(notifications, function (notification) {
                var target = (TimeLocale.timeDiff(notification.DateModified, 'hours') < 24 && !notification.IsRead) ? 'new' : 'other';
                preparedNotifications[target].push(notification);
            });

            return preparedNotifications;
        }

        function updateNotifications(notifications) {
            if (ctrl.preparedNotifications) {
                angular.forEach(notifications, function (n) {
                    var newNoti = _.find(ctrl.preparedNotifications.new, function (o) {
                        return o.NotificationId === n.NotificationId;
                    });
                    if (newNoti) {
                        newNoti = _.extend(newNoti, n);
                    } else {
                        var otherNoti = _.find(ctrl.preparedNotifications.other, function (o) {
                            return o.NotificationId === n.NotificationId;
                        });

                        if (otherNoti) {
                            otherNoti = _.extend(otherNoti, n);
                        }
                    }
                });
            }
        }

        function onOpenModuleNotificationsSettings(notification) {
            const module = {
                moduleName: notification.ModuleName,
                accountModuleId: notification.AppLinkId.AccountModuleId
            }

            PopupWrapperService
                .createDynamic(
                    '<module-notifications-settings-popup module="module"></module-notifications-settings-popup>',
                    {
                        module: module
                    }
                );
        }

        function filter(notification) {
            return !notification.isRemoved;
        }

        function paging() {
            if (!ctrl.isLoading && !ctrl.isPaging) {
                ctrl.isPaging = true;
                NotificationFactory.loadNotifications(10, ctrl.notificationCursor)
                    .then(function (data) {
                        ctrl.moreNotifications = data.MoreNotifications;
                        ctrl.notificationCursor = data.Cursor;

                        _.each(data.Notifications, function (item) {
                            ctrl.notifications.push(item);
                        });

                        ctrl.preparedNotifications = prepareNotifications(ctrl.notifications);
                        $timeout(function () {
                            ctrl.isPaging = false;
                        }, 200);
                    }, function () {
                        ctrl.isPaging = false;
                    });
            }
        }

        function loadNotifications() {
            if (!ctrl.isLoading) {
                ctrl.isLoading = true;

                NotificationFactory.loadNotifications().then(function (data) {
                    ctrl.moreNotifications = data.MoreNotifications;
                    ctrl.notificationCursor = data.Cursor;

                    if (data.Notifications.length) {
                        ctrl.notifications = data.Notifications;
                        ctrl.preparedNotifications = prepareNotifications(ctrl.notifications);
                    }

                    $timeout(function () {
                        ctrl.isLoading = false;
                    }, 200);
                });
            }
        }
    }
})();