(function () {
    'use strict';

    var app = angular.module('App');

    app.component('inlineSelector', {
        template: require('./InlineSelectorComponent.html'),
        controllerAs: 'ctrl',
        bindings: {
            title: '@',
            items: '<',
            compareField: '@'
        },
        require: {
            ngModelCtrl: 'ngModel'
        },
        controller: [InlineSelectorController],
    });

    function InlineSelectorController() {
        var ctrl = this;

        ctrl.selectedItems = [];

        ctrl.$onInit = onInit;
        ctrl.toggleSelection = toggleSelection;

        function onInit() {
            if (ctrl.items) {
                ctrl.selectedItems = ctrl.items.filter(function (item) {
                    return item.selected
                })
            }
        }

        function toggleSelection(item) {
            if (!item.selected && !ctrl.selectedItems.includes(item)) {
                ctrl.selectedItems.push(item);
            } else {
                ctrl.selectedItems = ctrl.selectedItems.filter(function (it) {
                    return it[ctrl.compareField] !== item[ctrl.compareField]
                })
            }

            item.selected = !item.selected;
            
            ctrl.ngModelCtrl.$setViewValue(ctrl.selectedItems);
        }
    }
})();