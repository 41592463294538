(function () {
    'use strict';

    angular.module('App')
        .factory('PointsLogService', ['PopupWrapperService', function (PopupWrapperService) {
            var popupId = 'pointsLogPopup',
                popup;
            
            var service = {
                openPopup: openPopup,
                closePopup: closePopup
            };
            return service;

            function getPopup() {
                return popup ? popup : PopupWrapperService.getPopup(popupId);
            }

            function closePopup() {
                getPopup().close();
            }

            function openPopup() {
                getPopup().open();
            }
        }]);
})();