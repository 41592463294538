(() => {
        'use strict';

        angular.module('App')
            .component('profileSettingsSecurityBiometric', {
                template: require('./ProfileSettingsSecurityBiometricComponent.tpl.html'),
                controller: ['ProfileSettingsService', 'PopupWrapperService', 'PinCodeService', 'Page', ProfileSettingsSecurityBiometricComponentController],
                controllerAs: 'ctrl'
            });

        function ProfileSettingsSecurityBiometricComponentController(ProfileSettingsService, PopupWrapperService, PinCodeService, Page) {
            var ctrl = this;

            ctrl.$onInit = onInit;
            ctrl.changeBiometric = changeBiometric;

            function onInit() {
                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile(true).then(res => {

                    ctrl.profile = res;
                    ctrl.pinEnabled = res.PinCodeEnabled;
                    ctrl.hasPinCode = res.HasPinCode;

                    if (!ctrl.pinEnabled || !ctrl.hasPinCode) {
                        Page.triggerBackButton();
                    }

                    initBiometric(res);
                    
                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            }

            function initBiometric(profile){
                ctrl.biometric = {
                    available: false,
                    enabled: false,
                    setupProcessing: false
                };
                
                PinCodeService.getBiometricPinStatus(profile.UserToken).then((res) => {
                    ctrl.biometric.available = true;
                    ctrl.biometric.enabled = res.isStored;
                });
            }

            function changeBiometric(){
                if(ctrl.biometric.setupProcessing){
                    ctrl.biometric.enabled = !ctrl.biometric.enabled;
                    return;
                }

                if(ctrl.biometric.enabled == true){
                    ctrl.biometric.setupProcessing = true;
                    PopupWrapperService.getPopup('createPinBiometricPopupId').open({
                        profile: ctrl.profile,
                        onClose: (isSuccess) => {
                            ctrl.biometric.enabled = isSuccess;
                            ctrl.biometric.setupProcessing = false;
                        }
                    });
                }else{
                    PinCodeService.clearBiometricPin(ctrl.profile.UserToken, true);
                }
            }
        }
    }
)();