(() => {
    'use strict';

    angular
        .module('App')
        .component('pinProfile', {
            template: require('./PinProfileComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                profile: '<'
            },
            controller: ['Profile', PinProfileController]
        });

    function PinProfileController(Profile) {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            var profile = ctrl.profile;
            if (!ctrl.profile) {
                profile = Profile.getCachedProfile();
                if (!profile) {
                    profile = Profile.getProfile();
                }
            }

            ctrl.profileName = profile && profile.Name || '';
            ctrl.profileImage = '/Content/Graphics/Profile.png';

            if (profile) {
                if (profile.ProfileImageBase64) {
                    ctrl.profileImage = profile.ProfileImageBase64;
                } else {
                    ctrl.profileImage = Profile.getProfileImageUrl(profile);
                }
            }
        }
    }
})()