(function () {
    'use strict';

    angular.module('App')
        .factory('LanguageSelectorService', ['$q', '$http', function ($q, $http) {

            const cultureImage = {
                1: '/Content/Graphics/Flags/english.svg',
                2: '/Content/Graphics/Flags/danish.svg',
                3: '/Content/Graphics/Flags/dutch.svg',
                4: '/Content/Graphics/Flags/swedish.svg',
                5: '/Content/Graphics/Flags/norwegian.svg',
                6: '/Content/Graphics/Flags/spanish.svg',
                7: '/Content/Graphics/Flags/french.svg',
                8: '/Content/Graphics/Flags/german.svg',
                9: '/Content/Graphics/Flags/italian.svg',
                10: '/Content/Graphics/Flags/portugese.svg',
                11: '/Content/Graphics/Flags/finnish.svg',
                12: '/Content/Graphics/Flags/polish.svg',
                13: '/Content/Graphics/Flags/belgian.svg',
                14: '/Content/Graphics/Flags/turkish.svg',
                15: '/Content/Graphics/Flags/chinese.svg',
                16: '/Content/Graphics/Flags/thai.svg',
                17: '/Content/Graphics/Flags/korean.svg',
                18: '/Content/Graphics/Flags/chinese.svg',
                19: '/Content/Graphics/Flags/romanian.svg',
                20: '/Content/Graphics/Flags/russian.svg',
                21: '/Content/Graphics/Flags/bulgarian.svg',
                22: '/Content/Graphics/Flags/icelandic.svg',
                23: '/Content/Graphics/Flags/indonesian.svg',
                24: '/Content/Graphics/Flags/faroese.svg',
                25: '/Content/Graphics/Flags/japanese.svg',
                26: '/Content/Graphics/Flags/vietnamese.svg',
                27: '/Content/Graphics/Flags/saudi-arabia.svg',
                28: '/Content/Graphics/Flags/israel.svg',
                29: '/Content/Graphics/Flags/greece.svg',
                30: '/Content/Graphics/Flags/brazil.svg',
                31: '/Content/Graphics/Flags/french-canadian.svg',
                32: '/Content/Graphics/Flags/hungarian.svg',
                33: '/Content/Graphics/Flags/greenlandic.svg',
                34: '/Content/Graphics/Flags/ukrainian.svg',
                35: '/Content/Graphics/Flags/slovenian.svg',
                36: '/Content/Graphics/Flags/lithuanian.svg',
                37: '/Content/Graphics/Flags/latvian.svg',
                38: '/Content/Graphics/Flags/Slovak.svg',
                39: '/Content/Graphics/Flags/Filipino.svg',
                40: '/Content/Graphics/Flags/Estonian.svg',
                41: '/Content/Graphics/Flags/Bosnian.svg',
                42: '/Content/Graphics/Flags/Croatian.svg',
                43: '/Content/Graphics/Flags/Hindi.svg',
                44: '/Content/Graphics/Flags/Nepali.svg',
                45: '/Content/Graphics/Flags/Urdu.svg',
                46: '/Content/Graphics/Flags/Persian.svg',
                47: '/Content/Graphics/Flags/Czech.svg',
                48: '/Content/Graphics/Flags/Luxembourgish.svg',
                49: '/Content/Graphics/Flags/Serbian.svg'
            };

            return {
                getLanguages: getLanguages,
                updateLanguagesInfo: updateLanguagesInfo
            };

            function getLanguages(accountModuleToken, socialPostToken) {
                return $http.get('/Social/GetCurrentUserCultures/?accountModuleToken=' + accountModuleToken + '&socialPostToken=' + socialPostToken)
                    .then(function (response) {
                        return updateLanguagesInfo(response.data)
                    })
            }

            function updateLanguagesInfo(languages) {
                if (!languages || !languages[0])
                    return
                languages[0].userLanguage = true

                return languages.map(language => {
                    return {
                        ...language,
                        image: cultureImage[language.CultureId],
                        selected: true
                    }
                })
            }

        }]);
})();