
(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('learningbank', {
                    parent: 'base',
                    url: '/learningbank/:token',
                    templateUrl: '/Scripts/Controllers/Modules/LearningBank/Templates/LearningBank.tpl.html',
                    resolve: {
                        data: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/LearningBank/GetModule/?token=' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'LearningBankController as LearningBankCtrl'
                });
        }
    ]);



    app.controller('LearningBankController', ['$window', 'Page', '$scope', 'data', '$sce', '$http', '$stateParams', 'events', '$timeout',
        function ($window, Page, $scope, data, $sce, $http, $stateParams, events, $timeout) {
            var ctrl = this;
            ctrl.Page = Page;

            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token);
            } else {
                Page.showBackButton('home');
            }

            Page.setTitle(data.data.Title);

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateReload();
            });

            $scope.loading = true;

            init();
            function init() {

                $http.get('/LearningBank/Get/?token=' + $stateParams.token)
                    .then(function (response) {
                        $scope.loading = false;
                        $timeout(function () {
                            $scope.url = response.data.Url;
                            $scope.error = response.data.ErrorMessage;
                        });
                    }, function (response) {
                        $scope.loading = false;
                        $timeout(function () {
                            $scope.url = response.data.Url;
                            $scope.error = "LEARNING_BANK.ERROR.GENERAL";
                        });
                    })
            }

            $scope.open = function () {
                window.open($scope.url, '_blank');
            };
        }
    ]);
})();