(function () {
    'use strict';

    angular
        .module('App')
        .component('socialPostReadUsers', {
            template: require('./SocialPostReadUsersComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', 'PostReadRegistrationService', 'MODULE_ID', SocialPostReadUsersController],
            bindings: {
                socialPostToken: '<',
                totalViews: '<'
            }
        });

    function SocialPostReadUsersController($filter, PostReadRegistrationService, MODULE_ID) {
        const ctrl = this;
        let isOpening;


        ctrl.showRegistrations = showRegistrations;

        function getTitle() {
            return ctrl.totalViews + ' ' + $filter('translate')('SOCIAL.VIEWS');
        }

        function showRegistrations() {
            if (!isOpening) {
                isOpening = true;
                PostReadRegistrationService.showPopup(ctrl.socialPostToken, getTitle(), MODULE_ID.SOCIAL)
                    .then(function () {
                        isOpening = false;
                    });
            }
        }
    }
})();