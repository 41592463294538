(function () {
    'use strict';

    angular.module('App')
        .component('scormcloudList', {
            templateUrl: '/Scripts/Components/ScormCloud/CourseList/ScormCloudCourseListComponent.html',
            controllerAs: 'ctrl',
            controller: ['$scope', ScormCloudCourseListController],
            bindings: {
                courses: '<'
            }
        });

    function ScormCloudCourseListController($scope) {
        var ctrl = this;
    }
})();