(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationsDropdown', {
            template: require('./NotificationsDropdownComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', 'NotificationFactory', 'Page', 'ActionSheetService', 'ToastFactory', 'events', 'ResponsiveService', NotificatiopnsDropdownController],
            bindings: {}
        });

    function NotificatiopnsDropdownController($scope, NotificationFactory, Page, ActionSheetService, ToastFactory, events, ResponsiveService) {
        var ctrl = this, pageChangedWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = onDestroy;
        ctrl.NotificationFactory = NotificationFactory;
        ctrl.openAllNotifications = openAllNotifications;
        ctrl.openNotificationOptions = openNotificationOptions;
        ctrl.markAllAsRead = markAllAsRead;
        ctrl.showOptions = showOptions;

        function init() {
            $scope.$watch(() => ctrl.NotificationFactory.getDropdownVisibility(), (isVisible) => {
                if (isVisible) {
                    ctrl.loadNotifications && ctrl.loadNotifications();
                }
            });

            pageChangedWatcher = $scope.$on(events.PAGE_CHANGED, function () {
                NotificationFactory.toggleDropdownVisibility(false);
            });
        }

        function onDestroy() {
            pageChangedWatcher();
        }

        function openAllNotifications() {
            NotificationFactory.toggleDropdownVisibility(false);
            Page.stateGo('notifications');
        }

        function openNotificationOptions() {
            NotificationFactory.toggleDropdownVisibility(false);
            Page.stateGo('notificationsSettings');
        }


        function markAllAsRead() {
            NotificationFactory.markAllAsRead().then(function () {
                // do nothing
            }, function () {
                ToastFactory.errorTranslated('ERROR.GENERAL');
            });
        }

        function showOptions(event) {
            var buttons = [{
                text: 'NOTIFICATION.MARK_ALL_AS_READ',
                icon: 'save',
                iconClass: 'icon-shape',
                onClick: function () {
                    ctrl.markAllAsRead();
                }
            }, {
                text: 'NOTIFICATION.NOTIFICATION_SETTINGS',
                icon: 'settings',
                iconClass: 'icon-shape',
                onClick: function () {
                    ctrl.openNotificationOptions();
                }
            }];

            var actionSheet;
            if (ResponsiveService.isDesktop()) {
                actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, { name: 'notifications-dropdown', token: ctrl.moduleToken});
            } else {
                actionSheet = ActionSheetService.create(buttons, null, null, { name: 'notifications-dropdown', token: ctrl.moduleToken});
            }
            actionSheet.show();
        }
    }
})();