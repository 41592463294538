(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('rankingGroup', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Ranking/Templates/RankingGroup.tpl.html',
                scope: {
                    group: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitDirective();
                    function InitDirective() {
                        
                    }

                    // Methods
                    function TabSelected(index) {
                        for (var i = 0; i < $scope.group.RankingViewChildren.length; i++) {
                            $scope.group.RankingViewChildren[i].inView = (i == index);
                        }
                    }

                    // Bind to scope
                    $scope.tabSelected = TabSelected;
                }]
            }
        }
    ]);
})();