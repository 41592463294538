(function () {
    'use strict';

    angular
        .module('App')
        .component('scoreReport', {
            template: require('./ScoreReportComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                report: '<',
                showOriginal: '<'
            }
        })
})();