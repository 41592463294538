(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('TemplateFactory', ['$rootScope', 'ResponsiveService',
        function ($rootScope, ResponsiveService) {
            var template = {
                key: 'web'
            };

            return {
                setTemplate: setTemplate,
                getTemplate: getTemplate,
                isNative: isNative
            };

            function setTemplate(key) {
                if (!key) {
                    return;
                }

                if (key === 'native' && ResponsiveService.isDesktop()) {
                    template.key = 'web';
                } else {
                    template.key = key;
                }
            }

            function getTemplate() {
                return template;
            }

            function isNative() {
                return template.key === 'native';
            }
        }
    ]);
})();