(() => {
    'use strict';

    angular
        .module('App')
        .component('fieldDatalist', {
            template: require('./FieldDataListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$scope', '$rootScope', '$templateRequest', '$compile', 'DISPLAY_TYPE', 'ServiceFormRestService',
                'PopupWrapperService', 'ResponsiveService', 'ServiceFormDataFormatService',
                DataListFieldController],
            bindings: {
                field: '<',
                index: '<',
                listData: '<',
                accountModuleToken: '<',
                isLimitedByRule: '<',
            }
        });

    function DataListFieldController($stateParams, $scope, $rootScope, $templateRequest, $compile, DISPLAY_TYPE,
                                     ServiceFormRestService, PopupWrapperService, ResponsiveService, ServiceFormDataFormatService) {
        var ctrl = this, popupElement, popupInstance, dataListItemAddedWatcher,
            scope = $scope.$new();

        ctrl.isDesktop = ResponsiveService.isDesktop();
        ctrl.DISPLAY_TYPE = DISPLAY_TYPE;
        ctrl.popupId = 'dataListPopup' + ctrl.field.DataListId;
        ctrl.isLazyDataList = ctrl.field.DisplayType === ctrl.DISPLAY_TYPE.DEFAULT || ctrl.field.DisplayType === ctrl.DISPLAY_TYPE.SELECT || !ctrl.field.DisplayType;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.lazyLoadItems = lazyLoadItems;
        ctrl.openCreateDataListPopup = openCreateDataListPopup;
        ctrl.onClose = onClose;
        ctrl.onRegistered = onRegistered;

        dataListItemAddedWatcher = $rootScope.$on('dataListItemAdded', function (e, newVal) {
            if (newVal && newVal.DataListId === ctrl.field.DataListId && !ctrl.listData.Items.find(item => item.DataListItemId === newVal.DataListItemId)) {
                newVal.IsOnlyFilter = !newVal.IsOnlyFilter ? false : newVal.IsOnlyFilter;
                ctrl.listData.Items.unshift(newVal);
                ctrl.listData.isUpdated = true;
                ctrl.field.model = [newVal];
                init();
            }
        });

        function init() {
            if (ctrl.listData?.Items?.length > 0) {
                if (!ctrl.listData.IsLazyLoad && ctrl.isLimitedByRule && !ctrl.isLazyDataList) {
                    getItemsInUse().then(fieldInUse => {
                        ctrl.listData.Items = ServiceFormDataFormatService.formatInUseFields(ctrl.listData.Items, fieldInUse);
                        ctrl.filteredItems = ctrl.listData.Items.filter(item => !item.IsOnlyFilter);
                    });
                } else {
                    ctrl.filteredItems = ctrl.listData.Items.filter(item => !item.IsOnlyFilter);
                }
            }
        }

        function getItemsInUse() {
            return ServiceFormRestService.getItemsInUseForField($stateParams.token, ctrl.field.ServiceFormFieldId);
        }

        function lazyLoadItems() {
            return ServiceFormRestService.loadDataList(ctrl.field.DataListId || ctrl.field.ReferencingDataListId,
                ctrl.field.ServiceFormFieldId, true, ctrl.accountModuleToken)
                .then(function (data) {
                    return _.filter(data.Items, {IsOnlyFilter: false});
                });
        }

        function openCreateDataListPopup() {
            $templateRequest('/Scripts/Components/ServiceForm/ServiceFormField/FieldDataList/CreateDataListItemPopup.html')
                .then(function (tpl) {
                    popupElement = $compile(tpl)(scope);
                    angular.element(document.body).append(popupElement);
                });
        }

        function onRegistered() {
            popupInstance = PopupWrapperService.getPopup(ctrl.popupId);
            popupInstance.open();
        }

        function onClose() {
            popupInstance.remove();
            popupElement.remove();
            PopupWrapperService.unRegisterEvent(popupInstance);
        }

        function destroy() {
            dataListItemAddedWatcher();
            scope.$destroy();
        }
    }
})();