(function () {
    'use strict';

    angular
        .module('App')
        .component('languageSelector', {
            template: require('./LanguageSelectorComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$scope', '$timeout', 'PopupWrapperService', LanguageSelectorController],
            bindings: {
                selectedLanguages: '=',
                onLanguageSelect: '<',
                currentIndex: '=',
                onLanguagesLoaded: '<',
                languages: '<',
                requireUserLanguage: '<',
                moduleToken: '<'
            }
        })

    function LanguageSelectorController($q, $scope, $timeout, PopupWrapperService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.openSelector = openSelector;
        ctrl.onUpdate = onUpdate;
        ctrl.selectLanguage = selectLanguage;

        function init() {
            $q.when(ctrl.languages, function (languages) {
                if (ctrl.selectedLanguages && ctrl.selectedLanguages.length) {
                    _.each(languages, function (language) {
                        var selectedLanguage = _.find(ctrl.selectedLanguages, {CultureId: language.CultureId});
                        language.selected = false;
                        if (selectedLanguage) {
                            _.assign(language, selectedLanguage);
                            language.selected = true;
                        }
                    })
                }
                ctrl.languages = _.sortBy(languages, function (language) {
                    return language.userLanguage
                });
                ctrl.selectedLanguages = _.filter(ctrl.languages, {selected: true});

                ctrl.selectedLanguages[0].active = true;
                ctrl.currentIndex = 0;
                $timeout(function () {
                    ctrl.onLanguagesLoaded && ctrl.onLanguagesLoaded()
                });
            })
        }

        function openSelector() {
            PopupWrapperService
                .createDynamic(
                    '<language-selector-popup languages="languages"' +
                    ' selected-languages="selectedLanguages" module-token="moduleToken" on-update="onUpdate"' +
                    ' require-user-language="requireUserLanguage"' +
                    '></language-selector-popup>',
                    {
                        languages: ctrl.languages,
                        selectedLanguages: ctrl.selectedLanguages,
                        moduleToken: ctrl.moduleToken,
                        onUpdate: ctrl.onUpdate,
                        requireUserLanguage: ctrl.requireUserLanguage || (typeof ctrl.requireUserLanguage === 'undefined')
                    })
        }

        function onUpdate(selectedLanguages) {
            ctrl.selectedLanguages = selectedLanguages;
            ctrl.currentIndex = _.findIndex(ctrl.selectedLanguages, {active: true});

            if(ctrl.currentIndex < 0){
                ctrl.currentIndex = 0;
                if(ctrl.selectedLanguages.length){
                    ctrl.selectedLanguages[0].active = true;
                }
            }
        }

        function selectLanguage(language) {
            _.each(ctrl.selectedLanguages, function (lang) {
                lang.active = false;
            });

            language.active = true;

            ctrl.currentIndex = _.findIndex(ctrl.selectedLanguages, language);

            ctrl.onLanguageSelect && ctrl.onLanguageSelect(language);
        }
    }
})();