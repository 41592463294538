(function () {
    'use strict';

    angular.module('App')
        .component('searchResults', {
            template: require('./SearchResultsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', SearchResultsController],
            bindings: {
                token: '<',
                module: '@',
                searchResults: '<',
                sortingOptions: '<',
                sortType: '=?',
                onSortTypeChange: '<',
                searchedText: '<',
                paging: '<',
                pagingDisabled: '<',
                onItemOpen: '<',
                hideResults: '<',
                sortingLabel: '@',
                sortingTitle: '@',
            }
        })

    function SearchResultsController($scope) {
        const ctrl = this;
        let removeSortTypeWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.openItem = openItem;

        function init() {
            if (ctrl.onSortTypeChange) {
                removeSortTypeWatcher = $scope.$watch('ctrl.sortType', (newSortType, oldSortType) => {
                    if (newSortType && newSortType !== oldSortType) {
                        ctrl.onSortTypeChange(newSortType);
                    }
                })
            }
        }

        function destroy() {
            removeSortTypeWatcher && removeSortTypeWatcher();
        }

        function openItem(item) {
            ctrl.onItemOpen && ctrl.onItemOpen(item);
        }
    }
})();