(() => {
    'use strict';

    angular.module('App')
        .component('profileSettingsLanguage', {
            template: require('./ProfileSettingsLanguageComponent.tpl.html'),
            controller: ['ProfileSettingsService', '$http', '$filter', '$scope', 'ConfirmPopupService', 'AppBaseFactory', 'Page', ProfileSettingsLanguageComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsLanguageComponentController(ProfileSettingsService, $http, $filter, $scope, ConfirmPopupService, AppBaseFactory, Page) {
        var ctrl = this,
            cultureWatcher = null;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.onUserCultureChange = onUserCultureChange;

        function onInit() {
            initProfile();
            initCultures();
        }

        function onDestroy() {
            if (cultureWatcher) {
                cultureWatcher();
            }
        }

        function initProfile() {
            ctrl.isLoadingProfileData = true;

            ProfileSettingsService.getFullProfile().then(res => {
                if (!res.CanChangeCulture) {
                    Page.triggerBackButton();
                }

                ctrl.userCulture = res.CultureId;
                
                ctrl.defaultCulture = {
                    LANGUAGE: res.DefaultCulture
                };

                ctrl.isLoadingProfileData = false;
                initCultureSelector();
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }

        function initCultures() {
            ctrl.isLoadingUserCultures = true;

            ctrl.cultureData = null;
            ctrl.cultureOptions = [];
            ctrl.cultureOptionsDisabled = false;

            $http.get('/Profile/GetUserCultureList').then((res) => {
                if (res.data) {
                    ctrl.cultureData = res.data;
                } else {
                    ctrl.isLoadingUserCultures = false;
                }

                initCultureSelector();
            }).catch(() => {
                ctrl.isLoadingUserCultures = false;
            });
        }

        function initCultureSelector() {
            if (!ctrl.isLoadingProfileData && ctrl.cultureData) {
                ctrl.cultureOptions = [{
                    id: null,
                    name: $filter('translate')('PROFILE.SETTINGS.LANGUAGE.DEFAULT_OPTION', ctrl.defaultCulture),
                    selected: ctrl.userCulture === null
                }];

                if (ctrl.cultureData.CanChange) {
                    ctrl.cultureData.Cultures.forEach(culture => {
                        ctrl.cultureOptions.push({
                            id: culture.CultureId,
                            name: culture.Name,
                            selected: ctrl.userCulture == culture.CultureId
                        });
                    });
                } else{
                    ctrl.cultureOptionsDisabled = true;
                    
                    if (ctrl.cultureData.Cultures.length > 0) {
                        const culture = ctrl.cultureData.Cultures[0];
                        ctrl.cultureOptions = [{
                            id: culture.CultureId,
                            name: culture.Name,
                            selected: true
                        }];
                    }
                }

                initCultureSelectorSelection();
                ctrl.isLoadingUserCultures = false;
            }
        }

        function initCultureSelectorSelection() {
            ctrl.selectedUserCulture = ctrl.cultureOptions.find(o => o.selected == true);
            ctrl.prevSelectedUserCulture = null;

            cultureWatcher = $scope.$watch('ctrl.selectedUserCulture', (newVal, oldVal) => {
                if (oldVal.id !== newVal.id) {
                    onUserCultureChange(newVal.id, oldVal);
                }
            });
        }

        function onUserCultureChange(newCultureId, prevCulture) {
            if (ctrl.isUpdatingCulture) {
                return;
            }

            if (ctrl.prevSelectedUserCulture !== null) {
                ctrl.prevSelectedUserCulture = null;
                return;
            }

            ctrl.isUpdatingCulture = true;
            ctrl.prevSelectedUserCulture = prevCulture;

            ProfileSettingsService.updateCulture(newCultureId).then(() => {
                ctrl.prevSelectedUserCulture = null;
                ctrl.isUpdatingCulture = false;
                ctrl.userCulture = newCultureId;

                onCultureUpdated();
            }).catch(() => {
                ctrl.isUpdatingCulture = false;
                ctrl.selectedUserCulture = ctrl.prevSelectedUserCulture;
            });
        }

        function onCultureUpdated() {
            ConfirmPopupService.open({
                message: $filter('translate')('PROFILE.SETTINGS.LANGUAGE.CHANGED_CONFIRMATION_MESSAGE'),
                yesText: $filter('translate')('YES'),
                noText: $filter('translate')('NO'),
                icon: null
            }).then(() => {
                AppBaseFactory.reloadApp();
            });
        }
    }
})();