(() => {
    'use strict';

    angular
        .module('App')
        .directive('landingPageTileLoading', () => {
            return {
                restrict: 'E',
                template: require('./Templates/landingpage-tile-loading.tpl.html'),
                scope: {
                    loading: '='
                }
            }
        });
})();