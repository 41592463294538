(() => {
    'use strict';

    angular
        .module('App')
        .component('updateFilling', {
            template: require('./CreateUpdateFillingComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$timeout', '$element', 'CreateUpdateFillingService', 'ServiceFormRenderService',
                'ServiceFormDataFormatService', 'ToastFactory', 'Profile', 'ServiceFormRestService', UpdateFillingController],
            bindings: {
                form: '<',
                stages: '<',
                listsData: '<',
                serviceFormId: '<',
                fillingUpdated: '<',
                popupId: '<',
                isDraftEnabled: '<',
                issueId: '<',
                isCollaborationEnabled: '<',
                isCollaborationEditingEnabled: '<',
                onPopupRegistered: '<',
                isDynamic: '<',
                isStagesEnabled: '<',
                accountModuleToken: '<',
                calendarEventId: '<',
                attendingStatusId: '<',
                outro: '<',
                embeddedMedias: '<',
            }
        });

    function UpdateFillingController($rootScope, $scope, $timeout, $element, CreateUpdateFillingService, ServiceFormRenderService,
        ServiceFormDataFormatService, ToastFactory, Profile, ServiceFormRestService) {
        const ctrl = this;
        let initialForm, popup, isStepValidWatcher;
        let hidePopup = false;


        if (!ctrl.isStagesEnabled) {
            initialForm = _.cloneDeep(ctrl.form)
        }

        ctrl.lastRuleActions = {
            FieldsToShow: [], InstantFeedbacks: [], PagesToJump: []
        };

        ctrl.isUpdateFilling = true;
        ctrl.currentUserToken = Profile.getProfile().UserToken;
        ctrl.ratingColorClass = ServiceFormRenderService.getFeedbackRatingColorClass;

        if (ServiceFormRenderService.getFeedbackOptions()) {
            ctrl.isFeedbackEnabled = ServiceFormRenderService.getFeedbackOptions().IsFeedbackEnabled;
        }

        ctrl.$onInit = init;
        ctrl.next = next;
        ctrl.finishForm = finishForm;
        ctrl.previous = previous;
        ctrl.getFieldDataList = getFieldDataList;
        ctrl.onClosePopup = onClosePopup;
        ctrl.onRenderComplete = onRenderComplete;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.saveFieldValue = saveFieldValue;
        ctrl.checkIfFieldsAreVisible = checkIfFieldsAreVisible;
        ctrl.$onDestroy = destroy;

        const goToLimitedReportsEvent = $rootScope.$on('goToLimitedReports', () => {
            hidePopup = true;
            if (!popup) return;
            
            onClosePopup();
        });

        function onRenderComplete() {
            isStepValidWatcher = $scope.$watch('ctrl.form', isStepValid, true);
        }

        function onOpenPopup(data, popupSrc) {
            ctrl.filling = data.Filling;
            ctrl.fieldsWithRuleActionsToLimitDataListUsage = data.FieldsWithRuleActionsToLimitDataListUsage;
            ctrl.onClose = data.onClose;
            popup = popupSrc;

            if (ctrl.filling.IssueId) {
                ctrl.issueId = filling.IssueId;
            }
            if (data.Stage) {
                ctrl.stage = data.Stage;
            }

            ctrl.IsCreatedByRequestingUser = ctrl.filling.IsCreatedByRequestingUser;
            ctrl.ServiceFormFillingToken = ctrl.filling.ServiceFormFillingToken;
            ctrl.ServiceFormFillingId = ctrl.filling.ServiceFormFillingId;
            ctrl.IsDraft = ctrl.filling.IsDraft;

            if (ctrl.isStagesEnabled) {
                ctrl.stageToken = ctrl.stage.ServiceFormStageToken;
                const form = ctrl.stages.find(stage => stage.ServiceFormStageToken === ctrl.stageToken);
                ctrl.form = ServiceFormDataFormatService
                    .formatDataForClient(form.Steps, ctrl.stage, ctrl.filling.Participants);
                ctrl.headerTitle = form.Title;
            } else {
                ctrl.form = ServiceFormDataFormatService
                    .formatDataForClient(ctrl.form, ctrl.filling, ctrl.filling.Participants);
                ctrl.headerTitle = ctrl.headerTitle || ServiceFormRenderService.getLabelSettings().TranslatedEntityNameSingular || ServiceFormRenderService.getLabelSettings().EntityNameSingular;
            }
            ctrl.PageNavigation = data.PageNavigation.map(pageId => ({
                step: ctrl.form.find(page => page.ServiceFormPageId === pageId).StepNumber
            }));
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            ctrl.PageNavigation[ctrl.PageNavigation.length - 1].isActive = true;
            ctrl.activeStep = ctrl.PageNavigation[ctrl.PageNavigation.length - 1].step;

            ctrl.maxActiveStep = !ctrl.filling.createdFromCopy ? ctrl.activeStep : ctrl.form.length;

            if (data.RuleActions) {
                CreateUpdateFillingService
                    .updateFormOnRules(data.RuleActions, ctrl.form, ctrl.lastRuleActions,
                        (lastRuleActions) => {
                            ctrl.lastRuleActions = lastRuleActions;
                        });
            }
        }
        
        function findEmptyRequiredFields() {
            return ctrl.form
                .map(formElement => formElement.Groups.map(group => group.Fields))
                .flat(2)
                .filter(field => field.IsRequired)
                .find(field => Array.isArray(field.model) ? !field.model?.length : !field.model)
        }

        function onClosePopup() {
            //get filling data 
            const isRequiredFieldEmpty = findEmptyRequiredFields();
            
            if (isRequiredFieldEmpty) {
                ServiceFormRestService.getFillingData(ctrl.filling.ServiceFormFillingToken).then(filling => {
                    ServiceFormRenderService.updateFilling(filling.Filling.ServiceFormFillingToken, filling.Filling)
                });
            }
            ctrl.onClose && updateFillingStatus();
            
            isStepValidWatcher && isStepValidWatcher();
            ctrl.filling = null;
            ctrl.form = _.cloneDeep(initialForm);
            ctrl.showOutro = false;
            ctrl.showFillings = true;

            if (!ctrl.IsDraft && ctrl.isFeedbackEnabled && ctrl.IsCreatedByRequestingUser && !hidePopup) {
                CreateUpdateFillingService.informAboutFillingUpdate(ctrl.ServiceFormFillingId);
            }
            if (ctrl.isDynamic) {
                popup.remove();
                $element.remove();
                $scope.$parent.$destroy();
            } else {
                popup.hide();
            }
        }

        function updateFillingStatus() {
            if (ctrl.filling && ctrl.filling.ServiceFormFillingToken) {
                ServiceFormRestService.getFillingData(ctrl.filling.ServiceFormFillingToken)
                    .then(latestFilling => ctrl.onClose(latestFilling));
            }
        }

        function next() {
            ctrl.isLoading = true;

            if (ctrl.maxActiveStep <= ctrl.activeStep) {
                ctrl.maxActiveStep = ctrl.activeStep + 1;
            }

            saveStep().then((data) => {
                if (data.RuleActions && data.RuleActions.PagesToJump.length) {
                    const pageJumpId = data.RuleActions.PagesToJump[0],
                        stepNumber = getStepNumber(pageJumpId);

                    if (stepNumber) {
                        goNext(stepNumber);
                    } else if (ctrl.outro?.ServiceFormPageId === pageJumpId) {
                        finishForm();
                    }
                } else {
                    goNext()
                }
            }).catch(function (error) {
                ctrl.isLoading = false;
                ToastFactory.error(error.Message);
            });

            function getStepNumber(jumpToPageId) {
                return ctrl.form.find(step => step.ServiceFormPageId === jumpToPageId)?.StepNumber;
            }
        }

        function goNext(stepNumber) {
            scrollTop();
            ctrl.isNext = true;
            ctrl.activeStep = stepNumber || (ctrl.activeStep + 1);
            const activeStepIndex = ctrl.PageNavigation.findIndex(page => page.step === ctrl.activeStep);
            if (activeStepIndex === -1) {
                ctrl.PageNavigation.length = ctrl.PageNavigation.findIndex(page => page.isActive) + 1
                ctrl.PageNavigation.push({
                    step: ctrl.activeStep
                });
            }
            ctrl.PageNavigation.forEach((page, key) => {
                if (key === ctrl.PageNavigation.length - 1) {
                    page.isActive = true
                } else {
                    delete page.isActive
                }
            })
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            isStepValid();
            ctrl.isLoading = false;
        }

        function previous() {
            scrollTop();
            ctrl.isNext = false;
            const activeStepIndex = ctrl.PageNavigation.findIndex(page => page.step === ctrl.activeStep);
            ctrl.activeStep = ctrl.PageNavigation[activeStepIndex - 1].step;
            ctrl.PageNavigation.forEach(page => {
                if (page.step === ctrl.activeStep) {
                    page.isActive = true
                } else {
                    delete page.isActive
                }
            })
            ctrl.form = CreateUpdateFillingService.updateVisibleSteps(ctrl.form, ctrl.PageNavigation);
            isStepValid();
        }

        function getFieldDataList(dataListId) {
            return ctrl.listsData.find(el => el.DataListId === dataListId)
        }

        function init() {
            ctrl.showFillings = true;
            ctrl.hideIntro = true;
            ServiceFormRenderService.setUpdateFillingController(ctrl);
        }

        function isStepValid() {
            if (ctrl.showFillings) {
                $timeout(() => {
                    ctrl.isNextDisabled = !ctrl.form[ctrl.activeStep].Groups
                        .every(group => group.formObject && group.formObject.$valid === true)
                }, 100)
            }
        }

        function saveStep() {
            return CreateUpdateFillingService
                .updateFilling(CreateUpdateFillingService.getSaveModel(ctrl))
                .then(function (resp) {
                    if (resp.data.Filling) {
                        ctrl.filling = resp.data.Filling;
                        ctrl.fillingUpdated && ctrl.fillingUpdated(resp.data);
                    }
                    return resp.data;
                });
        }

        function finishForm() {
            ctrl.isUploading = true;
            ctrl.IsDraft = false;

            CreateUpdateFillingService
                .updateFilling(CreateUpdateFillingService.getSaveModel(ctrl, true))
                .then(function (resp) {
                    if (resp.data) {
                        ctrl.filling = resp.data.Filling;
                        ctrl.fillingUpdated && ctrl.fillingUpdated(resp.data);
                    }

                    if (ctrl.outro) {
                        ctrl.showFillings = false;
                        ctrl.showOutro = true;
                    } else {
                        if (ctrl.calendarEventId) {
                            $rootScope.$emit('ServiceFrom: FillingCreated', ctrl.calendarEventId, resp.data?.Filling);
                        } else {
                            ToastFactory.success(
                                ServiceFormRenderService.getLabelSettings().TranslatedFillingSubmissionMessage ||
                                ServiceFormRenderService.getLabelSettings().FillingSubmissionMessage);
                        }
                        popup && popup.close();
                    }
                })
                .catch(function (error) {
                    ToastFactory.error(error.Message);
                })
                .finally(function () {
                    ctrl.isUploading = false;
                });
        }

        function scrollTop() {
            $element.find('.scroll-container').scrollTop(0);
        }

        function checkIfFieldsAreVisible(fields) {
            return fields.some(field => field.IsVisibleOnFormLoad);
        }

        function saveFieldValue(field) {
            ctrl.isLoading = true;
            return CreateUpdateFillingService.setSingleFieldValue({
                ServiceFormFillingToken: ctrl.filling.ServiceFormFillingToken,
                Field: field,
                ServiceFormStageToken: ctrl.stageToken,
                ServiceFormFieldId: field.ServiceFormFieldId,
                AccountModuleToken: ctrl.accountModuleToken
            }).then(resp => {
                CreateUpdateFillingService
                    .updateFormOnRules(resp.data.RuleActions, ctrl.form, ctrl.lastRuleActions,
                        (lastRuleActions) => {
                            ctrl.isLoading = false;
                            ctrl.lastRuleActions = lastRuleActions;
                        });
            })
        }
        
        function destroy() {
            goToLimitedReportsEvent();
        }
    }
})();
