(() => {
    'use strict';

    angular
        .module('App')
        .component('multiplePermissionSelectorPopup', {
            template: require('./MultiplePermissionSelectorPopup.html'),
            controller: ['$element', 'MultiplePermissionSelectorService', MultiplePermissionSelectorPopupController],
            controllerAs: 'ctrl',
            bindings: {
                index: '<',
                departments: '<',
                settings: '<',
                userGroups: '<',
                selectedPermissions: '=?',
                defaultPermissions: '<',
                matchAllGroups: '<',
                requirePermissionId: '<',
                isMultiple: '<',
                moduleToken: '@',
                showValidation: '<'
            }
        });


    function MultiplePermissionSelectorPopupController($element, MultiplePermissionSelectorService) {
        let ctrl = this, popup;

        ctrl.VIEW = {
            DEPARTMENTS: 1,
            USERGROUPS: 2
        };
        ctrl.PERMISSIONS_TYPE = MultiplePermissionSelectorService.getRequiredPermissionId();

        ctrl.currentPermissions = {};
        ctrl.closePermissionPopup = closePermissionPopup;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.setActiveTab = setActiveTab;
        ctrl.savePermissions = savePermissions;
        ctrl.$onInit = init;

        function init() {
            ctrl.currentTab = ctrl.VIEW.DEPARTMENTS;
            ctrl.matchAllGroupsOnInit = ctrl.matchAllGroups;
            ctrl.currentPermissions = ctrl.selectedPermissions[ctrl.index];

            ctrl.isDepartmentsRequired = ctrl.requirePermissionId === ctrl.PERMISSIONS_TYPE.departments 
                || ctrl.requirePermissionId === ctrl.PERMISSIONS_TYPE.departmentsAndUserGroups;
            ctrl.isUserGroupsRequired = ctrl.requirePermissionId === ctrl.PERMISSIONS_TYPE.userGroups 
                || ctrl.requirePermissionId === ctrl.PERMISSIONS_TYPE.departmentsAndUserGroups;
            ctrl.isDepartmentOrUsergroupRequired = ctrl.requirePermissionId === ctrl.PERMISSIONS_TYPE.departmentsOrUserGroups;

            if (ctrl.settings.hideDepartments) {
                ctrl.currentTab = ctrl.VIEW.USERGROUPS;
            }

            const selectedUserGroups = ctrl.currentPermissions?.UserGroupIds?.length > 0 ?
                    ctrl.currentPermissions.UserGroupIds :
                    ctrl.defaultPermissions[0].UserGroupIds,
                selectedDepartments = ctrl.currentPermissions?.DepartmentIds?.length > 0 ?
                    ctrl.currentPermissions.DepartmentIds :
                    ctrl.defaultPermissions[0].DepartmentIds;

            ctrl.userGroups.model = selectedUserGroups.map(RoleId => {
                return ctrl.userGroups.find(group => group.RoleId === RoleId);
            });
            ctrl.defaultUserGroup = ctrl.userGroups.find(group => group.IsEveryoneRole);

            if (selectedDepartments) {
                ctrl.departments.model = selectedDepartments.map(departmentId => {
                    return ctrl.departments.find(department => department.DepartmentId === departmentId)
                });
            }
        }

        function closePermissionPopup() {
            popup.remove();
            $element.remove();
            reset();
        }

        function savePermissions() {
            const departmentsIds = (ctrl.departments?.model || []).map(department => department.DepartmentId);
            const userGroupsIds = ctrl.userGroups.model.map(group => group.RoleId);

            if ((departmentsIds.length || userGroupsIds.length || ((ctrl.matchAllGroupsOnInit !== ctrl.matchAllGroups) && !ctrl.isMultiple)) || ((departmentsIds.length || userGroupsIds.length) && ctrl.isMultiple)) {
                ctrl.selectedPermissions[ctrl.index] = {
                    DepartmentIds: departmentsIds,
                    UserGroupIds: userGroupsIds,
                    MatchAllGroups: ctrl.matchAllGroups,
                    ModuleItemPermissionId: ctrl.selectedPermissions[ctrl.index]?.ModuleItemPermissionId || ctrl.defaultPermissions[0]?.ModuleItemPermissionId
                }
            }

            if ((
                !ctrl.isMultiple &&
                (ctrl.currentPermissions?.UserGroupIds.length !== 0 || ctrl.currentPermissions.DepartmentIds.length !== 0) &&
                (departmentsIds.length === 0 && userGroupsIds.length === 0) &&
                (ctrl.matchAllGroupsOnInit === ctrl.matchAllGroups)
            ) ||
                (
                    ctrl.isMultiple &&
                    (ctrl.currentPermissions?.UserGroupIds.length !== 0 || ctrl.currentPermissions.DepartmentIds.length !== 0) &&
                    (departmentsIds.length === 0 && userGroupsIds.length === 0)
                )) {
                ctrl.selectedPermissions.splice(ctrl.index, 1);
                closePermissionPopup();
            }

            closePermissionPopup();
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
        }

        function setActiveTab(tab) {
            ctrl.currentTab = tab;
        }

        function reset() {
            ctrl.departments.model = [];
            ctrl.userGroups.model = [];
            ctrl.matchAllGroups = false;
        }
    }
})();


