(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('readMore', ['$interval', '$translate', 'ResponsiveService',
        function ($interval, $translate, ResponsiveService) {
            return {
                restrict: 'A',
                scope: {
                    readMore: '=',
                    readMoreSimple: '=',
                    onReadMoreClick: '<'
                },
                link: function (scope, element) {
                    var $element = $(element);
                    var totalHeight = 0;
                    var offsetHeight = 80;

                    if (scope.readMore) {
                        $translate('CONTENT.READ_MORE').then(function (translation) {
                            var ReadMoreText = translation;
                            var ReadMoreTemplate = '<button role="button" aria-hidden="true" tabindex="0" class="readmore">' + ReadMoreText + '</button>';
                            var closed = true;
                            var ReadMoreButton = null;
                            var maxHeight = ResponsiveService.isDesktop() ? 180 : 90;


                            // Load it up
                            var interval = $interval(function () {
                                var newHeight = $element.height();
                                if (newHeight > totalHeight && newHeight > maxHeight) {
                                    totalHeight = newHeight;

                                    totalHeight = totalHeight + 300;
                                    $element.attr('style', 'max-height: ' + maxHeight + 'px');

                                    ReadMoreButton = angular.element(ReadMoreTemplate);
                                    ReadMoreButton.bind('click', ReadMoreToggle);

                                    // Insert button
                                    element.after(ReadMoreButton);
                                    // Add Classes
                                    $element.addClass('readmore');
                                    if (scope.readMoreSimple) {
                                        ReadMoreButton.addClass('simple');
                                    }

                                    // Clear interval
                                    $interval.cancel(interval);
                                }
                            }, 50, 100);

                            // Functions
                            function ReadMoreToggle() {
                                var windowPosition = $(window).scrollTop();
                                if (closed) {
                                    $translate('CONTENT.READ_MORE_CLOSE').then(function (readMoreClose) {
                                        closed = false;
                                        ReadMoreButton.html(readMoreClose).addClass('open');
                                        element.addClass('open').attr('style', 'max-height: auto');

                                        if (windowPosition !== $(window).scrollTop()) {
                                            $(window).scrollTop(windowPosition)
                                        }
                                    });
                                    scope.onReadMoreClick && scope.onReadMoreClick();
                                } else {
                                    closed = true;
                                    ReadMoreButton.html(ReadMoreText).removeClass('open');
                                    element.removeClass('open').attr('style', 'max-height: ' + maxHeight + 'px');
                                }
                            }
                        });
                    }
                }
            }
        }
    ]);
})();