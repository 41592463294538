(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('ChristmasCalendarIntroController', ['$scope', '$http', 'close', '$translate', '$timeout', 'TimeLocale', 'intro', 'toastr', 'Page',
        function ($scope, $http, close, $translate, $timeout, TimeLocale, intro, toastr, Page) {
            $scope.close = close;

            InitController();
            function InitController() {
                //console.log(intro);
                $scope.intro = intro;
            }
        }
    ]);

})();