(() => {
    'use strict';

    angular
        .module('App')
        .component('tasksByDay', {
            template: require('./TasksByDayComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                days: '<',
                issueKey: '@',
                sortByStart: '<'
            }
        });
})();