(function () {
    'use strict';

    var app = angular.module('App');

    app.component('howToGuide', {
        templateUrl: '/Scripts/Components/HowToGuide/HowToGuideComponent.tpl.html',
        controllerAs: 'ctrl',
        bindings: {
            popupId: '<',
            token: '<'
        },
        controller: ['$element', 'HowToGuideService', 'PopupWrapperService', howToGuideController]
    });
    
    function howToGuideController($element, HowToGuideService, PopupWrapperService) {
        var ctrl = this, popupInstance;

        ctrl.onOpenPopup = onOpenPopup;
        ctrl.onClose = onClose;
        ctrl.data = {};

        function onOpenPopup(data, popup) {
            popupInstance = popup;
            HowToGuideService.getData(ctrl.token).then(function (data) {
                ctrl.data = data;
            });
        }
        
        function onClose() {
            PopupWrapperService.getPopup(ctrl.popupId).hide();
            PopupWrapperService.unRegisterEvent(popupInstance);
            $element.remove();
        }
    }
})();