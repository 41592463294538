(function () {
    'use strict';

    angular.module('App')
        .factory('ServiceFormExternalService', ['PopupWrapperService', 'ServiceFormRenderService', ServiceFormExternalService]);

    function ServiceFormExternalService(PopupWrapperService, ServiceFormRenderService) {
        return {
            setSettings: setSettings,
            openCreateFillingPopup: openCreateFillingPopup,
            openUpdateFillingPopup: openUpdateFillingPopup,
            openFillingPreviewPopup: openFillingPreviewPopup
        }


        function setSettings(formData) {
            ServiceFormRenderService.setFeedbackOptions({
                IsFeedbackEnabled: formData.IsFeedbackEnabled,
                IsFeedbackDrawingEnabled: formData.IsFeedbackDrawingEnabled
            });
            ServiceFormRenderService.setLabelSettings(formData.LabelSettings);
            ServiceFormRenderService.setGpsSettings({
                ShowCustomersOnMapEnabled: formData.ShowCustomersOnMapEnabled,
                GpsToAddressEnabled: formData.GpsToAddressEnabled,
                IsGpsTrackingEnabled: formData.IsGpsTrackingEnabled
            });
        }

        function openCreateFillingPopup(serviceFormData) {
            PopupWrapperService
                .createDynamic(`<create-filling
                       lists-data="data.DataLists"
                       form="data.Form.Steps"
                       service-form-id="data.Form.ServiceFormId"
                       issue-id="data.issueId"
                       calendar-event-id="data.CalendarEventId"
                       attending-status-id="data.AttendingStatusId"
                       filling-created="data.fillingCreated"
                       pre-draft-created="data.preDraftCreated"
                       intro="data.Form.Intro"
                       outro="data.Form.Outro"
                       popup-id="'createFormIf'"
                       is-draft-enabled="data.IsDraftEnabled"
                       on-popup-registered="onPopupRegistered"
                       account-module-token="data.ServiceFormAccountModuleToken"
                       is-dynamic="true"
                    ></create-filling>`, {
                    data: serviceFormData,
                    onPopupRegistered: function (popup) {
                        popup.open();
                    }
                });
        }

        function openUpdateFillingPopup(serviceFormData, filling) {
            PopupWrapperService
                .createDynamic(`<update-filling
                       is-draft-enabled="data.IsDraftEnabled"
                       lists-data="data.DataLists"
                       form="data.Form.Steps"
                       service-form-id="data.Form.ServiceFormId"
                       filling-updated="data.fillingCreated"
                       issue-id="data.issueId"
                       calendar-event-id="data.CalendarEventId"
                       attending-status-id="data.AttendingStatusId"
                       popup-id="'EditFormId'"
                       on-popup-registered="onPopupRegistered"
                       account-module-token="data.ServiceFormAccountModuleToken"
                       is-dynamic="true"
                    ></update-filling>`, {
                    data: serviceFormData,
                    onPopupRegistered: function (popup) {
                        popup.open(filling);
                    }
                });
        }

        function openFillingPreviewPopup(token) {
            PopupWrapperService.createDynamic(`<filling-preview-popup  
                        on-popup-registered="onPopupRegistered"
                        is-read-only="true"
                        is-dynamic="true"
                    ></filling-preview-popup>`, {
                onPopupRegistered: function (popup) {
                    popup.open(token);
                }
            })
        }
    }
})();