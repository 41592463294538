(() => {
    'use strict';

    angular
        .module('App')
        .component('myEvents', {
            template: require('./MyEventsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$document', '$rootScope', 'BasicHelper', 'ToastFactory', 'CalendarRenderService',
                MyEventsController],
            bindings: {
                feedLink: '<'
            }
        })

    function MyEventsController($timeout, $document, $rootScope, BasicHelper, ToastFactory, CalendarRenderService) {
        const ctrl = this;
        let removeFilterWatcher, removeEventUpdatedWatcher, removeEventCreatedWatcher, myEvents;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.copyFeedUrl = copyFeedUrl;

        function init() {
            ctrl.feedUrl = window.location.origin + ctrl.feedLink;

            loadEvents();

            removeFilterWatcher = $rootScope.$on('filter-events', loadEvents)
            removeEventCreatedWatcher = $rootScope.$on('event-created', loadEvents)
            removeEventUpdatedWatcher = $rootScope.$on('event-updated', updateEvent)
        }

        function loadEvents() {
            ctrl.isLoading = true;
            CalendarRenderService.getCalendarEvents({
                OnlyMyEvents: true,
                Limit: 50
            }).then(({events}) => {
                myEvents = events;
                ctrl.todayEvents = myEvents.filter(ev => ev.IsTodayEvent);
                ctrl.futureEvents = myEvents.filter(ev => !ev.IsTodayEvent);
                $timeout(() => {
                    ctrl.isLoading = false;
                })
            })
        }

        function updateEvent(_, id) {
            const indexToUpdate = myEvents.findIndex(event => event.CalendarEventId === id);
            if (indexToUpdate !== -1) {
                const updatedEvent = CalendarRenderService.getEvent(id)
                if (updatedEvent) {
                    myEvents[indexToUpdate] = updatedEvent;
                } else {
                    myEvents.splice(indexToUpdate, 1);
                }
                ctrl.todayEvents = myEvents.filter(ev => ev.IsTodayEvent);
                ctrl.futureEvents = myEvents.filter(ev => !ev.IsTodayEvent);
            }
        }

        function destroy() {
            removeFilterWatcher && removeFilterWatcher();
            removeEventUpdatedWatcher && removeEventUpdatedWatcher();
            removeEventCreatedWatcher && removeEventCreatedWatcher();
        }

        function copyFeedUrl() {
            BasicHelper.CopyClipbord($document.find("#feed-url"));
            ToastFactory.successTranslated('CALENDAR.FEED_COPIED');
        }
    }
})();