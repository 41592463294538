(() => {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider',
            $stateProvider => {
                $stateProvider
                    .state('calendar', {
                        parent: 'base',
                        url: '/calendar/:token',
                        template: require('./Calendar.html'),
                        resolve: {
                            calendarData: ['$stateParams', 'base', 'CalendarRenderService', function ($stateParams, base, CalendarRenderService) {
                                if (base) {
                                    return CalendarRenderService.getCalendarData($stateParams.token);
                                }
                            }]
                        },
                        controller: 'CalendarController as ctrl'
                    })
                    .state('eventPopup', {
                        parent: 'calendar',
                        url: '/:extra',
                        onEnter: ['$stateParams', 'EventDetailsService',
                            function ($stateParams, EventDetailsService) {
                                const urlSearchParams = new URLSearchParams($stateParams.extra),
                                    calendarEventId = urlSearchParams.get('CalendarEventId') || $stateParams.extra;

                                EventDetailsService.openEventDetails(
                                    calendarEventId,
                                    urlSearchParams.get('EventUpdate') === 'true',
                                    $stateParams.token
                                );
                            }]
                    });
            }
        ])
        .controller("CalendarController", ['$stateParams', '$scope', 'calendarData', 'Page', 'events', '$state', 'Menu',
            function ($stateParams, $scope, calendarData, Page, events, $state, Menu) {
                const ctrl = this,
                    navigationBadge = calendarData.NavigationBadge;

                const badgeCount = Menu.updateActivity(0, $state.current.name, $state.params.token, $state.params.extra);
                Menu.updateLandingBadge(0, navigationBadge.AccountModuleId, navigationBadge.ModuleId, navigationBadge.ModuleItemId, badgeCount);

                Page.setTitle(calendarData.PageTitle);
                setBackButton();
                ctrl.calendarData = calendarData;

                $scope.$on(events.DEPARTMENT_CHANGED, () => {
                    Page.stateReload();
                });

                function setBackButton() {
                    if ($stateParams.backState) {
                        Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                    } else {
                        Page.showBackButton('home');
                    }
                }
            }]);
})();
