(function () {
    'use strict';

    angular
        .module('App')
        .factory('DepartmentPopupService', ['PopupWrapperService', DepartmentPopupService]);

    function DepartmentPopupService(PopupWrapperService) {

        return {
            openDepartmentPopup: openDepartmentPopup
        };


        function openDepartmentPopup(token, showDepartmentContacts, moduleToken) {
            PopupWrapperService.createDynamic('<department-popup on-popup-registered="popupRegistered" module-token="moduleToken" show-contacts="showContacts"></department-popup>',
            {
                popupRegistered: function (popup) {
                    popup.open(token);
                },
                moduleToken: moduleToken,
                showContacts: showDepartmentContacts
            });
        }
    }

})();
