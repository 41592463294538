(function () {
    'use strict';

    angular
        .module('App')
        .factory('PinCodeService', ['$q', '$http', '$rootScope', '$window', 'BasicHelper', 'Page', 'ToastFactory',
            'ReactionsService', 'Menu', 'NotificationFactory', 'Profile', 'NativeFactory', 'AppBaseFactory', 'ChatConnectionService',
            'NATIVE_BIOMETRIC_STATUS', 'NATIVE_BIOMETRIC_ERROR_LABELS', 'NATIVE_BIOMETRIC_ERROR_CODES', 'events', PinCodeService]);

    function PinCodeService($q, $http, $rootScope, $window, BasicHelper, Page, ToastFactory, ReactionsService, Menu, 
        NotificationFactory, Profile, NativeFactory, AppBaseFactory, ChatConnectionService,
        NATIVE_BIOMETRIC_STATUS, NATIVE_BIOMETRIC_ERROR_LABELS, NATIVE_BIOMETRIC_ERROR_CODES, events) {

        var pinCodeSettings, pinCodeLabels;

        return {
            setPinCodeSettings: setPinCodeSettings,
            getPinCodeSettings: getPinCodeSettings,
            skipSetPin: skipSetPin,
            setPin: setPin,
            verifyPinCode: verifyPinCode,
            goToState: goToState,
            forgotPin: forgotPin,
            saveLabels: saveLabels,
            getLabels: getLabels,
            loadDataAfterPin: loadDataAfterPin,
            mayAskForSavingBiometric: mayAskForSavingBiometric,
            optOutOfSavingBiometric: optOutOfSavingBiometric,
            getBiometricPinStatus: getBiometricPinStatus,
            getBiometricPin: getBiometricPin,
            saveBiometricPin: saveBiometricPin,
            clearBiometricPin: clearBiometricPin
        };

        function setPinCodeSettings(settings) {
            pinCodeSettings = {
                setPinCode: BasicHelper.parseBoolean(settings.SetPinCode),
                canSkip: BasicHelper.parseBoolean(settings.CanSkip),
                enterPinCode: BasicHelper.parseBoolean(settings.EnterPinCode)
            };

            return pinCodeSettings;
        }

        function getPinCodeSettings() {
            return pinCodeSettings;
        }

        function skipSetPin() {
            var d = $q.defer();

            $http.post('/PinCode/Skip').then(function (resp) {
                if (resp.data.success) {
                    d.resolve();
                } else {
                    ToastFactory.errorTranslated(resp.data.message);
                    d.reject();
                }
            });

            return d.promise;
        }

        function setPin(pin, oldPin) {
            return $http.post('/PinCode/Set', {
                pinCode: pin,
                oldPinCode: oldPin
            }).then(function (resp) {
                return {response: resp};
            });
        }

        function verifyPinCode(pin) {
            return $http.post('/PinCode/Verify', {
                pinCode: pin
            }).then(function (resp) {
                if (resp.data.logout) {
                    Page.stateGo('logout');
                    return;
                }

                // Create redirect url
                var redirect = null;
                if (resp.data.redirectUrl) {
                    redirect = resp.data.redirectUrl;
                }

                return {response: resp, redirect: redirect};
            });
        }

        function forgotPin() {
            return $http.post('/PinCode/Forgot')
                .then(function (resp) {
                    if (resp.data.logout) {
                        Page.stateGo('logout');
                    } else if (resp.data.redirectUrl) {
                        $window.location.replace(resp.data.redirectUrl);
                    }
                    return resp;
                });
        }

        function optOutOfSavingBiometric(userToken){
            if (userToken) {
                localStorage.setItem('biometric_requests_allowed_' + userToken, 'false');
            }
        }

        function clearOptOutOfSavingBiometric(userToken){
            if (userToken) {
                localStorage.removeItem('biometric_requests_allowed_' + userToken);
            }
        }

        function mayAskForSavingBiometric(userToken){
            if (userToken) {
                return localStorage.getItem('biometric_requests_allowed_' + userToken) !== 'false';
            }
            return true;
        }

        function getBiometricPinStatus(userToken){
            var d = $q.defer();

            NativeFactory.biometric.getStatus((res) => {
                if (res.status === 1) {
                    const status = res.data.status;
                    
                    if (status === NATIVE_BIOMETRIC_STATUS.ERROR) {
                        
                        console.error('Native.biometric.getStatus failed', res.data);
                        d.reject({
                            errorCode: NATIVE_BIOMETRIC_ERROR_LABELS[res.data.errorCode],
                            errorMessage: res.data.errorMessage
                        });

                    } else if (status == NATIVE_BIOMETRIC_STATUS.AVAILABLE || status == NATIVE_BIOMETRIC_STATUS.STORED) {
                        
                        d.resolve({
                            isStored: status == NATIVE_BIOMETRIC_STATUS.STORED
                        });

                    } else {
                        // It is not a status we know how to handle
                        console.error('Native.biometric.getStatus failed', res.data);
                        d.reject(); 
                    }
                } else {
                    d.reject();
                }
            }, userToken);

            return d.promise;
        }

        function getBiometricPin(userToken){
            var d = $q.defer();

            NativeFactory.biometric.getPin((res) => {
                if (res.status === 1) {
                    const status = res.data.status;

                    if (status == NATIVE_BIOMETRIC_STATUS.ERROR) {
                        console.error('Native.biometric.getPin failed', res.data);

                        const errorCode = res.data.errorCode;
                        if (errorCode === NATIVE_BIOMETRIC_ERROR_CODES.BIOMETRIC_CHANGED || errorCode === NATIVE_BIOMETRIC_ERROR_CODES.BIOMETRIC_NOT_AVAILABLE) {
                            clearBiometricPin(userToken);
                        }

                        if (errorCode == NATIVE_BIOMETRIC_ERROR_CODES.BIOMETRIC_AUTHENTICATION_FAILED) {
                            d.reject({
                                isCancelEvent: false,
                                errorMessage: res.data.errorMessage
                            });
                        } else {
                            d.reject({
                                isCancelEvent: false,
                                errorCode: NATIVE_BIOMETRIC_ERROR_LABELS[errorCode],
                                errorMessage: res.data.errorMessage
                            });
                        }                        
                    } else if (status == NATIVE_BIOMETRIC_STATUS.CANCELLED) {
                        d.reject({
                            isCancelEvent: true
                        });
                    }  else if (status == NATIVE_BIOMETRIC_STATUS.SUCCESS) {
                        d.resolve({
                            pinCode: res.data.pinCode
                        });
                    } else {
                        console.error('Native.biometric.getPin failed', res.data);
                        d.reject();
                    }
                } else {
                    d.reject();
                }
            }, userToken);

            return d.promise;
        }

        function saveBiometricPin(userToken, pinCode){
            var d = $q.defer();

            NativeFactory.biometric.savePin((res) => {
                if (res.status === 1) {
                    clearOptOutOfSavingBiometric(userToken);

                    const status = res.data.status;
                    if (status == NATIVE_BIOMETRIC_STATUS.SUCCESS) {
                        d.resolve();
                    } else if (status == NATIVE_BIOMETRIC_STATUS.CANCELLED) {
                        d.reject({
                            isCancelEvent: true
                        });
                    } else {
                        console.error('Native.biometric.savePin failed', res.data);

                        const errorCode = res.data.errorCode;
                        if (errorCode == NATIVE_BIOMETRIC_ERROR_CODES.BIOMETRIC_AUTHENTICATION_FAILED) {
                            d.reject({
                                isCancelEvent: false,
                                errorMessage: res.data.errorMessage
                            });
                        } else {
                            d.reject({
                                isCancelEvent: false,
                                errorCode: NATIVE_BIOMETRIC_ERROR_LABELS[errorCode],
                                errorMessage: res.data.errorMessage
                            });
                        }
                    }
                } else {
                    d.reject();
                }
            }, userToken, pinCode);

            return d.promise;
        }

        function clearBiometricPin(userToken, optOut){
            if(optOut === true) {
                optOutOfSavingBiometric(userToken)
            }else{
                clearOptOutOfSavingBiometric(userToken);
            }

            NativeFactory.biometric.clearPin((res) => {
                if (res.status === 0) {
                    console.error('Native.biometric.clearPin failed', res);
                } else if(res.data.status == NATIVE_BIOMETRIC_STATUS.ERROR) {
                    console.error('Native.biometric.clearPin failed', res.data);
                }
            }, userToken);
        }

        function goToState() {
            $rootScope.ForcePinSkip = true;
            Page.stateGoAfterPin()
        }

        function saveLabels(labels) {
            pinCodeLabels = labels;
        }

        function getLabels() {
            return pinCodeLabels;
        }

        function loadDataAfterPin() {
            if(Profile.getProfile()){
                Profile.loadProfile();
                NotificationFactory.loadUnseenCount();
                ReactionsService.loadReactions();
                return Menu.loadMenus().then((resp) => {
                    ChatConnectionService.setChatBadge(resp.data.UnreadChatsCount);
                    $rootScope.$broadcast(events.MENU_UPDATED, true);
                });
            }else{
                return AppBaseFactory.loadBase();
            }
        }
    }
})();

