(() => {
    'use strict';

    angular
        .module('App')
        .component('reactionButton', {
            template: require('./ReactionButton.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                onLike: '<',
                onSendReaction: '<',
                onRemoveReaction: '<',
                onToggleList: '<',
                item: '<',
                shouldBeExcluded: '<'
            },
            controller: ['$rootScope', '$scope', 'REACTION_TYPE_ID', '$timeout', '$element', 'ResponsiveService',
                'ReactionsService', ReactionButtonController]
        });

    function ReactionButtonController($rootScope, $scope, REACTION_TYPE_ID, $timeout, $element, ResponsiveService,
                                      ReactionsService) {
        var ctrl = this, iconWidth = 46;

        ctrl.showReactionTooltip = false;
        ctrl.preselectedReactionIndex = null;
        ctrl.reactions = ReactionsService.getReactions();

        ctrl.getReaction = ReactionsService.getByTypeId;
        ctrl.Like = Like;
        ctrl.sendReaction = SendReaction;
        ctrl.ToggleReactionTooltip = ToggleReactionTooltip;
        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.hoverTimeout = 0;
            ctrl.reactionButton = null;
            ctrl.btnReactionHammer = null;

            $timeout(function () {
                InitTouchEvents();
            }, 100);
        }

        function calculatePreselectedIndex(event) {
            var reactionList = $element.find('.reactions-list'),
                reactionListCursorPosition = event.center.x - reactionList.offset().left,
                reactionIndex = Math.floor(reactionListCursorPosition / iconWidth);

            return reactionIndex <= 0 ? 0 : reactionIndex > (ctrl.reactions.length - 1) ? ctrl.reactions.length - 1 : reactionIndex;
        }

        function destroy() {
            if (ctrl.btnReactionHammer) {
                ctrl.btnReactionHammer.destroy();
            }
            ctrl.reactionButton = null;
            ctrl.btnReactionHammer = null;

            if (ctrl.$reactionButton) {
                ctrl.$reactionButton.off('touchstart');
                ctrl.$reactionButton.off('touchend');
            }
        }

        function ToggleReactionTooltip(state, event, hidePreselected) {
            ResponsiveService.isDesktop() && event && event.stopPropagation();

            ctrl.onToggleList && ctrl.onToggleList(state);

            $rootScope.safeApply(() => {
                if (state && !hidePreselected) {
                    ctrl.preselectedReactionIndex = calculatePreselectedIndex(event);
                }
                ctrl.showReactionTooltip = state;
            })
        }

        function handlePressEvent(e) {
            ToggleReactionTooltip(true, e);
        }

        function Like() {
            if (!ctrl.item.ReactionTypeId) {
                SendReaction(null, _.head(ctrl.reactions).ReactionTypeId);
            } else {
                RemoveReaction();
            }
        }

        function SendReaction(event, typeId) {
            if (event) {
                event.stopPropagation && event.stopPropagation();
                ToggleReactionTooltip(false);
            }

            if (ctrl.item.ReactionTypeId === typeId) {
                return false;
            }

            ctrl.onSendReaction(typeId, ctrl.item);

            if (!ctrl.shouldBeExcluded) {
                updateReactionsSummary(ctrl.item.ReactionTypeId, typeId);
                if (!ctrl.item.ReactionTypeId) {
                    ctrl.item.TotalReactions++;
                }
            }
            ctrl.item.ReactionTypeId = typeId;
            ctrl.showReactionTooltip = false;
            ctrl.onToggleList && ctrl.onToggleList(false);
        }

        function RemoveReaction() {
            ctrl.onRemoveReaction(ctrl.item);
            if (!ctrl.shouldBeExcluded) {
                updateReactionsSummary(ctrl.item.ReactionTypeId, null);
                ctrl.item.TotalReactions--;
            }
            ctrl.item.ReactionTypeId = null;
        }

        function updateReactionsSummary(prevReactionId, nextReactionId) {
            if (prevReactionId !== null) {
                ctrl.item.Reactions.map(function (reaction) {
                    if (reaction.ReactionTypeId === prevReactionId) {
                        reaction.Count--;
                    }
                });
            }

            if (nextReactionId !== null) {
                var nextIndex = ctrl.item.Reactions.findIndex(function (reaction) {
                    return reaction.ReactionTypeId === nextReactionId;
                });

                if (nextIndex !== -1) {
                    ctrl.item.Reactions[nextIndex].Count++;
                } else {
                    ctrl.item.Reactions.push({
                        ReactionTypeId: nextReactionId,
                        Count: 1
                    });
                }
            }
        }

        function InitTouchEvents() {
            if (ResponsiveService.isDesktop()) {
                initDesktopEvents();
            } else {
                initMobileEvents();
            }
        }

        function initDesktopEvents() {
            $element.find('.btn-reaction').hover(function (ev) {
                ctrl.hoverTimeout = $timeout(function () {
                    ToggleReactionTooltip(true, ev, true);
                }, 500);
            }, function (ev) {
                $timeout.cancel(ctrl.hoverTimeout);
                ToggleReactionTooltip(false, ev, true);
            })
        }

        function initMobileEvents() {
            ctrl.$reactionButton = $element.find('.btn-reaction');
            ctrl.reactionButton = $element.find('.btn-reaction')[0];

            if (ctrl.reactionButton) {
                ctrl.btnReactionHammer = new Hammer(ctrl.reactionButton);
                ctrl.btnReactionHammer.get('press').set({time: 500});

                ctrl.$reactionButton.on('touchstart', function () {
                    $('body').addClass('no-user-select');
                });
                ctrl.$reactionButton.on('touchend', function () {
                    $('body').removeClass('no-user-select');
                });
                ctrl.btnReactionHammer.on('press', onPress);
                ctrl.btnReactionHammer.on('pressup', onPressup);

                ctrl.btnReactionHammer.get('pan').set({direction: Hammer.DIRECTION_ALL});

                ctrl.btnReactionHammer.on('panleft', onPan);
                ctrl.btnReactionHammer.on('panright', onPan);
                ctrl.btnReactionHammer.on('panend', onPanend);
                ctrl.btnReactionHammer.on('pancancel', onPancancel);
            }
        }

        function onPress(e) {
            e.preventDefault();
            handlePressEvent(e);
        }

        function onPressup(e) {
            e.preventDefault();
            SendReaction(e, ctrl.reactions[ctrl.preselectedReactionIndex].ReactionTypeId || 1);
        }

        function onPan(e) {
            ctrl.preselectedReactionIndex = calculatePreselectedIndex(e);
            $scope.$apply();
        }

        function onPanend(e) {
            e.preventDefault();
            SendReaction(e, ctrl.reactions[ctrl.preselectedReactionIndex].ReactionTypeId || 1);
        }

        function onPancancel(e) {
            e.preventDefault();
            ToggleReactionTooltip(false);
        }

    }
})();