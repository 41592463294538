(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('rlBadge', {
            template: '<span class="number">{{ ($ctrl.isText || $ctrl.count < 99) ? $ctrl.count : "99+" }}</span><span class="background"></span>',
            bindings: {
                count: '@',
                isText: '<'
            }
        })
})();