(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('loadingOverlay', ['$rootScope',
        function ($rootScope) {
            return {
                restrict: 'E',
                transclude: true,
                template: '<div class="loading-overlay" ng-if="loading"><div class="spinner"></div></div>',
                link: function (scope, element, attrs) {
                    scope.loading = true;
                }
            }
        }
    ]);
})();