(function () {
    'use strict';

    angular.module('App')
        .component('messageViewers', {
            template: require('./MessageViewers.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', 'ConversationService', 'ChatPopupsService', MessageViewersController],
            bindings: {
                channelId: '@',
                messageId: '@',
                currentUserId: '@',
                blockedUsers: '<',
            },
        });

    function MessageViewersController($rootScope, $element, ConversationService, ChatPopupsService) {
        var ctrl = this, popup;

        const pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close());

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.formatDate = formatDate;
        ctrl.close = close;
        ctrl.isUserNotBlocked = isUserNotBlocked;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();

            ConversationService
                .getMessageReads(ctrl.channelId, ctrl.messageId)
                .then(reads => {
                    ctrl.reads = reads.filter(user => user.item.userId !== ctrl.currentUserId)
                });
        }

        function formatDate(date) {
            return moment(date).format('YYYY/MM/DD HH:mm')
        }

        function close() {
            pushOpenWatcher();
            popup.remove();
            ChatPopupsService.disableBodyScrollInChat();
            $element.remove();
        }

        function isUserNotBlocked(read) {
            return !ctrl.blockedUsers?.includes(read.item.userId);
        }
    }
})();