(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('emptySearchResults', {
            template: require('./EmptySearchResultsComponent.html'),
            controllerAs: 'ctrl',
            controller: [EmptySearchResultsController],
            bindings: {
                searchedText: '<'
            }
        })

    function EmptySearchResultsController() {
        var ctrl = this;
    }
})();