(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('blockedUsers', {
                        parent: 'base',
                        url: '/blockedUsers',
                        template: '<blocked-users></blocked-users>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'BlockedUsersController as ctrl'
                    });
            }
        ])
        .controller('BlockedUsersController', ['$stateParams', '$translate', 'Page',
            ($stateParams, $translate, Page) => {
                $translate('PROFILE.BLOCK.BLOCKED_USERS').then((translation) => {
                    Page.setTitle(translation);
                });

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('profileOverview');
                }
            }]);
})();