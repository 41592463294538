(() => {
    'use strict';

    angular
        .module('App')
        .component('fillingBadges', {
            template: require('./FillingItemBadgesComponent.html'),
            controllerAs: 'ctrl',
            controller: ['ServiceFormRenderService', FillingItemBadgesController],
            bindings: {
                filling: '<',
                prefareTranslated: '<'
            }
        })

    function FillingItemBadgesController(ServiceFormRenderService) {
        var ctrl = this;

        ctrl.$onChanges = function(changes) {
            if (changes.filling) {
                ctrl.showFeedbackStatus = ServiceFormRenderService.getFeedbackOptions().IsFeedbackEnabled
                    && ctrl.filling.ShowFeedbackStatus && !ctrl.filling.IsDraft;
            }
        };

        ctrl.showFeedbackStatus = ServiceFormRenderService.getFeedbackOptions().IsFeedbackEnabled
            && ctrl.filling.ShowFeedbackStatus && !ctrl.filling.IsDraft;
        ctrl.ratingColorClass = ServiceFormRenderService.getFeedbackRatingColorClass;
        ctrl.ratingIcon = ServiceFormRenderService.getRatingMediaById;
    }
})();