(function() {
    'use strict';

    angular
        .module('App')
        .directive('nativeAppOverlay', directive);

    directive.$inject = ['$window', '$timeout'];
    
    function directive($window, $timeout) {
        return {
            link: link,
            restrict: 'E',
            templateUrl: '/Scripts/Directives/Templates/NativeAppOverlay.tpl.html'
        };

        function link(scope, element, attrs) {
            // Set URL
            scope.iosStore = scope.data.IosAppStoreUrl;
            scope.androidStore = scope.data.AndroidAppStoreUrl;
            scope.isItIos = scope.data.IsItIos;
            scope.isItAndroid = scope.data.IsItAndroid;

            // Show popup
            var noResponseTimeout = null;
            var seekTimeout = null;
            show();

            function show() {
                scope.seek = true;

                $timeout(function () {
                    scope.isVisible = true;
                }, 100);

                seekTimeout = $timeout(function () {
                    scope.seek = false;
                }, 2000);

                noResponseTimeout = $timeout(function () {
                    close();
                }, 5000);
            }

            function close() {
                scope.isVisible = true;
                $timeout(function () {
                    element.remove();
                    scope.$destroy();
                }, 200);
            }

            scope.closeOverlay = function () {
                close();
            };

            scope.openAppStore = function () {
                if (scope.iosStore) {
                    document.location.href = scope.iosStore;
                }
            };

            scope.openPlayStore = function () {
                if (scope.androidStore) {
                    document.location.href = scope.androidStore;
                }
            };
        }
    }

})();