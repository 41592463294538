(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlToggleFilters', {
            template: require('./ToggleFiltersComponent.html'),
            controller: ['ActionSheetCustomService', ToggleFiltersController],
            controllerAs: 'ctrl',
            bindings: {
                options: '<',
                removeSelection: '=?',
                select: '=?',
                popupHeader: '<'
            }
        });

    function ToggleFiltersController(ActionSheetCustomService) {
        const ctrl = this;
        
        ctrl.$onInit = init;
        ctrl.openFilters = openFilters;
        ctrl.selectOption = selectOption;
        ctrl.removeSelection = removeSelection;

        function init() {
            const option = ctrl.options.find(option => option.selected);
            if (option) {
                ctrl.title = option.title;
            }
        }

        function openFilters() {
            ActionSheetCustomService.create(
                `<rl-toggle-filters-popup 
                    on-filter-selected="onFilterSelected"
                    options="options"
                    remove-selection="removeSelection"
                    popup-header="popupHeader"
                ></rl-toggle-filters-popup>`,
                {
                    onFilterSelected: ctrl.selectOption,
                    options: ctrl.options,
                    removeSelection,
                    popupHeader: ctrl.popupHeader
                }, 'rl-toggle-filters');
        }


        function selectOption(option) {
            const selectedOption = ctrl.options.find(currentOption => option.title === currentOption.title);
            if (selectedOption) {
                selectedOption.selected = true;
                ctrl.title = selectedOption.title;
            }
            ctrl.select && ctrl.select(selectedOption);
        }

        function removeSelection() {
            const selectedOption = ctrl.options.find(option => option.selected);
            if (selectedOption) {
                selectedOption.selected = false;
            }

            ctrl.title = '';
        }
    }
})();