(() => {
    'use strict';

    angular
        .module('App')
        .component('allTasks', {
            template: require('./AllTasksComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$scope', 'TaskManagementRenderService', AllTasksController],
            transclude: true,
            bindings: {
                calendarSource: '=',
                isActive: '<'
            }
        });

    function AllTasksController($element, $timeout, $scope, TaskManagementRenderService) {
        var ctrl = this, scrolledContainer, isCalendarUpdating, isNextLoadMore, isPrevLoadMore;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        function toggleVisibility() {
            scrolledContainer.toggleClass('hidden');
        }

        function scrollToDay(day) {
            if ($(day).offset()) {
                var scrollTop = $(day).offset().top - scrolledContainer.offset().top + scrolledContainer.scrollTop();
                scrolledContainer.scrollTop(scrollTop);
            }
        }

        function scrollTasks() {
            var lastIssue, lastIssueId, firstIssue, firstIssueId, firstIssueElement;

            if (isCalendarUpdating) {
                return false;
            }
            if ((scrolledContainer.scrollTop() + scrolledContainer.innerHeight() >= scrolledContainer.get(0).scrollHeight) && isNextLoadMore !== false) {
                isCalendarUpdating = true;
                lastIssue = $element.find('task').last().scope().issue;
                lastIssueId = lastIssue.IssueId;

                TaskManagementRenderService.getNextTasks(lastIssueId, ctrl.calendarSource).then(function (data) {
                    ctrl.calendarSource = ctrl.calendarSource.concat(prepareMonths(data.months));
                    isNextLoadMore = data.LoadMore;
                    isCalendarUpdating = false;
                });
            } else if (scrolledContainer.scrollTop() <= 0 && isPrevLoadMore !== false) {
                isCalendarUpdating = true;
                firstIssueElement = $element.find('task').first();
                firstIssue = firstIssueElement.scope().issue;
                firstIssueId = firstIssue.IssueId;

                TaskManagementRenderService.getPreviousTasks(firstIssueId, ctrl.calendarSource).then(function (data) {
                    ctrl.calendarSource = prepareMonths(data.months).concat(ctrl.calendarSource);
                    isPrevLoadMore = data.LoadMore;
                    isCalendarUpdating = false;
                    scrollToDay(firstIssueElement.parents('.month').get(0));
                });
            }
        }

        function prepareMonths(calendar) {
            var monthHaveEvents;
            _.forEach(calendar, function (month) {
                monthHaveEvents = _.find(month.days, function (day) {
                    return day.issues ? day.issues.length : day.issues.length;
                });
                month.isHaveEvents = !!monthHaveEvents;
            });

            return calendar;
        }

        function onInit() {
            isNextLoadMore = true;
            isPrevLoadMore = true;
            scrolledContainer = angular.element('all-tasks');
            scrolledContainer.on('scroll', scrollTasks);

            $scope.$watch('ctrl.isActive', function (isActive) {
                if (isActive) {
                    ctrl.calendarSource = prepareMonths(ctrl.calendarSource);
                    toggleVisibility();
                    $timeout(function () {
                        scrollToDay(_.last($element.find('.current')));
                        toggleVisibility();
                    });
                }
            });
        }

        function onDestroy() {
            scrolledContainer.off('scroll', scrollTasks);
            scrolledContainer = null;
        }
    }
})();