(function () {
    'use strict';

    var app = angular.module('App');

    app.component('comments', {
        templateUrl: '/Scripts/Components/Comments/CommentsComponent.html',
        controllerAs: 'ctrl',
        controller: ['Profile', '$http', '$q', '$timeout', '$scope', 'PopupWrapperService', 'ActionSheetService',
            '$element', 'REACTION_TYPE_ID', 'CommentsService', 'Page', 'ReactionsService', 'ContentSafetyWarningService',
            CommentsComponentController],
        bindings: {
            postId: '<',
            comments: '<',
            commentsTree: '<',
            newComment: '=?',
            settings: '<',
            onDelete: '<',
            onReport: '<',
            onPost: '<',
            onEditComment: '<',
            onLazyLoad: '<',
            module: '@',
            moduleToken: '<',
            infiniteLoading: '<',
            enableReply: '<',
            disableReactions: '<',
            replyingMessage: '<',
            commentId: '<'
        },
    });

    function CommentsComponentController(Profile, $http, $q, $timeout, $scope, PopupWrapperService, ActionSheetService,
                                         $element, REACTION_TYPE_ID, CommentsService, Page, ReactionsService, ContentSafetyWarningService) {
        var ctrl = this, commentItemRenderCount = 0;

        ctrl.showReactionsMessage = false;
        ctrl.replyingMessage = ctrl.replyingMessage || null;
        ctrl.expandThreadState = {};

        ctrl.$onInit = init;
        ctrl.expandThread = expandThread;
        ctrl.onPostComment = onPostComment;
        ctrl.onMediaUpdated = onMediaUpdated;
        ctrl.removeMedia = removeMedia;
        ctrl.like = like;
        ctrl.reply = reply;
        ctrl.clearReplyingMessage = clearReplyingMessage;
        ctrl.cancelEditing = cancelEditing;
        ctrl.sendReaction = sendReaction;
        ctrl.removeReaction = removeReaction;
        ctrl.onToggleReactionsList = onToggleReactionsList;
        ctrl.showReactionsViewer = showReactionsViewer;
        ctrl.showOptions = showOptions;
        ctrl.getCommentIdKey = getCommentIdKey;
        ctrl.paging = paging;
        ctrl.commentItemRendered = commentItemRendered;
        ctrl.editComment = editComment;
        ctrl.textareaRendered = textareaRendered;
        ctrl.getExpandFlag = getExpandFlag;
        
        function init() {
            if (ctrl.newComment) {
                ctrl.newComment.text = '';
                ctrl.newComment.uploadedMedia = [];
            }
        }

        function textareaRendered() {
            PopupWrapperService.fixKeyboardShift($element.find('.textarea textarea')[0]);
        }

        function onPostComment() {
            if (!ctrl.edditingComment) {
                postComment();
            } else {
                postEditComment()
            }
        }

        function postComment() {
            $q.when(ctrl.onPost(ctrl.replyingMessage), newComments => {
                $timeout(function () {
                    var $commentsList = $element.find('.comments-list');
                    if ($commentsList.length) {
                        if (!ctrl.replyingMessage) {
                            $commentsList[0].scrollTop = $commentsList[0].scrollHeight;
                        } else if (ctrl.replyingMessage && newComments) {
                            var $newComment = $element.find('.comments-list li[data-id=' + newComments[newComments.length - 1][getCommentIdKey()] + ']');
                            $commentsList[0].scrollTop = $newComment[0] && $newComment[0].offsetTop - 20;
                        }
                    }
                    ctrl.replyingMessage = null;
                }, 100)
            }, showContentSafetyWarningPopup);

            if (ctrl.replyingMessage) {
                var parentId = ctrl.replyingMessage['Parent' + ctrl.getCommentIdKey()];
                if (parentId) {
                    ctrl.expandThread(ctrl.comments.find(function (comment) {
                        return comment[getCommentIdKey()] === parentId
                    })[getCommentIdKey()]);
                } else {
                    ctrl.expandThread(ctrl.replyingMessage[ctrl.getCommentIdKey()]);
                }
            }
        }

        function postEditComment() {
            $q.when(ctrl.onEditComment(), () => {
                cancelEditing();
            }, showContentSafetyWarningPopup);
        }

        function showContentSafetyWarningPopup(resp) {
            if (resp?.data?.ShowContentSafetyWarning) {
                ContentSafetyWarningService
                    .openPopup(resp.data.SeverityLevel, resp.data.Action, 'COMMENTS.EDIT_COMMENT', () => {
                        ctrl.newComment.IgnoreContentSafetyWarning = true;
                        onPostComment();
                        delete ctrl.newComment.IgnoreContentSafetyWarning;
                    });
            } else {
                return resp
            }
        }

        function like(comment) {
            if (!comment.ReactionTypeId) {
                // Add reaction
                ctrl.sendReaction(REACTION_TYPE_ID.LIKE, comment).then(function () {
                    comment.ReactionTypeId = REACTION_TYPE_ID.LIKE;
                    comment.TotalReactions++;
                });
            } else {
                // Remove
                ctrl.removeReaction(comment).then(function () {
                    comment.ReactionTypeId = null;
                    comment.TotalReactions--;
                });
            }
        }

        function reply(comment) {
            if (ctrl.replyingMessage) {
                return false;
            }

            ctrl.replyingMessage = comment;

            $timeout(function () {
                $element.find('.comments-footer textarea').focus();
            })
        }

        function clearReplyingMessage() {
            ctrl.edditingComment = false;
            ctrl.replyingMessage = null;
        }

        function cancelEditing() {
            ctrl.edditingComment = false;
            ctrl.newComment.text = '';
            ctrl.newComment.mentions = [];
            ctrl.newComment.uploadedMedia = [];

            delete ctrl.newComment.socialCommentId;
            delete ctrl.newComment.contentCommentId;
            delete ctrl.newComment.serviceFormFillingCommentId;
            delete ctrl.newComment.serviceFormStageCommentId;
            delete ctrl.newComment.issueCommentId;
            delete ctrl.newComment.ServiceFormFeedbackCommentId;
        }

        function sendReaction(reactionTypeId, comment) {
            return $http.post('/' + ctrl.module + '/CommentReaction/', {
                CommentId: getCommentId(comment), ReactionTypeId: reactionTypeId
            });
        }

        function removeReaction(comment) {
            return $http.post('/' + ctrl.module + '/RemoveCommentReaction/?commentId=' + getCommentId(comment));
        }

        function onToggleReactionsList(state) {
            ctrl.showReactionsMessage = state;
        }

        function onMediaUpdated(media) {
            ctrl.newComment.uploadedMedia.push(media);
        }

        function removeMedia(index) {
            ctrl.newComment.uploadedMedia.splice(index, 1);
        }

        function getCommentId(comment) {
            // Will be updated for other modules in future
            return ctrl.module === 'ContentModule' ? comment.ContentCommentId : comment.SocialCommentId;
        }

        function showReactionsViewer(comment) {
            ReactionsService.openReactionsViewer(getCommentId(comment), ctrl.settings?.AllowExtendedCommentReactions, ctrl.module, true);
        }

        function showOptions(comment, event) {
            if (event.target && (event.target.className.includes('post-tag') || event.target.className.includes('post-hashtag'))) {
                return;
            }

            var buttons = [];
            // Report post

            if (comment.IsEditable || comment.IsMyComment) {
                buttons.push({
                    text: 'COMMENTS.EDIT', icon: 'edit', onClick: function () {
                        ctrl.editComment(comment)
                    }
                });
            }

            if (Page.getSettings()?.ContentReportingEnabled) {
                buttons.push({
                    text: 'COMMENTS.REPORT', icon: 'exclamation-circle', onClick: function () {
                        ctrl.onReport(comment);
                    }
                });
            }

            if (comment.IsDeleteable || comment.IsMyComment) {
                buttons.push({
                    text: 'COMMENTS.DELETE', icon: 'delete', iconClass: 'red', onClick: function () {
                        ctrl.onDelete(comment);
                    }
                })
            }

            var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {
                name: 'comments',
                token: ctrl.moduleToken
            });
            actionSheet.show();
        }

        function paging() {
            if (typeof ctrl.onLazyLoad === 'function') {
                ctrl.onLazyLoad();
            }
        }

        function getCommentIdKey() {
            switch (ctrl.module) {
                case 'Social':
                    return 'SocialCommentId';
                case 'ContentModule':
                    return 'ContentCommentId';
                case 'TaskManagement':
                    return 'IssueCommentId';
                case 'ServiceForm':
                    return 'ServiceFormFillingCommentId';
                case 'ServiceFormStage':
                    return 'ServiceFormStageCommentId';
                case 'ServiceFormFeedback':
                    return 'ServiceFormFeedbackCommentId';
            }
        }

        function expandThread(commentId) {
            ctrl.expandThreadState[commentId] = true;
        }
        function getExpandFlag(comment) {
            if (!comment.expandReplies) {
                comment.expandReplies = comment.ChildComments?.length &&
                    comment.ChildComments.find(child => child[ctrl.getCommentIdKey()] === Number(ctrl.commentId));
            }
            
            return comment.ChildComments;
        }

        function commentItemRendered() {
            commentItemRenderCount++;
            if (commentItemRenderCount === document.getElementsByTagName('comment-item')?.length) {
                if (ctrl.commentId) {
                    const scrollToCommentPosition = $element.find('[data-id=' + ctrl.commentId + ']').position().top,
                        scrollFunc = () => {
                            $scope.$apply(() => {
                                delete ctrl.commentId;
                            })
                            $element.off('click touchstart', scrollFunc);
                            $element.find('.scroll-container').off('scroll', scrollFunc);
                        };
                    $element.find('.comments-list').scrollTop(scrollToCommentPosition - 10);
                    $timeout(() => {
                        $element.on('click touchstart', scrollFunc);
                        $element.find('.scroll-container').on('scroll', scrollFunc);
                    }, 100);
                } else {
                    scrollToBottom()
                }

                delete ctrl.commentItemRendered;
            }
        }

        function scrollToBottom() {
            if ($element.find('comment-item:last').length) {
                const lastCommentPosition = $element.find('comment-item:last').position().top
                $element.find('.comments-list').scrollTop(lastCommentPosition);
            }
        }

        function editComment(comment) {
            ctrl.edditingComment = true;
            if (comment.TaggedDepartments && comment.TaggedDepartments.length
                || comment.TaggedUsers && comment.TaggedUsers.length
                || comment.TaggedUserGroups && comment.TaggedUserGroups.length
            ) {
                ctrl.newComment.mentions = comment.TaggedDepartments.concat(comment.TaggedUsers, comment.TaggedUserGroups);
                $scope.$broadcast('mention:reInit', ctrl.newComment.mentions);
            }

            ctrl.newComment.text = comment._currentLocale ?
                CommentsService.decodeHtml(comment.originalText || comment._currentLocale.Text) :
                CommentsService.decodeHtml(comment.originalText || comment.Text);

            ctrl.newComment.uploadedMedia = _.cloneDeep(comment.MediaList);

            $timeout(function () {
                $element.find('.input-wrapper ')
            });

            comment.ServiceFormFeedbackCommentId && (ctrl.newComment.ServiceFormFeedbackCommentId = comment.ServiceFormFeedbackCommentId);
            comment.SocialCommentId && (ctrl.newComment.socialCommentId = comment.SocialCommentId);
            comment.ContentCommentId && (ctrl.newComment.contentCommentId = comment.ContentCommentId);
            comment.ServiceFormFillingCommentId && (ctrl.newComment.serviceFormFillingCommentId = comment.ServiceFormFillingCommentId);
            comment.ServiceFormStageCommentId && (ctrl.newComment.serviceFormStageCommentId = comment.ServiceFormStageCommentId);
            comment.IssueCommentId && (ctrl.newComment.issueCommentId = comment.IssueCommentId);
        }
    }
})();
