(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('backgroundImage', function () {
        return function (scope, element, attrs) {
            attrs.$observe('backgroundImage', function (value) {
                if (angular.isString(value) && value.length && value[0] !== '?' && (value.startsWith('http') || value.startsWith('/'))) {
                    element.css({
                        'background-image': bgImageGen(value),
                        'background-size': 'cover',
                        'background-position': 'center'
                    });
                } else if(angular.isString(value) && value.length && value.startsWith('data:image')) {
                    element.css({
                        'background-image': 'url(' + value + ')',
                        'background-size': 'cover',
                        'background-position': 'center'
                    })
                } else {
                    element.css({});
                }
            });

            function bgImageGen(val) {
                if (attrs['addBackground'] === 'true') {
                    return element[0].style.backgroundImage + ', url(' + val + ')';
                } else {
                    return 'url(' + val + ')';
                }
            }
        };
    });
})();