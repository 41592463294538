(() => {
    'use strict';

    angular
        .module('App')
        .component('moduleGroupNotificationsSettings', {
            template: require('./ModuleGroupNotificationsSettingsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$translate', 'MODULE_ID', ModuleNotificationSettingsController],
            bindings: {
                module: '='
            }
        });

    function ModuleNotificationSettingsController($http, $translate, MODULE_ID) {
        var ctrl = this;
        ctrl.updateModuleGroupSettings = updateModuleGroupSettings;
        ctrl.updateModuleSettings = updateModuleSettings;
        ctrl.isChanged = isChanged;
        ctrl.isModuleChanged = isModuleChanged;
        ctrl.temp = true;

        ctrl.$onInit = init;

        function init() {
            ctrl.isLoading = true;

            if (ctrl.module.ModuleId === MODULE_ID.SOCIAL) {
                $translate('NOTIFICATIONS.MODULE.SOCIAL_GROUPS').then(function (translation) {
                    ctrl.groupsTitle = translation;
                });
            }

            loadSettings();
        }

        function loadSettings() {
            $http.get('/ModuleGroup/ModuleGroupUserNotificationsByAccountId/?accountModuleId=' + ctrl.module.AccountModuleId).then((response) => {
                ctrl.moduleGroups = response.data;
            }).catch(() => {
                ctrl.moduleGroups = [];
                ToastFactory.errorTranslated('ERROR.GENERAL');
            }).finally(() => {
                ctrl.isLoading = false;
            });
        }

        function updateModuleGroupSettings(group) {
            group.AllowPushNotification = group.AllowNotification;
            $http.post('/Profile/SaveNotificationSettingsForAccountModuleGroup', {
                accountModuleId: group.AccountModuleId,
                moduleGroupId: group.ModuleGroupId,
                allowNotification: group.AllowNotification,
                allowPushNotification: group.AllowPushNotification
            })
        }


        function isChanged(group) {
            if (ctrl.isNative) {
                return group.AllowNotification !== group.NotificationsEnabledModuleSettings
                    || group.AllowPushNotification !== group.PushNotificationsEnabledModuleSettings;
            } else {
                return group.AllowNotification !== group.NotificationsEnabledModuleSettings;
            }
        }

        function updateModuleSettings(module) {
            module.AllowPushNotification = module.AllowNotification;
            $http.post('/Profile/SaveNotificationSettingsForAccountModule', {
                accountModuleId: module.AccountModuleId,
                allowNotification: module.AllowNotification,
                allowPushNotification: module.AllowPushNotification
            })
        }

        function isModuleChanged(module) {
            if (ctrl.isNative) {
                return module.AllowNotification !== module.NotificationsEnabledModuleSettings
                    || module.AllowPushNotification !== module.PushNotificationsEnabledModuleSettings;
            } else {
                return module.AllowNotification !== module.NotificationsEnabledModuleSettings;
            }
        }
    }
})();