(function () {
    'use strict';

    var app = angular.module('App');

    app.directive('mediaonload', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                element.bind('load error', function() {
                    scope.$apply(attrs.mediaonload);
                });
            }
        };
    })
})();