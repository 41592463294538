(() => {
    'use strict';

    window.Tribute = require('tributejs/dist/tribute')

    angular
        .module('App')
        .component('richMentionTextarea', {
            template: require('./RichMentionTextareaComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$scope', '$compile', '$filter', 'Page', 'Profile', 'MentionService', RichMentionTextareaController],
            bindings: {
                model: '=',
                placeholder: '@',
                enableMentions: '<',
                enableHashTags: '<',
                filterByDepartment: '<',
                socialGroupId: '<',
                groupsCount: '<',
                moduleToken: '<',
                module: '@',
                isRequired: '<',
                onKeyboardOpen: '<',
                scrollableContainer: '@',
                limitOptions: '<',
                disabled: '<'
            }
        })

    function RichMentionTextareaController($q, $scope, $compile, $filter, Page, Profile, MentionService) {
        const ctrl = this;
        let initedEditor, loadData = {
            users: [],
            tags: [],
        };

        ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
        ctrl.uploadParams = {
            AccountToken: Profile.getProfile().AccountToken, UserToken: Profile.getProfile().UserToken
        }

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onTextareaInit = onTextareaInit;

        function init() {
            if (ctrl.enableMentions || ctrl.enableHashTags) {
                if (ctrl.module !== 'Social' || ctrl.socialGroupId || ctrl.groupsCount === 0) {
                    loadData = getDataLoadObj();
                } else {
                    const removeWatch = $scope.$watch('ctrl.socialGroupId', newVal => {
                        if (newVal) {
                            loadData = getDataLoadObj();
                            removeWatch();
                            enableEdition();
                            initMention(initedEditor);
                        }
                    })
                }
            }
        }

        function onTextareaInit(editor) {
            initedEditor = editor;

            if (ctrl.enableMentions || ctrl.enableHashTags) {
                if (ctrl.module !== 'Social' || ctrl.socialGroupId || ctrl.groupsCount === 0) {
                    initMention(initedEditor);
                    enableEdition();
                }
            } else {
                enableEdition();
            }
        }
        
        function enableEdition() {
            !ctrl.disabled && initedEditor.edit.on();
            if (initedEditor.edit.isDisabled()) {
                const removeWatchDisabled = $scope.$watch('ctrl.disabled', (newValue, oldValue) => {
                    if (newValue !== oldValue && !newValue) {
                        initedEditor.edit.on();
                        removeWatchDisabled();
                    }
                })
            }
        }

        function initMention(editor) {
            const tributeCollection = [];

            if (ctrl.enableMentions) {
                tributeCollection.push(getOptions('@', loadData.users));
            }

            if (ctrl.enableHashTags) {
                tributeCollection.push(getOptions('#', loadData.tags));
            }

            const tribute = new Tribute({
                collection: tributeCollection,
                allowSpaces: true,
                noMatchTemplate: () => {
                    return `<li class="no-match">${$filter('translate')('RTE.MENTION_NO_MATCH')}</li>`
                }
            })

            tribute.attach(editor.el);

            editor.events.on('keydown', function (e) {
                if (e && e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
                    return false;
                }
                if (e && (e.which === FroalaEditor.KEYCODE.DELETE || e.which === FroalaEditor.KEYCODE.BACKSPACE)) {
                    removeMention();
                }
            }, true);
        }

        function removeMention() {
            const range = window.getSelection().getRangeAt(0);

            if (range.collapsed) {
                const parentEl = range.startContainer.parentElement,
                    shouldDelete = ['USER-MENTION', 'USER-GROUP-MENTION', 'DEPARTMENT-MENTION', 'HASH-TAG']
                        .some(tag => parentEl.tagName === tag);

                if (shouldDelete) {
                    parentEl.remove();
                }
            }
        }

        function getOptions(trigger, values) {
            const options = {
                trigger: trigger,
                values: function (text, cb) {
                    $scope.$apply(() => {
                        if (!ctrl.usersDataLaoded) {
                            ctrl.userLoading = true;
                        }
                    })
                    values.then(data => {
                        ctrl.usersDataLaoded = true
                        ctrl.userLoading = false;
                        cb(data);
                    })
                },
                menuItemLimit: 50,
                menuShowMinLength: 1,
                requireLeadingSpace: true,
            }
            if (trigger === '@') {
                options.lookup = item => {
                    return (item.UserName ? item.UserName : '') +
                        (item.DepartmentId ? item.DepartmentName : '') +
                        (item.UserGroupName ? item.UserGroupName : '')
                };
                options.menuItemTemplate = item => {
                    const text = getMentionItemText(item),
                    image = item.original.Image ? `<img src="${item.original.Image.ImageFormats.W100}"/>` : '';
                    return `${image}
                            <div class="right">${text}</div>`;
                };
                options.selectTemplate = item => {
                    if (item) {
                        const {UserName, UserToken, UserGroupToken, UserGroupName, DepartmentName, DepartmentToken}
                            = item.original;

                        if (UserToken)
                            return `<user-mention translate="no" class="fr-deletable" token="${UserToken}">@${UserName}</user-mention>`
                        if (UserGroupToken)
                            return `<user-group-mention translate="no" class="fr-deletable" token="${UserGroupToken}">@${UserGroupName}</user-group-mention>`
                        if (DepartmentToken)
                            return `<department-mention translate="no" class="fr-deletable" token="${DepartmentToken}">@${DepartmentName}</department-mention>`
                    }
                }
            }
            if (trigger === '#') {
                options.lookup = item => item.Name;
                options.menuItemTemplate = item => {
                    const {Featured, Name, PostsCount} = item.original;
                    return `<div class="tag-label">#</div>
                            <div class="right">
                                <span class="title">#${Name} ${Featured ? `<span class="fal fa-star"></span>` : ``}</span>
                                ${PostsCount > 0 ? `<span class="rl-subheadline">${PostsCount}</span>` : ''}
                            </div>`;
                };
                options.selectTemplate = item =>
                    `<hash-tag translate="no" class="fr-deletable">#${item.original.Name}</hash-tag>`
            }

            return options;
        }

        function getMentionItemText(item) {
            if (item.original.UserName)
                return `<span class="title">${item.string}</span>
                        <span class="rl-subheadline">${item.original.DepartmentName}</span>`
            if (item.original.DepartmentId)
                return `<span class="title">${item.string}</span>`
            if (item.original.UserGroupName)
                return `<span class="title">${item.string}</span>`
        }

        function getDataLoadObj() {
            return {
                users: ctrl.enableMentions && loadDataForMentions(),
                tags: ctrl.enableHashTags && MentionService.getHashTags(ctrl.moduleToken)
            }
        }

        function loadDataForMentions() {
            if (ctrl.module === 'Social') {
                return MentionService.getSocialUsers(ctrl.socialGroupId, false, ctrl.moduleToken)
            } else if (ctrl.module === "ContentModule") {
                return MentionService.getContentUsers(ctrl.postId, false, ctrl.moduleToken)
            }
        }

        function destroy() {
            //TODO: remove on popup close
            angular.element('.tribute-container').remove();
        }
    }
})();