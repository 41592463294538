(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationsSettingsList', {
            template: require('./NotificationsSettingsListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$scope', '$http', 'ToastFactory', 'NativeFactory', 'Profile',
                'TemplateFactory', NotificationSettingsListController],
            bindings: {
                settings: '=',
                onOpenModule: '<',
                isLoading: '<'
            }
        });

    function NotificationSettingsListController($rootScope, $timeout, $scope, $http, ToastFactory, NativeFactory,
                                                Profile, TemplateFactory) {
        
        var ctrl = this, globalInitializing = true, globalSettingsWatcher;

        ctrl.isNative = Profile.getProfile().IsNativeAppEnabled;
        ctrl.isNativeDesign = TemplateFactory.getTemplate().key === 'native';

        ctrl.$onInit = init;
        ctrl.getLabel = getLabel;
        ctrl.updateModuleSettings = updateModuleSettings;
        ctrl.isChanged = isChanged;
        ctrl.openModuleGroupsSettings = openModuleGroupsSettings;
        ctrl.isRTL = $rootScope.isRTL;

        $scope.$watch('ctrl.settings.GlobalSettings.AllowPushNotification', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                updateNativePush();
            }
        });

        $scope.$watch('ctrl.settings.GlobalSettings.AllowNotification', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                updateNativePush();
            }
        });

        function init() {
            initGlobalSettingsWatcher();
        }

        function initGlobalSettingsWatcher() {
            globalSettingsWatcher = $scope.$watch('ctrl.settings.GlobalSettings', function (newVal, oldVal) {
                if (globalInitializing) {
                    if(!ctrl.isLoading) {
                        globalInitializing = false;
                    }
                } else {
                    globalInitializing = true;
                    globalSettingsWatcher();

                    ctrl.settings.GlobalSettings.AllowPushNotification =
                        !ctrl.settings.GlobalSettings.AllowNotification ? false
                            : ctrl.settings.GlobalSettings.AllowPushNotification;

                    $http.post('Profile/SaveGlobalNotificationSettings', {
                        allowNotification: ctrl.settings.GlobalSettings.AllowNotification,
                        allowPushNotification: ctrl.isNative ? ctrl.settings.GlobalSettings.AllowPushNotification : ctrl.settings.GlobalSettings.AllowNotification
                    }).then(initGlobalSettingsWatcher).catch(function (err) {
                        onError(err, oldVal)
                    })
                }
            }, true);
        }

        function onError(err, oldVal) {
            ToastFactory.error(err);
            $timeout(function () {
                ctrl.settings.GlobalSettings.AllowNotification = oldVal.AllowNotification;
                ctrl.settings.GlobalSettings.AllowPushNotification = oldVal.AllowPushNotification;
                initGlobalSettingsWatcher();
            }, 500);
        }


        function GoToModuleSettings(module) {
            ctrl.onOpenModule && ctrl.onOpenModule(module)
        }

        function getLabel(module) {
            if (ctrl.isNative) {
                if (module.AllowNotification && module.AllowPushNotification) {
                    return 'NATIVE_NOTIFICATIONS.MODULE.NOTIFICATIONS_AND_PUSH';
                }
                if (module.AllowNotification && !module.AllowPushNotification) {
                    return 'NATIVE_NOTIFICATIONS.MODULE.NOTIFICATIONS'
                }
                if (!module.AllowNotification && !module.AllowPushNotification) {
                    return 'NATIVE_NOTIFICATIONS.MODULE.NONE'
                }
            } else {
                if (module.AllowNotification) {
                    return 'NATIVE_NOTIFICATIONS.MODULE.NOTIFICATIONS'
                } else {
                    return 'NATIVE_NOTIFICATIONS.MODULE.NONE'
                }
            }
        }

        function updateNativePush() {
            NativeFactory.push.togglePush((ctrl.settings.GlobalSettings.AllowPushNotification || ctrl.settings.GlobalSettings.AllowNotification));
        }

        function isChanged(module) {
            if (ctrl.isNative) {
                return module.AllowNotification !== module.NotificationsEnabledModuleSettings
                    || module.AllowPushNotification !== module.PushNotificationsEnabledModuleSettings;
            } else {
                return module.AllowNotification !== module.NotificationsEnabledModuleSettings;
            }
        }

        function openModuleGroupsSettings(module) {
            ctrl.onOpenModule && ctrl.onOpenModule(module)
        }

        function updateModuleSettings(module) {
            module.AllowPushNotification = module.AllowNotification;
            $http.post('/Profile/SaveNotificationSettingsForAccountModule', {
                accountModuleId: module.AccountModuleId,
                allowNotification: module.AllowNotification,
                allowPushNotification: module.AllowPushNotification
            })
        }
    }
})();