(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettingsSecurityMfa', {
                        parent: 'base',
                        url: '/profileSettings/account/security/mfa',
                        template: '<div class="profile-settings-edit-page">' +
                            '<profile-settings-security-mfa class="view-page narrow">' +
                            '</profile-settings-security-mfa></div>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'ProfileSettingsSecurityMfaController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsSecurityMfaController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('PROFILE.MFA.SETTINGS.PAGE_TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('profileSettingsSecurity');
                }
            }]);
})();