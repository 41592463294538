(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('lineChart', ['$log', '$http', 'Page', 'Profile', 'ChartHelper',
        function ($log, $http, Page, Profile, ChartHelper) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Chart/Templates/LineChart.tpl.html',
                scope: {
                    data: '='
                },
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.chartIsLoading = true;
                    scope.chartLabel = scope.data.Label;
                    scope.noChart = false;

                    var $canvas = $element.find("canvas");

                    var ctx = $canvas[0].getContext("2d");
                    var options = {
                        scaleShowGridLines: false,
                        showLine: false,
                        bezierCurve: true,
                        datasetFill: true,
                        scaleLineWidth: 2,
                        showScale: true,
                        animation: false,
                        scaleLabel: function (valueObj) {
                            return ChartHelper.formatNumber(valueObj.value, 0, ',', '.');
                        }
                    };

                    // graph defaults
                    var graphData = {
                        labels: [],
                        datasets: []
                    };

                    var filterDepartmentId = Profile.getProfile().FilterDepartmentId;
                    var hasMultipleDepartments = Profile.getProfile().HasMultiDepartment;

                    var url = Page.getSettings().BasicAPIDomain + "/DW/ChartData/GetData/?ChartDashboardItemToken=" + scope.data.ChartDashboardItemToken + "&usertoken=" + Profile.getProfile().UserToken;
                    if (hasMultipleDepartments) { url += "&departmentid=" + filterDepartmentId; }


                    // get data series from external source
                    $.getJSON(url,
                        function (response) {
                            if (response.DataSeries.length > 0) {
                                //console.log(response);
                                ShowChartWithData(response);
                            } else {
                                // No chart data
                                scope.noChart = true;
                            }
                            scope.chartIsLoading = false;
                            scope.$apply();
                    });

                    function ShowChartWithData(data) {
                        // add data sets
                        var iLength = data.DataSeries.length;
                        for (var i = 0; i < iLength; i++) {
                            var mainColor = ChartHelper.chartColor(i);
                            var newDataSerie = {
                                label: "n/a",
                                fillColor: "rgba(0,0,0,0.1)",
                                strokeColor: mainColor,
                                pointColor: "#fff",
                                pointStrokeColor: mainColor,
                                pointHighlightFill: mainColor,
                                pointHighlightStroke: "#fff",
                                data: []
                            }

                            var dLength = data.DataSeries[i].Data.length;
                            for (var d = 0; d < dLength; d++) {
                                var item = data.DataSeries[i].Data[d];
                                newDataSerie.data.push(item.Value);
                            }

                            graphData.datasets.push(newDataSerie);
                        }

                        // add labels
                        graphData.labels = data.Labels;

                        // change settings based on datasets
                        options.datasetFill = (graphData.datasets.length > 1) ? false : true;

                        // create chart
                        var myLineChart = new Chart(ctx).Line(graphData, options);
                    }
                }
            }
        }
    ]);
})();