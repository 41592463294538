(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('employeeEvaluationView', {
                    parent: 'base',
                    url: '/employeeEvaluationView/:token/:extra',
                    templateUrl: '/Templates/Modules/EmployeeEvaluation/EmployeeEvaluationView.html',
                    resolve: {
                        employeeEvaluationData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/EmployeeEvaluation/GetView/' + $stateParams.token + '?reportToken=' + $stateParams.extra);
                            } return;
                        }]
                    },
                    controller: 'EmployeeEvaluationViewController as EmployeeEvaluationViewCtrl'
                });
        }
    ]);

    app.controller('EmployeeEvaluationViewController', ['Page', '$scope', '$http', '$stateParams', 'toastr', '$translate', 'employeeEvaluationData', 'AttachmentsViewerService', 'BasicHelper', 'events',
        function (Page, $scope, $http, $stateParams, toastr, $translate, employeeEvaluationData, AttachmentsViewerService, BasicHelper, events) {
            var ctrl = this;
            Page.showBackButton('employeeEvaluation', $stateParams.token, 'employee');

            // Init
            Init();
            function Init() {
                Page.setTitle(employeeEvaluationData.data.PageTitle);

                $scope.report = employeeEvaluationData.data.Report;
                $scope.now = new Date();

                if ($scope.report != null) {
                    $scope.report.ReportDate = moment($scope.report.IntervalDateFrom);
                    $scope.report.SupervisorConfirmationDate = moment($scope.report.SupervisorConfirmationDate);
                    $scope.report.Checked = ReportStatus($scope.report);
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateGoBack('employeeEvaluation', $stateParams.token, 'employee');
                });
            }

            // Methods
            function ReportStatus(report) {
                var status = false;
                if ($scope.isSupervisor) {
                    status = report.SupervisorConfirmed;
                } else {
                    status = report.EmployeeConfirmed;
                }
                return status;
            }

            function ReportSwipe(report) {
                var status = ReportStatus(report);

                $http.post('/EmployeeEvaluation/SwipeEvaluation/' + $stateParams.token, {
                    EmployeeEvaluationIntervalId: report.EmployeeEvaluationIntervalId,
                    Confirm: !status,
                    EmployeeUserId: report.EmployeeUserId
                }).then(function (response) {
                    if (response.data.Report != null) {
                        report.SupervisorConfirmed = response.data.Report.SupervisorConfirmed;
                        report.EmployeeConfirmed = response.data.Report.EmployeeConfirmed;
                        report.EmployeeEvaluationReportStatus = response.data.Report.EmployeeEvaluationReportStatus;
                        report.EmployeeEvaluationReportToken = response.data.Report.EmployeeEvaluationReportToken;
                    }

                    var newStatus = ReportStatus(report);
                    report.Checked = ReportStatus(report);

                    if (newStatus == status) {
                        $translate('ERROR.CONTACT').then(function (translation) {
                            toastr.error(translation);
                        });
                    }
                }, function () {
                    $translate('ERROR.CONTACT').then(function (translation) {
                        toastr.error(translation);
                    });
                });
            }

            function OpenAttachment() {
                var media = $scope.report.AttachmentMediaId;
                if (media != null) {
                    if (media.MediaType == 1 || media.MediaType == 2) {
                        // Show in viewer
                        AttachmentsViewerService.openViewerWithMedia([media]);
                    } else {
                        // Download file
                        window.location.href = media.DownloadUrl;
                    }
                }
            }

            // Bindings
            $scope.BasicHelper = BasicHelper;
            $scope.ReportStatus = ReportStatus;
            $scope.ReportSwipe = ReportSwipe;
            $scope.OpenAttachment = OpenAttachment;
        }
    ]);
})();