(function () {
    'use strict';

    angular
        .module('App')
        .component('showMoreLess', {
            template: require('./ShowMoreLessComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$scope', 'ShowMoreLessFactory', ShowMoreLessController],
            bindings: {
                limit: '<',
                text: '<',
                readMoreLabel: '<',
                onReadMore: '<',
            }
        });

    function ShowMoreLessController($element, $timeout, $scope, ShowMoreLessFactory) {
        const ctrl = this, defaultLimit = 150;

        ctrl.$onInit = init;
        ctrl.readMore = readMore;
        ctrl.limit = ctrl.limit || defaultLimit;

        function init() {
            $scope.$watch('ctrl.text', () => {
                if (ctrl.text) {
                    ctrl.showTruncated = ctrl.showTruncated !== undefined ?
                        ctrl.showTruncated : (ctrl.text.length > ctrl.limit);
                    ctrl.truncatedText = ShowMoreLessFactory
                        .getStringByPosition(ctrl.text, ctrl.limit || defaultLimit) + '...';
                }
            })
        }

        function readMore() {
            if (ctrl.onReadMore) {
                ctrl.onReadMore();
            } else {
                ctrl.showTruncated = false;
            }
        }
    }
})();