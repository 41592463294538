(function () {
    'use strict';

    angular.module('App')
        .factory('SubtasksListService',
            ['$http', SubtasksListService]);

    function SubtasksListService($http) {

        return {
            sortSubtasks: sortSubtasks,
            getSubtaskDetails: getSubtaskDetails
        };


        function getSubtaskDetails(issueToken) {
            return $http.get('/TaskManagement/SubtaskPreview/' + issueToken);
        }

        function sortSubtasks(issueToken, subtasksTokens) {
            return $http.post('/TaskManagement/SortSubtasks', { token: issueToken, subtasksTokens: subtasksTokens });
        }
    }
})();