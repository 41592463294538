(() => {
    'use strict';

    angular
        .module('App')
        .component('moduleSearchFilters', {
            template: require('./ModuleSearchFiltesComponent.html'),
            controllerAs: 'ctrl',
            controller: [ModuleSearchFiltersController],
            bindings: {
                onSearch: '<',
                searchString: '=?',
                searchPlaceholder: '@',
                searchFocused: '<'
            },
            transclude: true,
        })

    function ModuleSearchFiltersController() {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            if (ctrl.searchFocused) {
                document.getElementById("search-input").focus();
            }
        }
    }
})();