(() => {
    'use strict';

    angular
        .module('App')
        .component('searchTasks', {
            template: require('./SearchTasksComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', 'TaskManagementRestService', 'TaskManagementRenderService', 'ResponsiveService',
                SearchTasksController],
            bindings: {
                onSearch: '<',
                searchInProgress: '=?',
                issues: '=?',
            }
        });

    function SearchTasksController($rootScope, $scope, TaskManagementRestService, TaskManagementRenderService, ResponsiveService) {
        var ctrl = this, updateListListener;

        ctrl.$onInit = init;
        ctrl.search = search;
        ctrl.$onDestroy = destroy;

        ctrl.isDesktop = ResponsiveService.isDesktop();
        
        function init() {
            ctrl.isSearched = false;

            updateListListener = $rootScope.$on('TaskManagement:taskUpdated', function (event, data) {
                const issue = ctrl.issues?.find(function (issue) {
                    return issue.IssueId === data.IssueId;
                });

                if (issue) {
                    ctrl.issues[ctrl.issues.indexOf(issue)] = data;
                }

                ctrl.issues?.sort(function (a, b) {
                    return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                        new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                });
            });
        }

        function search(searchString) {
            if (!ctrl.isSearchInProgress && searchString) {
                ctrl.onSearch(searchString);
                ctrl.isSearchInProgress = true;
                TaskManagementRestService.findIssues(searchString).then(function (issues) {
                    ctrl.issues = TaskManagementRenderService.mergeIssuesWithCategories(issues.Tasks);
                    ctrl.isSearchInProgress = false;
                    ctrl.isSearched = true;
                }).catch(function (er) {
                    ctrl.isSearchInProgress = false;
                    ctrl.isSearched = true;
                });
            } else {
                ctrl.issues = [];
                ctrl.onSearch(searchString);
                ctrl.isSearched = false;
            }
        }

        function destroy() {
            updateListListener && updateListListener();
        }
    }
})();