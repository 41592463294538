(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('helpPopup', ['$timeout',
        function ($timeout) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/HelpPopup.tpl.html',
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.Data.Shown = true;

                    scope.ClosePopupInternal = function () {
                        scope.Data.Shown = false;
                        $timeout(function () {
                            scope.PopupClosed();
                            $element.remove();
                            scope.$destroy();
                        }, 300);
                    };
                }
            }
        }
    ]);
})();