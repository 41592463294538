(function () {
    'use strict';

    angular
        .module('App')
        .component('updateSubtaskPopup', {
            template: require('./UpdateSubtaskPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$stateParams', '$element', '$timeout', 'Profile', 'Page',
                'UpdateSubtaskPopupService', 'TranslationService', UpdateSubtaskPopupController],
            bindings: {
                subtask: '<',
                parentIssueSubtaskToken: '<'
            }
        });

    function UpdateSubtaskPopupController($scope, $rootScope, $stateParams, $element, $timeout, Profile, Page,
        UpdateSubtaskPopupService, TranslationService) {
        const ctrl = this;
        let popup, translationSubtasksWatcher, subtaskChangeWatcher;

        ctrl.$onInit = init;
        ctrl.onOpen = onOpen;
        ctrl.getTranslation = getTranslation;
        ctrl.save = save;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.close = close;
        ctrl.onLanguageLoaded = onLanguageLoaded;
        ctrl.validateLanguages = validateLanguages;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;
        ctrl.translatedText = [];

        function init() {
            ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
            ctrl.uploadParams = {
                AccountToken: Profile?.getProfile().AccountToken, UserToken: Profile.getProfile().UserToken
            }
        }

        function onOpen() {
            ctrl.isLoading = true;
            if (ctrl.subtask.IssueSubtaskToken) {
                openConfirmUpdateSubtaskPopup(ctrl.subtask.IssueSubtaskToken);
            } else {
                UpdateSubtaskPopupService.getSubtaskCreatePage($stateParams.extra).then(resp => {
                    ctrl.subtask = {
                        ...resp.data,
                        Title: ctrl.subtask.Title,
                        Index: ctrl.subtask.Index,
                        LocaleList: ctrl.subtask.LocaleList,
                        Medias: ctrl.subtask.Medias
                    };
                    ctrl.isLoading = false;
                    ctrl.translatedText = _.cloneDeep(ctrl.subtask.LocaleList);
                    initTranslationWatcher();
                });
            }
        }

        function openConfirmUpdateSubtaskPopup(issueToken) {
            UpdateSubtaskPopupService.getSubtaskEditPage(issueToken).then(resp => {
                ctrl.subtask = resp.data;
                ctrl.isLoading = false;
                ctrl.translatedText = _.cloneDeep(ctrl.subtask.LocaleList);
                initTranslationWatcher();
            });
        }

        function onLanguageLoaded() {
            ctrl.languageLoaded = true;
            if (!ctrl.subtask.LocaleList[0].Title) {
                ctrl.subtask.LocaleList[0].Title = ctrl.subtask.Title;
            }
            initSubtaskChangeWatcher();
        }

        function initTranslationWatcher() {
            translationSubtasksWatcher = $scope.$watch(() => {
                return ctrl.subtask.LocaleList[ctrl.selectedLanguageIndex];
            },
                (newVal) => {
                    const currentItem = ctrl.translatedText?.find((item) => item.CultureId === newVal?.CultureId);
                    ctrl.translateDisabled = !newVal ||
                        ((newVal?.Title ?? '').trim() === (currentItem?.Title ?? '').trim() &&
                            (newVal?.Summary ?? '').trim() === (currentItem?.Summary ?? '').trim());
                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.subtask.LocaleList[ctrl.selectedLanguageIndex].AutoTranslated = false;
                    }
                }, true
            )
        }

        function initSubtaskChangeWatcher() {
            let initializing = true;

            subtaskChangeWatcher = $scope.$watch(() => ctrl.subtask,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.subtask.LocaleList[ctrl.selectedLanguageIndex]?.CultureId;
                const languages = ctrl.subtask.LocaleList
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function applyTranslations(translations) {
            if (translations.translations.length) {
                translations.translations.forEach(translation => {
                    const locale = ctrl.subtask?.LocaleList.find(culture => culture.CultureId === translation.CultureId);

                    locale.Title = translation.Title;
                    locale.InitialTitle = translation.Title;
                    locale.Summary = translation.Summary;
                    locale.InitialSummary = translation.Summary;
                    locale.AutoTranslated = true;
                })
                ctrl.translateDisabled = true;
                ctrl.subtask.LocaleList[ctrl.selectedLanguageIndex].AutoTranslated = false;
                ctrl.translatedText = _.cloneDeep(ctrl.subtask.LocaleList);
            }
        }

        function translate() {
            ctrl.isTranslating = true;
            UpdateSubtaskPopupService.translateSubtask(ctrl.subtask?.LocaleList, ctrl.selectedLanguageIndex)
                .then(applyTranslations)
                .finally(() => {
                    ctrl.isTranslating = false;
                });
        }

        function save() {
            if (ctrl.subtask.IssueSubtaskToken) {
                UpdateSubtaskPopupService.saveUpdatedSubtask(ctrl.subtask, ctrl.parentIssueSubtaskToken).then(resp => {
                    $rootScope.$emit('TaskManagement:subtasksListUpdated', resp.data);
                    ctrl.close();
                });
            } else {
                ctrl.subtask = {
                    ...ctrl.subtask,
                    Title: ctrl.subtask.LocaleList.find(locale => locale.Title).Title,
                    Medias: ctrl.subtask.Medias,
                    LocaleList: ctrl.subtask.LocaleList
                        .filter(locale => locale.Title?.length || locale.Summary?.length)
                        .map(({ CultureId, Title, Summary, AutoTranslated }) => ({ CultureId, Title, Summary, AutoTranslated }))
                }
                $rootScope.$emit('TaskManagement:updatePreliminarySubtasksList', ctrl.subtask);
                ctrl.close();
            }
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            translationSubtasksWatcher && translationSubtasksWatcher();
            popup.remove();
            $element.remove();
            subtaskChangeWatcher && subtaskChangeWatcher();
        }

        function validateLanguages() {
            if (Page.getSettings().CulturesRework) {
                return ctrl.subtask.LocaleList.some(x => x.Title);
            }

            return ctrl.subtask.LocaleList[0].Title;
        }

        function isShowTranslationChangeWarning() {
            return ctrl.subtask.LocaleList
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.Title?.trim() !== item?.Title?.trim() ||
                        lang.Summary?.trim() !== item?.Summary?.trim();
                })
        }
    }
})();
