(function () {
    'use strict';

    var app = angular.module('App');

    app.component('alertMessage', {
        templateUrl: '/Scripts/Components/AlertMessage/AlertMessageComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: [AlertMessageController],
        bindings: {
            type: '@',
            text: '@'
        }
    });

    /*
        alert types:
        alert, warning, confirm 
    */

    function AlertMessageController() {

    }
})();