(function () {
    'use strict';

    angular
        .module('App')
        .component('referenceField', {
            template: require('./ReferenceFieldComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['FIELD_TYPE', 'TimeLocale', ReferenceFieldController],
            bindings: {
                field: '<',
                index: '<'
            }
        });

    function ReferenceFieldController(FIELD_TYPE, TimeLocale) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.createSubTitles = createSubTitles;
        ctrl.getDateValue = getDateValue;


        function init() {
            if (ctrl.field.ReferencedFieldType === FIELD_TYPE.MEDIA) {
                ctrl.field.ParentFillingValues = _.map(ctrl.field.ParentFillingValues, function (item) {
                    return item.Media
                });
            }
        }

        function getDateValue(value) {
            if (value) {
                return value.DateTimeLocaleString;
            }

            return null;
        }

        function createSubTitles(dataListItem) {
            var subTitle = [];
            if (dataListItem.DataListType == 3 && dataListItem.AdditionalValues) // customer 
            {
                if (dataListItem.AdditionalValues.Country) {
                    subTitle.push(dataListItem.AdditionalValues.Country);
                }
                if (dataListItem.AdditionalValues.ZipCode) {
                    subTitle.push(dataListItem.AdditionalValues.ZipCode + " " + dataListItem.AdditionalValues.City);
                }
                if (dataListItem.AdditionalValues.Street) {
                    subTitle.push(dataListItem.AdditionalValues.Street);
                }
            }
            return subTitle;
        }
    }
})();