(() => {
    'use strict';

    angular
        .module('App')
        .component('cardItemBreadcrumb', {
            template: require('./CardItemBreadcrumbComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
            }
        });
})();