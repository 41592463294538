(() => {
    'use strict';

    angular
        .module('App')
        .component('sortedTasks', {
            template: require('./SortedTaskComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', SortedTasksController],
            bindings: {
                issues: '<',
                sortByStart: '<'
            }
        });

    function SortedTasksController($scope) {
        var ctrl = this;

        $scope.$watch('ctrl.issues', function (issues) {
            ctrl.isEmpty = _.isEmpty(ctrl.issues);
            ctrl.issues = issues;
        }, true)
    }
})();