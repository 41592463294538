(() => {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('contentLibrary', {
                    parent: 'base',
                    url: '/contentLibrary/:token/:extra',
                    template: '<content-library-item ' +
                        'item="ctrl.libraryData.item" ' +
                        'content-data="ctrl.libraryData.contentData"' +
                        '></content-library-item>',
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        libraryData: ['$http', '$stateParams', '$state', function ($http, $stateParams, $state) {
                            if ($stateParams.data?.contentData && $stateParams.data?.item) {
                                return $stateParams.data
                            } else {
                                return $http.get(`/ContentModule/GetContent/${$stateParams.token + $stateParams.extra}`).then(resp => {
                                    return {
                                        item: resp.data.Items[0],
                                        contentData: $state.params?.data?.contentData || resp.data,
                                        downloaded: true
                                    }
                                })
                            }
                        }]
                    },
                    controller: 'ContentLibraryController as ctrl'
                })
        }])
        .controller('ContentLibraryController',
            ['$scope', '$http', '$stateParams', 'Page', 'libraryData', ContentLibraryController]
        );


    function ContentLibraryController($scope, $http, $stateParams, Page, libraryData) {
        const ctrl = this;
        ctrl.libraryData = libraryData;
        const currentLocale = libraryData.item.TranslatableEntity?.PreferTranslatedVersion && libraryData.item.TranslatableEntity?.LocalizedAlternativeLocale ?
            libraryData.item.TranslatableEntity?.LocalizedAlternativeLocale : libraryData.item.TranslatableEntity?.LocalizedLocale;
        
        Page.setTitle(currentLocale.Title || libraryData.contentData.PageTitle);
        
        if ($stateParams.backState) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token,
                $stateParams.backState.extra, null, $stateParams.backState.options
            );
        } else {
            Page.showBackButton('content', libraryData.contentData.AccountModuleToken);
        }
    }
})();
