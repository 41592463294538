(() => {
    'use strict';

    angular
        .module('App')
        .component('scrollCalendar', {
            template: require('./ScrollCalendarComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', '$rootScope', 'Page', 'CalendarRenderService', 'CreateUpdateEventService',
                'HeaderButtonsFactory', 'ServiceFormRenderService', 'ModuleSearchService', 'FilterPopupService',
                'ResponsiveService', ScrollCalendarController],
            bindings: {
                defaultView: '<',
                calendars: '<',
                categories: '<',
                feedLink: '<',
                mainColor: '<',
                moderateAllowed: '<',
                timezones: '<',
                timezoneEnabled: '<',
                signupLimitEnabled: '<',
                exploringEnabled: '<',
                pageTitle: '<',
                accountModuleToken: '<'
            }
        });

    function ScrollCalendarController($filter, $rootScope, Page, CalendarRenderService, CreateUpdateEventService,
                                      HeaderButtonsFactory, ServiceFormRenderService, ModuleSearchService,
                                      FilterPopupService, ResponsiveService) {

        let previousView, removeFilterWatcher;
        const ctrl = this;

        ctrl.VIEW = {
            CALENDAR: 1,
            MY_EVENTS: 2,
            EXPLORE: 3,
            SEARCH: 4
        };

        ctrl.LABELS = {
            1: 'CALENDAR.CHANGE_VIEW.CALENDAR',
            2: 'CALENDAR.MY_EVENTS',
            3: 'CALENDAR.EVENT.EXPLORE'
        }

        ctrl.fillingsOverviewPopupId = ServiceFormRenderService.fillingsOverviewPopupId;
        ctrl.isDesktop = ResponsiveService.isDesktop();

        ctrl.$onInit = init;
        ctrl.search = search;
        ctrl.openCreateEvent = openCreateEvent;
        ctrl.changeView = changeView;
        ctrl.getHeaderButtons = getHeaderButtons;
        ctrl.$onDestroy = destroy;

        function init() {
            CalendarRenderService.setCategories(ctrl.categories);

            ctrl.currentView = ctrl.defaultView;
            Page.setSubTitle($filter('translate')(ctrl.LABELS[ctrl.defaultView]));

            if (!ctrl.isDesktop) {
                ctrl.searchSettings = {
                    onSearch: search,
                    onClose: () => {
                        previousView && ctrl.changeView(previousView);
                    },
                    isSpecialDesktopSearch: true
                };
                ModuleSearchService.enableSearch(ctrl.searchSettings);
            }


            HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            removeFilterWatcher = $rootScope.$on('filter-events', () => {
                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
            })

            document.documentElement.style.setProperty('--calendar-accent-color', ctrl.mainColor);
        }

        function search(searchString) {
            if (searchString?.length) {
                if (ctrl.currentView !== ctrl.VIEW.SEARCH) {
                    previousView = ctrl.currentView;
                }
                ctrl.changeView(ctrl.VIEW.SEARCH);
                ctrl.searchString = searchString;
            } else {
                ctrl.changeView(previousView);
            }
        }

        function destroy() {
            removeFilterWatcher && removeFilterWatcher();
            ModuleSearchService.disableSearch();
            HeaderButtonsFactory.resetButtonsList();
            document.documentElement.style.removeProperty('--calendar-accent-color');
            CalendarRenderService.clearData();
        }

        function openCreateEvent() {
            CreateUpdateEventService.openPopup({
                calendars: ctrl.calendars,
                timezones: ctrl.timezones,
                selectedDay: ctrl.currentView === ctrl.VIEW.CALENDAR && ctrl.selectedDay,
                timezoneEnabled: ctrl.timezoneEnabled,
                signupLimitEnabled: ctrl.signupLimitEnabled,
                categories: ctrl.categories,
                accountModuleToken: ctrl.accountModuleToken
            });
        }

        function changeView(view) {
            ctrl.currentView = view;
        }

        function getHeaderButtons() {
            const buttons = [
                    {
                        title: 'CALENDAR.MY_EVENTS', onClick: () => {
                            ctrl.changeView(ctrl.VIEW.MY_EVENTS);
                            Page.setSubTitle($filter('translate')(ctrl.LABELS[ctrl.VIEW.MY_EVENTS]));
                        }, active: ctrl.currentView === ctrl.VIEW.MY_EVENTS
                    },
                    {
                        title: 'CALENDAR.CHANGE_VIEW.CALENDAR', onClick: () => {
                            ctrl.changeView(ctrl.VIEW.CALENDAR);
                            Page.setSubTitle($filter('translate')(ctrl.LABELS[ctrl.VIEW.CALENDAR]));
                        }, active: ctrl.currentView === ctrl.VIEW.CALENDAR
                    }
                ],
                menu = [{
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    caption: 'CALENDAR.CHANGE_VIEW.TITLE',
                    place: 'title',
                    items: buttons
                }];

            if (ctrl.exploringEnabled) {
                buttons.push({
                    title: 'CALENDAR.EVENT.EXPLORE',
                    onClick: () => {
                        ctrl.changeView(ctrl.VIEW.EXPLORE);
                        Page.setSubTitle($filter('translate')(ctrl.LABELS[ctrl.VIEW.EXPLORE]));
                    },
                    active: ctrl.currentView === ctrl.VIEW.EXPLORE
                });
            }

            if (!ctrl.isDesktop) {
                menu.push({
                    icon: 'sliders',
                    activeIcon: 'sliders-active',
                    onClick: openFiltersPopup,
                    place: 'right',
                    badges: CalendarRenderService.getFilters().filterCount,
                    active: !!CalendarRenderService.getFilters().filterCount
                })
                menu.push({
                    icon: 'search',
                    place: 'right',
                })
            }

            return menu
        }

        function openFiltersPopup() {
            FilterPopupService.openFiltersPopup(ctrl.currentView === ctrl.VIEW.CALENDAR);
        }
    }
})();
