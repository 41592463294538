(function () {
    'use strict';

    angular
        .module('App')
        .factory('CreateUpdateTaskService', ['$http', '$stateParams', '$filter', '$rootScope', 'PopupWrapperService',
            'ConfirmPopupService', 'Menu', '$state', CreateUpdateTask]);

    function CreateUpdateTask($http, $stateParams, $filter, $rootScope, PopupWrapperService, ConfirmPopupService, Menu, $state) {

        return {
            openCreateUpdatePopup: openCreateUpdatePopup,
            getUpdatePage: getUpdatePage,
            getCreatePage: getCreatePage,
            createTask: createTask,
            editTask: editTask,
            deleteTask: deleteTask,
            updateNavigationBadges: updateNavigationBadges
        };


        function openCreateUpdatePopup(data) {
            PopupWrapperService.createDynamic('<create-update-task-popup on-popup-registered="popupRegistered"></create-update-task-popup>', {
                popupRegistered: function (popup) {
                    popup.open(data);
                }
            });
        }

        function createTask(model) {
            return $http.post('/TaskManagement/CreateIssue', {
                model: formatCreateData(_.cloneDeep(model))
            }).then(function (resp) {
                var model = resp.data.Model;

                model.IsOverdue = resp.data.IsOverdue;
                updateNavigationBadges(resp.data.BadgeCount, resp.data.NavigationBadge);

                return resp.data.Model
            })
        }

        function editTask(model) {
            return $http.post('/TaskManagement/EditIssue', {
                model: formatCreateData(_.cloneDeep(model))
            }).then(function (resp) {
                var model = resp.data.Model;

                model.IsOverdue = resp.data.IsOverdue;
                updateNavigationBadges(resp.data.BadgeCount, resp.data.NavigationBadge);

                return model;
            });
        }

        function deleteTask(issue) {
            if (issue.IsRecurringChild) {
                return ConfirmPopupService.open({
                    message: $filter('translate')('TASK_MANAGEMENT.RECURRING.SERIES_QUESTION_DELETE'),
                    yesText: $filter('translate')('TASK_MANAGEMENT.RECURRING.ONE'),
                    noText: $filter('translate')('TASK_MANAGEMENT.RECURRING.SERIES')
                }).then(
                    function () {
                        return $http.post('/TaskManagement/DeleteIssue', {
                            token: issue.IssueToken
                        }).then(function (data) {
                            $rootScope.$emit('TaskManagement:taskDeleted', issue);
                            return data;
                        });
                    }, function () {
                        return $http.post('/TaskManagement/DeleteIssue', {
                            token: issue.ParentIssueToken
                        }).then(function (data) {
                            $rootScope.$emit('TaskManagement:taskSeriesDeleted', issue);
                            return data;
                        })
                    }
                )
            } else {
                return $http.post('/TaskManagement/DeleteIssue', {
                    token: issue.IssueToken
                }).then(function (data) {
                    $rootScope.$emit('TaskManagement:taskDeleted', issue);
                    return data;
                });
            }
        }

        function updateNavigationBadges(badgeCount, navigationBadge) {
            if (navigationBadge) {
                Menu.updateActivity(badgeCount, $state.current.name, $state.params.token, $state.params.extra);
                Menu.updateLandingBadge(
                    0,
                    navigationBadge.AccountModuleId,
                    navigationBadge.ModuleId,
                    navigationBadge.ModuleItemId,
                    badgeCount
                );
            }
        }

        function getCreatePage() {
            return $http.get('/TaskManagement/GetCreatePage/' + $stateParams.token)
                .then(resp => resp.data)
        }

        function getUpdatePage(token) {
            return $http.get('/TaskManagement/GetEditPage/' + token)
                .then(resp => formatDataForEdit(resp.data))
        }

        function formatCreateData(model) {

            model.AssigneeTokens = [];
            if (model.Assignees && model.Assignees.length) {
                model.AssigneeTokens = model.Assignees.map(user => user.UserToken);
            }

            if (model.RecurrenceRule) {
                var momentStart = moment(model.StartTime.Recurrent);

                model.StartTime = model.StartTime.Recurrent ? momentStart.format('YYYY-MM-DD[T]HH:mm:ss') : null;

                model.EndTime = model.EndTime.Recurrent ?
                    moment(model.EndTime.Recurrent).set({
                        year: momentStart.year(),
                        month: momentStart.month(),
                        date: momentStart.date()
                    }).format('YYYY-MM-DD[T]HH:mm:ss') : null;

                const updatedRuleArray = model.RecurrenceRule.split(';').map(element => {
                    if (element.startsWith('DTSTART') && model.StartTime) {
                        return 'DTSTART=' + moment(model.StartTime).format('YYYYMMDD[T]HHmmss');
                    }

                    if (element.startsWith('UNTIL') && model.EndTime) {
                        return 'UNTIL=' + moment(element.split('=')[1]).hours(moment(model.EndTime).hours())
                            .minutes(moment(model.EndTime).minutes())
                            .seconds(0).format('YYYYMMDD[T]HHmmss');
                    }
                    
                    return element;
                });
                model.RecurrenceRule = updatedRuleArray.join(';');
            } else {
                model.RecurrenceRule = null;
                model.StartTime = model.StartTime.DateTime ?
                    moment(model.StartTime.DateTime).format('YYYY-MM-DD[T]HH:mm:ss') : null;
                model.EndTime = model.EndTime.DateTime ?
                    moment(model.EndTime.DateTime).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            }
            if (model.Medias && model.Medias.length) {
                model.Medias = model.Medias.map(media => media.MediaId);
            }

            model.LocaleList = model.LocaleList
                .filter(locale => locale.Title?.length || locale.Summary?.length)
                .map(locale => {
                    return {
                        CultureId: locale.CultureId,
                        Title: locale.Title,
                        Summary: locale.Summary,
                        AutoTranslated: locale.AutoTranslated
                    };
                })
            return model;
        }

        function formatDataForEdit(data) {
            const dataClone = _.cloneDeep(data),
                task = dataClone.Model[0],
                categories = dataClone.AllCategories;

            categories.forEach(category => {
                if (task.Categories.find(task => task.IssueCategoryId === category.IssueCategoryId)) {
                    category.isSelected = true;
                }
            });
            delete task.Categories;

            if (task.RecurrenceRule) {
                task.Recurrent = true;
                _.set(task, 'StartTime.Recurrent', moment(task.StartTime).toDate());
                _.set(task, 'EndTime.Recurrent', moment(task.EndTime).toDate());

                const updatedRuleArray = task.RecurrenceRule.split(';').map(element => {
                    if (element.startsWith('DTSTART')) {
                        return 'DTSTART=' + moment(task.StartTime).format('YYYYMMDD[T]HHmmss');
                    }

                    if (element.startsWith('UNTIL')) {
                        return 'UNTIL=' + moment(element.split('=')[1]).hours(moment(task.EndTime).hours())
                            .minutes(moment(task.EndTime).minutes())
                            .seconds(0).format('YYYYMMDD[T]HHmmss');
                    }
                    
                    return element;
                });
                task.RecurrenceRule = updatedRuleArray.join(';');
            } else {
                _.set(task, 'StartTime.DateTime', moment(task.StartTime).toDate());
                _.set(task, 'EndTime.DateTime', moment(task.EndTime).toDate());
            }

            if (task.ReminderInterval) {
                task.Deadline = true;
            }
            task.Medias = data.IssueMedias;
            task.Permissions = JSON.parse(data.Permission);

            task.Token = _.get(data, 'AccountModuleToken');
            task.CompleteByDepartmentTypeId = task.CompleteByDepartmentTypeId
                && task.CompleteByDepartmentTypeId.toString() || null;
            task.LocaleList = data.LocaleList.map(locale => ({
                ...locale,
                InitialTitle: locale.Title,
                InitialSummary: locale.Summary
            }));

            if (data.ServiceFormId) {
                task.ServiceFormId = '' + data.ServiceFormId;
            }

            return {
                Cultures: data.Cultures,
                Settings: data.Settings,
                task: task,
                AllCategories: categories,
                ReminderDueSoonNotificationEnabled: data.ReminderDueSoonNotificationEnabled,
                ReminderOverdueNotificationEnabled: data.ReminderOverdueNotificationEnabled,
                ServiceForms: data.ServiceForms,
                DepartmentTypes: data.DepartmentTypes
            }
        }
    }

})();
