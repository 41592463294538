(function () {
    'use strict';

    angular.module('App')
        .factory('CalendarRenderService', ['$http', '$q', '$rootScope', '$filter', '$timeout', 'BasicHelper',
            'Profile', 'TimeLocale', 'CalendarRestService', 'ServiceFormRestService', 'ServiceFormExternalService',
            function ($http, $q, $rootScope, $filter, $timeout, BasicHelper, Profile, TimeLocale, CalendarRestService,
                ServiceFormRestService, ServiceFormExternalService) {

                let weekStartNumber = 1, flatDays = [], categories, allEvents = [], savedFilters, filterCount;

                return {
                    getCalendarData: getCalendarData,
                    getCalendarEvents: getCalendarEvents,
                    filterEvents: filterEvents,
                    mergeEventsWithCategories: mergeEventsWithCategories,
                    getCategories: getCategories,
                    setCategories: setCategories,
                    createEvent: createEvent,
                    editEvent: editEvent,
                    cancelEvent: cancelEvent,
                    eventSignup: eventSignup,
                    eventSignOut: eventSignOut,
                    eventSignupWithForm: eventSignupWithForm,
                    deleteEvent: deleteEvent,
                    getNextMonth: getNextMonth,
                    getPreviousMonth: getPreviousMonth,
                    generateCalendar: generateCalendar,
                    clearData: clearData,
                    clearDays: clearDays,
                    getEventPreviewData: getEventPreviewData,
                    getFilters: getFilters,
                    getEvent: getEvent,
                    toggleInterested: toggleInterested,
                    search: search,
                    removeParticipants: removeParticipants,
                    getShortWeekdays: getShortWeekdays,
                    getWeekdays: getWeekdays,
                }

                function getCalendarData(token) {
                    return CalendarRestService.getCalendarData(token)
                }

                function getCalendarEvents(filter) {
                    return CalendarRestService.getCalendarEvents({
                        ...filter,
                        ...useSavedFilters()
                    }).then(data => {
                        const events = mergeEventsWithCategories(data.Events);
                        saveEvents(events)

                        return { events, lastEventId: data.BatchLastEventId }
                    })
                }

                function useSavedFilters() {
                    return {
                        ...savedFilters,
                        StartTime: savedFilters?.StartTime ? moment(savedFilters.StartTime).format('YYYY-MM-DD') : null,
                        EndTime: savedFilters?.EndTime ? moment(savedFilters.EndTime).format('YYYY-MM-DD') : null,
                        SortType: savedFilters?.SortType.id
                    }
                }

                function filterEvents(filter, count) {
                    savedFilters = filter;
                    filterCount = count;
                    allEvents = [];
                    $rootScope.$broadcast('filter-events')
                }

                function saveEvents(events) {
                    events.forEach(event => {
                        if (!allEvents.find(ev => ev.CalendarEventId === event.CalendarEventId)) {
                            allEvents.push(event);
                        }
                    })
                }

                function preparePreviewData(previewData) {
                    const event = previewData;

                    previewData.EventModel = _.head(mergeEventsWithCategories([previewData.EventModel]));

                    for (var key in previewData.EventModel) {
                        event[key] = previewData.EventModel[key];
                    }
                    delete previewData.EventModel;

                    return event;
                }

                function getWeekdays(isIso) {
                    var sunday,
                        weekDays = moment.weekdays();

                    if (isIso) {
                        sunday = weekDays.shift();
                        weekDays.push(sunday);
                    }

                    return weekDays;
                }

                function getShortWeekdays(isIso) {
                    var sunday,
                        weekDays = moment.weekdaysShort();

                    if (isIso) {
                        sunday = weekDays.shift();
                        weekDays.push(sunday);
                    }

                    return weekDays;
                }

                function getWeeksStartAndEndInMonth(month, year) {
                    var fullDate, day,
                        weeks = [],
                        flatWeeks = [],
                        days = [],
                        firstDate = new Date(year, month, 1),
                        lastDate = new Date(year, month + 1, 0),
                        numDays = lastDate.getDate(),
                        start = 1,
                        end = 7 - firstDate.getDay();

                    if (weekStartNumber === 1) {
                        if (firstDate.getDay() === 0) {
                            end = 1;
                        } else {
                            end = 7 - firstDate.getDay() + 1;
                        }
                    }
                    while (start <= numDays) {
                        days = [];
                        for (var i = start; i <= end; i++) {
                            fullDate = moment(i + '.' + (month + 1) + '.' + year, 'DD.MM.YYYY');
                            day = {
                                date: i,
                                fullDate: fullDate,
                                isCurrent: fullDate.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY'),
                                events: []
                            };
                            flatWeeks.push(day);
                            days.push(day);
                        }
                        weeks.push(days);
                        start = end + 1;
                        end = end + 7;
                        end = start === 1 && end === 8 ? 1 : end;
                        if (end > numDays) {
                            end = numDays;
                        }
                    }
                    return {
                        days: flatWeeks,
                        weeks: weeks
                    };
                }

                function getMonth(momentDate, isCurrent) {
                    var generatedWeeks = getWeeksStartAndEndInMonth(momentDate.month(), momentDate.year());
                    return {
                        year: momentDate.year(),
                        order: parseInt(momentDate.year() + '' + momentDate.month().toString().padStart(2, '0')),
                        title: momentDate.format('MMM'),
                        isCurrent: isCurrent,
                        fullDate: momentDate,
                        weeks: generatedWeeks.weeks,
                        days: generatedWeeks.days
                    };
                }

                function isStartLessThenEnd(start, end) {
                    return start.isSameOrBefore(end, 'day');
                }

                function addEventsToDay(events) {
                    events.forEach(function (event) {
                        var startTime = moment(event.StartTimeLocal),
                            endTime = moment(event.EndTimeLocal),
                            eventDay;

                        if (startTime.isSame(endTime, 'day')) {
                            eventDay = _.find(flatDays, function (day) {
                                return day.fullDate.isSame(startTime, 'day');
                            });

                            eventDay && addOrUpdateEvent(eventDay, event);
                        } else {
                            var i = 0;
                            while (isStartLessThenEnd(moment(event.StartTimeLocal).add(i, 'day'), endTime)) {
                                var tmp = moment(event.StartTimeLocal).add(i, 'day');
                                eventDay = _.find(flatDays, function (day) {
                                    return day.fullDate.isSame(tmp, 'day');
                                });
                                eventDay && addOrUpdateEvent(eventDay, event);
                                i++;
                            }
                        }
                    });

                    return flatDays
                }

                function addOrUpdateEvent(eventDay, event) {
                    var ind = _.findIndex(eventDay.events, { 'CalendarEventId': event.CalendarEventId });
                    if (ind === -1) {
                        eventDay.events.push(event);
                    } else {
                        eventDay.events[ind] = event;
                    }
                }

                function generateFlatCalendar(monthsData, events) {
                    let months = Array.isArray(monthsData) ? monthsData : [monthsData];

                    months.forEach(month => {
                        flatDays = [...flatDays, ...month.days];
                    })

                    CalendarRestService.getCalendarViewEvents(months, savedFilters).then(({ Events }) => {
                        const events = mergeEventsWithCategories(Events);
                        saveEvents(events);
                        addEventsToDay(Events);
                    })

                    return $q(function (resolve) {
                        const daysWithEvents = addEventsToDay(events || []);
                        resolve(daysWithEvents)
                    })
                }

                function mergeEventsWithCategories(events) {
                    events.forEach(function (event) {
                        const category = categories.find(cat => cat.CalendarCategoryId === event.CalendarCategoryId);
                        if (category) {
                            event.category = category;
                            event.color = event.category.Color;
                            event.bgColor = BasicHelper.hexToRgba(event.color, 0.3);
                        }
                    });

                    return events;
                }

                function getCategories() {
                    return categories
                }

                function setCategories(newCategories) {
                    categories = newCategories;
                }

                function createEvent(eventModel) {
                    return CalendarRestService.createEvent(eventModel).then(function (event) {
                        mergeEventsWithCategories([event]);
                        addEventsToDay([event]);
                        saveEvents([event]);
                    });
                }

                function editEvent(eventModel, oldEvent) {
                    return CalendarRestService.editEvent(eventModel).then(function (event) {
                        const ind = allEvents.findIndex(ev => ev.CalendarEventId === event.CalendarEventId);
                        if (ind >= 0) {
                            removeOldEvents(eventModel, oldEvent);
                            mergeEventsWithCategories([event]);
                            allEvents[ind] = event;
                            addEventsToDay([event]);
                        }
                    });
                }

                function cancelEvent(eventToken, translations, cancelType, sendNotifications) {
                    return CalendarRestService.cancelEventRequest(eventToken, translations, cancelType, sendNotifications).then(resp => {
                        const event = preparePreviewData(resp.EventPreview);
                        updateEventData(event);

                        return event
                    })
                }

                function eventSignup(id, statusId) {
                    return CalendarRestService.eventSignup(id, statusId).then(ev => {
                        const event = preparePreviewData(ev);
                        updateEventData(event);
                        return event
                    })
                }

                function eventSignOut(id) {
                    return CalendarRestService.eventSignOut(id).then(ev => {
                        const event = preparePreviewData(ev);
                        updateEventData(event);
                        return event
                    })
                }

                function eventSignupWithForm(attendingStatus, event, accountModuleToken, onCreateCallback) {
                    $q.all({
                        formData: ServiceFormRestService.getServiceFormData(attendingStatus.token),
                        fillingData: event.ServiceFormFillingToken &&
                            ServiceFormRestService.getFillingData(event.ServiceFormFillingToken)
                    }).then(({ formData, fillingData }) => {
                        ServiceFormExternalService.setSettings(formData);

                        formData.ServiceFormAccountModuleToken = event.ServiceFormAccountModuleToken;
                        formData.CalendarEventId = event.CalendarEventId;
                        formData.AttendingStatusId = attendingStatus.id;
                        formData.fillingCreated = onCreate;
                        formData.preDraftCreated = onCreate;

                        $timeout(function () {
                            if (fillingData) {
                                fillingData.PageNavigation = fillingData.PageNavigation[0].Pages;
                                ServiceFormExternalService.openUpdateFillingPopup(formData, fillingData);
                            } else {
                                ServiceFormExternalService.openCreateFillingPopup(formData);
                            }
                        });

                        function onCreate(resp) {
                            if (!resp.Filling.IsDraft) {
                                CalendarRestService.getEventPreviewData(event.CalendarEventId, accountModuleToken).then(function (previewData) {
                                    const event = preparePreviewData(previewData);
                                    updateEventData(event);

                                    onCreateCallback(event, resp.Filling.IsDraft);

                                    return event;
                                });
                            } else {
                                event.ServiceFormFillingToken = resp.Filling.ServiceFormFillingToken;
                                event.AttendingStatus = attendingStatus.id;
                                event.IsCurrentUserSignedUp = false;
                                event.IsDraft = resp.Filling.IsDraft;

                                const index = _.findIndex(event.Signups, { 'UserToken': Profile.getCachedProfile()?.UserToken });
                                if (index >= 0) {
                                    event.Signups.splice(index, 1);

                                    if (event.SignupNumber) {
                                        event.SignupNumber--;
                                    }
                                }

                                updateEventData(event);
                                onCreateCallback(event, resp.Filling.IsDraft);
                            }
                        }
                    })
                }

                function deleteEvent(event) {
                    var startEventInd, endEventindex;
                    return CalendarRestService.deleteEvent(event.CalendarEventId).then(function () {
                        startEventInd = _.findIndex(flatDays, function (day) {
                            return moment(event.StartTimeLocal).isSame(day.fullDate, 'day');
                        });
                        endEventindex = _.findIndex(flatDays, function (day) {
                            return moment(event.EndTimeLocal).isSame(day.fullDate, 'day');
                        });

                        if (startEventInd !== -1 && endEventindex !== -1) {
                            for (var i = startEventInd; i <= endEventindex; i++) {
                                _.remove(flatDays[i].events, { 'CalendarEventId': event.CalendarEventId });
                            }
                        }

                        _.remove(allEvents, { 'CalendarEventId': event.CalendarEventId });
                    });
                }

                function getNextMonth(calendarData) {
                    var lastMonth = _.last(calendarData.calendar),
                        months = [getMonth(_.cloneDeep(lastMonth.fullDate).add(1, 'months')),
                        getMonth(_.cloneDeep(lastMonth.fullDate).add(2, 'months'))];

                    calendarData.calendar.push(months[0]);
                    calendarData.calendar.push(months[1]);

                    return generateFlatCalendar(months).then(function (flatMonth) {
                        calendarData.flatDays = calendarData.flatDays.concat(flatMonth);
                        return calendarData;
                    });
                }

                function getPreviousMonth(calendarData) {
                    var firstMonth = _.head(calendarData.calendar),
                        months = [getMonth(_.cloneDeep(firstMonth.fullDate).subtract(2, 'months')),
                        getMonth(_.cloneDeep(firstMonth.fullDate).subtract(1, 'months'))];
                    calendarData.calendar.unshift(months[1]);
                    calendarData.calendar.unshift(months[0]);

                    return generateFlatCalendar(months).then(function (flatMonth) {
                        calendarData.flatDays = flatMonth.concat(calendarData.flatDays);
                        return calendarData;
                    });
                }

                function generateCalendar(isIso) {
                    const currentPeriod = [
                        getMonth(moment().subtract(1, 'months')),
                        getMonth(moment(), true),
                        getMonth(moment().add(1, 'months'))
                    ];

                    return generateFlatCalendar(currentPeriod, allEvents).then(function (days) {
                        return {
                            calendar: currentPeriod,
                            flatDays: days,
                            daysOfWeek: getWeekdays(isIso),
                            shortDaysOfWeek: getShortWeekdays(isIso)
                        }
                    });
                }

                function clearData() {
                    flatDays = [];
                    categories = [];
                    allEvents = [];
                    savedFilters = undefined;
                    filterCount = undefined;
                }

                function clearDays() {
                    flatDays = [];
                }

                function getEventPreviewData(eventId, accountModuleToken) {
                    return CalendarRestService.getEventPreviewData(eventId, accountModuleToken).then(function (previewData) {
                        return preparePreviewData(previewData)
                    });
                }

                function removeOldEvents(event, oldEvent) {
                    if (moment(oldEvent.StartTime).isBefore(moment(event.StartTime), 'day') ||
                        moment(oldEvent.EndTime).isAfter(moment(event.EndTime), 'day')) {
                        var startDate = moment(oldEvent.StartTime).isBefore(moment(event.StartTime), 'day')
                            ? moment(oldEvent.StartTime) : moment(event.StartTime),
                            endDate = moment(oldEvent.EndTime).isAfter(moment(event.EndTime), 'day')
                                ? moment(oldEvent.EndTime) : moment(event.EndTime);

                        for (var i = 0; i < endDate.dayOfYear() - startDate.dayOfYear() + 1; i++) {
                            if (moment(oldEvent.StartTime).add(i, 'day').isBefore(moment(event.StartTime), 'day') ||
                                (!moment(oldEvent.StartTime).add(i, 'day').isSameOrBefore(moment(event.StartTime), 'day') &&
                                    moment(oldEvent.StartTime).add(i, 'day').isAfter(moment(event.EndTime), 'day'))) {
                                var day = _.find(flatDays, function (day) {
                                    return day.fullDate.isSame(moment(oldEvent.StartTime).add(i, 'day'), 'day');
                                }), index = _.findIndex(day.events, { 'CalendarEventId': oldEvent.CalendarEventId });

                                day.events.splice(index, 1);
                            }
                        }
                    }
                }

                function getFilters() {
                    return { savedFilters, filterCount };
                }

                function getEvent(id) {
                    return allEvents.find(event => event.CalendarEventId === id)
                }

                function toggleInterested(event) {
                    updateEventData(event);
                    return CalendarRestService.toggleInterested(event);
                }

                function updateEventData(event) {
                    const updateEventIndex = allEvents.findIndex(ev => ev.CalendarEventId === event.CalendarEventId);

                    allEvents[updateEventIndex] = event;
                    addEventsToDay([event]);
                }

                function search(SearchString) {
                    return CalendarRestService.getCalendarEvents({
                        ...useSavedFilters(),
                        SearchString
                    })
                }

                function removeParticipants(calendarEventId, userTokens) {
                    return CalendarRestService.removeParticipants(calendarEventId, userTokens)
                        .then(({ Event, Signups }) => {
                            const event = preparePreviewData(Event);
                            updateEventData(event)

                            return {
                                Event: event,
                                Signups
                            }
                        });
                }
            }]);
})();
