(function () {
    'use strict';

    angular
        .module('App')
        .factory('ProfileSettingsService', ['$http', 'Profile', '$q', 'ToastFactory', ProfileSettingsService]);

    function ProfileSettingsService($http, Profile, $q, ToastFactory) {
        var lastProfileLoad = new Date().getTime();
        var phoneCodes = [];

        return {
            getFullProfile: getFullProfile,
            createToggleProperty: createToggleProperty,
            updateDyslexiaFontEnabled: updateDyslexiaFontEnabled,
            updateCulture: updateCulture,
            changePassword: changePassword,
            updateBaseProfile: updateBaseProfile,
            getPhoneCodes: getPhoneCodes
        };

        function getFullProfile(forceLoad) {
            let d = $q.defer();

            const profileData = Profile.getProfile();
            const maxTimeSinceLoad = new Date().getTime() - (1000 * 60 * 5); // 5 minutes ago

            if (Profile.isAllDataLoaded(profileData) && lastProfileLoad > maxTimeSinceLoad && forceLoad !== true) {
                d.resolve(profileData);
            } else {
                Profile.loadProfile().then(res => {
                    if (_.isObject(res)) {
                        lastProfileLoad = new Date().getTime();
                        d.resolve(res.data);
                    } else {
                        d.reject();
                    }
                })
            }

            return d.promise;
        }

        function createToggleProperty(profile, propertyName, updateMethod) {
            var state = {
                propertyName: propertyName,
                value: profile[propertyName],
                isUpdating: false
            };

            const onChange = () => {
                state.isUpdating = true;
                const newVal = state.value;

                if (updateMethod) {
                    updateMethod(newVal).then(() => {
                        state.isUpdating = false;
                    }).catch(() => {
                        state.value = Profile.getProfile()[state.propertyName];
                        state.isUpdating = false;
                        ToastFactory.errorTranslated('PROFILE.ERROR.SAVE_FAILED');
                    });
                } else {
                    saveAndUpdateProperty(state.propertyName, newVal).then(() => {
                        state.isUpdating = false;
                    }).catch(() => {
                        state.value = Profile.getProfile()[state.propertyName];
                        state.isUpdating = false;
                        ToastFactory.errorTranslated('PROFILE.ERROR.SAVE_FAILED');
                    });
                }
            }

            state.onChange = onChange;
            return state;
        }

        function updateDyslexiaFontEnabled(newVal) {
            var d = $q.defer();
            
            saveAndUpdateProperty('DyslexiaFontEnabled', newVal).then(() => {
                angular.element('body').toggleClass('dyslexia-font', newVal);
                d.resolve();
            }).catch(() => {
                d.reject();
            });

            return d.promise;
        }

        function updateCulture(newCultureId) {
            var d = $q.defer();
            
            saveAndUpdateProperty('CultureId', newCultureId).then(() => {
                
                d.resolve();
            }).catch(() => {
                ToastFactory.errorTranslated('PROFILE.ERROR.SAVE_FAILED');
                d.reject();
            });

            return d.promise;
        }

        function saveAndUpdateProperty(propertyName, newVal) {
            var d = $q.defer();
            
            var requestData = {};
            requestData[propertyName] = newVal;

            $http.post('/profile/updateProperties', requestData).then(res => {
                Profile.mergeWithCurrentProfile(res.data);
                d.resolve();
            }).catch(() => {
                d.reject();
            })

            return d.promise;
        }

        function changePassword(oldPassword, newPassword) {
            var d = $q.defer();

            $http.post('profile/changePassword', {
                oldPassword: oldPassword,
                newPassword, newPassword
            }).then(() => {
                d.resolve();
            }).catch(res => {
                if (res.status === 400) {
                    d.reject(res.data);
                } else {
                    d.reject();
                }
            });

            return d.promise;
        }

        function updateBaseProfile(requestData) {
            var d = $q.defer();

            $http.post('profile/updateBaseProfile', requestData).then(res => {
                Profile.setProfile(res.data, true);
                d.resolve();
            }).catch(res => {
                var errorResult = [];
                if (res.status === 400 && res.data && _.isArray(res.data) && res.data.length) {
                    res.data.forEach(o => {
                        if(o.Message && o.Message.length) {
                            errorResult.push(o.Message);
                        }
                    })
                }
                d.reject(errorResult);
            });

            return d.promise;
        }

        function getPhoneCodes() {
            var d = $q.defer();

            if (phoneCodes.length > 0) {
                d.resolve(phoneCodes);
            } else {
                $http.get('profile/phoneCodes').then(res => {
                    var result = [];
                    res.data.forEach(o => {
                        result.push({
                            id: o.CountryId,
                            code: o.PhoneCode,
                            countryCode: o.CountryCode,
                            name: o.Name
                        });
                    });

                    phoneCodes = result;
                    d.resolve(phoneCodes);
                }).catch(() => {
                    d.reject();
                })
            }

            return d.promise;
        }
    }
})();