(() => {
    'use strict';

    angular
        .module('App')
        .component('contactList', {
            template: require('./ContactListComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$anchorScroll', '$element', '$timeout', ContactListComponent],
            bindings: {
                contactList: '<',
                enableInfiniteScrolling: '<',
                showDepartment: '<'
            }
        });

    function ContactListComponent($anchorScroll, $element, $timeout) {
        var ctrl = this, scrolledContainer, throttledScroll = _.throttle(onScrollEvent, 200);

        ctrl.firstLetter = firstLetter;
        ctrl.goToDivider = goToDivider;

        ctrl.alphabetList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        ctrl.moreContactUsers = false;
        ctrl.enableAlphabet = false;

        ctrl.$onInit = function () {
            if (ctrl.enableInfiniteScrolling) {
                ctrl.contactsToDisplay = 50;
            } else {
                ctrl.contactsToDisplay = null;
            }

            scrolledContainer = $element.find('.contact-list-component');
            scrolledContainer.on('scroll', throttledScroll);
        };

        function firstLetter(name) {
            return name && name.charAt(0).toUpperCase();
        }

        function goToDivider(letter) {
            $anchorScroll.yOffset = 60;
            $anchorScroll('firstLetter-' + letter);
        }

        function onScrollEvent() {
            if (ctrl.enableInfiniteScrolling) {
                if (scrolledContainer.scrollTop() + scrolledContainer.innerHeight() > scrolledContainer[0].scrollHeight - 50) {
                    $timeout(function () {
                        ctrl.contactsToDisplay += 20;
                        ctrl.moreContactUsers = ctrl.contactsToDisplay <= ctrl.contactList.length;
                    }, 0)
                }
            }
        }
    }
})();
