(function () {
    'use strict';

    angular.module('App')
        .factory('ScormCloudService', ['$filter', '$timeout', function ($filter, $timeout) {
            return {
                getItemStatus: getItemStatus,
                getItemStatusClass: getItemStatusClass
            };

            function getItemStatus(item) {
                if (item.ProgressState === 0) {
                    return $filter('translate')('SCORMCLOUD.STATE.NOT_STARTED');
                }
                if (item.ProgressState === 1) {
                    return $filter('translate')('SCORMCLOUD.STATE.STARTED');
                }
                if (item.ProgressState === 2) {
                    if (item.PassedState === 1) {
                        return $filter('translate')('SCORMCLOUD.STATE.PASSED');
                    } else {
                        return $filter('translate')('SCORMCLOUD.STATE.COMPLETED');
                    }
                }
            }

            function getItemStatusClass(item) {
                if (item.ProgressState === 0) {
                    return 'new'
                }
                if (item.ProgressState === 1) {
                    return 'in-progress';
                }
                if (item.ProgressState === 2) {
                    if (item.Passed) {
                        return 'passed';
                    } else {
                        return 'completed';
                    }
                }
            }
        }]);
})();