(function () {
    'use strict';
    angular
        .module('App')
        .directive('timeAgoShort', ['TimeLocale', '$filter',
            function (TimeLocale, $filter) {
                return {
                    restrict: 'A',
                    scope: {
                        timeAgoShort: '='
                    },
                    link: function (scope, element, attrs) {

                        moment.updateLocale('en', {
                            relativeTime: {
                                future: "in %s",
                                past: "%s ago",
                                s: 'now', // less than a minute
                                m: "1m",
                                mm: "%dm",
                                h: "1h",
                                hh: "%dh",
                                d: "1d",
                                dd: "%dd",
                                M: "1mo",
                                MM: "%dmo",
                                y: "1y",
                                yy: "%dy"
                            }
                        });

                        moment.relativeTimeThreshold('s', 59);
                        moment.relativeTimeThreshold('m', 59);
                        moment.relativeTimeThreshold('h', 23);
                        moment.relativeTimeThreshold('d', 30);
                        moment.relativeTimeThreshold('M', 12);

                        // Load it up
                        var val = scope.timeAgoShort;
                        $(element).html(TimeLocale.timeAgoShort(val));
                    }
                }
            }
        ]);
})();
