(function () {
    'use strict';

    var app = angular.module('App');

    app.factory('PopupWrapperService', ['$q', '$rootScope', '$compile', '$timeout', 'BasicHelper', 'ConfirmPopupService',
        function ($q, $rootScope, $compile, $timeout, BasicHelper, ConfirmPopupService) {
            var registeredPopups = [];

            return {
                registerEvent: registerEvent,
                unRegisterEvent: unRegisterEvent,
                getPopup: getPopup,
                createDynamic: createDynamic,
                fixKeyboardShift: fixKeyboardShift,
                showConfirmationPopupOnLeave: showConfirmationPopupOnLeave 
            };

            function registerEvent(popup, callback) {
                if (!_.find(registeredPopups, {'popupId': popup.popupId})) {
                    registeredPopups.push(popup);
                    callback && callback(popup);
                }
            }

            function unRegisterEvent(popup, callback) {
                _.remove(registeredPopups, function (el) {
                    return el.popupId === popup.popupId;
                });
                callback && callback();
            }

            function getPopup(id) {
                return _.find(registeredPopups, {'popupId': id});
            }

            function createDynamic(component, bindings) {
                var defer = $q.defer(),
                    scope = _.assign($rootScope.$new(true), bindings),
                    popup = $compile(component)(scope);

                angular.element('body').append(popup);

                $timeout(function () {
                    defer.resolve(popup);
                });

                return defer.promise;
            }

            function fixKeyboardShift(el, innerScroll) {
                const root = document.querySelector(':root'),
                    popup = el.closest('popup-wrapper'),
                    scrollContainer = popup.querySelector('.scroll-container');
                
                let scrollPosition, viewPortHeight = visualViewport.height, keyboardHeight, lastScrollTop;

                if (BasicHelper.isIOS()) {
                    el.onfocus = () => {
                        scrollPosition = window.scrollY;
                        $timeout(() => {
                            innerScroll && innerScroll();
                            window.scrollTo(0, 0);
                            popup.classList.add('keyboard-open');
                            keyboardHeight = viewPortHeight - visualViewport.height;
                            root.style.setProperty('--popup-height-shift', keyboardHeight + 'px');
                            scrollContainer.scrollTop = scrollContainer.scrollTop + keyboardHeight;
                            lastScrollTop = scrollContainer.scrollTop;
                            $timeout(() => {
                                visualViewport.addEventListener('resize', onResize);
                            })
                        }, 300)
                    }
                    el.onblur = () => {
                        $timeout(() => {
                            root.style.setProperty('--popup-height-shift', 0 + 'px');
                            el.closest('popup-wrapper').classList.remove('keyboard-open');
                            scrollContainer.scrollTop = lastScrollTop - scrollContainer.scrollTop < keyboardHeight 
                                ? scrollContainer.scrollTop : scrollContainer.scrollTop - keyboardHeight; 
                              
                            window.scrollTo(0, scrollPosition);
                            visualViewport.removeEventListener('resize', onResize);
                        })
                    }
                }

                function onResize() {
                    $timeout(() => {
                        window.scrollTo(0, 0);
                        keyboardHeight = viewPortHeight - visualViewport.height;
                        root.style.setProperty('--popup-height-shift', keyboardHeight + 'px');
                    }, 300)
                }
            }
            
            function showConfirmationPopupOnLeave() {
                return ConfirmPopupService.open({
                    title: 'UNSAVED_CHANGES.TITLE',
                    message: 'UNSAVED_CHANGES.MESSAGE',
                    yesText: 'DISCARD',
                    noText: 'BACK',
                    preventClosing: true,
                })
            }
        }]);
})();