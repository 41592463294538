(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {

            // DataWarehouseList
            $stateProvider
                .state('datawarehouselist', {
                    parent: 'base',
                    url: '/datawarehouselist/:token',
                    templateUrl: '/DataWarehouseList',
                    resolve: {
                        dataWarehouseList: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/DataWarehouseList/GetDataWarehouseList/' + $stateParams.token);
                            } return;
                        }]

                    },
                    controller: 'DataWarehouseListController as DataWarehouseListCtrl'
                })
                .state('datawarehouselistitem', {
                    parent: 'base',
                    url: '/datawarehouselist/:token/:extra',
                    templateUrl: '/DataWarehouseList/DataWarehouseListItemView',
                    resolve: {
                        dataWarehouseListItem: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/DataWarehouseList/GetDataWarehouseListItems/' + $stateParams.token + '/?groupvalue=' + $stateParams.extra);
                            } return;
                        }]

                    },
                    controller: 'DataWarehouseListItemController as DataWarehouseListItemCtrl'
                });

            

        }
    ]);

    app.controller('DataWarehouseListController', ['$rootScope', '$scope', '$http', '$state', '$stateParams', 'Page', 'dataWarehouseList', 'events',
        function ($rootScope, $scope, $http, $state, $stateParams, Page, dataWarehouseList, events) {
            var ctrl = this;
            ctrl.Page = Page;
            Page.showBackButton('home');
            Page.setTitle(dataWarehouseList.data.Name);

            // bind data
            ctrl.groups = dataWarehouseList.data.Groups;
            ctrl.groupByHeader = dataWarehouseList.data.GroupByHeader;
            ctrl.displayMode = dataWarehouseList.data.DisplayMode;
            ctrl.items = dataWarehouseList.data.Items;
            ctrl.columns = dataWarehouseList.data.Columns;
            //console.log(ctrl)

            // Functions
            ctrl.ShowItems = function (group) {
                Page.stateGo('datawarehouselistitem', $stateParams.token, group);
            };

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);

    app.controller('DataWarehouseListItemController', ['$rootScope', '$scope', '$http', '$state', '$stateParams', 'Page', 'dataWarehouseListItem', 'events',
        function ($rootScope, $scope, $http, $state, $stateParams, Page, dataWarehouseListItem, events) {
            var ctrl = this;
            ctrl.Page = Page;
            Page.showBackButton('datawarehouselist', $stateParams.token);
            Page.setTitle(dataWarehouseListItem.data.Name);

            // bind data
            ctrl.items = dataWarehouseListItem.data.Items;
            ctrl.columns = dataWarehouseListItem.data.Columns;
            ctrl.groupName = dataWarehouseListItem.data.GroupName;

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateGoBack('datalist', $stateParams.token);
            });
        }
    ]);

})();