(() => {
    'use strict';

    angular
        .module('App')
        .component('icon', {
            template: require('./IconComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                src: '@'
            }
        });
})();