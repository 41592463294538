(function () {
    'use strict';

    var app = angular.module('App');

    app.component('filesPreview', {
        templateUrl: '/Scripts/Components/FilesPreview/FilesPreviewComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$filter', FilesPreviewController],
        bindings: {
            files: '=',
            allowDelete: '<'
        }
    });

    function FilesPreviewController($filter) {
        var ctrl = this,
            fileTypes = {
                1: {
                    className: 'image',
                    name: $filter('translate')('CALENDAR.FILE.IMAGE')
                },
                2: {
                    className: 'video',
                    name: $filter('translate')('CALENDAR.FILE.VIDEO')
                },
                3: {
                    className: 'pdf',
                    name: $filter('translate')('CALENDAR.FILE.PDF')
                },
                7: {
                    className: 'spreadsheet',
                    name: $filter('translate')('CALENDAR.FILE.SPREADSHEET')
                },
                10: {
                    className: 'document',
                    name: $filter('translate')('CALENDAR.FILE.DOCUMENT')
                },
                11: {
                    className: 'presentation',
                    name: $filter('translate')('CALENDAR.FILE.PRESENTATION')
                }
            };


        ctrl.removeFile = function (file) {
            _.remove(ctrl.files, function (item) {
                return item.MediaId === file.MediaId;
            });
        };

        ctrl.getClassName = function (file) {
            return fileTypes[file.MediaType].className;
        };

        ctrl.getTypeName = function (file) {
            return fileTypes[file.MediaType].name;
        }
    }
})();