(() => {
    'use strict';

    angular
        .module('App')
        .component('calendarView', {
            template: require('./CalendarViewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$rootScope', 'CalendarRenderService', CalendarViewController],
            bindings: {
                selectedDay: '=?'
            }
        })

    function CalendarViewController($timeout, $rootScope, CalendarRenderService) {
        const ctrl = this;
        let removeFilterWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onScrollPrev = onScrollPrev;
        ctrl.onScrollNext = onScrollNext;

        function init() {
            removeFilterWatcher = $rootScope.$on('filter-events', loadEvents)
            loadEvents();
        }

        function loadEvents() {
            ctrl.isDataLoaded = false;
            delete ctrl.selectedDay;
            CalendarRenderService.clearDays();
            
            $timeout(() => {
                CalendarRenderService.generateCalendar(true)
                    .then(calendarData => {
                        ctrl.isDataLoaded = true;
                        ctrl.calendarData = calendarData;
                    });
            })

        }

        function onScrollPrev(data) {
            return CalendarRenderService.getPreviousMonth(data);
        }

        function onScrollNext(data) {
            return CalendarRenderService.getNextMonth(data);
        }

        function destroy() {
            removeFilterWatcher && removeFilterWatcher();
        }
    }
})();