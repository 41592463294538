(function () {
    'use strict';

    var app = angular.module('App');

    app.component('ecShopgunCatalogView', {
        templateUrl: '/Scripts/Components/EmbeddedContent/ShopGun/ShopGunCatalogViewComponent/ShopGunCatalogViewComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$http', '$interval', '$element', 'Page', EcShopGunCatalogViewController],
        bindings: {
            token: '<',
            pageIndex: '<',
            session: '<',
            settings: '<',
            catalog: '<',
            back: '&'
        }
    });

    function EcShopGunCatalogViewController($http, $interval, $element, Page) {
        var ctrl = this;
        var intervalTimer = null;
        var intervalCounter = 0;
        var viewer = null;

        ctrl.isViewing = false;
        ctrl.isLoading = true;
        ctrl.loadError = false;
        ctrl.hasShopGunSDK = _.isObject(window.SGN);

        Page.showBackButtonFunction(function () {
            if (viewer !== null) {
                viewer.destroy();
                viewer = null;
            }
            ctrl.back({ catalog: ctrl.catalog });
        });

        ctrl.loadFailed = function () {
            $interval.cancel(intervalTimer);
            ctrl.isLoading = false;
            ctrl.loadError = true;
            ctrl.isViewing = false;
        };

        ctrl.showViewer = function (viewerData) {
            try {
                SGN.config.set({
                    appKey: ctrl.settings.ppiKey,
                    appSecret: ctrl.settings.appSecret
                });

                SGN.CoreKit.session.saveToken(ctrl.session.token);
                SGN.CoreKit.session.saveClientId(ctrl.session.client_Id);

                var pageId = 'page' + (ctrl.pageIndex + 1);
                var bootstrapper = new SGN.PagedPublicationKit.Bootstrapper({
                    el: $('.sgn__pp', $element)[0],
                    id: ctrl.id,
                    pageId: pageId
                });

                viewer = bootstrapper.createViewer(viewerData);
                ctrl.isLoading = false;
                viewer.start();
                ctrl.isViewing = true;
            }
            catch (ex) {
                console.error(ex);
                ctrl.loadFailed();
            }
            
        };

        ctrl.init = function () {
            if (intervalTimer) {
                $interval.cancel(intervalTimer);
            }

            try {
                var req = {
                    method: 'GET',
                    url: 'https://api.etilbudsavis.dk/v2/catalogs/' + ctrl.catalog.id,
                    headers: {
                        'X-Token': ctrl.session.Token
                    }
                };

                var viewerData = {
                    details: null,
                    pages: []
                };

                $http(req).then(function (details) {
                    viewerData.details = details.data;
                    req.url = 'https://api.etilbudsavis.dk/v2/catalogs/' + ctrl.catalog.id + '/pages';
                    $http(req).then(function (pages) {
                        viewerData.pages = pages.data;
                        ctrl.showViewer(viewerData);
                    }, function () {
                        ctrl.loadFailed();
                    });
                }, function () {
                    ctrl.loadFailed();
                });
            }
            catch (ex)
            {
                console.error(ex);
                ctrl.loadFailed();
            }
        };   

        if (ctrl.hasShopGunSDK === false) {
            intervalTimer = $interval(function () {
                if (_.isObject(window.SGN)) {
                    ctrl.init();
                } else {
                    intervalCounter++;
                    if (intervalCounter > 150) {
                        ctrl.loadFailed();
                    }
                }
            }, 100);
        } else {
            ctrl.init();
        }

             
    }
})();