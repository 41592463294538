(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('formModuleQuestionRating', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Modules/Form/Templates/FormModuleQuestionRating.html',
                scope: {
                    question: '=',
                    singleQuestion: '=',
                    stepDone: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    // Init
                    InitController();
                    function InitController() {
                        if ($scope.question.answers == undefined) {
                            $scope.question.answers = [];
                        }
                        if (!$scope.question.IsRequired) {
                            $scope.$watch('stepDone', function (newVal) {
                                if (newVal) {
                                    $scope.question.answered = true;
                                } else  {
                                    $scope.question.answered = false;
                                }
                            });
                        }
                    }

                    // Methods
                    function PickOption(option) {
                        for (var i = 0; i < $scope.question.Options.length; i++) {
                            var opt = $scope.question.Options[i];
                            opt.activated = (opt.SortOrder <= option.SortOrder);
                            opt.picked = (opt.FormQuestionOptionId == option.FormQuestionOptionId);
                        }

                        $scope.question.answers = [new QuestionAnswer(option.FormQuestionOptionId, option.Value)];
                        $scope.question.answered = true;
                    }

                    // Objects
                    function QuestionAnswer(optionId, text) {
                        this.FormQuestionOptionId = optionId;
                        this.Text = text;
                    }

                    // Bind scope
                    $scope.pickOption = PickOption;
                }]
            }
        }
    ]);
})();