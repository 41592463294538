(function () {
    'use strict';

    var app = angular.module('App');

    app.component('appHeaderDropdown', {
        template: require('./AppHeaderDropdownComponent.tpl.html'),
        controllerAs: 'ctrl',
        controller: ['$transclude', AppHeaderDropdownController],
        transclude: {
            header: '?appHeaderDropdownHeader',
            body: '?appHeaderDropdownBody',
            footer: '?appHeaderDropdownFooter'
        },
        bindings: {
            show: '=',
            toggleVisibility: '&'
        }
    });


    function AppHeaderDropdownController($transclude) {
        var ctrl = this;
        ctrl.hasHeader = $transclude.isSlotFilled('header');
        ctrl.hasBody = $transclude.isSlotFilled('body');
        ctrl.hasFooter = $transclude.isSlotFilled('footer');
    }
})(); 