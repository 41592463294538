(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('PhoneCodePickerController', ['$scope', '$http', 'close', '$translate', '$timeout', 'TimeLocale', 'countryId', 'Page',
        function ($scope, $http, close, $translate, $timeout, TimeLocale, countryId, Page) {
            $scope.loading = true;
            $scope.countries = [];
            $scope.selectedCountry = null;

            $scope.close = function () {
                Page.setMainContainerInert(false);
                close();
            };

            function Init() {
                // Create and translate header
                $scope.title = "";
                $translate('LOADING').then(function (translation) {
                    $scope.title = translation + "...";
                });

                Page.setMainContainerInert(true);

                $http.get('/Profile/PhoneCodes/')
                    .then(function (response) {
                        // Get Data
                        $scope.countries = response.data;
                        for (var i = 0; i < $scope.countries.length; i++) {
                            // Select current country
                            if ($scope.countries[i].CountryId == countryId) {
                                $scope.countries[i].selected = true;
                            }
                        }

                        // Translate Header
                        $translate('PROFILE.PICK_PHONE_CODE').then(function (translation) {
                            $scope.title = translation;
                        });

                        // Stop the loader
                        $timeout(function () {
                            $scope.loading = false;
                        });
                    }, function () {
                        $scope.loading = false;
                    });
            }

            // Run Init
            Init();

            // Methods
            $scope.Select = function (country) {
                // Remove old selections
                for (var i = 0; i < $scope.countries.length; i++) {
                    $scope.countries[i].selected = false;
                }

                // Select new
                country.selected = true;
                $scope.selectedCountry = country;

                // Close Modal
                CloseModal();
            }

            function CloseModal() {
                Page.setMainContainerInert(false);
                $scope.$emit('closeModal');
            }
        }
    ]);

})();