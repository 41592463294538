(() => {
    'use strict';

    angular.module('App')
        .component('dateField', {
            templateUrl: '/Scripts/Components/Date/DateFieldComponent.html',
            controllerAs: 'ctrl',
            controller: ['$scope', '$timeout', DateFieldController],
            bindings: {
                type: '@',//date, datetime
                fieldId: '@',
                isRequired: '<'
            },
            require: {
                ngModel: 'ngModel'
            }
        });

    function DateFieldController($scope, $timeout) {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            ctrl.ngModel.$render = () => {
                $timeout(() => {
                    ctrl.date = ctrl.ngModel.$viewValue ? new Date(ctrl.ngModel.$viewValue) : undefined;
                    if (ctrl.type === 'datetime') {
                        ctrl.time = ctrl.ngModel.$viewValue ? new Date(ctrl.ngModel.$viewValue) : undefined;
                    }
                })
            }

            ctrl.ngModel.$isEmpty = () => {
                return ctrl.type === 'datetime' ?
                    !ctrl.date || !ctrl.time :
                    !ctrl.date
            }
        }

        $scope.$watchGroup(['ctrl.date', 'ctrl.time'], (newVal, oldVal) => {
            if (newVal !== oldVal) {
                let modelValue = '';

                if (ctrl.date) {
                    modelValue += moment(ctrl.date).format('YYYY-MM-DD');
                }
                if (ctrl.type === 'datetime' && ctrl.time) {
                    modelValue += ('T' + moment(ctrl.time).format('HH:mm'));
                }

                ctrl.ngModel.$setViewValue(modelValue);
            }
        });
    }
})();