(() => {
    angular
        .module('App')
        .component('userManagementCreate', {
            template: require('./UserManagementCreate.html'),
            controllerAs: 'ctrl',
            bindings: {
                accountModuleToken: '<',
                userToken: '<',
                updateUsersOnClose: '<'
            },
            controller: ['$rootScope', '$scope', '$stateParams', '$element', 'Page',
                '$translate', '$timeout', '$http', 'ModalService', 'events', 'ToastFactory', 'UserManagementService',  UserManagementCreatePopupController]
        })
    function  UserManagementCreatePopupController($rootScope, $scope, $stateParams, $element, Page, $translate, $timeout, $http, ModalService,
                                                  events, ToastFactory, UserManagementService) {
        var ctrl = this, popup;
        let userChangeWatcher;

        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onClose = onClose;
        ctrl.onOpen = onOpen;
        ctrl.createUser = createUser;
        ctrl.pickPhoneCode = pickPhoneCode;
        ctrl.isLoading = true;

        // Init
        function setUserSettings(userSettings) {
            // Reset validation data
            ctrl.validationData = [];
            initUserChangeWatcher();

            // Hook up to for submit event
            var userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                ctrl.validationData = UserManagementService.validate(ctrl.userCreateModel, ctrl.settings);
                if (ctrl.validationData.length === 0) {
                    createUser();
                }
            });
            var userCancel = $rootScope.$on('GlobalFormCancel', function () {
                Page.stateGoPrevious('userManagement', $stateParams.token);
            });
            $scope.$on('$destroy', userSubmit);
            $scope.$on('$destroy', userCancel);

            // Create Model
            var country = userSettings.data.Country;
            ctrl.userCreateModel =  {
                AccountModuleId: userSettings.data.Settings.AccountModuleId,
                DepartmentId: userSettings.data.DepartmentId,
                Name: '',
                Title: '',
                Phone: '',
                PhoneCountryId:  country.CountryId,
                PhoneCode: country.PhoneCode,
                Email: '',
                Alias: '',
                UserGroups: userSettings.data.Settings.DefaultRoleList,
                SendLoginInfo: true,
                AdditionalDepartments: []
            };

            // handle custom user alias label (can be defined under UserModuleSettings)
            if (!(userSettings.data.UserAliasLabel)) {
                $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                    userSettings.data.Settings.UserAliasLabel = translation;
                });
            } else {
                userSettings.data.Settings.UserAliasLabel = userSettings.data.UserAliasLabel;
            }

            // add "formatet" array with job titles
            userSettings.data.Settings.PredefinedJobTitleOptionsList = userSettings.data.PredefinedJobTitleOptionsList

            // Data
            ctrl.settings = userSettings.data.Settings;
            ctrl.additionalDepartmentsEnabled = userSettings.data.AdditionalDepartmentsEnabled;
            ctrl.departmentSettings = {
                departments: userSettings.data.AllowedDepartments
            };
            ctrl.userGroupSettings = {
                usergroups: userSettings.data.AllowedUserGroups,
                locked: userSettings.data.Settings.DefaultRoleList,
                selected: userSettings.data.Settings.DefaultRoleList,
            };

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateGoBack('userManagement', $stateParams.token);
            });
        }

        // Methods
        function onPopupRegistered(popup) {
            popup.open();
        }

        function pickPhoneCode() {
            ModalService.showModal({
                templateUrl: '/Profile/PhoneCodePicker',
                controller: 'PhoneCodePickerController',
                inputs: {countryId: ctrl.userCreateModel.PhoneCountryId}
            }).then(function (modal) {
                // Returns the modal for further options if needed
                modal.scope.$watch('selectedCountry', function (newVal) {
                    if (newVal !== null) {
                        ctrl.userCreateModel.PhoneCountryId = newVal.CountryId;
                        ctrl.userCreateModel.PhoneCode = newVal.PhoneCode;
                    }
                });
            });
        }

        function initUserChangeWatcher() {
            let initializing = true;

            userChangeWatcher = $scope.$watch(() => ctrl.userCreateModel,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function createUser() {
            if (!ctrl.isSaving) {

                ctrl.isSaving = true;
                Page.startLoading();
                ctrl.validationData = [];

                $http.post('/UserManagement/CreateUser', ctrl.userCreateModel).then(() => {
                    ctrl.isSaving = false;
                    Page.stopLoading();

                    ToastFactory.successTranslated('USER_MANAGEMENT.SAVED');
                    ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.userCreateModel);
                    ctrl.close();

                }, function (response) {
                    ctrl.isSaving = false;
                    Page.stopLoading();

                    // Failed, show form validations if possible
                    if (response.status === 400) {
                        ctrl.validationData = response.data;
                        ToastFactory.warningTranslated('ERROR.FORM_VALIDATION');
                    } else {
                        ToastFactory.errorTranslated('ERROR.GENERAL');
                    }
                });
            }
        }

        function onClose() {
            $rootScope.disableVisibilitychange = false;
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
            UserManagementService.getSettings($stateParams.token).then((data) => {
                setUserSettings(data);
                ctrl.isLoading = false;
            })
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
            userChangeWatcher && userChangeWatcher();
        }
    }
})();