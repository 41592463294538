(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('actionSheet', {
            template: require('./ActionSheetComponent.html'),
            controller: ['$timeout', '$rootScope', '$element', 'Page', ActionSheetController],
            controllerAs: 'ctrl',
            bindings: {
                groups: '<',
                attached: '<',
                rect: '<',
                head: '<',
                click: '<',
                inline: '<',
                moduleToken: '<',
                moduleName: '<'
            }
        });

    function ActionSheetController($timeout, $rootScope, $element, Page) {
        var ctrl = this;


        ctrl.RTLStatus = $rootScope.isRTL;
        ctrl.$onInit = init;
        ctrl.mediaUploaded = mediaUploaded;

        ctrl.attached && $element.addClass('attached');

        if (ctrl.inline) {
            $element.addClass('inline');
            ctrl.click = ctrl.click || click;
        }


        function init() {
            // Check MediaUpload properties
            ctrl.groups.forEach(group => {
                Array.isArray(group) && group.forEach(button => {
                    if (button.mediaUpload) {
                        if (!button.mediaUpload.maxSize || !button.mediaUpload.maxSize.length) {
                            button.mediaUpload.maxSize = 100;
                        }
                    }
                });
            });
            
            ctrl.moduleName = ctrl.moduleName ?? Page.getBaseViewClass();
            ctrl.moduleToken = ctrl.moduleToken ?? Page.getCurrentToken();
        }

        function click($event, button, media) {
            if (button) {
                if (angular.isFunction(button.onClick)) {
                    button.onClick({button: button, media: media}, $event);
                }
            }
        }

        function mediaUploaded($media, button) {
            ctrl.click(null, button, $media);
        }
    }
})();

