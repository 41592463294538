(() => {
    'use strict'
    angular
        .module('Relesys.Ui')
        .component('rlTranslationInformation', {
            template: require('./TranslationInformation.html'),
            controller: [TranslationInformationController],
            controllerAs: 'ctrl',
            bindings: {
                showOriginal: '<',
                onButtonClick: '=?'
            }
        })

    function TranslationInformationController() {
        
    }
})();