(() => {
    'use strict';

    angular.module('App')
        .component('profileSettingsSecurityPassword', {
            template: require('./ProfileSettingsSecurityPasswordComponent.tpl.html'),
            controller: ['ProfileSettingsService', 'Page', '$rootScope', '$translate',
                'ConfirmPopupService', 'ToastFactory', 'ResponsiveService', '$scope', '$q', ProfileSettingsSecurityPasswordComponentController],
            controllerAs: 'ctrl',
            bindings: {
                canGoBackRequester: '='
            }
        });

    function ProfileSettingsSecurityPasswordComponentController(ProfileSettingsService, Page, $rootScope, $translate,
        ConfirmPopupService, ToastFactory, ResponsiveService, $scope, $q) {

        var ctrl = this,
            screenResizeWatcher,
            submitListener = null,
            cancelListener = null;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;
        ctrl.saveChanges = saveChanges;

        ctrl.overrideHasChanges = false;

        function onInit() {
            ctrl.isLoadingProfileData = true;
            ctrl.isSavingChanges = false;

            ctrl.canGoBackRequester = canGoBack;

            ctrl.isDesktop = ResponsiveService.isDesktop();
            screenResizeWatcher = $scope.$watch(() => ResponsiveService.isDesktop(), (isDesktop) => {
                if (isDesktop !== ctrl.isDesktop) {
                    ctrl.isDesktop = isDesktop;
                    screenSizeChange();
                }
            });

            ProfileSettingsService.getFullProfile().then(res => {
                ctrl.passwordEnabled = res.AllowPasswordChange;

                if (ctrl.passwordEnabled === false) {
                    Page.triggerBackButton();
                    return;
                }

                initForm();
                screenSizeChange();
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }

        function onDestroy() {
            if (submitListener) {
                submitListener();
            }

            if (cancelListener) {
                cancelListener();
            }

            if (screenResizeWatcher) {
                screenResizeWatcher();
            }
        }

        function screenSizeChange() {
            $rootScope.ShowFormSubmit = !ctrl.isDesktop;
        }

        function initForm() {
            ctrl.model = {
                currentPassword: null,
                newPassword: null,
                newPasswordRepeated: null
            };

            submitListener = $rootScope.$on('GlobalFormSubmit', () => {
                saveChanges();
            });
            cancelListener = $rootScope.$on('GlobalFormCancel', () => {
                goBack();
            });
        }

        function canGoBack() {
            var d = $q.defer();

            if (hasChanges() && !ctrl.overrideHasChanges) {
                confirmClose(() => {
                    d.resolve();
                });
            } else {
                d.resolve();
            }

            return d.promise;
        }

        function goBack() {
            if (hasChanges()) {
                confirmClose();
                return;
            }

            Page.triggerBackButton();
        }

        function hasChanges() {
            return ctrl.model.currentPassword
                || ctrl.model.newPassword
                || ctrl.model.newPasswordRepeated;
        }

        function confirmClose(callback) {
            $translate([
                'PROFILE.UNSAVED_CHANGES.TITLE',
                'PROFILE.UNSAVED_CHANGES.MESSAGE',
                'PROFILE.UNSAVED_CHANGES.DISCARD',
                'CANCEL'
            ]).then((res) => {
                ConfirmPopupService.open({
                    title: res['PROFILE.UNSAVED_CHANGES.TITLE'],
                    message: res['PROFILE.UNSAVED_CHANGES.MESSAGE'],
                    yesText: res['PROFILE.UNSAVED_CHANGES.DISCARD'],
                    noText: res['CANCEL'],
                    icon: null
                }).then(() => {
                    ctrl.overrideHasChanges = true;
                    if (callback) {
                        callback();
                    } else {
                        Page.triggerBackButton();
                    }
                });
            });
        }

        function saveChanges() {
            if (ctrl.isSavingChanges) {
                return;
            }

            if (validatePasswords() == false) {
                return;
            }

            ctrl.isSavingChanges = true;
            ProfileSettingsService.changePassword(ctrl.model.currentPassword, ctrl.model.newPassword).then(res => {
                ToastFactory.successTranslated('PROFILE.PASSWORD.CHANGES_SAVED');
                ctrl.overrideHasChanges = true;
                Page.triggerBackButton();
            }).catch(res => {
                ctrl.isSavingChanges = false;
                if (res) {
                    switch (res.ErrorCode) {
                        case 'OLD_PASSWORD_MISSING':
                            ToastFactory.warningTranslated('PROFILE.PASSWORD.CURRENT_PASSWORD_MISSING');
                            break;
                        case 'NEW_PASSWORD_MISSING':
                            ToastFactory.warningTranslated('PROFILE.PASSWORD.NEW_PASSWORD_MISSING');
                            break;
                        case 'INVALID':
                            ToastFactory.warningTranslated('PROFILE.PASSWORD.CURRENT_PASSWORD_INVALID');
                            break;
                        case 'TOO_SHORT':
                            $translate('PROFILE.PASSWORD.NEW_PASSWORD_TOO_SHORT', { LENGTH: res.PasswordMinimumLength }).then(trans => {
                                ToastFactory.warning(trans);
                            });
                            break;
                        case 'WEAK':
                            ToastFactory.warningTranslated('PROFILE.PASSWORD.NEW_PASSWORD_WEAK');
                            break;
                    }
                } else {
                    ToastFactory.errorTranslated('ERROR.GENERAL')
                }
            });
        }

        function validatePasswords() {
            if (ctrl.model.currentPassword === null || ctrl.model.currentPassword.length === 0) {
                ToastFactory.warningTranslated('PROFILE.PASSWORD.CURRENT_PASSWORD_MISSING');
                return false;
            }

            if (ctrl.model.newPassword === null || ctrl.model.newPassword.length === 0) {
                ToastFactory.warningTranslated('PROFILE.PASSWORD.NEW_PASSWORD_MISSING');
                return false;
            }

            if (ctrl.model.newPasswordRepeated === null || ctrl.model.newPasswordRepeated.length === 0) {
                ToastFactory.warningTranslated('PROFILE.PASSWORD.NEW_PASSWORD_REPEATED_MISSING');
                return false;
            }

            if (ctrl.model.newPasswordRepeated !== ctrl.model.newPassword) {
                ToastFactory.warningTranslated('PROFILE.PASSWORD.NEW_PASSWORD_MISSMATCH');
                return false;
            }

            return true;
        }
    }
}
)();