(function () {
    'use strict';

    angular.module('App')
        .component('aboutRelesys', {
            templateUrl: '/Scripts/Components/AboutRelesys/AboutRelesysComponent.tpl.html',
            controllerAs: 'ctrl',
            controller: ['$translate', AboutReslesysController]
        });

    function AboutReslesysController($translate) {
        var ctrl = this;

        ctrl.$onInit = function () {
            $translate('ABOUT_RELESYS.TEXT').then(function (translated) {
                ctrl.AboutRelesys = translated;
            })
        };
    }

})();