(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationItem', {
            template: require('./NotificationItem.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['Page', 'NotificationFactory', '$filter', 'ActionSheetService', 'TimeLocale',
                'ResponsiveService', 'REACTION_TYPE_ID', 'ToastFactory', notificationViewerController],
            bindings: {
                notification: '<',
                notificationChanged: '=',
                onOpenModuleNotificationsSettings: '<'
            }
        });


    function notificationViewerController(Page, NotificationFactory, $filter, ActionSheetService, TimeLocale,
                                          ResponsiveService, REACTION_TYPE_ID, ToastFactory) {
        var ctrl = this;

        ctrl.TimeLocale = TimeLocale;
        if (ctrl.notification) {
            ctrl.isReaction = ['like', 'love', 'haha', 'wow', 'sad', 'angry'].includes(ctrl.notification.Icon)
                || ctrl.notification.CustomIconId;
        }

        ctrl.openNotification = openNotification;
        ctrl.showOptions = showOptions;
        ctrl.getIcon = getIcon;
        ctrl.getReactionType = getReactionType;

        function openNotification(notification) {
            // Mark as read
            NotificationFactory.markAsRead(notification.NotificationId, true);
            // Attempt to open link
            Page.stateGoLink(notification.AppLinkId);
        }

        function showOptions(notification, event) {
            var buttons = [], actionSheet;

            if (notification.IsRead) {
                buttons.push({
                    text: 'NOTIFICATION.MARK_AS_UNREAD',
                    icon: 'save',
                    iconClass: 'icon-shape',
                    onClick: function () {
                        NotificationFactory.markAsUnRead(notification.NotificationId, true).then(function () {
                            // Do nothing
                        }, function () {
                            ToastFactory.errorTranslated('ERROR.GENERAL');
                        });
                    }
                });
            } else {
                buttons.push({
                    text: 'NOTIFICATION.MARK_AS_READ',
                    icon: 'save',
                    iconClass: 'icon-shape',
                    onClick: function () {
                        NotificationFactory.markAsRead(notification.NotificationId, true).then(function () {
                            // Do nothing
                        }, function () {
                            ToastFactory.errorTranslated('ERROR.GENERAL');
                        });
                    }
                });
            }

            // Delete
            buttons.push({
                text: 'NOTIFICATION.HIDE_THIS_NOTIFICATION',
                icon: 'eye-crossed',
                iconClass: 'icon-shape',
                onClick: function () {
                    notification.isRemoved = true;
                    NotificationFactory.delete(notification.NotificationId);
                }
            });

            // Disable Notifications from module
            if (notification.AllowAppUsersOverridingSettings) {
                const text = $filter('translate')('NATIVE_NOTIFICATIONS.MODULE.MANAGE')
                    + ' ' + notification.AppLinkId.Title.toLowerCase()
                    + ' ' + $filter('translate')('NOTIFICATION.TITLE').toLowerCase()
                buttons.push({
                    text: text,
                    icon: 'power-off',
                    iconClass: 'icon-off',
                    onClick: function () {
                        ctrl.onOpenModuleNotificationsSettings(notification);
                    }
                });
            }

            var headData = {
                description: notification.Message,
                image: getImage(notification),
                badgeIcon: getIcon(notification),
                badgeClass: notification.AppLinkId.PathState,
                reactionType: ctrl.isReaction ? ctrl.notification.CustomIconId || ctrl.getReactionType(ctrl.notification) : null,
            };

            if (ResponsiveService.isDesktop()) {
                actionSheet = ActionSheetService.create(buttons, event.currentTarget, headData, { name: 'notification-item'});
            } else {
                actionSheet = ActionSheetService.create(buttons, null, headData, { name: 'notification-item'});
            }
            actionSheet.show();
        }

        function getImage(notification) {
            if (notification.MediaId) {
                return {
                    src: notification.MediaId.ImageFormats.W100 || notification.MediaId.ThumbnailUrl,
                    type: 'img'
                };
            } else {
                return {
                    src: '/Content/Graphics/Profile/UserProfile.svg',
                    type: 'icon'
                };
            }
        }

        function getIcon(notification) {
            if (notification.Icon) {
                switch (notification.Icon) {
                    case 'heart':
                        return 'heart';
                    case 'newspaper':
                        return 'content';
                    case 'comment':
                        return 'comment';
                    case 'at':
                        return 'tag';
                    case 'users':
                        return 'tag';
                    case 'tasks':
                        return 'tasks';
                    case 'calendar':
                        return 'calendar';
                    case 'poll':
                        return 'survey';
                    case 'cap':
                        return 'cap';
                    case 'notifications':
                        return 'notifications';
                    case 'like':
                        return '/Content/Graphics/Social/like.svg';
                    case 'love':
                        return '/Content/Graphics/Social/love.svg';
                    case 'haha':
                        return '/Content/Graphics/Social/haha.svg';
                    case 'wow':
                        return '/Content/Graphics/Social/wow.svg';
                    case 'sad':
                        return '/Content/Graphics/Social/sad.svg';
                    case 'angry':
                        return '/Content/Graphics/Social/angry.svg';
                    case 'gavel':
                        return 'gavel';
                    default:
                        return 'content';
                }
            }

            return 'bell';
        }

        function getReactionType(notification) {
            if (notification.Icon) {
                return REACTION_TYPE_ID[notification.Icon.toUpperCase()];
            }
        }
    }
})();