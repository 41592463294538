(function () {
    'use strict';

    angular.module('App')
        .factory('ChatConnectionService', ['$http', 'ChatPopupsService', 'ChatDataService', 'NotificationFactory',
            'Menu', ChatConnectionService]);

    function ChatConnectionService($http, ChatPopupsService, ChatDataService, NotificationFactory, Menu) {

        let chatClient, channelItemEventsCallbacks = [], badge;

        return {
            connect: connect,
            reconnect: reconnect,
            isConnected: isConnected,
            getChatClient: getChatClient,
            onChannelListEvents: onChannelListEvents,
            onChannelItemEvents: onChannelItemEvents,
            onChannelLatMessageUpdate: onChannelLatMessageUpdate,
            removeChannelItemEvents: removeChannelItemEvents,
            destroyChannelItemsEvent: destroyChannelItemsEvent,
            openChannel: openChannel,
            openChannelFromPush: openChannelFromPush,
            destroy: destroy,
            getAllUsers: getAllUsers,
            setChatBadge: setChatBadge,
            getChatBadge: getChatBadge,
            onUpdateMessage: onUpdateMessage
        };

        function connect() {
            chatClient = new signalR.HubConnectionBuilder()
                .withUrl('/chat/v2/signalR')
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.None)
                .build();

            return chatClient.start();
        }

        function isConnected() {
            return chatClient.state === signalR.HubConnectionState.Connected;
        }

        function reconnect() {
            return chatClient.start();
        }

        function getChatClient() {
            return chatClient;
        }

        function destroy() {
            chatClient.stop()
        }

        function onChannelListEvents(callback) {
            chatClient.on('channelUpdated', event => callback(event));
            chatClient.on('channelCreated', event => callback(event));
            chatClient.on('usersAddedToChannel', event => callback(event));
            chatClient.on('usersRemovedFromChannel', event => callback(event));
        }

        function registerChannelItemsEvent(channelId) {
            chatClient.on('usersAddedToChannel', event => {
                runCallbacks(event)
            });
            chatClient.on('usersRemovedFromChannel', event => {
                runCallbacks(event)
            });
            chatClient.on('adminRightsAddedToUser', event => {
                runCallbacks(event)
            });
            chatClient.on('adminRightsRemovedFromUser', event => {
                runCallbacks(event)
            });

            function runCallbacks(event) {
                if (event.channelId === channelId) {
                    ChatDataService.getChannel(channelId).then(channel => {
                        channelItemEventsCallbacks.forEach(callback => {
                            callback(channel)
                        })
                    });
                }
            }
        }

        function onChannelItemEvents(channelId, callback) {
            if (!channelItemEventsCallbacks.length) {
                registerChannelItemsEvent(channelId)
            }
            channelItemEventsCallbacks.push(callback);
        }

        function removeChannelItemEvents(callback) {
            _.remove(channelItemEventsCallbacks, channelCallbacks => channelCallbacks === callback);
        }

        function destroyChannelItemsEvent() {
            channelItemEventsCallbacks = [];
            chatClient.off('usersAddedToChannel');
            chatClient.off('usersRemovedFromChannel');
            chatClient.off('adminRightsAddedToUser');
            chatClient.off('adminRightsRemovedFromUser');
        }

        function onChannelLatMessageUpdate(callback) {
            chatClient.on('lastChannelMessageChanged', event => {
                callback(event)
            })
        }

        function onUpdateMessage(callback) {
            chatClient.on('updateMessage', data => {
                const isModified = true;

                ChatDataService.getChannelMessages(data.item.channelId, {limit: '1'})
                .then (({messages}) => { 

                    if (messages.length > 0) {
                        const lastMessageId = messages[0].item.messageId;
                        if (lastMessageId === data.item.messageId) {
                            return callback(data.item, isModified)
                        }
                    }
                });

                return;
            });
        }

        function getAllUsers(offset, limit, name, userIdsToExclude) {
            return $http.post('/chat/v2/getUsers', {
                offset: offset,
                limit: limit,
                name: name,
                userIdsToExclude: userIdsToExclude
            }).then(resp => {
                return {
                    Users: resp.data.items,
                    MoreUsers: resp.data.moreItems
                }
            });
        }

        function setChatBadge(count) {
            badge = count;
            NotificationFactory.setNativeBadgeCount(null, null, count);

            if (Menu.getChatNavigationBadge()) {
                Menu.updateActivity(count, 'chat', Menu.getChatNavigationBadge().ChatModuleToken);
                Menu.updateLandingBadge(count, Menu.getChatNavigationBadge().AccountModuleId, Menu.getChatNavigationBadge().ModuleId);
            }
        }

        function getChatBadge() {
            return badge;
        }

        function openChannel(channelId, channel, onClose) {
            ChatPopupsService.openConversationPopup(channelId, channel, onClose);
        }

        function openChannelFromPush(channelId, messageId, onClose) {
            ChatPopupsService.openConversationPopupFromPush(channelId, messageId, onClose);
        }
    }
})();