(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('ChartHelper', ['$q', function ($q) {
        var chartColorArray = ["#2e75b5", "#2f5496", "#9cc3e5", "#8496b0", "#c9c9c9", "#44546a", "#5b9bd5", "#4472c4", "#ef5350", "#ec407a", "#ab47bc", "#7e57c2", "#5c6bc0",
        "#42a5f5", "#29b6f6", "#26c6da", "#26a69a", "#66bb6a", "#9ccc65", "#d4e157", "#ffee58", "#ffca28", "#ffa726", "#ff7043", "#8d6e63", "#bdbdbd"];

        var f = {
            formatNumber: function (number, decimalsLength, decimalSeparator, thousandSeparator) {
                if (number) {
                    var n = number,
                        decimalsLength = isNaN(decimalsLength = Math.abs(decimalsLength)) ? 2 : decimalsLength,
                        decimalSeparator = decimalSeparator == undefined ? "," : decimalSeparator,
                        thousandSeparator = thousandSeparator == undefined ? "." : thousandSeparator,
                        sign = n < 0 ? "-" : "",
                        i = parseInt(n = Math.abs(+n || 0).toFixed(decimalsLength)) + "",
                        j = (j = i.length) > 3 ? j % 3 : 0;

                    return sign +
                        (j ? i.substr(0, j) + thousandSeparator : "") +
                        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
                        (decimalsLength ? decimalSeparator + Math.abs(n - i).toFixed(decimalsLength).slice(2) : "");
                }
                return 0;
            },
            chartColor: function (index) {
                var i = index;
                if (index === undefined || index > (chartColorArray.length - 1)) {
                    i = Math.floor(Math.random() * (chartColorArray.length - 1));
                }

                return chartColorArray[i];
            },
            HexToRGB: function(hex) {
                // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b;
                });

                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16),
                    full: "rgb(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + ")"
                } : null;
            },
            HexToRGBA: function(hex, opacity){
                var rgb = f.HexToRGB(hex);
                return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + opacity + ")";
            },
            RGBToHex: function(r, g, b) {
                return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            }
        };

        return f;
    }]);
})();