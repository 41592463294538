(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('serviceFormOverview', {
                        parent: 'base',
                        url: '/serviceFormOverview/:token',
                        params: {
                            backState: null,
                            data: {}
                        },
                        template: `<forms-overview></forms-overview>`,
                        controller: 'FormsOverviewController as ctrl'
                    })
            }
        ])
        .controller("FormsOverviewController", ['$scope', '$stateParams', 'Page', 'events',
            function ($scope, $stateParams, Page, events) {
                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token);
                } else {
                    Page.showBackButton('home');
                }

                $scope.$on(events.DEPARTMENT_CHANGED, function (args) {
                    Page.stateReload();
                });
            }]);
})();