(() => {

    angular
        .module('App')
        .factory('ContentLibraryService', ['CookieService', ContentLibraryService])

    function ContentLibraryService(CookieService) {
        const COOKIE_PROGRESS_KEY = 'contentReadingProgress';

        return {
            prepareLibraryItem: prepareLibraryItem,
            saveReadingProgress: saveReadingProgress,
            getItemReadingProgress: getItemReadingProgress,
        }

        function prepareLibraryItem(content) {
            const reg = /<h1>(<br>|<br\/>|)<\/h1>/g;
            const sanitizedContent = content.replace(reg, "");

            const preparedBodyText = prepareLibraryItemContent(sanitizedContent);
            const preparedBodyHeaders = prepareLibraryItemHeaders(sanitizedContent);

            let bodyText = preparedBodyText;
            let bodyHeaders = preparedBodyHeaders;

            if (preparedBodyHeaders && preparedBodyHeaders.length && preparedBodyText && preparedBodyText.length) {

                bodyText = [];
                bodyHeaders = [];

                preparedBodyHeaders.forEach((e, i) => {
                    if (e.length) {
                        bodyHeaders.push(e);
                        bodyText.push(preparedBodyText[i]);
                    } else if (bodyText.length > 0) {
                        bodyText[bodyText.length - 1] = bodyText[bodyText.length - 1] + preparedBodyText[i];
                    }
                });

                if (bodyHeaders.length === 0) {
                    bodyHeaders = null;
                }
            }

            if (!bodyHeaders || !bodyHeaders.length) {
                bodyText = content;
            }

            return {
                bodyHeaders: bodyHeaders,
                bodyText: bodyText
            };
        }

        function prepareLibraryItemContent(content) {
            const reg = /<h1>.*?<\/h1>[\w\W]*?(?=<h1>.*?<\/h1>)|<h1>.*?<\/h1>[\w\W]*$/mgi;

            return content.match(reg) || content;
        }

        function prepareLibraryItemHeaders(content) {
            const reg = /<h1>(.*?)<\/h1>/mgi;
            return content.match(reg) &&
                content
                    .match(reg)
                    .map(val => {
                        const el = document.createElement("div");
                        el.innerHTML = val;
                        return el.innerText || "";
                    });
        }

        function saveReadingProgress(moduleToken, itemToken, progress) {
            const itemsData = getModuleReadingProgress(moduleToken);
            itemsData[itemToken] = {current: progress.current, total: progress.total};

            CookieService.set(COOKIE_PROGRESS_KEY + '-' + moduleToken, JSON.stringify(itemsData));
        }

        function getItemReadingProgress(moduleToken, itemToken) {
            return getModuleReadingProgress(moduleToken)[itemToken] || {};
        }

        function getModuleReadingProgress(moduleToken) {
            const data = CookieService.get(COOKIE_PROGRESS_KEY + '-' + moduleToken);
            return data ? JSON.parse(data) : {};
        }
    }
})();