(function () {
    'use strict';

    angular
        .module('App')
        .component('ratePopup', {
            template: require('./RatePopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', 'ToastFactory', 'ServiceFormRenderService', 'ConfirmPopupService', RatePopupController],
            bindings: {
                title: '<',
                onRate: '<',
                fillingId: '<',
                isEdit: '<',
                selectedRating: '<',
                averageRating: '<',
            }
        })

    function RatePopupController($scope, $element, ToastFactory, ServiceFormRenderService, ConfirmPopupService) {
        const ctrl = this;

        ctrl.ratingRange = ServiceFormRenderService.getFeedbackRatingRange();
        ctrl.getRatingColorClass = ServiceFormRenderService.getRatingColorClass;
        ctrl.getRatingIcon = ServiceFormRenderService.getRatingMedia;
        ctrl.isRatingInProgress = false;
        
        ctrl.rate = rate;
        ctrl.remove = remove;
        ctrl.onClose = onClose;
        ctrl.calculateAverageRate = calculateAverageRate;
        ctrl.showRatingSelector = showRatingSelector;
        ctrl.isButtonDisabled = isButtonDisabled;
        
        function calculateAverageRate() {
            if (!ctrl.averageRating) {
                ToastFactory.warningTranslated('SERVICEFORM.FEEDBACK.AVERAGE_WARNING');
                return;
            }
            
            ctrl.selectedRating = ctrl.averageRating;
            rate();
        }
        
        function showRatingSelector() {
            ctrl.ratingSelector = true;            
        }

        function rate() {
            if (ctrl.isRatingInProgress) {
                return;
            }

            ctrl.isRatingInProgress = true;

            ctrl.closeAction();
            ctrl.onRate && ctrl.onRate(ctrl.selectedRating, ctrl.ratingComment)
                .then(() => {
                    ToastFactory.successTranslated('SERVICEFORM.FEEDBACK.RATE_SUCCESS');
                })
                .catch(() => {
                    ToastFactory.errorTranslated();
                })
                .finally(() => {
                    ctrl.isRatingInProgress = false;
                });
        }

        function remove() {
            ConfirmPopupService
                .open({
                    title: 'SERVICEFORM.FEEDBACK.REMOVE_FEEDBACK',
                    message: 'SERVICEFORM.FEEDBACK.SURE_REMOVE',
                }).then(() => {
                    ctrl.onRate && ctrl.onRate(null)
                        .then(() => {
                            ToastFactory.successTranslated('SERVICEFORM.FEEDBACK.RATING_DELETED');
                            ctrl.closeAction();
                        })
                        .catch(() => {
                            ToastFactory.errorTranslated()
                        });
                });
        }
        
        function isButtonDisabled() {
            return typeof ctrl.selectedRating !== 'number' && !ctrl.selectedRating;
        }

        function onClose() {
            $scope.$destroy();
            $element.remove()
        }
    }
})();