(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('popup', ['$timeout',
        function ($timeout) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/Popup.tpl.html',
                link: function (scope, element, attrs) {
                    var $element = $(element);
                    scope.PopupContent.Shown = true;

                    scope.ClosePopupInternal = function () {
                        scope.PopupContent.Shown = false;
                        $timeout(function () {
                            scope.PopupClosed();
                            $element.remove();
                            scope.$destroy();
                        }, 300);
                    };
                }
            }
        }
    ]);
})();