(function () {
    'use strict';

    var app = angular.module('App');

    app.component('checkList', {
        templateUrl: '/Scripts/Components/CheckList/CheckListComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$scope', '$q', '$timeout', '$rootScope', 'ServiceFormRenderService', CheckListController],
        bindings: {
            items: '<',
            isMultiselect: '<',
            min: '<',
            max: '<',
            field: '<',
        },
        require: {
            ngModelCtrl: 'ngModel'
        }
    });

    function CheckListController($scope, $q, $timeout, $rootScope, ServiceFormRenderService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.showPopup = showPopup;
        ctrl.inUseLabel = ServiceFormRenderService.getLabelSettings().TranslatedInUseLabel || ServiceFormRenderService.getLabelSettings().InUseLabel;

        function init() {
            ctrl.items = _.cloneDeep(ctrl.items);
            $timeout(function () {
                setEditData(_.isArray(ctrl.ngModelCtrl.$viewValue) ? ctrl.ngModelCtrl.$viewValue : [ctrl.ngModelCtrl.$viewValue]);
                initWatchers();
                if (ctrl.min && ctrl.isMultiselect) {
                    if (ctrl.field.IsRequired) {
                        ctrl.ngModelCtrl.$validators.require = function (modelValue, viewValue) {
                            var value = modelValue || viewValue;
                            return value && value.length >= 1;
                        }
                    }

                    ctrl.ngModelCtrl.$validators.min = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;
                        if (value && value.length >= 1) {
                            return value.length >= ctrl.min;
                        } else {
                            return true
                        }
                    };
                    ctrl.ngModelCtrl.$validate();
                }
            });
        }

        function initWatchers() {
            if (ctrl.isMultiselect) {
                $scope.$watch('ctrl.items', function (newVal, oldVal) {
                    if (newVal) {
                        ctrl.disabled = ctrl.max && (filterItems(newVal).length === ctrl.max);
                        ctrl.ngModelCtrl.$setViewValue(filterItems(newVal));
                    }
                }, true);
            } else {
                $scope.$watch(function () {
                    return ctrl.ngModelCtrl.$viewValue
                }, setLoadedDataRadio, true);
                $scope.$watch('ctrl.radioValue', function (newVal) {
                    if (newVal && newVal.inUse && newVal.actionType) {
                        return;
                    }

                    ctrl.ngModelCtrl.$setViewValue([newVal]);
                });
            }
        }

        function showPopup($event, item) {
            $event?.preventDefault();

            return ServiceFormRenderService.checkLimitRuleAndShowPopup(item, () => {
                $rootScope.$broadcast('goToLimitedReports', {
                    item,
                    listData: ctrl.items,
                    DataListFilters: {
                        DataListItemId: item.DataListItemId,
                        ServiceFormFieldId: ctrl.field.ServiceFormFieldId
                    }
                })
                destroy();
            });
        }

        function setEditData(items) {
            _.forEach(ctrl.items, function (item, key) {
                if (_.find(items, {'DataListItemId': item.DataListItemId})) {
                    ctrl.items[key] = _.find(items, {'DataListItemId': item.DataListItemId});
                }
            })
        }

        function filterItems(items) {
            return items.filter(function (item) {
                return item.checked === true;
            })
        }

        function setLoadedDataRadio(items) {
            ctrl.radioValue = _.isArray(items) ? items[0] : items;
        }

        function destroy() {
            _.each(ctrl.items, function (item) {
                item.checked = false;
            })
        }
    }
})();