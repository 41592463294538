(() => {
    'use strict';

    angular.module('App')
        .component('profileSettingsCelebrations', {
            template: require('./ProfileSettingsCelebrationsComponent.tpl.html'),
            controller: ['ProfileSettingsService', 'Page', ProfileSettingsCelebrationsComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsCelebrationsComponentController(ProfileSettingsService, Page) {
        var ctrl = this;

        ctrl.$onInit = onInit;

        function onInit() {
            ctrl.isLoadingProfileData = true;

            ProfileSettingsService.getFullProfile().then(res => {

                if (!res.CelebrationsFeatureEnabled) {
                    Page.triggerBackButton();
                }

                ctrl.allowCelebrations = ProfileSettingsService.createToggleProperty(res, 'CelebrationsEnabled');
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }
    }
})();