(function () {
    'use strict';

    angular
        .module('App')
        .component('fillingPreviewPopup', {
            template: require('./FillingPreviewPopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$timeout', '$element', '$http', '$rootScope', 'ServiceFormRenderService',
                'Page', 'HeaderButtonsFactory', 'ActionSheetService', 'PopupWrapperService',
                'ServiceFormRestService', 'FillingPreviewService', 'FILLING_VIEW', 'ReportPopupService', FillingItemFullController],
            bindings: {
                onClose: '<',
                isPreview: '<',
                serviceFormId: '<',
                accountModuleToken: '<',
                fillingDeleted: '&',
                fillingCreated: '&',
                isDraftEnabled: '<',
                onPopupRegistered: '<',
                copyFillingsEnabled: '<',
                isCollaborationEnabled: '<',
                isStagesEnabled: '<',
                isReadOnly: '<',
                isDynamic: '<'
            }
        });

    function FillingItemFullController($scope, $timeout, $element, $http, $rootScope, ServiceFormRenderService,
                                       Page, HeaderButtonsFactory, ActionSheetService, PopupWrapperService,
                                       ServiceFormRestService, FillingPreviewService, FILLING_VIEW, ReportPopupService) {
        const ctrl = this;
        let popup, updateWatcher, closeRenderWatcher;

        closeRenderWatcher = $rootScope.$on('fillingPreviewRendered', () => {
            if (ctrl.ScrollToFeedbackId) {
                const feedbackId = parseInt(ctrl.ScrollToFeedbackId),
                    scrollEl = $element.find('.field-' + feedbackId),
                    commentId = parseInt(ctrl.data.CommentId),
                    feedback = ctrl.data.FeedbackData.FieldsFeedback
                        .find(feedback => feedback.ServiceFormFieldId === feedbackId).Feedback;

                if (scrollEl && scrollEl.length) {
                    $element.find('filling-preview').scrollTop(scrollEl.offset().top);
                }

                if (feedback.CommentsCount > 3) {
                    FillingPreviewService.openFormFeedbackComments(feedback, null, commentId);
                }
            }
        })

        ctrl.popupId = 'fillingPreviewPopupId';
        ctrl.FILLING_VIEW = FILLING_VIEW;

        ctrl.$onInit = init;
        ctrl.close = close;
        ctrl.open = open;
        ctrl.openOptions = openOptions;
        ctrl.continueFilling = continueFilling;
        ctrl.changeView = changeView;
        ctrl.onStageUpdate = onStageUpdate;
        ctrl.updateUncompletedFilling = updateUncompletedFilling;
        ctrl.shimmer = {
            containerCount: 4,
            minWidth: 40,
            maxWidth: 100,
            lineCount: 4
        }

        function init() {
            ctrl.labels = ServiceFormRenderService.getLabelSettings;
            ctrl.settings = ServiceFormRenderService.fillingInteractionSettings;
        }

        function open(data, popupSrc) {
            popup = popupSrc;
            updateWatcher = $scope.$on('fillingUpdated', fillingUpdated);
            if (typeof data === 'string') {
                ServiceFormRestService.getFillingData(data).then(initData);
            } else {
                initData(data)
            }
        }

        function initData(data) {
            ctrl.ScrollToFeedbackId = data.ScrollToFeedback;
            ctrl.filling = ServiceFormRenderService.formatFillingGropsMedia(data.Filling || data);
            ctrl.data = data;
            ctrl.currentView = ctrl.data.DefaultFillingView === 2 ? FILLING_VIEW.SCORE : FILLING_VIEW.FORM;

            ctrl.hasFeedback = hasFeedback();
            ctrl.showTabs = !ctrl.isStagesEnabled && ctrl.hasFeedback || ctrl.data.ScoreReport;

            if (ctrl.showTabs && ctrl.hasFeedback && data.ShowFeedbackPage) {
                ctrl.currentView = FILLING_VIEW.FEEDBACK;
            }
            if (!data.ShowFeedbackPage && data.CommentId && !ctrl.ScrollToFeedbackId) {
                $scope.$watch('ctrl.openComments', (isLoaded) => {
                    isLoaded && ctrl.openComments(data.CommentId);
                })
            }
            if (data.ShowFeedbackPage && data.CommentId && !ctrl.ScrollToFeedbackId) {
                $scope.$watch('ctrl.openFeedbackComments', (isLoaded) => {
                    isLoaded && ctrl.openFeedbackComments(data.CommentId)
                })
            }
        }

        function fillingUpdated(ev, data) {
            if (data.Filling.ServiceFormFillingToken === ctrl.data.Filling.ServiceFormFillingToken) {
                ctrl.filling.IsDraft = data.Filling.IsDraft;
                ctrl.filling.ServiceFormFillingStatus = data.Filling.ServiceFormFillingStatus;
            }
        }

        function close(triggeredOutside) {
            if (!triggeredOutside && canLeaveFeedback() && !ctrl.data.Filling.IsDraft) {
                const fields = ctrl.data.Stages?.length > 0 ? ctrl.data.Stages.map(stage => stage.Fields).flat() : ctrl.data.Filling.Fields;
                const averageScore = ServiceFormRenderService.getAverageFeedbackRating(fields);
                
                FillingPreviewService.showWantRate(ctrl.data.Filling.ServiceFormFillingId, resp => {
                    ctrl.data.Filling.Rating = resp.data.Rating;
                    ctrl.data.Filling.ServiceFormFeedbackRangeId = resp.data.ServiceFormFeedbackRangeId;
                    closePopup();
                }, closePopup, averageScore);
            } else {
                closePopup();
            }

            function closePopup() {
                ctrl.data?.Filling
                && ServiceFormRenderService.updateFilling(ctrl.data.Filling.ServiceFormFillingToken, ctrl.data.Filling);
                delete ctrl.data;
                ctrl.onClose && ctrl.onClose(popup, $element);
                updateWatcher && updateWatcher();
                closeRenderWatcher && closeRenderWatcher();

                if (ctrl.isDynamic) {
                    popup.remove();
                    $element.remove();
                    $scope.$parent.$destroy();
                } else {
                    popup.hide();
                }
            }
        }

        function openOptions($event) {
            const options = [];

            if (ctrl.data.Filling.IsFollowed) {
                options.push({
                    text: 'SERVICEFORM.FILLING.UNFOLLOW',
                    icon: 'notifications',
                    prefix: 'fas',
                    iconClass: 'label-color active',
                    onClick: () => {
                        FillingPreviewService.unfollow(ctrl.data.Filling.ServiceFormFillingToken).then(() => {
                            ctrl.data.Filling.IsFollowed = false;
                        })
                    }
                });
            } else {
                options.push({
                    text: 'SERVICEFORM.FILLING.FOLLOW',
                    icon: 'notifications',
                    onClick: () => {
                        FillingPreviewService.follow(ctrl.data.Filling.ServiceFormFillingToken).then(() => {
                            ctrl.data.Filling.IsFollowed = true;
                        })
                    }
                });
            }

            options.push({
                text: (ctrl.settings.AllowAutomaticTranslation
                    && ctrl.data.Filling.UserCultureSupportsAutoTranslation
                    && ctrl.showTranslationButton) ?
                    'SERVICEFORM.FILLING.DOWNLOAD_PDF_ORIGINAL' : 'SERVICEFORM.FILLING.DOWNLOAD_PDF',
                icon: 'pdf',
                prefix: 'fal',
                link: '/ServiceForm/GetFillingPdfReport/' + ctrl.data.Filling.ServiceFormFillingToken
            });
            if (ctrl.settings.AllowAutomaticTranslation
                && ctrl.data.Filling.UserCultureSupportsAutoTranslation
                && ctrl.showTranslationButton) {
                options.push({
                    text: 'SERVICEFORM.FILLING.DOWNLOAD_PDF_TRANSLATED',
                    icon: 'pdf',
                    prefix: 'fal',
                    onClick: () => {
                        FillingPreviewService.translatePdf(ctrl.data)
                    }
                });
            }

            if (ctrl.isPreview) {
                options.push({
                    text: 'Open in SF',
                    icon: 'tasks',
                    onClick: () => {
                        $timeout(() => {
                            Page.stateGo('serviceForm', ctrl.accountModuleToken,
                                '?ServiceFormFillingToken=' + ctrl.data.Filling.ServiceFormFillingToken +
                                '&ServiceFormId=' + ctrl.serviceFormId
                            );
                        })
                    }
                });
            } else {
                if (ctrl.isDraftEnabled && ctrl.copyFillingsEnabled) {
                    options.push({
                        text: 'SERVICEFORM.FILLING.DUPLICATE',
                        icon: 'copy',
                        onClick: function () {
                            $http.post('/ServiceForm/Copy', {
                                token: ctrl.data.Filling.ServiceFormFillingToken
                            }).then(function (resp) {
                                var filling = resp.data.Filling;
                            filling.createdFromCopy = true;
                                PopupWrapperService
                                    .getPopup(ServiceFormRenderService.updateFillingPopupId)
                                    .open({Filling: filling});
                                close();
                                ctrl.data.FillingCreated && ctrl.data.FillingCreated();
                            })
                        }
                    });
                }

                if (!ctrl.isStagesEnabled && ctrl.isCollaborationEnabled && ctrl.data.Filling.IsDraft && !ctrl.data.Filling.IsCreatedByRequestingUser) {
                    options.push({
                        text: 'SERVICEFORM.FILLING.CONTINUE',
                        icon: 'edit',
                        onClick: function () {
                            $scope.$broadcast('continueFilling')
                        }
                    });
                } else if (!ctrl.isStagesEnabled && ctrl.data.Filling.ModerateAllowed) {
                    options.push({
                        text: 'SERVICEFORM.FILLING.EDIT',
                        icon: 'edit',
                        onClick: function () {
                            $scope.$broadcast('editFilling')
                        }
                    });
                }

                if (ctrl.data?.FeedbackData) {
                    if (canLeaveFeedback() && ctrl.data.Filling.ServiceFormFillingStatus?.AdditionalCategoryId === 5) {
                        options.push({
                            text: 'SERVICEFORM.FEEDBACK.RATE_REPORT',
                            icon: 'star',
                            onClick: rateTheForm
                        });
                    } else if (ctrl.data.FeedbackData.FillingFeedback?.IsOwn && ctrl.data.FeedbackData.CanLeaveFeedback) {
                        options.push({
                            text: 'SERVICEFORM.FEEDBACK.EDIT_RATING',
                            icon: 'star',
                            onClick: onEditFeedback
                        });
                    }
                }

                if (Page.getSettings()?.ContentReportingEnabled) {
                    options.push({
                        text: 'REPORT.TITLE',
                        icon: 'exclamation-circle',
                        onClick: reportFilling
                    });
                }
                
                if (ctrl.data.Filling.CanDelete) {
                    options.push({
                        text: 'SERVICEFORM.FILLING.DELETE',
                        icon: 'delete',
                        iconClass: 'red',
                        onClick: function () {
                            $scope.$broadcast('deleteFilling')
                        }
                    });
                }
            }

            ActionSheetService.create(options, $event.currentTarget, null, { name: 'filling-preview-popup', token: ctrl.accountModuleToken }).show();
        }

        function onReportSubmit(model) {
            return ReportPopupService.reportFilling({
                token: ctrl.data.Filling.ServiceFormFillingToken,
                model,
            })
        }

        function updateUncompletedFilling(fillingLatest) {
            if (ctrl.data) {
                ctrl.data.Filling = fillingLatest.Filling;
            }
            ctrl.filling = fillingLatest.Filling;
        }

        function onStageUpdate(newStatus) {
            ctrl.data.Filling.ServiceFormFillingStatus = newStatus;
            ctrl.filling.ServiceFormFillingStatus = newStatus;
        }

        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportFilling() {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit);
        }

        function rateTheForm() {
            const fields = ctrl.data.Stages?.length > 0 ? ctrl.data.Stages.map(stage => stage.Fields).flat() : ctrl.data.Filling.Fields;
            const averageScore = ServiceFormRenderService.getAverageFeedbackRating(fields);
            
            FillingPreviewService.rateReport(ctrl.data.Filling.ServiceFormFillingId, resp => {
                ctrl.data.FeedbackData.FillingFeedback = resp.data
                ctrl.data.Filling.Rating = resp.data.Rating;
                ctrl.data.Filling.ServiceFormFeedbackRangeId = resp.data.ServiceFormFeedbackRangeId;
                FillingPreviewService.getFillingStatus(ctrl.data.Filling.ServiceFormFillingToken)
                    .then(newStatus => ctrl.data.Filling.ServiceFormFillingStatus = newStatus);
            }, averageScore);
        }

        function canLeaveFeedback() {
            return ctrl.data?.FeedbackData?.CanLeaveFeedback && !ctrl.data?.FeedbackData?.FillingFeedback;
        }

        function onEditFeedback() {
            const fields = ctrl.data.Stages?.length > 0 ? ctrl.data.Stages.map(stage => stage.Fields).flat() : ctrl.data.Filling.Fields;
            const averageScore = ServiceFormRenderService.getAverageFeedbackRating(fields);
            
            FillingPreviewService.showRatePopup('SERVICEFORM.FEEDBACK.EDIT_RATING', (rate) => {
                const path = '/ServiceForm/' + (rate !== null ? 'UpdateFeedback' : 'DeleteFeedback');

                return $http.post(path, {
                    serviceFormFeedbackId: ctrl.data.FeedbackData.FillingFeedback.ServiceFormFeedbackId,
                    rating: rate
                }).then((resp) => {
                    ctrl.data.FeedbackData.FillingFeedback = resp.data;
                    ctrl.data.Filling.Rating = resp.data.Rating;
                    ctrl.data.Filling.ServiceFormFeedbackRangeId = resp.data.ServiceFormFeedbackRangeId;
                    ServiceFormRenderService
                        .updateFilling(ctrl.data.Filling.ServiceFormFillingToken, ctrl.data.Filling);
                    FillingPreviewService.getFillingStatus(ctrl.data.Filling.ServiceFormFillingToken)
                        .then(newStatus => ctrl.data.Filling.ServiceFormFillingStatus = newStatus);                })
            }, true, ctrl.data.FeedbackData.FillingFeedback.Rating, averageScore);
        }

        function continueFilling() {
            $scope.$broadcast('continueFilling')
        }

        function changeView(view) {
            ctrl.currentView = view;
        }

        function hasFeedback() {
            return ctrl.data.FeedbackData && !!ctrl.data.FeedbackData.FieldsFeedback.find(feedback => feedback.Feedback !== null)
        }
    }
})();
