(() => {
    'use strict';

    angular
        .module('App')
        .directive('autoHeight', [function () {
            return {
                link: function ($scope, $element, $attrs) {
                    let initialHeight = $element[0].scrollHeight;
                    $element.on('input paste', testHeight);

                    if ($attrs.ngModel) {
                        $scope.$watch($attrs.ngModel, (newValue, oldValue) => {
                            if (newValue !== oldValue && !newValue?.length) {
                                testHeight();
                            }
                        });
                    }

                    function testHeight() {
                        if (initialHeight !== $element[0].scrollHeight) {
                            $element[0].style.height = '';
                            $element[0].style.height = ($element[0].scrollHeight) + 'px';
                        }
                    }
                }
            }
        }]);
})();