(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('ProfilePointLogController', ['$scope','$http', 'close', '$translate', '$timeout', 'TimeLocale', 'Page',
        function ($scope, $http, close, $translate, $timeout, TimeLocale, Page) {
            Page.setMainContainerInert(true);

            $scope.loading = true;
            $scope.logs = [];

            // Create and translate header
            $scope.title = "";
            $translate('LOADING').then(function (translation) {
                $scope.title = translation + "...";
            });

            $scope.close = function() {
                Page.setMainContainerInert(false);
                close();
            };

            $scope.showDate = function (data) {
              return TimeLocale.dateTimeFormat(data, 'D MMM YYYY');
            };

            $http.get('/Profile/GetPointLog/')
                .then(function (response) {
                    $scope.logs = response.data;
                    $translate('POINTS').then(function (translation) {
                        $scope.title = translation;
                    });

                    $timeout(function () {    
                        $scope.loading = false;
                    });
                }, function () {
                    $scope.loading = false;
                });
        }
    ]);

})();