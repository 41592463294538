(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('rlTextInput', {
            template: require('./RelesysTextInputComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', RlTextInputController],
            require: {
                ngModel: 'ngModel'
            },
            bindings: {
                customId: '@',
                type: '@',
                placeholder: '@',
                isRequired: '<',
                highlight: '<',
                ngDisabled: '='
            }
        });

    function RlTextInputController($scope) {
        const ctrl = this;

        $scope.$watch('ctrl.model', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                ctrl.ngModel.$setViewValue(newValue);
            }
        });

        ctrl.$onInit = init;

        function init() {
            if(!ctrl.type || !ctrl.type.length) {
                ctrl.type = 'text';
            }

            ctrl.ngModel.$isEmpty = (val) => {
                return val === undefined || !val?.length
            }
            ctrl.ngModel.$render = () => {
                ctrl.model = ctrl.ngModel.$viewValue;
            };
        }
    }
})();