(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('walkthroughSlidePolicy', ['$translate', 'toastr', '$http',
        function ($translate, toastr, $http) {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough-Slide-Policy.tpl.html',
                scope: {
                    slide: '=',
                    parent: '=',
                    done: '&'
                },
                controller: ['$scope', '$element', function ($scope, $element) {

                    // Init
                    InitDirective();
                    function InitDirective() {
                        // Create object
                        $scope.data = {
                            Title: $scope.slide.SlideData.Policy.Title,
                            Body: $scope.slide.SlideData.Policy.Body,
                            Deny: false,
                            DenyText: ''
                        };

                        $translate('WALKTHROUGH.TERMS.FAILURE').then(function (translation) {
                            $scope.data.DenyText = translation.replace('[TERMS]', $scope.data.Title);
                        });
                    }

                    // Methods
                    function agree() {
                        if (!$scope.slide.Loading) {
                            $scope.slide.Loading = true;

                            var token = $scope.slide.SlideData.Policy.PolicyToken;

                            $http.post('/Walkthrough/ApprovePolicy/?policyToken=' + token).then(function (response) {
                                $scope.slide.Loading = false;
                                if (response.status === 201) {
                                    done();
                                } else {
                                    $scope.slide.SlideData.Policy = response.data;

                                    $translate('WALKTHROUGH.TERMS.WRONG_VERSION').then(function (translation) {
                                        toastr.error(translation);
                                    });
                                }
                            }, function () {
                                $scope.slide.Loading = false;

                                $translate('ERROR.GENERAL').then(function (translation) {
                                    toastr.error(translation);
                                });
                            });
                        }
                    }

                    function deny() {
                        $scope.data.Deny = true;
                    }

                    function back() {
                        $scope.data.Deny = false;
                    }

                    function done() {
                        $($scope.parent).removeClass('swiper-no-swiping');
                        $scope.done();
                    }

                    // Objects

                    // Bindings
                    $scope.Deny = deny;
                    $scope.Back = back;
                    $scope.Agree = agree;
                }]
            };
        }
    ]);
})();