(() => {
    angular
        .module('App')
        .component('userManagementUpdate', {
            template: require('./UserManagementUpdate.html'),
            controllerAs: 'ctrl',
            bindings: {
                accountModuleToken: '<',
                userToken: '<',
                updateUsersOnClose: '<'
            },
            controller: ['$rootScope', '$scope', '$stateParams', '$element', '$filter',
                '$translate', '$timeout', '$http', 'BasicHelper', 'ConfirmPopupService', 'ModalService', 'events', 'Page', 'ToastFactory', 'UserManagementService', UserManagementUpdatePopupController]
        })

    function UserManagementUpdatePopupController($rootScope, $scope, $stateParams, $element, $filter, $translate, $timeout, $http, BasicHelper, ConfirmPopupService, ModalService,
                                                 events, Page, ToastFactory, UserManagementService) {
        var ctrl = this, popup;
        let userChangeWatcher;

        ctrl.close = close;
        ctrl.onOpen = onOpen;
        ctrl.onClose = onClose;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.saveUser = saveUser;
        ctrl.pickPhoneCode = pickPhoneCode;
        ctrl.sendLoginInfo = sendLoginInfo;
        ctrl.deleteUser = deleteUser;
        ctrl.approveUser = approveUser;
        ctrl.declineUser = declineUser;
        ctrl.isLoading = true;

        // Run Init

        function setUserEditSettings(userConfigurationSettings) {
            // Reset validation data
            ctrl.validationData = [];
            initUserChangeWatcher();

            // Hook up to for submit event
            var userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                ctrl.validationData = UserManagementService.validate(ctrl.renderModel, ctrl.settings.Settings);
                if (ctrl.validationData.length === 0) {
                    saveUser();
                }
            });
            var userCancel = $rootScope.$on('GlobalFormCancel', function () {
                Page.stateGoPrevious('userManagement', $stateParams.token);
            });
            $scope.$on('$destroy', userSubmit);
            $scope.$on('$destroy', userCancel);

            ctrl.renderModel = {
                UserToken: null,
                AccountModuleToken: null,
                Name: '',
                Title: '',
                Alias: '',
                Phone: '',
                PhoneCountryId: 1,
                PhoneCode: 45,
                Email: '',
                UserGroups: [],
                AdditionalDepartments: []
            };

            // handle custom user alias label (can be defined under UserModuleSettings)
            if (!(userConfigurationSettings.settingsData.data.UserAliasLabel)) {
                $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                    userConfigurationSettings.settingsData.data.UserAliasLabel = translation;
                });
            }

            // add "formatet" array with job titles
            ctrl.settings = userConfigurationSettings.settingsData.data;
            ctrl.User = userConfigurationSettings.userData.data.User;
            ctrl.AllowDelete = userConfigurationSettings.userData.data.AllowDelete;

            ctrl.departmentSettings = {
                departments: userConfigurationSettings.settingsData.data.AllowedDepartments
            };

            const selectableRoles =
                userConfigurationSettings.settingsData.data.Settings.SelectableRoles?.length
                    ? userConfigurationSettings.settingsData.data.Settings.SelectableRoles
                    : userConfigurationSettings.settingsData.data.Settings.DefaultRoleList;

            let lockedGroups = [];

            if (userConfigurationSettings.userData.data.User.ImportedUserGroups?.length) {
                lockedGroups = lockedGroups.concat(userConfigurationSettings.userData.data.User.ImportedUserGroups);
            }
            if (userConfigurationSettings.settingsData.data.Settings.DefaultRoleList) {
                lockedGroups = lockedGroups.concat(userConfigurationSettings.settingsData.data.Settings.DefaultRoleList);
            }
            ;

            lockedGroups = lockedGroups.filter(value => ctrl.User.SelectedUserGroups?.includes(value));

            ctrl.userGroupSettings = {
                usergroups: userConfigurationSettings.settingsData.data.AllowedUserGroups,
                locked: lockedGroups,
                selectableRoles: selectableRoles
            };

            // Map properties to renderModel
            ctrl.renderModel.UserToken = ctrl.User.UserToken;
            ctrl.renderModel.AccountModuleToken = $stateParams.token;
            ctrl.renderModel.Name = ctrl.User.Name;
            ctrl.renderModel.Title = ctrl.User.Title;
            ctrl.renderModel.Alias = ctrl.User.Alias;
            ctrl.renderModel.Email = ctrl.User.Email;
            ctrl.renderModel.PhoneCountryId = ctrl.User.PhoneCountryId;
            ctrl.renderModel.Phone = ctrl.User.Phone;
            ctrl.renderModel.PhoneCode = ctrl.User.PhoneCode;
            ctrl.renderModel.UserGroups = ctrl.User.SelectedUserGroups;
            ctrl.renderModel.DepartmentId = ctrl.User.DepartmentId;
            ctrl.renderModel.AdditionalDepartments = ctrl.User.AdditionalDepartments;

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateGoBack('userManagement', $stateParams.token);
            });
        }

        // Methods
        function pickPhoneCode() {
            ModalService.showModal({
                templateUrl: '/Profile/PhoneCodePicker',
                controller: 'PhoneCodePickerController',
                inputs: {countryId: ctrl.renderModel.PhoneCountryId}
            }).then(function (modal) {
                // Returns the modal for further options if needed
                modal.scope.$watch('selectedCountry', function (newVal) {
                    if (newVal !== null) {
                        ctrl.renderModel.PhoneCountryId = newVal.CountryId;
                        ctrl.renderModel.PhoneCode = newVal.PhoneCode;
                    }
                });
            });
        }

        function sendLoginInfo() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                var opts = {
                    title: $filter('translate')('USER_MANAGEMENT.SEND_LOGIN_INFO'),
                    message: $filter('translate')('CONFIRM.ARE_YOU_SURE')
                };

                ConfirmPopupService.open(opts).then(function () {
                    // Confirmed
                    Page.startLoading();
                    $http.post('/UserManagement/SendInfo', {
                        token: ctrl.renderModel.UserToken,
                        accModuleToken: ctrl.renderModel.AccountModuleToken
                    }).then(function (response) {
                        var successMessage = $filter('translate')('USER_MANAGEMENT.SEND_LOGIN_INFO_DONE');
                        ctrl.isSaving = false;
                        Page.stopLoading();
                        ToastFactory.success(successMessage);
                    }, function (errorBody) {
                        var errorMessage = $filter('translate')(errorBody.data[0].Message);
                        ctrl.isSaving = false;
                        Page.stopLoading();
                        ToastFactory.error(errorMessage);
                    });
                }, function () {
                    // Cancelled
                    ctrl.isSaving = false;
                });
            }
        }

        function deleteUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                BasicHelper.translateStrings(
                    ['USER_MANAGEMENT.DELETE_USER', 'USER_MANAGEMENT.DELETE_USER_CONFIRM', 'OK', 'CANCEL']
                ).then(function (translations) {

                    var opts = {
                        title: translations[0],
                        message: translations[1],
                        yesText: translations[2],
                        noText: translations[3]
                    };

                    ConfirmPopupService.open(opts).then(function () {
                        // Confirmed
                        Page.startLoading();
                        $http.get('/UserManagement/DeleteUser?token=' + ctrl.User.UserToken +
                            '&accModuleToken=' + $stateParams.token).then(function (response) {
                            Page.stopLoading();
                            if (response.data) {
                                $translate('USER_MANAGEMENT.DELETED').then(function (translation) {
                                    ToastFactory.success(translation);
                                });
                                ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                                ctrl.close();
                            } else {
                                ctrl.isSaving = false;
                                $translate('ERROR.GENERAL').then(function (translation) {
                                    ToastFactory.error(translation);
                                });
                            }
                        }, function () {
                            Page.stopLoading();
                            ctrl.isSaving = false;
                            $translate('ERROR.GENERAL').then(function (translation) {
                                ToastFactory.error(translation);
                            });
                        });
                    }, function () {
                        // Cancelled
                        ctrl.isSaving = false;
                    });
                });
            }
        }

        function initUserChangeWatcher() {
            let initializing = true;

            userChangeWatcher = $scope.$watch(() => ctrl.renderModel,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function saveUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;
                Page.startLoading();

                $http.post('/UserManagement/SaveUser', ctrl.renderModel).then(function (response) {
                    ctrl.isSaving = false;
                    Page.stopLoading();

                    // Success, user saved
                    ToastFactory.successTranslated('USER_MANAGEMENT.SAVED');
                    ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                    ctrl.close();


                }, function (response) {
                    ctrl.isSaving = false;
                    Page.stopLoading();

                    // Failed
                    if (response.status === 400) {
                        // Invalid data
                        ctrl.validationData = response.data;
                        ToastFactory.warningTranslatedReplace('ERROR.FORM_VALIDATION');
                    } else {
                        // Unknown error
                        ToastFactory.errorTranslated('ERROR.GENERAL');
                    }
                });
            }
        }

        function approveUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;
                UserManagementService.approveUser(ctrl.User.UserToken)
                    .then(() => {
                        $translate('USER_MANAGEMENT.APPROVE_MESSAGE').then(text => {
                            ToastFactory.success(text + ' ' + ctrl.User.Name);
                        })
                        ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                        ctrl.close();
                    }).finally(() => ctrl.isSaving = false);
            }
        }

        function declineUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;
                UserManagementService.declineUser(ctrl.User.UserToken)
                    .then(() => {
                        $translate('USER_MANAGEMENT.DECLINE_MESSAGE').then(text => {
                            ToastFactory.warning(ctrl.User.Name + ' ' + text);
                        });
                        ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                        ctrl.close();
                    }).finally(() => ctrl.isSaving = false);
            }
        }

        function onPopupRegistered(popup) {
            popup.open();
        }

        function onClose() {
            $rootScope.disableVisibilitychange = false;
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;

            UserManagementService.getUser(ctrl.userToken, $stateParams.token).then((userData) => {
                UserManagementService.getSettings($stateParams.token).then((settingsData) => {
                    const combinedUserSettings = {
                        userData,
                        settingsData
                    };

                    setUserEditSettings(combinedUserSettings);
                }).finally(() => ctrl.isLoading = false);
            });
        }


        function close() {
            ctrl.onClose && ctrl.onClose();
            ctrl.isChanged = false;
            popup.remove();
            $element.remove();
            userChangeWatcher && userChangeWatcher();
        }
    }
})();