(function () {
    'use strict';

    angular.module('App')
        .component('departmentSwapDropdown', {
            template: require('./DepartmentSwapDropdownComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['DepartmentSwapService', DepartmentSwapDropdownComponentController],
            bindings: {}
        });

    function DepartmentSwapDropdownComponentController(DepartmentSwapService) {
        var ctrl = this;
        ctrl.DepartmentSwapService = DepartmentSwapService;

        ctrl.saveChanges = saveChanges;
        function saveChanges() {
            var selection = DepartmentSwapService.getDepartments().find(o => o.selected == true);

            if (selection) {
                DepartmentSwapService.changeDepartment(selection.id).finally(() => {
                    DepartmentSwapService.toggleDropdownVisibility(false);
                });
            }
        }
    }
})();