(() => {
    'use strict'

    angular.module('Relesys.Ui')
        .component('rlRichPreview', {
            template: require('./RelesysRichPreviewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', '$compile', '$scope', 'ResponsiveService', 'RichMentionService',
                RlRichPreviewController],
            transclude: true,
            bindings: {
                text: '<',
                embeddedMedias: '<',
                readMoreEnabled: '<',
                onReadMoreClick: '<',
                hashTagsEnabled: '<',
                isOpen: '=?',
                departments: '<',
                userGroups: '<',
                users: '<',
                isOld: '<'
            }
        });

    function RlRichPreviewController($timeout, $element, $compile, $scope, ResponsiveService, RichMentionService) {
        const ctrl = this, el = $element.find('.content')[0];
        let maxHeight = ResponsiveService.isDesktop() ? 180 + 70 : 90 + 50, loadedMediaCount = 0;

        ctrl.$onInit = init;
        ctrl.toggleReadMore = toggleReadMore;

        $scope.$watch('ctrl.text', (val, oldVal) => {
            if (val && val !== oldVal) {
                loadedMediaCount = 0;
                el.style.minHeight = el.scrollHeight + "px";
                init(true);
            }
        })

        function init(isReInit) {
            const parsedText = parseText(ctrl.text);

            ctrl.loading = ctrl.readMoreEnabled && !isReInit;

            if (isReInit) {
                $element.find('.content .text').empty();
                el.style.minHeight = null;
            }

            $element.find('.content .text').append(parsedText);

            if (ctrl.readMoreEnabled) {
                if (!ctrl.embeddedMedias || !ctrl.embeddedMedias.length) {
                    initReadMore();
                }
            } else {
                $element.find('.content').removeClass('loading');
            }
        }

        function onOpen(onInit) {
            el.style.minHeight = null;
            if (ctrl.isOpen) {
                el.classList.remove('closed');
                if (onInit) {
                    el.style.maxHeight = 'initial';
                } else {
                    el.style.maxHeight = el.scrollHeight + "px";
                    $timeout(() => {
                        el.style.maxHeight = "initial";
                    }, 200)
                }
            } else {
                if (onInit) {
                    el.style.maxHeight = null;
                    el.classList.add('closed');
                } else {
                    el.style.maxHeight = el.scrollHeight + "px";
                    $timeout(() => {
                        el.style.maxHeight = null;
                        el.classList.add('closed');
                    })
                }
            }
        }

        function initReadMore() {
            $timeout(() => {
                if ($element.find('.content')[0].scrollHeight > maxHeight) {
                    ctrl.applyReadMore = true;
                    onOpen(true);
                }
                ctrl.loading = false;
            })
        }

        function parseText(text) {
            let scope, parsedText = text;
            parsedText = parsedText.replace(new RegExp('translate="no"', 'g'), '');
            parsedText = parsedText.replace(new RegExp('contenteditable="false" draggable="true" ', 'g'), '');
            parsedText = parsedText.replace(new RegExp('contenteditable="false"', 'g'), '');
            parsedText = parsedText.replace(/(?:\r\n|\r|\n)/g, '<br />');

            if (!ctrl.isOld) {
                parsedText = parseMedia(parsedText, ctrl.embeddedMedias);
                parsedText = parseTables(parsedText);
            } else {
                parsedText = RichMentionService.parseOldMentions(parsedText, {
                    taggedDepartments: ctrl.departments, taggedUserGroups: ctrl.userGroups, taggedUsers: ctrl.users
                });

                if (ctrl.embeddedMedias && ctrl.embeddedMedias.length) {
                    parsedText = RichMentionService.parseOldImages(parsedText, ctrl.embeddedMedias, (foundmedia) => {

                        let missingMediaMatches = ctrl.embeddedMedias.length - foundmedia.length;
                        if (missingMediaMatches > 0) {
                            loadedMediaCount += (missingMediaMatches - 1); // next method automatically adds a count, so we subtract one before calling it
                            onMediaLoaded();
                        }

                    });
                }

                if (ctrl.hashTagsEnabled) {
                    parsedText = parseOldHashtags(parsedText);
                }
            }

            if (parsedText[0] !== '<') {
                parsedText = `<p>${parsedText}</p>`;
            }

            scope = Object.assign($scope.$new(true), {
                embeddedMedias: ctrl.embeddedMedias,
                onMediaLoaded: ctrl.embeddedMedias && ctrl.embeddedMedias.length > 0 && onMediaLoaded
            });
            return $compile(parsedText)(scope);
        }

        function parseMedia(text, medias) {
            let videoTokenMatches = [];
            let imageTokenMatches = [];

            const originalText = text,
                string = '<img[^>]*? token="(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})".*?>',
                regExp = new RegExp(string, 'gm'),
                images = Array.from(originalText.matchAll(regExp));

            images.forEach(arr => {
                const index = medias && medias.length && medias.findIndex(media => media.MediaToken === arr[1]);

                if (index !== undefined && index !== -1) {
                    const classes = arr[0].match(/class="([^"]*)"/),
                        width = arr[0].match(/style="width: (\d+)px;.*?"/);

                    text = text.replace(arr[0],
                        `<media 
                                item="embeddedMedias[${index}]"
                                on-media-loaded="onMediaLoaded"
                                enable-popup="true"
                                ${classes && 'class="' + classes[1] + '"'}
                                ${width && 'resize-width="' + width[1] + '"'}
                        ></media>`);

                    if (imageTokenMatches.indexOf(arr[1]) === -1) {
                        imageTokenMatches.push(arr[1]);
                    }
                } else {
                    //remove empty media
                    text = text.replace(arr[0], ``);
                }
            });

            const videos = angular.element(`<div>${text}</div>`).find('.fr-video:not(.iframe)');

            videos.each((i, video) => {
                const token = video.getAttribute('token'),
                    index = medias && medias.length && medias.findIndex(media => media.MediaToken === token);

                if (index !== undefined && index !== -1) {
                    const classes = video.getAttribute('class'),
                        width = video.innerHTML.match(/style="width: (\d+)px;.*?"/);

                    text = text.replace(video.outerHTML,
                        `<media 
                            item="embeddedMedias[${index}]"
                            on-media-loaded="onMediaLoaded"
                            enable-popup="true"
                            ${classes && 'class="' + classes + '"'}
                            ${width && 'resize-width="' + width[1] + '"'}
                        ></media>`
                    );

                    if (videoTokenMatches.indexOf(token) === -1) {
                        videoTokenMatches.push(token);
                    }
                }
            });

            if (medias && medias.length) {
                // did we have more images embedded than we found with our regex?
                let missingMediaMatches = medias.length - imageTokenMatches.length - videoTokenMatches.length;
                //loadedMediaCount += missingMediaMatches;
                if (missingMediaMatches > 0) {
                    loadedMediaCount += (missingMediaMatches - 1); // next method automatically adds a count, so we subtract one before calling it
                    onMediaLoaded();
                }
            }

            return text;
        }

        function parseTables(text) {
            const textEl = angular.element(`<div>${text}</div>`), tables = textEl.find('table');

            if (tables && tables.length) {
                tables.each((ind, table) => {
                    angular.element(table).wrap('<div class="table-wrapper"></div>')
                })
            }
            return textEl.html();
        }

        function parseOldHashtags(text) {
            return text.replace(/\B(#[\w]+\b)(?!<)/ig, '<hash-tag>$&</hash-tag>');
        }

        function onMediaLoaded() {
            loadedMediaCount++;
            if (ctrl.readMoreEnabled && loadedMediaCount >= ctrl.embeddedMedias.length) {
                initReadMore();
            }
        }

        function toggleReadMore() {
            ctrl.isOpen = !ctrl.isOpen;
            onOpen();
            ctrl.onReadMoreClick && ctrl.onReadMoreClick();
        }
    }
})()
