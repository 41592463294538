(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .factory('ActionPopupService', ['$rootScope', '$compile', function ($rootScope, $compile) {
            return {
                create: create
            };

            function create(component, bindings) {
                const scope = Object.assign($rootScope.$new(true), bindings || {}),
                    popup = $compile(component)(scope);

                angular.element('body').append(popup);

                return popup;
            }
        }]);
})();