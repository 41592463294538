(function () {
    'use strict';
    var app = angular.module('App');

    app.controller('HomeController', ['$rootScope', '$scope', '$window', '$http', '$timeout',
        'Page', 'Menu', 'events', 'AppBaseFactory',
        function ($rootScope, $scope, $window, $http, $timeout, Page, Menu,
                  events, AppBaseFactory) {
            var ctrl = this, appUnhideWatcher, updateMenuWatchers, departmentChangedWatcher;
            ctrl.Page = Page;

            // Init
            InitController();

            function InitController() {

                // On app resume
                appUnhideWatcher = $rootScope.$on(events.APP_UNHIDE, function () {
                    // Check Version and reload if a new is present - only on native
                    if (Page.getSettings().NativeSettings.IsNativeWrapper === true) {
                        CheckAppVersion();
                    }
                });

                updateMenuWatchers = $rootScope.$on(events.MENU_UPDATED, function () {
                    ctrl.Tiles = Menu.homeMenu();
                });

                departmentChangedWatcher = $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.clearBackButton();
                    Page.setTitle();
                });

                // Check Version and reload if a new is present
                CheckAppVersion();
                // Set to be frontpage
                $rootScope.IsFrontPage = true;

                // Load data
                ctrl.Settings = Page.getSettings();
                ctrl.Tiles = Menu.homeMenu();
                ctrl.Slides = [];

                if (ctrl.Settings?.NewsTickersEnabled) {
                    LoadNewsTicker();
                }

                ctrl.landingPage = Menu.getFrontPage;

                // Start slideshow
                $scope.SlideshowTimer = null;
                StartSlideshow();

                $scope.$on('$destroy', function () {
                    clearTimeout($scope.SlideshowTimer);
                    clearInterval($scope.SlideshowTimer);
                    appUnhideWatcher();
                    updateMenuWatchers();
                    departmentChangedWatcher();
                });
            }

            // Methods
            function FrontPageGo(item) {
                if (item.Url) {
                    $window.open(item.Url, '_blank');
                    return false;
                }

                Page.stateGo(item.State, item.Token, item.Extra);
            }

            function CheckAppVersion() {
                $http.get('/Index/GetVersion').then(function (response) {
                    if (response.data.Version !== Page.getVersion()) {
                        AppBaseFactory.reloadApp();
                    }
                });
            }

            function StartSlideshow() {
                // Add Slides
                angular.forEach(ctrl.Settings.HomeMedia, function (item) {
                    ctrl.Slides.push(item);
                });

                ctrl.SlideShowIndex = 0;
                if (ctrl.Slides.length > 1) {
                    $scope.SlideshowTimer = setTimeout(function () {
                        NextSlide();
                        $scope.SlideshowTimer = setInterval(function () {
                            NextSlide();
                        }, 8000);
                    }, 5000)
                }
            }

            function NextSlide() {
                $timeout(function () {
                    ctrl.SlideShowStarted = true;
                    ctrl.SlideShowPrevIndex = ctrl.SlideShowIndex;
                    ctrl.SlideShowIndex = (ctrl.SlideShowIndex === (ctrl.Slides.length - 1)) ? 0 : ctrl.SlideShowIndex + 1;
                });
            }

            function LoadNewsTicker() {
                $http.get('/NewsTicker/GetNewsTicker').then(function (response) {
                    ctrl.newsTickerList = response.data;
                    ctrl.showNewsTicker = (ctrl.newsTickerList.length > 0);
                }, function () {
                    ctrl.showNewsTicker = false;
                    ctrl.newsTickerList = [];
                });
            }

            // Map scope
            ctrl.MenuGo = FrontPageGo;
        }
    ]);

})();