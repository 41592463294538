(function () {
    'use strict';

    angular.module('App')
        .component('subtasksList', {
            template: require('./SubtasksListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', 'PopupWrapperService', subtasksListController],
            bindings: {
                issue: '<',
                showOriginal: '<',
                sortingEnabled: '<'
            }
        });

    function subtasksListController($rootScope, PopupWrapperService) {
        var ctrl = this;

        ctrl.openSubtaskDetailsPopup = openSubtaskDetailsPopup;
        ctrl.subtasksWereSorted = subtasksWereSorted;

        ctrl.sortableOptions = {
            animation: 350,
            handle: '.handle',
            forceFallback: true,
            onEnd: function () {
                ctrl.subtasksWereSorted();
            }
        };

        function subtasksWereSorted() {
            $rootScope.$emit('TaskManagement:subtasksOrderChanged', ctrl.issue.Subtasks);
        }

        function openSubtaskDetailsPopup(subtask) {
            if (!ctrl.sortingEnabled) {
                const completionData = {
                    isCompletionLocked: ctrl.issue.IsCompletionLocked
                };
                PopupWrapperService.createDynamic(
                    `<subtask-details-popup
                                    issue-token="issueToken"
                                    completion-data="completionData"
                                    task-token="taskToken"
                                ></subtask-details-popup>`,
                    {
                        issueToken: subtask.IssueSubtaskToken,
                        completionData: completionData,
                        taskToken: ctrl.issue.IssueToken
                    }
                );
            }
        };
    }
})();