(function () {
    'use strict';

    var app = angular.module('App');

    app.component('ecShopgunListView', {
        templateUrl: '/Scripts/Components/EmbeddedContent/ShopGun/ShopGunListViewComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$http', 'Page', 'TimeLocale', EcShopgunListViewController],
        bindings: {
            token: '<',
            settings: '<',
            title: '<'
        }
    });

    function EcShopgunListViewController($http, Page, TimeLocale) {
        var ctrl = this;
        ctrl.state = 'LIST';
        ctrl.isLoading = true;
        ctrl.catalogs = [];
        ctrl.session = null;
        ctrl.pageIndex = null;
        ctrl.currentCatalog = null;

        $http.get('/EmbeddedContent/ShopGunGetListView/' + ctrl.token).then(function (response) {
            ctrl.session = response.data.session;
            ctrl.catalogs = response.data.catalogs;
            ctrl.isLoading = false;
        }, function (response) {
            ctrl.session = null;
            ctrl.isLoading = false;
        });

        ctrl.showList = function () {
            ctrl.state = 'LIST';
            ctrl.currentCatalog = null;
            ctrl.pageIndex = null;
            Page.setSubTitle(null);
            Page.showBackButton('home');
        };

        ctrl.openCatalog = function (catalog) {
            ctrl.currentCatalog = catalog;
            ctrl.pages = [];
            ctrl.state = 'PAGE';
            ctrl.pageIndex = null;
            Page.setSubTitle(catalog.label);

            Page.showBackButtonFunction(function () {
                ctrl.showList();
            });
        };

        ctrl.getDateInterval = function (catalog) {
            var locale = TimeLocale.getUserLocale();
            var from = TimeLocale.dateTimeFormat(catalog.run_From, 'MMM D', locale);
            var to = TimeLocale.dateTimeFormat(catalog.run_Till, 'MMM D', locale);

            return from + ' - ' + to;
        };

        ctrl.showCatalog = function (catalog, pageIndex) {
            ctrl.state = 'CATALOG';
            ctrl.currentCatalog = catalog;
            ctrl.pageIndex = pageIndex;
        };
    }
})();