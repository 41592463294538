(function () {
	'use strict';
	var app = angular.module('App');

	app.controller('IOS11UploadWarningModalController', ['$scope', '$http', 'close',
		function ($scope, $http, close) {
			$scope.close = close;

			function CloseModal() {
				$scope.$emit('closeModal');
			}

			// Bindings
			$scope.CloseModal = CloseModal;
		}
	]);
})();