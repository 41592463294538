(function () {
    'use strict';

    var app = angular.module('App');

    app.component('mentionTextarea', {
        templateUrl: '/Scripts/Components/MentionTextarea/MentionTextareaComponent.tpl.html',
        controllerAs: 'ctrl',
        controller: ['$scope', '$element', '$compile', '$timeout', 'MentionService', 'BasicHelper', MentionTextareaController],
        bindings: {
            placeholder: '@',
            text: '=',
            mentions: '=',
            hashTags: '=?',
            short: '<',
            filterByDepartment: '<',
            loadUsersForPost: '<',
            socialGroupId: '<',
            postId: '<',
            groupsCount: '<',
            enableMentions: '<',
            enableHashTags: '<',
            moduleToken: '<',
            module: '@',
            isRequired: '<',
            onRender: '<',
            onBlur: '<',
            onKeydown: '<',
            users: '<'
        }
    });

    function MentionTextareaController($scope, $element, $compile, $timeout, MentionService, BasicHelper) {
        var ctrl = this, pristine = true, maxTagging;

        ctrl.$onInit = onInit;

        $scope.$watch('ctrl.text', (newVal) => {
            if (typeof newVal !== 'string') {
                ctrl.text = '';
            }
        });

        function onInit() {
            if (ctrl.users?.length) {
                ctrl.isDisabled = false;
                initMentionsWatcher();
            }
            
            if (ctrl.enableMentions && !ctrl.users?.length) {
                ctrl.isDisabled = true;
                loadDataForMentions();
                initMentionsWatcher();
            }
            if (ctrl.enableHashTags) {
                initTaggingWatcher();
                loadDataForTagging();
            }

            if (!ctrl.short && (ctrl.enableMentions || ctrl.enableHashTags)) {
                $scope.$watch('ctrl.mention.caretPosition', watchPosition, true);
                $scope.$watch('ctrl.tagging.caretPosition', watchPosition, true);
            }

            $scope.$on('mention:reInit', initMentions)

            $timeout(function () {
                $element.find('.textarea').append(renderTextarea());
                $timeout(function () {
                    ctrl.onRender && ctrl.onRender();
                    initMentions();
                })
                document
                    .querySelector('.ui-mention-container')
                    .addEventListener('click', selectMention)

                if (BasicHelper.isIOS()) {
                    document
                        .querySelector('.ui-mention-container')
                        .addEventListener('touchend', selectMention);

                    document
                        .querySelector('.ui-mention-container')
                        .addEventListener('touchmove', () => ctrl.touchScroll = true);
                }
            });
        }

        function selectMention(e) {
            const li = e.target.closest('li');
            if (li && !ctrl.touchScroll) {
                if (li.parentNode.classList.contains('mention')) {
                    ctrl.mention.select(ctrl.mention.choices[li.getAttribute('index')]);
                    e.preventDefault();
                    $element.find('textarea').focus();
                }

                if (li.parentNode.classList.contains('tagging')) {
                    ctrl.tagging.select(ctrl.tagging.choices[li.getAttribute('index')]);
                    e.preventDefault();
                    $element.find('textarea').focus();
                }
            }
            
            ctrl.touchScroll = false;
        }

        function initMentions(ev, mentions) {
            ctrl.mentions = mentions || ctrl.mentions;
            if (ctrl.mentions && ctrl.mention) {
                ctrl.mention.mentions = ctrl.mentions;
            }
        }

        function initTaggingWatcher() {
            var pattern = /#(?:[\S])+/ig;

            $scope.$watch('ctrl.text', function (newVal) {
                if (newVal != undefined && newVal.match(pattern)) {
                    ctrl.hashTags = _.map(newVal.match(pattern), function (tag) {
                        return tag.replace('#', '').toLowerCase()
                    });
                }
                if (ctrl.hashTags && ctrl.hashTags.length >= 10) {
                    maxTagging = true;
                    $element.find('.textarea').on('keypress', handleMaxTags)
                } else if (maxTagging) {
                    $element.find('.textarea').off('keypress', handleMaxTags);
                    maxTagging = false;
                }
            }, true);
        }

        function loadDataForTagging() {
            MentionService.getHashTags(ctrl.moduleToken).then(function (hashtags) {
                ctrl.suggestedHashtags = hashtags
            })
        }

        function loadDataForMentions() {
            var watchTarget;
            if (ctrl.module === 'Social') {
                watchTarget = ctrl.loadUsersForPost ? 'ctrl.postId' : 'ctrl.socialGroupId';
                $scope.$watch(watchTarget, function (id) {
                    if (id || !ctrl.filterByDepartment && ctrl.groupsCount === 0 || ctrl.filterByDepartment) {
                        MentionService.getSocialUsers(id, ctrl.loadUsersForPost, ctrl.moduleToken).then(function (users) {
                            ctrl.isDisabled = false;
                            ctrl.users = users;
                        });
                    }
                });
            } else if (ctrl.module === "ContentModule") {
                MentionService.getContentUsers(ctrl.postId, ctrl.loadUsersForPost, ctrl.moduleToken)
                    .then(function (users) {
                        ctrl.isDisabled = false;
                        ctrl.users = users;
                    });
            }
        }

        function handleMaxTags(e) {
            var textarea = $element.find('textarea'),
                pos = textarea.prop('selectionStart'),
                lastChar = textarea.val()[pos - 1],
                txt = String.fromCharCode(e.which);

            if (txt === '#' && lastChar === ' ') {
                return false;
            }
        }

        function watchPosition(position) {
            if (position) {
                var dropdownHeight = $element.find('.mention-dropdown').outerHeight() || 150,
                    ffFix = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? 15 : 0,
                    popupEl = ctrl.module === 'Social' ? $element.parents('.popup-viewer') : $element.parents('.rl-form'),
                    textarea = $element.find('textarea');

                if (textarea.offset().top + position.top - 25 - dropdownHeight >= popupEl.offset().top) {
                    ctrl.dropdownTopPosition = {'top': textarea[0].getBoundingClientRect().top + position.top - dropdownHeight - 25 + ffFix + 'px'};
                } else {
                    ctrl.dropdownTopPosition = {'top': textarea[0].getBoundingClientRect().top + position.top + 5 + ffFix + 'px'};
                }
            }
        }

        function initMentionsWatcher() {
            ctrl.mentionsWatcher = $scope.$watchCollection('ctrl.mention.mentions', function (newVal, oldVal) {
                // provide existing mentions if provided for first load
                if (ctrl.mention && pristine && ctrl.mentions) {
                    // pause watcher to prevent infinite loop
                    ctrl.mentionsWatcher();
                    ctrl.mentions && ctrl.mentions.map(function (mention) {
                        ctrl.mention.mentions.push(mention);
                    });

                    initMentionsWatcher();
                }

                // updating model
                if (newVal !== oldVal && newVal !== undefined) {
                    pristine = false;
                    ctrl.mentions = newVal;
                }
            });
        }

        function renderTextarea() {
            var element,
                tagging = ctrl.enableHashTags ? 'tagging' : '',
                mentions = ctrl.enableMentions ? 'users-departments-mention' : '',
                textarea = '<textarea ui-mention auto-height ' + tagging + ' ' + mentions + ' ng-model="ctrl.text" ng-disabled="ctrl.isDisabled"\n' +
                    '                  ng-attr-placeholder="{{ ctrl.placeholder }}" ng-keydown="ctrl.onKeydown($event)"\n' +
                    '                        ng-blur="ctrl.onBlur()" ng-required="ctrl.isRequired" \n' +
                    '                  aria-label="{{ ctrl.placeholder }}" body-scroll-lock-ignore>\n' +
                    '</textarea>';

            element = $compile(textarea)($scope);

            return element;
        }
    }
})();
