(function () {
    'use strict';

    angular.module('App')
        .component('loadingItem', {
            template: `
            <div class="bookmarks-list-loading-item">
                <div class="bookmarks-list-loading-item-image placeholder-shimmer"></div>
                <div class="bookmarks-list-loading-item-text">
                    <div class="bookmarks-list-loading-item-text-headline placeholder-shimmer"></div>
                    <div class="bookmarks-list-loading-item-text-headline placeholder-shimmer"></div>
                    <div class="bookmarks-list-loading-item-text-subheadline placeholder-shimmer"></div>
                </div>
            </div>
            `
        });
})();